import React from 'react'

import { observer } from 'mobx-react'
import { Helmet } from 'react-helmet'
import { useRouteMatch } from 'react-router'
import { Redirect, Route, Switch } from 'react-router-dom'

import { ScrollResetter } from 'shared/components/atoms'

import { UniversalNotifications } from 'retailer/components/organisms'
import { SidebarLeftTemplate } from 'retailer/components/templates'
import { BusinessInfoPage, ManagePlanPage, NotFound, StoreProfilePage, UserSettingsPage } from 'retailer/pages'

const SettingsPage = () => {
  const { path } = useRouteMatch()

  const main = (
    <Switch>
      <Route exact path={path}>
        <Redirect to={`${path}/store-profile`} />
      </Route>
      <Route exact path={`${path}/store-profile`}>
        <StoreProfilePage />
      </Route>
      <Route exact path={`${path}/business-info`}>
        <BusinessInfoPage />
      </Route>
      <Route exact path={`${path}/user-settings`}>
        <UserSettingsPage />
      </Route>
      <Route exact path={`${path}/manage-store-plan/:message?`}>
        <ManagePlanPage />
      </Route>
      <Route>
        <NotFound withSidebar={false} />
      </Route>
    </Switch>
  )

  return (
    <>
      <Helmet>
        <title>Settings</title>
      </Helmet>
      <ScrollResetter />
      <SidebarLeftTemplate
        notifications={<UniversalNotifications />}
        main={main}
      />
    </>
  )
}

export default observer(SettingsPage)
