import PropTypes from 'prop-types'

import { refType } from 'shared/types'

const productOption = PropTypes.shape({
  key: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
})

const categoryTag = PropTypes.shape({
  id: PropTypes.number.isRequired,
  uuid: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
})

const categoryTagTree = PropTypes.shape({
  children: PropTypes.arrayOf(categoryTag),
})

const remoteImage = PropTypes.shape({
  uuid: PropTypes.string.isRequired,
  src: PropTypes.string.isRequired,
})

const localImage = PropTypes.shape({
  uuid: PropTypes.string.isRequired,
  data: PropTypes.string.isRequired,
  file: PropTypes.object.isRequired,
})

const apiStatus = PropTypes.shape({
  isLoaded: PropTypes.bool,
  isLoading: PropTypes.bool,
  isSaving: PropTypes.bool,
  isDeleting: PropTypes.bool,
  remoteErrors: PropTypes.object,
})

const image = PropTypes.oneOfType([remoteImage, localImage])

export default {
  ref: refType,
  categoryTag,
  categoryTagTree,
  remoteImage,
  localImage,
  image,
  productOption,
  apiStatus,
}
