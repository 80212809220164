import React from 'react'

import PropTypes from 'prop-types'

import { Button } from '@material-ui/core'
import { observer } from 'mobx-react'

import { Alert } from 'shared/components/molecules'
import { DataModal, ProgressBar } from 'shared/components/organisms'

import style from './SingleBatchActionModal.module.scss'

const SingleBatchActionModal = ({ singleBatchAction, description }) => {
  return (
    <DataModal
      className={style.SingleBatchActionModal}
      onExited={() => singleBatchAction.reset()}
      title={singleBatchAction.actionName}
      maxWidth="md"
      fullWidth
      renderBody={() => {
        if (singleBatchAction.error) {
          return (
            <Alert title={singleBatchAction.error.name}>
              {singleBatchAction.error.message}
            </Alert>
          )
        }

        if (singleBatchAction.success) {
          return (
            <Alert title="Processed successfully">
              Your request to {singleBatchAction.actionName} has been processed successfully!
            </Alert>
          )
        }

        if (singleBatchAction.inProgress) {
          return (
            <div data-testid="processing">
              <ProgressBar indeterminate />
            </div>
          )
        }

        return description
      }}
      renderActions={({ dismissModal }) => {
        return (
          <>
            <Button
              data-testid="closeButton"
              variant="outlined"
              onClick={dismissModal}
              disabled={singleBatchAction.inProgress}
            >
              Close
            </Button>
            <Button
              data-testid="actionButton"
              variant="contained"
              color="primary"
              onClick={() => singleBatchAction.run()}
              disabled={Boolean(
                singleBatchAction.inProgress || singleBatchAction.success || singleBatchAction.error
              )}
            >
              {singleBatchAction.actionName}
            </Button>
          </>
        )
      }}
    />
  )
}

SingleBatchActionModal.propTypes = {
  singleBatchAction: PropTypes.shape({
    open: PropTypes.bool.isRequired,
    actionName: PropTypes.string.isRequired,
    inProgress: PropTypes.bool,
    success: PropTypes.bool,
    error: PropTypes.any,
    run: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired
  }).isRequired,
  description: PropTypes.node.isRequired
}

export default observer(SingleBatchActionModal)
