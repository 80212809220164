import { action, computed, observable } from 'mobx'

import { processApiError } from 'shared/api/utils'

import {
  adaptGetShippingSettingsResponse,
  adaptPutShippingSettingsErrors,
  adaptPutShippingSettingsRequest,
} from 'supplier/adapters/settings'
import { getShippingSettings, putShippingSettings } from 'supplier/api/settings'

class ShippingSettingsStore {
  /** @type {import('../root').RootStore} */
  root

  @observable isLoaded
  @observable isInProgress
  @observable remoteErrors

  @observable leadTimes
  @observable shippingDetails

  /**
   * @param {import('../root').RootStore} root
   */
  constructor (root, data = {}) {
    this.root = root
    this.update(data)
  }

  @action.bound
  update (data) {
    Object.assign(this, data)
  }

  @computed get formData () {
    return {
      leadTimes: Number.isInteger(this.leadTimes) ? this.leadTimes : null,
      shippingDetails: this.shippingDetails || '',
    }
  }

  @action.bound
  setIsInProgress (value) {
    this.isInProgress = value
  }

  @action.bound
  setIsLoaded (value) {
    this.isLoaded = value
  }

  @action.bound
  setRemoteErrors (value) {
    this.remoteErrors = value
  }

  @action.bound
  reset () {
    this.remoteErrors = undefined
  }

  @action.bound
  fetch () {
    this.isInProgress = true
    return getShippingSettings()
      .then(response => {
        const data = adaptGetShippingSettingsResponse(response.data)
        this.update(data)
        this.setIsLoaded(true)
        return data
      })
      .finally(() => {
        this.setIsInProgress(false)
      })
  }

  @action.bound
  submit (data) {
    this.isInProgress = true
    const payload = adaptPutShippingSettingsRequest(data)
    return putShippingSettings(payload)
      .then(response => {
        const data = adaptGetShippingSettingsResponse(response.data)
        this.update(data)
        return data
      })
      .catch(error => {
        const errors = processApiError(error, adaptPutShippingSettingsErrors)
        this.setRemoteErrors(errors)
        throw error
      })
      .finally(() => {
        this.setIsInProgress(false)
      })
  }
}

export default ShippingSettingsStore
