import QueryParamsOperator from './QueryParamsOperator'

/**
 * Get top-level object (root + 1) from a tree-like structure of objects referencing their parent
 * For:
 * R - L1.1 - L1.1.1
 *   - L1.2 - L1.2.1
 * getTopmostAncestorOf(L1.2.1) is L1.2
 **/
const getTopmostAncestorOf = obj => (obj.parent?.parent ? getTopmostAncestorOf(obj.parent) : obj)

/**
 * Given a node in a tree linked by `parent`, return a path of all parent nodes
 *
 * @param {Object} node
 * @param {Object} node.parent
 *
 * @returns {Array}
 */
export const getTreePath = node => {
  const result = []
  while (node?.parent) {
    result.push(node)
    node = node.parent
  }
  return result
}

export function dataURItoBlob (dataURI) {
  const chunks = dataURI.split(',')
  const byteString = atob(chunks[1])
  const mimeString = chunks[0].split(':')[1].split(';')[0]
  const ab = new ArrayBuffer(byteString.length)
  const ia = new Uint8Array(ab)
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i)
  }
  return new Blob([ab], { type: mimeString })
}

export {
  getTopmostAncestorOf,
  QueryParamsOperator,
}
