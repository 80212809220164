
import isEmpty from 'lodash/isEmpty'
import { action, observable, computed } from 'mobx'

import { adaptGetProductsParams, adaptGetProductsResponse } from 'shared/adapters/marketplace'
import { getProducts, getCustomizableItems } from 'shared/api/marketplace'
import { MARKETPLACE_CODE_PRINT_ON_DEMAND } from 'shared/constants/marketplace'

import { ItemStore } from 'retailer/stores'

class ProductCarouselStore {
  /** @type {import('../context').RootStore} */
  root

  @observable.shallow items
  @observable params = {}
  @observable numOfSlides
  @observable numOfItemsInSlide

  /**
   *
   * @param {import('../context').RootStore} root
   * @param {Object} params fetch params
   */
  constructor (root, params = {}) {
    this.root = root
    this.params = params
  }

  @action.bound setNumOfItemsInSlide (value) {
    if (value !== this.numOfItemsInSlide) {
      this.numOfItemsInSlide = value
      this.numOfSlides = Math.ceil(20 / value)
      this.fetch()
    }
  }

  @computed get groups () {
    if (!this.numOfItemsInSlide || isEmpty(this.items)) return []
    const numPages = Math.ceil(this.items.length / this.numOfItemsInSlide)
    return [...Array(numPages).keys()].map(
      pageNum => this.items.slice(this.numOfItemsInSlide * pageNum, this.numOfItemsInSlide * (pageNum + 1))
    )
  }

  @action.bound setItems (value) {
    this.items = value
  }

  @action.bound fetch () {
    this.items = undefined
    const pageSize = this.numOfItemsInSlide * this.numOfSlides
    const { marketplaceCode, ...params } = this.params
    const apiFn = marketplaceCode === MARKETPLACE_CODE_PRINT_ON_DEMAND
      ? getCustomizableItems
      : getProducts
    const apiFnParams = adaptGetProductsParams(Object.assign({
      page: 1, pageSize, staffPicks: ['bestseller']
    }, params))
    const biContext = { origin: 'dashboard' }
    return apiFn(apiFnParams)
      .then(response => {
        const results = adaptGetProductsResponse(response.data).results
        this.setItems(results.map((product, pos) => (
          new ItemStore(this.root, product, { ...biContext, position: pos + 1 })
        )))
        return response
      })
  }
}

export default ProductCarouselStore
