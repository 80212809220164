/* eslint camelcase: 0 */
/** Default MUI theme for the Modalyst Web UI */

import { unstable_createMuiStrictModeTheme } from '@material-ui/core/styles'

import baseTheme from './baseTheme'
import colorScheme from './colorScheme'

const muiTheme = unstable_createMuiStrictModeTheme({
  themeName: 'Modalyst Base Theme',
  direction: 'ltr', // RTL option
  props: {
    MuiCheckbox: {
      color: 'primary'
    }
  },
  palette: {
    primary: {
      main: colorScheme.primary600,
    },
    secondary: {
      main: colorScheme.secondary600,
    },
    blueGrey: {
      main: colorScheme.blueGrey4,
    },
    accent1: {
      main: colorScheme.accent1,
    },
    accent2: {
      main: colorScheme.accent2,
    },
    error: {
      main: colorScheme.error600,
    },
    warning: {
      main: colorScheme.warning600,
    },
    info: {
      main: colorScheme.blueGrey5,
    },
    success: {
      main: colorScheme.success600,
    },
    background: {
      default: colorScheme.grey100
    },
  },
  typography: {
    fontFamily: 'Inter, sans-serif',
    body1: {
      fontSize: '15px',
    },
    body2: {
      fontSize: '13px',
    },
    h1: {
      fontSize: '42px',
      fontWeight: 700,
    },
    h2: {
      fontSize: '36px',
      fontWeight: 700,
    },
    h3: {
      fontSize: '28px',
      fontWeight: 700,
    },
    h4: {
      fontSize: '24px',
      fontWeight: 700,
    },
    h5: {
      fontSize: '20px',
    },
    h6: {
      fontSize: '16px',
    },
    button: {
      textTransform: 'none'
    }
  },
  breakpoints: baseTheme.breakpoints,
  overrides: {
    MuiDialog: {
      paper: {
        margin: '16px',
      }
    },
    MuiDialogTitle: {
      root: {
        color: colorScheme.white,
        backgroundColor: colorScheme.primary800,
      },
      title: {
        margin: 0,
        fontSize: 16,
        fontWeight: 500,
      }
    },
    MuiDialogContent: {
      root: {
        padding: '12px 24px 8px',
      },
    },
    MuiDialogActions: {
      root: {
        padding: '16px 24px',
      },
    },
    MuiAlertTitle: {
      root: {
        fontSize: 16,
        fontWeight: 700,
      }
    },
    MuiAlert: {
      root: {
        flexWrap: 'wrap',
        fontSize: 14,
      },
    },
    MuiTypography: {
      gutterBottom: {
        marginBottom: '0.5em',
      },
    },
    MuiFormHelperText: {
      root: {
        lineHeight: '1rem',
        marginTop: '4px',
        marginBottom: '4px',
      }
    },
    MuiTableCell: {
      head: {
        fontWeight: 700,
      },
    },
    MuiFormLabel: {
      asterisk: {
        color: colorScheme.error500,
      },
    },
    MuiTooltip: {
      tooltip: {
        fontSize: '12px',
        backgroundColor: colorScheme.primary800,
        color: colorScheme.grey100,
        padding: '12px',
      },
      arrow: {
        color: colorScheme.primary800,
      }
    },
    MuiCardActions: {
      root: {
        justifyContent: 'flex-end',
        padding: 16
      }
    },
  }
})

export default muiTheme
