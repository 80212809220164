import { action, observable } from 'mobx'

import { adaptLocationErrors } from 'shared/adapters/settings'
import { getStoreLocation, saveStoreLocation } from 'shared/api/settings'
import { processApiError } from 'shared/api/utils'
import { LoadableStore } from 'shared/stores'

class LocationSettingsStore {
  parent

  @observable loading = new LoadableStore()
  @observable isSaving = false
  @observable errors
  @observable selectedCountryCode = null

  constructor (parent) {
    this.parent = parent
  }

  @action.bound
  fetch () {
    this.loading.setLoading()
    return getStoreLocation().then(response => {
      this.selectedCountryCode = response.data.selected_country
      this.loading.setLoaded()
    })
  }

  @action.bound
  save (countryCode) {
    this.isSaving = true
    return saveStoreLocation(countryCode)
      .then(response => {
        this.selectedCountryCode = response.data.selected_country
        this.parent.refreshUserProfile()
      })
      .catch(error => {
        this.errors = processApiError(error, adaptLocationErrors)
      })
      .finally(() => {
        this.isSaving = false
      })
  }
}

export default LocationSettingsStore
