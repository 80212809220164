import React from 'react'

import PropTypes from 'prop-types'

import { Typography } from '@material-ui/core'
import clsx from 'clsx'
import { observer } from 'mobx-react'

import {
  ALIBABA,
  ALIEXPRESS,
  BEAUTY,
  CBD,
  INDIE_BRANDS,
  INVENTORY_TYPE_COLORS,
  NAME_BRANDS,
  PRINT_ON_DEMAND,
  TRENDY_ITEMS,
} from 'shared/constants/marketplace'
import { useTranslation } from 'shared/hooks'

import style from './InventoryTypeBadge.module.scss'

const inventoryTypes = new Map([
  [INDIE_BRANDS, {
    fullName: 'Independent Brands',
    color: INVENTORY_TYPE_COLORS.get(INDIE_BRANDS),
  }],
  [NAME_BRANDS, {
    fullName: 'Name Brands',
    color: INVENTORY_TYPE_COLORS.get(NAME_BRANDS),
  }],
  [TRENDY_ITEMS, {
    fullName: 'Trendy Items',
    color: INVENTORY_TYPE_COLORS.get(TRENDY_ITEMS),
  }],
  [ALIEXPRESS, {
    fullName: 'AliExpress',
    color: INVENTORY_TYPE_COLORS.get(ALIEXPRESS),
  }],
  [CBD, {
    fullName: 'CBD',
    color: INVENTORY_TYPE_COLORS.get(CBD),
  }],
  [ALIBABA, {
    fullName: 'Alibaba',
    color: INVENTORY_TYPE_COLORS.get(ALIBABA),
  }],
  [BEAUTY, {
    fullName: 'Beauty',
    color: INVENTORY_TYPE_COLORS.get(BEAUTY),
  }],
  [PRINT_ON_DEMAND, {
    fullNameTrans: 'podProduct.supplierNamePod.label',
    color: INVENTORY_TYPE_COLORS.get(PRINT_ON_DEMAND),
  }]
])

const InventoryTypeBadge = ({ id, className, testId }) => {
  const data = inventoryTypes.get(id)

  const { t } = useTranslation('productPage')
  if (!data) return null

  return (
    <Typography
      variant="body2"
      style={{ color: data.color }}
      className={clsx(style.InventoryTypeBadge, className)}
      data-testid={testId}
    >
      {data.fullNameTrans ? t(data.fullNameTrans) : data.fullName}
    </Typography>
  )
}

InventoryTypeBadge.propTypes = {
  id: PropTypes.string.isRequired,
  className: PropTypes.string,
  testId: PropTypes.string,
}

InventoryTypeBadge.defaultProps = {
  className: ''
}

export default observer(InventoryTypeBadge)
