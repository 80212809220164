import pickBy from 'lodash/pickBy'
import { action, computed } from 'mobx'
import { v4 as uuid4 } from 'uuid'

import { logger as _logger } from 'shared/utils/debug'

import { getWixBiLogger } from './utils'

/**
 * @typedef {import('@wix/bi-logger-modalyst/v2/types').LogObject} LogObject
 */

/**
 * Builder/processor for log objects returned by a function generated from BI schema
 */
class BiEventBuilder {
  /** @type {Map<Number, Array<String>} */
  paramsMap

  constructor (paramsMap) {
    this.paramsMap = paramsMap
  }

  /**
   * @param {LogObject} logObject as returned by a bi-logger-modalyst function
   */
  from (logObject) {
    const { evid, params, ...rest } = logObject
    this.evid = evid
    this.params = params
    this.rest = rest
    return this
  }

  stripExtraParams () {
    const evParams = [...this.paramsMap.get(this.evid), 'correlationId']
    this.params = pickBy(this.params, (v, k) => (k.startsWith('_') || evParams.includes(k)))
    return this
  }

  ensureCorrelationId () {
    if (!this.params.correlationId) {
      this.params = { ...this.params, correlationId: uuid4() }
    }
    return this
  }

  /**
   * @returns {LogObject} processed LogObject
   */
  build () {
    return { evid: this.evid, params: this.params, ...this.rest }
  }
}

/**
 * Wrapper store for Wix's BI logger
 *
 * Powered by
 *  https://github.com/wix-private/bi-logger
 *  https://github.com/wix-private/bi-schema-loggers
 */
class BiLoggerStore {
  /** @type {import('../index').RootStore} */
  root
  /** @type {Map<Number, Array<String>} */
  paramsMap
  /** @type {BiEventBuilder} */
  eventBuilder

  constructor (root, paramsMap) {
    this.root = root
    this.paramsMap = paramsMap
    this.eventBuilder = new BiEventBuilder(this.paramsMap)
  }

  @computed get isDebug () {
    return this.root.appConfigStore?.biDebug
  }

  @computed get logger () {
    // require userProfileStore and appConfigStore to exist and provide data,
    // otherwise do not return a logger instance
    const userProfileBiData = this.root.userProfileStore?.biEventData
    const isDebug = this.isDebug
    if (userProfileBiData === undefined || isDebug === undefined) return undefined

    // XXX: userProfileBiData is expected to contain all the required common params

    return getWixBiLogger({ isDebug, ...userProfileBiData })
  }

  /**
   * Log event
   * @param {LogObject} logObject
   */
  @action.bound
  async log (logObject) {
    if (!this.logger) throw new Error('BI Logger not initialized')

    const event = this.eventBuilder
      .from(logObject).ensureCorrelationId().stripExtraParams().build()

    if (this.isDebug) _logger.log(`logging BI event #${event.evid}`, event)

    await this.logger.report(event)
    return event
  }
}

export default BiLoggerStore
