import React from 'react'

import PropTypes from 'prop-types'

import { CardContent, Paper, Tab, Tabs, useTheme, useMediaQuery } from '@material-ui/core'
import {
  DescriptionRounded,
  KeyboardReturnRounded,
  LocalShippingRounded,
  ViewListRounded,
} from '@material-ui/icons'
import { observer } from 'mobx-react'

import { ITEM_TABS_ID, TABS } from 'shared/constants/marketplaceItem'
import { useTranslation } from 'shared/hooks'

import {
  MarketplaceItemDescriptionTab,
  MarketplaceItemReturnsTab,
  MarketplaceItemShippingTab,
  MarketplaceItemVariantsTable,
} from 'retailer/components/organisms'
import { MarketplaceItemStore } from 'retailer/stores'

import style from './MarketplaceItemTabs.module.scss'

const MarketplaceItemTabs = ({ store, testId }) => {
  const { t } = useTranslation('productPage')
  const theme = useTheme()
  const lgUp = useMediaQuery(theme.breakpoints.up('lg'))

  const tabs = new Map([
    [TABS.DESCRIPTION, <MarketplaceItemDescriptionTab key={TABS.DESCRIPTION} item={store.item} />],
    [TABS.VARIANTS, <MarketplaceItemVariantsTable key={TABS.VARIANTS} item={store.item} />],
    [TABS.SHIPPING, <MarketplaceItemShippingTab key={TABS.SHIPPING} item={store.item} table={store.shippingTable} />],
    [TABS.RETURNS, <MarketplaceItemReturnsTab key={TABS.RETURNS} item={store.item} />],
  ])

  return (
    <Paper className={style.MarketplaceItemTabs} id={ITEM_TABS_ID}>
      <Tabs
        value={store.selectedTabId}
        onChange={(_, value) => store.selectTab(value)}
        variant={lgUp ? 'standard' : 'fullWidth'}
        indicatorColor="primary"
        textColor="primary"
        centered
      >
        <Tab
          label={t('tabs.description.label')} icon={<DescriptionRounded />} value={TABS.DESCRIPTION}
          data-testid={testId ? `${testId}-descriptionTab` : undefined}
        />
        {!store.item.isCustomizable && <Tab
          label={t('tabs.variants.label')} icon={<ViewListRounded />} value={TABS.VARIANTS}
          data-testid={testId ? `${testId}-variantsTab` : undefined}
                                       />}
        <Tab
          label={t('tabs.shipping.label')} icon={<LocalShippingRounded />} value={TABS.SHIPPING}
          data-testid={testId ? `${testId}-shippingTab` : undefined}
        />
        <Tab
          label={t('tabs.returns.label')} icon={<KeyboardReturnRounded />} value={TABS.RETURNS}
          data-testid={testId ? `${testId}-returnsTab` : undefined}
        />
      </Tabs>
      <CardContent className={style.Content} data-testid={testId ? `${testId}-tabContent` : undefined}>
        {tabs.get(store.selectedTabId)}
      </CardContent>
    </Paper>
  )
}

MarketplaceItemTabs.propTypes = {
  store: PropTypes.instanceOf(MarketplaceItemStore).isRequired,
  testId: PropTypes.string,
}

export default observer(MarketplaceItemTabs)
