import React from 'react'

import PropTypes from 'prop-types'

import { Divider } from '@material-ui/core'
import { observer } from 'mobx-react'

import {
  MarketplaceItemProcessingTimeData, MarketplaceItemReturnsData,
  MarketplaceItemVariantsData, MarketplaceItemProductRating
} from 'retailer/components/organisms'
import { ItemStore } from 'retailer/stores'

import style from './MarketplaceItemData.module.scss'

const MarketplaceItemData = ({ marketplaceItemStore, testId }) => {
  return (
    <div className={style.MarketplaceItemData}>
      <MarketplaceItemProductRating item={marketplaceItemStore.item} />
      <MarketplaceItemVariantsData marketplaceItemStore={marketplaceItemStore} testId={testId} />
      <Divider className={style.Divider} />
      <MarketplaceItemProcessingTimeData marketplaceItemStore={marketplaceItemStore} />
      <Divider className={style.Divider} />
      <MarketplaceItemReturnsData marketplaceItemStore={marketplaceItemStore} />
    </div>
  )
}

MarketplaceItemData.propTypes = {
  marketplaceItemStore: PropTypes.shape({
    item: PropTypes.instanceOf(ItemStore).isRequired
  }).isRequired,
  testId: PropTypes.string,
}

export default observer(MarketplaceItemData)
