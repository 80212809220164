const getProductSourceCounts = products => {
  return products.reduce((stats, product) => {
    if (stats[product.source] === undefined) stats[product.source] = 0
    stats[product.source] += 1
    return stats
  }, {})
}

export {
  getProductSourceCounts,
}
