import React from 'react'

import PropTypes from 'prop-types'

import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'

import { DataModal } from 'shared/components/organisms'
import { CheckMark, SadFace } from 'shared/icons'

import style from './ModalystOrderPaymentCompleteModal.module.scss'

/**
 * Displays confirmation about the completed payment
 *
 * Should be rendered by Route component; expects router to pass props.
 * Assumes the last history entry is the one that triggers the mount and
 * replaces it on exit.
 *
 * @param {object} props
 */
const ModalystOrderPaymentCompleteModal = ({ history, pathOnExit, success }) => {
  const onExited = () => history && history.replace(pathOnExit)

  return (
    <DataModal
      className={style.ModalystOrderPaymentCompleteModal}
      onExited={onExited}
      title="Payment confirmation"
      renderBody={({ dismissModal }) => (
        <div className={style.ModalBodyContent}>
          <Grid container direction="column" alignItems="center" spacing={3}>
            <Grid item>
              {success ? <CheckMark /> : <SadFace />}
            </Grid>
            <Grid item>
              <Typography align="center" color={success ? 'textPrimary' : 'error'}>
                {success
                  ? 'Payment has been successfully completed'
                  : 'Your payment has not been completed'}
              </Typography>
            </Grid>
            <Grid item>
              <Button onClick={dismissModal} color="primary" variant="contained" size="large" align="center">OK</Button>
            </Grid>
          </Grid>
        </div>
      )}
    />
  )
}

ModalystOrderPaymentCompleteModal.propTypes = {
  history: PropTypes.object,
  pathOnExit: PropTypes.string,
  success: PropTypes.bool,
}

ModalystOrderPaymentCompleteModal.defaultProps = {
  pathOnExit: '/orders',
  success: true,
}

export default ModalystOrderPaymentCompleteModal
