const PLAN_YEARLY = 'YEARLY'
const PLAN_MONTHLY = 'MONTHLY'

const LEADTIME_DAYS_1_2 = 0
const LEADTIME_DAYS_3_4 = 1
const LEADTIME_DAYS_5_7 = 2
const LEADTIME_MORE_THAN_WEEK = 3

const LEADTIME_CHOICES = new Map([
  [LEADTIME_DAYS_1_2, '1 - 2 business days'],
  [LEADTIME_DAYS_3_4, '3 - 4 business days'],
  [LEADTIME_DAYS_5_7, '5 - 7 business days'],
  [LEADTIME_MORE_THAN_WEEK, 'More than 1 week'],
])

export {
  PLAN_YEARLY,
  PLAN_MONTHLY,
  LEADTIME_CHOICES,
}
