import React from 'react'

const ThinPlus = () => {
  return (
    <svg viewBox="0 0 7 7" xmlns="http://www.w3.org/2000/svg">
      <path d="M6.708 3.958h-2.75v2.75h-.916v-2.75H.292v-.917h2.75V.292h.916v2.75h2.75v.917Z" />
    </svg>
  )
}

export default ThinPlus
