import React from 'react'

import PropTypes from 'prop-types'

import clsx from 'clsx'
import { observer } from 'mobx-react'

import { Money } from 'shared/components/atoms'

import style from './MoneyRange.module.scss'

const MoneyRange = ({ amountMin, amountMax, nowrap, testId, className, ...MoneyProps }) => {
  const hasMax = amountMin !== amountMax && amountMax !== null && amountMax !== undefined
  return (
    <span className={clsx(style.MoneyRange, nowrap && style.isNoWrap, className)} data-testid={testId}>
      <Money {...MoneyProps} amount={amountMin} testId={testId ? `${testId}-min` : undefined} />
      {hasMax && <span> &ndash; </span>}
      {hasMax && (
        <Money
          {...MoneyProps}
          amount={amountMax}
          currencyDisplay={false}
          testId={testId ? `${testId}-max` : undefined}
        />
      )}
    </span>
  )
}

const amountPropType = PropTypes.oneOfType([PropTypes.number, PropTypes.string])

MoneyRange.propTypes = {
  amountMin: amountPropType,
  amountMax: amountPropType,
  nowrap: PropTypes.bool,
  testId: PropTypes.string,
  ...Money.propTypes
}

export default observer(MoneyRange)
