const PROGRESS_PENDING = 'pending'
const PROGRESS_PARTIAL = 'partial'
const PROGRESS_FULL = 'full'

const DPO_STATUS_NEW = 'new'
const DPO_STATUS_PAYMENT_PENDING = 'payment-pending'
const DPO_STATUS_PENDING = 'pending'
const DPO_STATUS_PAID = 'paid'
const DPO_STATUS_RESERVED = 'reserved'
const DPO_STATUS_RESERVED_CANCELLED = 'reserved-cancelled'
const DPO_STATUS_FULFILLING = 'fulfilling'
const DPO_STATUS_FULFILLED = 'fulfilled'
const DPO_STATUS_REFUNDED = 'refunded'

const INTEGRATION_ORDER_NEW = 'new'
const INTEGRATION_ORDER_RESERVED = 'reserved'
const INTEGRATION_ORDER_CANCELLED = 'cancelled'
const INTEGRATION_ORDER_SUBMITTED = 'submitted'
const INTEGRATION_ORDER_FULFILLED = 'fulfilled'
const INTEGRATION_ORDER_REFUNDED = 'refunded'

const ORDER_STATUS_OPEN = 'open'
const ORDER_STATUS_FULFILLED = 'fulfilled'
const ORDER_STATUS_CANCELLED = 'cancelled'
const ORDER_STATUS_PARTIALLY_CANCELLED = 'partially_cancelled'
const ORDER_STATUS_TO_ORDER = 'to_order'
const ORDER_STATUS_SYNCING = 'syncing'
const ORDER_STATUS_PENDING_PAYMENT = 'pending_payment'
const ORDER_STATUS_PENDING_FULFILLMENT = 'pending_fulfillment'
const ORDER_STATUS_PARTIALLY_FULFILLED = 'partially_fulfilled'

const ACTION_PLACE_ORDER = 'place_order'
const ACTION_MARK_AS_ORDERED = 'mark_as_ordered'
const ACTION_PAY = 'pay'
const ACTION_MARK_AS_PAID = 'mark_as_paid'
const ACTION_MARK_AS_FULFILLED = 'mark_as_fulfilled'
const ACTION_CANCEL = 'cancel'
const ACTION_DOWNLOAD_INVOICE = 'download_invoice'

const ALERT_NO_CPF = 'noCpf'

const ERROR_ACTION_INVALID = 'error_action_invalid'

const SHIPPING_ADDRESS_VALIDATION_RELAXED = 'relaxed'
const SHIPPING_ADDRESS_VALIDATION_STRICT = 'strict'

export {
  PROGRESS_PENDING,
  PROGRESS_PARTIAL,
  PROGRESS_FULL,

  DPO_STATUS_NEW,
  DPO_STATUS_PAYMENT_PENDING,
  DPO_STATUS_PENDING,
  DPO_STATUS_PAID,
  DPO_STATUS_RESERVED,
  DPO_STATUS_RESERVED_CANCELLED,
  DPO_STATUS_FULFILLING,
  DPO_STATUS_FULFILLED,
  DPO_STATUS_REFUNDED,

  INTEGRATION_ORDER_NEW,
  INTEGRATION_ORDER_RESERVED,
  INTEGRATION_ORDER_CANCELLED,
  INTEGRATION_ORDER_SUBMITTED,
  INTEGRATION_ORDER_FULFILLED,
  INTEGRATION_ORDER_REFUNDED,

  ORDER_STATUS_OPEN,
  ORDER_STATUS_FULFILLED,
  ORDER_STATUS_CANCELLED,
  ORDER_STATUS_PARTIALLY_CANCELLED,
  ORDER_STATUS_TO_ORDER,
  ORDER_STATUS_SYNCING,
  ORDER_STATUS_PENDING_PAYMENT,
  ORDER_STATUS_PENDING_FULFILLMENT,
  ORDER_STATUS_PARTIALLY_FULFILLED,

  ACTION_PLACE_ORDER,
  ACTION_MARK_AS_ORDERED,
  ACTION_PAY,
  ACTION_MARK_AS_PAID,
  ACTION_MARK_AS_FULFILLED,
  ACTION_CANCEL,
  ACTION_DOWNLOAD_INVOICE,

  ALERT_NO_CPF,
  ERROR_ACTION_INVALID,

  SHIPPING_ADDRESS_VALIDATION_RELAXED,
  SHIPPING_ADDRESS_VALIDATION_STRICT,
}
