import React from 'react'

import PropTypes from 'prop-types'

import { Typography } from '@material-ui/core'

/** Renders a series of Typography paragraphs based on an array of strings / React nodes */
const Paragraphs = ({ contents, paragraph, ...typographyProps }) => (
  <>
    {contents.map((text, index) => (
      <Typography
        key={index}
        paragraph={paragraph && index < contents.length - 1}
        {...typographyProps}
      >
        {text}
      </Typography>
    ))}
  </>
)

Paragraphs.propTypes = {
  contents: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.node])
  ).isRequired,
  paragraph: PropTypes.bool,
}

Paragraphs.defaultProps = {
  paragraph: true,
}

export default Paragraphs
