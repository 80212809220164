import React from 'react'

import PropTypes from 'prop-types'

import { Button, Grid, Tooltip } from '@material-ui/core'
import { observer } from 'mobx-react'

import { DownloadCloud } from 'shared/icons'

import { DateRangePicker } from 'retailer/components/molecules'
import { DateRangeStore } from 'retailer/stores'
import { useStores } from 'retailer/stores/hooks'

import style from './OrdersTopActions.module.scss'

const OrdersTopActions = ({ dateRangeStore }) => {
  const { ordersStore } = useStores()

  return (
    <div className={style.OrdersTopActions}>
      <Grid
        container
        spacing={2}
        justifyContent="space-between"
      >
        <Grid item>
          <DateRangePicker store={dateRangeStore} />
        </Grid>
        <Grid item>
          <Grid container spacing={2}>
            <Grid item>
              <Tooltip arrow title="Export currently selected orders">
                <Button
                  onClick={() => ordersStore.ordersList.exportOrders()}
                  startIcon={<DownloadCloud />}
                >
                  Export&nbsp;orders
                </Button>
              </Tooltip>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  )
}

OrdersTopActions.propTypes = {
  dateRangeStore: PropTypes.instanceOf(DateRangeStore),
}

export default observer(OrdersTopActions)
