import React from 'react'

import PropTypes from 'prop-types'

import { Checkbox, Chip, Grid, Hidden, IconButton, Link, Paper, Tooltip } from '@material-ui/core'
import {
  AccessTimeRounded,
  DeleteSweepRounded,
  RestoreFromTrashRounded,
  StorefrontRounded
} from '@material-ui/icons'
import clsx from 'clsx'
import { observer } from 'mobx-react'
import { Link as RouterLink } from 'react-router-dom'

import { ModalystLogo, Time } from 'shared/components/atoms'
import { ACTION_RESTORE_TO_IMPORT_LIST, ACTION_RESTORE_TO_SYNC_LIST } from 'shared/constants/archivedRetailerItems'
import { getIntegrationIcon } from 'shared/utils/integrations'

import { ArchivedItemStore } from 'retailer/stores'

import style from './ArchiveListItem.module.scss'

const ArchiveListItem = ({ store, className }) => {
  const SourceIcon = getIntegrationIcon(store.source) || ModalystLogo

  const buttons = (
    <>
      <Tooltip title="Restore to Import List">
        <span>
          <IconButton
            disabled={!store.restorable}
            data-testid="archiveList-archiveListProduct-restoreToImportListButton"
            onClick={() => store.parent.restoreItem(store.id, ACTION_RESTORE_TO_IMPORT_LIST)}
          >
            <RestoreFromTrashRounded />
          </IconButton>
        </span>
      </Tooltip>
      <Tooltip title="Restore to Sync List">
        <span>
          <IconButton
            disabled={!store.restorable}
            data-testid="archiveList-archiveListProduct-restoreToSyncListButton"
            onClick={() => store.parent.restoreItem(store.id, ACTION_RESTORE_TO_SYNC_LIST)}
          >
            <StorefrontRounded />
          </IconButton>
        </span>
      </Tooltip>
    </>
  )

  return (
    <Paper
      variant="outlined" className={clsx(style.ArchiveListItem, store.selected && style.marked, className)}
      data-testid="archiveList-archiveListProduct"
    >
      <Grid container spacing={4} alignItems="center">
        <Grid item xs={12} sm="auto" className={style.Checkbox}>
          <Grid container spacing={2} alignItems="center" justifyContent="space-between">
            <Grid item>
              <Checkbox
                color="primary"
                data-testid="archiveList-archiveListProduct-selectProductCheckbox"
                checked={store.selected}
                onChange={() => store.setSelected(!store.selected)}
                disabled={!store.restorable}
              />
            </Grid>
            <Hidden smUp>
              <Grid item>
                {buttons}
              </Grid>
            </Hidden>
          </Grid>
        </Grid>
        <Grid item xs={12} sm>
          <Grid container spacing={4} alignItems="center" wrap="wrap">
            <Grid item xs={12} lg className={style.ProductDetails}>
              <img src={store.image} alt="" className={clsx(style.Image, style[store.source])} />
              <div className={style.InventoryType}>
                <SourceIcon width={20} height={20} />
              </div>
              <div className={style.Product}>
                {store.itemUuid && (
                  <Link
                    component={RouterLink}
                    to={`/marketplace/${store.itemUuid}`}
                    target="_blank"
                    rel="opener"
                    className={style.ProductName}
                    data-testid="archiveList-archiveListProduct-productName"
                  >
                    {store.name}
                  </Link>
                )}
                {!store.itemUuid && (
                  <span className={style.ProductName} data-testid="archiveList-archiveListProduct-productName">
                    {store.name}
                  </span>
                )}
                <span className={style.BrandName} data-testid="archiveList-archiveListProduct-vendorName">
                  {store.readableVendor}
                </span>
              </div>
            </Grid>
            <Grid item xs={12} sm={6} lg="auto" className={style.Badges}>
              <Chip
                data-testid="archiveList-archiveListProduct-productRemovedTime"
                size="small"
                label={<Time value={store.created} format="MMMM DD, YYYY, hh:mm a" />}
                icon={<AccessTimeRounded />}
                variant="outlined"
                color="primary"
              />
              <Chip
                data-testid="archiveList-archiveListProduct-productRemovalReason"
                size="small"
                label={store.removalReasonReadable}
                icon={<DeleteSweepRounded />}
                variant="outlined"
                color="secondary"
              />
            </Grid>
            <Hidden xsDown>
              <Grid item xs={12} sm={6} lg="auto" className={style.Actions}>
                {buttons}
              </Grid>
            </Hidden>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  )
}

ArchiveListItem.propTypes = {
  store: PropTypes.instanceOf(ArchivedItemStore).isRequired,
  className: PropTypes.string,
}

ArchiveListItem.defaultProps = {
  className: ''
}

export default observer(ArchiveListItem)
