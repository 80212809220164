import { useEffect, useMemo, useState } from 'react'

import merge from 'lodash/merge'
import { useTranslation as useTranslationBase } from 'react-i18next'

/**
 * A custom useTranslation hook that handles namespaces and key prefixes nicely.
 * @param {string} namespace
 * @param {Object} options
 * @returns {UseTranslationResponse<DefaultNamespace, string>}
 */
function useTranslation (namespace, options = {}) {
  options = Object.assign({ keyPrefix: `modalyst.${namespace}` }, options)
  return useTranslationBase(namespace, options)
}

/**
 * A custom hook to set up a mutation observer on the given target, and run `onMutation` callback on each mutation.
 * The observer can be parametrized by padding `observerOptions` object.
 * Inspired by: https://blog.logrocket.com/guide-to-custom-react-hooks-with-mutationobserver/
 *
 * Usage:
 * const element = <div>hello</div>
 * const mutationObserver = useCallback(mutationList => { ... }, [])
 * useMutationObserver(element, mutationObserver)
 *
 * @param {Node}      target            DOM element to run mutation observer on
 * @param {function}  onMutation        A callback that will be run and receive the mutation list
 * @param {Object}    observerOptions   Optional options to parametrize the mutation observer
 */
function useMutationObserver (target, onMutation, observerOptions = {}) {
  const [observer, setObserver] = useState(null)

  const options = merge({
    childList: true,
  }, observerOptions)

  useEffect(() => {
    if (target) setObserver(new MutationObserver(onMutation))
    return () => {
      if (observer) observer.disconnect()
    }
  }, [target, onMutation])

  useEffect(() => {
    if (observer) observer.observe(target, options)
  }, [observer, target])
}

function useDeepCompareMemo (factory, deps) {
  const JsonDeps = deps.map(dep => JSON.stringify(dep))
  return useMemo(factory, JsonDeps)
}

export {
  useMutationObserver,
  useTranslation,
  useDeepCompareMemo,
}
