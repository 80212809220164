import React, { useRef } from 'react'

import PropTypes from 'prop-types'

import { yupResolver } from '@hookform/resolvers/yup'
import { Grid, TextField } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { observer } from 'mobx-react'
import { useSnackbar } from 'notistack'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'

import { CardForm } from 'shared/components/organisms'
import { getFieldErrorMessage, useRemoteErrors } from 'shared/utils/forms'

import { SocialMediaSettingsStore } from 'retailer/stores'

import style from './StoreSocialMediaForm.module.scss'

const validationSchema = yup.object().shape({
  facebook: yup.string()
    .max(255, 'The URL cannot be longer than 255 characters')
    .matches(
      /((http|https):\/\/)(?:www.)?(facebook|fb).com\/([A-Za-z0-9\-_]+)\/?/,
      {
        message: 'Provide a valid Facebook URL',
        excludeEmptyString: true
      }
    )
    .nullable(),
  instagram: yup.string()
    .max(255, 'The URL cannot be longer than 255 characters')
    .matches(
      /((http|https):\/\/)(?:www.)?(instagram).com\/([A-Za-z0-9\-_]+)\/?/,
      {
        message: 'Provide a valid Instagram URL',
        excludeEmptyString: true
      }
    )
    .nullable(),
  website: yup.string()
    .max(255, 'The URL cannot be longer than 255 characters')
    .url('Provide a valid website URL')
    .nullable()
})

const StoreSocialMediaForm = ({ store }) => {
  const ref = useRef(null)
  const { enqueueSnackbar } = useSnackbar()
  const { handleSubmit, formState, reset, errors, register, setError } = useForm({
    defaultValues: {
      facebook: store.facebook,
      instagram: store.instagram,
      website: store.website
    },
    resolver: yupResolver(validationSchema)
  })
  const { nonFieldErrors } = useRemoteErrors(store.errors, setError)

  const onSubmit = data => {
    store.save(data).then(() => {
      reset({
        facebook: store.facebook,
        instagram: store.instagram,
        website: store.website
      })
    })
    enqueueSnackbar('Social media saved successfully', {
      variant: 'success'
    })
  }

  return (
    <CardForm
      formRef={ref}
      title="Social Media"
      subheader="Share your social media accounts to increase engagement with suppliers"
      canSubmit={formState.isDirty && !Object.keys(errors).length}
      isSubmitting={store.isSaving}
      onDiscard={formState.isDirty ? () => reset() : null}
    >
      {nonFieldErrors && (
        <Alert severity="error" className={style.Errors}>
          {nonFieldErrors.join(' ')}
        </Alert>
      )}
      <Grid
        ref={ref}
        component="form"
        container
        spacing={2}
        className={style.StoreSocialMediaForm}
        onSubmit={handleSubmit(onSubmit)}
      >
        <Grid item xs={12}>
          <TextField
            id="facebook" name="facebook" label="Facebook" variant="outlined"
            placeholder="https://facebook.com/username" fullWidth
            inputRef={register()} error={!!errors.facebook}
            helperText={getFieldErrorMessage(errors, 'facebook')}
            inputProps={{ type: 'url' }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            id="instagram" name="instagram" label="Instagram" variant="outlined"
            placeholder="https://instagram.com/username" fullWidth
            inputRef={register()} error={!!errors.instagram}
            helperText={getFieldErrorMessage(errors, 'instagram')}
            inputProps={{ type: 'url' }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            id="website" name="website" label="Website" variant="outlined"
            placeholder="https://your-website.com" fullWidth
            inputRef={register()} error={!!errors.website}
            helperText={getFieldErrorMessage(errors, 'website')}
            inputProps={{ type: 'url' }}
          />
        </Grid>
      </Grid>
    </CardForm>
  )
}

StoreSocialMediaForm.propTypes = {
  store: PropTypes.instanceOf(SocialMediaSettingsStore).isRequired
}

export default observer(StoreSocialMediaForm)
