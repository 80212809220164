/* eslint camelcase: 0 */
/**
 * Base settings for all the MUI themes defined in the system.
 * Please use it only for application-agnostic, system-wide stuff.
 */

import { unstable_createMuiStrictModeTheme } from '@material-ui/core/styles'

// XXX: unstable_createMuiStrictModeTheme fixes issues with MUI and Strict Mode in **some** cases
//      see https://github.com/mui-org/material-ui/issues/13394
//      should be fixed after upgrade to v5

const baseTheme = unstable_createMuiStrictModeTheme({
  direction: 'ltr', // RTL option
  breakpoints: {
    values: {
      xs: 0,
      sm: 576,
      md: 768,
      lg: 992,
      xl: 1200,
      '2xl': 1420,
    },
  },
})

export default baseTheme
