import React from 'react'

import PropTypes from 'prop-types'

import { Zoom, useTheme, Box, alpha } from '@material-ui/core'
import { Check } from '@material-ui/icons'
import clsx from 'clsx'

import style from './PoppingBadge.module.scss'

/**
 * A check badge that pops up in the top-right corner of the container element.
 * For proper results, parent must have:
 *  - non-static positioning
 *  - visible overflow
 *
 * TODO: now that the component also includes the outline, it should probably be renamed...
 * */
const PoppingBadge = ({ in: inProp, testId, ContainerProps, ...props }) => {
  const theme = useTheme()
  const { className: _containerClassName, ...cPropsRest } = ContainerProps || {}
  const containerClassName = clsx(style.Container, inProp && style.ContainerIn, _containerClassName)
  return (

    <Box
      {...cPropsRest}
      className={containerClassName}
      style={{ outlineColor: alpha(theme.palette.primary.main, 0.5) }}
    >
      <Zoom in={inProp} mountOnEnter appear={false}>
        <Box
          position="absolute" top={-8} right={-8}
          color={theme.palette.primary.contrastText} bgcolor={theme.palette.primary.main}
          padding={0.25} borderRadius="100%"
          {...props}
        >
          <Check style={{ display: 'block', fontSize: '16px' }} data-testid={testId} />
        </Box>
      </Zoom>
    </Box>
  )
}
PoppingBadge.propTypes = {
  in: PropTypes.bool,
  ContainerProps: PropTypes.object,
  testId: PropTypes.string,
}

PoppingBadge.defaultProps = {
  in: false,
}

export default PoppingBadge
