/* eslint camelcase: 0 */

const { keysToCamelCase } = require('shared/utils/api')

const adaptPostApprovalRequestResponse = keysToCamelCase

const adaptPostApprovalRequestErrors = ({ non_field_errors, ...fieldErrors }) => ({
  nonFieldErrors: non_field_errors,
  fieldErrors: keysToCamelCase(fieldErrors),
})

export {
  adaptPostApprovalRequestResponse,
  adaptPostApprovalRequestErrors,
}
