import pick from 'lodash/pick'
import { action, computed, observable, reaction } from 'mobx'
import qs from 'query-string'

import { adaptGetProductCountersResponse, adaptGetProductsResponse, adaptProduct } from 'shared/adapters/products'
import {
  getProducts,
  setProductsCategory as apiSetProductsCategory,
  setProductsActive,
  getProductsExportUrl,
  postResyncProducts,
  postUnsyncProducts,
  getProductCounters,
} from 'shared/api/products'
import { BaseListStore } from 'shared/stores'

import { ALL } from 'supplier/constants'
import {
  ACTION_CHANGE_CATEGORY,
  ACTION_PUBLISH,
  ACTION_RESYNC,
  ACTION_UNPUBLISH,
  ACTION_UNSYNC,
  PRODUCT_COLLECTION_TITLES,
  PRODUCT_FILTER_INVALID,
  PRODUCT_FILTER_PUBLISHABLE,
  PRODUCT_FILTER_PUBLISHED,
  PRODUCT_FILTER_SYNC_FAILED,
  PRODUCT_FILTER_UNPUBLISHED,
  PRODUCT_FILTER_UNSYNCED,
  PRODUCT_LIST_PATHNAME_REGEX,
  PRODUCT_VIEWGROUP_ADD_PRODUCTS,
  PRODUCT_VIEWGROUP_MY_PRODUCTS,
} from 'supplier/constants/products'
import { ProductStore } from 'supplier/stores'

class ProductListStore extends BaseListStore {
  /** @type {import('../root').RootStore} */
  root
  pathnameRegex = PRODUCT_LIST_PATHNAME_REGEX

  @observable counters

  listParamsGetter = () => {
    const { filter, statusInIntegration, query, offset } = this
    return { filter, query, statusInIntegration, offset }
  }

  /**
   * @param {import('../root').RootStore} root
   */
  constructor (root, data = {}) {
    super(root.routerStore, data)
    this.root = root
    reaction(this.listParamsGetter, this.listParamsReaction)
  }

  @computed
  get viewGroup () {
    return this.pathnameMatch?.groups.viewgroup
  }

  @computed
  get title () {
    return PRODUCT_COLLECTION_TITLES.get(this.filter) || 'My products'
  }

  @computed
  get filterOptions () {
    switch (this.viewGroup) {
      case PRODUCT_VIEWGROUP_ADD_PRODUCTS:
        return new Map([
          [PRODUCT_FILTER_INVALID, 'Incomplete (action required)'],
          [PRODUCT_FILTER_SYNC_FAILED, 'Products failed to sync'],
          [PRODUCT_FILTER_UNSYNCED, 'Unsynced products'],
        ])
      case PRODUCT_VIEWGROUP_MY_PRODUCTS:
        return new Map([
          [PRODUCT_FILTER_PUBLISHABLE, 'Published and unpublished products'],
          [PRODUCT_FILTER_PUBLISHED, 'Published'],
          [PRODUCT_FILTER_UNPUBLISHED, 'Unpublished'],
        ])
      default: return new Map([])
    }
  }

  @computed
  get filter () {
    return this.pathnameMatch?.groups.filter
  }

  @computed
  get statusInIntegration () {
    const params = new URLSearchParams(this.root.routerStore?.location.search)
    return params.get('statusInIntegration') || undefined
  }

  @computed
  get query () {
    const params = new URLSearchParams(this.root.routerStore?.location.search)
    return params.get('query') || undefined
  }

  @action.bound
  setFilters (filter, statusInIntegration, query) {
    this.root.routerStore.push(this.getUrl({ filter, statusInIntegration, query, offset: 0 })) // offset reset added for verbosity
  }

  /** Params that make up the current collection (across all pages) */
  @computed get collectionParams () {
    return pick(this, ['filter', 'statusInIntegration', 'query'])
  }

  @computed get availableActions () {
    switch (this.filter) {
      case PRODUCT_FILTER_INVALID:
        return [ACTION_CHANGE_CATEGORY, ACTION_UNSYNC]
      case PRODUCT_FILTER_PUBLISHABLE:
        return [ACTION_CHANGE_CATEGORY, ACTION_PUBLISH, ACTION_UNPUBLISH, ACTION_UNSYNC]
      case PRODUCT_FILTER_PUBLISHED:
        return [ACTION_CHANGE_CATEGORY, ACTION_UNPUBLISH, ACTION_UNSYNC]
      case PRODUCT_FILTER_UNPUBLISHED:
        return [ACTION_CHANGE_CATEGORY, ACTION_PUBLISH, ACTION_UNSYNC]
      case PRODUCT_FILTER_SYNC_FAILED:
        return []
      case PRODUCT_FILTER_UNSYNCED:
        return [ACTION_CHANGE_CATEGORY, ACTION_RESYNC]
      default:
        return [ACTION_CHANGE_CATEGORY, ACTION_PUBLISH, ACTION_UNPUBLISH, ACTION_RESYNC, ACTION_UNSYNC]
    }
  }

  @computed get mainAction () {
    switch (this.filter) {
      case PRODUCT_FILTER_PUBLISHABLE:
      case PRODUCT_FILTER_PUBLISHED:
      case PRODUCT_FILTER_UNPUBLISHED:
        return ACTION_PUBLISH
      case PRODUCT_FILTER_UNSYNCED: return ACTION_RESYNC
      default: return null
    }
  }

  @action.bound
  updateItems (data) {
    data.forEach(updated => {
      const updatedIndex = this.items.findIndex(item => item.uuid === updated.uuid)
      if (updatedIndex > -1) {
        this.items[updatedIndex] = new ProductStore(adaptProduct(updated))
      }
    })
  }

  // BEGIN set products active status

  _setProductsActive (payload, params) {
    return this.performBulkAction(setProductsActive(payload, params))
  }

  /** Toggle active status for a single item */
  @action.bound
  toggleProductActive (product) {
    const payload = { value: !product.active, products: [product.uuid] }
    return this._setProductsActive(payload)
  }

  @action.bound
  setSelectedProductsActive (value) {
    const payload = {
      value,
      products: this.allOnAllPagesSelected ? undefined : this.selectedIds
    }
    return this._setProductsActive(payload, this.collectionParams)
  }

  // BEGIN set product categories

  _setCategory (payload, params) {
    return this.performBulkAction(apiSetProductsCategory(payload, params))
  }

  /** Sets category for specified products within the current collection */
  @action.bound
  setProductsCategory (category, products) {
    const payload = {
      category: category.uuid,
      products: products === ALL ? undefined : products.map(product => product.uuid)
    }
    return this._setCategory(payload, this.collectionParams)
  }

  // BEGIN unsync/resync

  _resyncProducts (payload, params) {
    return this.performBulkAction(postResyncProducts(payload, params))
  }

  @action.bound
  resyncProducts (products) {
    const payload = { products: products.map(product => product.uuid) }
    return this._resyncProducts(payload, this.collectionParams)
  }

  @action.bound
  resyncSelectedProducts () {
    const payload = { products: this.allOnAllPagesSelected ? undefined : this.selectedIds }
    return this._resyncProducts(payload, this.collectionParams)
  }

  @action.bound
  unsyncSelectedProducts () {
    const payload = { products: this.allOnAllPagesSelected ? undefined : this.selectedIds }
    return this.performBulkAction(postUnsyncProducts(payload, this.collectionParams))
  }

  getUrl (params) {
    const { filter, statusInIntegration, query, offset } = params
    const search = qs.stringify({ query, statusInIntegration, offset: offset || undefined })
    return `/${this.viewGroup}/${filter}` + (search ? `?${search}` : '')
  }

  doFetch () {
    const { filter, statusInIntegration, query, offset } = this
    return getProducts({ filter, statusInIntegration, query, offset })
      .then(response => {
        const results = adaptGetProductsResponse(response.data).results
        this.setItems(results.map(row => new ProductStore(row)))
        return response
      })
  }

  /** The description of what will be exported */
  @computed get exportedCollectionDescription () {
    return (this.filter || this.statusInIntegration || this.query) ? 'the results' : 'all products'
  }

  @action.bound
  exportProducts (format, ids) {
    window.open(getProductsExportUrl({ ...this.collectionParams, format, ids }))
  }

  @action.bound
  exportSelectedProducts (format) {
    const ids = this.allOnAllPagesSelected ? undefined : this.selectedIds
    this.exportProducts(format, ids)
    this.setAllSelected(false)
  }

  /** Export all products in users inventory (including those outside current filters) */
  @action.bound
  exportAllProducts (format) {
    window.open(getProductsExportUrl({ format }))
  }

  @action.bound
  setCounters (data) {
    this.counters = data
  }

  @action.bound
  fetchCounters () {
    // TODO: call when initial import finished / updated; check other possible usages
    getProductCounters()
      .then(response => {
        this.setCounters(adaptGetProductCountersResponse(response.data))
      })
  }
}

export default ProductListStore
