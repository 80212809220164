import React from 'react'

import { Box, CircularProgress } from '@material-ui/core'

const FullPageSpinner = () => {
  return (
    <Box
      height="100vh"
      display="flex" flexDirection="column" alignItems="center" justifyContent="center"
    >
      <CircularProgress size={56} />
    </Box>
  )
}

export default FullPageSpinner
