import React from 'react'

import PropTypes from 'prop-types'

import {
  Button,
  Checkbox,
  FormControlLabel,
  Typography
} from '@material-ui/core'
import { observer } from 'mobx-react'

import { DataModal } from 'shared/components/organisms'
import { integrationsNames } from 'shared/constants/integrations'

import { ExternalOrderPaymentStore, UserProfileStore } from 'retailer/stores'

import style from './ExternalOrderPaymentModal.module.scss'

const ModalBody = observer(({ store, showSpinner }) => {
  const handleChange = (event) => {
    store.setDoNotShowAgain(event.target.checked)
  }
  const integrationName = integrationsNames.get(store.order.orderType)
  return (
    <>
      {store.order.is3rdPartySupplierOrder
        ? (
          <>
            <Typography gutterBottom>
              Modalyst has a partnership with {integrationName} for this product. To pay for the order,
              you will be redirected to {integrationName}.
            </Typography>
            <Typography>
              If you have multiple {integrationName} accounts, you must be signed into the account
              which you have integrated with Modalyst to pay for the order.
            </Typography>
          </>
          )
        : (
          <>
            <Typography gutterBottom>
              To pay for this order, we will redirect you to our partner.
              Once payment is accepted, the order will be marked as paid on Modalyst.
            </Typography>
            <Typography>
              The tracking number will be provided and synced
              to your store when the order has been shipped.
            </Typography>
          </>)}
      <FormControlLabel
        disabled={showSpinner}
        checked={store.doNotShowAgain}
        onChange={handleChange}
        className={style.DoNotShowAgainLabel}
        control={<Checkbox color="primary" />}
        label="I understand. Do not show this information again."
      />
    </>
  )
})

ModalBody.propTypes = {
  store: PropTypes.instanceOf(ExternalOrderPaymentStore).isRequired,
  showSpinner: PropTypes.bool
}

ModalBody.defaultProps = {
  showSpinner: false
}

const ExternalOrderPaymentModal = ({ modalStore, userProfileStore, onExited }) => {
  const handleConfirmation = () => {
    if (!modalStore.doNotShowAgain) return
    return modalStore.confirmExternalOrderPayment().then(() => {
      userProfileStore.markExternalOrderPaymentConfirmed(modalStore.confirmationOrderType)
    })
  }

  const handlePayButtonClick = (action) => {
    const confirmation = handleConfirmation()
    if (confirmation) {
      confirmation.then(() => action())
    } else action()
  }

  return (
    <DataModal
      className={style.ExternalOrderPaymentModal}
      title="You will be redirected to pay for this order"
      onExited={onExited}
      renderBody={({ showSpinner }) => (
        <ModalBody store={modalStore} showSpinner={showSpinner} />
      )}
      renderActions={({ dismissModal, showSpinner }) => (
        <>
          <Button
            disabled={showSpinner}
            onClick={dismissModal}
            color="primary"
            size="large"
          >
            Cancel
          </Button>
          <Button
            disabled={showSpinner}
            onClick={() => handlePayButtonClick(dismissModal)}
            href={modalStore.order.checkoutUrl}
            target="_blank"
            color="primary"
            variant="contained"
            size="large"
          >
            Pay for Order
          </Button>
        </>
      )}
    />
  )
}

ExternalOrderPaymentModal.propTypes = {
  modalStore: PropTypes.instanceOf(ExternalOrderPaymentStore).isRequired,
  userProfileStore: PropTypes.instanceOf(UserProfileStore).isRequired,
  onExited: PropTypes.func,
}

ExternalOrderPaymentModal.defaultProps = {
  onExited: () => null
}

export default observer(ExternalOrderPaymentModal)
