import { api } from 'shared/api/common'

const baseUrl = '/api/v3/supplier/approval_requests'

function postApprovalRequest () {
  return api.post(`${baseUrl}/`)
}

export {
  postApprovalRequest
}
