import React, { useMemo } from 'react'

import PropTypes from 'prop-types'

import { Box, Breadcrumbs, Link, Typography } from '@material-ui/core'
import { ChevronRight } from '@material-ui/icons'

import { getTreePath } from 'shared/utils'

import CustomPropTypes from 'retailer/propTypes'

import style from './MarketplaceBreadcrumbs.module.scss'

const Crumb = ({ title, onClick }) => {
  const Component = onClick ? Link : Typography
  const color = onClick ? 'textPrimary' : 'textSecondary'
  return (
    <Component color={color} onClick={onClick} variant="inherit" data-testid="MarektplaceBreadcrumbs-crumb">
      {title}
    </Component>
  )
}

Crumb.propTypes = {
  title: PropTypes.string.isRequired,
  onClick: PropTypes.func,
}

const MarketplaceBreadcrumbs = ({ category, onClick, ...boxProps }) => {
  const path = useMemo(() => {
    return getTreePath(category?.parent).reverse()
  }, [category])

  return (
    <Box className={style.MarketplaceBreadcrumbs} {...boxProps}>
      <Breadcrumbs
        aria-label="breadcrumb"
        separator={<ChevronRight fontSize="small" />}
        variant="inherit"
        data-testid="MarketplaceBreadcrumbs"
      >
        <Crumb key={0} title="All Products" onClick={() => onClick(null)} />
        {path.map(cat => (
          <Crumb key={cat.uuid} title={cat.name} onClick={() => onClick(cat)} />
        ))}
        {category && <Crumb key={category.uuid} title={category.name} />}
      </Breadcrumbs>
    </Box>
  )
}

MarketplaceBreadcrumbs.propTypes = {
  category: CustomPropTypes.categoryTree,
  onClick: PropTypes.func.isRequired,
}

MarketplaceBreadcrumbs.defaultProps = {

}

export default MarketplaceBreadcrumbs
