import React from 'react'

import PropTypes from 'prop-types'

import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab'
import { observer } from 'mobx-react'

import style from './SubscriptionPeriodSwitcher.module.scss'

const SubscriptionPeriodSwitcher = ({ values, selectedValue, onChange }) => {
  /**
   * Only run change handler if an actually value was selected. This effectively enforces the toggle
   * to always have one value selected and makes it impossible to deselect an option.
   */
  const handleChange = (event, value) => {
    if (value) onChange(value)
  }

  return (
    <ToggleButtonGroup
      value={selectedValue}
      onChange={handleChange}
      aria-label="Choose subscription period"
      exclusive
    >
      {values.map(option => {
        const [key, title] = option
        const testid = `subscriptionPeriodSwitcher-switchTo${title}Button`
        return (
          <ToggleButton
            value={key}
            key={key}
            className={style.Button}
            data-testid={testid}
          >
            {title}
          </ToggleButton>
        )
      })}
    </ToggleButtonGroup>
  )
}

SubscriptionPeriodSwitcher.propTypes = {
  values: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)).isRequired,
  selectedValue: PropTypes.string,
  onChange: PropTypes.func.isRequired
}

export default observer(SubscriptionPeriodSwitcher)
