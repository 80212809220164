import React, { forwardRef } from 'react'

import PropTypes from 'prop-types'

import { Chip } from '@material-ui/core'
import clsx from 'clsx'

import style from './StatusChip.module.scss'

const STATUS_STYLES = {
  error: style.isError,
  warning: style.isWarning,
  success: style.isSuccess,
  default: style.isDefault,
}

const StatusChip = forwardRef(({ status, ghosted, className, ...props }, ref) => (
  <Chip
    ref={ref}
    className={clsx(
      style.StatusChip,
      ghosted && style.isGhosted,
      STATUS_STYLES[status],
      className,
    )}
    {...props}
  />
))

StatusChip.propTypes = {
  status: PropTypes.oneOf(Object.keys(STATUS_STYLES)),
  ghosted: PropTypes.bool,
  className: PropTypes.string,
  ...Chip.propTypes,
}

StatusChip.defaultProps = {
  ghosted: false,
}

export default StatusChip
