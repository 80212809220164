import { action, computed, observable } from 'mobx'

import {
  IMPORT_COMPLETED,
  IMPORT_FAILED,
  IMPORT_PENDING,
  IMPORT_PENDING_MESSAGE,
  IMPORT_STARTED,
} from 'shared/constants/imports'

class ImportRunStore {
  @observable numRecords
  @observable numRecordsProcessed
  @observable eta
  @observable status

  constructor (data = {}) {
    this.update(data)
  }

  @action.bound
  update (data) {
    Object.assign(this, data)
  }

  @computed get timeLeft () {
    if (!Number.isInteger(this.eta)) return undefined
    return this.eta > 60
      ? `${Math.round(this.eta / 60)} minute(s)`
      : `${Math.round(this.eta)} second(s)`
  }

  /** Completion percent */
  @computed get itemsProcessedPercent () {
    if (!this.numRecords || this.status === IMPORT_PENDING) return 0
    if (this.status === IMPORT_COMPLETED) return 100
    return Math.round(100 * this.numRecordsProcessed / this.numRecords)
  }

  @computed get statusMessage () {
    switch (this.status) {
      case IMPORT_PENDING: return IMPORT_PENDING_MESSAGE
      case IMPORT_STARTED: return 'Products are now being imported.'
      case IMPORT_COMPLETED: return 'Product import has been completed.'
      case IMPORT_FAILED: return 'Product import has failed.'
      default: return undefined
    }
  }
}

export default ImportRunStore
