import axios from 'axios'
import Cookie from 'js-cookie'

import { CURRENT_STORE_KEY } from 'retailer/constants'

const config = {
  baseURL: process.env.REACT_APP_API_URL,
  // For development builds, send all cookies with each request (incl. GET).
  // Note that for cross domain requests the credentials need to be allowed in
  // the backend's CORS config. This allows for cross-domain authentication in
  // development mode.
  withCredentials: process.env.NODE_ENV === 'development' || undefined,
  headers: {
    // Get current store ID from session storage on each request
    CurrentStoreId: {
      toString () {
        return sessionStorage.getItem(CURRENT_STORE_KEY) || ''
      }
    },
    // Get CSRF token from the cookie on each request and
    // properly send as header.
    'X-CSRFToken': {
      toString () {
        return Cookie.get('csrftoken')
      }
    }
  }
}

const api = axios.create(config)

export {
  api,
}
