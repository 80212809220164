import { computed, observable } from 'mobx'

class PricingRangeStore {
  @observable name
  @observable from
  @observable to

  constructor (data) {
    Object.assign(this, data)
  }

  @computed get active () {
    return Boolean(this.from !== undefined && this.from !== null)
  }
}

export default PricingRangeStore
