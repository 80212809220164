import { action, computed, observable } from 'mobx'

import { processApiError } from 'shared/api/utils'
import { LEADTIME_CHOICES } from 'shared/constants/settings'

import { adaptGetWarehouseResponse, adaptPutWarehouseErrors, adaptPutWarehouseRequest } from 'supplier/adapters/warehouses'
import { getWarehouse, putWarehouse } from 'supplier/api/warehouses'
import { WarehouseStore } from 'supplier/stores'

class DestinationZoneStore {
  @observable expanded = false
  @observable.shallow destinations

  constructor (data = {}, destinations = []) {
    this.destinations = destinations
    Object.assign(this, data)
  }

  @computed get selectedDestinations () {
    return this.destinations.filter(destination => !!destination.selected)
  }

  @action.bound
  setExpanded (value) {
    this.expanded = value
  }

  @computed get allFree () {
    return false
  }

  @computed get partFree () {
    return false
  }

  @computed get allSelected () {
    return this.destinations.length && this.selectedDestinations.length === this.destinations.length
  }

  @computed get partSelected () {
    return this.selectedDestinations.length > 0 && this.selectedDestinations.length < this.destinations.length
  }

  @action.bound
  toggleAllSelected () {
    const value = !this.partSelected && !this.allSelected
    this.destinations.forEach(destination => destination.setSelected(value))
  }

  @computed get indexes () {
    return this.destinations.map(obj => obj.index)
  }

  @computed get fieldNames () {
    const objFieldNames = this.destinations.map(obj => obj.fieldNames)
    return Object.fromEntries(Object.keys(objFieldNames[0]).map(
      key => [key, objFieldNames.map(obj => obj[key])]
    ))
  }
}

/**
 * Represents an item in the list of destinations
 */
class DestinationStore {
  /** WarehouseDetails instance */
  parentStore

  @observable index

  /** A shipping country object (NOT the id) */
  @observable shippingCountry

  @observable active
  @observable free
  @observable cost
  @observable costAddon
  @observable shippingTime

  @observable selected = false

  constructor (parentStore, data = {}) {
    this.parentStore = parentStore
    this.update(data)
  }

  @action.bound
  update (data) {
    Object.assign(this, data)
  }

  @action.bound
  setSelected (value) {
    this.selected = value
  }

  @computed get shippingTimesChoices () {
    const shippingTimes = this.parentStore.shippingTimes
    return this.isDomestic ? shippingTimes.filter(obj => obj.domestic) : shippingTimes
  }

  @computed get isDomestic () {
    return this === this.parentStore.domesticDestination
  }

  @computed get maxRates () {
    return this.parentStore.maxRates.find(obj => obj.destinationCountry === this.shippingCountry.id)
  }

  @computed get maxCostRule () {
    if (!this.parentStore.limitShippingRates) return {}

    const value = this.maxRates?.cost
    return value
      ? { max: { value, message: `Max. allowed rate is $${value}` } }
      : {}
  }

  @computed get maxCostAddonRule () {
    if (!this.parentStore.limitShippingRates) return {}

    const value = this.maxRates?.costAddon
    return value
      ? { max: { value, message: `Max. allowed rate for additional items is $${value}` } }
      : {}
  }

  /** Default shipping time *id* for the current origin country and this destination country */
  @computed get defaultShippingTime () {
    return this.maxRates?.shippingTime
  }

  @computed get fieldNames () {
    return {
      shippingCountry: `destinations.${this.index}.shippingCountry`,
      free: `destinations.${this.index}.free`,
      active: `destinations.${this.index}.active`,
      cost: `destinations.${this.index}.cost`,
      costAddon: `destinations.${this.index}.costAddon`,
      shippingTime: `destinations.${this.index}.shippingTime`,
    }
  }

  /** Default shipping rates and time for the current origin country as field name:value pairs */
  @computed get defaultRates () {
    return [
      [this.fieldNames.cost, this.maxRates?.cost],
      [this.fieldNames.costAddon, this.maxRates?.costAddon],
      [this.fieldNames.shippingTime, this.defaultShippingTime],
    ]
  }
}

class WarehouseDetailsStore {
  /** @type {import('../root').RootStore} */
  root

  @observable isLoading
  @observable isSubmitting
  @observable remoteErrors
  @observable isNotFound

  @observable warehouseStore
  @observable originCountry

  /**
   * @param {import('../root').RootStore} root
   */
  constructor (root, data = {}) {
    this.root = root
    this.update(data)
  }

  @action.bound
  update (data) {
    Object.assign(this, data)
  }

  @action.bound
  reset () {
    this.isLoading = undefined
    this.isSubmitting = undefined
    this.remoteErrors = undefined
    this.isNotFound = undefined
    this.warehouseStore = undefined
  }

  @action.bound
  setIsLoading (value) {
    this.isLoading = value
  }

  @action.bound
  setIsSubmitting (value) {
    this.isSubmitting = value
  }

  @action.bound
  setIsNotFound (value) {
    this.isNotFound = value
  }

  @action.bound
  setRemoteErrors (value) {
    this.remoteErrors = value
  }

  @action.bound
  setWarehouseStore (obj) {
    this.warehouseStore = obj
  }

  @action.bound
  setOriginCountry (value) {
    this.originCountry = value
  }

  @computed get shippingTimes () {
    return this.root.appConfigStore.shippingTimes
  }

  @computed get originShippingCountry () {
    return this.root.appConfigStore.shippingCountries.find(obj => obj.countries.includes(this.originCountry))
  }

  @computed get allZonesExpanded () {
    return this.zones.every(zone => zone.expanded)
  }

  @action.bound
  toggleZonesExpanded () {
    const value = !this.allZonesExpanded
    this.zones.forEach(zone => { zone.expanded = value })
  }

  @computed get selectedDestinations () {
    return this.destinations.filter(destination => !!destination.selected)
  }

  /** Options for "Shipping from" field */
  @computed get shippedFromCountries () {
    return this.root.appConfigStore.countriesShippableFrom
  }

  @computed get defaultOriginCountry () {
    const { shippedFrom, shippingCountry, businessCountry } = this.warehouseStore
    return (
      shippedFrom ||
        (shippingCountry.countries.includes(businessCountry)
          ? businessCountry
          : shippingCountry.countries[0])
    )
  }

  /**
   * Form initial values derived from loaded warehouse
   *
   * Mind the source of shippedFrom - this.originCountry should be kept in sync
   * with this.warehouseStore.shippingCountry unless we deliberately change it
   * to user's choice
   */
  @computed get defaultValues () {
    const { shippedFrom, leadTimes, shippingDetails } = this.warehouseStore || {}
    return {
      shippedFrom: shippedFrom || this.defaultOriginCountry || '',
      leadTimes: LEADTIME_CHOICES.has(leadTimes) ? leadTimes : '',
      shippingDetails: shippingDetails || '',
      destinations: this.destinations.map(obj => ({
        shippingCountry: obj.shippingCountry.id || '',
        free: obj.free === undefined ? false : !!obj.free,
        cost: obj.cost || obj.maxRates?.cost || '',
        costAddon: obj.costAddon || obj.maxRates?.costAddon || '',
        shippingTime: obj.shippingTime || obj.defaultShippingTime || '',
        active: obj.active === undefined ? true : !!obj.active,
      }))
    }
  }

  /** Discard changes made to the form
   *
   * In practice, resets the origin country to the value from the loaded
   * warehouse resource. this.defaultValues will re-compute accordingly;
   * reset RHF form to new values after calling this method.
   */
  @action.bound
  discardChanges () {
    this.originCountry = this.defaultOriginCountry
  }

  @action.bound
  fetch (id) {
    this.setIsLoading(true)
    return getWarehouse(id)
      .then(response => {
        const data = adaptGetWarehouseResponse(response.data)
        this.setWarehouseStore(new WarehouseStore(data))
        this.setOriginCountry(this.defaultOriginCountry)
      })
      .catch(error => {
        this.setIsNotFound(error.response && error.response.status === 404)
      })
      .finally(() => {
        this.setIsLoading(false)
      })
  }

  @action.bound
  submit (payload) {
    this.setIsSubmitting(true)
    return putWarehouse('default', adaptPutWarehouseRequest(payload))
      .then(response => {
        const data = adaptGetWarehouseResponse(response.data)
        this.setWarehouseStore(new WarehouseStore(data))
        this.setOriginCountry(data.shippedFrom)
        return data
      })
      .catch(error => {
        const remoteErrors = processApiError(error, adaptPutWarehouseErrors)
        this.setRemoteErrors(remoteErrors)
        throw error
      })
      .finally(() => {
        this.setIsSubmitting(false)
      })
  }

  /**
   * A collection of DestinationStores (destination data + shippingCountry)
  */
  @computed get destinations () {
    if (!this.warehouseStore) return undefined
    return (
      this.root.appConfigStore.shippingCountries
        .map((shippingCountry, index) =>
        /* Caveats:
         * - the form structure is based on the current collection of shipping
         *   countries from the app config, NOT the destinations for
         *   the supplier, as the latter may be a subset or superset of the
         *   former - and we want the user to see the former, as items may be
         *   added or removed globally
         * - when building DestinationStore, shipping country id is replaced
         *   by by respective object from app config
         * - index is passed to DestinationStore and later used to build the
         *   form field names
         */
          new DestinationStore(this, Object.assign(
            { index },
            this.warehouseStore.destinationsMapByCountry.get(shippingCountry.id),
            { shippingCountry }
          )
          ))
    )
  }

  @computed get zones () {
    return this.root.appConfigStore.shippingZones.map(zone => (
      new DestinationZoneStore(
        zone,
        this.destinations.filter(destination => (
          destination.shippingCountry.shippingZone === zone.id &&
          !destination.isDomestic
        ))
      ))
    )
  }

  @computed get limitShippingRates () {
    return !!this.root.appConfigStore.limitShippingRates
  }

  @computed get maxRates () {
    if (!this.warehouseStore) return undefined
    return this.root.appConfigStore.shippingMaxRates
      .filter(rates => rates.originCountry === this.originShippingCountry.id)
  }

  @computed get domesticDestination () {
    if (!this.warehouseStore) return undefined
    return this.destinations.find(obj => obj.shippingCountry.id === this.originShippingCountry.id)
  }
}

export default WarehouseDetailsStore
