/* eslint camelcase: 0 */

import camelCase from 'lodash/camelCase'
import isEmpty from 'lodash/isEmpty'
import omit from 'lodash/omit'

import { adaptImage } from 'shared/adapters/image'
import { keysToCamelCase, keysToSnakeCase } from 'shared/utils/api'

const adaptShippingCountry = (data) => {
  return keysToCamelCase(data)
}

const adaptItemShipping = (data = {}) => {
  const countries = data.countries || []
  return {
    countries: countries.map(country => adaptShippingCountry(country)),
    defaultCountry: adaptShippingCountry(data.default_country),
    selectedCountry: data?.default_country?.country_code
  }
}

const adaptBrand = data => keysToCamelCase(data)

const adaptDesigner = ({ designer_name, lead_times_display, ...rest }) => {
  return {
    ...keysToCamelCase(rest),
    name: designer_name,
    leadTimes: lead_times_display
  }
}

const adaptVariant = ({ uuid, color_name, size_name, ...rest }) => {
  return {
    id: uuid,
    color: color_name,
    size: size_name,
    ...keysToCamelCase(rest)
  }
}

const adaptItem = (data) => {
  return {
    ...keysToCamelCase(data),

    id: data.identifier,
    name: data.name,
    description: data.description,
    sku: data.sku,
    source: data.source,
    inventoryType: camelCase(data.inventory_type),
    category: data.category,
    tags: data.tags,

    available: data.available,
    quantity: data.quantity,
    isPremium: data.is_premium,
    isLimited: data.is_limited,

    designer: adaptDesigner(data.designer),
    brand: adaptBrand(data.brand),
    vendor: data.vendor,

    url: data.url,
    externalUrl: data.external_url,
    externalId: data.external_id,
    customEditorConfig: data.custom_editor_config,
    mainImage: data.image ? adaptImage(data.image) : null,
    lifestyleImage: (data.lifestyle_image && data.lifestyle_image?.uuid) ? adaptImage(data.lifestyle_image) : null,
    images: data.images.map(image => adaptImage(image)),

    maxCost: data.max_cost,
    minCost: data.min_cost,
    maxRetailPrice: data.max_retail_price,
    minRetailPrice: data.min_retail_price,
    maxProfit: data.max_profit,
    minProfit: data.min_profit,
    currency: data.retailer_currency,

    importsCount: data.import_count,
    vendorRating: data.external_vendor_rating || data.designer.fulfillment_stars,
    starRating: data.star_rating,
    reviewCount: data.review_count,

    shipsFrom: data.shipped_from_iso,
    shipping: adaptItemShipping(data.shipping),
    hasFastShipping: data.has_fast_shipping,
    hasFreeShipping: data.has_free_shipping,
    customReturns: data.custom_returns_text,

    variants: data.variants ? data.variants.map(variant => adaptVariant(variant)) : [],
    options: data.options,

    isImported: data.is_imported,
    isExported: data.is_synced,
  }
}

/**
 * Adapts marketplace query params from internal format as used by stores/routing
 * to query params as expected by the API endpoint.
 *
 * @param {Object} params Params in the shape used by the store/routing
 * @param {string} params.categoryId Category UUID
 * @param {string[]} params.inventoryTypes Inventory type codes (get all products from each specified type)
 * @param {string[]} params.brandIds Specific brand UUIDs
 * @param {Map} categoriesMap Supplementary map of categories (by UUID) required for transformations
 * @param {Map} brandsMap Supplementary map of brands (by UUID) required for transformations
 * @param {Map} inventoryTypesMap Supplementary mapping of inventory types to brands
 * @returns {Object}
 */
const adaptGetProductsParams = ({
  categoryId, inventoryTypes, brandIds, shipsFrom, productCostFrom, productCostTo, search, shipping, ...rest
}, categoriesMap, brandsMap, inventoryTypesMap) => {
  const oneBrandInventoryTypes = Array.from(inventoryTypesMap?.entries() || [])
    .filter(([_, brands]) => brands.length === 1)
    .map(([inventoryType, _]) => inventoryType)
  const inventoryTypeBrands = isEmpty(brandIds)
    ? undefined
    : (
        brandIds.reduce((prev, brandId) => {
          const { inventoryType } = brandsMap.get(brandId)
          if (!(inventoryType in prev)) prev[inventoryType] = []
          prev[inventoryType].push(brandId)
          return prev
        }, {})
      )
  const combinedInventoryTypes = [
    ...(inventoryTypes || []),
    ...Object.keys(inventoryTypeBrands || {}),
  ]
  return {
    categories: categoryId ? [categoriesMap.get(categoryId).id] : undefined,
    inventory_type: isEmpty(combinedInventoryTypes) ? undefined : combinedInventoryTypes,
    shipping_origin: shipsFrom,
    brands: brandIds,
    prices: (productCostFrom || productCostTo) ? [parseInt(productCostFrom) || 0, parseInt(productCostTo) || ''].join(',') : undefined,
    keywords: search && search.split(' ').map(value => value.trim()),
    general_shipping_options: shipping,
    ...omit(inventoryTypeBrands, oneBrandInventoryTypes),
    ...keysToSnakeCase(rest),
  }
}

const adaptGetProductsResponse = ({ results, ...meta }) => ({
  results: results.map(row => adaptItem(row)),
  ...keysToCamelCase(meta)
})

const adaptItemShippingTableCountry = ({ shipping_cost, ...rest }) => {
  return {
    shippingCost: shipping_cost ? keysToCamelCase(shipping_cost) : null,
    ...keysToCamelCase(rest)
  }
}

const adaptShippingZone = ({ countries, ...rest }) => {
  return {
    countries: countries.map(country => adaptItemShippingTableCountry(country)),
    ...keysToCamelCase(rest)
  }
}

const adaptItemShippingTable = data => {
  return data.zones.map(zoneData => adaptShippingZone(zoneData))
}

/**
 * Adapts manual order data returned from API.
 * @param {string} item_uuid
 * @param {Object[]} variants
 * @returns {{variants, itemUuid}}
 */
const adaptManualOrder = ({ item_uuid, variants }) => {
  return {
    itemUuid: item_uuid,
    variants: variants.map(variant => keysToCamelCase(variant))
  }
}

/**
 * Adapts manual order data from client to be processed by API.
 * @param {string} itemUuid
 * @param {Object[]} variants
 * @returns {{item_uuid, variants}}
 */
const adaptManualOrderForApi = ({ variants, ...rest }) => {
  return {
    variants: variants.map(variant => ({
      uuid: variant.id,
      quantity: variant.quantityToOrder,
    })),
    ...keysToSnakeCase(rest)
  }
}

const adaptManualOrderErrors = ({ non_field_errors = null, ...rest }) => {
  return {
    nonFieldErrors: non_field_errors,
    fieldErrors: {
      variants: rest?.variants,
      ...keysToSnakeCase(rest),
    }
  }
}

const adaptSetStaffPickResponse = keysToCamelCase

export {
  adaptGetProductsParams,
  adaptGetProductsResponse,
  adaptItem,
  adaptShippingCountry,
  adaptItemShipping,
  adaptDesigner,
  adaptShippingZone,
  adaptItemShippingTable,
  adaptManualOrder,
  adaptManualOrderForApi,
  adaptManualOrderErrors,
  adaptSetStaffPickResponse,
}
