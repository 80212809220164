import React, { useCallback } from 'react'

import PropTypes from 'prop-types'

import { Checkbox, ListItem, ListItemIcon, ListItemText } from '@material-ui/core'
import { observer } from 'mobx-react'
import AutoSizer from 'react-virtualized-auto-sizer'
import { FixedSizeList } from 'react-window'

const CheckboxListItem = observer(({ selectableStore, renderLabel, ...rest }) => {
  const { label, selected, toggle } = selectableStore
  return (
    <ListItem
      role={undefined} dense button disableRipple onClick={toggle}
      data-testid="FiltersDrawerCheckboxList-listItem" {...rest}
    >
      <ListItemIcon>
        <Checkbox
          edge="start" checked={selected} tabIndex={-1} disableRipple
          data-testid="FiltersDrawerCheckboxList-listItem-checkbox"
        />
      </ListItemIcon>
      <ListItemText
        primary={renderLabel ? renderLabel(label) : label}
        data-testid="FiltersDrawerCheckboxList-listItem-text"
      />
    </ListItem>
  )
})

CheckboxListItem.propTypes = {
  selectableStore: PropTypes.shape({
    label: PropTypes.string.isRequired,
    selected: PropTypes.bool.isRequired,
    toggle: PropTypes.func.isRequired,
  }).isRequired,
  renderLabel: PropTypes.func,
}

const FiltersDrawerCheckboxList = ({ options, renderOptionLabel }) => {
  const renderRow = useCallback(({ index, style }) => {
    const store = options[index]
    return (
      <CheckboxListItem
        key={store.id}
        selectableStore={store}
        renderLabel={renderOptionLabel}
        style={style}
      />
    )
  }, [options, renderOptionLabel])

  return (
    <AutoSizer>
      {size => {
        return (
          <FixedSizeList {...size} itemSize={50} itemCount={options.length}>
            {renderRow}
          </FixedSizeList>
        )
      }}
    </AutoSizer>
  )
}

FiltersDrawerCheckboxList.propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.any.isRequired,
  })).isRequired,
  renderOptionLabel: PropTypes.func,
}

export default observer(FiltersDrawerCheckboxList)
