import React from 'react'

import PropTypes from 'prop-types'

import { observer } from 'mobx-react'

import style from './Input.module.scss'

const Input = ({ borderRadius, error, wrapperProps, className, ...props }) => {
  const borderRadiusClasses = new Map([
    ['first', style.borderRadiusFirst],
    ['last', style.borderRadiusLast],
    ['all', style.borderRadiusAll],
    ['none', style.borderRadiusNone],
  ])
  const wrapperClass = wrapperProps.className || ''
  return (
    <div
      {...wrapperProps}
      className={`${style.InputWrapper} ${wrapperClass}`}
    >
      <input
        className={`
          ${style.Input}
          ${borderRadiusClasses.get(borderRadius)}
          ${error && style.error}
          ${className}
        `}
        {...props}
      />
    </div>
  )
}

Input.propTypes = {
  borderRadius: PropTypes.oneOf(['first', 'last', 'all', 'none']),
  error: PropTypes.bool,
  wrapperProps: PropTypes.object,
  className: PropTypes.string,
}

Input.defaultProps = {
  borderRadius: 'all',
  error: false,
  wrapperProps: {},
  className: '',
}

export default observer(Input)
