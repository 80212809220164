import omit from 'lodash/omit'
import { action, computed, observable } from 'mobx'
import { v4 as uuid4 } from 'uuid'

import { adaptGetProductResponse, adaptPostProductErrors, adaptPostProductRequest, adaptPutProductErrors, adaptPutProductRequest } from 'shared/adapters/products'
import { getProduct, postProduct, putProduct, deleteProduct as apiDeleteProduct, postAsset } from 'shared/api/products'
import { processApiError } from 'shared/api/utils'

import { ProductStore, ProductImageStore, EditProductOptionsStore, ApiStatusStore } from 'supplier/stores'

import { buildProductVariants } from '../helpers'

class ProductDetailsStore {
  /** @type {import('../root').RootStore} */
  root

  apiStatus = new ApiStatusStore()

  @observable isNotFound
  @observable productStore
  @observable editOptionsStore

  /**
   * @param {import('../root').RootStore} root
   */
  constructor (root, data = {}) {
    this.root = root
    this.update(data)
  }

  @computed get formData () {
    const {
      name, sku, shippedFrom, madeIn, description, active, categoryTags, options, images, variants
    } = this.productStore
    return {
      name: name || '',
      sku: sku || '',
      shippedFrom: shippedFrom || null,
      madeIn: madeIn || null,
      description: description || '',
      active: !!active,
      options: options ? [...options] : [],
      images: images ? [...images] : [],
      categoryTags: categoryTags ? [...categoryTags] : [],
      variants: buildProductVariants(options || [], variants, this.getNewVariant).map(
        variant => ({
          uuid: variant.uuid,
          sku: variant.sku,
          active: variant.active,
          quantity: variant.quantity,
          msrpOriginal: variant.msrpOriginal,
          options: variant.options ? [...variant.options] : [],
          images: variant.images ? [...variant.images] : [],
        })
      ),
    }
  }

  @computed get shippedFromChoices () {
    return this.root.appConfigStore.shippingCountriesMap.get(
      this.root.userProfileStore.defaultShippingOriginCountry
    ).countries
  }

  @computed get defaultShippedFrom () {
    const { shippingFrom } = this.root.userProfileStore
    return this.shippedFromChoices.includes(shippingFrom) ? shippingFrom : null
  }

  @computed get defaultMadeIn () {
    return this.root.userProfileStore.productsMadeIn || this.defaultShippedFrom
  }

  @computed get optionsChoices () {
    const options = this.productStore?.options || []
    return new Map(options.map(o => [o.key, options.filter(o2 => o2.key === o.key)]))
  }

  @computed get isCreate () {
    return this.productStore ? !this.productStore.uuid : undefined
  }

  @action.bound
  update (data) {
    Object.assign(this, data)
  }

  @action.bound
  reset () {
    this.apiStatus.reset()
    this.isNotFound = undefined
    this.productStore = undefined
  }

  @action.bound
  setIsNotFound (value) {
    this.isNotFound = value
  }

  @action.bound
  setProductStore (obj) {
    this.productStore = obj
  }

  getNewVariant = (data = {}) => {
    return Object.assign({ uuid: uuid4(), options: [], images: [], active: true }, data)
  }

  getNewImage = (data = {}) => new ProductImageStore(Object.assign(data, { uuid: uuid4() }))

  @action.bound
  initNew () {
    this.setProductStore(new ProductStore({
      originalCurrency: 'USD',
      shippedFrom: this.defaultShippedFrom || null,
      madeIn: this.defaultMadeIn || null,
      active: true,
      variants: [{ uuid: uuid4(), active: true }],
    }))
    this.apiStatus.setIsLoading(false)
    this.apiStatus.setIsLoaded(true)
  }

  @action.bound
  fetch (id) {
    this.apiStatus.setIsLoading(true)
    return getProduct(id)
      .then(response => {
        const data = adaptGetProductResponse(response.data)
        this.setProductStore(new ProductStore(data))
        this.apiStatus.setIsLoaded(true)
      })
      .catch(error => {
        this.setIsNotFound(error.response && error.response.status === 404)
      })
      .finally(() => {
        this.apiStatus.setIsLoading(false)
      })
  }

  uploadImage ({ uuid, file }) {
    const formData = new FormData()
    formData.append('uuid', uuid)
    formData.append('data', file, file.name)
    return postAsset(formData)
  }

  @action.bound
  uploadImages (images) {
    this.apiStatus.setIsSaving(true)
    const requestFunctions = images.map(imageData => () => this.uploadImage(imageData))
    const promiseAll = requestFunctions.reduce((promise, func) =>
      promise.then(result => func().then(Array.prototype.concat.bind(result))), Promise.resolve([]))
    return promiseAll
      .then(responses => responses.map(({ data }) => data))
      .finally(() => {
        this.apiStatus.setIsSaving(false)
      })
  }

  @action.bound
  submit (payload) {
    this.apiStatus.setIsSaving(true)

    if (this.productStore.isSynced) {
      payload = omit(payload, ['name', 'sku', 'options', 'images', 'variants'])
    } else {
      payload.options = payload.options.map(({ uuid, key, value }) => ({ uuid, key, value }))
      payload.variants = payload.variants.map(v => ({ ...v, options: (v.options || []).filter(o => !!o) }))
      payload.images = payload.images.filter(i => !i.deleted).map(({ uuid }) => ({ uuid }))
    }

    const promise = this.isCreate
      ? postProduct(adaptPostProductRequest(payload))
      : putProduct(this.productStore.uuid, adaptPutProductRequest(payload))

    const errorAdapter = this.isCreate
      ? adaptPostProductErrors
      : adaptPutProductErrors

    return promise
      .then(response => {
        const data = adaptGetProductResponse(response.data)
        this.setProductStore(new ProductStore(data))
        return data
      })
      .catch(error => {
        const remoteErrors = processApiError(error, errorAdapter)
        this.apiStatus.setRemoteErrors(remoteErrors)
        throw error
      })
      .finally(() => {
        this.apiStatus.setIsSaving(false)
      })
  }

  /**
   * Start editing of options specified by a common key
   *
   * @param {Object[]} currentOptions Current options as stored in the current form state
   */
  @action.bound
  beginEditOptions (currentOptions, key = undefined) {
    this.editOptionsStore = new EditProductOptionsStore(currentOptions, key)
  }

  @action.bound
  resetEditOptions () {
    this.editOptionsStore = undefined
  }

  @action.bound
  deleteProduct () {
    if (this.isCreate) return
    this.apiStatus.setIsDeleting(true)
    return apiDeleteProduct(this.productStore.uuid)
      .finally(() => {
        this.apiStatus.setIsDeleting(false)
      })
  }
}

export default ProductDetailsStore
