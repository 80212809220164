import React, { forwardRef, useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react'

import PropTypes from 'prop-types'

import { Box, Button, Card, CardMedia, Fade, Hidden, Typography, useTheme } from '@material-ui/core'
import { ChevronLeft, ChevronRight } from '@material-ui/icons'
import { Skeleton } from '@material-ui/lab'
import { observer } from 'mobx-react'
import { Link as RouterLink } from 'react-router-dom'
import SwipeableViews from 'react-swipeable-views'
import { autoPlay, virtualize } from 'react-swipeable-views-utils'

import { ALIBABA, ALIEXPRESS } from 'shared/constants/marketplace'
import { AlibabaLogoApp, AliExpress } from 'shared/icons/external'

import { ProductLink } from 'retailer/components/atoms'

import style from './ProductCarouselCard.module.scss'

const EnhancedSwipeableViews = autoPlay(virtualize(SwipeableViews))

const CarouselItem = observer(({ productStore }) => {
  const { spacing } = useTheme()
  const { inventoryType, mainImage } = productStore || {}
  const imageSrc = mainImage?.thumbnail

  const [image, setImage] = useState()
  const [isLoading, setIsLoading] = useState(true)
  const handleImageLoaded = () => setIsLoading(false)

  useEffect(() => {
    let newImage
    setIsLoading(true)
    if (imageSrc) {
      if (image) image.onload = undefined
      newImage = new Image()
      newImage.onload = handleImageLoaded
      newImage.src = imageSrc
    }
    setImage(newImage)
  }, [imageSrc])

  const supplierBadge = useMemo(() => {
    switch (inventoryType) {
      case ALIEXPRESS: return <AliExpress />
      case ALIBABA: return <AlibabaLogoApp />
      default: return null
    }
  }, [inventoryType])

  return (
    <Box p={0.5} className={style.CarouselItem}>
      <Card elevation={0}>
        {productStore && (
          <ProductLink productStore={productStore}>
            <CardMedia image={imageSrc} />
          </ProductLink>
        )}
        <Fade in={isLoading} timeout={800} appear={false}>
          <Skeleton component="div" variant="rect" animation="wave" />
        </Fade>
        {supplierBadge && (
          <Box position="absolute" width={spacing(2)} top={spacing(1)} right={spacing(1)}>
            {supplierBadge}
          </Box>
        )}
      </Card>
    </Box>
  )
})

CarouselItem.propTypes = {
  productStore: PropTypes.shape({
    mainImage: PropTypes.shape({
      thumbnail: PropTypes.string,
    }),
    inventoryType: PropTypes.string,
  }),
}

const ScrollButton = ({ direction, onClick }) => {
  const Icon = direction === 'left' ? ChevronLeft : ChevronRight
  return (
    <Button onClick={onClick} size="small" className={style.ScrollButton}>
      <Icon />
    </Button>
  )
}

ScrollButton.propTypes = {
  direction: PropTypes.oneOf(['left', 'right']).isRequired,
  onClick: PropTypes.func.isRequired,
}

const ProductCarousel = observer(({ productCarouselStore }) => {
  const itemsContainerRef = useRef(null)

  const [slideIndex, setSlideIndex] = useState(0)

  const { numOfItemsInSlide, setNumOfItemsInSlide, numOfSlides, groups } = productCarouselStore

  const handleNextClick = () => setSlideIndex(slideIndex + 1)
  const handlePrevClick = () => setSlideIndex(slideIndex - 1)
  const handleIndexChange = value => setSlideIndex(value)

  const calculateNumOfItemsInSlide = useCallback(() => {
    const width = itemsContainerRef.current?.offsetWidth
    if (!width) return
    setNumOfItemsInSlide(
      [[690, 6], [580, 5], [470, 4], [320, 3], [0, 2]]
        .find(([bp, _num]) => (width || 0) >= bp)[1]
    )
  }, [itemsContainerRef.current])

  useLayoutEffect(() => {
    window.addEventListener('resize', calculateNumOfItemsInSlide)
    return () => window.removeEventListener('resize', calculateNumOfItemsInSlide)
  }, [])
  useLayoutEffect(calculateNumOfItemsInSlide, [itemsContainerRef.current])

  const slideRenderer = useCallback(({ key, index }) => {
    const slideIndex = Math.abs(
      index - numOfSlides * Math.floor(index / numOfSlides)
    )
    const slideItems = groups[slideIndex] || []
    return (
      <Box key={key} display="flex" alignItems="center">
        {[...Array(numOfItemsInSlide).keys()].map(i => (
          <CarouselItem key={i} productStore={slideItems[i]} />
        ))}
      </Box>
    )
  }, [numOfSlides, numOfItemsInSlide, groups])

  return (
    <Box height="100%" display="flex" p={0.5} overflow="hidden" className={style.ProductCarousel}>
      <ScrollButton direction="left" onClick={handlePrevClick} />
      <Box ref={itemsContainerRef} flexGrow={1} position="relative" alignSelf="center">
        <EnhancedSwipeableViews
          slideRenderer={slideRenderer}
          index={slideIndex} onChangeIndex={handleIndexChange}
          autoplay={false}
          enableMouseEvents
        />
      </Box>
      <ScrollButton direction="right" onClick={handleNextClick} />
    </Box>
  )
})

ProductCarousel.propTypes = {
  productCarouselStore: PropTypes.shape({
    numOfItemsInSlide: PropTypes.number,
    setNumOfItemsInSlide: PropTypes.func.isRequired,
    numOfSlides: PropTypes.number,
    groups: PropTypes.arrayOf(PropTypes.array),
  }),
}

const ProductCarouselCard = forwardRef(({
  primary, secondary, buttonText, to, background, productCarouselStore, ...boxProps
}, ref) => {
  const theme = useTheme()
  return (
    <Card ref={ref} component={Box} {...boxProps}>
      <Box className={style.CardContent}>
        <Box
          className={style.Description}
          style={{ background, color: theme.palette.getContrastText(background) }}
        >
          <Typography variant="h5" color="inherit">
            {primary}
          </Typography>
          <Hidden mdDown>
            <Typography color="inherit" paragraph>
              {secondary}
            </Typography>
          </Hidden>
          <Button
            component={RouterLink} to={to}
            variant="outlined" color="primary" size="small"
            className={style.Button}
          >
            {buttonText}
          </Button>
        </Box>
        <Box flexGrow={1}>
          <ProductCarousel productCarouselStore={productCarouselStore} />
        </Box>
      </Box>
    </Card>
  )
})

ProductCarouselCard.propTypes = {
  primary: PropTypes.string.isRequired,
  secondary: PropTypes.string.isRequired,
  buttonText: PropTypes.string,
  to: PropTypes.string.isRequired,
  background: PropTypes.string.isRequired,
  productCarouselStore: PropTypes.object.isRequired,
}

ProductCarouselCard.defaultProps = {
  buttonText: 'Explore Catalog',
  background: '#333333',
}

export default observer(ProductCarouselCard)
