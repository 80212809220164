import React, { useMemo } from 'react'

import { observer } from 'mobx-react'

import { useStores } from 'retailer/stores/hooks'

import CtaCard from '../CtaCard'
import jewelryAccessoriesImg from './accessories.jpg'
import beautyHealthImg from './beauty_health.jpg'
import style from './CategoriesPromoGrid.module.scss'
import homeGardenImg from './home_garden.jpg'
import kidsBabiesImg from './kids_babies.jpg'
import mensFashionImg from './mens_fashion.jpg'
import newSuppliersImg from './new_suppliers.jpg'
import womensFashionImg from './womens_fashion.jpg'

const CategoriesPromoGrid = () => {
  const { appConfigStore } = useStores()
  const categories = useMemo(() => Object.fromEntries(
    ['womens-fashion', 'kids-babies', 'mens-fashion', 'jewelry-accessories', 'beauty-health', 'home-garden'].map(slug => {
      const { name: primary, uuid } = appConfigStore.categoriesSlugMap.get(slug) || {}
      const data = { primary, uuid, to: `/marketplace/ready-to-sell?categoryId=${uuid}` }
      return [slug, data]
    })
  ), [appConfigStore.categoriesSlugMap])

  const common = { darken: true }

  return (
    <div className={style.CategoriesPromoGrid}>
      <CtaCard
        className={style.womensFashion}
        image={womensFashionImg}
        backgroundColor="#d6d7d9"
        textPosition="right"
        textMaxWidth={200}
        {...categories['womens-fashion']}
        {...common}
      />
      <CtaCard
        className={style.newSuppliers}
        image={newSuppliersImg}
        backgroundColor="#4e6c94"
        primary="New Suppliers"
        textPosition="center"
        textMaxWidth={200}
        to="/marketplace/ready-to-sell?ordering=-approved_at%2C-quantity"
        {...common}
      />
      <CtaCard
        className={style.kidsBabies}
        image={kidsBabiesImg}
        imagePosition="center"
        backgroundColor="#d9d1c6"
        textMaxWidth={200}
        {...categories['kids-babies']}
        {...common}
      />
      <CtaCard
        className={style.mensFashion}
        image={mensFashionImg}
        backgroundColor="#8d5139"
        textPosition="right"
        textMaxWidth={200}
        {...categories['mens-fashion']}
        {...common}
      />
      <CtaCard
        className={style.jewelryAccessories}
        image={jewelryAccessoriesImg}
        backgroundColor="#d1968d"
        textPosition="bottomRight"
        textMaxWidth={320}
        {...categories['jewelry-accessories']}
        {...common}
      />
      <CtaCard
        className={style.beautyHealth}
        image={beautyHealthImg}
        imagePosition="center"
        backgroundColor="#a38cca"
        textPosition="bottomLeft"
        divide
        {...categories['beauty-health']}
        {...common}
      />
      <CtaCard
        className={style.homeGarden}
        image={homeGardenImg}
        imagePosition="center"
        backgroundColor="#808ad5"
        textPosition="bottomLeft"
        divide
        {...categories['home-garden']}
        {...common}
      />
    </div>
  )
}

export default observer(CategoriesPromoGrid)
