import { action, computed, observable } from 'mobx'

import { adaptPostStoreErrors, adaptPostStoreRequest, adaptStore } from 'shared/adapters/stores'
import { postStore } from 'shared/api/stores'
import { processApiError } from 'shared/api/utils'
import { WIX } from 'shared/constants/integrations'
import { StoreStore } from 'shared/stores'

import { getBiHeadersFromParams } from '../BiLoggerStore/utils'

class CreateStoreStore {
  /** @type {import('../context').RootStore} */
  root

  @observable isSaving = false
  @observable isFormValid
  @observable remoteErrors
  @observable isDone = false
  /** @type {StoreStore} set after creating the store from the data returned by the API */
  @observable createdStore
  /** @type {('wix'|'shopify')} holds the chosen platform after submitting */
  @observable designatedPlatform

  /**
   * @param {import('../context').RootStore} root
   */
  constructor (root) {
    this.root = root
  }

  @computed get countryChoices () {
    return this.root.appConfigStore.shipsToCountries.map(obj => obj.code)
  }

  @computed get formDefaults () {
    return {
      storeName: '',
      integrationType: WIX,
      country: this.root.userProfileStore?.country || 'US',
    }
  }

  @action.bound reset = () => {
    this.isFormValid = undefined
    this.isSaving = false
    this.remoteErrors = undefined
    this.isDone = false
    this.designatedPlatform = undefined
    this.createdStore = undefined
  }

  @action.bound setFormValid = value => {
    this.isFormValid = value
  }

  @action.bound setIsSaving = value => {
    this.isSaving = value
  }

  @action.bound setRemoteErrors = value => {
    this.remoteErrors = value
  }

  @action.bound setCreatedStore = value => {
    this.createdStore = value
  }

  @action.bound setDesignatedPlatform = value => {
    this.designatedPlatform = value
  }

  @action.bound setIsDone = value => {
    this.isDone = value
  }

  @action.bound submitFormData (data, params) {
    this.setIsSaving(true)
    const { integrationType, ...payload } = data
    return postStore(adaptPostStoreRequest(payload), getBiHeadersFromParams(params))
      .then(response => {
        const data = adaptStore(response.data)
        this.setCreatedStore(new StoreStore(this.root, data))
        this.setDesignatedPlatform(integrationType)
        this.setIsDone(true)
        return data
      })
      .catch(error => {
        const remoteErrors = processApiError(error, adaptPostStoreErrors)
        this.setRemoteErrors(remoteErrors)
        throw error
      })
      .finally(() => {
        this.setIsSaving(false)
      })
  }
}

export default CreateStoreStore
