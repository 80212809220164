import { api } from './common'

const apiV2 = '/api/v2'
const apiV3 = '/api/v3'

// FIXME: checkRetailerAuthorization and createRetailerAuthorization functions
//  are inappropriately named - the endpoints they call are serving both Suppliers and Retailers

const checkRetailerAuthorization = data => {
  return api.post(`${apiV3}/integrations/authorizations/check/`, data, { withCredentials: true })
}

const createRetailerAuthorization = data => {
  return api.post(`${apiV3}/integrations/authorizations/`, data, { withCredentials: true })
}

const getRetailerAuthorization = () => {
  return api.get(apiV2 + '/retailer/retailer-authorization/')
}

const getSupplierAppAuthorization = (appName) => {
  return api.get(apiV2 + `/retailer/retailer-supplier-app-integration/${appName}`)
}

const changeDefaultStoreForSupplierAppAuthorization = (appName, storeUuid) => {
  return api.put(
    apiV2 + `/retailer/retailer-supplier-app-integration/${appName}`,
    { default_store_uuid: storeUuid },
  )
}

export {
  checkRetailerAuthorization,
  createRetailerAuthorization,
  getRetailerAuthorization,
  getSupplierAppAuthorization,
  changeDefaultStoreForSupplierAppAuthorization,
}
