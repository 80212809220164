import React, { useEffect } from 'react'

import PropTypes from 'prop-types'

import { Typography } from '@material-ui/core'
import { observer } from 'mobx-react'
import { Helmet } from 'react-helmet'
import { Route, useLocation } from 'react-router'

import { ScrollResetter } from 'shared/components/atoms'
import { Alert } from 'shared/components/molecules'

import { Loader, Pagination } from 'retailer/components/molecules'
import {
  ExternalOrderPaymentModal,
  MarkFulfilledModal,
  MarkOrderedModal,
  MarkPaidModal,
  ModalystOrderPaymentCompleteModal,
  ModalystOrderPaymentModal,
  OrderCancelModal,
  OrderSyncModal,
  OrderSyncAndPayModal,
  OrderItemShippingMethodModal,
  OrdersFilters,
  OrderShippingAddressModal,
  OrdersTopActions,
  RetailerOrdersList,
  UniversalNotifications,
} from 'retailer/components/organisms'
import { SidebarLeftTemplate } from 'retailer/components/templates'
import { PaginationStore } from 'retailer/stores'
import { useStores } from 'retailer/stores/hooks'

const List = observer(({ orders, pagination, handler, visible }) => {
  if (!visible) return null
  return (
    <>
      <RetailerOrdersList orders={orders} />
      <Pagination
        totalItems={pagination.totalCount}
        count={pagination.totalPages}
        page={pagination.currentPage}
        onChange={handler}
      />
    </>
  )
})

List.propTypes = {
  orders: PropTypes.array,
  pagination: PropTypes.instanceOf(PaginationStore).isRequired,
  handler: PropTypes.func.isRequired,
  visible: PropTypes.bool,
}

List.defaultProps = {
  orders: [],
  visible: false
}

const Error = ({ visible }) => {
  if (!visible) return null
  return (
    <div style={{ margin: '64px 0' }}>
      <Alert severity="error">Unexpected error. Please try again.</Alert>
    </div>
  )
}

Error.propTypes = {
  visible: PropTypes.bool
}

Error.defaultProps = {
  visible: false
}

const OrdersPage = () => {
  const { ordersStore, userProfileStore } = useStores()

  const ordersList = ordersStore.ordersList
  const setQueryFromSearch = ordersList.setQueryFromSearch
  const load = ordersList.load

  const { search } = useLocation()

  useEffect(() => {
    setQueryFromSearch(search)
    load()
  }, [search, setQueryFromSearch, load])

  const main = (
    <>
      <Typography variant="h2">Orders</Typography>
      <OrdersFilters filters={ordersList.filters} />
      <OrdersTopActions dateRangeStore={ordersList.filters.dateRangeFilter} />
      <Error visible={ordersList.error} />
      <List
        orders={ordersList.orders}
        pagination={ordersList.pagination}
        handler={(event, value) => ordersList.changePage(value)}
        visible={ordersList.loading.isLoaded}
      />
      <Loader visible={!ordersList.loading.isLoaded} />

      <Route
        path="/orders/purchase-orders/:uuid/payment-complete"
        component={ModalystOrderPaymentCompleteModal}
      />

      <Route
        path="/orders/purchase-orders/:uuid/payment-cancelled"
        render={props => (
          <ModalystOrderPaymentCompleteModal
            success={false}
            {...props}
          />
        )}
      />

      {ordersStore.externalOrderPaymentStore && (
        <ExternalOrderPaymentModal
          modalStore={ordersStore.externalOrderPaymentStore}
          userProfileStore={userProfileStore}
          onExited={() => ordersStore.resetOrderForExternalPayment()}
        />
      )}

      {ordersStore.orderShippingAddressEditState && (
        <OrderShippingAddressModal
          store={ordersStore.orderShippingAddressEditState}
          onExited={() => ordersStore.finishOrderShippingAddressEditing()}
        />
      )}

      {ordersStore.orderItemShippingMethodEditState && (
        <OrderItemShippingMethodModal
          store={ordersStore.orderItemShippingMethodEditState}
          onExited={() => ordersStore.finishOrderItemShippingMethodEditing()}
        />
      )}

      {ordersStore.orderPaymentState && (
        <ModalystOrderPaymentModal
          store={ordersStore.orderPaymentState}
          onExited={() => ordersStore.resetModalystOrderPayment()}
        />
      )}

      {ordersStore.orderMarkAsPaidState && (
        <MarkPaidModal
          store={ordersStore.orderMarkAsPaidState}
          onExited={() => ordersStore.resetOrderMarkAsPaid()}
        />
      )}

      {ordersStore.orderMarkAsFulfilledState && (
        <MarkFulfilledModal
          store={ordersStore.orderMarkAsFulfilledState}
          onExited={() => ordersStore.resetOrderMarkAsFulfilled()}
        />
      )}

      {ordersStore.orderMarkAsOrderedState && (
        <MarkOrderedModal
          store={ordersStore.orderMarkAsOrderedState}
          onExited={() => ordersStore.resetOrderMarkAsOrdered()}
        />
      )}
      {ordersStore.orderCancelState && (
        <OrderCancelModal
          store={ordersStore.orderCancelState}
          onExited={() => ordersStore.resetOrderCancel()}
        />
      )}
      {ordersStore.orderSyncState && (
        <OrderSyncModal
          orderStore={ordersStore.orderSyncState}
          userProfileStore={userProfileStore}
          onExited={() => ordersStore.resetOrderSync()}
        />
      )}
      {ordersStore.orderSyncAndPayState && (
        <OrderSyncAndPayModal
          orderStore={ordersStore.orderSyncAndPayState}
          userProfileStore={userProfileStore}
          onExited={() => ordersStore.resetOrderSyncAndPay()}
        />
      )}
    </>
  )

  return (
    <>
      <Helmet>
        <title>Orders</title>
      </Helmet>
      <ScrollResetter />
      <SidebarLeftTemplate
        notifications={<UniversalNotifications />}
        main={main}
      />
    </>
  )
}

export default observer(OrdersPage)
