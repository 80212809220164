import React from 'react'

import PropTypes from 'prop-types'

import { Grid, IconButton } from '@material-ui/core'
import { AddRounded, RemoveRounded } from '@material-ui/icons'
import { observer } from 'mobx-react'

import style from './QuantityChanger.module.scss'

/**
 * Displays a quantity changer component with buttons that increase and decrease quantity by 1.
 * It is a controlled  component, so provide quantity from outside and a changer function that accepts
 * value by how much to change the quantity (+1 or -1).
 */
const QuantityChanger = ({ quantity, maxQuantity, onChange, vertical, disabled, testId }) => {
  return (
    <Grid
      container
      spacing={1}
      alignItems="center"
      direction={vertical ? 'column-reverse' : 'row'}
      className={style.QuantityChanger}
    >
      <Grid item>
        <IconButton
          onClick={() => onChange(-1)} disabled={disabled || quantity === 0}
          data-testid={testId ? `${testId}-decreaseButton` : undefined}
        >
          <RemoveRounded fontSize="small" />
        </IconButton>
      </Grid>
      <Grid item>
        <div className={style.Quantity} data-testid={testId ? `${testId}-quantity` : undefined}>
          {quantity}
        </div>
      </Grid>
      <Grid item>
        <IconButton
          onClick={() => onChange(1)} disabled={disabled || quantity === maxQuantity}
          data-testid={testId ? `${testId}-increaseButton` : undefined}
        >
          <AddRounded fontSize="small" />
        </IconButton>
      </Grid>
    </Grid>
  )
}

QuantityChanger.propTypes = {
  quantity: PropTypes.number,
  maxQuantity: PropTypes.number,
  onChange: PropTypes.func,
  vertical: PropTypes.bool,
  disabled: PropTypes.bool,
  testId: PropTypes.string,
}

QuantityChanger.defaultProps = {
  quantity: 0,
  maxQuantity: Infinity,
  onChange: () => null,
  vertical: true,
  disabled: false
}

export default observer(QuantityChanger)
