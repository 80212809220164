import { action, computed, observable } from 'mobx'

import { adaptAppConfigResponse } from 'shared/adapters/profiles'
import { getAppConfig } from 'shared/api/profiles'
import { CATEGORIES_ROOT_NAME } from 'shared/constants/categories'
import { isosToCountries } from 'shared/utils/countries'

class AppConfigStore {
  @observable.shallow shipsFromCountries
  @observable.shallow shipsToCountries
  @observable.shallow activeCountries = []

  @observable.shallow categories = new Map()

  @observable featureFlags = {}
  @observable gaTrackingId
  @observable debug
  @observable isReady = false

  constructor (data = {}) {
    this.update(data)
  }

  @action.bound
  update (data) {
    const {
      activeCountries = [],
      shipsFromCountries = [],
      shipsToCountries = [],
      categories = [],
      ...rest
    } = data

    this.shipsFromCountries = isosToCountries(shipsFromCountries)
    this.shipsToCountries = isosToCountries(shipsToCountries)
    this.activeCountries = isosToCountries(activeCountries)

    this.categories = new Map(categories.map(cat => [cat.id, Object.assign(cat, { children: [] })]))
    this.categories.forEach(cat => {
      const parent = this.categories.get(cat.parentId)
      if (parent) {
        cat.parent = parent
        parent.children.push(cat)
      }
    })

    Object.assign(this, rest)
  }

  @computed get categoriesUuidMap () {
    return new Map([...this.categories.values()].map(cat => [cat.uuid, cat]))
  }

  @computed get categoriesSlugMap () {
    return new Map([...this.categories.values()].map(cat => [cat.slug, cat]))
  }

  @computed get categoriesTree () {
    const root = [...this.categories.values()].filter(cat => cat.name === CATEGORIES_ROOT_NAME)
    return root.length ? root[0] : undefined
  }

  @computed get categoryRootsMap () {
    return new Map([...this.categories.values()].filter(({ parentId }) => parentId === null).map(cat => [cat.name, cat]))
  }

  @action.bound
  setIsReady (val) {
    this.isReady = val
  }

  isFeatureEnabled (name) {
    return !!this.featureFlags[name]
  }

  @action.bound
  fetch () {
    return this.doFetch().then(response => {
      this.update(adaptAppConfigResponse(response.data))
      this.setIsReady(true)
    })
  }

  doFetch () {
    return getAppConfig()
  }
}

export default AppConfigStore
