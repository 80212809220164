import React, { useEffect } from 'react'

import { observer } from 'mobx-react'

import { Instagram, Twitter } from 'shared/icons/external'

import { Link } from 'retailer/components/atoms'
import { List, RetailerSubscriptionBox } from 'retailer/components/molecules'
import { useStores } from 'retailer/stores/hooks'

import style from './Footer.module.scss'

const Footer = () => {
  const { mainMenuStore: { retailerSubscription } } = useStores()

  useEffect(() => {
    retailerSubscription.fetch()
  }, [retailerSubscription])

  return (
    <div className={style.Footer}>
      <RetailerSubscriptionBox
        subscription={retailerSubscription}
      />
      <List>
        <Link href="http://blog.modalyst.co/" absolute block inverted>
          Blog
        </Link>
        <Link href="/terms/" absolute block inverted>
          Terms of Service
        </Link>
        <Link href="/privacy/" absolute block inverted>
          Privacy Policy
        </Link>
        <Link href="/contact-us/" absolute block inverted>
          Contact
        </Link>
      </List>
      <List inline className={style.SocialLinks}>
        <Link href="http://www.twitter.com/modalyst" absolute inverted>
          <Twitter />
        </Link>
        <Link href="https://www.instagram.com/modalyst/" absolute inverted>
          <Instagram />
        </Link>
      </List>
    </div>
  )
}

export default observer(Footer)
