import { api } from './common'

const baseUrl = '/api/v2'

const getCsvExportTypes = () => {
  return api.get(baseUrl + '/csv_export_types/')
}

const exportCsv = (data = {}, headers = {}) => {
  return api.post(baseUrl + '/csv_export/', data, { headers })
}

export {
  getCsvExportTypes,
  exportCsv
}
