import React from 'react'

import PropTypes from 'prop-types'

import { Button, Grid } from '@material-ui/core'
import { Add } from '@material-ui/icons'
import clsx from 'clsx'
import { observer } from 'mobx-react'
import ImageUploading from 'react-images-uploading'

import { useTranslation } from 'shared/hooks'

import { ReactComponent as Avatar } from './Avatar.svg'
import style from './ProfileImageUpload.module.scss'

/**
 * Handles uploading a single image, it's removal and errors.
 * @param file
 * @param onChange
 * @param onClear
 * @param onError - callback on error detected internally by the component
 * @param error - error for the field
 * @param className
 * @param {Object} props
 * @returns {JSX.Element}
 * @constructor
 */
const ProfileImageUpload = ({ file, onChange, onClear, onError, error, className, ...props }) => {
  const { t } = useTranslation('settings')
  return (
    <ImageUploading
      value={file ? [file] : []}
      onChange={(imageList, _) => onChange(imageList[0])}
      {...props}
    >
      {({
        imageList,
        onImageUpload,
        onImageUpdate,
        onImageRemove,
        errors
      }) => {
        if (errors && errors.maxFileSize && onError) onError('This file is too large')
        if (errors && errors.acceptType && onError) onError('Only JPG, PNG and GIF files are accepted')
        if (error && onError) onError(error.message)
        if (!(errors || error) && onError) onError(null)

        return (
          <Grid container alignItems="center" spacing={4} className={className}>
            <Grid item xs={12} sm="auto">
              {!imageList.length && (
                <div className={clsx(style.ProfileImageInput)}>
                  <div className={clsx(style.ProfileImageBox, style.AddProfileImage)}>
                    <Avatar className={style.Icon} />
                    <div className={style.IconOverlay} />
                    <Button
                      variant="text"
                      color="primary"
                      size="small"
                      className={style.AddButton}
                      onClick={onImageUpload}
                    >
                      <Add className={style.PlusIcon} />
                      <span className={style.ButtonText}>{t('accountSettings.basicInfo.addImage.cta')}</span>
                    </Button>
                  </div>
                </div>
              )}
              {imageList.length === 1 && (
                <div key={imageList[0]} className={clsx(style.ProfileImageInput)}>
                  <div className={clsx(style.ProfileImageBox, style.ChangeProfileImage)}>
                    <img src={typeof imageList[0] === 'object' ? imageList[0].dataUrl : imageList[0]} alt="" className={style.Image} />
                    <Button
                      variant="text"
                      color="primary"
                      size="small"
                      className={style.ChangeButton}
                      onClick={() => { onImageUpdate(0) }}
                    >
                      <Add className={style.PlusIcon} />
                      <span className={style.ButtonText}>{t('accountSettings.basicInfo.changeImage.cta')}</span>
                    </Button>
                  </div>
                  <div className={style.DeleteButtonBox}>
                    <Button
                      variant="text"
                      color="primary"
                      size="small"
                      onClick={() => { if (onClear) onClear(); onImageRemove(0) }}
                    >
                      {t('accountSettings.basicInfo.removeImage.cta')}
                    </Button>
                  </div>
                </div>
              )}
            </Grid>
          </Grid>
        )
      }}
    </ImageUploading>
  )
}

ProfileImageUpload.propTypes = {
  file: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  onChange: PropTypes.func.isRequired,
  onClear: PropTypes.func,
  onError: PropTypes.func,
  error: PropTypes.shape({
    message: PropTypes.string,
    type: PropTypes.string
  }),
  className: PropTypes.string,
  ...ImageUploading.propTypes
}

ProfileImageUpload.defaultProps = {
  file: null,
  className: ''
}

export default observer(ProfileImageUpload)
