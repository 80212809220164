import { api } from 'shared/api/common'

const BASE_URL = '/api/v3/settings/'

const getPublicInfo = () => {
  return api.get(BASE_URL + 'public_info/')
}

const savePublicInfo = (data) => {
  return api.patch(BASE_URL + 'public_info/', data)
}

/**
 * Retailer's currently selected marketplaces
 * @return {Promise<AxiosResponse<any>>}
 */
const getMarketplaces = () => {
  return api.get(BASE_URL + 'marketplaces/')
}

/**
 * @param data
 * @return {Promise<AxiosResponse<any>>}
 */
const saveMarketplaces = (data) => {
  return api.put(BASE_URL + 'marketplaces/', data)
}

const getStoreLocation = () => {
  return api.get(BASE_URL + 'location/')
}

const saveStoreLocation = (countryCode) => {
  return api.put(BASE_URL + 'location/', {
    selected_country: countryCode
  })
}

const getSocialMedia = () => {
  return api.get(BASE_URL + 'social_media/')
}

const saveSocialMedia = (data) => {
  return api.put(BASE_URL + 'social_media/', data)
}

const getBusinessInfo = () => {
  return api.get(BASE_URL + 'business_information/')
}

const saveBusinessInfo = data => {
  return api.put(BASE_URL + 'business_information/', data)
}

const getShippingInfo = () => {
  return api.get(BASE_URL + 'shipping_information/')
}

const saveShippingInfo = data => {
  return api.put(BASE_URL + 'shipping_information/', data)
}

const getUserBasicInfo = () => {
  return api.get(BASE_URL + 'user_basic_info/')
}

const saveUserBasicInfo = data => {
  return api.patch(BASE_URL + 'user_basic_info/', data)
}

const getUserName = () => {
  return api.get(BASE_URL + 'username/')
}

const changePassword = data => {
  return api.put(BASE_URL + 'user_password_change/', data)
}

const getUserRequestedDataRemoval = () => {
  return api.get(BASE_URL + 'user_personal_data/')
}

/**
 * Will return 400 if data is already requested and not yet provided to the user.
 * @returns {Promise<AxiosResponse<any>>}
 */
const requestPersonalData = () => {
  return api.put(BASE_URL + 'user_personal_data/', {
    requested_personal_data: true
  })
}

const getUserRequestedAccountRemoval = () => {
  return api.get(BASE_URL + 'user_delete_account/')
}

/**
 * Will return 400 if a request is already present.
 * @returns {Promise<AxiosResponse<any>>}
 */
const requestAccountRemoval = () => {
  return api.post(BASE_URL + 'user_delete_account/request_removal/')
}

/**
 * Will return 404 if removal was not previously requested.
 * @returns {Promise<AxiosResponse<any>>}
 */
const cancelAccountRemoval = () => {
  return api.post(BASE_URL + 'user_delete_account/cancel_removal/')
}

export {
  getPublicInfo,
  savePublicInfo,
  getMarketplaces,
  saveMarketplaces,
  getStoreLocation,
  saveStoreLocation,
  getSocialMedia,
  saveSocialMedia,
  getBusinessInfo,
  saveBusinessInfo,
  getShippingInfo,
  saveShippingInfo,
  getUserBasicInfo,
  saveUserBasicInfo,
  getUserName,
  changePassword,
  getUserRequestedDataRemoval,
  requestPersonalData,
  getUserRequestedAccountRemoval,
  requestAccountRemoval,
  cancelAccountRemoval
}
