import React from 'react'

import PropTypes from 'prop-types'

import { Box, Button, Typography } from '@material-ui/core'
import { observer } from 'mobx-react'

import { LoadingButton } from 'shared/components/atoms'
import { Alert, CountrySelect } from 'shared/components/molecules'
import { DataModal } from 'shared/components/organisms'
import { CheckMark } from 'shared/icons'

import style from './SetPrimaryShippingCountryModal.module.scss'

const FormContent = observer(({ setPrimaryShippingCountryStore }) => {
  const { country, setCountry, isSaving, isError } = setPrimaryShippingCountryStore
  const handleChange = iso => setCountry(iso)

  return (
    <>
      <Typography>
        The shipping costs displayed are based on the country which you select as your primary shipping country
      </Typography>
      <Box my={3}>
        <CountrySelect
          variant="outlined"
          label="Primary shipping country"
          value={country}
          onChange={handleChange}
          disabled={isSaving}
        />
      </Box>
      <Typography>
        Are you sure you want to change primary shipping country?
      </Typography>
      {isError && (
        <Alert severity="error" component={Box} mt={3}>
          We could not finish this operation. Please try again or contact our support team.
        </Alert>
      )}
    </>

  )
})

FormContent.propTypes = {
  setPrimaryShippingCountryStore: PropTypes.shape({
    country: PropTypes.string.isRequired,
    setCountry: PropTypes.func.isRequired,
    isSaving: PropTypes.bool,
    isError: PropTypes.bool,
  }).isRequired,
}

const FormActions = observer(({ setPrimaryShippingCountryStore, dismissModal }) => {
  const { save, isSaving } = setPrimaryShippingCountryStore
  const handleSubmitClick = () => save()

  return (
    <>
      <Button
        variant="outlined" color="primary"
        disabled={isSaving}
        onClick={dismissModal}
      >
        Cancel
      </Button>
      <LoadingButton
        variant="contained" color="primary"
        loading={isSaving}
        disabled={isSaving}
        onClick={handleSubmitClick}
      >
        Select shipping country
      </LoadingButton>
    </>

  )
})

FormActions.propTypes = {
  setPrimaryShippingCountryStore: PropTypes.shape({
    save: PropTypes.func.isRequired,
    isSaving: PropTypes.bool,
  }).isRequired,
  dismissModal: PropTypes.func.isRequired,
}

const DoneContent = observer(() => {
  return (
    <>
      <Box my={3} textAlign="center"><CheckMark /></Box>
      <Box my={3} textAlign="center">
        <Typography>
          Primary shipping country is being changed in the background. This may take a while.
        </Typography>
        <Typography>
          You can continue using Modalyst as usual while we process this change.
        </Typography>
      </Box>
    </>
  )
})

const SetPrimaryShippingCountryModal = ({ setPrimaryShippingCountryStore, ...props }) => {
  const { isDone } = setPrimaryShippingCountryStore

  return (
    <DataModal
      className={style.SetPrimaryShippingCountryModal}
      title="Select primary shipping country"
      fullWidth
      {...props}
      renderBody={() => isDone
        ? <DoneContent />
        : <FormContent setPrimaryShippingCountryStore={setPrimaryShippingCountryStore} />}
      renderActions={({ dismissModal }) => isDone
        ? <Button variant="contained" color="primary" onClick={dismissModal}>OK</Button>
        : (
          <FormActions
            setPrimaryShippingCountryStore={setPrimaryShippingCountryStore}
            dismissModal={dismissModal}
          />
          )}
    />
  )
}

SetPrimaryShippingCountryModal.propTypes = {
  setPrimaryShippingCountryStore: PropTypes.shape({
    isDone: PropTypes.bool,
  }).isRequired,
}

export default observer(SetPrimaryShippingCountryModal)
