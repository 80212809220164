import dayjs from 'dayjs'
import { computed, observable } from 'mobx'

import { FilterStore, SelectFilterStore } from 'shared/stores'
import { colorScheme } from 'shared/theme'

import {
  DateRangeStore,
  OrderFulfillmentStatusFilter
} from 'retailer/stores'

import { orderFulfillmentStatuses, orderSources, orderStatuses } from './utils'

class OrdersFiltersStore {
  @observable searchFilter = new FilterStore(
    'search', '', false,
  )

  @observable orderStatusFilter = new SelectFilterStore(
    'orderStatus', orderStatuses[1].value, true, orderStatuses,
  )

  @observable orderFulfillmentStatusFilter = new OrderFulfillmentStatusFilter(
    this, 'orderFulfillmentStatus', orderFulfillmentStatuses[0].value, true,
    orderFulfillmentStatuses,
  )

  @observable orderSourceFilter = new SelectFilterStore(
    'orderSource', orderSources[0].value, true, orderSources,
  )

  @observable dateRangeFilter = new DateRangeStore(
    'ordersDateRange', {
      startDate: dayjs().subtract(1, 'month').toDate(),
      endDate: dayjs().toDate(),
      color: colorScheme.blueGrey5,
      key: 'ordersDateRange',
    },
  )

  @computed get queryParams () {
    return {
      search: this.searchFilter.activeValue,
      orderStatus: this.orderStatusFilter.activeValue,
      fulfillmentStatus: this.orderFulfillmentStatusFilter.activeValue,
      orderSource: this.orderSourceFilter.activeValue,
      startDate: dayjs(
        this.dateRangeFilter.state.startDate,
      ).format('YYYY-MM-DD'),
      endDate: dayjs(
        this.dateRangeFilter.state.endDate,
      ).format('YYYY-MM-DD'),
    }
  }
}

export default OrdersFiltersStore
