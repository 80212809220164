import { useMediaQuery } from 'react-responsive'

const Sm = ({ children }) => {
  const isSm = useMediaQuery({ minWidth: 576 })
  return isSm ? children : null
}
const Md = ({ children }) => {
  const isMd = useMediaQuery({ minWidth: 768 })
  return isMd ? children : null
}
const Lg = ({ children }) => {
  const isLg = useMediaQuery({ minWidth: 992 })
  return isLg ? children : null
}
const Xl = ({ children }) => {
  const isXl = useMediaQuery({ minWidth: 1200 })
  return isXl ? children : null
}

const queries = {
  default: { maxWidth: 575 },
  sm: { minWidth: 576 },
  md: { minWidth: 768 },
  lg: { minWidth: 992 },
  xl: { minWidth: 1200 }
}

export {
  Sm, Md, Lg, Xl,
  queries
}
