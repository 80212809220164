import React, { forwardRef, useRef } from 'react'

import PropTypes from 'prop-types'

import { yupResolver } from '@hookform/resolvers/yup'
import { Button, Typography, TextField, Grid } from '@material-ui/core'
import { observer } from 'mobx-react'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'

import { Alert } from 'shared/components/molecules'
import { DataModal } from 'shared/components/organisms'
import { getFieldErrorMessage, submitForm, useRemoteErrors } from 'shared/utils/forms'

import { OrderModalActionState } from 'retailer/stores'

import style from './MarkFulfilledModal.module.scss'

const validationSchema = yup.object().shape({
  courierName: yup.string()
    .max(255, 'Courier name cannot be longer than 255 characters')
    .required('Please provide the courier name'),
  trackingCode: yup.string()
    .max(255, 'Tracking code cannot be longer than 255 characters')
    .required('Please provide the tracking code')
})

const MarkFulfilledForm = observer(forwardRef(function MarkFulfilledForm (props, ref) {
  // Apparently, using forwardRef throws prop-types off and does not properly read the proptypes declaration.
  // eslint-disable-next-line react/prop-types
  const { disableFields, onSubmit, remoteErrors } = props
  const { errors, handleSubmit, register, setError } = useForm({
    resolver: yupResolver(validationSchema)
  })
  const { nonFieldErrors } = useRemoteErrors(remoteErrors, setError)

  const sharedProps = {
    variant: 'outlined', // TODO: consider overriding in muiTheme
    fullWidth: true,
    disabled: disableFields,
    required: true
  }

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      ref={ref}
      className={style.MarkFulfilledForm}
    >
      <Grid container spacing={3}>
        {nonFieldErrors && (
          <Grid item xs={12}>
            <Alert severity="error" className={style.NonFieldError}>
              {nonFieldErrors.join(' ')}
            </Alert>
          </Grid>
        )}
        <Grid item xs={12} sm={6}>
          <TextField
            {...sharedProps}
            data-testid="mark_fulfilled_courier_name"
            label="Courier name"
            name="courierName"
            inputRef={register({ required: true, maxLength: 255 })}
            error={!!errors.courierName}
            helperText={getFieldErrorMessage(errors, 'courierName')}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            {...sharedProps}
            data-testid="mark_fulfilled_tracking_code"
            label="Tracking code"
            name="trackingCode"
            inputRef={register({ required: true, maxLength: 255 })}
            error={!!errors.trackingCode}
            helperText={getFieldErrorMessage(errors, 'trackingCode')}
          />
        </Grid>
      </Grid>
      <input data-testid="submit" type="submit" className={style.SubmitInput} />
    </form>
  )
}))

MarkFulfilledForm.propTypes = {
  disableFields: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  remoteErrors: PropTypes.object,
}

MarkFulfilledForm.defaultProps = {
  disableFields: false,
}

const MarkFulfilledModal = ({ store, onExited }) => {
  const formEl = useRef(null)
  const handleSubmitClick = () => submitForm(formEl)

  return (
    <DataModal
      fullWidth
      className={style.MarkFulfilledModal}
      showSpinner={store.inProgress}
      title="Mark as fulfilled"
      hide={store.done}
      onExited={onExited}
      renderBody={({ showSpinner }) => (
        <div className={style.ModalContent}>
          <Typography variant="body1" gutterBottom>
            To mark the order as fulfilled please enter the courier's name and the tracking code.
          </Typography>
          <MarkFulfilledForm
            ref={formEl}
            onSubmit={data => store.markFulfilled(data)}
            disableFields={showSpinner}
            remoteErrors={store.remoteErrors}
          />
        </div>
      )}
      renderActions={({ dismissModal, showSpinner }) => (
        <>
          <Button
            onClick={dismissModal}
            disabled={showSpinner}
            color="primary"
            size="large"
          >
            Cancel
          </Button>
          <Button
            onClick={handleSubmitClick}
            disabled={showSpinner}
            color="primary"
            variant="contained"
            size="large"
          >
            Mark as fulfilled
          </Button>
        </>
      )}
    />
  )
}

MarkFulfilledModal.propTypes = {
  store: PropTypes.instanceOf(OrderModalActionState).isRequired,
  onExited: PropTypes.func,
}

export default observer(MarkFulfilledModal)
