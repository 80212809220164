/* eslint camelcase: 0 */

import { keysToCamelCase } from 'shared/utils/api'

const adaptGetUserProfileResponse = ({ context_data, store_authorization, ...rest }) => ({
  context: keysToCamelCase(context_data),
  storeAuthorization: store_authorization && keysToCamelCase(store_authorization),
  ...keysToCamelCase(rest)
})

export {
  adaptGetUserProfileResponse,
}
