import React, { useCallback, useEffect, useState } from 'react'

import PropTypes from 'prop-types'

import { Chip, Grid, Tooltip } from '@material-ui/core'
import { createFilterOptions } from '@material-ui/lab/Autocomplete'
import isEmpty from 'lodash/isEmpty'
import pick from 'lodash/pick'
import { toJS } from 'mobx'
import { observer } from 'mobx-react'
import { useSnackbar } from 'notistack'
import { useFormContext, useWatch } from 'react-hook-form'

import { RhfTextField } from 'shared/components/atoms'
import {
  RETAILER_AUTHORIZATION_NONE,
  RETAILER_AUTHORIZATION_SHOPIFY,
  RETAILER_AUTHORIZATION_WIX,
} from 'shared/constants/accounts'
import { TAB_GENERAL } from 'shared/constants/importList'
import { useTranslation } from 'shared/hooks'
import { importListItemType } from 'shared/types'
import { getFieldErrorMessage } from 'shared/utils/forms'
import { checkFieldDirty } from 'shared/utils/forms/common'
import { ForIntegration } from 'shared/utils/integrations'

import { RhfAutocomplete } from 'retailer/components/molecules'
import { checkIfTabHasErrors, getGeneralTabDefaultValues } from 'retailer/utils/importList'

import style from './ImportListProductGeneralTab.module.scss'

const filter = createFilterOptions()

const ImportListProductGeneralTab = ({ item, errors }) => {
  const { t } = useTranslation('importList')
  const { enqueueSnackbar } = useSnackbar()
  const { control, getValues, formState, handleSubmit, setValue, reset, trigger, register } = useFormContext()
  const itemName = useWatch({ control, name: `${TAB_GENERAL}.name` }) || ''
  const [biEvent, setBiEvent] = useState(null)

  register(`${TAB_GENERAL}.name`)
  register(`${TAB_GENERAL}.vendor`)
  register(`${TAB_GENERAL}.categories`)
  register(`${TAB_GENERAL}.collections`)
  register(`${TAB_GENERAL}.productType`)
  register(`${TAB_GENERAL}.tags`)

  useEffect(() => { trigger() }, [trigger])

  const { isDirty, dirtyFields } = formState

  const resetForm = useCallback((data = {}) => {
    reset({
      ...getValues(),
      [TAB_GENERAL]: getGeneralTabDefaultValues(item, data)
    }, { errors: true })
    trigger()
  }, [item, reset, trigger])

  const onSubmit = async data => {
    if (!isDirty || isEmpty(dirtyFields)) return
    const pickedData = pick(data[TAB_GENERAL], Object.keys(dirtyFields[TAB_GENERAL]))
    const saved = await item.saveGeneralTab(pickedData, biEvent)
    // Clear the BI Event reference once the request has made it to the API
    setBiEvent(null)
    if (saved) {
      resetForm(pickedData)
      enqueueSnackbar(
        `${item.name || 'Item'} changes saved successfully`,
        { variant: 'success' }
      )
    } else {
      enqueueSnackbar(
        `${item.name || 'Item'} was not saved. Please, ensure there are no errors.`,
        { variant: 'error' }
      )
    }
  }

  const onError = async () => {
    if (!checkIfTabHasErrors(errors[TAB_GENERAL])) {
      await onSubmit(getValues())
      return
    }
    enqueueSnackbar(
      `${item.name || 'Item'} was not saved. Please, ensure there are no errors.`,
      { variant: 'error' }
    )
  }

  return (
    <div className={style.ImportListProductGeneralTab}>
      <Grid container spacing={4}>
        <Grid item xs={12} xl={6}>
          <Tooltip title={itemName} placement="top">
            <div>
              <RhfTextField
                fullWidth
                control={control}
                name={`${TAB_GENERAL}.name`}
                testId="importList-importListProduct-generalTab-productName"
                label={t('main.productTitle.label')}
                variant="outlined"
                error={getFieldErrorMessage(errors, `${TAB_GENERAL}.name`)}
                onBlur={async e => {
                  const fieldName = `${TAB_GENERAL}.name`
                  item.clearError(fieldName)
                  if (checkFieldDirty(dirtyFields, fieldName)) {
                    setBiEvent(
                      await item.logProductEdited({
                        correlationId: biEvent?.params?.correlationId,
                        settingTab: TAB_GENERAL,
                        settingField: 'name',
                        newValue: e.target.value,
                        oldValue: item.name,
                      })
                    )
                  }
                  await handleSubmit(onSubmit, onError)(e)
                }}
                InputLabelProps={{
                  shrink: true
                }}
              />
            </div>
          </Tooltip>
        </Grid>
        <ForIntegration
          integration={item.retailerAuthorization}
          allowedIntegrations={[RETAILER_AUTHORIZATION_SHOPIFY, RETAILER_AUTHORIZATION_WIX]}
        >
          <Grid item xs={12} xl={6}>
            <RhfAutocomplete
              name={`${TAB_GENERAL}.collections`}
              label={t('main.productCollections.label')}
              testId="importList-importListProduct-generalTab-productCollections"
              control={control}
              options={item.availableCollections}
              AutocompleteProps={{
                multiple: true,
                noOptionsText: 'Please create collections first',
                getOptionLabel: option => option.name,
                renderTags: (value, getTagProps) => value.map((option, index) => {
                  const { props } = getTagProps({ index })
                  return (
                    <Chip
                      key={index}
                      {...props}
                      className={style.Chip}
                      data-testid="importList-importListProduct-generalTab-productCollections-autocompleteCollectionChip"
                      onDelete={async () => {
                        const fieldName = `${TAB_GENERAL}.collections`
                        const newValue = getValues(fieldName).filter(collection => collection.uuid !== option.uuid)
                        setBiEvent(
                          await item.logProductEdited({
                            correlationId: biEvent?.params?.correlationId,
                            settingTab: TAB_GENERAL,
                            settingField: 'collections',
                            newValue: newValue,
                            oldValue: getValues(fieldName),
                          })
                        )
                        setValue(fieldName, newValue, { shouldDirty: true })
                        await handleSubmit(onSubmit, onError)()
                      }}
                      variant="outlined"
                      color="primary"
                      label={option.name}
                    />
                  )
                })
              }}
              TextFieldProps={{
                fullWidth: true,
                variant: 'outlined',
                onBlur: async e => {
                  const fieldName = `${TAB_GENERAL}.collections`
                  if (checkFieldDirty(dirtyFields, fieldName)) {
                    setBiEvent(
                      await item.logProductEdited({
                        correlationId: biEvent?.params?.correlationId,
                        settingTab: TAB_GENERAL,
                        settingField: 'collections',
                        newValue: getValues(fieldName),
                        oldValue: item.collections,
                      })
                    )
                  }
                  await handleSubmit(onSubmit, onError)(e)
                },
                error: !!getFieldErrorMessage(errors, `${TAB_GENERAL}.collections`),
                helperText: (
                  getFieldErrorMessage(errors, `${TAB_GENERAL}.collections`) ||
                  t('main.productCollectionsWixNote.text')
                ),
                InputLabelProps: {
                  shrink: true
                }
              }}
            />
          </Grid>
        </ForIntegration>
        <ForIntegration
          integration={item.retailerAuthorization}
          allowedIntegrations={[RETAILER_AUTHORIZATION_NONE, RETAILER_AUTHORIZATION_SHOPIFY]}
        >
          <Grid item xs={12} xl={6}>
            <RhfAutocomplete
              name={`${TAB_GENERAL}.productType`}
              label={t('main.productType.label')}
              testId="importList-importListProduct-generalTab-productType"
              control={control}
              options={item.productTypes}
              onChange={async (onChange, data) => {
                const fieldName = `${TAB_GENERAL}.productType`
                if (typeof data === 'string') {
                  const type = item.productTypes.find(productType => productType.name === data)
                  if (type) {
                    setBiEvent(
                      await item.logProductEdited({
                        correlationId: biEvent?.params?.correlationId,
                        settingTab: TAB_GENERAL,
                        settingField: 'productType',
                        newValue: toJS(type),
                        oldValue: toJS(getValues(fieldName)),
                      })
                    )
                    onChange(type)
                    return
                  }
                  const newType = await item.addProductType(data)
                  if (newType) {
                    setBiEvent(
                      await item.logProductEdited({
                        correlationId: biEvent?.params?.correlationId,
                        settingTab: TAB_GENERAL,
                        settingField: 'productType',
                        newValue: toJS(newType),
                        oldValue: toJS(getValues(fieldName)),
                      })
                    )
                    onChange(newType)
                  } else enqueueSnackbar('Could not add the new product type', { variant: 'error' })
                } else if (data && data.inputValue) {
                  const newType = await item.addProductType(data.inputValue)
                  if (newType) {
                    setBiEvent(
                      await item.logProductEdited({
                        correlationId: biEvent?.params?.correlationId,
                        settingTab: TAB_GENERAL,
                        settingField: 'productType',
                        newValue: toJS(newType),
                        oldValue: toJS(getValues(fieldName)),
                      })
                    )
                    onChange(newType)
                  } else enqueueSnackbar('Could not add the new product type', { variant: 'error' })
                } else {
                  setBiEvent(
                    await item.logProductEdited({
                      correlationId: biEvent?.params?.correlationId,
                      settingTab: TAB_GENERAL,
                      settingField: 'productType',
                      newValue: toJS(data),
                      oldValue: toJS(getValues(fieldName)),
                    })
                  )
                  onChange(data)
                }
              }}
              AutocompleteProps={{
                freeSolo: true,
                selectOnFocus: true,
                clearOnBlur: true,
                handleHomeEndKeys: true,
                filterOptions: (options, state) => {
                  const filtered = filter(options, state)
                  // Suggest the creation of a new value
                  if (
                    state.inputValue !== '' &&
                    !item.productTypes.find(productType => productType.name === state.inputValue)
                  ) {
                    filtered.unshift({
                      inputValue: state.inputValue,
                      name: `Add "${state.inputValue}"`,
                    })
                  }
                  return filtered
                },
                getOptionLabel: option => {
                  if (typeof option === 'string') return option
                  if (option.inputValue) return option.inputValue
                  return option.name
                },
              }}
              TextFieldProps={{
                fullWidth: true,
                variant: 'outlined',
                error: !!getFieldErrorMessage(errors, `${TAB_GENERAL}.productType`),
                helperText: getFieldErrorMessage(errors, `${TAB_GENERAL}.productType`),
                onBlur: handleSubmit(onSubmit, onError),
                InputLabelProps: {
                  shrink: true
                }
              }}
            />
          </Grid>
        </ForIntegration>
        <ForIntegration
          integration={item.retailerAuthorization}
          allowedIntegrations={[RETAILER_AUTHORIZATION_NONE, RETAILER_AUTHORIZATION_SHOPIFY]}
        >
          <Grid item xs={12} xl={6}>
            <RhfAutocomplete
              name={`${TAB_GENERAL}.tags`}
              label={t('main.productTags.label')}
              testId="importList-importListProduct-generalTab-productTags"
              control={control}
              options={item.availableTags}
              onChange={async (onChange, data) => {
                const tags = []
                data.forEach(optionData => {
                  let tag
                  if (typeof optionData === 'string') tag = { name: optionData }
                  else if (optionData && optionData.inputValue) tag = { name: optionData.inputValue }
                  else tag = { name: optionData.name }
                  // Only push the tag if it isn't already added
                  if (!tags.find(tagIter => tagIter.name === tag.name)) tags.push(tag)
                })
                const biEventLocal = await item.logProductEdited({
                  settingTab: TAB_GENERAL,
                  settingField: 'tags',
                  newValue: tags,
                  oldValue: item.tags,
                })
                if (!tags.length) {
                  const removed = await item.removeAllTags(biEventLocal)
                  if (!removed) enqueueSnackbar('Could not remove tags', { variant: 'error' })
                  resetForm()
                  return
                }
                const savedTags = await item.addTags(tags, biEventLocal)
                if (!savedTags) {
                  enqueueSnackbar('Could not add tags', { variant: 'error' })
                  return
                }
                onChange(tags.map(originalOption => {
                  const newTag = savedTags.find(
                    opt => opt.name === originalOption.name || opt.name === originalOption?.inputValue
                  )
                  if (!newTag) return originalOption
                  return {
                    uuid: newTag.uuid,
                    name: newTag.name
                  }
                }))
              }}
              AutocompleteProps={{
                multiple: true,
                filterSelectedOptions: true,
                getOptionLabel: option => {
                  if (typeof option === 'string') return option
                  return option.name
                },
                renderTags: (value, getTagProps) => value.map((option, index) => (
                  <Chip
                    key={index}
                    {...getTagProps({ index })}
                    className={style.Chip}
                    onDelete={async () => {
                      const biEventLocal = await item.logProductEdited({
                        settingTab: TAB_GENERAL,
                        settingField: 'tags',
                        newValue: item.tags.filter(tag => tag.uuid !== option.uuid),
                        oldValue: item.tags,
                      })
                      const removed = await item.removeTag(option.uuid, biEventLocal)
                      if (!removed) enqueueSnackbar('Could not remove tag', { variant: 'error' })
                      resetForm()
                    }}
                    variant="outlined"
                    color="primary"
                    label={option.name}
                  />
                )),
                freeSolo: true,
                selectOnFocus: true,
                clearOnBlur: true,
                handleHomeEndKeys: true,
                filterOptions: (options, params) => {
                  const currentTags = getValues(`${TAB_GENERAL}.tags`)
                  const currentUuids = currentTags.map(option => option.uuid)
                  const currentNames = currentTags.map(option => option.name)
                  const filtered = filter(options, params).filter(option => {
                    if (option?.uuid) return !currentUuids.includes(option.uuid)
                    return !currentNames.includes(option.name)
                  })
                  // Suggest the creation of a new value
                  if (
                    params.inputValue !== '' &&
                    !item.availableTags.find(tag => tag.name === params.inputValue) &&
                    !currentNames.includes(params.inputValue)
                  ) {
                    filtered.unshift({
                      inputValue: params.inputValue,
                      name: params.inputValue,
                    })
                  }
                  return filtered
                },
              }}
              TextFieldProps={{
                fullWidth: true,
                variant: 'outlined',
                error: !!getFieldErrorMessage(errors, `${TAB_GENERAL}.tags`),
                helperText: getFieldErrorMessage(errors, `${TAB_GENERAL}.tags`),
                InputLabelProps: {
                  shrink: true
                }
              }}
            />
          </Grid>
        </ForIntegration>
        <Grid item xs={12} xl={6}>
          <RhfTextField
            fullWidth
            control={control}
            name={`${TAB_GENERAL}.vendor`}
            label={t('main.productVendor.label')}
            testId="importList-importListProduct-generalTab-productVendor"
            variant="outlined"
            error={getFieldErrorMessage(errors, `${TAB_GENERAL}.vendor`)}
            onBlur={async e => {
              e.persist()
              const fieldName = `${TAB_GENERAL}.vendor`
              const newValue = e.target.value.trim()
              item.clearError(fieldName)
              setValue(fieldName, newValue)
              if (checkFieldDirty(dirtyFields, fieldName)) {
                setBiEvent(
                  await item.logProductEdited({
                    correlationId: biEvent?.params?.correlationId,
                    settingTab: TAB_GENERAL,
                    settingField: 'vendor',
                    newValue: newValue,
                    oldValue: item.vendor,
                  })
                )
              }
              await handleSubmit(onSubmit, onError)(e)
            }}
            InputLabelProps={{
              shrink: true
            }}
          />
        </Grid>
      </Grid>
    </div>
  )
}

ImportListProductGeneralTab.propTypes = {
  item: importListItemType.isRequired,
  errors: PropTypes.object,
}

ImportListProductGeneralTab.defaultProps = {
  errors: {}
}

export default observer(ImportListProductGeneralTab)
