import React, { useCallback, useLayoutEffect, useRef, useState } from 'react'

import PropTypes from 'prop-types'

import { Box, ButtonBase, Divider, Paper, Typography } from '@material-ui/core'
import clsx from 'clsx'
import { observer } from 'mobx-react'
import { OutboundLink } from 'react-ga'
import { ExternalLink } from 'wix-ui-icons-common'

import { EVENT_LABELS } from 'shared/analytics'
import { ALIBABA, ALIEXPRESS } from 'shared/constants/integrations'
import { useTranslation } from 'shared/hooks'
import { Alibaba, AliExpress } from 'shared/icons/external'

import { useStores } from 'retailer/stores/hooks'

import style from './SupplierMarketplaceButtons.module.scss'

const MarketplaceButton = ({ disabled, name, to, Icon, onClick, children }) => {
  const handleClick = event => {
    if (!onClick() || !to) {
      event.preventDefault()
    }
  }
  return (
    <ButtonBase
      component={OutboundLink}
      eventLabel={EVENT_LABELS.navigateToIntegrationProducts(name)}
      onClick={handleClick}
      to={to || undefined}
      target="_blank"
      disabled={disabled}
      className={style.Button}
      size="large"
    >
      <Icon width={32} height={32} />
      <Typography variant="body1" color="textPrimary">{children}</Typography>
      <ExternalLink size={30} />
    </ButtonBase>
  )
}

MarketplaceButton.propTypes = {
  disabled: PropTypes.bool,
  Icon: PropTypes.elementType,
  name: PropTypes.string.isRequired,
  to: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  children: PropTypes.node,
}

MarketplaceButton.defaultProps = {
  disabled: false,
}

const SupplierMarketplaceButtons = ({ className, ...boxProps }) => {
  const { t } = useTranslation('mainDashboard', { keyPrefix: 'modalyst.dashboard' })

  const paperRef = useRef(null)
  const [isVertical, setIsVertical] = useState()

  const adjustLayout = useCallback(() => {
    setIsVertical(paperRef.current?.offsetWidth < 700)
  }, [paperRef.current])

  useLayoutEffect(() => {
    adjustLayout()
    window.addEventListener('resize', adjustLayout)
    return () => window.removeEventListener('resize', adjustLayout)
  }, [])

  const { connectToSuppliersStore } = useStores()
  const { isLoading, alibaba, aliexpress, navigateToMarketplace } = connectToSuppliersStore

  return (
    <Paper
      ref={paperRef}
      className={clsx(style.Root, isVertical && style.isVertical, className)}
      component={Box}
      display="flex" flexDirection={isVertical ? 'column' : 'row'} {...boxProps}
    >
      <MarketplaceButton
        name={ALIEXPRESS}
        Icon={AliExpress}
        to={aliexpress?.marketplaceUrl || undefined}
        onClick={() => navigateToMarketplace(ALIEXPRESS)}
        disabled={isLoading}
      >
        {t('findProducts.aliexpress')}
      </MarketplaceButton>
      <Divider orientation={isVertical ? 'horizontal' : 'vertical'} variant="fullWidth" className={style.Divider} />
      <MarketplaceButton
        name={ALIBABA}
        Icon={Alibaba}
        to={alibaba?.marketplaceUrl || undefined}
        onClick={() => navigateToMarketplace(ALIBABA)}
        disabled={isLoading}
      >
        {t('findProducts.alibaba')}
      </MarketplaceButton>
    </Paper>
  )
}

SupplierMarketplaceButtons.propTypes = {
  className: PropTypes.string,
  ...Box.propTypes,
}

export default observer(SupplierMarketplaceButtons)
