import dayjs from 'dayjs'

import { keysToCamelCase, keysToSnakeCase } from 'shared/utils/api'

const adaptPublicInfo = (data) => {
  return {
    description: data.description,
    established: data.established ? new Date(data.established) : null,
    logo: data.logo,
    storeName: data.store_name
  }
}

/**
 * Adapts public info form data to format required by the API.
 * @param {Object} data
 * @returns {{established: *, store_name: (string|*), description, logo: (*|null)}}
 */
const adaptPublicInfoForm = (data) => {
  return {
    store_name: data?.storeName,
    established: data?.established ? dayjs(data.established).format('YYYY-MM-DD') : undefined,
    description: data?.description,
    logo: data?.logo === null ? null : data?.logo?.dataUrl,
  }
}

// eslint-disable-next-line camelcase
const adaptPublicInfoErrors = ({ non_field_errors, ...rest }) => {
  return {
    nonFieldErrors: non_field_errors,
    fieldErrors: {
      storeName: rest?.store_name,
      established: rest?.established,
      description: rest?.description,
      logo: rest?.logo
    }
  }
}

const adaptMarketplacesErrors = data => {
  return {
    nonFieldErrors: data.non_field_errors
  }
}

// eslint-disable-next-line camelcase
const adaptLocationErrors = ({ non_field_errors, ...rest }) => {
  return {
    nonFieldErrors: non_field_errors,
    fieldErrors: {
      location: rest?.location
    }
  }
}

// eslint-disable-next-line camelcase
const adaptSocialMediaErrors = ({ non_field_errors, ...rest }) => {
  return {
    nonFieldErrors: non_field_errors,
    fieldErrors: {
      facebook: rest?.facebook,
      instagram: rest?.instagram,
      website: rest?.website
    }
  }
}

/**
 * Some values come as null when non present, other come as empty strings.
 * Here we change all null values into empty strings to keep it consistent.
 * This also helps with react-hook-form improperly marking fields as dirt.
 *
 * `is_complete` is not affected by this as it should be a boolean value.
 */
// eslint-disable-next-line camelcase
const adaptBusinessInformation = ({ is_complete, ...rest }) => {
  const nonNullObj = {
    isComplete: is_complete
  }
  Object.keys(rest).forEach(key => {
    nonNullObj[key] = rest[key] || ''
  })
  return keysToCamelCase(nonNullObj)
}

const adaptBusinessInformationForm = data => {
  return keysToSnakeCase(data)
}

// eslint-disable-next-line camelcase
const adaptBusinessInformationErrors = ({ non_field_errors, ...rest }) => {
  return {
    fieldErrors: keysToCamelCase(rest),
    nonFieldErrors: non_field_errors
  }
}

/**
 * Some values come as null when non present, other come as empty strings.
 * Here we change all null values into empty strings to keep it consistent.
 * This also helps with react-hook-form improperly marking fields as dirt.
 */
// eslint-disable-next-line camelcase
const adaptShippingInformation = data => {
  const nonNullObj = {}
  Object.keys(data).forEach(key => {
    nonNullObj[key] = data[key] || ''
  })
  return keysToCamelCase(data)
}

const adaptShippingInformationForm = data => {
  return keysToSnakeCase(data)
}

// eslint-disable-next-line camelcase
const adaptShippingInformationErrors = ({ non_field_errors, ...rest }) => {
  return {
    fieldErrors: keysToCamelCase(rest),
    nonFieldErrors: non_field_errors
  }
}

const adaptUserBasicInfo = data => {
  return {
    contactEmail: data.email,
    profileImage: data.profile_image,
  }
}

const adaptUserBasicInfoFormData = data => {
  return {
    email: data?.contactEmail,
    profile_image: data?.profileImage === null ? null : data?.profileImage?.dataUrl,
  }
}

// eslint-disable-next-line camelcase
const adaptUserBasicInfoSettingsError = ({ non_field_errors, ...rest }) => {
  return {
    nonFieldErrors: non_field_errors,
    fieldErrors: {
      contactEmail: rest?.email
    }
  }
}

const adaptChangePasswordForm = ({ password, newPassword }) => {
  return {
    password,
    new_password: newPassword
  }
}

// eslint-disable-next-line camelcase
const adaptChangePasswordErrors = ({ non_field_errors, ...rest }) => {
  return {
    nonFieldErrors: non_field_errors,
    fieldErrors: {
      password: rest?.password,
      newPassword: rest?.new_password
    }
  }
}

const adaptUserRequestedData = data => {
  return {
    userRequestedPersonalData: data.requested_personal_data
  }
}

const adaptUserRequestedAccountRemoval = data => {
  return {
    userRequestedAccountRemoval: data.requested_account_removal,
    requestDatetime: data.request_datetime
  }
}

export {
  adaptPublicInfo,
  adaptPublicInfoForm,
  adaptPublicInfoErrors,
  adaptMarketplacesErrors,
  adaptLocationErrors,
  adaptSocialMediaErrors,
  adaptBusinessInformation,
  adaptBusinessInformationForm,
  adaptBusinessInformationErrors,
  adaptShippingInformation,
  adaptShippingInformationForm,
  adaptShippingInformationErrors,
  adaptUserBasicInfo,
  adaptUserBasicInfoFormData,
  adaptUserBasicInfoSettingsError,
  adaptChangePasswordForm,
  adaptChangePasswordErrors,
  adaptUserRequestedData,
  adaptUserRequestedAccountRemoval
}
