import React from 'react'

import PropTypes from 'prop-types'

import { Tooltip } from '@material-ui/core'
import { ToggleButton, ToggleButtonGroup as MuiToggleButtonGroup } from '@material-ui/lab'
import { observer } from 'mobx-react'

import style from './ToggleButtonGroup.module.scss'

/**
 * Custom ToggleButtonGroup with some specific features:
 * - always acts as "exclusive"
 * - prevents the user from deselecting a button (acts as a radio group)
 * - takes `options` prop and outputs ToggleButtons according to its contents
 * - allows for showing a tooltip on a ToggleButton
 */
const ToggleButtonGroup = ({ options, value, onChange, testId, ...rest }) => {
  const handleChange = (event, newValue) => {
    if (onChange && newValue !== null) {
      onChange(event, newValue)
    }
  }
  return (
    <MuiToggleButtonGroup
      exclusive
      value={value}
      className={style.ToggleButtonGroup}
      onChange={handleChange}
      data-testid={testId}
      {...rest}
    >
      {options.map(({ value: _value, label, tooltip, testId }) => (
        <Tooltip key={_value.toString()} title={tooltip || ''} placement="top">
          <ToggleButton value={_value} selected={_value === value} data-testid={testId}>
            {label}
          </ToggleButton>
        </Tooltip>
      ))}
    </MuiToggleButtonGroup>
  )
}

ToggleButtonGroup.propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.any.isRequired,
    label: PropTypes.string.isRequired,
    tooltip: PropTypes.string,
    testId: PropTypes.string,
  })),
  testId: PropTypes.string,
  value: PropTypes.any.isRequired,
  onChange: PropTypes.func,
}

export default observer(ToggleButtonGroup)
