import { action, observable } from 'mobx'

class LoadableStore {
  @observable isLoaded = false
  @observable isLoading = false

  @action.bound
  setLoading () {
    this.isLoaded = false
    this.isLoading = true
  }

  @action.bound
  unsetLoading () {
    this.isLoading = false
  }

  @action.bound
  setLoaded () {
    this.isLoaded = true
    this.isLoading = false
  }

  @action.bound
  reset () {
    this.isLoaded = false
    this.isLoading = false
  }
}

export default LoadableStore
