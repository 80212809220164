import React, { useRef } from 'react'

import PropTypes from 'prop-types'

import DayJsUtils from '@date-io/dayjs'
import { yupResolver } from '@hookform/resolvers/yup'
import { Grid, TextField, Typography } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import clsx from 'clsx'
import { observer } from 'mobx-react'
import { useSnackbar } from 'notistack'
import { Controller, useForm } from 'react-hook-form'
import * as yup from 'yup'

import { CardForm } from 'shared/components/organisms'
import { getFieldErrorMessage, useRemoteErrors } from 'shared/utils/forms'

import { ImageUpload } from 'retailer/components/organisms'
import { ProfileSettingsStore } from 'retailer/stores'

import style from './PublicInfoForm.module.scss'

const validationSchema = yup.object().shape({
  logo: yup.object().nullable(),
  storeName: yup.string()
    .min(3, 'Store name must be at least 3-characters-long')
    .max(100, 'Store name cannot have more than 100 characters')
    .required('Please provide your store name'),
  description: yup.string()
    .max(5000, 'Description cannot be longer than 5000 characters')
    .required('Store description is required'),
  established: yup.date().required('Please provide established/launching date for your store')
})

const PublicInfoForm = ({ store }) => {
  const ref = useRef(null)
  const { enqueueSnackbar } = useSnackbar()
  const defaultValues = {
    storeName: store.storeName,
    established: store.established,
    description: store.description,
    logo: store.logo
  }
  const { handleSubmit, errors, register, control, reset, formState, setValue, setError } = useForm({
    defaultValues,
    resolver: yupResolver(validationSchema)
  })
  const { nonFieldErrors } = useRemoteErrors(store.errors, setError)

  const onSubmit = async (data) => {
    await store.save(data, formState.dirtyFields)
    reset({
      storeName: store.storeName,
      established: store.established,
      description: store.description,
      logo: store.logo
    })
    enqueueSnackbar('Store profile saved successfully', {
      variant: 'success'
    })
  }

  return (
    <CardForm
      title="Store Details"
      formRef={ref}
      canSubmit={formState.isDirty && !Object.keys(errors).length}
      isSubmitting={store.isSaving}
      onDiscard={formState.isDirty ? () => reset() : null}
    >
      {nonFieldErrors && (
        <Alert severity="error" className={style.Errors}>
          {nonFieldErrors.join(' ')}
        </Alert>
      )}
      <form
        ref={ref}
        className={style.PublicInfoForm}
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className={style.LogoSection}>
          <Grid container spacing={3} alignItems="center">
            <Grid item xs={12} lg={8}>
              <Controller
                control={control}
                id="logo"
                name="logo"
                defaultValue={store.logo}
                render={({ value, onChange }) => (
                  <ImageUpload
                    file={value}
                    onChange={file => onChange(file)}
                    onClear={() => setValue('logo', null)}
                    error={errors.logo}
                    dataURLKey="dataUrl"
                    maxFileSize="800000"
                    acceptType={['jpg', 'jpeg', 'gif', 'png']}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} lg={4}>
              <Typography variant="body2">
                This logo will be placed on your branded invoices
              </Typography>
            </Grid>
          </Grid>
        </div>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TextField
              id="storeName"
              name="storeName"
              label="Store Name"
              variant="outlined"
              data-testid="PublicInfoForm-storeName"
              required
              className={clsx(style.Input)}
              inputRef={register({ required: true, maxLength: 100 })}
              error={!!errors.storeName}
              helperText={getFieldErrorMessage(errors, 'storeName')}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              control={control}
              id="established"
              name="established"
              rules={{ required: true }}
              render={({ value, onChange }) => (
                <MuiPickersUtilsProvider utils={DayJsUtils}>
                  <KeyboardDatePicker
                    autoOk
                    variant="inline"
                    inputVariant="outlined"
                    format="MM/DD/YYYY"
                    margin="normal"
                    label="Established Date"
                    value={value}
                    placeholder="MM/DD/YYYY"
                    onChange={(data) => {
                      if (data && data.$d) onChange(data.$d)
                    }}
                    KeyboardButtonProps={{
                      'aria-label': 'Change Date',
                    }}
                    inputRef={register({ required: true })}
                    required
                    inputProps={{
                      required: true
                    }}
                    error={!!errors.established}
                    helperText={getFieldErrorMessage(errors, 'established')}
                    className={clsx(style.Input)}
                  />
                </MuiPickersUtilsProvider>
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="description"
              name="description"
              label="Description"
              placeholder="Tell suppliers about your store so they can get to know you"
              variant="outlined"
              className={style.Input}
              multiline
              required
              minRows={6}
              inputRef={register({ required: true, maxLength: 5000 })}
              error={!!errors.description}
              helperText={getFieldErrorMessage(errors, 'description') || 'Max. 5000 characters'}
            />
          </Grid>
        </Grid>
      </form>
    </CardForm>
  )
}

PublicInfoForm.propTypes = {
  store: PropTypes.instanceOf(ProfileSettingsStore).isRequired
}

export default observer(PublicInfoForm)
