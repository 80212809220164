import { action, computed, observable } from 'mobx'

import { PRICING_ACTIONS_CONFIG } from './constants'

class PricingActionStore {
  /** @type {import('../context').RootStore} */
  root

  /** type of action */
  actionCode
  @observable inProgress
  @observable isDone = false
  @observable isError

  /**
   * @param {import('../context').RootStore} root
   */
  constructor (root, actionCode) {
    this.root = root
    this.actionCode = actionCode
  }

  @computed get actionConfig () {
    return PRICING_ACTIONS_CONFIG.get(this.actionCode)
  }

  @computed get confirmContent () {
    return this.actionConfig.confirm
  }

  @computed get doneContent () {
    return this.actionConfig.done
  }

  @computed get title () {
    return this.actionConfig.title
  }

  @computed get buttonLabel () {
    return this.title
  }

  @action.bound async confirm () {
    this.inProgress = true
    this.isError = undefined
    try {
      await this.actionConfig.apiFn()
      this.isDone = true
      this.inProgress = false
    } catch (error) {
      this.isError = true
      this.inProgress = false
    }
  }
}

export default PricingActionStore
