import { action, observable } from 'mobx'

import {
  adaptShippingInformation,
  adaptShippingInformationErrors,
  adaptShippingInformationForm,
} from 'shared/adapters/settings'
import { getShippingInfo, saveShippingInfo } from 'shared/api/settings'
import { processApiError } from 'shared/api/utils'
import { LoadableStore } from 'shared/stores'

class ShippingInformationStore {
  parent

  @observable loading = new LoadableStore()
  @observable isSaving = false
  @observable errors
  @observable lastUpdate = new Date()

  @observable sameAsBusinessInfo = true

  @observable contactName = ''
  @observable organizationName = ''
  @observable address = ''
  @observable zipCode = ''
  @observable city = ''
  @observable state = ''
  @observable country = ''
  @observable phoneNumber = ''

  constructor (parent) {
    this.parent = parent
  }

  @action.bound
  fetch () {
    this.loading.setLoading()
    this.errors = null
    return getShippingInfo()
      .then(response => {
        Object.assign(this, adaptShippingInformation(response.data))
        this.lastUpdate = new Date()
      })
      .catch(error => {
        if (error.response.status === 404) return
        this.errors = processApiError(error, adaptShippingInformationErrors)
      })
      .finally(() => {
        this.loading.setLoaded()
      })
  }

  @action.bound
  refresh (delay = 0) {
    this.errors = null
    return setTimeout(() => {
      getShippingInfo()
        .then(response => {
          Object.assign(this, adaptShippingInformation(response.data))
          this.lastUpdate = new Date()
        })
        .catch(error => {
          this.errors = processApiError(error, adaptShippingInformationErrors)
        })
    }, delay)
  }

  @action.bound
  save (data) {
    this.errors = null
    this.isSaving = true
    return saveShippingInfo(adaptShippingInformationForm(data))
      .then(response => {
        Object.assign(this, adaptShippingInformation(response.data))
      })
      .catch(error => {
        this.errors = processApiError(error, adaptShippingInformationErrors)
        throw error
      })
      .finally(() => {
        this.isSaving = false
      })
  }
}

export default ShippingInformationStore
