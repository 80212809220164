import React from 'react'

import PropTypes from 'prop-types'

import { Typography } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { observer } from 'mobx-react'
import { useSnackbar } from 'notistack'

import { CardForm } from 'shared/components/organisms'
import { UserRequestDataStore } from 'shared/stores'

import style from './UserRequestDataForm.module.scss'

const UserRequestDataForm = ({ store }) => {
  const { enqueueSnackbar } = useSnackbar()

  const request = () => {
    store.requestData().then(() => {
      enqueueSnackbar('Data was requested successfully', {
        variant: 'success',
      })
    })
  }

  return (
    <CardForm
      className={style.UserRequestDataForm}
      title="Request data"
      subheader={
        `If you would like to receive a digital copy of your personal data
        on our system please click the button below.`
      }
      submitText={store.userRequestedPersonalData ? 'Data requested' : 'Request data'}
      discardAvailable={false}
      isSubmitting={store.isSaving}
      onSave={request}
      canSubmit={!store.isSaving && !store.userRequestedPersonalData}
    >
      {store.error && (
        <Alert severity="error" className={style.Errors}>
          Something went wrong. Please, try again later.
        </Alert>
      )}
      {store.userRequestedPersonalData && (
        <>
          <Typography variant="body1" className={style.Bold}>
            You have requested your personal data.
          </Typography>
          <Typography variant="body1">
            After it's prepared, it will be provided to you in an e-mail message.
          </Typography>
        </>
      )}
    </CardForm>
  )
}

UserRequestDataForm.propTypes = {
  store: PropTypes.instanceOf(UserRequestDataStore).isRequired
}

export default observer(UserRequestDataForm)
