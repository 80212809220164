import { action, computed, observable } from 'mobx'

import { adaptRetailerOrder } from 'shared/adapters/orders'
import { getOrder } from 'shared/api/orders'

import { AddressStore, PurchaseOrder } from 'retailer/stores'

class RetailerOrder {
  @observable uuid
  @observable placedOn
  @observable orderNumber
  @observable externalId
  @observable externalUrl
  @observable placementProgress
  @observable fulfillmentProgress
  @observable paymentProgress
  @observable cancellationProgress
  @observable refundProgress
  @observable isPaidByCustomer = false
  @observable address = null
  @observable isShippingAddressLocked
  @observable shippingAddressValidationMode
  @observable totalCosts

  @observable alerts = []
  @observable purchaseOrders = []

  /**
   * @param {Object}        data    Optional initial data
   */
  constructor (data = {}) {
    this.update(data)
  }

  /**
   * Is the order a manual order?
   * @returns {boolean}
   */
  @computed get isManual () {
    return this.orderNumber === null
  }

  /**
   * Does the order have any 3rd party supplier orders (like Alibaba and AliExpress)?
   * @returns {boolean}
   */
  @computed get has3rdPartySupplierOrders () {
    return this.purchaseOrders.some(order => order.is3rdPartySupplierOrder)
  }

  @computed get hasAlibabaOrders () {
    return this.purchaseOrders.some(order => order.isAlibaba)
  }

  @computed get hasAliexpressOrders () {
    return this.purchaseOrders.some(order => order.isAliExpress)
  }

  @action.bound
  update (data) {
    const { address, purchaseOrders, ...rest } = data
    Object.assign(this, rest)
    this.address = new AddressStore(address)
    this.purchaseOrders = purchaseOrders
      ? purchaseOrders.map(order => new PurchaseOrder(this, order))
      : []
  }

  @action.bound
  refreshFromRemote () {
    return getOrder(this.uuid)
      .then(({ data }) => {
        this.update(adaptRetailerOrder(data))
      })
  }

  /**
   * Add order or orders.
   * @param orders
   */
  @action.bound
  addOrders (...orders) {
    this.purchaseOrders.push(...orders)
  }
}

export default RetailerOrder
