/**
 * Get scrollbar size in pixels
 *
 * Straight up copy from material-ui/packages/material-ui/src/utils/getScrollbarSize.js
 * unfortunately it's not exported
 */
function getScrollbarSize () {
  const scrollDiv = document.createElement('div')
  scrollDiv.style.width = '99px'
  scrollDiv.style.height = '99px'
  scrollDiv.style.position = 'absolute'
  scrollDiv.style.top = '-9999px'
  scrollDiv.style.overflow = 'scroll'

  document.body.appendChild(scrollDiv)
  const scrollbarSize = scrollDiv.offsetWidth - scrollDiv.clientWidth
  document.body.removeChild(scrollDiv)

  return scrollbarSize
}

/**
 * Enable or disable scrolling and scrollbar on a container.
 *
 * @param {Boolean} enable - enable scrolling if true-ish, disable otherwise
 */
function adjustContainerScroll (enable) {
  const container = document.body
  if (enable) {
    container.style.paddingRight = null
    container.style.overflowY = 'scroll'
  } else {
    container.style.paddingRight = `${getScrollbarSize()}px`
    container.style.overflowY = 'hidden'
  }
}

export {
  getScrollbarSize,
  adjustContainerScroll,
}
