import { noCase, camelCase, snakeCase } from 'change-case'
import { titleCase } from 'title-case'

/**
 * Format given string to title case, i.e. change `test_string` to `Test String`,
 * `camelCaseString` to `Camel Case String` etc.
 * @param {String} stringToFormat
 * @return {String}
 */
const toTitleCase = (stringToFormat = '') => {
  return titleCase(noCase(String(stringToFormat)))
}

const toCamelCase = (stringToFormat = '') => {
  return camelCase(noCase(String(stringToFormat)))
}

const toSnakeCase = (stringToFormat = '') => {
  return snakeCase(noCase(String(stringToFormat)))
}

export {
  toTitleCase,
  toCamelCase,
  toSnakeCase,
}
