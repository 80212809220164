/* eslint-disable react/prop-types */
import React, { useRef } from 'react'

import PropTypes from 'prop-types'

import { yupResolver } from '@hookform/resolvers/yup'
import {
  Button,
  FormControl,
  FormLabel,
  RadioGroup,
  Typography,
  Grid,
  TextField,
  useTheme, FormControlLabel, Switch, FormHelperText,
} from '@material-ui/core'
import {
  CloseRounded,
  DateRangeRounded, ShoppingCartRounded,
  StarsRounded,
} from '@material-ui/icons'
import { CardElement, useRecurly } from '@recurly/react-recurly'
import clsx from 'clsx'
import { observer } from 'mobx-react'
import { Controller, useForm } from 'react-hook-form'
import * as yup from 'yup'

import { RadioTile, Time } from 'shared/components/atoms'
import { CountrySelect, Alert } from 'shared/components/molecules'
import { DataModal } from 'shared/components/organisms'
import { PLAN_MONTHLY, PLAN_YEARLY } from 'shared/constants/settings'
import { getFieldErrorMessage } from 'shared/utils/forms'

import { SubscriptionPlanChangeStore } from 'retailer/stores'
import { useStores } from 'retailer/stores/hooks'

import style from './PlanUpgradeModal.module.scss'
import { adaptFormDataForRecurly, setRecurlyErrors } from './utils'

const validationSchema = yup.object().shape({
  firstName: yup.string()
    .required('First name is required')
    .min(2, 'First name must be at least 2 characters long')
    .max(50, 'First name cannot be longer than 50 characters'),
  lastName: yup.string()
    .required('Last name is required')
    .min(2, 'Last name must be at least 2 characters long')
    .max(50, 'last name cannot be longer than 50 characters'),
  streetAddress: yup.string()
    .required('Street address is required')
    .min(3, 'Street address must be at least 3 characters long')
    .max(50, 'Street address cannot be longer than 50 characters'),
  suiteApt: yup.string()
    .required('Suite and apartment number are required')
    .max(50, 'Suite and apartment number cannot be longer than 50 characters'),
  city: yup.string()
    .required('City name is required')
    .max(50, 'City name cannot be longer than 50 characters'),
  state: yup.string()
    .required('State is required')
    .max(50, 'State name cannot be longer than 50 characters'),
  zipCode: yup.string()
    .required('ZIP code is required')
    .max(50, 'ZIP code cannot be longer than 50 characters'),
  country: yup.string()
    .required('Country is required')
    .min(2, 'Invalid value provided')
    .max(2, 'Invalid value provided'),
})

const PlanUpgradeModal = ({ store, onExited }) => {
  const { mainMenuStore } = useStores()
  const formRef = useRef(null)
  const recurly = useRecurly()

  const { handleSubmit, errors, register, control, setError } = useForm({
    resolver: yupResolver(validationSchema)
  })
  const theme = useTheme()

  const commonInputProps = {
    variant: 'outlined',
    fullWidth: true,
    required: true,
    disabled: store.inProgress
  }

  const submitForm = () => {
    // When reusing billing info we don't need Recurly token
    if (store.reuseBillingInfo) {
      return store.buyPlan().then(() => {
        mainMenuStore.retailerSubscription.refresh()
      })
    }
    if (formRef.current) formRef.current.dispatchEvent(new Event('submit', { cancelable: true }))
  }

  const onSubmit = data => {
    store.setInProgress(true)
    store.setRecurlyError(null)
    store.setRecurlyToken({
      id: null,
      type: null
    })

    recurly.token(adaptFormDataForRecurly(data), (error, token) => {
      if (error) {
        setRecurlyErrors(error, setError)
        store.setRecurlyError(error)
        store.setInProgress(false)
        return
      }
      store.setRecurlyToken(token)
      return store.buyPlan().then(() => {
        mainMenuStore.retailerSubscription.refresh()
      })
    })
  }

  return (
    <DataModal
      className={style.PlanUpgradeModal}
      title="Change Your Plan"
      maxWidth="md"
      fullWidth
      onExited={onExited}
      showSpinner={store.inProgress}
      renderBody={() => {
        if (store.isSuccess) {
          return (
            <>
              <Alert
                severity="success"
                className={style.Success}
                data-testid="paymentUpgradeModal-paymentReceivedAlert"
              >
                Payment received!
              </Alert>
              <Typography paragraph>
                Your subscription has been successfully upgraded to the {store.plan.userFriendlyName} plan.
                Please check your email for a receipt.
              </Typography>
            </>
          )
        }
        return (
          <form ref={formRef} onSubmit={handleSubmit(onSubmit)}>
            <div className={style.HeadingArea}>
              <Grid container spacing={3} alignItems="center" justifyContent="space-between">
                <Grid item>
                  <Typography>
                    Subscription start date
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography component="div">
                    <Time value={new Date()} />
                  </Typography>
                </Grid>
              </Grid>
              <Typography className={style.PlanName}>
                Modalyst {store.plan.shortName} Plan
              </Typography>
              <Typography paragraph>
                {store.plan.lead}
              </Typography>
              <FormControl component="fieldset" fullWidth>
                <FormLabel component="legend" hidden>Billing Period</FormLabel>
                <RadioGroup
                  row
                  aria-label="Billing Period"
                  id="billingPeriod"
                  name="billingPeriod"
                  value={store.billingPeriod}
                  onChange={e => store.changeBillingPeriod(e.target.value)}
                  className={style.RadioGroup}
                >
                  <RadioTile
                    value={PLAN_YEARLY}
                    disabled={store.inProgress || store.isCurrentPlan(store.getPlanForPeriod(PLAN_YEARLY))}
                    checked={store.billingPeriod === PLAN_YEARLY}
                    icon={StarsRounded}
                    size="large"
                    data-testid="planUpgradeModal-yearlyTile"
                    disableTypography
                    label={
                      <div className={style.Label}>
                        <Typography variant="body1">
                          <strong>Billed Yearly ({store.getPlanForPeriod(PLAN_YEARLY).monthlyPrice}/month)</strong>
                        </Typography>
                        <Typography variant="body2" color="textSecondary">
                          {store.getPlanForPeriod(PLAN_YEARLY).priceText} paid upfront
                        </Typography>
                      </div>
                    }
                  />
                  <RadioTile
                    value={PLAN_MONTHLY}
                    disabled={store.inProgress || store.isCurrentPlan(store.getPlanForPeriod(PLAN_MONTHLY))}
                    checked={store.billingPeriod === PLAN_MONTHLY}
                    icon={DateRangeRounded}
                    size="large"
                    data-testid="planUpgradeModal-monthlyTile"
                    disableTypography
                    label={
                      <div className={style.Label}>
                        <Typography variant="body1">
                          Billed Monthly ({store.getPlanForPeriod(PLAN_MONTHLY).monthlyPrice}/month)
                        </Typography>
                        <Typography variant="body2" color="textSecondary">
                          {store.getPlanForPeriod(PLAN_MONTHLY).monthlyPrice} paid monthly
                        </Typography>
                      </div>
                    }
                  />
                </RadioGroup>
              </FormControl>
            </div>
            {store.error.hasError && (
              <Alert severity="error" title="Error">
                {!store.error.messages.length && (
                  <Typography>
                    Something went wrong. Please try again later or contact the support.
                  </Typography>
                )}
                {store.error.messages.map(message => (
                  <Typography key={message}>
                    {message}
                  </Typography>
                ))}
              </Alert>
            )}
            {store.recurlyError && (
              <Alert severity="error" title="Billing Information Error">
                <Typography>
                  {store.recurlyError?.message || `There was an error while validating your Billing Information.
                  Please make sure it is valid, try again later or contact the support.`}
                </Typography>
              </Alert>
            )}
            <Typography className={style.Title}>
              Billing Information
            </Typography>
            {store.canReuseBillingInfo && (
              <FormControlLabel
                control={
                  <Switch
                    checked={store.reuseBillingInfo}
                    onChange={e => store.setReuseBillingInfo(e.target.checked)}
                    color="primary"
                  />
                }
                label="Reuse saved billing information"
                labelPlacement="end"
                className={style.ReuseBillingInfo}
              />
            )}
            {!store.reuseBillingInfo && (
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <TextField
                    id="firstName"
                    name="firstName"
                    label="First Name"
                    data-testid="planUpgradeModal-billingInformationForm-firstName"
                    inputRef={register({ required: true })}
                    error={!!errors.firstName || store.recurlyError?.fields.includes('first_name')}
                    helperText={getFieldErrorMessage(errors, 'firstName')}
                    {...commonInputProps}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    id="lastName"
                    name="lastName"
                    label="Last Name"
                    data-testid="planUpgradeModal-billingInformationForm-lastName"
                    inputRef={register({ required: true })}
                    error={!!errors.lastName || store.recurlyError?.fields.includes('last_name')}
                    helperText={getFieldErrorMessage(errors, 'lastName')}
                    {...commonInputProps}
                  />
                </Grid>
                <Grid item xs={12} md={9}>
                  <TextField
                    id="streetAddress"
                    name="streetAddress"
                    label="Street Address"
                    data-testid="planUpgradeModal-billingInformationForm-streetAddress"
                    inputRef={register({ required: true })}
                    error={!!errors.streetAddress || store.recurlyError?.fields.includes('address1')}
                    helperText={getFieldErrorMessage(errors, 'streetAddress')}
                    {...commonInputProps}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <TextField
                    id="suiteApt"
                    name="suiteApt"
                    label="Suite / APT"
                    data-testid="planUpgradeModal-billingInformationForm-suite"
                    inputRef={register({ required: true })}
                    error={!!errors.suiteApt || store.recurlyError?.fields.includes('address2')}
                    helperText={getFieldErrorMessage(errors, 'suiteApt')}
                    {...commonInputProps}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <TextField
                    id="city"
                    name="city"
                    label="City"
                    data-testid="planUpgradeModal-billingInformationForm-city"
                    inputRef={register({ required: true })}
                    error={!!errors.city || store.recurlyError?.fields.includes('city')}
                    helperText={getFieldErrorMessage(errors, 'city')}
                    {...commonInputProps}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <TextField
                    id="state"
                    name="state"
                    label="State"
                    data-testid="planUpgradeModal-billingInformationForm-state"
                    inputRef={register({ required: true })}
                    error={!!errors.state || store.recurlyError?.fields.includes('state')}
                    helperText={getFieldErrorMessage(errors, 'state')}
                    {...commonInputProps}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <TextField
                    id="zipCode"
                    name="zipCode"
                    label="ZIP Code"
                    data-testid="planUpgradeModal-billingInformationForm-zipCode"
                    inputRef={register({ required: true })}
                    error={!!errors.zipCode || store.recurlyError?.fields.includes('postal_code')}
                    helperText={getFieldErrorMessage(errors, 'zipCode')}
                    {...commonInputProps}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    id="country"
                    name="country"
                    control={control}
                    label="Country"
                    defaultValue="US"
                    testId="planUpgradeModal-billingInformationForm"
                    error={!!errors.country || store.recurlyError?.fields.includes('country')}
                    helperText={getFieldErrorMessage(errors, 'country')}
                    TextFieldProps={{
                      inputProps: {
                        autoComplete: 'off',
                        form: {
                          autocomplete: 'off',
                        },
                      }
                    }}
                    required
                    as={<CountrySelect />}
                    {...commonInputProps}
                  />
                </Grid>
                <Grid
                  item xs={12}
                  data-testid="planUpgradeModal-billingInformationForm-paymentDetails"
                >
                  <CardElement
                    className={clsx(store.recurlyError && style.RecurlyError)}
                    style={{
                      fontFamily: 'Inter, sans-serif',
                      fontSize: '15px',
                      placeholder: {
                        color: theme.palette.grey['500']
                      }
                    }}
                  />
                  {Object.keys(errors).some(fieldName => ['number', 'month', 'year', 'cvv'].includes(fieldName)) && (
                    <FormHelperText error className={style.RecurlyErrorHelper}>
                      Please verify that your credit/debit card information is correct
                    </FormHelperText>
                  )}
                </Grid>
              </Grid>
            )}
            <input
              type="submit"
              className={style.SubmitInput}
            />
          </form>
        )
      }}
      renderActions={({ dismissModal, showSpinner }) => {
        if (store.isSuccess) {
          return (
            <Button
              variant="outlined"
              size="large"
              onClick={dismissModal}
              data-testid="planUpgradeModal-dismissModalButton"
            >
              Close
            </Button>
          )
        }
        return (
          <>
            <Button
              variant="outlined"
              disabled={showSpinner}
              onClick={dismissModal}
              size="large"
              startIcon={<CloseRounded />}
            >
              Cancel
            </Button>
            <Button
              color="primary"
              variant="contained"
              disabled={showSpinner}
              size="large"
              onClick={submitForm}
              startIcon={<ShoppingCartRounded />}
              data-testid="planUpgradeModal-billingInformationForm-purchasePlanButton"
            >
              Purchase Plan
            </Button>
          </>
        )
      }}
    />
  )
}

PlanUpgradeModal.propTypes = {
  store: PropTypes.instanceOf(SubscriptionPlanChangeStore).isRequired,
  onExited: PropTypes.func.isRequired
}

export default observer(PlanUpgradeModal)
