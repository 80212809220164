import React from 'react'

const PrintfulLogo = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 807.87 127.56">
      <path d="M294.39 30.12a21.61 21.61 0 0 0-8.65-6.77 22.69 22.69 0 0 0-11.28-2.64H238v86.14h19.56V79h17.3a27.59 27.59 0 0 0 11.29-2.25 24.92 24.92 0 0 0 8.65-6.4 34.76 34.76 0 0 0 5.64-9.4 34.94 34.94 0 0 0 1.88-10.91A26.45 26.45 0 0 0 300 39.52c-1.09-3.39-3.35-6.39-5.61-9.4ZM280.1 58.7a7.85 7.85 0 0 1-6 3h-16.19V38h15.42a5.57 5.57 0 0 1 3.38.75c1.13.75 2.26 1.13 3 2.25a7.84 7.84 0 0 1 2.29 3.79 21.93 21.93 0 0 1 .75 5.26c-.02 3.76-.75 6.77-2.65 8.65ZM367 70.36a22.93 22.93 0 0 0 4.51-6 28 28 0 0 0 2.63-6.77 24.28 24.28 0 0 0 1.13-7.52A26.45 26.45 0 0 0 373 39.52a30.73 30.73 0 0 0-6-9.4 26 26 0 0 0-9-6.77 22.76 22.76 0 0 0-11.29-2.64h-38.78v86.14h20.31V79H341l17.31 27.84h22.19L361 74.88a23.13 23.13 0 0 0 6-4.52Zm-15.05-12a7.23 7.23 0 0 1-6 3.38h-18.08l-.38-23.32h17.68a7.92 7.92 0 0 1 6.39 3 11.71 11.71 0 0 1 3 8.27c.01 3.75-.74 6.38-2.62 8.64Zm33.47-37.65h19.93v85.76h-19.93zm84.25 50.4-39.87-50.4h-15.42v85.76h19.93v-48.9l39.5 48.9h15.79V20.71h-19.93v50.4zm24.82-33.09h26.33v68.45h19.93V38.02h25.96V20.71h-72.22v17.31zm77.11 68.45h19.93V72.62h31.6V56.45h-31.6V38.02h38.37V20.71h-58.3v85.76zm118.1-42.13a38.31 38.31 0 0 1-.75 9.41 18.37 18.37 0 0 1-3 7.9 20.19 20.19 0 0 1-5.64 5.64 15.63 15.63 0 0 1-8.65 2.25 17.93 17.93 0 0 1-8.28-1.88 19.93 19.93 0 0 1-5.65-5.66 36.76 36.76 0 0 1-4.14-17.3v-44h-19.93v44a58 58 0 0 0 2.25 16.17 38.38 38.38 0 0 0 6.77 13.54 32.33 32.33 0 0 0 11.67 9.41 42.39 42.39 0 0 0 17.65 3.4c6.4 0 12.41-1.13 16.93-3.38a34.84 34.84 0 0 0 11.66-9 38 38 0 0 0 6.77-13.54 57 57 0 0 0 2.26-16.55v-44H689.7Zm50.4 24.83V20.71h-19.93v85.76h61.31v-17.3H740.1z" fill="#222" />
      <path d="m76.8 20.34-50.4 86.51h45.51L99.55 59.4 76.8 20.34z" fill="#f2c994" />
      <path d="m145.06 59.4-22.75-39.06L99.55 59.4l22.76 39.05 22.75-39.05z" fill="#ed4642" />
      <path d="M167.82 20.34 145.06 59.4l27.65 47.45h45.51l-50.4-86.51z" fill="#17bcb5" />
      <path d="m99.55 59.4-27.64 47.45L99.55 59.4zm22.76 39.05L99.55 59.4l22.76 39.05zM99.55 59.4l-27.64 47.45h45.51l4.89-8.4L99.55 59.4z" fill="#df392f" />
      <path d="m122.31 98.45 22.75-39.05-22.75 39.05zm22.75-39.05 27.65 47.45-27.65-47.45zm-22.75 39.05 4.89 8.4h45.51L145.06 59.4l-22.75 39.05zm0 0-4.89 8.4 4.89-8.4zm0 0-4.89 8.4 4.89-8.4z" fill="#16342f" />
      <path d="m122.31 98.45 4.89 8.4-4.89-8.4zm0 0 4.89 8.4-4.89-8.4zm-4.89 8.4h9.78l-4.89-8.4-4.89 8.4z" fill="#15291a" />
    </svg>
  )
}

export default PrintfulLogo
