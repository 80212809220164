import { action, computed, observable } from 'mobx'

import BaseProductStore from 'supplier/stores/BaseProductStore'

class ProductStore extends BaseProductStore {
  @observable uuid
  @observable clearanceErrors
  @observable syncMeta
  @observable syncErrors
  @observable selected = false

  @action.bound
  setSelected (value) {
    this.selected = value
  }

  @computed get isSynced () {
    return !!this.syncMeta?.syncEnabled
  }
}

export default ProductStore
