import React, { useState } from 'react'

import PropTypes from 'prop-types'

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from '@material-ui/core'
import { ExpandMoreRounded } from '@material-ui/icons'
import { observer } from 'mobx-react'

import style from './FiltersPanelSection.module.scss'

/**
 * A single filters section, which should be used within the context of `<FiltersPanel>` component.
 * @param {string} title
 * @param {ReactDOM} children
 * @returns {JSX.Element}
 * @constructor
 */
const FiltersPanelSection = ({ title, children }) => {
  const [isSectionOpen, setIsSectionOpen] = useState(true)

  return (
    <Accordion
      className={style.FiltersPanelSection}
      expanded={isSectionOpen}
      onChange={(_, isExpanded) => setIsSectionOpen(isExpanded)}
    >
      <AccordionSummary
        data-testid="sectionHeading"
        className={style.Header}
        expandIcon={<ExpandMoreRounded />}
      >
        <Typography component="span" className={style.Title}>
          {title}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        {children}
      </AccordionDetails>
    </Accordion>
  )
}

FiltersPanelSection.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node,
}

export default observer(FiltersPanelSection)
