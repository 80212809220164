export const STEP_START = 'start'
export const STEP_SHOPIFY_INSTRUCTIONS = 'shopify_instructions'
export const STEP_BACK_FROM_PERMISSIONS_GRANT = 'back_from_grant'
export const STEP_AUTHORIZATION_CHECK_IN_PROGRESS = 'authorization_check_in_progress'
export const STEP_AUTHORIZATION_CHECK_FAILED = 'authorization_check_failed'
export const STEP_CONFIRMATION_FAILED = 'confirmation_failed'
export const STEP_AUTHORIZATION_CREATED = 'authorization_created'
export const STEP_SERVER_ERROR = 'server_error'

export const SHOPIFY_AUTH_STATE_COOKIE_NAME = 'shopify_authorization_state'
