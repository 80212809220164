import { computed } from 'mobx'

import { IMPORT_COMPLETED, IMPORT_FAILED, IMPORT_PENDING, IMPORT_PENDING_MESSAGE, IMPORT_STARTED } from 'shared/constants/imports'
import { integrationsNames } from 'shared/constants/integrations'

import { ImportRunStore } from 'supplier/stores'

class IntegrationImportStore extends ImportRunStore {
  integrationType

  @computed get statusMessage () {
    const integrationName = integrationsNames.get(this.integrationType)
    switch (this.status) {
      case IMPORT_PENDING: return IMPORT_PENDING_MESSAGE
      case IMPORT_STARTED: return `Products from your ${integrationName} account are now being imported.`
      case IMPORT_COMPLETED: return 'Product import has been completed.'
      case IMPORT_FAILED: return 'Product import has failed.'
      default: return undefined
    }
  }
}

export default IntegrationImportStore
