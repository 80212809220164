/**
 * @param {Object|undefined} imageStore
 * @returns {{thumbnail, id, url}|undefined}
 */
const adaptVariantImageForBiEvent = imageStore => {
  if (!imageStore) return undefined
  const { id, thumbnail, url } = imageStore
  return { id, thumbnail, url }
}

export {
  adaptVariantImageForBiEvent
}
