import React, { useState } from 'react'

import PropTypes from 'prop-types'

import {
  Button,
  Checkbox,
  FormControlLabel,
  Typography,
} from '@material-ui/core'
import { observer } from 'mobx-react'

import { DataModal } from 'shared/components/organisms'

import {
  OrderModalActionState,
  UserProfileStore,
} from 'retailer/stores'

const OrderSyncAndPayModal = ({ orderStore, userProfileStore, onExited }) => {
  const [dismissPermanently, setDismissPermanently] = useState(false)
  const handleDismissChange = () => setDismissPermanently(!dismissPermanently)

  const dismissModalPermanently = () => {
    if (dismissPermanently) {
      userProfileStore.dismissAlibabaAutomaticPaymentsModal()
    }
  }

  return (
    <DataModal
      maxWidth="sm"
      title="Modalyst will Automatically Sync & Pay for Alibaba Order"
      renderBody={() => (
        <>
          <Typography paragraph>
            When you click on Pay for Order, you are confirming that Modalyst can automatically sync this order to Alibaba.
          </Typography>
          <Typography paragraph>
            If you have checked the box on Alibaba to save your credit card, the order will be paid automatically with
            the credit card that you have set up on Alibaba directly. Modalyst does not have access to your payment information.
          </Typography>
          <FormControlLabel
            control={<Checkbox checked={dismissPermanently} onChange={handleDismissChange} name="dismiss" />}
            label="Don't show this message again"
          />
        </>
      )}
      renderActions={({ dismissModal }) => (
        <>
          <Button onClick={dismissModal}>Cancel</Button>
          <Button
            onClick={() => {
              dismissModalPermanently()
              orderStore.order.placeOrder()
              dismissModal()
            }}
            variant="contained"
            color="primary"
          >
            Pay for Order
          </Button>
        </>
      )}
      onExited={onExited}
    />
  )
}

OrderSyncAndPayModal.propTypes = {
  orderStore: PropTypes.instanceOf(OrderModalActionState),
  userProfileStore: PropTypes.instanceOf(UserProfileStore),
  onExited: PropTypes.func.isRequired,
}

export default observer(OrderSyncAndPayModal)
