import { api } from './common'

const baseUrl = '/api/v3/user_profiles'

const getUserProfile = () => {
  return api.get(`${baseUrl}/`)
}

const getAppConfig = () => {
  return api.get(`${baseUrl}/app_config/`)
}

const setContextAttribute = (key, value) => {
  return api.post(`${baseUrl}/set_context_attribute/`, { key, value })
}

const dismissOnboarding = () => {
  return api.post(`${baseUrl}/dismissed_onboarding_widget/`)
}

const aliexpressJoinDropshippingProgram = () => {
  return api.post(`${baseUrl}/aliexpress_joined_dropshipping_program/`)
}

const dismissAlibabaAdModal = () => {
  return api.post(`${baseUrl}/dismiss_alibaba_ad_modal/`)
}

const dismissAlibabaSyncModal = () => {
  return api.post(`${baseUrl}/dismiss_alibaba_sync_modal/`)
}

const dismissAlibabaAutomaticPaymentsModal = () => {
  return api.post(`${baseUrl}/dismiss_alibaba_automatic_payments_modal/`)
}

/**
 * Confirm that user does not wish to see external order payment modal any more.
 * @param {String} orderType Type of confirmation (we have separate confirmations for AliExpress and Draft Orders).
 * @returns {Promise<AxiosResponse<any>>}
 */
const confirmExternalOrderPayment = (orderType) => {
  return api.post(`${baseUrl}/confirmed_external_order_payment/`, { order_type: orderType })
}

const getCurrentRetailerAuthorization = () => {
  return api.get('/api/v3/retailer_authorization/')
}

export {
  getUserProfile,
  getAppConfig,
  confirmExternalOrderPayment,
  dismissOnboarding,
  aliexpressJoinDropshippingProgram,
  dismissAlibabaAdModal,
  setContextAttribute,
  dismissAlibabaAutomaticPaymentsModal,
  dismissAlibabaSyncModal,
  getCurrentRetailerAuthorization,
}
