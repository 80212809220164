import React from 'react'

import PropTypes from 'prop-types'

import { Box, Button, MenuItem, Select, Typography, useTheme, useMediaQuery, InputBase } from '@material-ui/core'
import { SortOutlined } from '@material-ui/icons'
import { Skeleton } from '@material-ui/lab'
import clsx from 'clsx'
import { observer } from 'mobx-react'
import FilterFill from 'wix-ui-icons-common/on-stage/FilterFill'

import { ORDERING_MAP } from 'shared/constants/marketplace'
import { useTranslation } from 'shared/hooks'

import style from './MarketplaceToolbar.module.scss'

const MarketplaceToolbar = ({ marketplaceStore, marketplaceFiltersEditorStore, ...rest }) => {
  const { t } = useTranslation('marketplace')
  const { totalCount, ordering, setOrdering, filterParams, allowFilters } = marketplaceStore
  const { filtersVisible, showFilters, hideFilters } = marketplaceFiltersEditorStore

  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'))

  const handleOrderingSelectChange = event => setOrdering(event.target.value)
  const handleFiltersToggleClick = event => {
    if (filtersVisible) hideFilters()
    else showFilters(filterParams)
  }

  return (
    <Box
      className={clsx(style.MarketplaceToolbar, isDesktop && style.isDesktop)}
      display="flex" alignItems="center"
      {...rest}
    >
      <Typography
        className={style.Count}
        component={Box} flexGrow={1}
        variant={isDesktop ? 'body1' : 'body2'}
        data-testid="MarketplaceToolbar-totalCount"
      >
        {totalCount === undefined
          ? <Skeleton width={80} />
          : t('main.productAmount.counter', { numberOfProducts: totalCount })}
      </Typography>
      {allowFilters && (
        <Button
          data-testid="MarketplaceToolbar-filtersButton"
          className={style.FiltersButton}
          size={isDesktop ? 'large' : 'medium'} color="primary"
          startIcon={isDesktop ? <FilterFill /> : undefined}
          onClick={handleFiltersToggleClick}
        >
          {t('main.filter.label')}
        </Button>
      )}
      <Select
        value={ordering}
        onChange={handleOrderingSelectChange}
        className={clsx(style.OrderingSelect, !isDesktop && style.labelHidden)}
        data-testid="MarketplaceToolbar-orderingSelect"
        renderValue={
          value => isDesktop
            ? t(`main.${ORDERING_MAP.get(value)}`)
            : <SortOutlined color="secondary" />
        }
        input={<InputBase />}
      >
        {[...ORDERING_MAP.entries()].map(([value, label]) => (
          <MenuItem key={value} value={value} data-testid="MarketplaceToolbar-orderingSelect-option">{t(`main.${label}`)} </MenuItem>
        ))}
      </Select>
    </Box>
  )
}

MarketplaceToolbar.propTypes = {
  marketplaceStore: PropTypes.shape({
    totalCount: PropTypes.number,
    filterParams: PropTypes.object,
    ordering: PropTypes.oneOf([...ORDERING_MAP.keys()]),
    setOrdering: PropTypes.func.isRequired,
    allowFilters: PropTypes.bool.isRequired,
  }).isRequired,
  marketplaceFiltersEditorStore: PropTypes.shape({
    filtersVisible: PropTypes.bool.isRequired,
    hideFilters: PropTypes.func.isRequired,
    showFilters: PropTypes.func.isRequired,
  }).isRequired,
}

export default observer(MarketplaceToolbar)
