import React from 'react'

import PropTypes from 'prop-types'

import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
  Paper,
  Avatar,
  Tooltip, Container, Link,
} from '@material-ui/core'
import { BlockRounded, HomeRounded, MoneyOffRounded } from '@material-ui/icons'
import { observer } from 'mobx-react'
import { Trans } from 'react-i18next'

import { Money } from 'shared/components/atoms'
import { useTranslation } from 'shared/hooks'
import { getIntegrationName } from 'shared/utils/integrations'

import style from './MarketplaceItemShippingTab.module.scss'

const ShippingCost = observer(({ isFreeZone, country }) => {
  const { t } = useTranslation('productPage')

  if (isFreeZone || country.free) {
    return (
      <Typography
        variant="body1" className={style.FreeShipping} data-testid="MarketplaceItemShippingTab-shippingCost"
      >
        <MoneyOffRounded className={style.FreeShippingIcon} />
        {t('tabs.shippingFree')}
      </Typography>
    )
  }

  if (!country.enabled || !country.shippingCost) {
    return (
      <Typography
        className={style.DisabledShipping} data-testid="MarketplaceItemShippingTab-shippingCost"
      >
        <BlockRounded className={style.DisabledShippingIcon} />
        {t('tabs.shippingUnavailable')}
      </Typography>
    )
  }

  return (
    <>
      <Typography
        variant="body1" data-testid="MarketplaceItemShippingTab-shippingCost"
      >
        <Money amount={country.shippingCost.cost} />
      </Typography>
      <Typography variant="body2" className={style.AdditionalPrice} data-testid="MarketplaceItemShippingTab-shippingCostAddon">
        <Trans
          t={t} i18nKey="tabs.shippingCostAddon"
          components={{ money: <Money amount={country.shippingCost.costAddon || 0} /> }}
        />
      </Typography>
    </>
  )
})

ShippingCost.propTypes = {
  isFreeZone: PropTypes.bool,
  country: PropTypes.shape({
    iso: PropTypes.string,
    countryName: PropTypes.string,
    enabled: PropTypes.bool,
    domestic: PropTypes.bool,
    free: PropTypes.bool,
    shippingCost: PropTypes.shape({
      costAddon: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      cost: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    })
  }).isRequired
}

const MarketplaceItemShippingTab = ({ item, table }) => {
  const { t } = useTranslation('productPage')

  if (!table) return null

  if (item.is3rdPartySupplier) {
    return (
      <Container className={style.MarketplaceItemShippingTab}>
        <Typography variant="body1" paragraph>
          {t('tabs.shippingThirdParty.content')}{' '}
          <Link href={item.externalUrl} target="_blank" rel="noopener noreferrer">
            {t('tabs.shippingThirdParty.cta', { platformName: getIntegrationName(item.inventoryType) })}
          </Link>
        </Typography>
      </Container>
    )
  }

  return (
    <Grid container spacing={4} className={style.MarketplaceItemShippingTab}>
      {table.map(zone => {
        return (
          <Grid key={zone.name} item xs={12} md={6} xl data-testid="MarketplaceItemShippingTab-zone">
            <Typography
              variant="h4" component="p" gutterBottom data-testid="MarketplaceItemShippingTab-zoneName"
              data-test-state={zone.enabled ? 'enabled' : 'disabled'}
            >
              {zone.name}
            </Typography>
            {!zone.enabled && (
              <Typography
                variant="body1" className={style.ShippingDisabled}
                data-testid="MarketplaceItemShippingTab-zoneDisabledText"
              >
                {t('tabs.shippingNotAvailableForRegion')}
              </Typography>
            )}
            {zone.enabled && (
              <TableContainer component={Paper} variant="outlined">
                <Table>
                  <TableBody>
                    {zone.countries.map(country => {
                      return (
                        <TableRow hover key={country.countryName} data-testid="MarketplaceItemShippingTab-country">
                          <TableCell>
                            <Typography variant="body1" component="div" data-testid="MarketplaceItemShippingTab-countryName">
                              {country.domestic && (
                                <Tooltip
                                  title={t('tabs.shippingDomestic.title')}
                                  data-testid="MarketplaceItemShippingTab-domesticCountryIcon"
                                >
                                  <Avatar className={style.DomesticIndicator}>
                                    <HomeRounded fontSize="inherit" />
                                  </Avatar>
                                </Tooltip>
                              )}
                              {country.countryName}
                            </Typography>
                          </TableCell>
                          <TableCell
                            align="right" data-testid="MarketplaceItemShippingTab-shippingCostCell"
                            data-test-state={zone.free || country.free
                              ? 'free'
                              : !country.enabled || !country.shippingCost
                                  ? 'disabled'
                                  : 'enabled'}
                          >
                            <ShippingCost isFreeZone={zone.free} country={country} />
                          </TableCell>
                        </TableRow>
                      )
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </Grid>
        )
      })}
    </Grid>
  )
}

MarketplaceItemShippingTab.propTypes = {
  item: PropTypes.shape({
    is3rdPartySupplier: PropTypes.bool,
    externalUrl: PropTypes.string,
    inventoryType: PropTypes.string.isRequired,
  }).isRequired,
  table: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    enabled: PropTypes.bool,
    free: PropTypes.bool,
    countries: PropTypes.arrayOf(PropTypes.shape({
      countryName: PropTypes.string.isRequired,
      domestic: PropTypes.bool,
    })).isRequired,
  })),
}

export default observer(MarketplaceItemShippingTab)
