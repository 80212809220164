import { computed } from 'mobx'

import BaseProductStore from 'supplier/stores/BaseProductStore'

class ImportedProductStore extends BaseProductStore {
  id

  @computed get uuid () {
    // ImportedItem model does not have an uuid field
    return this.id
  }

  @computed get activeVariants () {
    return this.variants
  }
}
export default ImportedProductStore
