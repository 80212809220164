import React from 'react'

import PropTypes from 'prop-types'

import { Fade } from '@material-ui/core'
import clsx from 'clsx'

import CustomPropTypes from 'retailer/propTypes'

import style from './ProductSlateActionsContainer.module.scss'

/**
 * Takes care of positioning and toggling the visibility of the CTA buttons
 * (passed as children) on a Product Slate.
 * Parent must be relatively positioned.
 */
const ProductSlateActionsContainer = ({ show, className, children, moreActions }) => {
  return (
    <Fade in={show} unmountOnExit>
      <div className={clsx(style.ProductSlateActionsContainer, className)}>
        <div className={style.MainActions}>{children}</div>
        {moreActions && <div className={style.MoreActions}>{moreActions}</div>}
      </div>
    </Fade>
  )
}

ProductSlateActionsContainer.propTypes = {
  show: PropTypes.bool,
  className: PropTypes.string,
  children: CustomPropTypes.nodes,
  moreActions: CustomPropTypes.nodes,
}

ProductSlateActionsContainer.defaultProps = {
  show: false,
}

export default ProductSlateActionsContainer
