import { pick } from 'lodash'
import { action, computed, observable } from 'mobx'

import {
  adaptUserBasicInfo,
  adaptUserBasicInfoFormData,
  adaptUserBasicInfoSettingsError,
} from 'shared/adapters/settings'
import { getUserBasicInfo, saveUserBasicInfo } from 'shared/api/settings'
import { processApiError } from 'shared/api/utils'
import { LoadableStore } from 'shared/stores'

class UserBasicInfoSettingsStore {
  parent

  @observable loading = new LoadableStore()
  @observable contactEmail
  @observable profileImage
  @observable isSaving = false
  @observable errors = {}

  constructor (parent) {
    this.parent = parent
  }

  @computed get defaultValues () {
    return {
      contactEmail: this.contactEmail || '',
      profileImage: this.profileImage || null,
    }
  }

  @action.bound
  clearProfileImageError () {
    this.errors.profileImageError = null
  }

  @action.bound
  fetch () {
    this.loading.setLoading()
    return getUserBasicInfo().then(response => {
      Object.assign(this, adaptUserBasicInfo(response.data))
      this.loading.setLoaded()
    })
  }

  @action.bound
  async refresh () {
    const response = await getUserBasicInfo()
    Object.assign(this, adaptUserBasicInfo(response.data))
  }

  @action.bound
  async save (data, dirtyFields) {
    // when image was removed it is undefined in the data, but also dirty
    // for API call it should be set to null
    if (data.profileImage === undefined && dirtyFields.profileImage) data.profileImage = null
    this.isSaving = true
    this.errors = {}
    try {
      const pickedData = pick(data, Object.keys(dirtyFields))
      await saveUserBasicInfo(adaptUserBasicInfoFormData(pickedData))
      await this.parent.refreshUserProfile()
      await this.refresh()
    } catch (error) {
      Object.assign(this.errors, processApiError(error, adaptUserBasicInfoSettingsError))
    } finally {
      this.isSaving = false
    }
  }
}

export default UserBasicInfoSettingsStore
