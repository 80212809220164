export const MARKETPLACE_CODE_READY_TO_SELL = 'ready-to-sell'
export const MARKETPLACE_CODE_PRINT_ON_DEMAND = 'print-on-demand'

export const SHORT_MARKETPLACE_CODES = {
  [MARKETPLACE_CODE_READY_TO_SELL]: 'RTS',
  [MARKETPLACE_CODE_PRINT_ON_DEMAND]: 'POD',
}

export const DEFAULT_PAGE_SIZE = 40

const INDIE_BRANDS = 'indieBrands'
const NAME_BRANDS = 'nameBrands'
const TRENDY_ITEMS = 'trendyItems'
const ALIEXPRESS = 'aliexpress'
const ALIBABA = 'alibaba'
const CBD = 'cbd'
const BEAUTY = 'beauty'
const PRINT_ON_DEMAND = 'printOnDemand'

const inventoryTypesUserFriendly = new Map([
  [INDIE_BRANDS, 'Indie Brands'],
  [NAME_BRANDS, 'Name Brands'],
  [TRENDY_ITEMS, 'Trendy Items'],
  [BEAUTY, 'Beauty'],
  [CBD, 'CBD'],
  [ALIEXPRESS, 'Aliexpress'],
  [ALIBABA, 'Alibaba']
])

const STYLE_COMMERCE = 'style_commerce'

const INVENTORY_TYPES_IN_ORDER = [
  INDIE_BRANDS,
  NAME_BRANDS,
  TRENDY_ITEMS,
  CBD,
  ALIEXPRESS,
  ALIBABA,
  BEAUTY,
]

const INVENTORY_TYPE_COLORS = new Map([
  [INDIE_BRANDS, '#6b2c55'],
  [NAME_BRANDS, '#92720a'],
  [TRENDY_ITEMS, '#1b2c7e'],
  [ALIEXPRESS, '#961f0f'],
  [CBD, '#0e5b32'],
  [ALIBABA, '#C75F00'],
  [BEAUTY, '#ff00d7'], // TODO - add color!,
  [PRINT_ON_DEMAND, '#000000'] // TODO: Add color
])

const SORT_BESTSELLERS = 'Best sellers'
const SORT_MOST_RECENTLY_ADDED = 'New arrivals'
const SORT_CHEAPEST = 'Price: low to high'
const SORT_MOST_EXPENSIVE = 'Price: high to low'
const SORT_NEW_BRANDS = 'New brands'

const DEFAULT_ORDERING = '-recently_sold_at,-quantity'

// ordering value->label map for the new marketplace
// TODO: delete other constdants once the old marketplace is deprecated
const ORDERING_MAP = new Map([
  [DEFAULT_ORDERING, 'sortRelevant.label'],
  ['-bestsellers_index,-quantity', 'sortBestSeller.label'],
  ['-created,-quantity', 'sortNewArrive.label'],
  ['max_price,-quantity', 'sortPriceLow.label'],
  ['-max_price,-quantity', 'sortPriceHigh.label'],
  ['-approved_at,-quantity', 'sortNewBrands.label'],
])

const NO_ORDERING = 'all'

const SUPPLIER_FILTER_MINE = 'suppliers_im_selling'
const SUPPLIER_FILTER_PREMIUM = 'premium_only'

const SHIPPING_SPEED_OPTIONS = new Map([
  ['free', 'filterShippingTypeFree'],
  ['4_day', 'filterShippingType4Day'],
  ['fast', 'filterShippingTypeFast'],
])

const KORNIT_COLORS_MAP = new Map([
  ['black', '#000000'],
  ['red', '#ff0000'],
  ['yellow', '#ffff00'],
  ['white', '#ffffff'],
  ['lightblue', '#add8e6'],
  ['navy', '#000080'],
  ['royal', '#4169e1'],
  ['turquoise', '#40e0d0'],
  ['purple', '#800080'],
  ['maroon', '#800000'],
  ['heathergray', '#d3d3d3'],
  ['silver', '#c0c0c0'],
  ['olive', '#808000'],
  ['green', '#008000'],
  ['aqua', '#00ffff'],
  ['blue', '#0000ff'],
  ['purple', '#800080'],
  ['charcoalheather', '#413f3d'],
  ['athleticheather', '#aaa1a2'],
  ['sportgrey', '#97999b'],
  ['antiqueheliconia', '#aa0061'],
  ['irishgreen', '#009e69'],
  ['graphiteheather', '#707372'],
  ['militarygreen', '#63655a'],
  ['heathernavy', '#333f48'],
  ['charcoal', '#66676c'],
  ['darkgreyheather', '#3f4444'],
  ['trueroyal', '#006a8e'],
  ['grey', '#808080'],
  ['heather', '#d3d3d3'],
  ['pink', '#ffc0cb'],
  ['darkheather', '#3f4444'],
  ['cardinal', '#771e2e'],
  ['heavymetal', '#a3a1a4'],
  ['kellygreen', '#049457'],
  ['lightgray', '#9b908c'],
  ['lightpink', '#efc3c4'],
  ['midnightnavy', '#121e29'],
  ['natural', '#f4eacf'],
  ['purplerush', '#583b7b'],
  ['turquoise', '#2081ab'],
  ['granitegreyheather', '#a3a2a3'],
  ['terraingrey', '#adad99'],
  ['nightskynavy', '#7b8993'],
  ['deepblack', '#050203'],
  ['jetblack', '#0a0a0a'],
  ['beaconblue', '#255c99'],
  ['terraingray', '#adad99'],
  ['brightwhite', '#ffffff'],
  ['spaceblack', '#343435'],
  ['rebelblue', '#85a4b2'],
  ['aluminiumgrey', '#878581'],
  ['fairlywhite', '#ded4d8'],
  ['riseupred', '#ff033e'],
  ['aluminumgrey', '#878581'],
  ['azureblue', '#007fff'],
  ['safetygreen', '#f2fb3e'],
  ['hotpink', '#ff69b4'],
  ['mauvelous', '#ef98aa'],
  ['heatherolive', '#7a8768'],
  ['heatherstone', '#968487'],
  ['truered', '#8f1d21'],
  ['blackink', '#06080f'],
  ['cyberpink', '#ffc0cb'],
  ['scubablue', '#00abc0'],
  ['charcoalgrey', '#36454f'],
])

const COLOR_NAME_UNKNOWN = 'unknown'

export {
  INDIE_BRANDS,
  NAME_BRANDS,
  TRENDY_ITEMS,
  ALIBABA,
  ALIEXPRESS,
  BEAUTY,
  CBD,
  PRINT_ON_DEMAND,
  INVENTORY_TYPES_IN_ORDER,
  INVENTORY_TYPE_COLORS,
  STYLE_COMMERCE,
  DEFAULT_ORDERING,
  SORT_BESTSELLERS,
  SORT_MOST_RECENTLY_ADDED,
  SORT_CHEAPEST,
  SORT_MOST_EXPENSIVE,
  SORT_NEW_BRANDS,
  ORDERING_MAP,
  NO_ORDERING,
  SUPPLIER_FILTER_MINE,
  SUPPLIER_FILTER_PREMIUM,
  inventoryTypesUserFriendly,
  SHIPPING_SPEED_OPTIONS,
  KORNIT_COLORS_MAP,
  COLOR_NAME_UNKNOWN,
}
