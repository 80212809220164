import React, { useState } from 'react'

import PropTypes from 'prop-types'

import { Card, CardMedia, Link, Tooltip } from '@material-ui/core'
import { observer } from 'mobx-react'
import { OutboundLink } from 'react-ga'

import { EVENT_LABELS } from 'shared/analytics'

import style from './MarketplaceBanner.module.scss'

const MarketplaceBanner = ({ url, name, image, tooltip, newTab, onClick }) => {
  const [hover, setHover] = useState(false)

  const handleClick = event => {
    if ((onClick && !onClick()) || !url) {
      event.preventDefault()
    }
  }

  const banner = (
    <Link
      component={OutboundLink}
      role="banner"
      onClick={handleClick}
      to={url}
      target={newTab ? '_blank' : undefined}
      eventLabel={EVENT_LABELS.clickMarketplaceBanner(name)}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      className={style.MarketplaceBanner}
    >
      <Card className={style.Card} elevation={hover ? 3 : 0}>
        <CardMedia
          className={style.Media}
          image={image}
        />
      </Card>
    </Link>
  )

  if (tooltip) {
    return (
      <Tooltip arrow interactive title={tooltip} placement="top">
        {banner}
      </Tooltip>
    )
  }

  return banner
}

MarketplaceBanner.propTypes = {
  url: PropTypes.string,
  name: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  tooltip: PropTypes.node,
  newTab: PropTypes.bool,
  /** A function to link to `url` conditionally; trueish - follow the url, falseish - do nothing */
  onClick: PropTypes.func,
}

MarketplaceBanner.defaultProps = {
  tooltip: null,
  newTab: false,
}

export default observer(MarketplaceBanner)
