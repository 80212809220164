import { action } from 'mobx'

import {
  UserBasicInfoSettingsStore,
  UserDeleteAccountStore,
  UserLoginInfoSettingsStore,
  UserRequestDataStore,
} from 'shared/stores'

import {
  BusinessInformationStore,
  LocationSettingsStore,
  MarketplacesSettingsStore,
  ProfileSettingsStore, ShippingInformationStore,
  SocialMediaSettingsStore,
} from 'retailer/stores'

class SettingsStore {
  /** @type {import('../context').RootStore} */
  root

  /**
   * @param {import('../context').RootStore} root
   */
  constructor (root) {
    this.root = root
  }

  profileSettings = new ProfileSettingsStore(this)
  marketplacesSettings = new MarketplacesSettingsStore(this)
  locationSettings = new LocationSettingsStore(this)
  socialMediaSettings = new SocialMediaSettingsStore(this)

  businessInformationSettings = new BusinessInformationStore(this)
  shippingInformationSettings = new ShippingInformationStore(this)

  userBasicInfoSettings = new UserBasicInfoSettingsStore(this)
  userLoginInfoSettings = new UserLoginInfoSettingsStore(this)
  userRequestDataStore = new UserRequestDataStore(this)
  userDeleteAccountStore = new UserDeleteAccountStore(this)

  @action.bound refreshUserProfile () {
    this.root.userProfileStore.fetch()
  }
}

export default SettingsStore
