import { action, computed, observable } from 'mobx'

import { adaptGetImportResponse, adaptPostImportErrors, adaptPostImportRequest, adaptPostImportResponse } from 'shared/adapters/imports'
import { getImport, postImport } from 'shared/api/imports'
import { processApiError } from 'shared/api/utils'
import { IMPORT_STATUSES_IN_PROGRESS, MIME_TYPE_TO_IMPORT_FORMAT } from 'shared/constants/imports'

import { BaseProductImportManagerStore, ImportRunStore } from 'supplier/stores'

class ProductImportManagerStore extends BaseProductImportManagerStore {
  /** @type {import('../root').RootStore} */
  root

  @observable selectedFile
  @observable isSubmitting
  @observable remoteErrors

  /**
   * @param {import('../root').RootStore} root
   */
  constructor (root) {
    super()
    this.root = root
  }

  @computed get templateUrls () {
    return this.root.appConfigStore.importTemplateUrls
  }

  @computed get selectedFileLabel () {
    const file = this.selectedFile
    return file && `${file.name} - ${Math.round(file.size / 1024)}KB`
  }

  /** Resets the manager state to initials. Prepares for starting new import. */
  @action.bound
  reset () {
    super.reset()
    this.selectedFile = undefined
    this.remoteErrors = undefined
    this.isSubmitting = undefined
  }

  @action.bound
  setRemoteErrors (value) {
    this.remoteErrors = value
  }

  @action.bound
  setSelectedFile (file) {
    this.selectedFile = file
  }

  @action.bound
  readSelectedFile (file) {
    const reader = new FileReader()
    return new Promise((resolve, reject) => {
      reader.onabort = () => { reject(new Error('File reading aborted')) }
      reader.onerror = error => { reject(new Error(`Could not read the selected file: ${error}`)) }
      reader.onloadend = () => { resolve(reader.result) }
      reader.readAsDataURL(this.selectedFile)
    })
  }

  @action.bound
  async uploadSelectedFile (file) {
    if (!this.selectedFile) throw new Error('No file selected')
    if (this.importRun) throw new Error('A product import is currently in progress')
    try {
      const dataUrl = await this.readSelectedFile()
      const [mimeType, importFile] = dataUrl.match(/^data:(.+);base64,(.+)$/).slice(1)
      const importType = MIME_TYPE_TO_IMPORT_FORMAT[mimeType]
      const response = await postImport(adaptPostImportRequest({ importFile, importType }))
      const data = adaptPostImportResponse(response.data)
      this.setImportRun(new ImportRunStore(data))
      this.pollImportRun()
      this.root.importListStore.refresh()
      return data
    } catch (error) {
      if (error.request || error.response) {
        this.setRemoteErrors(processApiError(error, adaptPostImportErrors))
      }
      throw error
    }
  }

  /** Check if there is a running import eg. at an application startup */
  @action.bound
  checkOngoingImportRun () {
    getImport('ongoing')
      .then(response => {
        const data = adaptGetImportResponse(response.data)
        if (IMPORT_STATUSES_IN_PROGRESS.includes(data.status)) {
          this.setImportRun(new ImportRunStore(data))
          this.pollImportRun()
        }
      })
      .catch(error => {
        if (error.response && error.response.status === 404) return
        throw error
      })
  }

  @action.bound
  pollImportRun () {
    if (!this.importRun) throw new Error('There is no importRun to update')
    getImport(this.importRun.id)
      .then(response => {
        const data = adaptGetImportResponse(response.data)
        if (this.importRun.status !== data.status) {
          this.root.importListStore.refresh()
        }
        this.importRun.update(data)
        if (IMPORT_STATUSES_IN_PROGRESS.includes(data.status)) {
          this._poll()
        }
      })
  }
}

export default ProductImportManagerStore
