import React from 'react'

import PropTypes from 'prop-types'

import style from './InputWithIcon.module.scss'

const InputWithIcon = ({ input, icon, className, ...props }) => {
  return (
    <div className={`${style.InputWithIcon} ${className}`} {...props}>
      <div className={style.Input}>
        {input}
      </div>
      <span className={style.Icon}>
        {icon}
      </span>
    </div>
  )
}

InputWithIcon.propTypes = {
  input: PropTypes.node.isRequired,
  icon: PropTypes.node.isRequired,
  className: PropTypes.string,
}

InputWithIcon.defaultProps = {
  className: '',
}

export default InputWithIcon
