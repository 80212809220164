import React, { useState } from 'react'

import PropTypes from 'prop-types'

import { Box, Button, Card, CardContent, CardMedia, Typography } from '@material-ui/core'
import clsx from 'clsx'
import toInteger from 'lodash/toInteger'
import { observer } from 'mobx-react'
import { Trans } from 'react-i18next'

import { PoppingBadge, RatingStar } from 'shared/components/atoms'
import { MoneyRange } from 'shared/components/molecules'
import { inventoryTypesUserFriendly } from 'shared/constants/marketplace'
import { useTranslation } from 'shared/hooks'
import { imageType } from 'shared/types'

import { ProductLink, ProductSlateActionsContainer } from 'retailer/components/atoms'
import { AddProductAction, AddProductActionButton, ProductSlateShippingSection } from 'retailer/components/molecules'

import imagePlaceholder from './image-placeholder.svg'
import style from './ProductSlate.module.scss'

/**
 * Product slate for regular products.
 */
const ProductSlate = ({ item }) => {
  const { t } = useTranslation('marketplace')

  const {
    name,
    primaryImage,
    inventoryType,
    brand,
    quantity,
    currency,
    minCost,
    maxCost,
    minRetailPrice,
    maxRetailPrice,
    vendorRating,
    shipping,
    isImportedOrExported,
  } = item

  const inventoryTypeDisplay = inventoryTypesUserFriendly.get(inventoryType)
  const image = primaryImage?.thumbnail || imagePlaceholder
  const priceProps = {
    currency,
    AmountProps: { className: style.PriceAmount },
    CurrencyProps: { className: style.PriceCurrency },
    useRounding: true,
  }

  const [hover, setHover] = useState(false)
  const handleMouseEnter = () => setHover(true)
  const handleMouseLeave = () => setHover(false)

  return (
    <Card
      className={clsx(style.ProductSlate, isImportedOrExported && style.isAdded)}
      onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}
      elevation={hover ? 4 : 0}
      data-testid="ProductSlate"
      data-testinventorytype={inventoryTypeDisplay}
    >
      <ProductLink productStore={item}>
        <CardContent
          component={Box} display="flex" alignItems="center" flexWrap="wrap"
          className={style.SourceInfo}
        >
          <Typography className={style.InventoryType} variant="inherit" data-testid="ProductSlate-inventoryType">
            {inventoryTypeDisplay}
          </Typography>
          <Typography
            className={style.SupplierName}
            variant="inherit" color="textSecondary" noWrap
            data-testid="ProductSlate-brandName"
          >
            {brand?.name}
          </Typography>
          <div className={style.SupplierRating}>
            <RatingStar className={style.SupplierRatingStar} percent={toInteger(100 * vendorRating / 5) || 0} />
            {parseFloat(vendorRating).toFixed(1)}
          </div>
        </CardContent>
        <CardMedia
          className={clsx(style.CardMedia, style[inventoryType])}
          image={image}
          data-testid="ProductSlate-productImage"
        />
        <CardContent>
          <Typography
            component="h5" variant="body2" noWrap
            className={style.ProductName}
            data-testid="ProductSlate-productName"
          >
            {name}
          </Typography>
          <Typography
            component="div" variant="inherit" noWrap color="textSecondary"
            className={style.Quantity}
            data-testid="ProductSlate-productQuantity"
          >
            {t('productSlate.productStock.counter', { quantity })}
          </Typography>
        </CardContent>
        <CardContent className={style.PriceInfo}>
          <Typography
            component="div" variant="inherit" noWrap color="textPrimary"
            className={style.ProductCost}
          >
            <Trans
              t={t} i18nKey="productSlate.productCost.label"
              components={{
                moneyRange: (
                  <MoneyRange
                    amountMin={minCost} amountMax={maxCost}
                    testId="ProductSlate-productCost"
                    {...priceProps}
                  />
                )
              }}
            />
          </Typography>
          <Typography
            component="div" color="textSecondary" variant="inherit" noWrap
            className={style.RetailPrice}
          >
            <Trans
              t={t} i18nKey="productSlate.productRetailPrice.label"
              components={{
                moneyRange: (
                  <MoneyRange
                    amountMin={minRetailPrice} amountMax={maxRetailPrice}
                    testId="ProductSlate-retailPrice"
                    {...priceProps}
                  />
                )
              }}
            />
          </Typography>
        </CardContent>
      </ProductLink>
      <ProductSlateShippingSection shipping={shipping} currency={currency} />
      <ProductSlateActionsContainer show={hover} className={style.Actions}>
        <AddProductAction
          item={item}
          component={AddProductActionButton}
          disableIcon variant="contained" disableElevation={false}
          testId="ProductSlate-addProductActionButton"
        />
        <ProductLink
          productStore={item}
          component={Button}
          variant="contained" disableElevation={false}
          data-testid="ProductSlate-viewDetailsActionButton"
        >
          {t('productSlate.moreDetails.cta')}
        </ProductLink>
      </ProductSlateActionsContainer>
      <PoppingBadge in={isImportedOrExported} testId="ProductSlate-importedBadge" />
    </Card>
  )
}

ProductSlate.propTypes = {
  item: PropTypes.shape({
    name: PropTypes.string.isRequired,
    primaryImage: imageType,
    inventoryType: PropTypes.string.isRequired,
    brand: PropTypes.shape({
      name: PropTypes.string.isRequired,
    }).isRequired,
    quantity: PropTypes.number.isRequired,
    minCost: PropTypes.number.isRequired,
    maxCost: PropTypes.number.isRequired,
    minRetailPrice: PropTypes.number.isRequired,
    maxRetailPrice: PropTypes.number.isRequired,
    vendorRating: PropTypes.number.isRequired,
    currency: PropTypes.string.isRequired,
    shipping: PropTypes.shape({
      isExternal: PropTypes.bool.isRequired,
    }).isRequired,
    isImportedOrExported: PropTypes.bool,
  })
}

export default observer(ProductSlate)
