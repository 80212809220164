import React from 'react'

import PropTypes from 'prop-types'

import { Button, Typography } from '@material-ui/core'
import { observer } from 'mobx-react'

import { DataModal } from 'shared/components/organisms'

import style from './SupplierAppConnectionSuccessModal.module.scss'

const SupplierAppConnectionSuccessModal = ({ integrationName, onExited }) => {
  return (
    <DataModal
      fullWidth
      className={style.Root}
      title={`Your Modalyst account is now connected to your ${integrationName} account`}
      onExited={onExited}
      renderBody={() => (
        <div className={style.ModalContent}>
          <Typography gutterBottom>
            {`Your Modalyst & ${integrationName} accounts are now connected to each other. `}
            {`All of your ${integrationName} products, inventory and order details will be synced.`}
          </Typography>
        </div>
      )}
      renderActions={({ dismissModal }) => (
        <Button onClick={dismissModal} variant="contained" color="primary" size="large">
          OK
        </Button>
      )}
    />
  )
}

SupplierAppConnectionSuccessModal.propTypes = {
  integrationName: PropTypes.string.isRequired,
  onExited: PropTypes.func.isRequired,
}

export default observer(SupplierAppConnectionSuccessModal)
