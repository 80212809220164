import React from 'react'

import PropTypes from 'prop-types'

import { Menu } from '@material-ui/core'
import clsx from 'clsx'
import { bindMenu, bindTrigger, usePopupState } from 'material-ui-popup-state/hooks'
import { observer } from 'mobx-react'
import { v4 as uuid4 } from 'uuid'

import { MoreButton } from 'retailer/components/atoms'

import style from './MoreButtonWithMenu.module.scss'

const MoreButtonWithMenu = ({ menuId, render, children, className, ButtonProps, popupStateProps, testId }) => {
  const popupState = usePopupState({
    variant: 'popover',
    popupId: menuId,
    ...popupStateProps
  })

  return (
    <div className={clsx(style.MoreButtonWithMenu, className)}>
      <MoreButton {...bindTrigger(popupState)} {...ButtonProps} testId={testId ? `${testId}-button` : undefined} />
      <Menu
        {...bindMenu(popupState)}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {render ? render(popupState) : children}
      </Menu>
    </div>
  )
}

MoreButtonWithMenu.propTypes = {
  menuId: PropTypes.string.isRequired,
  render: PropTypes.func,
  children: PropTypes.node,
  className: PropTypes.string,
  ButtonProps: PropTypes.object,
  popupStateProps: PropTypes.object,
  testId: PropTypes.string,
}

MoreButtonWithMenu.defaultProps = {
  menuId: uuid4(),
  children: null,
  ButtonProps: {},
  popupStateProps: {}
}

export default observer(MoreButtonWithMenu)
