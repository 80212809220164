import React, { useCallback, useEffect } from 'react'

import PropTypes from 'prop-types'

import { Button, Container, Link, Typography } from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'
import times from 'lodash/times'
import { observer } from 'mobx-react'
import { useSnackbar } from 'notistack'
import { Helmet } from 'react-helmet'
import { Link as RouterLink } from 'react-router-dom'

import { ScrollResetter } from 'shared/components/atoms'
import { Alert } from 'shared/components/molecules'
import { CursorPagination } from 'shared/components/organisms'
import {
  ACTION_ADD_TO_STORE, ACTION_REMOVE_FROM_STORE,
  PRODUCT_LIST_ORDERING_RECENTLY_ADDED,
  productListOrderingUserFriendly,
} from 'shared/constants/retailerItems'
import { useTranslation } from 'shared/hooks'

import { PageHeader } from 'retailer/components/molecules'
import {
  DestructiveActionConfirmationModal,
  EmptyListSplash, ImportListBatchActionModal,
  ImportListFilters, ImportListProduct, ItemCustomizerModal,
  ListFiltersBar, ManuallyOrderItemModal, SelectAllButton,
  UniversalNotifications,
} from 'retailer/components/organisms'
import { SidebarLeftTemplate } from 'retailer/components/templates'
import { useStores } from 'retailer/stores/hooks'

import style from './ImportListPage.module.scss'

const SkeletonPage = observer(({ pageSize }) => {
  return (
    <div className={style.SkeletonWrapper}>
      <Skeleton variant="rect" height={60} />
      <div className={style.ListSkeletons}>
        {times(pageSize, n => <Skeleton key={n} variant="rect" height={400} />)}
      </div>
      <Skeleton variant="rect" className={style.PaginationSkeleton} />
    </div>
  )
})

SkeletonPage.propTypes = {
  pageSize: PropTypes.number
}

SkeletonPage.defaultProps = {
  pageSize: 5
}

const Main = observer(() => {
  const { t } = useTranslation('importList')
  const { importListPageStore: store } = useStores()
  const { setFilters, search, ordering, supplier } = store

  if (store.initialized && !store.counters.all) {
    return (
      <EmptyListSplash
        heading={t('main.noProducts.title')}
        content={
          <Typography variant="body1">
            {t('main.noProducts.text')}
          </Typography>
        }
        actions={
          <>
            <Button
              component={RouterLink}
              to="/marketplace/ready-to-sell"
              variant="outlined"
              color="primary"
            >
              Find ready to sell products
            </Button>
          </>
        }
      />
    )
  }

  return (
    <>
      <ListFiltersBar
        className={style.FiltersBar}
        selectAllButton={
          <div className={style.SelectAllButton}>
            <SelectAllButton
              disabled={store.isLoading || !store.items?.length}
              selectionActive={store.selectionActive}
              onSelectAll={() => store.setAllSelected(true)}
              onSelectAllPages={() => store.setAllOnAllPagesSelected(true)}
              onUnselectAll={() => store.setAllOnAllPagesSelected(false)}
              allPagesSelected={store.allOnAllPagesSelected}
              allOnPageSelected={store.allOnPageSelected}
              partSelected={store.partSelected}
              testId="importList"
            />
          </div>
        }
        label={t('main.productNumber.label', { totalNumberOfProducts: store.counters.all })}
        filtersAsPanel={{
          handleOpen: () => store.openFiltersPanel(),
          handleClose: () => store.closeFiltersPanel(),
          panel: (
            <ImportListFilters
              open={store.filtersPanelOpen}
              onClose={() => store.closeFiltersPanel()}
              onSubmit={data => {
                setFilters({ search, ordering, supplier: data.supplier })
              }}
              filters={{
                supplier: store.supplier
              }}
            />
          )
        }}
        searchFilter={{
          setFilter: value => setFilters({ search: value, ordering, supplier }),
          clearFilter: () => setFilters({ search: undefined, ordering, supplier }),
          activeValue: search || ''
        }}
        orderingFilter={{
          activeValue: ordering || PRODUCT_LIST_ORDERING_RECENTLY_ADDED,
          values: productListOrderingUserFriendly,
          setFilter: value => setFilters({ search, ordering: value, supplier })
        }}
        activeFilters={store.activeFilters}
        onClearAll={() => store.setFilters({ ordering })}
        selection={{
          active: store.selectionActive,
          count: store.selectedCount,
          actions: [
            {
              name: t('main.productBulkActionAddToStore.cta'),
              handler: () => store.batchAction.startAction(ACTION_ADD_TO_STORE),
            },
            {
              name: t('main.productBulkActionRemove.cta'),
              handler: () => store.batchAction.startAction(ACTION_REMOVE_FROM_STORE),
            }
          ]
        }}
        handleOpenFilters={() => store.setFiltersPanelOpen(true)}
      />
      {store.isLoading && (
        <div className={style.ListSkeletons}>
          {times(store.pagination.pageSize, idx => <Skeleton key={idx} height={350} variant="rect" />)}
        </div>
      )}
      {!store.isLoading && !store.items?.length && (
        <Alert title="No results" severity="warning" className={style.Alert}>
          There are no import list products that match your search, please try something else.
        </Alert>
      )}
      {!store.isLoading && store.items?.map(item => {
        return (
          <ImportListProduct
            key={item.uuid}
            item={item}
            currentRetailerAuthorization={store.currentRetailerAuthorization}
          />
        )
      })}
      <CursorPagination
        page={store.pagination.page}
        pageSize={store.pagination.pageSize}
        onPrevious={() => store.previous()}
        onNext={(!store.items?.length || !store.hasNextPage) ? null : store.next}
        disabled={store.isLoading || !!store.error}
        className={style.Pagination}
      />
    </>
  )
})

const ImportListPage = () => {
  const { t } = useTranslation('importList')
  const { importListPageStore: store, manualOrderStore } = useStores()
  const { enqueueSnackbar } = useSnackbar()

  const fetchData = useCallback(async () => {
    await store.fetchAdditionalData()
    await store.fetch()
  }, [])

  useEffect(() => {
    fetchData()
    return () => {
      store.reset()
      manualOrderStore.reset()
    }
  }, [])

  return (
    <SidebarLeftTemplate
      notifications={<UniversalNotifications />}
      main={
        <Container maxWidth="xl" disableGutters>
          <Helmet>
            <title>{t('main.title')}</title>
          </Helmet>
          <ScrollResetter />
          <PageHeader
            title={t('main.title')}
            testId="importList-pageHeader"
            subtitle={
              <>
                {t('main.subtitle')}{' '}
                <Link target="_blank" rel="noopener noreferrer" href={t('main.subtitle.url')}>
                  {t('main.subtitle.link')}
                </Link>
              </>
            }
          />
          {!store.initialized && <SkeletonPage pageSize={store.limit} />}
          {store.initialized && <Main />}
          {store.batchAction.initialized && (
            <ImportListBatchActionModal batchActionStore={store.batchAction} />
          )}
          <ItemCustomizerModal />
          {manualOrderStore.open && (
            <ManuallyOrderItemModal manualOrderStore={manualOrderStore} />
          )}
          {store.productRemove.isOpen && (
            <DestructiveActionConfirmationModal
              title={t('main.productRemoveModal.title', { numberOfSelectedProducts: 1 })}
              testId="importList-productRemovalModal"
              onConfirm={async () => {
                const removed = await store.productRemove.remove()
                if (!removed) return
                enqueueSnackbar(
                  `${store.productRemove.product?.name || 'The product'} has been removed`,
                  { variant: 'success' }
                )
                store.productRemove.reset()
              }}
              confirmLabel={t('main.productRemoveModalRemove.cta')}
              onCancel={() => store.productRemove.reset()}
              inProgress={store.productRemove.inProgress}
            >
              {!!store.productRemove.error && (
                <Alert
                  className={style.RemoveAlert}
                  severity="error"
                  title="Error when removing the product"
                >
                  <Typography variant="body2">{store.productRemove.error}</Typography>
                </Alert>
              )}
              <Typography paragraph>{t('main.productRemoveModal.text')}</Typography>
            </DestructiveActionConfirmationModal>
          )}
        </Container>
      }
    />
  )
}

export default observer(ImportListPage)
