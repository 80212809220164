import React from 'react'

import PropTypes from 'prop-types'

import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@material-ui/core'
import { observer } from 'mobx-react'

import { Money } from 'shared/components/atoms'
import { useTranslation } from 'shared/hooks'
import { toTitleCase } from 'shared/utils/text'

import { ItemStore } from 'retailer/stores'

import style from './MarketplaceItemVariantsTable.module.scss'

const MarketplaceItemVariantsTable = ({ item }) => {
  const { t } = useTranslation('productPage')
  const optionKeysSet = new Set()
  item.variants.forEach(
    variant => variant.options.forEach(
      option => optionKeysSet.add(option.key)
    )
  )
  const optionsNames = Array.from(optionKeysSet)

  return (
    <TableContainer
      className={style.MarketplaceItemVariantsTable}
      component={Paper}
      variant="outlined"
    >
      <Table>
        <TableHead>
          <TableRow>
            <TableCell className={style.Head}>
              {t('tabs.variantsTableColumnSKU.label')}
            </TableCell>
            {optionsNames.map(name => (
              <TableCell key={name} className={style.Head}>
                {toTitleCase(name)}
              </TableCell>
            ))}
            <TableCell className={style.Head}>
              {t('tabs.variantsTableColumnCost.label')}
            </TableCell>
            <TableCell className={style.Head} align="right">
              {t('tabs.variantsTableColumnQuantity.label')}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {item.variants.map(variant => {
            return (
              <TableRow hover key={variant.id} data-testid="productDetailPage-variantsTab-variantRow">
                <TableCell className={style.Cell} data-testid="productDetailPage-variantsTab-variantRow-skuCell">
                  {variant.sku}
                </TableCell>
                {optionsNames.map(name => (
                  <TableCell
                    key={variant.options.find(option => option.key === name)?.value || '-'}
                    className={style.Cell}
                    data-testid="productDetailPage-variantsTab-variantRow-optionCell"
                  >
                    {toTitleCase(
                      variant.options.find(option => option.key === name)?.value || '-'
                    )}
                  </TableCell>
                ))}
                <TableCell className={style.Cell}>
                  <Money
                    amount={variant.price} currency={item.retailerCurrency}
                    testId="productDetailPage-variantsTab-variantRow-price"
                  />
                </TableCell>
                <TableCell
                  className={style.Cell} align="right"
                  data-testid="productDetailPage-variantsTab-variantRow-quantityCell"
                >
                  {variant.quantity}
                </TableCell>
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

MarketplaceItemVariantsTable.propTypes = {
  item: PropTypes.instanceOf(ItemStore).isRequired,
}

export default observer(MarketplaceItemVariantsTable)
