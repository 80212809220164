import React, { useEffect } from 'react'

import { Box, Chip, Container, Typography, Grid, Divider } from '@material-ui/core'
import { Alert, AlertTitle, Skeleton } from '@material-ui/lab'
import { Elements, RecurlyProvider } from '@recurly/react-recurly'
import { observer } from 'mobx-react'
import { Helmet } from 'react-helmet'
import { useHistory, useParams } from 'react-router'

import { useBiEvents } from 'shared/bi/hooks'
import { ScrollResetter } from 'shared/components/atoms'

import { SubscriptionPeriodSwitcher } from 'retailer/components/molecules'
import {
  CurrentSubscriptionPlan,
  FeaturesTable, PlanDowngradeModal,
  PlanUpgradeModal, RetailerExceedsProductLimitModal, SubscriptionPaymentSuccessModal,
  SubscriptionPlans,
} from 'retailer/components/organisms'
import { MANAGE_STORE_PLAN_MESSAGE_PAYMENT_SUCCESSFUL } from 'retailer/constants'
import { useStores } from 'retailer/stores/hooks'

import style from './ManagePlanPage.module.scss'

const productAutomation = [
  'Add Products in 1-click',
  'Import All Products Info, Images, and Variants',
  'Easily Edit All Product Information',
  'Real-Time Product Update',
  'Order Tracking with Tracking Numbers',
  'Variant Mapping',
  'Product Editing Before Adding to Store',
  'Bulk Add Products to Ecommerce Store',
]

const pricingAutomation = [
  'Set Margins with Automated Markups',
  'Automatically include shopping in the product price',
  'Profits Calculator to Clearly Understand Margins',
]

const ManagePlanPage = () => {
  const { appConfigStore, managePlanStore } = useStores()
  const { message } = useParams()
  const history = useHistory()
  const { logPageLoaded } = useBiEvents({ correlateWithLocation: true })

  useEffect(() => {
    logPageLoaded({ pageName: 'premium' })
    managePlanStore.fetch()
  }, [])

  /**
   * When user sees the payment success modal and closes it, let's remove the `message` from the URL,
   * so that the user does not see it again, e.g. when refreshing the page or using back/next buttons.
   * Moreover, we use `replace` so that user cannot manually use browser back button to navigate to the URL
   * and re-open the modal.
   */
  const markPaymentSuccessSeen = () => {
    const newUrl = history.location.pathname.substring(0, history.location.pathname.lastIndexOf('/'))
    history.replace(newUrl)
  }

  const skeleton = (
    <>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Typography variant="h2" component={Skeleton} style={{ maxWidth: '200px' }} />
        </Grid>
        <Grid item xs={12} md={4}>
          <Typography component={Skeleton} style={{ maxWidth: '100px' }} />
          <Typography component={Skeleton} style={{ maxWidth: '150px', marginTop: 16 }} />
        </Grid>
        <Grid item xs={12} md={4}>
          <Typography component={Skeleton} style={{ maxWidth: '200px' }} />
        </Grid>
        <Grid item xs={12} md={4}>
          <Typography component={Skeleton} style={{ maxWidth: '100px' }} />
        </Grid>
      </Grid>
      <Divider style={{ height: 0, margin: '128px 0' }} />
      <Box className={style.Switcher}>
        <Skeleton variant="rect" style={{ width: '200px', height: '48px', margin: '0 auto' }} />
        <Typography component={Skeleton} style={{ width: '250px', margin: '16px auto 0 auto' }} />
        <Typography component={Skeleton} style={{ width: '250px', margin: '0 auto' }} />
      </Box>
      <Container maxWidth="xl" className={style.Plans}>
        <Grid container spacing={4}>
          <Grid item xs={12} md={4}>
            <Skeleton variant="rect" height="600px" />
          </Grid>
          <Grid item xs={12} md={4}>
            <Skeleton variant="rect" height="600px" />
          </Grid>
          <Grid item xs={12} md={4}>
            <Skeleton variant="rect" height="600px" />
          </Grid>
        </Grid>
      </Container>
    </>
  )

  const main = (
    <>
      <CurrentSubscriptionPlan subscription={managePlanStore.retailerSubscription} />
      {managePlanStore.wixError && (
        <Container maxWidth="xl">
          <Alert severity="error" variant="filled">
            <AlertTitle>Cannot Process Your Plan Change</AlertTitle>
            Something went wrong and you will be unable to change your plan at this moment - please try again later.<br />
            If the problem persists, email us at <strong>team@modalyst.co</strong>{' '}
            and provide us with the code below to speed up the process. <br />
            <Chip
              label={`Error Code: ${managePlanStore.wixErrorCodes.join('-')}`}
              color="default"
              className={style.ErrorCode}
            />
          </Alert>
        </Container>
      )}
      <Box className={style.Switcher}>
        <SubscriptionPeriodSwitcher
          values={managePlanStore.subscriptionPeriodsAvailable}
          selectedValue={managePlanStore.subscriptionPeriod}
          onChange={value => managePlanStore.changeSubscriptionPeriod(value)}
        />
        <Typography paragraph className={style.Intro}>
          <strong>Save up to 25%</strong> when you pay <strong>annually</strong>.<br />
          Offer available for Start-up and Pro Plans.
        </Typography>
      </Box>
      <Container maxWidth="xl" className={style.Plans}>
        <SubscriptionPlans plans={managePlanStore.plansForPeriod} />
        <FeaturesTable features={productAutomation} />
        <FeaturesTable features={pricingAutomation} />
      </Container>
    </>
  )
  return (
    <RecurlyProvider publicKey={appConfigStore.recurlyPublicToken}>
      <Helmet>
        <title>Manage Store Plan</title>
      </Helmet>
      <ScrollResetter />
      <Box mb={4}>
        <Typography variant="h2" gutterBottom>
          Manage Store Plan
        </Typography>
        <Typography variant="subtitle1">
          Modalyst makes it simple for an online store to source, list, and sell an array of niche products.
        </Typography>
      </Box>

      {!managePlanStore.loading.isLoaded ? skeleton : main}

      {managePlanStore.retailerExceedsProductLimitStore && (
        <RetailerExceedsProductLimitModal
          store={managePlanStore.retailerExceedsProductLimitStore}
          onExited={() => managePlanStore.resetRetailerExceedsProductLimitStore()}
        />
      )}
      {managePlanStore.planChangeStore && (
        <Elements>
          <PlanUpgradeModal
            store={managePlanStore.planChangeStore}
            onExited={() => managePlanStore.resetPlanForChange()}
          />
        </Elements>
      )}
      {managePlanStore.planCancelStore && (
        <PlanDowngradeModal
          store={managePlanStore.planCancelStore}
          onExited={() => managePlanStore.resetPlanCancellation()}
        />
      )}
      {message === MANAGE_STORE_PLAN_MESSAGE_PAYMENT_SUCCESSFUL && (
        <SubscriptionPaymentSuccessModal onExited={markPaymentSuccessSeen} />
      )}
    </RecurlyProvider>
  )
}

export default observer(ManagePlanPage)
