import React, { useEffect, useState } from 'react'

import PropTypes from 'prop-types'

import { Button, Checkbox, FormControlLabel, Typography } from '@material-ui/core'
import { Launch } from '@material-ui/icons'
import { OutboundLink } from 'react-ga'
import { Link as RouterLink } from 'react-router-dom'

import { ACTIONS, EVENT_LABELS, CONTEXT_MARKETPLACE, useAnalytics } from 'shared/analytics'
import { DataModal } from 'shared/components/organisms'
import { ALIBABA, ALIEXPRESS, integrationsNames, CHROME_EXTENSION_URL } from 'shared/constants/integrations'

import { useStores } from 'retailer/stores/hooks'

/**
 * A modal component to display the information about a missing Alibaba Connection,
 * with an option to go to "Connect suppliers" page to resolve the issue
 */
const ConnectIntegrationModal = ({ name, onExited, onNav }) => {
  const { appConfigStore } = useStores()
  const { reportEvent } = useAnalytics(appConfigStore.gaTrackingId, appConfigStore.debug)

  useEffect(() => {
    reportEvent(ACTIONS.seeIntegrationModal[CONTEXT_MARKETPLACE], ['missing connection', name])
  }, [])

  const nameDisplay = integrationsNames.get(name)
  return (
    <DataModal
      maxWidth="sm"
      title={`Connect ${nameDisplay} to your Modalyst account`}
      renderBody={() => (
        <Typography>
          {`Connect Modalyst to your ${nameDisplay} account. `}
          {`Once connected you can import products from ${nameDisplay}. `}
          We will sync all product details, pricing, inventory and orders.
        </Typography>
      )}
      renderActions={({ dismissModal }) => (
        <>
          <Button onClick={dismissModal}>Cancel</Button>
          <Button
            component={RouterLink}
            to={`/connect-suppliers/${name}`}
            onClick={onNav}
            startIcon={<Launch width={18} height={18} />}
            variant="contained"
            color="primary"
          >
            {`Connect ${nameDisplay}`}
          </Button>
        </>
      )}
      onExited={onExited}
    />
  )
}

ConnectIntegrationModal.propTypes = {
  name: PropTypes.string.isRequired,
  onExited: PropTypes.func.isRequired,
  /** Callback to use when the user clicks the primary button */
  onNav: PropTypes.func,
}

/**
 * A modal component to display the information about a URL to Alibaba Pavillion
 * and the button to navigate to the latter, with an option to permanently
 * dismiss the modal
 */
const SourceAlibabaModal = ({ url, onExited }) => {
  const { appConfigStore } = useStores()
  const { reportEvent } = useAnalytics(appConfigStore.gaTrackingId, appConfigStore.debug)

  const [dismissPermanently, setDismissPermanently] = useState(false)
  const handleDismissChange = () => setDismissPermanently(!dismissPermanently)
  const handleOnExited = () => onExited(dismissPermanently)

  useEffect(() => {
    reportEvent(ACTIONS.seeIntegrationModal[CONTEXT_MARKETPLACE], ['source items', 'alibaba'])
  }, [])

  return (
    <DataModal
      maxWidth="sm"
      title="You will now be redirected to Alibaba"
      renderBody={() => (
        <>
          <Typography>
            Modalyst has a selection of curated products from dropshipping on Alibaba.
            Go to Alibaba to find millions of more dropship products on Alibaba directly.
          </Typography>
          <FormControlLabel
            control={<Checkbox checked={dismissPermanently} onChange={handleDismissChange} name="dismiss" />}
            label="Don't show this message again"
          />
        </>
      )}
      renderActions={({ dismissModal }) => (
        <>
          <Button onClick={dismissModal}>Cancel</Button>
          <Button
            component={OutboundLink}
            eventLabel={EVENT_LABELS.navigateToIntegrationProducts(ALIBABA)}
            to={url}
            target="_blank"
            onClick={dismissModal}
            startIcon={<Launch width={18} height={18} />}
            variant="contained"
            color="primary"
          >
            Find more products on Alibaba
          </Button>
        </>
      )}
      onExited={handleOnExited}
    />
  )
}

SourceAlibabaModal.propTypes = {
  url: PropTypes.string.isRequired,
  onExited: PropTypes.func.isRequired,
}

/**
 * A modal component to display the information about a missing AliExpress
 * extension, with an option to go to "Connect suppliers" page to resolve the issue
 */
const ChromeExtensionModal = ({ onExited }) => {
  const { appConfigStore } = useStores()
  const { reportEvent } = useAnalytics(appConfigStore.gaTrackingId, appConfigStore.debug)

  useEffect(() => {
    reportEvent(ACTIONS.seeIntegrationModal[CONTEXT_MARKETPLACE], ['missing chrome extension', 'aliexpress'])
  }, [])

  return (
    <DataModal
      maxWidth="sm"
      title="Install the Chrome Extension"
      renderBody={() => (
        <Typography>
          Modalyst has a selection of curated products from AliExpress in our marketplace.
          You can add products directly from AliExpress to Modalyst with our Chrome Extension.
        </Typography>
      )}
      renderActions={({ dismissModal }) => (
        <>
          <Button onClick={dismissModal}>Cancel</Button>
          <Button
            component={OutboundLink}
            eventLabel={EVENT_LABELS.installBrowserExtension(ALIEXPRESS)}
            to={CHROME_EXTENSION_URL}
            target="_blank"
            startIcon={<Launch width={18} height={18} />}
            variant="contained"
            color="primary"
          >
            Install Chrome Extension
          </Button>
        </>
      )}
      onExited={onExited}
    />
  )
}

ChromeExtensionModal.propTypes = {
  onExited: PropTypes.func.isRequired,
}

/**
 * Shows information about the supplier (Alibaba, AliExpress) connection when the user
 * tries to access its marketplace (or "pavillion"), eg. missing connection, missing
 * browser extension, dynamic URL etc.
 */
const SupplierMarketplaceNavigationModal = ({ store, onExited }) => {
  const { appConfigStore } = useStores()

  const alibabaUrl = appConfigStore.alibabaDropshippingPageRedirectUrl

  const handleAlibabaModalExited = dismissPermanently => {
    store.setMarketplaceModalDismissed(dismissPermanently)
    onExited && onExited()
  }

  if (!store.isConnected) {
    return (
      <ConnectIntegrationModal name={store.integrationName} onExited={onExited} onNav={onExited} />
    )
  } else {
    switch (store.integrationName) {
      case ALIBABA:
        return <SourceAlibabaModal url={alibabaUrl} onExited={handleAlibabaModalExited} />
      case ALIEXPRESS:
        return <ChromeExtensionModal onExited={onExited} />
    }
  }
}

SupplierMarketplaceNavigationModal.propTypes = {
  store: PropTypes.object.isRequired,
  onExited: PropTypes.func,
}

SupplierMarketplaceNavigationModal.defaultProps = {

}

export default SupplierMarketplaceNavigationModal
