import React from 'react'

import PropTypes from 'prop-types'

import { Button } from '@material-ui/core'
import { observer } from 'mobx-react'

import { LoadingButton } from 'shared/components/atoms'
import { DataModal } from 'shared/components/organisms'

import style from './DestructiveActionConfirmationModal.module.scss'

const DestructiveActionConfirmationModal = ({ title, onConfirm, confirmLabel, onCancel, inProgress, children, testId }) => {
  return (
    <DataModal
      fullWidth
      maxWidth="sm"
      className={style.DestructiveActionConfirmationModal}
      title={title}
      onExited={onCancel}
      renderBody={() => {
        return children
      }}
      renderActions={({ dismissModal }) => {
        return (
          <>
            <Button
              className={style.Cancel}
              testId={testId ? `${testId}-dismissButton` : undefined}
              onClick={dismissModal}
              variant="outlined"
            >
              Cancel
            </Button>
            <LoadingButton
              loading={inProgress}
              className={style.Confirm}
              testId={testId ? `${testId}-confirmButton` : undefined}
              onClick={onConfirm}
              variant="contained"
            >
              {confirmLabel}
            </LoadingButton>
          </>
        )
      }}
    />
  )
}

DestructiveActionConfirmationModal.propTypes = {
  title: PropTypes.string.isRequired,
  onConfirm: PropTypes.func.isRequired,
  confirmLabel: PropTypes.string,
  onCancel: PropTypes.func,
  inProgress: PropTypes.bool,
  children: PropTypes.node.isRequired,
  testId: PropTypes.string,
}

DestructiveActionConfirmationModal.defaultProps = {
  confirmLabel: 'Remove',
  onCancel: () => null,
  inProgress: false,
}

export default observer(DestructiveActionConfirmationModal)
