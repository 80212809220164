import { action, computed, observable } from 'mobx'

import { processApiError } from 'shared/api/utils'

import {
  adaptGetBusinessSettingsResponse,
  adaptPutBusinessSettingsErrors,
  adaptPutBusinessSettingsRequest,
} from 'supplier/adapters/settings'
import { getBusinessSettings, putBusinessSettings } from 'supplier/api/settings'
import { ApiStatusStore } from 'supplier/stores'

class BusinessSettingsStore {
  /** @type {import('../root').RootStore} */
  root

  apiStatus = new ApiStatusStore()

  @observable businessName
  @observable streetAddress
  @observable suiteApt
  @observable city
  @observable state
  @observable zipcode
  @observable country
  @observable phone
  @observable website
  @observable paypalEmail
  @observable brandName
  @observable brandDescription
  @observable productsMadeIn

  /**
   * @param {import('../root').RootStore} root
   */
  constructor (root, data = {}) {
    this.root = root
    this.update(data)
  }

  @action.bound
  update (data) {
    Object.assign(this, data)
  }

  @computed get defaultValues () {
    return {
      businessName: this.businessName || '',
      streetAddress: this.streetAddress || '',
      suiteApt: this.suiteApt || '',
      city: this.city || '',
      state: this.state || '',
      zipcode: this.zipcode || '',
      country: this.country || null,
      phone: this.phone || '',
      website: this.website || '',
      paypalEmail: this.paypalEmail || '',
      brandName: this.brandName || '',
      brandDescription: this.brandDescription || '',
      productsMadeIn: this.productsMadeIn || null,
    }
  }

  @action.bound
  reset () {
    this.apiStatus.reset()
  }

  @action.bound
  fetch () {
    this.apiStatus.setIsLoading(true)
    return getBusinessSettings()
      .then(response => {
        const data = adaptGetBusinessSettingsResponse(response.data)
        this.update(data)
        this.apiStatus.setIsLoaded(true)
        return data
      })
      .finally(() => {
        this.apiStatus.setIsLoading(false)
      })
  }

  @action.bound
  submit (data) {
    this.apiStatus.setIsSaving(true)
    const payload = adaptPutBusinessSettingsRequest(data)
    return putBusinessSettings(payload)
      .then(response => {
        const data = adaptGetBusinessSettingsResponse(response.data)
        this.update(data)
        return data
      })
      .catch(error => {
        const errors = processApiError(error, adaptPutBusinessSettingsErrors)
        this.apiStatus.setRemoteErrors(errors)
        throw error
      })
      .finally(() => {
        this.apiStatus.setIsSaving(false)
      })
  }
}

export default BusinessSettingsStore
