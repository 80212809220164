import React from 'react'

import PropTypes from 'prop-types'

import { Typography } from '@material-ui/core'
import { observer } from 'mobx-react'

import style from './EmptyListSplash.module.scss'

const EmptyListSplash = ({ heading, content, actions }) => {
  return (
    <div className={style.EmptyListSplash}>
      <Typography variant="body1" gutterBottom className={style.Header}>
        {heading}
      </Typography>
      {content}
      {actions && (
        <div className={style.Actions}>
          {actions}
        </div>
      )}
    </div>
  )
}

EmptyListSplash.propTypes = {
  heading: PropTypes.string.isRequired,
  content: PropTypes.node.isRequired,
  actions: PropTypes.node
}

export default observer(EmptyListSplash)
