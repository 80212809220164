import React from 'react'

import PropTypes from 'prop-types'

import {
  Button,
  FormControlLabel,
  Grid,
  Link, Radio,
  Table, TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'
import clsx from 'clsx'
import { observer } from 'mobx-react'
import { Shipping } from 'wix-ui-icons-common'

import { Money } from 'shared/components/atoms'
import { Alert } from 'shared/components/molecules'
import { DataModal } from 'shared/components/organisms'
import { useTranslation } from 'shared/hooks'

import { Country } from 'retailer/components/atoms'
import { useStores } from 'retailer/stores/hooks'

import style from './ImportListProductShippingSelector.module.scss'

const serverErrorMessage = `
  There was an issue with server connection when pulling the shipping data.
  Please try again in a few minutes.`

const errorMessages = {
  404: `No shipping provider is available to ship this product to your chosen country,
        so it cannot be exported. You can try to contact the seller and ask if a new provider could be added.`,
  422: `A 3rd part platform does not give us the shipping options via their API.
      Until they provide us with shipping options, we are not able to add this
      item to your store. The platform is aware of the issue and is working to resolve it.
      The platform has many suppliers selling the same thing. We suggest selecting this item
      from a different supplier.`,
  500: `${serverErrorMessage} (Error Code: 500)`,
  502: `${serverErrorMessage} (Error Code: 502)`,
  503: `${serverErrorMessage} (Error Code: 503)`
}

const CourierTable = observer(({ shippingStore }) => {
  const { t } = useTranslation('importList')
  const { userProfileStore } = useStores()

  if (shippingStore.loading.isLoaded && shippingStore.error) {
    return (
      <div className={style.Table}>
        <Alert severity="error" title="Shipping not available">
          {errorMessages[shippingStore.error] || serverErrorMessage}
        </Alert>
      </div>
    )
  }

  return (
    <div className={style.Table}>
      <div className={clsx(style.ShippingCountry, style.PadBox)}>
        <Grid container spacing={1} alignItems="center">
          <Grid item>
            <Shipping />
          </Grid>
          <Grid item>
            <Typography className={style.ShippingCountryName}>
              {t('main.productPricingSelectShippingModalShippingTo.label')}{' '}<Country iso={userProfileStore.currentStore.country} />
            </Typography>
          </Grid>
        </Grid>
      </div>
      <TableContainer>
        <Table>
          <TableHead className={style.TableHead}>
            <TableRow>
              <TableCell style={{ paddingLeft: 48 }}>
                {t('main.productPricingSelectShippingModalMethod.label')}
              </TableCell>
              <TableCell>
                {t('main.productPricingSelectShippingModalETA.label')}
              </TableCell>
              <TableCell>
                {t('main.productPricingSelectShippingModalCost.label')}
              </TableCell>
            </TableRow>
          </TableHead>
          {!shippingStore.loading.isLoaded && (
            <TableBody>
              <TableRow>
                <TableCell>
                  <Skeleton width={150} />
                </TableCell>
                <TableCell>
                  <Skeleton width={100} />
                </TableCell>
                <TableCell>
                  <Skeleton width={100} />
                </TableCell>
              </TableRow>
            </TableBody>
          )}
          {shippingStore.loading.isLoaded && (
            <TableBody>
              {shippingStore.shippingOptions.map(option => {
                return (
                  <TableRow key={option.shippingProvider}>
                    <TableCell>
                      <FormControlLabel
                        control={
                          <Radio
                            color="primary"
                            checked={shippingStore.selectedProvider?.shippingProvider === option.shippingProvider}
                            onChange={(_, checked) => {
                              if (checked) shippingStore.selectProvider(option)
                            }}
                          />
                        }
                        label={
                          !!option.shippingProviderName && option.shippingProviderName !== ''
                            ? option.shippingProviderName
                            : option.shippingProvider
                        }
                      />
                    </TableCell>
                    <TableCell>
                      {option.minEstimatedDelivery}—{option.maxEstimatedDelivery} days
                    </TableCell>
                    <TableCell>
                      <Money amount={option.convertedShippingCost} currency={userProfileStore.currentStore.currency} />
                    </TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          )}
        </Table>
      </TableContainer>
    </div>
  )
})

CourierTable.propTypes = {
  shippingStore: PropTypes.shape({
    loading: PropTypes.object,
    error: PropTypes.string,
    selectProvider: PropTypes.func.isRequired,
    selectedProvider: PropTypes.string,
    shippingOptions: PropTypes.arrayOf(PropTypes.shape({
      shippingProvider: PropTypes.string,
      minEstimatedDelivery: PropTypes.number,
      maxEstimatedDelivery: PropTypes.number,
      convertedShippingCost: PropTypes.number,
    }))
  }),
}

const ImportListProductShippingSelector = ({ shippingStore }) => {
  const { t } = useTranslation('importList')
  return (
    <DataModal
      fullWidth
      onExited={() => shippingStore.reset()}
      showSpinner={shippingStore.loading.isLoading}
      className={style.ImportListProductShippingSelector}
      keepMounted={false}
      maxWidth="md"
      title={t('main.productPricingSelectShippingModal.title')}
      renderBody={() => {
        return (
          <>
            <Typography variant="subtitle1" className={style.PadBox}>
              {t('main.productPricingSelectShippingModal.subtitle')}{' '}
              <Link
                href={t('main.productPricingSelectShippingModalSubtitleLearnMore.url')}
                target="_blank"
                rel="noopener noreferrer"
              >
                {t('main.productPricingSelectShippingModalSubtitleLearnMore.link')}
              </Link>
            </Typography>
            <CourierTable shippingStore={shippingStore} />
            <Typography variant="subtitle2" className={style.PadBox}>
              {t('main.productPricingSelectShippingModalNote.text')}
            </Typography>
          </>
        )
      }}
      renderActions={({ dismissModal }) => {
        return (
          <>
            <Button
              variant="outlined"
              color="primary"
              onClick={dismissModal}
            >
              {t('main.productPricingSelectShippingModalCancel.cta')}
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={() => shippingStore.save()}
            >
              {t('main.productPricingSelectShippingModalSave.cta')}
            </Button>
          </>
        )
      }}
    />
  )
}

ImportListProductShippingSelector.propTypes = {
  shippingStore: PropTypes.object.isRequired,
}

export default observer(ImportListProductShippingSelector)
