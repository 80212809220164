import React from 'react'

import PropTypes from 'prop-types'

import { Box, Button, Typography } from '@material-ui/core'
import { CheckRounded } from '@material-ui/icons'
import { observer } from 'mobx-react'

import { DataModal } from 'shared/components/organisms'

import { RetailerExceedsProductLimitStore } from 'retailer/stores'

import style from './RetailerExceedsProductLimitModal.module.scss'

const RetailerExceedsProductLimitModal = ({ store, onExited }) => {
  return (
    <DataModal
      className={style.RetailerExceedsProductLimitModal}
      title={`You Have Too Many Products to Change to ${store.plan.userFriendlyName} Plan`}
      onExited={onExited}
      renderBody={() => {
        return (
          <Box mt={2}>
            <Typography paragraph>
              <strong>You have too many items in your Import List & Sync List.</strong>
            </Typography>
            <Typography paragraph>
              To change plans, remove items needed to reach the maximum number
              of <strong>{store.plan.itemLimit} items</strong>{' '}
              in the <strong>{store.plan.userFriendlyName}</strong> plan.
            </Typography>
            <Typography paragraph component="ul">
              <li>The limit is calculated with items in both the Import List and Sync List.</li>
              <li>You can reduce the number from either list—all items will be moved to the Archive List.</li>
              <li>
                If you would like to restore items, you can re-add them to your Import List or Sync List
                directly from the Archive Live.
              </li>
              <li>All edited product info is preserved in your Archive List for 30 days.</li>
            </Typography>
          </Box>
        )
      }}
      renderActions={({ dismissModal }) => {
        return (
          <>
            <Button
              variant="contained"
              color="primary"
              size="large"
              onClick={dismissModal}
              startIcon={<CheckRounded />}
            >
              I understand
            </Button>
          </>
        )
      }}
    />
  )
}

RetailerExceedsProductLimitModal.propTypes = {
  store: PropTypes.instanceOf(RetailerExceedsProductLimitStore).isRequired,
  onExited: PropTypes.func
}

export default observer(RetailerExceedsProductLimitModal)
