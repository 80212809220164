import React from 'react'

import PropTypes from 'prop-types'

import { Button, Link, Typography } from '@material-ui/core'
import { observer } from 'mobx-react'

import BaseOnboardingWidget, { SetupStep } from 'shared/components/organisms/OnboardingWidget'
import { RequireFeature, useFeatureFlags } from 'shared/features'

import { useStores } from 'retailer/stores/hooks'

const OnboardingWidget = ({ store, onDismiss, ...paperProps }) => {
  const {
    steps,
    stepsAllCount,
    stepsDoneCount,
    finished,
    finishedExceptOptional,
  } = store

  const { appConfigStore } = useStores()
  const { alibaba: alibabaEnabled } = useFeatureFlags()

  const supplierIntegrationNames = alibabaEnabled ? 'AliExpress or Alibaba' : 'AliExpress'

  const description = (
    <>
      {!finished && (
        <>
          <Typography paragraph>
            Complete the following steps to get your store setup and ready
            to start selling products
          </Typography>
          <Typography paragraph>
            If you need help, visit our <Link href={appConfigStore.urls.helpCenter}>Help Center</Link>
          </Typography>
        </>
      )}
      {finished && (
        <Typography paragraph>
          Your account has been successfully set up! Enjoy Modalyst!
        </Typography>
      )}
      {finishedExceptOptional && (
        <Typography paragraph>
          {`If you do not wish to connect ${supplierIntegrationNames}, you can safely dismiss this widget`}
        </Typography>
      )}
      {(finished || finishedExceptOptional) && (
        <Button variant="contained" size="small" color="secondary" onClick={onDismiss}>
          Do not show this anymore
        </Button>
      )}
    </>
  )

  return (
    <BaseOnboardingWidget
      title="Finish setting up your store"
      description={description}
      numStepsCompleted={stepsDoneCount}
      numStepsTotal={stepsAllCount}
      {...paperProps}
    >
      <SetupStep
        completed={steps.storeConnected}
        title="Connect your store"
        subTitle="Connect your store to add items to your store, keep info updated & sync orders"
        to="/my-stores"
      />
      <SetupStep
        completed={steps.pricingRulesAdjusted}
        title="Adjust pricing rules"
        subTitle="Control your markups & margins with the Pricing Rules"
        to="/my-products/pricing-rules"
      />
      <RequireFeature name="alibaba">
        <SetupStep
          completed={!!steps.alibabaConnected}
          title={<>Connect Alibaba</>}
          subTitle="Modalyst is Alibaba’s #1 dropshipping partner - source millions of dropship products at wholesale prices"
          to="/connect-suppliers/alibaba"
        />
      </RequireFeature>
      <SetupStep
        completed={steps.aliExpressConnected}
        title="Connect AliExpress"
        subTitle="Search AliExpress for over 100M items to sell"
        to="/connect-suppliers/aliexpress"
      />
      <SetupStep
        completed={steps.productsImported}
        title="Add products to your Import List"
        subTitle="Search our curated marketplace & add up to 25 products for free"
        to="/marketplace"
      />
      <SetupStep
        completed={steps.productsExported}
        title="Sync products to your store"
        subTitle="Publish products to your store to start selling immediately"
        to="/my-products/import-list"
      />
    </BaseOnboardingWidget>
  )
}

OnboardingWidget.propTypes = {
  store: PropTypes.shape({
    steps: PropTypes.shape({
      storeConnected: PropTypes.bool,
      pricingRulesAdjusted: PropTypes.bool,
      productsImported: PropTypes.bool,
      alibabaConnected: PropTypes.bool,
      aliExpressConnected: PropTypes.bool,
      productsExported: PropTypes.bool,
    }).isRequired,
    stepsAllCount: PropTypes.number.isRequired,
    stepsDoneCount: PropTypes.number.isRequired,
    finished: PropTypes.bool.isRequired,
    finishedExceptOptional: PropTypes.bool.isRequired,
  }),
  onDismiss: PropTypes.func,
}

export default observer(OnboardingWidget)
