import React, { useCallback, useEffect } from 'react'

import PropTypes from 'prop-types'

import {
  Grid,
  Hidden,
  Switch,
  TableBody,
  TableCell,
  TableHead,
  TableRow, Tooltip,
  Typography,
} from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'
import clsx from 'clsx'
import isEmpty from 'lodash/isEmpty'
import pick from 'lodash/pick'
import set from 'lodash/set'
import times from 'lodash/times'
import { observer } from 'mobx-react'
import { useSnackbar } from 'notistack'
import { useFormContext } from 'react-hook-form'

import { Alert } from 'shared/components/molecules'
import { TAB_VARIANTS } from 'shared/constants/importList'
import { useTranslation } from 'shared/hooks'
import { importListItemType } from 'shared/types'
import { getFieldErrorMessage } from 'shared/utils/forms'

import { Image, ImportListProductTableField } from 'retailer/components/molecules'
import { ImportListProductTable, ImportListProductVariantImageSelector } from 'retailer/components/organisms'
import {
  getOptionNameFieldName,
  getOptionValueFieldName,
  getSkuFieldName,
  getVariantTabFieldNames,
  getPricingTabFieldNames,
  getVariantsNonFieldName,
  getVariantsTabDefaultValues,
  getPricingTabDefaultValues, checkIfTabHasErrors,
} from 'retailer/utils/importList'

import style from './ImportListProductVariantsTab.module.scss'

const InventoryStock = observer(({ quantity, isPrintOnDemand }) => {
  if (isPrintOnDemand && quantity > 0) return <Typography variant="body2">In stock</Typography>
  if (quantity <= 0) return <Typography variant="body2" color="error">Out of stock</Typography>
  return <Typography variant="body2">{quantity}</Typography>
})

InventoryStock.propTypes = {
  quantity: PropTypes.number.isRequired,
  isPrintOnDemand: PropTypes.bool,
}

const ImportListProductVariantsTab = ({ item, errors }) => {
  const { t } = useTranslation('importList')
  const {
    control,
    formState,
    getValues,
    handleSubmit,
    register,
    reset,
    trigger,
    setValue,
  } = useFormContext()
  const { isDirty, dirtyFields } = formState
  const { enqueueSnackbar } = useSnackbar()

  const resetForm = useCallback((data = {}) => {
    reset({ ...getValues(), ...data }, { errors: true })
    trigger()
  }, [item, reset, trigger])

  getVariantTabFieldNames(item).forEach(fieldName => register(fieldName))
  getPricingTabFieldNames(item).forEach(fieldName => register(fieldName))

  useEffect(() => {
    if (!item.variantsLoaded) {
      item.loadVariants().then(() => {
        resetForm({ ...getVariantsTabDefaultValues(item), ...getPricingTabDefaultValues(item) })
      })
    }
  }, [item.variantsLoaded])

  if (!item.variantsLoaded) {
    return (
      <div className={style.Loader}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Skeleton variant="rect" height={30} />
          </Grid>
          {times(3, idx => (
            <Grid item xs={12} key={idx}>
              <Skeleton variant="rect" height={80} />
            </Grid>
          ))}
        </Grid>
      </div>
    )
  }

  const updateVariantSku = async (variantUuid, sku) => {
    const fieldName = getSkuFieldName(variantUuid)
    item.clearError(fieldName)
    const variant = item.variants.find(variant => variant.uuid === variantUuid)
    const updated = await variant.updateSku(sku)
    if (!updated) return
    const resetData = {}
    set(resetData, fieldName, variant.sku)
    resetForm(resetData)
  }

  const updateOptionName = async (optionUuid, newOptionName) => {
    const fieldName = getOptionNameFieldName(optionUuid)
    item.clearError(fieldName)
    const originalOption = item.uniqueOptionNames.find(option => option.uuid === optionUuid)
    const updated = await item.renameOption({
      uuid: optionUuid,
      optionName: originalOption.key,
      newOptionName: newOptionName,
    })
    if (updated) {
      const resetData = {}
      set(resetData, fieldName, newOptionName)
      resetForm(resetData)
    }
  }

  const updateOptionValue = async (uuid, { variantUuid, value }) => {
    item.clearError(getOptionValueFieldName(uuid, variantUuid))
    const updated = await item.changeOption(uuid, { variantUuid, value })
    if (updated) {
      const resetData = getVariantsTabDefaultValues(item)
      set(resetData, getOptionValueFieldName(uuid, variantUuid), value)
      resetForm(resetData)
    }
  }

  /**
   * Based on dirtyFields pick values which have actually been changed, then run some actions based on that.
   * Note that under normal circumstances only a single field at a time will be changed.
   * @param {Object} data
   * @returns {Promise<void>}
   */
  const onSubmit = async data => {
    const variantsDirtyFields = dirtyFields[TAB_VARIANTS]
    if (!isDirty || isEmpty(variantsDirtyFields)) return

    const { skus, optionNames, optionValues } = data[TAB_VARIANTS]
    const pickedSkus = pick(skus, Object.keys(variantsDirtyFields?.skus || {}))
    const pickedOptionNames = pick(optionNames, Object.keys(variantsDirtyFields?.optionNames || {}))
    const pickedOptionValues = pick(optionValues, Object.keys(variantsDirtyFields?.optionValues || {}))
    const finalPickedOptionValues = {}

    Object.entries(pickedOptionValues).forEach(([optionUuid, fields]) => {
      const variantKey = Object.keys(variantsDirtyFields.optionValues[optionUuid])[0]
      finalPickedOptionValues[optionUuid] = {
        variantUuid: variantKey,
        value: fields[variantKey]
      }
    })

    await Promise.all([
      ...Object.entries(pickedSkus).map(
        ([uuid, sku]) => updateVariantSku(uuid, sku)
      ),
      ...Object.entries(pickedOptionNames).map(
        ([optionUuid, newOptionName]) => updateOptionName(optionUuid, newOptionName)
      ),
      ...Object.entries(finalPickedOptionValues).map(
        ([uuid, data]) => updateOptionValue(uuid, data)
      ),
    ])
  }

  const onError = async () => {
    if (!checkIfTabHasErrors(errors[TAB_VARIANTS])) {
      await onSubmit(getValues())
      return
    }
    enqueueSnackbar(
      `${item.name || 'Item'} was not saved. Please, ensure there are no errors.`,
      { variant: 'error' }
    )
  }

  return (
    <>
      {!!getFieldErrorMessage(errors, getVariantsNonFieldName()) && (
        <Alert className={style.Alert} severity="error">
          {getFieldErrorMessage(errors, getVariantsNonFieldName())}
        </Alert>
      )}
      <ImportListProductTable className={style.ImportListProductVariantsTab}>
        <TableHead>
          <TableRow>
            <TableCell>
              <Hidden xsUp>
                Image
              </Hidden>
            </TableCell>
            <TableCell>
              <span style={{ paddingLeft: 14 }}>
                {t('main.productVariantsSKU.label')}
              </span>
            </TableCell>
            {item.uniqueOptionNames.map(option => {
              const fieldName = getOptionNameFieldName(option.uuid)
              return (
                <TableCell key={option.uuid}>
                  <ImportListProductTableField
                    control={control}
                    testId="importList-importListProduct-optionNameInput"
                    name={fieldName}
                    defaultValue={option.key}
                    placement="head"
                    onBlur={e => {
                      item.clearError(fieldName)
                      setValue(fieldName, e.target.value.trim(), { shouldDirty: true, shouldValidate: true })
                      handleSubmit(onSubmit, onError)(e)
                    }}
                    error={getFieldErrorMessage(errors, fieldName)}
                  />
                </TableCell>
              )
            })}
            <TableCell>
              {t('main.productVariantsInventory.label')}
            </TableCell>
            <TableCell align="right">
              {t('main.productVariantsAddToStore.label')}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {item.variants.map(variant => {
            return (
              <TableRow
                data-testid="importList-importListProduct-variantRow"
                data-test-state={variant.active ? 'active' : 'inactive'}
                key={variant.uuid}
                className={clsx(style.Variant, !variant.active && style.inactive)}
              >
                <TableCell className={style.ImageCell}>
                  <Image
                    ButtonProps={{
                      disabled: false,
                      onClick: () => item.variantImageSelectorStore.openVariant(variant)
                    }}
                    image={variant.image}
                    imgProps={{
                      className: style.Image,
                    }}
                    testId="importList-importListProduct-variantRow-image"
                  />
                </TableCell>
                <TableCell>
                  <ImportListProductTableField
                    testId="importList-importListProduct-variantRow-skuField"
                    control={control}
                    name={getSkuFieldName(variant.uuid)}
                    defaultValue={variant.sku}
                    onBlur={e => {
                      variant.clearError(getSkuFieldName(variant.uuid))
                      item.clearError(getSkuFieldName(variant.uuid))
                      setValue(
                        getSkuFieldName(variant.uuid),
                        e.target.value.trim(),
                        { shouldDirty: true, shouldValidate: true }
                      )
                      handleSubmit(onSubmit, onError)(e)
                    }}
                    error={getFieldErrorMessage(errors, getSkuFieldName(variant.uuid))}
                  />
                </TableCell>
                {item.uniqueOptionNames.map(({ key }) => {
                  const option = variant.options.find(option => option.key === key)
                  if (!option) {
                    return (
                      <TableCell key={`${variant.uuid}-${key}`}>
                        <Typography variant="body2" className={style.MissingOption}>
                          -
                        </Typography>
                      </TableCell>
                    )
                  }
                  const fieldName = getOptionValueFieldName(option.uuid, variant.uuid)
                  return (
                    <TableCell key={option.uuid}>
                      <ImportListProductTableField
                        control={control}
                        name={fieldName}
                        testId="importList-importListProduct-variantRow-optionField"
                        defaultValue={option.value}
                        onBlur={e => {
                          item.clearError(fieldName)
                          setValue(fieldName, e.target.value.trim(), { shouldDirty: true, shouldValidate: true })
                          handleSubmit(onSubmit, onError)(e)
                        }}
                        error={getFieldErrorMessage(errors, fieldName)}
                      />
                    </TableCell>
                  )
                })}
                <TableCell>
                  <InventoryStock quantity={variant.quantity} isPrintOnDemand={item.isPrintOnDemand} />
                </TableCell>
                <TableCell align="right">
                  <Tooltip
                    title={
                      variant.active && item.computedActiveVariantsCount === 1
                        ? 'At least one variant has to be active'
                        : ''
                    }
                  >
                    <span>
                      <Switch
                        data-testid="importList-importListProduct-variantRow-addToStoreSwitch"
                        color="primary"
                        disabled={variant.active && item.computedActiveVariantsCount === 1}
                        checked={variant.active}
                        onChange={async (_, checked) => {
                          const isSuccess = await variant.setActive(checked)
                          if (!isSuccess) {
                            enqueueSnackbar(
                              `Failed to ${checked ? 'activate' : 'deactivate'} variant`,
                              { variant: 'error' }
                            )
                          }
                        }}
                      />
                    </span>
                  </Tooltip>
                </TableCell>
              </TableRow>
            )
          })}
        </TableBody>
      </ImportListProductTable>
      {item.variantImageSelectorStore.isOpen && (
        <ImportListProductVariantImageSelector variantImageSelectorStore={item.variantImageSelectorStore} />
      )}
    </>
  )
}

ImportListProductVariantsTab.propTypes = {
  item: importListItemType.isRequired,
  errors: PropTypes.object,
}

ImportListProductVariantsTab.defaultProps = {
  errors: {},
}

export default observer(ImportListProductVariantsTab)
