import React, { forwardRef, useRef } from 'react'

import PropTypes from 'prop-types'

import { yupResolver } from '@hookform/resolvers/yup'
import { Button, Typography } from '@material-ui/core'
import TextField from '@material-ui/core/TextField'
import { observer } from 'mobx-react'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'

import { Alert } from 'shared/components/molecules'
import { DataModal } from 'shared/components/organisms'
import { integrationsNames } from 'shared/constants/integrations'
import { getFieldErrorMessage, submitForm } from 'shared/utils/forms'

import { OrderModalActionState } from 'retailer/stores'

import style from './MarkOrderedModal.module.scss'

const validationSchema = yup.object().shape({
  externalId: yup.string()
    .max(255, 'External id cannot be longer than 255 characters')
    .required('Please provide the external id')
})

const OrderExternalIdForm = observer(forwardRef(function Form (props, ref) {
  // Apparently, using forwardRef throws prop-types off and does not properly read the proptypes declaration.
  // eslint-disable-next-line react/prop-types
  const { onSubmit, disableFields, integrationName } = props
  const { errors, handleSubmit, register } = useForm({
    resolver: yupResolver(validationSchema)
  })

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      ref={ref}
      className={style.Form}
    >
      <TextField
        required
        fullWidth
        disabled={disableFields}
        variant="outlined"
        data-testid="externalId"
        label={`${integrationName} order ID`}
        name="externalId"
        inputRef={register({ required: true, maxLength: 255 })}
        error={!!errors.externalId}
        helperText={getFieldErrorMessage(errors, 'externalId')}
      />
      <input data-testid="submit" type="submit" className={style.Submit} />
    </form>
  )
}))

OrderExternalIdForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  disableFields: PropTypes.bool,
  integrationName: PropTypes.string.isRequired
}

OrderExternalIdForm.defaultProps = {
  disableFields: false
}

const MarkOrderedModal = ({ store, ...rest }) => {
  const formEl = useRef(null)
  const handleSubmitClick = () => submitForm(formEl)

  const integrationName = integrationsNames.get(store.order.orderType)

  return (
    <DataModal
      fullWidth
      className={style.MarkOrderedModal}
      showSpinner={store.inProgress}
      title="Mark as ordered"
      hide={store.done}
      renderBody={({ showSpinner }) => (
        <div className={style.ModalContent}>
          <Typography variant="body1" gutterBottom>
            To mark as ordered, please enter <strong>{integrationName} order ID</strong>.
            This will sync the order between {integrationName},
            Modalyst and your store for automated updates.
          </Typography>
          {store.error
            ? (
              <Alert severity="error">
                Error. Please try marking the order as ordered again.
              </Alert>
              )
            : null}
          <OrderExternalIdForm
            ref={formEl}
            onSubmit={({ externalId }) => store.markOrdered(externalId)}
            disableFields={showSpinner}
            integrationName={integrationName}
          />
        </div>
      )}
      renderActions={({ dismissModal, showSpinner }) => (
        <>
          <Button
            onClick={dismissModal}
            disabled={showSpinner}
            color="primary"
            size="large"
          >
            Cancel
          </Button>
          <Button
            onClick={handleSubmitClick}
            disabled={showSpinner}
            color="primary"
            variant="contained"
            size="large"
          >
            Update
          </Button>
        </>
      )}
      {...rest}
    />
  )
}

MarkOrderedModal.propTypes = {
  store: PropTypes.instanceOf(OrderModalActionState)
}

export default observer(MarkOrderedModal)
