import React, { useMemo } from 'react'

import PropTypes from 'prop-types'

import { Button, Tooltip } from '@material-ui/core'
import { QueueRounded, LibraryAddCheckRounded } from '@material-ui/icons'
import clsx from 'clsx'
import { Link as RouterLink } from 'react-router-dom'

import { LoadingButton } from 'shared/components/atoms'

import style from './AddProductActionButton.module.scss'

const ImportableIcon = <QueueRounded className={style.ButtonIcon} />
const AlreadyImportedIcon = <LibraryAddCheckRounded className={style.ButtonIcon} />

/**
 * A button component that consumes props injected by AddProductAction
 *
 * TODO: completely remove icon after GA of the new product details page (Reut's request)
 * */
const AddProductActionButton = ({
  status, disabled, children, title, onClick, to, testId, // injected props
  disableIcon, className, ...rest // own props
}) => {
  const Icon = useMemo(() => {
    if (disableIcon) return undefined
    switch (true) {
      case status === 'added': return AlreadyImportedIcon
      default: return ImportableIcon
    }
  }, [disableIcon, status])

  const buttonProps = Object.assign({
    className: clsx(style.AddProductActionButton, className),
    variant: status === 'added' ? 'outlined' : 'contained',
    color: 'primary',
    disabled,
    component: disabled ? 'div' : (to ? RouterLink : undefined),
    startIcon: Icon,
    onClick: disabled ? undefined : onClick,
    to: disabled ? undefined : to,
  }, rest)

  const button = (
    <div className={style.InteractiveWrapper}>
      <LoadingButton loading={status === 'adding'} testId={testId} {...buttonProps}>
        {children}
      </LoadingButton>
    </div>
  )

  return title
    ? <Tooltip title={title} placement="top" arrow>{button}</Tooltip>
    : button
}

AddProductActionButton.propTypes = {
  disableIcon: PropTypes.bool,
  testId: PropTypes.string,
  ...Button.propTypes,
}

AddProductActionButton.defaultProps = {

}

export default AddProductActionButton
