import { useEffect } from 'react'

import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import isObject from 'lodash/isObject'
import isString from 'lodash/isString'

/**
 * Submit form represented by a ref
 * @param {object} formRef a React ref to the form element
 */
const submitForm = formRef => {
  if (formRef && formRef.current) {
    formRef.current.dispatchEvent(new Event('submit', { cancelable: true }))
  }
}

/**
 * Format and return message to display based on RHF field error state
 *
 * @param {Object} errors React Hook Form errors state
 * @param {string} fieldName name of the field
 */
const getFieldErrorMessage = (errors, fieldName) => {
  const error = errors && get(errors, fieldName)
  if (error) {
    if (typeof error === 'string') return error
    // return custom messages
    if (error.message) {
      return error.message
    }
    // return fallback messages
    switch (error.type) {
      case 'required':
        return 'This field is required'
      case 'maxLength':
        return 'The provided value is too long'
      case 'minLength':
        return 'The provided value is too short'
      default:
        return ''
    }
  }
}

/**
 * Set React Hook Form field errors based on the provided remote validation data
 *
 * Combines remote field errors into a single message - apparently RHC does not allow
 * for adding multiple errors per field
 *
 * @param {Object} fieldErrors map of field to error messages
 * @param {Function} setError RHF callback for setting an error on the field
 */
const setFieldErrors = (fieldErrors, setError) => {
  Object.entries(fieldErrors).forEach(([fieldName, fieldErrorList]) => {
    if (Array.isArray(fieldErrorList) && fieldErrorList.length) {
      setError(fieldName, { type: 'remote', message: fieldErrorList.join(' ') })
    }
    if (typeof fieldErrorList === 'string') {
      setError(fieldName, { type: 'remote', message: fieldErrorList })
    }
  })
}

/**
 * Transform a nested structure of errors object into a flat object compatible with RHF
 * ie.
 * {
 *  "rootLevelField": ["Error"],
 *  "nested.0.field": ["Error 1", "Error 2"],
 * }
 * @param {Object} fieldErrors errors object, as returned from the API, ie. potentially nested
 * @param {String} prefix recursion prefix
 * @returns {Object} flat errors object
 */
const flattenErrors = (fieldErrors, prefix = '') => (
  Object.keys(fieldErrors).reduce((flatErrors, key) => {
    const pre = prefix.length ? prefix + '.' : ''
    const value = fieldErrors[key]
    if (!isEmpty(value)) {
      if (Array.isArray(value) && isString(value[0])) flatErrors[pre + key] = value
      else if (isObject(value)) Object.assign(flatErrors, flattenErrors(value, pre + key))
      else throw new Error('Unexpected errors object structure')
    }
    return flatErrors
  }, {})
)

/**
 * A react hook to set form errors based on a standard nonFieldErrors+fieldErrors object
 *
 * If remoteErrors is observable, ensure the hook is used in an observer component.
 * Assumes form fields are named after the payload fields. Assumes RHF is responsible
 * for clearing field errors when necessary (eg. on submit).
 *
 * @param {Object} remoteErrors an object in a standard API error response format
 * @param {Function} setError RHF callback for setting an error on the field
 * @returns {Object} object with normalized non-field errors array or undefined if no non-field error
 */
const useRemoteErrors = (remoteErrors, setError, fieldErrorReducer = flattenErrors) => {
  const remoteErrorsJson = JSON.stringify(remoteErrors || {})

  // XXX: useEffect ensures the component does not fall into re-rendering cascade
  // XXX: useEffect deps are compared using reference equality, using JSON string is a somewhat dirty hack
  //      to ensure that the effect is re-run when the remoteErrors object changes
  useEffect(() => {
    const remoteErrorsObject = JSON.parse(remoteErrorsJson)
    if (!isEmpty(remoteErrorsObject?.fieldErrors) && setError) {
      const flatFieldErrors = fieldErrorReducer(remoteErrorsObject.fieldErrors)
      setFieldErrors(flatFieldErrors, setError)
    }
  }, [remoteErrorsJson, setError])

  let { nonFieldErrors, fieldErrors, allErrors } = remoteErrors || {}
  nonFieldErrors = Array.isArray(nonFieldErrors) && nonFieldErrors.length ? nonFieldErrors : undefined
  fieldErrors = fieldErrors && Object.keys(fieldErrors).length ? fieldErrors : undefined

  // FIXME: allErrors will almost certainly be unusable if the for is using a nested field struct
  allErrors = Object.values(fieldErrors || {}).reduce(
    (allE, fieldE) => fieldE ? allE.concat(fieldE) : allE,
    nonFieldErrors ? nonFieldErrors.slice() : []
  )
  allErrors = allErrors.length ? allErrors : undefined

  return { nonFieldErrors, fieldErrors, allErrors }
}

export {
  submitForm,
  getFieldErrorMessage,
  setFieldErrors,
  useRemoteErrors,
}
