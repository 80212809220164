import React, { useEffect, useState } from 'react'

import PropTypes from 'prop-types'

import { Checkbox, IconButton, Zoom } from '@material-ui/core'
import clsx from 'clsx'
import { observer } from 'mobx-react'
import { ZoomIn } from 'wix-ui-icons-common'

import { ImageZoom } from 'shared/components/organisms'

import { MoreButtonWithMenu } from 'retailer/components/organisms'

import style from './ImageGallery.module.scss'

const Image = observer(({
  id,
  url,
  thumbnail,
  isMain,
  selected,
  onSelect,
  onZoom,
  renderMenu,
  className,
  testId,
  ...rest
}) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false)

  return (
    <Zoom in key={id}>
      <div
        data-testid={testId ? `${testId}-tile` : undefined}
        className={clsx(style.Tile, selected && style.selected, isMain && style.featured, className)}
        {...rest}
      >
        <img
          data-testid={testId ? `${testId}-tile-image` : undefined}
          className={style.Image}
          src={thumbnail || url}
          alt=""
        />
        {renderMenu && (
          <div className={clsx(style.Overlay, isMenuOpen && style.keepOpen)}>
            <IconButton
              data-testid={testId ? `${testId}-tile-enlargeButton` : undefined}
              color="primary"
              className={style.EnlargeButton}
              onClick={() => onZoom(url || thumbnail)}
            >
              <ZoomIn />
            </IconButton>
            <MoreButtonWithMenu
              testId={testId ? `${testId}-tile-moreButton` : undefined}
              ButtonProps={{ className: style.MoreButton }}
              render={popupState => {
                // Tile loses the :hover state when menu is open, thus we have to enforce an open state
                // as long as the menu is open.
                useEffect(() => {
                  if (popupState.isOpen !== isMenuOpen) setIsMenuOpen(popupState.isOpen)
                }, [popupState.isOpen])
                return renderMenu(id, popupState)
              }}
            />
          </div>
        )}
        {onSelect && <div className={style.CheckboxBackground} />}
        {onSelect && (
          <Checkbox
            data-testid={testId ? `${testId}-tile-checkbox` : undefined}
            color="primary"
            checked={selected}
            onChange={(_, checked) => onSelect(id, checked)}
            className={style.Checkbox}
          />
        )}
      </div>
    </Zoom>
  )
})

Image.propTypes = {
  id: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  thumbnail: PropTypes.string,
  isMain: PropTypes.bool,
  selected: PropTypes.bool,
  onSelect: PropTypes.func,
  onZoom: PropTypes.func,
  renderMenu: PropTypes.func,
  className: PropTypes.string,
  testId: PropTypes.string,
}

/**
 * A simple image gallery component. By default, it will only display a list of images,
 * but these can optionally:
 *    1. be selectable (then the image has to have the `selected` prop, as well as `onSelect` has to be provided)
 *    2. have an additional menu (use `renderMenu` prop, which receives the popupState and image id)
 */
const ImageGallery = ({ images, onSelect, renderMenu, ImageProps, testId }) => {
  const [zoomedSrc, setZoomedSrc] = useState(null)

  return (
    <div className={style.ImageGallery} data-testid={testId}>
      {images.map(image => (
        <Image
          testId={testId}
          key={image.id}
          {...image}
          onSelect={onSelect}
          renderMenu={renderMenu}
          onZoom={setZoomedSrc}
          {...ImageProps}
        />
      ))}
      <ImageZoom
        src={zoomedSrc}
        isZoomed={!!zoomedSrc}
        handleClose={() => setZoomedSrc(null)}
        testId={testId ? `${testId}-zoom` : undefined}
      />
    </div>
  )
}

ImageGallery.propTypes = {
  images: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    thumbnail: PropTypes.string,
    isMain: PropTypes.bool,
    selected: PropTypes.bool,
  })),
  onSelect: PropTypes.func,
  renderMenu: PropTypes.func,
  ImageProps: PropTypes.object,
  testId: PropTypes.string,
}

ImageGallery.defaultProps = {
  ImageProps: {},
}

export default observer(ImageGallery)
