import React from 'react'

import PropTypes from 'prop-types'

import { Accordion, AccordionSummary, AccordionDetails, Avatar, List, Typography } from '@material-ui/core'
import { ExpandMore, Check, PriorityHigh, Remove } from '@material-ui/icons'
import clsx from 'clsx'
import { observer } from 'mobx-react'

import { SUPPLIER_APP_INTEGRATION_STATUSES } from 'retailer/constants'

import style from './SupplierAppConnectionCard.module.scss'

const ConnectionStatusAvatar = observer(({ status }) => {
  const { connected, expired } = SUPPLIER_APP_INTEGRATION_STATUSES
  switch (status) {
    case connected: return <Avatar className={style.Avatar}><Check /></Avatar>
    case expired: return <Avatar className={style.Avatar}><PriorityHigh /></Avatar>
    default: return <Avatar className={style.Avatar}><Remove /></Avatar>
  }
})

ConnectionStatusAvatar.propTypes = {
  status: PropTypes.oneOf(Object.values(SUPPLIER_APP_INTEGRATION_STATUSES)),
}

const SupplierAppConnectionCard = ({ className, expanded, ariaPrefix, store, beta, logo, intro, outro, children }) => {
  const rootClassName = clsx(style.Root, style[`Root-${store.status}`], className)
  return (
    <Accordion className={rootClassName} defaultExpanded={expanded}>
      <AccordionSummary
        expandIcon={<ExpandMore />}
        aria-controls={`${ariaPrefix}-content`}
        id={`${ariaPrefix}-header`}
        className={style.Header}
      >
        <div className={style.AvatarContainer}>
          <ConnectionStatusAvatar status={store.status} />
        </div>
        <div className={style.HeaderContent}>
          <div className={style.LogoContainer}>
            {logo}
          </div>
          <Typography component="div" className={style.InfoContainer}>
            {store.connectionText}
          </Typography>
        </div>
      </AccordionSummary>
      <AccordionDetails className={style.Content}>
        {intro ? <div className={style.Intro}>{intro}</div> : null}
        <List
          component="div"
          aria-label="connection steps"
          className={style.StepList}
          disablePadding
        >
          {children}
        </List>
        {outro ? <div className={style.Outro}>{outro}</div> : null}
      </AccordionDetails>
    </Accordion>
  )
}

SupplierAppConnectionCard.propTypes = {
  className: PropTypes.string,
  expanded: PropTypes.bool,
  ariaPrefix: PropTypes.string.isRequired,
  beta: PropTypes.bool,
  logo: PropTypes.node.isRequired,
  intro: PropTypes.node,
  outro: PropTypes.node,
  children: PropTypes.node,
  store: PropTypes.shape({
    status: PropTypes.oneOf(Object.values(SUPPLIER_APP_INTEGRATION_STATUSES)),
    connectionText: PropTypes.string,
  }).isRequired,
}

SupplierAppConnectionCard.defaultProps = {
  expanded: false,
}

export default observer(SupplierAppConnectionCard)
