import React from 'react'

import PropTypes from 'prop-types'

import { Button, Chip, Tooltip } from '@material-ui/core'
import clsx from 'clsx'
import { observer } from 'mobx-react'

import { SYNC_STATUS_ERROR } from 'shared/constants/retailerItems'

import style from './SyncListItemStatusBadge.module.scss'

const SyncListItemStatusBadge = ({ status, description, onRetry, className, testId }) => {
  if (status !== SYNC_STATUS_ERROR) return null

  let defaultErrorDescription = 'We were not able to sync this product to your external store.'
  if (onRetry) defaultErrorDescription += ' You can retry the sync progress.'

  return (
    <div className={clsx(style.SyncListItemStatusBadge, className)}>
      <Tooltip title={(!description || description === '') ? defaultErrorDescription : description}>
        <Chip
          className={style.Badge}
          data-testid={testId}
          label="Sync failed"
          size="small"
        />
      </Tooltip>
      {onRetry && (
        <Button
          onClick={onRetry}
          data-testid={testId ? `${testId}-retryButton` : undefined}
          variant="text"
          color="primary"
          size="small"
        >
          Retry
        </Button>
      )}
    </div>
  )
}

SyncListItemStatusBadge.propTypes = {
  status: PropTypes.string,
  description: PropTypes.string,
  onRetry: PropTypes.func,
  className: PropTypes.string,
  testId: PropTypes.string,
}

export default observer(SyncListItemStatusBadge)
