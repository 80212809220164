import React from 'react'

import PropTypes from 'prop-types'

import {
  FormControl,
  FormGroup,
  InputLabel,
  MenuItem,
  Select,
} from '@material-ui/core'
import { observer } from 'mobx-react'

import { SearchField } from 'shared/components/organisms'

import style from './OrdersFilters.module.scss'

const OrdersFilters = ({ filters }) => {
  const {
    searchFilter,
    orderStatusFilter,
    orderFulfillmentStatusFilter,
    orderSourceFilter,
  } = filters

  return (
    <FormGroup row className={style.OrdersFilters}>
      <SearchField
        fullWidth
        className={style.Search}
        onSubmit={value => searchFilter.setFilter(value)}
        id="search"
        label="Search"
        variant="outlined"
        placeholder="Filter by keywords"
        defaultValue={searchFilter.value}
      />
      <FormControl
        className={style.Filter}
        variant="outlined"
      >
        <InputLabel id="status-label">Status</InputLabel>
        <Select
          labelId="status-label"
          id="status"
          value={orderStatusFilter.value}
          onChange={e => orderStatusFilter.setFilter(e.target.value)}
          label="Status"
          inputProps={{
            className: style.Input,
          }}
        >
          {orderStatusFilter.values.map(option => (
            <MenuItem
              key={option.value}
              value={option.value}
            >
              {option.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl
        className={style.Filter}
        variant="outlined"
      >
        <InputLabel id="status-label">Fulfillment Status</InputLabel>
        <Select
          labelId="status-label"
          id="status"
          value={orderFulfillmentStatusFilter.value}
          onChange={e => orderFulfillmentStatusFilter.setFilter(e.target.value)}
          label="Fulfillment Status"
          disabled={orderFulfillmentStatusFilter.isDisabled}
          inputProps={{
            className: style.Input,
          }}
        >
          {orderFulfillmentStatusFilter.values.map(option => (
            <MenuItem
              key={option.value}
              value={option.value}
            >
              {option.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl
        className={style.Filter}
        variant="outlined"
      >
        <InputLabel id="source-label">Source</InputLabel>
        <Select
          displayEmpty
          labelId="source-label"
          id="source"
          value={orderSourceFilter.value}
          onChange={e => orderSourceFilter.setFilter(e.target.value)}
          label="Source"
          inputProps={{
            className: style.Input,
          }}
        >
          {orderSourceFilter.values.map(option => (
            <MenuItem
              key={option.value}
              value={option.value}
            >
              {option.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </FormGroup>
  )
}

OrdersFilters.propTypes = {
  filters: PropTypes.object.isRequired,
}

export default observer(OrdersFilters)
