import { action, computed, observable } from 'mobx'

import { TAB_VARIANTS } from 'shared/constants/importList'

import { adaptVariantImageForBiEvent } from './utils'

class ImportListProductVariantImageSelectorStore {
  /** @type {ImportListProductStore} */
  @observable parent

  @observable variant
  @observable selectedImageId
  @observable isSaving = false

  constructor (parent) {
    this.parent = parent
  }

  @computed get images () {
    return this.parent.orderedImages.map(image => {
      const copy = {}
      Object.assign(copy, image)
      copy.selected = image.id === this.selectedImageId
      copy.isMain = false
      return copy
    })
  }

  @computed get isOpen () {
    return !!this.variant
  }

  @action.bound
  openVariant (variant) {
    this.variant = variant
    this.selectedImageId = this.variant.imageId
  }

  @action.bound
  selectImage (id) {
    this.selectedImageId = id
  }

  @action.bound
  async save () {
    this.isSaving = true
    const biEvent = await this.parent.logProductEdited({
      settingTab: TAB_VARIANTS,
      settingField: 'variantImageChanged',
      modalystVariantIds: this.variant.uuid,
      oldValue: adaptVariantImageForBiEvent(this.variant.image),
      newValue: adaptVariantImageForBiEvent(this.images.find(image => image.id === this.selectedImageId)),
    })
    const saved = await this.variant.patch({ image_id: this.selectedImageId }, biEvent)
    this.isSaving = false
    return saved
  }

  @action.bound
  reset () {
    this.variant = undefined
  }
}

export default ImportListProductVariantImageSelectorStore
