import { action, computed, observable } from 'mobx'

import { adaptItem, adaptItemShippingTable } from 'shared/adapters/marketplace'
import { adaptRetailerPricingRule } from 'shared/adapters/retailer'
import { getItem, getItems, getItemShippingTable } from 'shared/api/marketplace'
import { TABS } from 'shared/constants/marketplaceItem'
import { LoadableStore } from 'shared/stores'
import { QueryParamsOperator } from 'shared/utils'
import { keysToSnakeCase } from 'shared/utils/api'

import { ItemStore, RetailerPricingRuleStore } from 'retailer/stores'

const SIMILAR_ITEMS_COUNT = 5

class MarketplaceItemStore {
  /** @type {import('../context').RootStore} */
  root

  @observable loading = new LoadableStore()
  @observable error = null
  @observable item // Actual item data
  @observable pricingRule = null // Retailer's pricing rule for the item's inventory type
  @observable moreItems // Similar items
  @observable shippingTable = null

  @observable selectedTabId = TABS.DESCRIPTION

  /** @param {import('../context').RootStore} root */
  constructor (root, data = {}) {
    this.root = root
    Object.assign(this, data)
  }

  /**
   * API params for fetching "More from brand" items.
   * @returns {string}
   */
  @computed get similarItemsApiParams () {
    const params = {
      brands: [this.item.brand.uuid],
      [this.item.inventoryType]: [this.item.brand.uuid],
      pageSize: SIMILAR_ITEMS_COUNT,
      omit: this.item.id
    }
    return new QueryParamsOperator(keysToSnakeCase(params)).getSearchAsText()
  }

  @action.bound
  fetchShipping () {
    if (!this.item) return Promise.reject(new Error('no item'))
    return getItemShippingTable(this.item.id)
      .then(response => {
        this.shippingTable = adaptItemShippingTable(response.data)
      })
  }

  @action.bound
  fetchSimilar () {
    if (!this.item) return Promise.reject(new Error('no item'))
    // disallow for POD? if not, use a different api fn
    return getItems(this.similarItemsApiParams)
      .then(response => {
        this.moreItems = response.data.results.map(
          (item, pos) => new ItemStore(
            this.root,
            adaptItem(item),
            { origin: 'product_page', position: pos + 1 }
          )
        )
      })
      .catch(() => null)
  }

  @action.bound
  fetch (id) {
    this.loading.setLoading()
    return getItem(id)
      .then(response => {
        if (response.data.pricing_rule) {
          this.pricingRule = new RetailerPricingRuleStore(adaptRetailerPricingRule(response.data.pricing_rule))
        }
        this.item = new ItemStore(this.root, adaptItem(response.data), { origin: 'product_page' })
        this.fetchShipping()
        if (!this.item.isCustomizable && !this.item.is3rdPartySupplier) {
          this.fetchSimilar()
        }
        this.loading.setLoaded()
      })
      .catch(error => {
        this.error = error
        throw error
      })
  }

  @action.bound
  reset () {
    this.loading.isLoaded = false
    this.item = undefined
    this.moreItems = undefined
    this.error = null
    this.shippingTable = null
    this.selectedTabId = TABS.DESCRIPTION
  }

  @action.bound
  selectTab (tabId) {
    if (!Object.values(TABS).includes(tabId)) throw Error('Invalid tabId provided!')
    this.selectedTabId = tabId
  }
}

export default MarketplaceItemStore
