import React, { useEffect } from 'react'

import PropTypes from 'prop-types'

import {
  Box,
  Button,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@material-ui/core'
import { Launch } from '@material-ui/icons'
import clsx from 'clsx'
import { autorun } from 'mobx'
import { observer } from 'mobx-react'
import { useSnackbar } from 'notistack'
import { OutboundLink } from 'react-ga'

import { EVENT_LABELS } from 'shared/analytics'
import { ALIBABA, ALIEXPRESS, CHROME_EXTENSION_URL } from 'shared/constants/integrations'
import { RequireFeature } from 'shared/features'
import { AliExpressLogo, AlibabaLogo } from 'shared/icons/external'

import { SupplierAppConnectionCard, SupplierAppConnectionStep } from 'retailer/components/organisms'
import { SUPPLIER_APP_INTEGRATION_STATUSES } from 'retailer/constants'
import { useStores } from 'retailer/stores/hooks'

import style from './SupplierAppConnectionList.module.scss'

/**
 * Get status prop value for SupplierAppConnectionStep component based on integration's store
 *
 * @param {SupplierAppIntegrationStore} integrationStore
 * @returns {string} status
 */
const getConnectionStepStatus = (integrationStore) => {
  const { connected, expired } = SUPPLIER_APP_INTEGRATION_STATUSES
  switch (integrationStore.status) {
    case connected: return 'success'
    case expired: return 'error'
    default: return 'default'
  }
}

/**
 * Renders a list of panes for each Supplier App Integration
 * and the steps contained within
 */
const SupplierAppConnectionList = ({ className, expandedCard }) => {
  const {
    connectToSuppliersStore: store,
    appConfigStore,
    userProfileStore,
  } = useStores()
  const { enqueueSnackbar } = useSnackbar()

  const alibabaUrl = appConfigStore.alibabaDropshippingPageRedirectUrl

  useEffect(() => {
    // monitor flag changes from store to notify user with toasts; note: autorun returns as disposer
    return autorun(() => {
      const joinedProgramSuccess = userProfileStore.aliexpressJoinedDropshippingProgramSuccess
      if (joinedProgramSuccess !== undefined) {
        enqueueSnackbar(
          joinedProgramSuccess ? 'Saved successfully!' : 'Something went wrong. Please try again.',
          { variant: joinedProgramSuccess ? 'success' : 'error' }
        )
      }
    })
  }, [])

  const handleStoreSelectChange = event => {
    const success = store.alibaba.changeDefaultStoreForSupplierAppAuthorization(event.target.value)
    if (success) {
      enqueueSnackbar('Changes saved successfully', { variant: 'success' })
    } else {
      enqueueSnackbar('Something went wrong. Please try again.', { variant: 'error' })
    }
  }

  return (
    <div className={clsx(style.Root, className)}>
      <RequireFeature name="alibaba">
        <SupplierAppConnectionCard
          ariaPrefix="alibaba"
          expanded={expandedCard === 'alibaba'}
          beta
          logo={<AlibabaLogo className={style.AlibabaLogo} />}
          intro={
            <>
              <Typography paragraph className={style.Intro}>
                Modalyst was handpicked by Alibaba as their #1 dropshipping partner
                - source millions of dropship products at wholesale prices with no
                advance purchases & no inventory risk. Products are available in
                single units with no minimum order requirements.
              </Typography>
              {store.alibaba.authorization && (
                <Box mt={3} mb={3}>
                  <FormControl fullWidth className={style.DefaultStoreSelect}>
                    <InputLabel>Products imported from Alibaba will be added to</InputLabel>
                    <Select
                      value={store.alibaba.authorization.defaultStoreUuid}
                      onChange={handleStoreSelectChange}
                    >
                      {userProfileStore.stores.map(store => {
                        return (
                          <MenuItem value={store.uuid} key={store.uuid}>
                            {store.name}
                          </MenuItem>
                        )
                      })}
                    </Select>
                  </FormControl>
                </Box>
              )}
            </>
          }
          outro={store.alibaba.status === SUPPLIER_APP_INTEGRATION_STATUSES.connected && (
            <Button
              component={OutboundLink}
              eventLabel={EVENT_LABELS.navigateToIntegrationProducts(ALIBABA)}
              to={alibabaUrl}
              target="_blank"
              endIcon={<Launch />}
              variant="contained"
              color="primary"
            >
              Find dropship items on Alibaba
            </Button>
          )}
          store={store.alibaba}
        >
          <SupplierAppConnectionStep
            eventLabel={EVENT_LABELS.connectIntegration(ALIBABA)}
            to={appConfigStore.alibabaAppAuthorizeUrl}
            status={getConnectionStepStatus(store.alibaba)}
            primary={
            store.alibaba.authorization
              ? 'Renew your connection to Alibaba'
              : 'Connect your account to Alibaba'
            }
            secondary="Once connected, all product information, updates, & orders will be synced with your store"
          />
        </SupplierAppConnectionCard>
      </RequireFeature>

      <SupplierAppConnectionCard
        ariaPrefix="aliexpress"
        expanded={expandedCard === 'aliexpress'}
        logo={<AliExpressLogo className={style.AliExpressLogo} />}
        store={store.aliexpress}
        intro={
          <Typography paragraph className={style.Intro}>
            Connecting AliExpress gives you access to 100M products in 2800 categories.
            You can also join the AliExpress Dropshipping Program for increased insight into
            the sales data and install our own Chrome extension to add products to Modalyst directly
            from AliExpress.
          </Typography>
        }
      >
        <SupplierAppConnectionStep
          eventLabel={EVENT_LABELS.connectIntegration(ALIEXPRESS)}
          to={appConfigStore.aliexpressOauthAuthorizeUrl}
          status={getConnectionStepStatus(store.aliexpress)}
          primary={
            store.aliexpress.authorization
              ? 'Renew your connection to AliExpress'
              : 'Connect your account to AliExpress'
          }
          secondary="Click here and follow the process of connecting Modalyst with AliExpress"
        />
        <Divider variant="inset" />
        <SupplierAppConnectionStep
          eventLabel={EVENT_LABELS.installBrowserExtension(ALIEXPRESS)}
          to={CHROME_EXTENSION_URL}
          target="_blank"
          status={store.aliexpress.isChromeExtensionInstalled ? 'success' : 'default'}
          primary="Install Chrome Extension"
          secondary="Add products to Modalyst directly from AliExpress, then modify their details and sync them with your store."
        />
      </SupplierAppConnectionCard>
    </div>
  )
}

SupplierAppConnectionList.propTypes = {
  expandedCard: PropTypes.oneOf(['alibaba', 'aliexpress']),
  className: PropTypes.string,
}

SupplierAppConnectionList.defaultProps = {

}

export default observer(SupplierAppConnectionList)
