import React, { useEffect } from 'react'

import PropTypes from 'prop-types'

import { Button, IconButton, Menu, MenuItem } from '@material-ui/core'
import { ArrowDropDown, SortRounded } from '@material-ui/icons'
import { bindMenu, bindTrigger, usePopupState } from 'material-ui-popup-state/hooks'
import { observer } from 'mobx-react'

import style from './SortFilter.module.scss'

/**
 * Reusable controlled Sort Filter input, most notably used within the context of `<ListFiltersBar>` component.
 *
 * @param {string}    activeValue   The current value for the filter.
 * @param {function}  onChange      A function that handles changing the value of the filter.
 *                                  It should also run any side effects.
 * @param {Map}       values        A mapping, where the map-key is the value of the option,
 *                                  while map-value is the human readable label of the option.
 * @param {Boolean}   compact       A compact form, i.e. just a button with an icon with options in a pop up menu.
 * @returns {JSX.Element}
 * @constructor
 */
const SortFilter = ({ activeValue, onChange, values, compact }) => {
  const popupState = usePopupState({ variant: 'popover', popupId: 'sort' })

  useEffect(() => {
    if (compact) popupState.close()
  }, [compact])

  const button = compact
    ? (
      <IconButton
        color="default"
        className={style.SortFilter}
        {...bindTrigger(popupState)}
      >
        <SortRounded className={style.Icon} />
      </IconButton>
      )
    : (
      <Button
        className={style.SortFilter}
        startIcon={<ArrowDropDown className={style.Icon} />}
        color="default"
        variant="text"
        {...bindTrigger(popupState)}
      >
        {values.get(activeValue)}
      </Button>
      )

  return (
    <>
      {button}
      <Menu
        {...bindMenu(popupState)}
        variant="menu"
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        {Array.from(values.entries()).map(([key, value]) => {
          return (
            <MenuItem
              key={key}
              value={key}
              selected={key === activeValue}
              onClick={() => {
                onChange(key)
                popupState.close()
              }}
            >
              {value}
            </MenuItem>
          )
        })}
      </Menu>
    </>
  )
}

SortFilter.propTypes = {
  activeValue: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  values: PropTypes.instanceOf(Map).isRequired,
  compact: PropTypes.bool
}

SortFilter.defaultProps = {
  compact: false
}

export default observer(SortFilter)
