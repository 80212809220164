import React from 'react'

import PropTypes from 'prop-types'

import {
  Button,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Typography,
} from '@material-ui/core'
import { ShoppingBasketOutlined } from '@material-ui/icons'
import { observer } from 'mobx-react'

import { Money } from 'shared/components/atoms'
import { Alert } from 'shared/components/molecules'
import { DataModal } from 'shared/components/organisms'
import { ERROR_ACTION_INVALID } from 'shared/constants/orders'

import { OrderModalActionState } from 'retailer/stores'

import style from './OrderCancelModal.module.scss'

const OrderCancelModal = ({ store, ...rest }) => {
  return (
    <DataModal
      fullWidth
      className={style.OrderCancelModal}
      showSpinner={store.inProgress}
      title="Cancel order"
      hide={store.done}
      renderBody={() => (
        <div className={style.ModalContent}>
          <Typography variant="body1" gutterBottom>
            Do you want to cancel this order, which contains the following
            item(s):
          </Typography>
          <List
            dense
            className={style.Items}
          >
            {store.order.items && store.order.items.map(item => {
              const { uuid, itemName, options, itemCost } = item
              return (
                <ListItem key={uuid}>
                  <ListItemIcon>
                    <ShoppingBasketOutlined />
                  </ListItemIcon>
                  <ListItemText>
                    {store.order.brand}, <strong>{itemName}</strong>, {options.size} / {options.color}, <Money amount={itemCost} />
                  </ListItemText>
                </ListItem>
              )
            })}
          </List>
          {store.error
            ? (
              <Alert severity="error">
                {
                store.error === ERROR_ACTION_INVALID
                  ? 'Error. This order cannot be cancelled, as it has already been placed.'
                  : 'Error. Please try cancelling the order again.'
              }
              </Alert>
              )
            : null}
        </div>
      )}
      renderActions={({ dismissModal, showSpinner }) => (
        <>
          <Button
            onClick={dismissModal}
            disabled={showSpinner}
            color="primary"
            size="large"
          >
            No, I do not want
          </Button>
          <Button
            onClick={() => store.markCancelled()}
            disabled={showSpinner}
            color="primary"
            variant="contained"
            size="large"
          >
            Yes, cancel the order
          </Button>
        </>
      )}
      {...rest}
    />
  )
}

OrderCancelModal.propTypes = {
  store: PropTypes.instanceOf(OrderModalActionState),
}

export default observer(OrderCancelModal)
