import { useCallback, useState } from 'react'

import { pageLoaded } from '@wix/bi-logger-modalyst/v2'
import { useLocation } from 'react-router'
import { v4 as uuid4 } from 'uuid'

import { useStores } from 'shared/stores/hooks'

const createCorrelationId = () => uuid4()

/**
 * Use BI events in components.
 *
 * @param {Object} config
 * @param {Boolean} config.correlateWithLocation Try to get the correlationId from router's location
 */
export const useBiEvents = (config = {}) => {
  const { correlateWithLocation } = config

  const location = useLocation()
  const { biLoggerStore } = useStores()

  // get correlation id from router's location.state (available if a BiLink
  // was used to navigate to the current location).
  const { correlationId: locationCorrelationId } = location.state || {}

  const [correlationId, setCorrelationId] = useState(
    (correlateWithLocation && locationCorrelationId) ||
    createCorrelationId()
  )

  const resetCorrelationId = useCallback(() => setCorrelationId(createCorrelationId()), [])

  const logBiEvent = useCallback(
    async logObject => {
      return await biLoggerStore.log(logObject)
    }, [biLoggerStore]
  )

  const logPageLoaded = useCallback(
    async biData => {
      // use correlationId from hook, but allow for overwriting by biData
      const logObject = pageLoaded({ correlationId, ...biData })
      return await biLoggerStore.log(logObject)
    }, [biLoggerStore, locationCorrelationId]
  )

  return {
    createCorrelationId,
    locationCorrelationId,
    /**
     * A correlation ID created for the lifetime of this hook.
     * Use to correlate events fired between mounting and unmounting of the component.
     */
    correlationId,
    setCorrelationId,
    resetCorrelationId,
    logBiEvent,
    logPageLoaded,
  }
}
