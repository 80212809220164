import { action, computed, observable } from 'mobx'

import { flattenApiErrors } from 'retailer/utils/importList'

const defaultOnRemove = async (product) => console.info(product)
const defaultOnError = async (product, error) => console.error(product, error)

/**
 * A simple reusable ProductRemoveStore, which lets you select a product to remove,
 * as well as provide onRemove and onError methods.
 *
 * To use: simply `chooseProduct`, and then call `remove`.
 * The `onRemove` method will be called with the selected product.
 * If there is an error, the `onError` method will be called with the product, and the error instance.
 */
class ProductRemoveStore {
  @observable product = null
  @observable error = null
  @observable inProgress = false

  @observable onRemove = defaultOnRemove
  @observable onError = defaultOnError

  /**
   * @param {function(product: *, biEvent: Object): Promise<*>} onRemove
   * @param {function(product, error): Promise<*>} onError
   */
  constructor ({ onRemove, onError } = {}) {
    if (onRemove) this.onRemove = onRemove
    if (onError) this.onError = onError
  }

  @computed get isOpen () {
    return !!this.product
  }

  @action.bound
  chooseProduct (product) {
    this.product = product
  }

  @action.bound
  async remove () {
    this.inProgress = true
    this.error = null
    try {
      await this.onRemove(this.product)
      this.inProgress = false
      return true
    } catch (e) {
      switch (e.response.status) {
        case 500: {
          this.error = 'Server Error. Please, try again later or contact Customer Support.'
          break
        }
        default: {
          this.error = flattenApiErrors(e.response.data).join('. ')
          break
        }
      }
      await this.onError(this.product, e)
      this.inProgress = false
      return false
    }
  }

  @action.bound
  reset ({ onRemove = false, onError = false } = {}) {
    this.product = null
    this.error = null
    if (onRemove) this.onRemove = defaultOnRemove
    if (onError) this.onError = defaultOnError
  }
}

export default ProductRemoveStore
