import React, { useRef } from 'react'

import PropTypes from 'prop-types'

import { Alert } from '@material-ui/lab'
import { observer } from 'mobx-react'
import { useSnackbar } from 'notistack'
import { Controller, useForm } from 'react-hook-form'

import { CountrySelect } from 'shared/components/molecules'
import { CardForm } from 'shared/components/organisms'
import { getFieldErrorMessage, useRemoteErrors } from 'shared/utils/forms'

import { LocationSettingsStore } from 'retailer/stores'

import style from './StoreLocationForm.module.scss'

const StoreLocationForm = ({ store }) => {
  const ref = useRef(null)
  const { enqueueSnackbar } = useSnackbar()
  const { control, handleSubmit, formState, reset, errors, setError } = useForm()
  const { nonFieldErrors } = useRemoteErrors(store.errors, setError)
  const testid = 'storeLocationForm'

  const onSubmit = ({ location }) => {
    store.save(location).then(() => {
      reset()
      enqueueSnackbar('Store location saved successfully', {
        variant: 'success'
      })
    })
  }

  return (
    <CardForm
      title="Location"
      subheader="Select the country to display available products, prices, and shipping costs to that location"
      className={style.StoreLocationForm}
      formRef={ref}
      canSubmit={formState.isDirty && !Object.keys(errors).length}
      isSubmitting={store.isSaving}
      onDiscard={formState.isDirty ? () => reset() : null}
      testId={testid}
    >
      {nonFieldErrors && (
        <Alert severity="error" className={style.Errors}>
          {nonFieldErrors.join(' ')}
        </Alert>
      )}
      <form
        ref={ref}
        onSubmit={handleSubmit(onSubmit)}
      >
        <Controller
          required
          fullWidth
          variant="outlined"
          name="location"
          control={control}
          defaultValue={store.selectedCountryCode}
          label="Where are most of your customers located?"
          error={!!errors.location}
          helperText={getFieldErrorMessage(errors, 'location')}
          disabled={store.isSaving}
          testId={testid}
          as={<CountrySelect />}
        />
      </form>
    </CardForm>
  )
}

StoreLocationForm.propTypes = {
  store: PropTypes.instanceOf(LocationSettingsStore).isRequired,
}

export default observer(StoreLocationForm)
