import React, { useRef } from 'react'

import PropTypes from 'prop-types'

import { yupResolver } from '@hookform/resolvers/yup'
import { Grid } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { observer } from 'mobx-react'
import { useSnackbar } from 'notistack'
import { Controller, useForm } from 'react-hook-form'
import * as yup from 'yup'

import { RhfTextField } from 'shared/components/atoms'
import { CardForm } from 'shared/components/organisms'
import { useTranslation } from 'shared/hooks'
import { UserBasicInfoSettingsStore } from 'shared/stores'
import { getFieldErrorMessage, useRemoteErrors } from 'shared/utils/forms'

import { ProfileImageUpload } from 'retailer/components/organisms'

import style from './UserBasicInfoSettings.module.scss'

const formSchema = {
  contactEmail: { name: 'contactEmail', label: 'accountSettings.basicInfo.email.label', required: true },
}

const validationSchema = yup.object().shape({
  contactEmail: yup.string()
    .email()
    .max(254, 'E-mail address cannot be longer than 254 characters'),
  profileImage: yup.object().nullable(),
})

const UserBasicInfoSettings = ({ store }) => {
  const formRef = useRef(null)
  const { enqueueSnackbar } = useSnackbar()
  const { control, handleSubmit, formState, reset, errors, setValue, setError } = useForm({
    defaultValues: store.defaultValues,
    resolver: yupResolver(validationSchema)
  })
  const { nonFieldErrors } = useRemoteErrors(store.errors, setError)
  const { t } = useTranslation('settings')

  const onSubmit = async data => {
    await store.save(data, formState.dirtyFields)
    reset(store.defaultValues)
    enqueueSnackbar('Basic information saved successfully', {
      variant: 'success',
    })
  }

  const onImageClear = () => {
    setValue('profileImage', null)
    store.clearProfileImageError()
  }

  const translate = (fieldSchema) => {
    return { ...fieldSchema, label: t(fieldSchema.label) }
  }

  return (
    <CardForm
      title={t('accountSettings.basicInfo.title')}
      className={style.UserBasicInfoSettings}
      formRef={formRef}
      canSubmit={formState.isDirty && !Object.keys(errors).length}
      isSubmitting={store.isSaving}
      onDiscard={formState.isDirty ? () => reset(store.defaultValues) : null}
      testId="UserBasicInfoSettings"
    >
      {nonFieldErrors && (
        <Alert severity="error" className={style.Errors}>
          {nonFieldErrors.join(' ')}
        </Alert>
      )}
      {store.errors.profileImageError && (
        <Alert severity="error" className={style.Errors}>
          {store.errors.profileImageError}
        </Alert>
      )}
      <form ref={formRef} onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={0}>
          <Grid container item spacing={3} xs={12} md={8}>
            <Grid item xs={12}>
              <RhfTextField
                control={control}
                {...translate(formSchema.contactEmail)}
                error={getFieldErrorMessage(errors, 'contactEmail')}
                id="contactEmail"
                testId="UserBasicInfoSettings-emailAddress"
              />
            </Grid>
          </Grid>
          <Grid container item xs={12} md={4}>
            <Controller
              control={control}
              id="profileImage"
              name="profileImage"
              defaultValue={store.profileImage}
              render={({ value, onChange }) => (
                <ProfileImageUpload
                  file={value}
                  onChange={file => onChange(file)}
                  onClear={onImageClear}
                  onError={(error) => { store.errors.profileImageError = error }}
                  error={errors.profileImage}
                  dataURLKey="dataUrl"
                  maxFileSize="800000"
                  acceptType={['jpg', 'jpeg', 'gif', 'png']}
                  className={style.profileImageController}
                />
              )}
            />
          </Grid>
        </Grid>
      </form>
    </CardForm>
  )
}

UserBasicInfoSettings.propTypes = {
  store: PropTypes.instanceOf(UserBasicInfoSettingsStore).isRequired
}

export default observer(UserBasicInfoSettings)
