import React from 'react'

import ReactDOM from 'react-dom'

/**
 * Renders a React app into the provided root element.
 * @param Component
 * @param rootId
 */
const render = (Component, rootId = 'root') => {
  const root = document.getElementById(rootId)
  ReactDOM.render(<Component {...root.dataset} />, root)
}

export {
  render
}
