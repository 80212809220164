import React, { forwardRef, useState } from 'react'

import PropTypes from 'prop-types'

import { Box, Button, IconButton, ListItemSecondaryAction, ListItemText, Menu, MenuItem } from '@material-ui/core'
import { MoreHoriz } from '@material-ui/icons'
import { observer } from 'mobx-react'
import { Link as RouterLink, useRouteMatch } from 'react-router-dom'

import { TooltipHelper } from 'shared/components/molecules'
import { useTranslation } from 'shared/hooks'

import { AddProductAction, AddProductActionButton, DesignProductAction, SupplierProductAdminMenu } from 'retailer/components/molecules'
import { useStores } from 'retailer/stores/hooks'

import style from './MarketplaceItemActions.module.scss'

const LinkMenuItem = forwardRef((props, ref) => (
  <MenuItem component={RouterLink} ref={ref} button {...props} />
))

/**
 * Prop injector for the 'order sample action'
 * (which can be triggered both by a Button and a MenuItem)
 * */
const OrderSampleAction = observer(({
  component: Component, item, onClick: onClickProp, testId, ...rest
}) => {
  const onClick = ev => {
    ev.preventDefault()
    item.orderSample()
    onClickProp && onClickProp()
  }
  const injectedProps = { onClick, disabled: !item.canOrderSample, ...rest }
  return <Component {...injectedProps} data-testid={testId} />
})

OrderSampleAction.propTypes = {
  component: PropTypes.elementType.isRequired,
  item: PropTypes.shape({
    canOrderSample: PropTypes.bool.isRequired,
    orderSample: PropTypes.func.isRequired,
  }),
  onClick: PropTypes.func,
  testId: PropTypes.string,
}

/** MenuItem that shows AddProductAction's `title` as a tooltip */
const AddProductMenuItem = forwardRef(({ children, title, ...rest }, ref) => (
  <LinkMenuItem ref={ref} {...rest}>
    <ListItemText>{children}</ListItemText>
    <ListItemSecondaryAction>
      <TooltipHelper content={title} />
    </ListItemSecondaryAction>
  </LinkMenuItem>
))

AddProductMenuItem.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
}

/** Dropdown menu with actions and a trigger for the same */
const MoreActions = observer(({ item, t }) => {
  const [moreMenuAnchorEl, setMoreMenuAnchorEl] = useState(null)
  const closeMoreMenu = () => setMoreMenuAnchorEl(null)
  return (
    <>
      <IconButton color="primary" onClick={ev => setMoreMenuAnchorEl(ev.currentTarget)}>
        <MoreHoriz />
      </IconButton>
      <Menu
        anchorEl={moreMenuAnchorEl} open={!!moreMenuAnchorEl} keepMounted
        onClose={closeMoreMenu}
      >
        <AddProductAction
          component={AddProductMenuItem}
          item={item} onClick={closeMoreMenu}
        />
        <OrderSampleAction
          component={MenuItem} button
          item={item}
          onClick={closeMoreMenu}
        >
          {t('productActions.orderPODBlankSample.menuItem')}
        </OrderSampleAction>
      </Menu>
    </>
  )
})

MoreActions.propTypes = {
  item: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
}

const MarketplaceItemActions = ({ item, disableElevation, testId }) => {
  const { t } = useTranslation('productPage')
  const { url } = useRouteMatch()
  const { userProfileStore } = useStores()

  const commonButtonProps = { disableElevation, color: 'primary', size: 'large' }

  const primaryAction = item.isCustomizable
    ? (
      <DesignProductAction
        data-testid={testId ? `${testId}-designProductActionButton` : undefined}
        productStore={item} variant="contained" {...commonButtonProps}
      >
        {t('podProduct.designProduct.button')}
      </DesignProductAction>
      )
    : (
      <AddProductAction
        item={item}
        testId={testId ? `${testId}-addProductActionButton` : undefined}
        component={AddProductActionButton} {...commonButtonProps}
      />
      )

  const secondaryAction = item.isCustomizable
    ? item.draftCount > 0 && (
      <Button
        component={RouterLink} to={`${url}/drafts`}
        data-testid={testId ? `${testId}-viewDraftsActionButton` : undefined}
        variant="outlined" {...commonButtonProps}
      >
        {t('productActions.viewPODDrafts.button')}
      </Button>
    )
    : (
      <OrderSampleAction
        item={item}
        testId={testId ? `${testId}-orderSampleActionButton` : undefined}
        component={Button} variant="outlined" {...commonButtonProps}
      >
        {t('productActions.orderSample.button')}
      </OrderSampleAction>
      )

  return (
    <Box className={style.MarketplaceItemActions}>
      {primaryAction && <span>{primaryAction}</span>}
      {secondaryAction && <span>{secondaryAction}</span>}
      {/* TODO: do not display the menu until the discussions re POD "blanks" are resolved
      {item.isCustomizable && (
        <Grid item><MoreActions item={item} t={t} /></Grid>
      )}
      */}
      {userProfileStore.isStaff && (
        <SupplierProductAdminMenu
          productStore={item}
          className={style.SupplierProductAdminMenu}
        />
      )}
    </Box>
  )
}

MarketplaceItemActions.propTypes = {
  item: PropTypes.shape({
    draftCount: PropTypes.number.isRequired,
    isCustomizable: PropTypes.bool.isRequired,
  }).isRequired,
  disableElevation: PropTypes.bool,
  testId: PropTypes.string,
}

export default observer(MarketplaceItemActions)
