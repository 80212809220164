import React, { useEffect } from 'react'

import PropTypes from 'prop-types'

import { Box } from '@material-ui/core'
import { AutorenewRounded, CardMembershipRounded } from '@material-ui/icons'
import { observer } from 'mobx-react'

import { Time } from 'shared/components/atoms'

import { UniversalNotification } from 'retailer/components/organisms'
import { useStores } from 'retailer/stores/hooks'

import style from './UniversalNotifications.module.scss'

/**
 * A organism to completely control the universal notifications mechanism. It not only displays
 * all the notifications, but also handles setting up a refresh interval etc.
 *
 * Generally, you should only have one instance of this component at any given time. We usually put it
 * at the top of the page.
 */
const UniversalNotifications = ({ children, ...boxProps }) => {
  const { universalNotificationsStore } = useStores()

  useEffect(() => {
    universalNotificationsStore.fetch()
    const interval = setInterval(() => {
      universalNotificationsStore.refresh()
    }, 5000)
    return () => {
      clearInterval(interval)
    }
  }, [])

  if (
    !children &&
    (
      !universalNotificationsStore.loading.isLoaded ||
      !universalNotificationsStore.anyNotificationsActive
    )
  ) {
    return null
  }

  const { alibabaExpiration, aliexpressExpiration, limitReached } = universalNotificationsStore

  return (
    <Box className={style.UniversalNotifications} {...boxProps}>
      {children}
      <UniversalNotification
        visible={alibabaExpiration.active}
        severity={alibabaExpiration.isExpired ? 'error' : 'warning'}
        content={alibabaExpiration.isExpired
          ? (
            <>
              Your Alibaba connection has expired <Time value={alibabaExpiration.expirationDate} relative /> ago.
            </>
            )
          : (
            <>
              Your Alibaba connection expires in <Time value={alibabaExpiration.expirationDate} relative />.
            </>
            )}
        action={{
          icon: <AutorenewRounded />,
          text: 'Renew the connection',
          to: '/connect-suppliers'
        }}
      />
      <UniversalNotification
        visible={aliexpressExpiration.active}
        severity={aliexpressExpiration.isExpired ? 'error' : 'warning'}
        content={aliexpressExpiration.isExpired
          ? (
            <>
              Your AliExpress connection has expired <Time value={aliexpressExpiration.expirationDate} relative /> ago.
            </>
            )
          : (
            <>
              Your AliExpress connection expires in <Time value={aliexpressExpiration.expirationDate} relative />.
            </>
            )}
        action={{
          icon: <AutorenewRounded />,
          text: 'Renew the connection',
          to: '/connect-suppliers'
        }}
      />
      <UniversalNotification
        visible={limitReached.active}
        severity="warning"
        content={
          `You have hit your plan's limit with a total of ${limitReached.limit} products
          in Import & Sync Lists. Upgrade to add more products.`
        }
        action={{
          icon: <CardMembershipRounded />,
          text: 'Upgrade now',
          to: '/settings/manage-store-plan'
        }}
      />
    </Box>
  )
}

UniversalNotifications.propTypes = {
  children: PropTypes.node
}

export default observer(UniversalNotifications)
