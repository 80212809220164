import React, { useEffect, useState } from 'react'

import PropTypes from 'prop-types'

import { IconButton, List, ListItem, ListItemSecondaryAction, ListItemText, Popover, Switch } from '@material-ui/core'
import { Settings } from '@material-ui/icons'
import { bindPopover, bindTrigger, usePopupState } from 'material-ui-popup-state/hooks'
import { observer } from 'mobx-react'

const SupplierProductAdminMenu = ({ productStore, className }) => {
  const popupState = usePopupState({ variant: 'popover', popupId: 'supplierProductAdminMenu' })
  return (
    <div className={className}>
      <IconButton {...bindTrigger(popupState)}><Settings /></IconButton>
      <Popover
        {...bindPopover(popupState)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{ style: { width: 240 } }}
      >
        <List>
          <ActionListItem label="Bestseller" flag="bestseller" productStore={productStore} />
          <ActionListItem label="Category pick" flag="category_pick" productStore={productStore} />
        </List>
      </Popover>
    </div>
  )
}

SupplierProductAdminMenu.propTypes = {
  productStore: PropTypes.object.isRequired,
  className: PropTypes.string,
}

const ActionListItem = observer(({ label, flag, productStore }) => {
  const { staffPicks, setStaffPick } = productStore
  const [checked, setChecked] = useState(staffPicks.includes(flag))
  useEffect(() => setChecked(staffPicks.includes(flag)), [staffPicks])
  const handleClick = async () => {
    const current = staffPicks.includes(flag)
    setChecked(!current)
    try {
      await setStaffPick(flag, !current)
    } catch (error) {
      setChecked(current)
    }
  }
  return (
    <ListItem>
      <ListItemText>{label}</ListItemText>
      <ListItemSecondaryAction>
        <Switch edge="end" checked={checked} onClick={handleClick} />
      </ListItemSecondaryAction>
    </ListItem>
  )
})

ActionListItem.propTypes = {
  label: PropTypes.string.isRequired,
  flag: PropTypes.string.isRequired,
  productStore: PropTypes.shape({
    staffPicks: PropTypes.arrayOf(PropTypes.string),
    setStaffPick: PropTypes.func.isRequired,
  }).isRequired,
}

export default observer(SupplierProductAdminMenu)
