import React from 'react'

import PropTypes from 'prop-types'

import {
  Checkbox,
  Grid,
  IconButton,
  Link,
  MenuItem,
  Switch,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
  useTheme,
  useMediaQuery,
  FormControlLabel,
} from '@material-ui/core'
import { WarningRounded } from '@material-ui/icons'
import clsx from 'clsx'
import isEmpty from 'lodash/isEmpty'
import { observer } from 'mobx-react'
import { useSnackbar } from 'notistack'
import { Link as RouterLink } from 'react-router-dom'

import { ModalystLogo } from 'shared/components/atoms'
import { TextTruncate, TooltipHelper } from 'shared/components/molecules'
import { STYLE_COMMERCE } from 'shared/constants/integrations'
import { RequireFeature } from 'shared/features'
import { useTranslation } from 'shared/hooks'
import { imageType, pricingRangeType, syncStatusBadgeType } from 'shared/types'
import { downloadFromUrl } from 'shared/utils/download'
import { getIntegrationIcon, getIntegrationName } from 'shared/utils/integrations'
import { getMarketplaceItemUrl } from 'shared/utils/urls'

import { SyncListItemStatusBadge, Image, PricingRangeDisplay } from 'retailer/components/molecules'
import { MoreButtonWithMenu } from 'retailer/components/organisms'
import { FEATURE_EDIT_KORNIT_PROJECT_ON_SYNC_LIST } from 'retailer/constants'

import style from './SyncListItem.module.scss'

const ActionButtons = observer(({
  retailerProduct,
  onOrderSample,
  onMoveToImportList,
  onRemoveFromStore,
  onViewDetailsClicked,
  onCsvExport,
}) => {
  const { t } = useTranslation('syncedProducts')
  const { enqueueSnackbar } = useSnackbar()
  const {
    uuid,
    itemUuid,
    name,
    source,
    storeAdminEditUrl,
    thirdPartySupplierUrl,
    isPrintOnDemand,
    exportInProgress,
    csvExportInProgress,
  } = retailerProduct

  const integrationName = getIntegrationName(source) || source

  const handleCsvExport = () => {
    onCsvExport()
      .then(({ url, fileName }) => {
        downloadFromUrl(url, fileName)
        enqueueSnackbar(
          t('main.action.exportedCSV.toast'),
          { variant: 'success' }
        )
      })
      .catch(() => {
        enqueueSnackbar(
          `${name} could not be exported to CSV file`,
          { variant: 'error' }
        )
      })
  }

  const handleMoveToImportList = () => {
    onMoveToImportList(uuid)
      .then(() => {
        enqueueSnackbar(
          t('main.action.moveToImport.toast', { productsCount: 1 }),
          { variant: 'success' }
        )
      })
      .catch(() => {
        enqueueSnackbar(
          `${name} could not be moved to the Import List`,
          { variant: 'error' }
        )
      })
  }

  const handleRemoveFromStore = () => {
    onRemoveFromStore(uuid)
  }

  const handleCustomize = () => {
    retailerProduct.editDesign()
  }

  return (
    <MoreButtonWithMenu menuId={`${uuid}-moreMenu`} testId="syncList-syncListProduct-moreMenu">
      <RequireFeature name={FEATURE_EDIT_KORNIT_PROJECT_ON_SYNC_LIST}>
        <MenuItem
          disabled={exportInProgress} onClick={handleCustomize}
        >
          Customize
        </MenuItem>
      </RequireFeature>
      {onOrderSample && (
        <MenuItem
          disabled={exportInProgress} onClick={onOrderSample}
          data-testid="syncList-syncListProduct-moreMenu-orderASample"
        >
          {t('table.orderSample.cta')}
        </MenuItem>
      )}
      {storeAdminEditUrl && (
        <MenuItem
          disabled={exportInProgress}
          component={Link}
          href={storeAdminEditUrl}
          target="_blank"
          rel="noopener noreferrer"
          data-testid="syncList-syncListProduct-moreMenu-viewInStore"
        >
          View in store
        </MenuItem>
      )}
      {thirdPartySupplierUrl && (
        <MenuItem
          disabled={exportInProgress}
          component={Link}
          href={thirdPartySupplierUrl}
          target="_blank"
          rel="noopener noreferrer"
          data-testid="syncList-syncListProduct-moreMenu-viewInAliexpress"
        >
          {t('table.viewAliexpress.cta', { externalSupplierName: integrationName })}
        </MenuItem>
      )}
      {isPrintOnDemand && (
        <MenuItem
          disabled={exportInProgress} onClick={handleMoveToImportList}
          data-testid="syncList-syncListProduct-moreMenu-customize"
        >
          {t('table.customizeProduct.cta')}
        </MenuItem>
      )}
      {!isPrintOnDemand && (
        <MenuItem
          disabled={exportInProgress} onClick={handleMoveToImportList}
          data-testid="syncList-syncListProduct-moreMenu-moveToImportList"
        >
          {t('table.edit.cta')}
        </MenuItem>
      )}
      <MenuItem
        disabled={exportInProgress || csvExportInProgress} onClick={handleCsvExport}
        data-testid="syncList-syncListProduct-moreMenu-exportToCsv"
      >
        {t('table.actions.export.cta')}
      </MenuItem>
      <MenuItem
        component={RouterLink}
        to={getMarketplaceItemUrl(itemUuid, isPrintOnDemand)}
        target="_blank"
        rel="opener"
        onClick={onViewDetailsClicked}
        data-testid="syncList-syncListProduct-moreMenu-viewDetails"
      >
        {t('table.viewDetails.cta')}
      </MenuItem>
      <MenuItem
        className={style.error} onClick={handleRemoveFromStore}
        data-testid="syncList-syncListProduct-moreMenu-remove"
      >
        {t('table.remove.cta')}
      </MenuItem>
    </MoreButtonWithMenu>
  )
})

ActionButtons.propTypes = {
  retailerProduct: PropTypes.shape({
    uuid: PropTypes.string.isRequired,
    itemUuid: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    source: PropTypes.string.isRequired,
    storeAdminEditUrl: PropTypes.string,
    thirdPartySupplierUrl: PropTypes.string,
    isPrintOnDemand: PropTypes.bool,
    exportInProgress: PropTypes.bool,
    csvExportInProgress: PropTypes.bool,
    editDesign: PropTypes.func,
  }).isRequired,
  onMoveToImportList: PropTypes.func.isRequired,
  onRemoveFromStore: PropTypes.func.isRequired,
  onOrderSample: PropTypes.func,
  onViewDetailsClicked: PropTypes.func,
  onCsvExport: PropTypes.func.isRequired,
}

const SyncListItem = ({ syncListItemStore, className }) => {
  const { t } = useTranslation('syncedProducts')
  const { enqueueSnackbar } = useSnackbar()
  const theme = useTheme()
  const desktopLayoutBreakpoint = useMediaQuery(theme.breakpoints.up('xl'))

  const {
    uuid,
    name,
    source,
    vendorDisplay,
    is3rdPartySupplier,
    productFromSourceUrl,
    exportStatus,
    syncStatusBadge,
    hidden,
    quantity,
    isPrintOnDemand,
    activeVariantsCount,
    pricingRanges,
    currencyIso,
    priceLockedChangeInProgress,
    priceLocked,
    togglePriceLocked,
    selected,
    setSelected,
    mainImage,
    exportInProgress,
    moveToImportList,
    removeFromStore,
    logViewDetailsClickedBiEvent,
    resync,
    exportToCsv,
    orderSample,
  } = syncListItemStore

  const handleOrderSample = !hidden ? orderSample : undefined

  const handleViewDetailsClicked = () => logViewDetailsClickedBiEvent()

  const SourceIcon = getIntegrationIcon(source) || ModalystLogo
  // const integrationName = getIntegrationName(source) || source

  const productData = (
    <>
      <Tooltip title={name} placement="top">
        <Typography
          variant="body2"
          data-testid="syncList-syncListProduct-productData-productName"
          className={clsx(style.ProductName, style.Prominent)}
        >
          <TextTruncate maxWords={7} text={name} />
        </Typography>
      </Tooltip>
      <Typography variant="body2" data-testid="syncList-syncListProduct-productData-vendorName">
        {isPrintOnDemand ? t('table.supplierNamePod.label') : vendorDisplay}
      </Typography>
      {is3rdPartySupplier && (
        <IconButton
          href={productFromSourceUrl}
          data-testid="syncList-syncListProduct-productData-3rdPartySupplierButton"
          color="primary"
          size="small"
          edge="start"
          rel="noopener noreferrer"
          target="_blank"
        >
          <SourceIcon width={14} height={14} />
        </IconButton>
      )}
      <SyncListItemStatusBadge
        testId="syncList-syncListProduct-productData-itemStatusBadge"
        status={exportStatus}
        description={syncStatusBadge.explanation}
        onRetry={syncStatusBadge?.isResyncAllowed ? () => resync(uuid) : null}
        className={style.Status}
      />
    </>
  )

  const variantsData = (
    <>
      <Typography
        variant="body2" className={clsx(style.Prominent, hidden && style.error)}
        data-testid="syncList-syncListProduct-variantData-quantity"
      >
        {hidden ? 'Out of stock' : ''}
        {isPrintOnDemand && !hidden ? 'In stock' : ''}
        {!isPrintOnDemand && !hidden ? t('table.inventory.stock.label', { numberOfProductsInStock: quantity }) : ''}
      </Typography>
      <Typography variant="body2" data-testid="syncList-syncListProduct-variantData-activeVariantsCount">
        {t('table.inventory.variants.label', { numberOfAvailableVariants: activeVariantsCount })}
      </Typography>
    </>
  )

  const pricesData = (
    <>
      {!isEmpty(pricingRanges) && (
        <>
          <PricingRangeDisplay
            testId="syncList-syncListProduct-pricesData-price"
            pricing={pricingRanges.price}
            pricingNameOverride={t('table.pricing.storePrice.label')}
            currencyIso={currencyIso}
            className={style.Prominent}
          />
          <PricingRangeDisplay
            testId="syncList-syncListProduct-pricesData-discountedPrice"
            pricing={pricingRanges.discountedPrice}
            pricingNameOverride={t('table.pricing.discountedPrice.label')}
            currencyIso={currencyIso}
          />
          <PricingRangeDisplay
            testId="syncList-syncListProduct-pricesData-cost"
            pricing={pricingRanges.cost}
            pricingNameOverride={t('table.pricing.productCost.label')}
            currencyIso={currencyIso}
          />
          <div className={clsx(style.ProfitBlock, style.Price)}>
            <PricingRangeDisplay
              testId="syncList-syncListProduct-pricesData-profit"
              pricingNameOverride={t('table.pricing.profit.label')}
              pricing={pricingRanges.profit}
              currencyIso={currencyIso}
              className={clsx(pricingRanges.profit.from <= 0 && style.negative)}
            />{' '}
            {pricingRanges.profit.from <= 0 && (
              <TooltipHelper
                content={t('table.pricing.profit.error')}
                className={style.negative}
                IconComponent={WarningRounded}
              />
            )}
          </div>
        </>
      )}
    </>
  )

  const dynamicPriceToggle = (
    <Switch
      data-testid="syncList-syncListProduct-dynamicPriceToggle"
      color="primary"
      checked={priceLockedChangeInProgress ? priceLocked : !priceLocked}
      onChange={() => {
        togglePriceLocked()
          .then(() => {
            enqueueSnackbar(
              `Toggled auto-price update for ${name}`,
              { variant: 'info' }
            )
          })
          .catch(() => {
            enqueueSnackbar(
              `Could not toggle auto-price update for ${name}`,
              { variant: 'error' }
            )
          })
      }}
      disabled={exportInProgress || priceLockedChangeInProgress}
    />
  )

  const mobileLayout = (
    <TableCell className={style.ProductCol}>
      <Grid container spacing={1}>
        <Grid item>
          <Checkbox
            color="primary"
            checked={selected}
            onChange={() => setSelected(!selected)}
            className={style.Checkbox}
          />
        </Grid>
        <Grid item style={{ flexGrow: 1 }}>
          <Grid container spacing={1} style={{ flexWrap: 'nowrap' }}>
            <Grid item>
              <Image
                image={mainImage}
                imgProps={{
                  className: source === STYLE_COMMERCE ? style.style_commerce : ''
                }}
                inProgress={exportInProgress}
                testId="syncList-syncListProduct-productImage"
              />
            </Grid>
            <Grid item style={{ flexGrow: 1 }}>
              {productData}
            </Grid>
            <Grid item>
              <ActionButtons
                retailerProduct={syncListItemStore}
                onOrderSample={handleOrderSample}
                onMoveToImportList={moveToImportList}
                onRemoveFromStore={removeFromStore}
                onViewDetailsClicked={handleViewDetailsClicked}
                onCsvExport={exportToCsv}
              />
            </Grid>
          </Grid>
          <Grid container spacing={1}>
            <Grid item xs={12} lg={6} className={style.Section}>
              {variantsData}
            </Grid>
            <Grid item xs={12} lg={6} className={style.Section}>
              {pricesData}
            </Grid>
            <Grid item xs={12} className={style.Section}>
              <FormControlLabel
                control={dynamicPriceToggle}
                label="Dynamic prices"
              />
              <TooltipHelper content={t('table.autoPrice.tooltip')} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </TableCell>
  )

  const desktopLayout = (
    <>
      <TableCell className={style.ProductCol}>
        <div>
          <Grid container spacing={1} className={style.ProductGrid}>
            <Grid item style={{ alignSelf: 'center' }}>
              <Checkbox
                color="primary"
                checked={selected}
                onChange={() => setSelected(!selected)}
                className={style.Checkbox}
              />
            </Grid>
            <Grid item>
              <Image
                image={mainImage}
                imgProps={{
                  className: source === STYLE_COMMERCE ? style.style_commerce : ''
                }}
                inProgress={exportInProgress}
                testId="syncList-syncListProduct-productImage"
              />
            </Grid>
            <Grid item className={style.ProductInfo}>
              {productData}
            </Grid>
          </Grid>
        </div>
      </TableCell>
      <TableCell>
        {variantsData}
      </TableCell>
      <TableCell className={style.PricingCell}>
        {pricesData}
      </TableCell>
      <TableCell className={style.ActionsColumn}>
        <Grid container spacing={desktopLayoutBreakpoint ? 3 : 1} className={style.Actions}>
          <Grid item>
            {dynamicPriceToggle}
          </Grid>
          <Grid item>
            <ActionButtons
              retailerProduct={syncListItemStore}
              onOrderSample={handleOrderSample}
              onMoveToImportList={moveToImportList}
              onRemoveFromStore={removeFromStore}
              onViewDetailsClicked={handleViewDetailsClicked}
              onCsvExport={exportToCsv}
            />
          </Grid>
        </Grid>
      </TableCell>
    </>
  )

  return (
    <TableRow
      className={clsx(style.SyncListItem, exportInProgress && style.exportInProgress, className)}
      data-testid="syncList-syncListProduct"
    >
      {desktopLayoutBreakpoint ? desktopLayout : mobileLayout}
    </TableRow>
  )
}

SyncListItem.propTypes = {
  syncListItemStore: PropTypes.shape({
    uuid: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    source: PropTypes.string.isRequired,
    vendorDisplay: PropTypes.string,
    is3rdPartySupplier: PropTypes.bool,
    productFromSourceUrl: PropTypes.string,
    exportStatus: PropTypes.string,
    syncStatusBadge: syncStatusBadgeType.isRequired,
    quantity: PropTypes.number.isRequired,
    hidden: PropTypes.bool,
    isPrintOnDemand: PropTypes.bool,
    activeVariantsCount: PropTypes.number,
    pricingRanges: PropTypes.shape({
      price: pricingRangeType.isRequired,
      discountedPrice: pricingRangeType.isRequired,
      cost: pricingRangeType.isRequired,
      profit: pricingRangeType.isRequired
    }).isRequired,
    currencyIso: PropTypes.string.isRequired,
    priceLockedChangeInProgress: PropTypes.bool,
    priceLocked: PropTypes.bool,
    togglePriceLocked: PropTypes.func,
    orderSample: PropTypes.func.isRequired,
    selected: PropTypes.bool,
    setSelected: PropTypes.func,
    mainImage: imageType,
    exportInProgress: PropTypes.bool,
    moveToImportList: PropTypes.func.isRequired,
    removeFromStore: PropTypes.func.isRequired,
    logViewDetailsClickedBiEvent: PropTypes.func.isRequired,
    resync: PropTypes.func,
    exportToCsv: PropTypes.func.isRequired,
  }).isRequired,
  className: PropTypes.string
}

SyncListItem.defaultProps = {
  className: ''
}

export default observer(SyncListItem)
