import React from 'react'

// eslint-disable-next-line camelcase
import { alpha, unstable_createMuiStrictModeTheme } from '@material-ui/core/styles'
import { Clear } from '@material-ui/icons'

import baseTheme from 'shared/theme/baseTheme'

import { overrides } from './common'

const palette = {
  primary0: '#F7F7FD',
  primary5: '#EBEBFA',
  primary10: '#D6D6F5',
  primary15: '#B1B1E7',
  primary20: '#7777D4',
  primary30: '#6464CE',
  primary40: '#4B4AC6',
  primary50: '#3535A7',
  primary60: '#31319B',
  primary70: '#252574',
  primary80: '#191966',
  primary90: '#18184E',
  primary100: '#0C0C27',

  secondary0: '#F6F7FE',
  secondary5: '#E8ECFD',
  secondary10: '#A1B4F7',
  secondary15: '#728EF3',
  secondary20: '#5B7BF1',
  secondary30: '#4368EF',
  secondary40: '#2C56ED',
  secondary50: '#1443EB',
  secondary60: '#1427B8',
  secondary70: '#0F1E8A',
  secondary80: '#0A145C',
  secondary90: '#080F45',
  secondary100: '#050A2E',

  tertiary0: '#FAF6FD',
  tertiary5: '#F3E9FB',
  tertiary10: '#DBBEF3',
  tertiary15: '#C294EB',
  tertiary20: '#AA69E2',
  tertiary30: '#923FDB',
  tertiary40: '#7F49D0',
  tertiary50: '#6931BE',
  tertiary60: '#5A2AA2',
  tertiary70: '#381A65',
  tertiary80: '#2D1551',
  tertiary90: '#22103D',
  tertiary100: '#180B28',

  grey0: '#F8FAFC',
  grey5: '#EFF2F7',
  grey10: '#BECBDA',
  grey15: '#91A6C0',
  grey20: '#647FA0',
  grey30: '#3E5878',
  grey40: '#324862',
  grey50: '#263950',
  grey60: '#1F2F42',
  grey70: '#0D213A',
  grey80: '#152138',
  grey90: '#09182A',
  grey100: '#02080E',

  error1: '#FBE5E5',
  error2: '#F5C2C2',
  error3: '#EC8D8E',
  error4: '#E45859',
  error5: '#DB2425',
  error6: '#C11F20',
  error7: '#A71B1C',
  error8: '#8C1718',
  error9: '#721313',

  warning1: '#FEEFC8',
  warning2: '#FBE19D',
  warning3: '#F7D069',
  warning4: '#F4BD2F',
  warning5: '#EDAC02',
  warning6: '#BB8802',
  warning7: '#886301',
  warning8: '#604601',
  warning9: '#382801',

  success1: '#E4F7D4',
  success2: '#C9EEB0',
  success3: '#9BD26A',
  success4: '#74AF41',
  success5: '#408305',
  success6: '#356D03',
  success7: '#295502',
  success8: '#1D3C01',
  success9: '#112301',
}

const muiTheme = unstable_createMuiStrictModeTheme({
  themeName: 'New Retailer Theme',
  direction: 'ltr', // RTL option,
  breakpoints: baseTheme.breakpoints,
  palette: {
    primary: {
      light: palette.primary30,
      main: palette.primary50,
      dark: palette.primary80,
      contrastText: palette.primary5
    },
    secondary: {
      light: palette.tertiary30,
      main: palette.tertiary50,
      dark: palette.tertiary80,
      contrastText: palette.tertiary5
    },
    success: {
      light: palette.success3,
      main: palette.success5,
      dark: palette.success8,
      contrastText: palette.success1
    },
    warning: {
      light: palette.warning3,
      main: palette.warning5,
      dark: palette.warning8,
      contrastText: palette.warning1
    },
    error: {
      light: palette.error3,
      main: palette.error5,
      dark: palette.error8,
      contrastText: palette.error1
    }
  },
  typography: {
    fontFamily: 'Inter, sans-serif',
    pageHeader: {
      fontSize: '28px',
      fontWeight: 700,
      lineHeight: '36px'
    },
    sectionHeader: {
      fontSize: '20px',
      fontWeight: 600,
      lineHeight: '26px'
    },
    h1: {
      fontSize: '96px',
      fontWeight: 700,
      letterSpacing: '-1px'
    },
    h2: {
      fontSize: '60px',
      fontWeight: 700,
    },
    h3: {
      fontSize: '48px',
      fontWeight: 400,
    },
    h4: {
      fontSize: '26px',
      fontWeight: 700,
    },
    h5: {
      // TESTME:
      fontSize: '20px',
      fontWeight: 700
    },
    h6: {
      // TESTME:
      fontSize: '16px',
      fontWeight: 700
    },
    subtitle1: {
      fontSize: '16px'
    },
    subtitle2: {
      fontSize: '14px',
      fontWeight: 500
    },
    body1: {
      fontSize: '16px',
    },
    body2: {
      fontSize: '14px',
    },
    button: {
      textTransform: 'none'
    },
    caption: {
      fontSize: '12px',
      fontWeight: 500
    },
    overline: {
      fontSize: '12px',
      fontWeight: 500,
      textTransform: 'uppercase'
    }
  },
  props: {
    MuiCheckbox: {
      color: 'primary',
    },
    MuiTooltip: {
      arrow: true,
    },
    MuiButton: {
      disableElevation: true,
    },
    MuiChip: {
      deleteIcon: <Clear />,
    },
  },
  overrides: {
    ...overrides(baseTheme),
    MuiSkeleton: {
      root: {
        borderRadius: '4px',
        backgroundColor: '#d9dbe3',
      },
    },
    MuiCardActions: {
      root: {
        justifyContent: 'flex-end',
        padding: 16,
      },
    },
    MuiDialog: {
      paper: {
        margin: '16px',
      }
    },
    MuiDialogTitle: {
      root: {
        color: palette.grey100,
        borderBottom: `1px solid ${palette.grey5}`,
        '& .MuiDialogTitle-title': {
          margin: 0,
          fontSize: 20,
          fontWeight: 700,
        }
      }
    },
    MuiDialogContent: {
      root: {
        padding: '16px 24px',
      },
    },
    MuiDialogActions: {
      root: {
        padding: '16px 24px',
        borderTop: `1px solid ${palette.grey5}`
      },
    },
    MuiFormLabel: {
      asterisk: {
        color: palette.error5,
      },
    },
    MuiOutlinedInput: {
      root: {
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: palette.primary15,
        },
        '&:hover': {
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: palette.primary30,
          }
        }
      },
    },
    MuiBackdrop: {
      root: {
        backgroundColor: 'rgba(22, 45, 61, 0.66)'
      }
    },
    MuiCheckbox: {
      root: {
        color: palette.primary30,
      }
    },
    MuiMenuItem: {
      root: {
        '&:hover': {
          background: palette.primary5,
        },
        '&.Mui-selected': {
          background: palette.primary10,
          '&:hover': {
            background: palette.primary15
          }
        }
      }
    },
    MuiListItem: {
      button: {
        '&:hover': {
          backgroundColor: alpha(palette.secondary60, 0.08),
        },
      }
    },
    MuiTooltip: {
      tooltip: {
        fontSize: '12px',
        fontWeight: 500,
        backgroundColor: palette.grey90,
        color: palette.grey10,
        padding: '12px',
        '& .MuiLink-root': {
          color: palette.primary10,
          textDecoration: 'underline',
          transition: 'color .1s',
          '&:hover': {
            color: palette.primary20
          }
        }
      },
      arrow: {
        color: palette.grey90,
      }
    },
    MuiButton: {
      textSizeSmall: {
        fontSize: '14px',
      }
    },
    MuiChip: {
      root: {
        fontSize: '0.875rem',
      },
      label: {
        lineHeight: '1rem',
      },
      sizeSmall: {
        fontWeight: 500,
        fontSize: '0.75rem',
      },
      colorSecondary: {
        backgroundColor: palette.tertiary30,
      }
    }
  },
  shadows: baseTheme.shadows.map(shadow => {
    // Just go over all the 25 shadow levels, and replace the black one with a slightly-blue-tinted one
    if (!shadow?.replace) return shadow
    return shadow
      .replace('rgba(0,0,0,0.2)', 'rgba(22,45,61,0.2)')
      .replace('rgba(0,0,0,0.14)', 'rgba(22,45,61,0.14)')
      .replace('rgba(0,0,0,0.12)', 'rgba(22,45,61,0.12)')
  })
})

export {
  muiTheme
}
