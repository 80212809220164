import i18n from 'i18next'
import ICU from 'i18next-icu'
import { initReactI18next } from 'react-i18next'

/**
 * Fetching all namespaces right away avoids having to handle the intermediate state of the app,
 * where files for the namespace are not yet initialized.
 * @type {string[]}
 */
const defaultNamespaces = [
  'common',
  'marketplace',
  'importList',
  'mainDashboard',
  'productPage',
  'syncedProducts',
  'myStores',
  'settings',
]

/**
 * Configure i18next instance to be able to load content files properly,
 * as well as handle ICU format.
 */
const i18nConfig = i18n
  .use({
    type: 'backend',
    read: (language, namespace, callback) => {
      import(`shared/locale/${namespace}/${namespace}_${language}.json`)
        .then((resources) => {
          callback(null, resources)
        })
        .catch((error) => {
          callback(error, null)
        })
    },
  })
  .use(ICU)
  .use(initReactI18next)

export {
  i18nConfig,
  defaultNamespaces
}
