import { action } from 'mobx'

import { FilterStore } from 'shared/stores'

class SearchStore extends FilterStore {
  constructor (...props) {
    super(...props)
    this._value = '' // Ensure value is string at init
  }

  @action.bound
  clearFilter () {
    this._value = ''
    this.deactivate()
  }
}

export default SearchStore
