import React from 'react'

import PropTypes from 'prop-types'

import { SwipeableDrawer } from '@material-ui/core'
import clsx from 'clsx'
import { observer } from 'mobx-react'

import { Footer, MainMenu } from 'retailer/components/organisms'
import { useStores } from 'retailer/stores/hooks'
import { useIsDesktop } from 'retailer/theme/hooks'

import style from './Sidebar.module.scss'

/**
 * The main navigation sidebar menu
 **/
const Sidebar = ({ temporary }) => {
  const { mainMenuStore: { open, toggle } } = useStores()
  const isDesktop = useIsDesktop()
  const permanent = !temporary && isDesktop

  const handleClose = () => toggle()
  const handleOpen = () => toggle()

  return (
    <SwipeableDrawer
      variant={permanent ? 'permanent' : 'temporary'} anchor="left"
      open={permanent || open} onClose={handleClose} onOpen={handleOpen}
      PaperProps={{ className: clsx(style.DrawerPaper, permanent && style.isPermanent) }}
      ModalProps={{ keepMounted: true }}
    >
      <MainMenu />
      <Footer />
    </SwipeableDrawer>
  )
}

Sidebar.propTypes = {
  /** Force menu to stay hidden until explicitely toggled on */
  temporary: PropTypes.bool,
}

export default observer(Sidebar)
