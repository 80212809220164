import React from 'react'

import PropTypes from 'prop-types'

import { ListItem, ListItemText } from '@material-ui/core'
import { ChevronRight } from '@material-ui/icons'

import style from './FiltersDrawerSectionButton.module.scss'

/**
 * A listitem acting as a button to open a section.
 * Pass `secondary` for additional info
 */
const FiltersDrawerSectionButton = ({ primary, secondary, onClick }) => (
  <ListItem className={style.FiltersDrawerSectionButton} button onClick={onClick}>
    <ListItemText
      primary={primary}
      primaryTypographyProps={{ component: 'span', display: 'inline', 'data-testid': 'FilterDrawersSectionButton' }}
      secondary={secondary}
      secondaryTypographyProps={{ component: 'span', display: 'inline' }}
    />
    <ChevronRight />
  </ListItem>
)

FiltersDrawerSectionButton.propTypes = {
  primary: PropTypes.string.isRequired,
  secondary: PropTypes.string,
  onClick: PropTypes.func.isRequired,
}

export default FiltersDrawerSectionButton
