import { action, observable } from 'mobx'

class DateRangeStore {
  @observable id
  @observable state

  constructor (id, state = {}) {
    this.id = id
    this.state = state
  }

  @action.bound
  setState (state) {
    this.state = state
  }
}

export default DateRangeStore
