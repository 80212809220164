import { useContext } from 'react'

import storesContext from 'shared/stores/context'

/**
 * @returns {import('./root').RootStore}
 */
function useStores () {
  return useContext(storesContext)
}

export {
  useStores,
}
