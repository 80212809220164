import React from 'react'

import PropTypes from 'prop-types'

import { CheckBoxOutlineBlankRounded, CheckBoxRounded, IndeterminateCheckBoxRounded } from '@material-ui/icons'
import { observer } from 'mobx-react'

/**
 * Get a proper Checkbox-state icon, based on provided params.
 * I.e. we want to display a selected checkbox if all items are selected,
 * indeterminate if some, and an empty checkbox if none are selected.
 */
const StatefulCheckboxIcon = ({ status, IconProps, className }) => {
  let IconComponent

  switch (status) {
    case 'empty': {
      IconComponent = CheckBoxOutlineBlankRounded
      break
    }
    case 'indeterminate': {
      IconComponent = IndeterminateCheckBoxRounded
      break
    }
    case 'checked': {
      IconComponent = CheckBoxRounded
      break
    }
    default: {
      IconComponent = CheckBoxOutlineBlankRounded
    }
  }
  return <IconComponent {...IconProps} className={className} />
}

StatefulCheckboxIcon.propTypes = {
  status: PropTypes.oneOf(['empty', 'indeterminate', 'checked']),
  IconProps: PropTypes.object,
  className: PropTypes.string
}

StatefulCheckboxIcon.defaultProps = {
  status: 'empty',
  IconProps: {},
  className: ''
}

export default observer(StatefulCheckboxIcon)
