import React from 'react'

import PropTypes from 'prop-types'

import { Button, Menu, MenuItem, Tooltip } from '@material-ui/core'
import { ArrowDropDownRounded } from '@material-ui/icons'
import { bindMenu, bindTrigger, usePopupState } from 'material-ui-popup-state/hooks'
import { observer } from 'mobx-react'

import { StatefulCheckboxIcon } from 'shared/components/atoms'
import { useTranslation } from 'shared/hooks'

import style from './SelectAllButton.module.scss'

const MENU_ANCHOR_ID = 'menuAnchor'

const SelectAllButton = ({
  singleOption,
  disabled,
  selectionActive,
  onSelectAll,
  onSelectAllPages,
  onUnselectAll,
  allPagesSelected,
  allOnPageSelected,
  partSelected,
  anchorEl,
  testId,
}) => {
  const { t } = useTranslation('common')
  const popupState = usePopupState({ variant: 'popover', popupId: 'itemActions' })

  const checkboxStatus = (() => {
    if (allPagesSelected || allOnPageSelected) return 'checked'
    if (partSelected) return 'indeterminate'
    return 'empty'
  })()

  if (singleOption) {
    return (
      <>
        {!selectionActive && (
          <Button
            disabled={disabled}
            color="primary"
            className={style.SingleSelectButton}
            data-testid={testId ? `${testId}-selectAllButton` : undefined}
            data-test-state="unselected"
            onClick={() => {
              onSelectAll()
              popupState.close()
            }}
          >
            <StatefulCheckboxIcon status={checkboxStatus} />
          </Button>
        )}
        {selectionActive && (
          <Tooltip title={t('selectAllButton.unselectAll.label')}>
            <Button
              disabled={disabled}
              color="primary"
              className={style.CompositeButtonElement}
              onClick={onUnselectAll}
              data-testid={testId ? `${testId}-selectAllButton` : undefined}
              data-test-state="selected"
            >
              <StatefulCheckboxIcon status={checkboxStatus} />
            </Button>
          </Tooltip>
        )}
      </>
    )
  }

  return (
    <>
      {!selectionActive && (
        <Button
          id={MENU_ANCHOR_ID}
          disabled={disabled}
          color="primary"
          endIcon={<ArrowDropDownRounded />}
          className={style.SingleSelectButton}
          data-testid={testId ? `${testId}-selectAllButton` : undefined}
          data-test-state="unselected"
          {...bindTrigger(popupState)}
        >
          <StatefulCheckboxIcon status={checkboxStatus} />
        </Button>
      )}
      {selectionActive && (
        <div id={MENU_ANCHOR_ID}>
          <Tooltip title={t('selectAllButton.unselectAll.label')}>
            <Button
              color="primary"
              className={style.CompositeButtonElement}
              onClick={onUnselectAll}
              data-testid={testId ? `${testId}-selectAllButton` : undefined}
              data-test-state="selected"
            >
              <StatefulCheckboxIcon status={checkboxStatus} />
            </Button>
          </Tooltip>
          <Button
            color="primary"
            className={style.CompositeButtonElement}
            {...bindTrigger(popupState)}
          >
            <ArrowDropDownRounded fontSize="small" />
          </Button>
        </div>
      )}
      <Menu
        {...bindMenu(popupState)}
        anchorEl={anchorEl ? () => anchorEl : () => document.getElementById(MENU_ANCHOR_ID)}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <MenuItem
          onClick={() => {
            onSelectAll()
            popupState.close()
          }}
          data-testid={testId ? `${testId}-selectAllButton-selectPage` : undefined}
        >
          {t('selectAllButton.selectPage.label')}
        </MenuItem>
        <MenuItem
          onClick={() => {
            onSelectAllPages()
            popupState.close()
          }}
          data-testid={testId ? `${testId}-selectAllButton-selectAll` : undefined}
        >
          {t('selectAllButton.selectAll.label')}
        </MenuItem>
      </Menu>
    </>
  )
}

SelectAllButton.propTypes = {
  singleOption: PropTypes.bool,
  disabled: PropTypes.bool,
  selectionActive: PropTypes.bool,
  onSelectAll: PropTypes.func.isRequired,
  onSelectAllPages: PropTypes.func.isRequired,
  onUnselectAll: PropTypes.func.isRequired,
  allPagesSelected: PropTypes.bool,
  allOnPageSelected: PropTypes.bool,
  partSelected: PropTypes.bool,
  anchorEl: PropTypes.element,
  testId: PropTypes.string,
}

export default observer(SelectAllButton)
