/* eslint camelcase: 0 */

import { keysToCamelCase, keysToSnakeCase } from 'shared/utils/api'

const adaptGetInitialImportResponse = ({ sync_summary, ...data }) => ({
  syncSummary: sync_summary && keysToCamelCase(sync_summary),
  ...keysToCamelCase(data)
})

const adaptGetImportsResponseRow = ({ started_at, completed_at, ...rest }) => ({
  startedAt: started_at && new Date(started_at),
  completedAt: completed_at && new Date(completed_at),
  ...keysToCamelCase(rest),
})

const adaptGetImportsResponse = ({ results, ...meta }) => ({
  results: results.map(row => adaptGetImportsResponseRow(row)),
  ...keysToCamelCase(meta)
})

const adaptGetImportResponse = data => adaptGetImportsResponseRow(data)

const adaptGetImportItemVariantsResponseRow = data => keysToCamelCase(data)

const adaptGetImportItemsResponseRow = ({ variants, ...rest }) => ({
  variants: variants.map(row => adaptGetImportItemVariantsResponseRow(row)),
  ...keysToCamelCase(rest)
})

const adaptGetImportItemsResponse = ({ results, ...meta }) => ({
  results: results.map(row => adaptGetImportItemsResponseRow(row)),
  ...keysToCamelCase(meta)
})

const adaptPostImportRequest = keysToSnakeCase
const adaptPostImportErrors = ({ non_field_errors, ...rest }) => ({
  nonFieldErrors: non_field_errors,
  fieldErrors: keysToCamelCase(rest),
})
const adaptPostImportResponse = adaptGetImportResponse

export {
  adaptGetInitialImportResponse,
  adaptGetImportsResponse,
  adaptGetImportResponse,
  adaptGetImportItemsResponse,
  adaptPostImportRequest,
  adaptPostImportErrors,
  adaptPostImportResponse,
}
