import pick from 'lodash/pick'
import { action, observable } from 'mobx'
import { RouterStore as BaseRouterStore } from 'mobx-react-router'

const LOCATION_HISTORY_SIZE = 30

class RouterStore extends BaseRouterStore {
  /** Stores last LOCATION_HISTORY_SIZE location entries */
  @observable.shallow locationHistory = []

  @action
  _updateLocation (newState) {
    super._updateLocation(newState)
    this.locationHistory = [
      ...this.locationHistory.slice(-LOCATION_HISTORY_SIZE + 1),
      pick(newState, ['pathname', 'search', 'hash']),
    ]
  }

  /**
   * Find the first entry in history with a matching pathname and push it
   *
   * If the entry is not found, use fallback if provided.
   * Use this method to go back to specific views without losing the params
   * chosen by the user.
   * */
  @action.bound
  pushFromHistory (pathnamePattern, fallback) {
    const matchingEntry = this.locationHistory.reverse().find(loc => loc.pathname.match(pathnamePattern))
    if (matchingEntry || fallback) {
      this.push(matchingEntry || fallback)
    }
  }

  getSearchParamValue (paramName, defaultValue = undefined) {
    const params = new URLSearchParams(this.location.search)
    return params.get(paramName) || defaultValue
  }

  @action.bound
  openInBlank (url) {
    window.open(url, '_blank', 'noopener,noreferrer')
  }
}

export default RouterStore
