import { action, observable } from 'mobx'

import {
  OrderModalActionState,
  OrderPaymentState,
  OrderShippingAddressEditState,
  OrdersListStore,
  OrderItemShippingMethodEditState,
  ExternalOrderPaymentStore
} from 'retailer/stores'

class OrdersStore {
  @observable ordersList = new OrdersListStore(window.location.search)
  @observable orderShippingAddressEditState
  @observable orderPaymentState
  @observable orderMarkAsOrderedState
  @observable orderMarkAsPaidState
  @observable orderItemShippingMethodEditState
  @observable orderCancelState
  @observable orderMarkAsFulfilledState
  @observable externalOrderPaymentStore
  @observable orderSyncState
  @observable orderSyncAndPayState

  @action.bound
  selectOrderForShippingAddressEditing (order) {
    this.orderShippingAddressEditState = new OrderShippingAddressEditState(
      order)
  }

  @action.bound
  finishOrderShippingAddressEditing () {
    this.orderShippingAddressEditState = undefined
  }

  @action.bound
  selectOrderForModalystPayment (order) {
    this.orderPaymentState = new OrderPaymentState(order)
  }

  @action.bound
  resetModalystOrderPayment () {
    this.orderPaymentState = undefined
  }

  @action.bound
  selectOrderForMarkAsOrdered (order) {
    this.orderMarkAsOrderedState = new OrderModalActionState(order)
  }

  @action.bound
  resetOrderMarkAsOrdered () {
    this.orderMarkAsOrderedState = undefined
  }

  @action.bound
  selectOrderForMarkAsPaid (order) {
    this.orderMarkAsPaidState = new OrderModalActionState(order)
  }

  @action.bound
  resetOrderMarkAsPaid () {
    this.orderMarkAsPaidState = undefined
  }

  @action.bound
  selectOrderItemForShippingMethodEditing (item) {
    this.orderItemShippingMethodEditState = new OrderItemShippingMethodEditState(item)
  }

  @action.bound
  finishOrderItemShippingMethodEditing () {
    this.orderItemShippingMethodEditState = undefined
  }

  @action.bound
  selectOrderForCancel (order) {
    this.orderCancelState = new OrderModalActionState(order)
  }

  @action.bound
  resetOrderCancel () {
    this.orderCancelState = undefined
  }

  @action.bound
  selectOrderForMarkAsFulfilled (order) {
    this.orderMarkAsFulfilledState = new OrderModalActionState(order)
  }

  @action.bound
  resetOrderMarkAsFulfilled () {
    this.orderMarkAsFulfilledState = undefined
  }

  @action.bound
  selectOrderForExternalPayment (order) {
    this.externalOrderPaymentStore = new ExternalOrderPaymentStore(order)
  }

  @action.bound
  resetOrderForExternalPayment () {
    this.externalOrderPaymentStore = undefined
  }

  @action.bound
  selectOrderForSync (order) {
    this.orderSyncState = new OrderModalActionState(order)
  }

  @action.bound
  selectOrderForSyncAndPay (order) {
    this.orderSyncAndPayState = new OrderModalActionState(order)
  }

  @action.bound
  resetOrderSync () {
    this.orderSyncState = undefined
  }

  @action.bound
  resetOrderSyncAndPay () {
    this.orderSyncAndPayState = undefined
  }
}

export default OrdersStore
