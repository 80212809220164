import { action, observable } from 'mobx'

class BaseProductImportManagerStore {
  /** polling timeout handle */
  pollTimeout

  /** Show details of the import run eg. in a modal */
  @observable detailsShown = false

  @observable importRun

  /** Resets the manager state to initials */
  @action.bound
  reset () {
    this._clearPollTimeout()
    this.importRun = undefined
  }

  @action.bound
  setDetailsShown (value) {
    this.detailsShown = value
  }

  @action.bound
  setImportRun (value) {
    this.importRun = value
  }

  _clearPollTimeout () {
    clearTimeout(this.pollTimeout)
  }

  _poll () {
    this.pollTimeout = setTimeout(() => {
      this.pollImportRun()
    }, 2000)
  }

  pollImportRun () {
    throw new Error('Override in a subclass')
  }
}

export default BaseProductImportManagerStore
