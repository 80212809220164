import { PLAN_MONTHLY, PLAN_YEARLY } from 'shared/constants/settings'
import { UNLIMITED_ITEM_LIMIT } from 'shared/constants/subscriptions'
import { keysToCamelCase } from 'shared/utils/api'

const adaptPeriod = period => {
  switch (period) {
    case 'annual': return PLAN_YEARLY
    case 'monthly': return PLAN_MONTHLY
    default: return null
  }
}

// eslint-disable-next-line camelcase
const adaptSubscriptionPlan = ({ period, item_limit, wix_checkout_url, ...rest }) => {
  return {
    period: adaptPeriod(period),
    // eslint-disable-next-line camelcase
    itemLimit: item_limit >= UNLIMITED_ITEM_LIMIT ? null : item_limit,
    wixCheckoutUrl: keysToCamelCase(wix_checkout_url),
    ...keysToCamelCase(rest)
  }
}

export {
  adaptSubscriptionPlan,
}
