import { addNewStoreModalNextClicked } from '@wix/bi-logger-modalyst/v2'
import { action, observable } from 'mobx'

import { adaptGetStoresResponse } from 'shared/adapters/stores'
import { getStores, deleteStore as apiDeleteStore } from 'shared/api/stores'
import { ERROR_GENERIC_API_ERROR } from 'shared/constants/messages'
import { LoadableStore, StoreStore, CreateStoreStore } from 'shared/stores'
import { getBiHeadersFromEvent } from 'shared/stores/BiLoggerStore/utils'

class MyStoresStore {
  /** @type {import('../context').RootStore} */
  root

  @observable stores
  @observable fetchStatus = new LoadableStore()
  @observable createStoreStore

  /**
   * @param {import('../context').RootStore} root
   */
  constructor (root) {
    this.root = root
    this.createStoreStore = new CreateStoreStore(root)
  }

  @action.bound
  async handleCreateStoreSubmit (data, params = {}) {
    const { name, integrationType, country } = data
    const { correlationId } = params
    this.root.biLoggerStore.log(addNewStoreModalNextClicked({
      origin: 'retailer', storeName: name, action: integrationType, country, correlationId
    }))
    await this.createStoreStore.submitFormData(data, params)
    await Promise.all([
      this.root.userProfileStore.fetch(),
      this.root.myStoresStore.refresh(),
    ])
  }

  @action.bound
  async fetch () {
    this.fetchStatus.setLoading()
    try {
      const response = await getStores()
      const data = adaptGetStoresResponse(response.data)
      this.stores = data.results.map(obj => new StoreStore(this.root, obj))
      return data
    } catch (error) {
      // TODO: add proper error handling
      this.error = error.response?.content.details || ERROR_GENERIC_API_ERROR
    }
    this.fetchStatus.setLoaded()
  }

  @action.bound
  reset () {
    this.stores = undefined
    this.fetchStatus.reset()
  }

  @action.bound
  async refresh () {
    return await this.fetch()
  }

  @action.bound
  async deleteStore (store, biEvent) {
    if (!store.isDeleteable) return
    store.setIsDeleteInProgress(true)
    const isCurrentStore = this.root.userProfileStore.currentStore.id === store.id
    try {
      await apiDeleteStore(store.uuid, getBiHeadersFromEvent(biEvent))
      if (isCurrentStore) {
        window.location.assign('/retailer/my-stores')
      } else {
        await this.root.userProfileStore.refresh()
        await this.refresh()
      }
    } finally {
      store.setIsDeleteInProgress(false)
    }
  }
}

export default MyStoresStore
