import React from 'react'

import PropTypes from 'prop-types'

import {
  Grid,
  Hidden,
  Table,
  TableBody,
  TableCell as BaseTableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { observer } from 'mobx-react'

import Money from 'shared/components/atoms/Money'
import { integrationsNames } from 'shared/constants/integrations'
import { Info } from 'shared/icons'

import { OrderItemName, OrderItemShippingCost } from 'retailer/components/molecules'
import { PurchaseOrder as PurchaseOrderStore } from 'retailer/stores'

import style from './OrderTable.module.scss'

const TableCell = withStyles(() => ({
  head: {
    border: 'none',
  },
  body: {
    border: 'none',
  },
}))(BaseTableCell)

const TotalTooltip = observer(({ visible, integrationName, children }) => {
  if (!visible) return null
  return (
    <Tooltip
      arrow
      placement="left-end"
      title={
        <>
          <Typography variant="inherit">
            The total cost may be different from the total
            in {integrationName} because of the following:
          </Typography>
          <Typography variant="inherit">
            <ul>
              <li>
                Shipping fees are based on the shipping method
                which you selected in the Import List
                and may change slightly over time.
              </li>
              <li>
                Shipping fees may vary with multiple items in an order.
              </li>
              <li>
                Shipping fees vary according to the country of your customer.
              </li>
              <li>
                {integrationName} does not give us tax data -
                they will be applied to your order on {integrationName}.
              </li>
              <li>
                Prices on Modalyst are rounded -
                you are responsible for the listed cost on {integrationName}.
              </li>
            </ul>
          </Typography>
          <Typography variant="inherit">
            You are responsible for the stated price on {integrationName} when
            the order is placed.
          </Typography>
        </>
      }
    >
      {children}
    </Tooltip>
  )
})

TotalTooltip.propTypes = {
  visible: PropTypes.bool,
  integrationName: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
}

const OrderTable = ({ order, testId }) => {
  const {
    items,
    totalShippingCost,
    totalCost,
    orderType,
    is3rdPartySupplierOrder,
    usesOrderLevelShippingSelection,
    usesItemLevelShippingSelection,
  } = order
  if (!items || !items.length) {
    return (
      <Typography
        align="center"
        variant="body2"
        color="textSecondary"
        gutterBottom
      >
        No items have been ordered...
      </Typography>
    )
  }

  const integrationName = integrationsNames.get(orderType)

  return (
    <div>
      <TableContainer className={style.TableContainer}>
        <Table size="small" className={style.OrderTable}>
          <colgroup>
            <col className={style.ImageCol} />
            <col className={style.NameAndSkuCol} />
            <col className={style.VariantCol} />
            <col className={style.ShippingMethodCol} />
            <col className={style.SmallWidthCol} />
            <col className={style.SmallWidthCol} />
            <col className={style.SmallWidthCol} />
          </colgroup>
          <TableHead>
            <TableRow>
              <TableCell className={style.ImageCell}>
                <Hidden xsUp>Image</Hidden>
              </TableCell>
              <TableCell>Item</TableCell>
              <TableCell>Variant</TableCell>
              <TableCell>Shipping</TableCell>
              <TableCell>Quantity</TableCell>
              <TableCell>Item Cost</TableCell>
              <TableCell className={style.TotalColumn}>Total</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {items.map(item => {
              const {
                uuid,
                image,
                itemName,
                options,
                quantity,
                itemCost,
                totalCosts,
                sku,
              } = item
              return (
                <TableRow key={uuid} data-testid={`${testId}-itemRow`}>
                  <TableCell className={style.ImageCell}>
                    {image && <img src={image} alt={itemName} />}
                  </TableCell>
                  <TableCell>
                    <OrderItemName item={item} testId={`${testId}-itemRow-itemName`} />
                    <span data-testid={`${testId}-itemRow-sku`}>SKU: {sku}</span>
                  </TableCell>
                  <TableCell data-testid={`${testId}-itemRow-options`}>
                    {Object.values(options).join(' / ') || '-'}
                  </TableCell>
                  <TableCell data-testid={`${testId}-itemRow-shippingCost`}>
                    {usesItemLevelShippingSelection ? <OrderItemShippingCost orderItem={item} /> : '-'}
                  </TableCell>
                  <TableCell data-testid={`${testId}-itemRow-quantity`}>{quantity}</TableCell>
                  <TableCell>
                    <Money amount={itemCost} testId={`${testId}-itemRow-itemCost`} />
                  </TableCell>
                  <TableCell
                    className={style.TotalColumn}
                  >
                    <Money amount={totalCosts} testId={`${testId}-itemRow-totalCost`} />
                  </TableCell>
                </TableRow>
              )
            })}
            <Hidden smDown>
              <TableRow className={style.Summary}>
                <TableCell colSpan={3} className={style.ShippingSummary}>
                  {usesOrderLevelShippingSelection && (
                    <>
                      Shipping:
                      <OrderItemShippingCost
                        className={style.ShippingMethod} showAmount={false} orderItem={items[0]}
                        testId={`${testId}-summaryRow-orderItemShippingCost`}
                      />
                    </>
                  )}
                </TableCell>
                <TableCell>
                  <Money amount={totalShippingCost} testId={`${testId}-summaryRow-totalShippingCost`} />
                </TableCell>
                <TableCell />
                <TableCell />
                <TableCell className={style.TotalColumn}>
                  <Grid
                    container
                    alignItems="center"
                    justifyContent="flex-end"
                    spacing={1}
                  >
                    <Grid item><Money amount={totalCost} testId={`${testId}-summaryRow-totalCost`} /></Grid>
                    <Grid item>
                      <TotalTooltip visible={is3rdPartySupplierOrder} integrationName={integrationName}>
                        <Info width={16} height={16} />
                      </TotalTooltip>
                    </Grid>
                  </Grid>
                </TableCell>
              </TableRow>
            </Hidden>
          </TableBody>
        </Table>
      </TableContainer>
      <Hidden mdUp>
        <Grid
          container
          direction="column"
          alignItems="flex-end"
          className={style.Summary}
        >
          <Grid item>
            <Typography variant="body2" className={style.Shipping}>
              <strong>Shipping:</strong>
              {usesOrderLevelShippingSelection && (
                <OrderItemShippingCost
                  className={style.ShippingMethod} showAmount={false} orderItem={items[0]}
                  testId={`${testId}-summaryRow-orderItemShippingCost`}
                />
              )}
              <Money amount={totalShippingCost} testId={`${testId}-summaryRow-totalShippingCost`} />
            </Typography>
          </Grid>
          <Grid item>
            <Grid
              container
              alignItems="center"
              justifyContent="flex-end"
              spacing={1}
            >
              <Grid item>
                <Typography variant="body1" className={style.Total}>
                  Total: <Money amount={totalCost} testId={`${testId}-summaryRow-totalCost`} />
                </Typography>
              </Grid>
              <Grid item>
                <TotalTooltip visible={is3rdPartySupplierOrder} integrationName={integrationName}>
                  <Info width={16} height={16} />
                </TotalTooltip>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Hidden>
    </div>
  )
}

OrderTable.propTypes = {
  order: PropTypes.instanceOf(PurchaseOrderStore).isRequired,
  testId: PropTypes.string,
}

export default observer(OrderTable)
