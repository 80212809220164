import React from 'react'

import PropTypes from 'prop-types'

import clsx from 'clsx'
import Flags from 'country-flag-icons/react/3x2'

import style from './CountryFlag.module.scss'

const CountryFlag = ({ isoCode, className, ...props }) => {
  const Component = Flags[isoCode]
  if (!Component) return null
  return (
    <Component className={clsx(style.CountryFlag, className)} {...props} />
  )
}

CountryFlag.propTypes = {
  isoCode: PropTypes.string.isRequired,
  className: PropTypes.string
}

CountryFlag.defaultProps = {
  className: ''
}

export default CountryFlag
