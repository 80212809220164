import React from 'react'

import PropTypes from 'prop-types'

import { Box, Grid, Typography, Button } from '@material-ui/core'
import { HomeRounded, NotListedLocationRounded } from '@material-ui/icons'
import { observer } from 'mobx-react'
import { Link as RouterLink } from 'react-router-dom'

import { SidebarLeftTemplate } from 'retailer/components/templates'

import style from './NotFound.module.scss'

/**
 * Not Found Page. Sometimes with nested routing we want to only output the main content,
 * as sidebar is already provided. In this case just set `withSidebar` to false.
 */
const NotFound = ({ withSidebar }) => {
  const main = (
    <Box className={style.PageWrapper}>
      <Box>
        <Grid container alignItems="center" spacing={1}>
          <Grid item xs={12} lg="auto" className={style.Column}>
            <NotListedLocationRounded className={style.ErrorIcon} color="primary" />
          </Grid>
          <Grid item xs={12} lg="auto" className={style.Column}>
            <Typography variant="h1" component="h2">Not Found</Typography>
            <Typography variant="h5" component="p" color="textSecondary" gutterBottom>
              The page that you were looking for was not found.
            </Typography>
            <Button
              component={RouterLink} to="/"
              variant="contained"
              color="primary"
              size="large"
              startIcon={<HomeRounded />}
              className={style.BackButton}
            >
              Back to the home page
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Box>
  )

  if (!withSidebar) return main

  return (
    <SidebarLeftTemplate
      main={main}
    />
  )
}

NotFound.propTypes = {
  withSidebar: PropTypes.bool
}

NotFound.defaultProps = {
  withSidebar: true
}

export default observer(NotFound)
