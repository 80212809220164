export const PASSWORD_VALIDATION_SPECIAL_CHAR_REGEX = /^(?=.*[ !"#$%&'()*+,\-./:;<=>?@[\]^_`{|}~\\])/
export const PASSWORD_VALIDATION_SPECIAL_CHAR_MESSAGE = 'Password must contain at least one special character'

export const PASSWORD_VALIDATION_CAPITAL_LETTER_REGEX = /^(?=.*[A-Z])/
export const PASSWORD_VALIDATION_CAPITAL_LETTER_MESSAGE = 'Password must contain at least one capital letter'

export const PASSWORD_VALIDATION_DIGIT_REGEX = /^(?=.*\d)/
export const PASSWORD_VALIDATION_DIGIT_MESSAGE = 'Password must contain at least one digit'

export const PASSWORD_VALIDATION_REQUIRED = 'Password is required'

export const PASSWORD_VALIDATION_MIN_LENGTH = 'Password has to be at least 8-characters long'
export const PASSWORD_VALIDATION_MAX_LENGTH = 'Password cannot be longer than 128 characters'

export const PASSWORD_HELP_TEXT = 'At least 8 characters, one big letter, one digit and one special character are required.'
