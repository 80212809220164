import React from 'react'

import PropTypes from 'prop-types'

import { Alert as MuiAlert, AlertTitle } from '@material-ui/lab'
import clsx from 'clsx'
import { observer } from 'mobx-react'

import style from './Alert.module.scss'

const Alert = ({ severity, title, className, children, testId, ...props }) => {
  return (
    <MuiAlert
      className={clsx(style.Alert, className)}
      data-testid={testId}
      severity={severity}
      {...props}
    >
      {title && (
        <AlertTitle data-testid={testId ? `${testId}-title` : undefined}>
          {title}
        </AlertTitle>
      )}
      {children}
    </MuiAlert>
  )
}

Alert.propTypes = {
  severity: PropTypes.string,
  title: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node,
  testId: PropTypes.string,
}

export default observer(Alert)
