import React from 'react'

import PropTypes from 'prop-types'

import { IconLink } from 'retailer/components/molecules'

import style from './MainMenuItem.module.scss'

const MainMenuItem = ({ text, href, icon, className, absolute, submenu, testId }) => {
  return (
    <div className={style.MainMenuItem} data-testid={testId}>
      <IconLink
        text={text}
        href={href}
        icon={icon}
        className={`${style.Link} ${className}`}
        nav
        absolute={absolute}
      />
      {submenu && <div className={style.Submenu}>{submenu}</div>}
    </div>
  )
}

MainMenuItem.propTypes = {
  className: PropTypes.string,
  icon: PropTypes.node.isRequired,
  href: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  absolute: PropTypes.bool,
  submenu: PropTypes.node,
  testId: PropTypes.string,
}

MainMenuItem.defaultProps = {
  className: '',
  absolute: false
}

export default MainMenuItem
