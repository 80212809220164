import { computed } from 'mobx'

const REQUIRED_STEPS = ['storeConnected', 'productsImported', 'pricingRulesAdjusted', 'productsExported']

class OnboardingStore {
  appConfigStore
  data

  constructor (data, appConfigStore) {
    this.data = data
    this.appConfigStore = appConfigStore
  }

  @computed get steps () {
    const steps = {
      storeConnected: !!this.data.storeInstalled,
      aliExpressConnected: !!this.data.aliexpressConnected,
      productsImported: !!this.data.hadItemsInImportList,
      pricingRulesAdjusted: !!this.data.adjustedPricingRules,
      productsExported: !!this.data.hadItemsInSyncList
    }
    if (this.appConfigStore.isFeatureEnabled('alibaba')) {
      steps.alibabaConnected = !!this.data.alibabaConnected
    }
    return steps
  }

  @computed get stepsAllCount () {
    return Object.keys(this.steps).length
  }

  @computed get stepsDone () {
    return Object.entries(this.steps).filter(([key, val]) => val).map(([key, val]) => key)
  }

  @computed get stepsDoneCount () {
    return this.stepsDone.length
  }

  @computed get finished () {
    return this.stepsDoneCount === this.stepsAllCount
  }

  @computed get finishedExceptOptional () {
    return this.stepsDoneCount < this.stepsAllCount &&
           REQUIRED_STEPS.every(key => this.stepsDone.includes(key))
  }
}

export default OnboardingStore
