import React from 'react'

import PropTypes from 'prop-types'

import { CircularProgress, Container, Typography } from '@material-ui/core'
import { observer } from 'mobx-react'
import { Helmet } from 'react-helmet'

import { useBiEvents } from 'shared/bi/hooks'
import { ScrollResetter } from 'shared/components/atoms'
import { useFeatureFlags } from 'shared/features'

import {
  SupplierAppConnectionList,
  SupplierAppConnectionSuccessModal, UniversalNotifications,
} from 'retailer/components/organisms'
import { SidebarLeftTemplate } from 'retailer/components/templates'
import { useStores } from 'retailer/stores/hooks'

import style from './ConnectSuppliersPage.module.scss'

const Intro = () => {
  const { alibaba } = useFeatureFlags()
  const namesOr = alibaba ? 'Alibaba or AliExpress' : 'AliExpress'
  const namesAnd = alibaba ? 'Alibaba & AliExpress' : 'AliExpress'
  return (
    <div className={style.Intro}>
      <Typography paragraph>
        {`Modalyst is the #1 Preferred Partner for ${namesAnd}.
        Access millions of dropship products at wholesale prices and add them to your store in 1-click.
        Once your account is connected to ${namesOr}, Modalyst will:`}
      </Typography>
      <Typography component="ul">
        <li>Update products with any inventory & price changes</li>
        <li>{`Automatically sync your orders with ${namesOr} & your store`}</li>
        <li>Facilitate payment for the order & send the supplier all order details</li>
        <li>Update your order status when orders are fulfilled</li>
        <li>Sync the tracking number and courier</li>
      </Typography>
    </div>
  )
}

const ConnectSuppliersPage = ({ history, match }) => {
  const { logPageLoaded } = useBiEvents({ correlateWithLocation: true })
  const { connectToSuppliersStore } = useStores()

  // these are passed from react-router (see routing in retailer/App.js) and used to
  //  a) auto expand an app card on entry
  //  b) show a connection confirmation modal (which replaces the url
  //     using `history` when closed, removing the `connected` param)
  const { app, connected } = match?.params

  React.useEffect(() => {
    logPageLoaded({ pageName: 'alibaba aliexpress settings' })
    connectToSuppliersStore.fetchAuthorizations()
  }, [])

  const { alibaba } = useFeatureFlags()
  const names = alibaba ? 'Alibaba & AliExpress' : 'AliExpress'

  return (
    <>
      <Helmet>
        <title>Connect to Suppliers</title>
      </Helmet>
      <ScrollResetter />
      <SidebarLeftTemplate
        notifications={<UniversalNotifications />}
        main={
          <Container maxWidth="xl">
            <Typography variant="h2" gutterBottom>{`Connect with ${names}`}</Typography>
            <Intro />
            {connectToSuppliersStore.isLoading
              ? <CircularProgress />
              : <SupplierAppConnectionList className={style.AppList} expandedCard={app} />}
            {connected === 'success' && (
              <SupplierAppConnectionSuccessModal
                integrationName={connectToSuppliersStore[app]?.integrationNameDisplay}
                onExited={() => {
                  history && history.replace(`/connect-suppliers/${app}`)
                }}
              />
            )}
          </Container>
        }
      />
    </>
  )
}

ConnectSuppliersPage.propTypes = {
  history: PropTypes.object,
  match: PropTypes.object,
}

ConnectSuppliersPage.defaultProps = {
}

export default observer(ConnectSuppliersPage)
