import Cookies from 'js-cookie'
import head from 'lodash/head'
import orderBy from 'lodash/orderBy'
import { action, computed, observable } from 'mobx'

import { adaptUserProfile, adaptUserProfileContext } from 'shared/adapters/profiles'
import { logout as apiLogout } from 'shared/api/accounts'
import {
  getUserProfile,
  dismissOnboarding as apiDismissOnboarding,
  aliexpressJoinDropshippingProgram as apiAliexpressJoinDropshippingProgram,
  dismissAlibabaAutomaticPaymentsModal as apiDismissAlibabaAutomaticPaymentsModal,
  dismissAlibabaSyncModal as apiDismissAlibabaSyncModal,
  setContextAttribute,
} from 'shared/api/profiles'
import { StoreStore } from 'shared/stores'

import { CURRENT_STORE_KEY, EXTERNAL_ORDER_CONFIRMATION_KEYS } from 'retailer/constants'
import { OnboardingStore } from 'retailer/stores'

const CURRENT_STORE_COOKIE_NAME = 'current_store_id'
const CURRENT_STORE_COOKIE_EXPIRES = 14

class UserProfileStore {
  /** @type {import('../context').RootStore} */
  root

  @observable uuid
  @observable.shallow stores
  @observable currentStoreId
  @observable country
  @observable context = {}
  @observable onboarding
  @observable aliexpressJoinedDropshippingProgramSuccess
  @observable isStaff

  /**
   * @param {import('../context').RootStore} root
   */
  constructor (root, data = {}) {
    this.root = root

    const currentStoreId = (
      parseInt(sessionStorage.getItem(CURRENT_STORE_KEY)) || // for refresh / link scenario
      parseInt(Cookies.get(CURRENT_STORE_COOKIE_NAME)) || // fall back to last selected
      undefined // fall back to auto selection
    )
    this.setCurrentStoreId(currentStoreId)

    this.update(data)
  }

  @action.bound update ({ stores = [], ...data }) {
    this.stores = stores.map(obj => new StoreStore(this.root, obj))
    Object.assign(this, data)
  }

  @computed get currentStore () {
    return this.stores?.find(store => store.id === this.currentStoreId) || head(this.stores)
  }

  @computed get productsLeft () {
    return this.currentStore?.productsLeft || 0
  }

  @computed get ready () {
    return !!this.uuid && !!this.currentStore
  }

  @computed get storeSelectorOptions () {
    return orderBy(this.stores, ['isCurrent', 'isPremium', 'name'], ['desc', 'desc', 'asc'])
  }

  /** Set current store for the current session */
  @action.bound
  setCurrentStoreId (storeId) {
    sessionStorage.setItem(CURRENT_STORE_KEY, storeId || '')
    this.currentStoreId = storeId
  }

  /**
   * Prepare for a store switch
   *
   * Call to this method should be immediately followed by a redirection/full refresh.
   * Any API calls that follow will have the CurrentStoreId set to empty.
   **/
  @action.bound
  onBeforeStoreSwitch (targetStoreId) {
    sessionStorage.removeItem(CURRENT_STORE_KEY)
    Cookies.set(CURRENT_STORE_COOKIE_NAME, targetStoreId, { expires: CURRENT_STORE_COOKIE_EXPIRES })
  }

  @action.bound
  async fetch () {
    await getUserProfile().then(response => {
      this.update(adaptUserProfile(response.data))

      // ensure currentStoreId matches one of the actual stores (in case it's undefined
      // or out of sync with available stores) - this.currentStore will handle fallback
      this.setCurrentStoreId(this.currentStore?.id)

      if (this.onboarding === undefined) {
        this.onboarding = this.context.dismissedOnboardingWidget
          ? null
          : new OnboardingStore(this.context, this.root.appConfigStore)
      }
    })
  }

  @action.bound
  async refresh () {
    return await this.fetch()
  }

  @action.bound
  markExternalOrderPaymentConfirmed (confirmationType) {
    const key = EXTERNAL_ORDER_CONFIRMATION_KEYS.get(confirmationType)
    this.context[key] = true
  }

  @action.bound
  dismissOnboarding () {
    apiDismissOnboarding().then(() => {
      this.onboarding = null
    })
  }

  @action.bound
  async setContextAttribute (key, value) {
    const response = await setContextAttribute(key, value)
    this.context = adaptUserProfileContext(response.data.context_data)
  }

  @action.bound
  async markAdjustedPricingRules () {
    const response = await setContextAttribute('adjusted_pricing_rules', true)
    const data = adaptUserProfile(response.data)
    this.update(data)
    this.onboarding = this.context.dismissedOnboardingWidget
      ? null
      : new OnboardingStore(this.context, this.root.appConfigStore)
  }

  @action.bound
  aliexpressJoinDropshippingProgram () {
    if (this.context.aliexpressJoinedDropshippingProgram) return

    // TODO: set properties with actions
    apiAliexpressJoinDropshippingProgram()
      .then(() => {
        this.context.aliexpressJoinedDropshippingProgram = true
        this.aliexpressJoinedDropshippingProgramSuccess = true
      })
      .catch(() => {
        this.context.aliexpressJoinedDropshippingProgram = false
        this.aliexpressJoinedDropshippingProgramSuccess = false
      })
  }

  @action.bound
  dismissAlibabaAutomaticPaymentsModal () {
    if (this.context.dismissAlibabaAutomaticPaymentsModal) return

    this.context.dismissAlibabaAutomaticPaymentsModal = true
    apiDismissAlibabaAutomaticPaymentsModal()
      .catch(() => {
        this.context.dismissAlibabaAutomaticPaymentsModal = false
      })
  }

  @action.bound
  dismissAlibabaSyncModal () {
    if (this.context.dismissAlibabaSyncModal) return

    this.context.dismissAlibabaSyncModal = true
    apiDismissAlibabaSyncModal()
      .catch(() => {
        this.context.dismissAlibabaSyncModal = false
      })
  }

  @action.bound
  async logout (params) {
    try {
      await apiLogout(params)
      sessionStorage.removeItem(CURRENT_STORE_KEY)
    } catch (error) {
      if (error.response?.status !== 403) throw error
    }
    window.location.assign('/login')
  }

  @computed get biEventData () {
    const { subscriptionPlan, storeInstallation } = this.currentStore || {}
    return {
      msid: storeInstallation?.siteId || null,
      instanceId: storeInstallation?.instanceId || null,
      storeUrl: storeInstallation?.siteUrl || null,
      accountId: this.uuid,
      businessId: this.currentStore.uuid,
      currency: this.currentStore.currency,
      retailerStorePlatform: storeInstallation?.platform || null,
      isPremium: this.currentStore.isPremium,
      subscriptionPlanId: subscriptionPlan?.wixPlanId || null,
    }
  }
}

export default UserProfileStore
