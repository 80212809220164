import { pick } from 'lodash'
import { action, observable } from 'mobx'

import { adaptPublicInfo, adaptPublicInfoErrors, adaptPublicInfoForm } from 'shared/adapters/settings'
import { getPublicInfo, savePublicInfo } from 'shared/api/settings'
import { processApiError } from 'shared/api/utils'
import { LoadableStore } from 'shared/stores'

class ProfileSettingsStore {
  parent

  @observable loading = new LoadableStore()
  @observable isSaving = false
  @observable errors

  @observable storeName = ''
  @observable established = new Date()
  @observable logo = null
  @observable description = ''

  constructor (parent) {
    this.parent = parent
  }

  @action.bound
  fetch () {
    this.loading.setLoading()
    return getPublicInfo().then(response => {
      Object.assign(this, adaptPublicInfo(response.data))
    }).finally(() => {
      this.loading.setLoaded()
    })
  }

  @action.bound
  async refresh () {
    const response = await getPublicInfo()
    Object.assign(this, adaptPublicInfo(response.data))
  }

  @action.bound
  async save (data, dirtyFields) {
    // when image was removed it is undefined in the data, but also dirty
    // for API call it should be set to null
    if (data.logo === undefined && dirtyFields.logo) data.logo = null
    this.isSaving = true
    try {
      const pickedData = pick(data, Object.keys(dirtyFields))
      await savePublicInfo(adaptPublicInfoForm(pickedData))
      await this.parent.refreshUserProfile()
      await this.refresh()
    } catch (error) {
      this.errors = processApiError(error, adaptPublicInfoErrors)
    } finally {
      this.isSaving = false
    }
  }
}

export default ProfileSettingsStore
