import { action, computed, observable } from 'mobx'

class StoreStore {
    /** @type {import('../context').RootStore} */
    root

    id
    uuid
    name
    currency
    @observable country
    @observable subscriptionPlan
    @observable storeInstallation
    @observable productsLeft
    @observable productsLimit
    @observable newOrdersCount
    @observable isDeleteable
    @observable isDeleteInProgress

    /**
     * @param {import('../context').RootStore} root
     * @param {Object} data
     */
    constructor (root, data = {}) {
      this.root = root
      Object.assign(this, data)
    }

    @computed get isCurrent () {
      return this.root.userProfileStore.currentStoreId === this.id
    }

    @computed get isPremium () {
      return this.subscriptionPlan ? !this.subscriptionPlan.free : false
    }

    @computed get isConnected () {
      return !!this.storeInstallation
    }

    @computed get disconnectUrl () {
      return this.storeInstallation?.disconnectUrl || undefined
    }

    @computed get unlimitedProducts () {
      return this.productsLimit === null
    }

    @computed get platform () {
      return this.storeInstallation?.platform
    }

    @computed get siteName () {
      return this.storeInstallation?.siteName
    }

    @action.bound setIsDeleteInProgress (value) {
      this.isDeleteInProgress = value
    }
}

export default StoreStore
