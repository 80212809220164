import { keysToCamelCase } from 'shared/utils/api'

const adaptRetailerNotifications = data => {
  const dataCamelCase = keysToCamelCase(data)
  const adaptedData = {}
  Object.keys(dataCamelCase).forEach(key => {
    adaptedData[key] = keysToCamelCase(dataCamelCase[key])
  })
  return adaptedData
}

export {
  adaptRetailerNotifications
}
