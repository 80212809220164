import { INVENTORY_TYPES_IN_ORDER } from 'shared/constants/marketplace'

/**
 * Sort an array of inventory types by the desired order.
 * @param {String[]} inventoryTypes
 * @return {String[]}
 */
const sortInventoryTypes = (inventoryTypes = []) => {
  inventoryTypes.sort((a, b) => {
    return INVENTORY_TYPES_IN_ORDER.indexOf(a) - INVENTORY_TYPES_IN_ORDER.indexOf(b)
  })
  return inventoryTypes
}

export {
  sortInventoryTypes
}
