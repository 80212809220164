class ImageStore {
  id
  url
  thumbnail

  constructor (data) {
    Object.assign(this, data)
  }
}

export default ImageStore
