import React from 'react'

import PropTypes from 'prop-types'

import { Card, CardMedia, Typography, IconButton, Box, Button } from '@material-ui/core'
import clsx from 'clsx'
import { observer } from 'mobx-react'
import { Link as RouterLink } from 'react-router-dom'

import { DoubleChevronRight } from 'shared/icons'

import style from './CtaCard.module.scss'

const CtaCard = ({
  to,
  backgroundColor, divide, className,
  primary, secondary, cta, textPosition, textMaxWidth,
  image, imagePosition, darken,
  ...rest
}) => {
  const rootClassName = clsx(style.CtaCard, divide ? style.divided : style.overlaid, className)
  const textClassName = clsx(style.CardText, style[textPosition])
  const mediaClassName = clsx(style.CardMedia, darken && style.darken)
  const buttonIcon = <DoubleChevronRight />
  const buttonProps = {
    component: RouterLink,
    to,
    size: cta ? 'large' : 'medium',
    color: 'inherit',
    variant: 'outlined',
    className: clsx(style.GoButton, !cta && style.iconOnly),
  }
  const button = cta
    ? <Button {...buttonProps} endIcon={buttonIcon}>{cta}</Button>
    : <IconButton {...buttonProps}>{buttonIcon}</IconButton>
  return (
    <Card
      className={rootClassName}
      style={{ '--bg-color': backgroundColor }}
      {...rest}
    >
      <CardMedia image={image} className={mediaClassName} style={{ backgroundPosition: imagePosition }} />
      <Box className={textClassName}>
        <Box maxWidth={textMaxWidth}>
          <Typography variant="h4" gutterBottom>{primary}</Typography>
          {secondary && <Typography variant="subtitle1" paragraph>{secondary}</Typography>}
        </Box>
        {button}
      </Box>
    </Card>
  )
}

CtaCard.propTypes = {
  /** Card background color */
  backgroundColor: PropTypes.string.isRequired,
  /** Card background image */
  image: PropTypes.string.isRequired,
  /** Position of the image */
  imagePosition: PropTypes.string,
  /** Card title text */
  primary: PropTypes.string.isRequired,
  /**  Card subtitle text */
  secondary: PropTypes.string,
  /** CTA text (button) */
  cta: PropTypes.string,
  /** Path for router link */
  to: PropTypes.string.isRequired,
  /** Divide card into text and image halves */
  divide: PropTypes.bool,
  /** Class name */
  className: PropTypes.string,
  textPosition: PropTypes.oneOf(['left', 'topLeft', 'top', 'topRight', 'right', 'bottomRight', 'bottom', 'bottomLeft', 'center']),
  textMaxWidth: Box.propTypes.maxWidth,
  darken: PropTypes.bool,
}

CtaCard.defaultProps = {
  backgroundColor: '#ddd',
  imagePosition: 'top',
  textPosition: 'left',
  divide: false,
  darken: false,
}

export default observer(CtaCard)
