import { RETAILER_ITEM_DRAFT, RETAILER_ITEM_NEW } from 'shared/constants/retailerItems'

export const adaptKornitSaveStateCallbackPayload = data => {
  // Ignore the first image as it's always a duplicate of the second one
  // Skip nullish values as well (although they shouldn't happen in theory).
  const images = data.thumbnails.slice(1).map(image => image?.url).filter(value => !!value)
  const variants = data.additional.variants
  return {
    createdFrom: data.created_from,
    draftRef: data.ref,
    variants,
    sizes: data.sizes,
    images,
    status: data.additional?.draft ? RETAILER_ITEM_DRAFT : RETAILER_ITEM_NEW,
  }
}
