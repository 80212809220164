
import difference from 'lodash/difference'
import head from 'lodash/head'
import pick from 'lodash/pick'

export function cartesian (...a) {
  if (a.length === 0) return []
  if (a.length === 1) return a[0].map(b => Array.of(b))
  return a.reduce((a, b) => a.flatMap(d => b.map(e => [d, e].flat())))
}

/**
 * Group product options into Record<string, Array<IProductOption>> Map by their key
 *
 * @param {Object[]} option flat array of option objects
 * */
export function groupProductOptions (options) {
  return options.reduce((map, option) => {
    if (!map.has(option.key)) map.set(option.key, [])
    map.get(option.key).push(option)
    return map
  }, new Map())
}

export function findBestMatchingOptionCombination (optionCombosMap, variantOptions, precision = 0) {
  const allOptionsSet = new Set([].concat(...optionCombosMap.keys()))
  return [...optionCombosMap.keys()].find(oc => {
    return (
      optionCombosMap.get(oc) === undefined &&
      difference(oc, variantOptions).length === precision &&
      !difference(variantOptions, oc).some(option => allOptionsSet.has(option))
    )
  })
}

/** Match variants to a map of option combinations with a given precision
 *
 * Returns an array with the variants that could not be matched.
 * Modifies optionCombosMap in place.
 */
export function matchVariants (optionCombosMap, variants, precision = 0) {
  return variants.reduce((acc, v) => {
    const variantOptions = [...(v.options || [])]
    const bestMatchingCombination = findBestMatchingOptionCombination(optionCombosMap, variantOptions, precision)
    if (bestMatchingCombination) {
      optionCombosMap.set(bestMatchingCombination, v)
      return acc.filter(_v => _v !== v)
    }
    return acc
  }, [...variants])
}

export function buildProductVariants (options, existingVariants, newVariantCallback) {
  const optionGroups = groupProductOptions(options)
  let optionCombinationsMap
  if (optionGroups.size > 0) {
    const optionCombinations = cartesian(...optionGroups.values())
    const optionsCombinationIds = optionCombinations.map(c => c.map(o => o.uuid))
    optionCombinationsMap = new Map(optionsCombinationIds.map(c => [c, undefined]))
    // map existing variants onto option combinations
    let unmappedVariants = existingVariants
    for (let precision = 0; precision <= optionsCombinationIds[0].length; precision++) {
      unmappedVariants = matchVariants(optionCombinationsMap, unmappedVariants, precision)
    }
  } else {
    optionCombinationsMap = new Map([[[], existingVariants[0]]])
  }

  const variantEntries = [...optionCombinationsMap.entries()]
  // get a first mapped variant as a source of default values
  const variantDefaults = pick(
    head(variantEntries.map(([_, v]) => v).filter(v => !!v)) || {},
    ['msrpOriginal']
  )
  // create new variants for unmapped option combinations and set options
  variantEntries.forEach(([optionCombination, variant]) => {
    if (variant === undefined) {
      optionCombinationsMap.set(
        optionCombination,
        newVariantCallback({ options: optionCombination, ...variantDefaults })
      )
    } else {
      variant.options = optionCombination
    }
  })
  // flatten and return the map
  return [...optionCombinationsMap.values()]
}
