import React, { useEffect, useRef } from 'react'

import PropTypes from 'prop-types'

import {
  Box,
  Button,
  Card,
  Fade,
  FormControl,
  RadioGroup,
  Typography,
} from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { connectStoreModalNextClicked, connectStoreModalShown } from '@wix/bi-logger-modalyst/v2'
import { observer } from 'mobx-react'

import { useBiEvents } from 'shared/bi/hooks'
import { LoadingButton, RadioTile, SuccessSpinner } from 'shared/components/atoms'
import { DataModal, StoreIntegrationCheckWarning } from 'shared/components/organisms'
import { connectableIntegrations, SHOPIFY, WIX } from 'shared/constants/integrations'
import { useTranslation } from 'shared/hooks'
import { SadFace } from 'shared/icons'
import { Shopify, WixLogo } from 'shared/icons/external'
import { useRemoteErrors } from 'shared/utils/forms'

import {
  STEP_START,
  STEP_SHOPIFY_INSTRUCTIONS,
  STEP_AUTHORIZATION_CHECK_FAILED,
  STEP_BACK_FROM_PERMISSIONS_GRANT,
  STEP_CONFIRMATION_FAILED,
  STEP_AUTHORIZATION_CREATED,
  STEP_AUTHORIZATION_CHECK_IN_PROGRESS,
  STEP_SERVER_ERROR,
} from 'retailer/stores/ConnectStoreStore/constants'

import style from './ConnectStoreModal.module.scss'
import ShopifyInstructionsImage from './shopify_app_store.png'

const SelectPlatformContent = observer(({ connectStoreStore }) => {
  const {
    integrationType,
    setConnectToPlatform,
    isAuthorizationInProgress,
  } = connectStoreStore
  const handleChange = (ev, value) => setConnectToPlatform(value)
  return (
    <FormControl fullWidth disabled={isAuthorizationInProgress}>
      <RadioGroup
        row className={style.RadioTileGroup}
        required value={integrationType || ''} onChange={handleChange}
      >
        <RadioTile
          value={WIX}
          icon={WixLogo} label="Wix"
          data-testid="connectStoreModal_platformTileWix"
        />
        <RadioTile
          value={SHOPIFY}
          icon={Shopify} label="Shopify"
          data-testid="connectStoreModal_platformTileShopify"
        />
      </RadioGroup>
    </FormControl>
  )
})

SelectPlatformContent.propTypes = {
  connectStoreStore: PropTypes.shape({
    integrationType: PropTypes.oneOf([WIX, SHOPIFY]),
    setConnectToPlatform: PropTypes.func.isRequired,
    isAuthorizationInProgress: PropTypes.bool.isRequired,
  }).isRequired,
}

const ShopifyInstructionsContent = observer(() => {
  const backgroundImage = `url(${ShopifyInstructionsImage})`
  return (
    <>
      <Typography variant="body1" paragraph>
        You will now be redirected to the Shopify App Store.
      </Typography>
      <Typography variant="body1" paragraph>
        Please ensure that the Shopify store you want to connect is selected. You will find the store selector in the upper right corner of the Shopify interface.
      </Typography>
      <Typography variant="body1" paragraph>
        Click "Install" in the Shopify App Store to finish the installation.
      </Typography>
      <Card className={style.ShopifyInstructionsImage} style={{ backgroundImage }}>
        <img src={ShopifyInstructionsImage} />
      </Card>
    </>
  )
})

ShopifyInstructionsContent.propTypes = {}

const ServerErrorContent = observer(() => {
  return (
    <Alert severity="error">
      There was an error while processing your request. Please try connecting your store again in a couple of minutes.
    </Alert>
  )
})

/**
 * Modal content after the authorization check returns a response that indicates issues
 */
const AuthorizationCheckFailedContent = observer(({ integrationType, checkResults }) => {
  return (
    <div className={style.AuthorizationCheckWarning}>
      <SadFace className={style.WarningIcon} />
      <StoreIntegrationCheckWarning
        integrationType={integrationType}
        results={checkResults}
        TitleProps={{
          variant: 'h6',
          paragraph: true
        }}
      />
    </div>
  )
})

AuthorizationCheckFailedContent.propTypes = {
  integrationType: PropTypes.oneOf(connectableIntegrations).isRequired,
  checkResults: PropTypes.shape({
    canInstallIntegration: PropTypes.bool.isRequired,
    hasCurrentAuthorization: PropTypes.bool.isRequired,
    isAuthorizedOnAnotherBusiness: PropTypes.bool.isRequired,
    hasAuthorizationInRemoval: PropTypes.bool.isRequired,
    installationExistsForUser: PropTypes.string,
  })
}

const AuthorizationInProgressContent = observer(({ success }) => {
  const { t } = useTranslation('myStores')
  return (
    <Box textAlign="center">
      <SuccessSpinner success={success} size={54} mx="auto" my={2} />
      <Fade in={success}>
        <Typography data-testid="connectStoreModal-storeConnectedMessage">
          {t('connectStoreModal.storeConnected.message')}
        </Typography>
      </Fade>
    </Box>
  )
})

AuthorizationInProgressContent.propTypes = {
  success: PropTypes.bool.isRequired,
}

const ConfirmationFailedContent = observer(({ storeIntegrationStore }) => {
  const { confirmAuthorizationRemoteErrors } = storeIntegrationStore
  const { allErrors } = useRemoteErrors(confirmAuthorizationRemoteErrors)
  return (
    <Alert severity="error">
      {allErrors?.join('')}
    </Alert>
  )
})

ConfirmationFailedContent.propTypes = {
  storeIntegrationStore: PropTypes.shape({
    confirmAuthorizationRemoteErrors: PropTypes.object,
  })
}

const ConnectStoreModal = ({ connectStoreStore, onExited }) => {
  const modalRef = useRef(null)
  const { correlationId, logBiEvent } = useBiEvents({ correlateWithLocation: true })

  const { t } = useTranslation('myStores')

  const {
    step,
    businessId,
    isAuthorizationInProgress,
    showPrimaryAction,
    primaryActionDisabled,
    primaryActionLabel,
    showCancelAction,
    cancelActionDisabled,
    confirmIntegrationType,
    confirmInstructions,
    storeIntegration,
    checkAuthorizationState,
    integrationType,
    reset,
  } = connectStoreStore

  const biParams = { correlationId, targetStoreId: businessId, action: integrationType }

  useEffect(() => {
    (async () => {
      await logBiEvent(connectStoreModalShown(biParams))
      checkAuthorizationState({ correlationId })
    })()
    return () => {
      reset()
    }
  }, [])

  const handleProceedClick = async () => {
    if (step === STEP_START) {
      await logBiEvent(connectStoreModalNextClicked(biParams))
      confirmIntegrationType()
    } else if (step === STEP_SHOPIFY_INSTRUCTIONS) {
      confirmInstructions()
    } else {
      modalRef?.current && modalRef.current.dismiss()
    }
  }

  return (
    <DataModal
      ref={modalRef}
      fullWidth maxWidth="sm"
      className={style.ConnectStoreModal}
      title={t('connectStoreModal.title')}
      renderBody={() => {
        switch (step) {
          case STEP_SHOPIFY_INSTRUCTIONS:
            return (
              <ShopifyInstructionsContent />
            )
          case STEP_AUTHORIZATION_CHECK_FAILED: return (
            <AuthorizationCheckFailedContent
              integrationType={integrationType}
              checkResults={storeIntegration.authorizationCheckResults}
            />
          )
          case STEP_AUTHORIZATION_CHECK_IN_PROGRESS:
          case STEP_BACK_FROM_PERMISSIONS_GRANT:
          case STEP_AUTHORIZATION_CREATED:
            return (
              <AuthorizationInProgressContent
                success={step === STEP_AUTHORIZATION_CREATED}
              />
            )
          case STEP_CONFIRMATION_FAILED: return (
            <ConfirmationFailedContent storeIntegrationStore={storeIntegration} />
          )
          case STEP_SERVER_ERROR: return (
            <ServerErrorContent />
          )
          default:
            return <SelectPlatformContent connectStoreStore={connectStoreStore} />
        }
      }}
      renderActions={({ dismissModal }) => (
        <>
          <Fade in={showCancelAction}>
            <Button
              variant="outlined" color="primary"
              disabled={cancelActionDisabled}
              onClick={dismissModal}
            >
              {t('connectStoreModal.cancelAction.cta')}
            </Button>
          </Fade>
          <Fade in={showPrimaryAction}>
            <LoadingButton
              variant="contained" color="primary"
              loading={isAuthorizationInProgress}
              disabled={primaryActionDisabled}
              onClick={handleProceedClick}
              testId="connectStoreModal-submitButton"
            >
              {t(primaryActionLabel)}
            </LoadingButton>
          </Fade>
        </>
      )}
      onExited={onExited}
    />

  )
}

ConnectStoreModal.propTypes = {
  connectStoreStore: PropTypes.shape({
    step: PropTypes.oneOf([
      STEP_START,
      STEP_SHOPIFY_INSTRUCTIONS,
      STEP_AUTHORIZATION_CHECK_IN_PROGRESS,
      STEP_AUTHORIZATION_CHECK_FAILED,
      STEP_BACK_FROM_PERMISSIONS_GRANT,
      STEP_AUTHORIZATION_CREATED,
      STEP_CONFIRMATION_FAILED,
      STEP_SERVER_ERROR,
    ]).isRequired,
    businessId: PropTypes.string.isRequired,
    primaryActionLabel: PropTypes.string.isRequired,
    primaryActionDisabled: PropTypes.bool.isRequired,
    isAuthorizationInProgress: PropTypes.bool,
    showPrimaryAction: PropTypes.bool.isRequired,
    showCancelAction: PropTypes.bool.isRequired,
    cancelActionDisabled: PropTypes.bool.isRequired,
    checkAuthorizationState: PropTypes.func.isRequired,
    confirmIntegrationType: PropTypes.func.isRequired,
    confirmInstructions: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
    integrationType: PropTypes.string,
    storeIntegration: PropTypes.shape({
      authorizationCheckResults: PropTypes.object,
    }),
  }).isRequired,
  onExited: PropTypes.func,
}

export default observer(ConnectStoreModal)
