import { action, observable } from 'mobx'

import { adaptMarketplacesErrors } from 'shared/adapters/settings'
import { getMarketplaces, saveMarketplaces } from 'shared/api/settings'
import { processApiError } from 'shared/api/utils'
import { LoadableStore } from 'shared/stores'

const ALL_CHOICES = ['Amazon', 'Asos', 'Ebay', 'Storenvy', 'Wanelo', 'My own website', 'Other']

class MarketplacesSettingsStore {
  parent
  allChoices = ALL_CHOICES

  @observable loading = new LoadableStore()
  @observable isSaving = false
  @observable errors

  @observable marketplaces = []

  constructor (parent) {
    this.parent = parent
  }

  @action.bound
  fetch () {
    this.loading.setLoading()
    return getMarketplaces().then(response => {
      this.marketplaces = response.data.marketplaces
    }).finally(() => {
      this.loading.setLoaded()
    })
  }

  @action.bound
  refresh () {
    return getMarketplaces().then(response => {
      this.marketplaces = response.data.marketplaces
    })
  }

  @action.bound
  save (data) {
    this.isSaving = true
    return saveMarketplaces(data)
      .then(() => {
        return this.refresh()
      })
      .catch(error => {
        this.errors = processApiError(error, adaptMarketplacesErrors)
      })
      .finally(() => {
        this.isSaving = false
      })
  }
}

export default MarketplacesSettingsStore
