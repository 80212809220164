/* eslint camelcase: 0 */

const adaptImage = (data) => {
  return {
    id: data.uuid,
    url: data.image,
    thumbnail: data.thumbnail,
  }
}

export {
  adaptImage
}
