import React, { useEffect } from 'react'

import { Container, Typography } from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'
import { observer } from 'mobx-react'
import { Helmet } from 'react-helmet'

import { ScrollResetter } from 'shared/components/atoms'

import {
  ArchivedProductsList, ArchiveListBatchRestoreModal,
  ArchiveListFilters,
  ArchiveListSelector,
  UniversalNotifications,
} from 'retailer/components/organisms'
import { SidebarLeftTemplate } from 'retailer/components/templates'
import { useStores } from 'retailer/stores/hooks'

import style from './ArchiveListPage.module.scss'

const ArchiveListPage = () => {
  const { archiveListPageStore } = useStores()

  useEffect(() => {
    archiveListPageStore.load()
  }, [])

  const skeleton = (
    <>
      <Skeleton variant="rect" width="100%" height={60} />
      <Skeleton height={60} width={200} style={{ margin: '0 0 0 auto' }} />
      <Skeleton variant="rect" width={200} height={60} />
      <div className={style.List}>
        <Skeleton width="100%" height={60} />
        <Skeleton variant="rect" width="100%" height="100vh" className={style.ListSkeleton} />
        <Skeleton width="100%" height={60} />
      </div>
    </>
  )

  const main = (
    <>
      <ArchiveListFilters
        searchFilter={archiveListPageStore.searchFilter}
        removalReasonFilter={archiveListPageStore.removalReasonFilter}
        nonRestorableFilter={archiveListPageStore.nonRestorableFilter}
      />
      <ArchiveListSelector store={archiveListPageStore} />
      <div className={style.List}>
        <ArchivedProductsList store={archiveListPageStore} />
      </div>
    </>
  )

  return (
    <SidebarLeftTemplate
      notifications={<UniversalNotifications />}
      main={
        <Container maxWidth="xl" disableGutters>
          <Helmet>
            <title>Archive List</title>
          </Helmet>
          <ScrollResetter />
          <div className={style.Header}>
            <Typography variant="h2" gutterBottom>
              Archive List
            </Typography>
            <Typography variant="body1" paragraph>
              This page is a list of items which were removed from the Sync List or directly from your store.<br />
              After 30 days we will automatically remove the items from this archive.
            </Typography>
          </div>
          {!archiveListPageStore.loading.isLoaded ? skeleton : main}

          {archiveListPageStore.batchRestore.open && (
            <ArchiveListBatchRestoreModal store={archiveListPageStore.batchRestore} />
          )}
        </Container>
      }
    />
  )
}

export default observer(ArchiveListPage)
