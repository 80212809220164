import React, { useCallback, useState, useRef } from 'react'

import PropTypes from 'prop-types'

import {
  Button,
  Grid,
  Typography,
  useTheme,
  useMediaQuery,
  Fade,
} from '@material-ui/core'
import clsx from 'clsx'
import { observer } from 'mobx-react'
import FilterFill from 'wix-ui-icons-common/on-stage/FilterFill'

import { useMutationObserver, useTranslation } from 'shared/hooks'

import {
  ActiveFilters,
  BatchActionsBar,
  SearchFilter,
  SortFilter,
} from 'retailer/components/organisms'

import style from './ListFiltersBar.module.scss'

const ListFiltersBar = ({
  selectAllButton,
  label,
  filtersAsPanel,
  searchFilter,
  orderingFilter,
  activeFilters,
  selection,
  onClearAll,
  className
}) => {
  const { t } = useTranslation('common')
  const theme = useTheme()
  const desktopLayoutBreakpoint = useMediaQuery(theme.breakpoints.up('xl'))
  const overlayContainerRef = useRef(null)
  const [overlayOpen, setOverlayOpen] = useState(false)

  const overlayMutationObserver = useCallback(mutationList => {
    if (!overlayOpen && mutationList[0].target.hasChildNodes()) {
      setOverlayOpen(true)
      return
    }

    if (overlayOpen && !mutationList[0].target.hasChildNodes()) {
      setOverlayOpen(false)
    }
  }, [overlayOpen, setOverlayOpen])

  useMutationObserver(overlayContainerRef.current, overlayMutationObserver)

  return (
    <div className={clsx(style.ListFiltersBar, className)}>
      <div className={style.Bar}>
        <Fade in={overlayOpen}>
          <div
            className={clsx(style.Overlay, overlayOpen && style.OverlayOpen)}
            ref={overlayContainerRef}
          />
        </Fade>
        {!!selection?.active && (
          <BatchActionsBar
            count={selection.count}
            actions={selection.actions}
            compact={!desktopLayoutBreakpoint}
            containerRef={overlayContainerRef}
            selectAllButton={selectAllButton}
          />
        )}
        <Grid container spacing={desktopLayoutBreakpoint ? 2 : 1} className={style.Grid}>
          {(selectAllButton || label) && (
            <Grid item className={style.SelectAllButtonWithLabel}>
              {selectAllButton}
              <Typography className={style.Label} data-testid="listFiltersBar-label">
                {label}
              </Typography>
            </Grid>
          )}
          <Grid item style={{ flexGrow: 1 }} />
          {filtersAsPanel && (
            <Grid item>
              <Button
                className={style.Button}
                variant="text"
                color="default"
                startIcon={desktopLayoutBreakpoint && <FilterFill className={style.Icon} />}
                onClick={() => filtersAsPanel.handleOpen()}
              >
                {t('filtersPanel.openFilters.cta')}
              </Button>
            </Grid>
          )}
          {orderingFilter && (
            <Grid item>
              <SortFilter
                activeValue={orderingFilter.activeValue}
                values={orderingFilter.values}
                onChange={value => orderingFilter.setFilter(value)}
                compact={!desktopLayoutBreakpoint}
              />
            </Grid>
          )}
          {searchFilter && (
            <Grid item>
              <SearchFilter
                activeValue={searchFilter.activeValue}
                onChange={value => searchFilter.setFilter(value)}
                onClear={() => searchFilter.clearFilter()}
                compact={!desktopLayoutBreakpoint}
                containerRef={overlayContainerRef}
              />
            </Grid>
          )}
        </Grid>
      </div>
      {!!activeFilters?.length && (
        <ActiveFilters
          filters={activeFilters}
          onClear={onClearAll}
        />
      )}
      {filtersAsPanel?.panel}
    </div>
  )
}

ListFiltersBar.propTypes = {
  selectAllButton: PropTypes.node,
  label: PropTypes.string.isRequired,
  filtersAsPanel: PropTypes.shape({
    handleOpen: PropTypes.func.isRequired,
    handleClose: PropTypes.func.isRequired,
    panel: PropTypes.node.isRequired
  }),
  searchFilter: PropTypes.shape({
    setFilter: PropTypes.func.isRequired,
    clearFilter: PropTypes.func.isRequired,
    activeValue: PropTypes.string.isRequired
  }),
  orderingFilter: PropTypes.shape({
    activeValue: PropTypes.string.isRequired,
    values: PropTypes.instanceOf(Map).isRequired,
    setFilter: PropTypes.func.isRequired
  }),
  activeFilters: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    onDelete: PropTypes.func,
  })),
  selection: PropTypes.shape({
    active: PropTypes.bool,
    count: PropTypes.number.isRequired,
    actions: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string.isRequired,
      handler: PropTypes.func
    }))
  }),
  onClearAll: PropTypes.func,
  className: PropTypes.string
}

ListFiltersBar.defaultProps = {
  selectAllButton: null,
}

export default observer(ListFiltersBar)
