import React from 'react'

import PropTypes from 'prop-types'

import { Tooltip } from '@material-ui/core'
import { InfoOutlined } from '@material-ui/icons'
import clsx from 'clsx'
import { observer } from 'mobx-react'

import style from './TooltipHelper.module.scss'

/**
 * Displays a provided IconComponent and automatically wraps it with a tooltip.
 */
const TooltipHelper = ({ content, className, IconComponent, TooltipProps, ...rest }) => {
  if (!content) return null
  return (
    <Tooltip title={content} {...TooltipProps}>
      <IconComponent className={clsx(style.Icon, className)} {...rest} />
    </Tooltip>
  )
}

TooltipHelper.propTypes = {
  content: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  className: PropTypes.string,
  IconComponent: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  TooltipProps: PropTypes.object,
  color: PropTypes.string,
}

TooltipHelper.defaultProps = {
  IconComponent: InfoOutlined,
  TooltipProps: { placement: 'top' },
  color: 'primary',
}

export default observer(TooltipHelper)
