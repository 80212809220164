import { action, observable } from 'mobx'

import { cancelSubscription } from 'shared/api/subscriptions'

class SubscriptionPlanCancelStore {
  @observable managePlanStore

  @observable inProgress = false
  @observable isSuccess = false
  @observable error = {
    hasError: false,
    errorMessage: null
  }

  constructor (managePlanStore) {
    this.managePlanStore = managePlanStore
  }

  /**
   * @param {boolean} hasError
   * @param {string} [errorMessage]
   */
  @action.bound
  setError (hasError, errorMessage) {
    this.error = {
      hasError,
      errorMessage
    }
  }

  @action.bound
  cancelSubscription () {
    this.setError(false)
    this.inProgress = true
    return cancelSubscription()
      .then(() => {
        return this.managePlanStore.reload().then(() => {
          this.isSuccess = true
        })
      })
      .catch(e => this.setError(true, e?.response?.data?.detail))
      .finally(() => {
        this.inProgress = false
      })
  }
}

export default SubscriptionPlanCancelStore
