import React, { useState } from 'react'

import PropTypes from 'prop-types'

import { Button, Card, CardContent, CardMedia, Typography } from '@material-ui/core'
import clsx from 'clsx'
import head from 'lodash/head'
import isEmpty from 'lodash/isEmpty'
import last from 'lodash/last'
import { observer } from 'mobx-react'
import { Trans } from 'react-i18next'

import { ColorSwatches, MoneyRange } from 'shared/components/molecules'
import { useTranslation } from 'shared/hooks'
import { imageType } from 'shared/types'

import { ProductLink, ProductSlateActionsContainer } from 'retailer/components/atoms'
import { DesignProductAction, ProductSlateShippingSection } from 'retailer/components/molecules'
import { useIsDesktop } from 'retailer/theme/hooks'

import style from './CustomizableProductSlate.module.scss'
import imagePlaceholder from './image-placeholder.svg'

const ProductOptionsDescription = observer(({ sizeOptionValues, otherOptions }) => {
  const { t } = useTranslation('marketplace')
  const otherOptionsCount = Object.keys(otherOptions || {}).length
  switch (true) {
    case !isEmpty(sizeOptionValues):
      return head(sizeOptionValues) +
        (sizeOptionValues.length > 1 ? ` - ${last(sizeOptionValues)}` : '')
    case otherOptionsCount:
      return t('podProductSlate.otherOptions.counter', { count: otherOptionsCount })
    default: return ''
  }
})

ProductOptionsDescription.propTypes = {
  sizeOptionValues: PropTypes.arrayOf(PropTypes.string),
  otherOptions: PropTypes.object,
}

/**
 * Product slate for customizable products.
 */
const CustomizableProductSlate = ({ item }) => {
  const { t } = useTranslation('marketplace')
  const isDesktop = useIsDesktop()

  const {
    name,
    primaryImage,
    hoverImage,
    currency,
    minCost,
    maxCost,
    minRetailPrice,
    maxRetailPrice,
    sizeOptionValues,
    colorOptionValues,
    otherOptions,
    shipping,
  } = item

  const priceProps = {
    currency,
    AmountProps: { className: style.PriceAmount },
    CurrencyProps: { className: style.PriceCurrency },
    useRounding: true,
  }

  const typographyProps = {
    component: 'div', variant: 'inherit', noWrap: true, color: 'textSecondary', gutterBottom: true
  }

  const [hover, setHover] = useState(false)
  const handleMouseEnter = () => setHover(true)
  const handleMouseLeave = () => setHover(false)

  return (
    <Card
      className={clsx(style.CustomizableProductSlate)}
      onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}
      elevation={hover ? 4 : 0}
    >
      <ProductLink productStore={item}>
        <CardMedia className={style.CardMedia}>
          <img
            className={clsx(style.ProductImage, style.ProductImageVisible)}
            src={primaryImage?.thumbnail || imagePlaceholder}
            alt=""
          />
          {!!hoverImage?.thumbnail && (
            <img
              className={clsx(style.ProductImage, hover && style.ProductImageVisible)}
              src={hoverImage.thumbnail}
              alt=""
            />
          )}
        </CardMedia>
        <CardContent>
          <Typography
            component="h5" variant="body2" noWrap gutterBottom
            className={style.ProductName}
          >
            {name}
          </Typography>
          <Typography
            component="div" variant="inherit" noWrap color="textPrimary"
            className={style.ProductCost}
          >
            <Trans
              t={t} i18nKey="productSlate.productCost.label"
              components={{
                moneyRange: (
                  <MoneyRange
                    amountMin={minCost} amountMax={maxCost}
                    testId="ProductSlate-productCost"
                    {...priceProps}
                  />
                )
              }}
            />
          </Typography>
          <Typography
            component="div" color="textSecondary" variant="inherit" noWrap gutterBottom
            className={style.RetailPrice}
          >
            <Trans
              t={t} i18nKey="productSlate.productRetailPrice.label"
              components={{
                moneyRange: (
                  <MoneyRange
                    amountMin={minRetailPrice} amountMax={maxRetailPrice}
                    testId="ProductSlate-retailPrice"
                    {...priceProps}
                  />
                )
              }}
            />
          </Typography>
          <Typography {...typographyProps} className={style.Sizes}>
            <ProductOptionsDescription
              sizeOptionValues={sizeOptionValues}
              otherOptions={otherOptions}
            />
          </Typography>
          <ColorSwatches colors={colorOptionValues} size="small" />
        </CardContent>
      </ProductLink>
      <ProductSlateShippingSection shipping={shipping} currency={currency} isUsOnly />
      <ProductSlateActionsContainer show={!isDesktop && hover} className={style.Actions}>
        <DesignProductAction
          productStore={item}
          variant="contained" color="primary" disableElevation={false}
        >
          {t('podProductSlate.designProduct.cta')}
        </DesignProductAction>
        <ProductLink
          productStore={item}
          component={Button} variant="contained" disableElevation={false}
        >
          {t('podProductSlate.moreDetails.cta')}
        </ProductLink>
      </ProductSlateActionsContainer>
    </Card>
  )
}

CustomizableProductSlate.propTypes = {
  item: PropTypes.shape({
    name: PropTypes.string.isRequired,
    primaryImage: imageType,
    hoverImage: imageType,
    sizeOptionValues: PropTypes.array,
    colorOptionValues: PropTypes.array,
    otherOptions: PropTypes.object.isRequired,
    shipping: PropTypes.object.isRequired,
    minCost: PropTypes.number.isRequired,
    maxCost: PropTypes.number.isRequired,
    minRetailPrice: PropTypes.number.isRequired,
    maxRetailPrice: PropTypes.number.isRequired,
    currency: PropTypes.string.isRequired,
    available: PropTypes.bool,
    isLimited: PropTypes.bool,
  })
}

export default observer(CustomizableProductSlate)
