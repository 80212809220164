import { api } from 'shared/api/common'

const BASE_URL = '/api/v3/subscription_plans/'
const MANAGE_SUBSCRIPTION_BASE_URL = '/api/v3/manage_subscription/'

const getSubscriptionPlans = () => {
  return api.get(BASE_URL)
}

const buySubscription = ({ planCode, recurlyToken }) => {
  return api.post(MANAGE_SUBSCRIPTION_BASE_URL, { plan_code: planCode, recurly_token: recurlyToken })
}

const cancelSubscription = () => {
  return api.post(MANAGE_SUBSCRIPTION_BASE_URL + 'cancel/')
}

export {
  getSubscriptionPlans,
  buySubscription,
  cancelSubscription
}
