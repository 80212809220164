import React from 'react'

import PropTypes from 'prop-types'

import style from './Button.module.scss'

const Button = ({ className, type, children, ...props }) => {
  const typeClassName = type ? style[type] : ''
  return (
    <button
      className={`${style.Button} ${typeClassName} ${className}`}
      {...props}
    >
      {children}
    </button>
  )
}

Button.propTypes = {
  className: PropTypes.string,
  type: PropTypes.oneOf(['primary', 'secondary']),
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
}

Button.defaultProps = {
  className: ''
}

export default Button
