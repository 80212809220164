import { action, computed, observable } from 'mobx'

const noop = () => null

class AsyncBatchActionsStore {
  @observable isStopped = false
  @observable isFinished = false
  @observable inProgress = false
  @observable iterable = []
  @observable processed = 0
  @observable errors = 0

  @observable processorFunc = noop
  @observable onError = noop

  @observable before = noop
  @observable beforeEach = noop
  @observable after = noop
  @observable afterEach = noop

  /**
   * @param {{iterable: *[], processorFunc: function, onError: function, [before]: function, [beforeEach]: function, [after]: function, [afterEach]: function}} props
   */
  constructor (props) {
    this.setUp(props)
  }

  @computed get totalCount () {
    return this.iterable.length
  }

  @computed get processedPercent () {
    return Math.floor(this.processed / this.totalCount * 100)
  }

  @action.bound
  reset () {
    this.isStopped = false
    this.isFinished = false
    this.inProgress = false
    this.iterable = []
    this.processed = 0
    this.errors = 0
  }

  /**
   * @param {{iterable: *[], processorFunc: function, onError: function, [before]: function, [beforeEach]: function, [after]: function, [afterEach]: function}} props
   */
  @action.bound
  setUp (props) {
    this.reset()
    Object.assign(this, props)
  }

  @action.bound
  stop () {
    this.isStopped = true
  }

  @action.bound
  async run () {
    this.isFinished = false
    this.inProgress = true
    if (this.before) this.before()
    for (const task of this.iterable) {
      if (this.isStopped) break
      if (this.beforeEach) this.beforeEach(task)
      try {
        await this.processorFunc(task)
        this.processed += 1
      } catch (error) {
        this.onError(error)
        this.errors += 1
      }
      if (this.afterEach) this.afterEach(task)
    }
    if (this.after) this.after()
    this.inProgress = false
    this.isFinished = true
  }
}

export default AsyncBatchActionsStore
