import React from 'react'

import PropTypes from 'prop-types'

import { Button, Typography } from '@material-ui/core'
import { observer } from 'mobx-react'

import { Alert } from 'shared/components/molecules'
import { DataModal } from 'shared/components/organisms'
import { integrationsNames } from 'shared/constants/integrations'

import { OrderModalActionState } from 'retailer/stores'

import style from './MarkPaidModal.module.scss'
/**
 * @param store
 * @param rest
 * @returns {*}
 * @constructor
 */
const MarkPaidModal = ({ store, ...rest }) => {
  const integrationName = integrationsNames.get(store.order.orderType)
  return (
    <DataModal
      fullWidth
      className={style.MarkPaidModal}
      showSpinner={store.inProgress}
      title="Mark as paid"
      hide={store.done}
      renderBody={() => (
        <div className={style.ModalContent}>
          <Typography variant="body1" gutterBottom>
            Mark this order as paid
            if you have paid for this order on {integrationName}.
          </Typography>
          {store.error
            ? (
              <Alert severity="error">
                Error. Please try marking the order as paid again.
              </Alert>
              )
            : null}
        </div>
      )}
      renderActions={({ dismissModal, showSpinner }) => (
        <>
          <Button
            onClick={dismissModal}
            disabled={showSpinner}
            color="primary"
            size="large"
          >
            Cancel
          </Button>
          <Button
            onClick={() => store.markPaid()}
            disabled={showSpinner}
            color="primary"
            variant="contained"
            size="large"
          >
            Mark as paid
          </Button>
        </>
      )}
      {...rest}
    />
  )
}

MarkPaidModal.propTypes = {
  store: PropTypes.instanceOf(OrderModalActionState)
}

export default observer(MarkPaidModal)
