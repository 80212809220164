import React, { useEffect, useState } from 'react'

import PropTypes from 'prop-types'

import { Typography } from '@material-ui/core'
import debounce from 'lodash/debounce'
import isEqual from 'lodash/isEqual'
import { observer } from 'mobx-react'

import { ChipList } from 'shared/components/organisms'
import { useTranslation } from 'shared/hooks'

import style from './ActiveFilters.module.scss'

const FILTERS_CHIP_LIST_ID = 'syncListFiltersChipList'
const CHIP_CLASS_NAME = 'chipElement'

/**
 * Displays a list of active filters, using `<ChipList>` component. A bonus feature is that this component
 * automatically calculates how many chips will fit in a single line and wraps the remaining filters.
 *
 * @param {Object[]} filters  A list of objects representing active filters. At least provide an ID and a label.
 *                            and optionally an `onDelete` handler that will let users deactivate the filter.
 * @returns {JSX.Element}
 * @constructor
 */
const ActiveFilters = ({ filters, onClear }) => {
  const { t } = useTranslation('common')
  const [chipsCache, setChipsCache] = useState(filters)
  const [maxChips, setMaxChips] = useState(0)

  /**
   * Calculates how many chips will fit in one line, in the container with id `FILTERS_CHIP_LIST_ID`.
   * Takes into account the presence of additional "+X more" chip, as well as clear all button.
   *
   * One trick here is that we have to do setMaxChips(0) to briefly show all the chips in order to calculate
   * which will fit in, and which won't. Once we did the calculation, we then we can restore
   * the previous maxChips number and return the new number.
   * FIXME: We _could_ replace hardcoded widths with actual widths from DOM element for more precision,
   *        but it's not required for proper use.
   * FIXME: This does not work properly in some cases. My assumption is that between setMaxChips(0)
   *        and setMaxChips(maxChipsCache) there is not enough time for the browser to repaint the layout,
   *        or React does not update the DOM, thus calculations are off (not all chips are shown when calculating).
   * @returns {number}
   */
  const calculateMaxChips = () => {
    const maxChipsCache = maxChips
    setMaxChips(0)
    const containerWidth = document.getElementById(FILTERS_CHIP_LIST_ID)?.offsetWidth || 0
    const clearButtonWidth = 90
    const moreChipsIndicatorWidth = 90
    const maxWidth = containerWidth - clearButtonWidth - moreChipsIndicatorWidth
    let runningWidth = 0
    let chipsCount = 0
    const chips = document.getElementsByClassName(CHIP_CLASS_NAME)
    Array.from(chips).forEach(elem => {
      runningWidth = elem.offsetWidth + runningWidth
      if (runningWidth <= maxWidth) chipsCount += 1
    })
    setMaxChips(maxChipsCache)
    return Math.max(1, chipsCount)
  }

  useEffect(() => {
    if (!isEqual(filters, chipsCache)) {
      setChipsCache(filters)
      setMaxChips(calculateMaxChips())
    }
  }, [filters, chipsCache])

  /**
   * Calculate max chips once on initial page load, and recalculate it on window resize.
   * Uses 500ms debounce for a performance gain.
   */
  useEffect(() => {
    setMaxChips(calculateMaxChips())
    const debouncedCalculateMaxChips = debounce(() => setMaxChips(calculateMaxChips()), 500)
    window.addEventListener('resize', debouncedCalculateMaxChips)
    return () => window.removeEventListener('resize', debouncedCalculateMaxChips)
  }, [])

  return (
    <div className={style.ActiveFilters}>
      <Typography variant="body2">
        {t('activeFilters.label')}
      </Typography>
      <ChipList
        id={FILTERS_CHIP_LIST_ID}
        maxChips={maxChips}
        expandable
        onClear={onClear}
        className={style.ChipList}
        ChipProps={{ className: CHIP_CLASS_NAME }}
        chips={filters}
      />
    </div>
  )
}

ActiveFilters.propTypes = {
  filters: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    onDelete: PropTypes.func,
  })).isRequired,
  onClear: PropTypes.func
}

export default observer(ActiveFilters)
