import history from 'history/browser'
import queryString from 'query-string'

/**
 * A utility class for working with query params.
 * It allows to parse and update params.
 */
class QueryParamsOperator {
  query = {}

  /**
   * A default search string can be provided to init query.
   * @param {String|Object} query
   */
  constructor (query = null) {
    if (query && typeof query === 'string') this.setQueryFromSearch(query)
    if (query && typeof query === 'object') this.query = query
  }

  setQueryFromSearch (search) {
    this.query = {
      ...this.query,
      ...queryString.parse(search, { parseNumbers: true, parseBooleans: true })
    }
    this.updateUrl()
  }

  /**
   * @return {string}
   * @private
   */
  _stringify () {
    return queryString.stringify(this.query, {
      arrayFormat: 'separator',
      arrayFormatSeparator: ';',
      skipEmptyString: true,
      skipNull: true,
      sort: false,
      encode: true
    })
  }

  /**
   * Returns query as string ready to be injected into the url.
   * @returns {string}
   */
  getSearchAsText () {
    return this._stringify().replace(
      // For some reason query-string does not encode separators (;) and our API
      // cannot handle them in such form. Thus we manually replace ; with its encoded value.
      /;/g, encodeURIComponent(';')
    )
  }

  /**
   * Replaces the URL in the browser with current query
   */
  updateUrl () {
    const qs = this._stringify()
    const search = `?${qs}`
    if (history.location.search === search) return
    history.replace({
      pathname: window.location.pathname,
      search: search,
    })
  }

  /**
   * Set given query param to a value and update the URL.
   * @param {String} key
   * @param {*} value
   */
  set (key, value) {
    this.query = {
      ...this.query,
      [key]: value,
    }
    this.updateUrl()
  }
}

export default QueryParamsOperator
