import React from 'react'

import PropTypes from 'prop-types'

import { Grid, Link, Typography } from '@material-ui/core'
import groupBy from 'lodash/groupBy'
import { observer } from 'mobx-react'

import { CountryFlag } from 'shared/components/atoms'
import { ITEM_TABS_ID, TABS } from 'shared/constants/marketplaceItem'
import { useTranslation } from 'shared/hooks'
import { getIntegrationName } from 'shared/utils/integrations'

import { MarketplaceItemDataRow } from 'retailer/components/organisms'
import { ItemStore } from 'retailer/stores'

import style from './MarketplaceItemProcessingTimeData.module.scss'

/**
 * Displays a grid with shipping time on the left, and all countries with that shipping time
 * on the right. It will infer the shipping time from the first country in the array,
 * so ensure there's at least one there!
 */
const ShippingDataDisplay = observer(({ countries, t }) => {
  if (!countries || !countries.length) return null

  return (
    <Grid container spacing={3}>
      <Grid item xs={4} lg={6} xl={5}>
        {countries[0].shippable
          ? t('productData.shippingTimeToCountries.label', { time: countries[0].shippingTime })
          : t('productData.shippingUnavailableForCountries.label')}
      </Grid>
      <Grid item xs={8} lg={6} xl={7}>
        {countries.map(country => {
          return (
            <div key={country.countryCode} className={style.Country}>
              <CountryFlag isoCode={country.countryCode} className={style.Flag} /> {country.countryName}
            </div>
          )
        })}
      </Grid>
    </Grid>
  )
})

ShippingDataDisplay.propTypes = {
  countries: PropTypes.arrayOf(PropTypes.shape({
    shippable: PropTypes.bool,
    shippingTime: PropTypes.string,
    countryCode: PropTypes.string,
    countryName: PropTypes.string,
  })).isRequired,
  t: PropTypes.func.isRequired,
}

const MarketplaceItemProcessingTimeData = ({ marketplaceItemStore }) => {
  const { t } = useTranslation('productPage')
  const { item, selectTab } = marketplaceItemStore

  if (item.is3rdPartySupplier) {
    return (
      <div className={style.MarketplaceItemProcessingTimeData}>
        <MarketplaceItemDataRow
          title={t('productData.thirdPartyProcessingTime.title')}
          content={
            <Link href={item.externalUrl} target="_blank" rel="noopener noreferrer">
              {t('productData.thirdPartyProcessingTime.cta', { integrationName: getIntegrationName(item.inventoryType) })}
            </Link>
          }
        />
      </div>
    )
  }

  const groupedCountries = groupBy(
    item.shipping.countries.filter(
      country => country.countryCode !== item.shipping.selectedCountry,
    ),
    'shippingTime',
  )

  return (
    <div className={style.MarketplaceItemProcessingTimeData}>
      <MarketplaceItemDataRow
        title={t('productData.processingTime.title')}
        content={
          <>
            {item.designer.leadTimes}
            {item.designer.additionalShippingInfo && (
              <Typography variant="body2" className={style.AdditionalShippingInfo}>
                {item.designer.additionalShippingInfo}
              </Typography>
            )}
          </>
        }
      />
      <MarketplaceItemDataRow
        title={t('productData.shippingTime.title')}
        content={
          <>
            <ShippingDataDisplay countries={[item.shipping.activeCountry]} t={t} />
            {Object.keys(groupedCountries).map(groupKey => (
              <ShippingDataDisplay
                key={groupKey}
                countries={groupedCountries[groupKey]}
                t={t}
              />
            ))}
          </>
        }
      />
      <MarketplaceItemDataRow
        title=" "
        content={
          <Link
            href={`#${ITEM_TABS_ID}`}
            onClick={() => selectTab(TABS.SHIPPING)}
          >
            {t('productData.shippingToOtherDestinations.cta')}
          </Link>
        }
      />
    </div>
  )
}

MarketplaceItemProcessingTimeData.propTypes = {
  marketplaceItemStore: PropTypes.shape({
    selectTab: PropTypes.func.isRequired,
    item: PropTypes.instanceOf(ItemStore).isRequired,
  }).isRequired,
}

export default observer(MarketplaceItemProcessingTimeData)
