import React, { useLayoutEffect, useRef, useState } from 'react'

import PropTypes from 'prop-types'

import { Box, Typography } from '@material-ui/core'
import clsx from 'clsx'
import { observer } from 'mobx-react'

import { COLOR_NAME_UNKNOWN } from 'shared/constants/marketplace'
import { useTranslation } from 'shared/hooks'

import style from './ColorSwatches.module.scss'

const ColorSwatch = ({ value }) => {
  const className = clsx(style.ColorSwatch, value === COLOR_NAME_UNKNOWN && style.isUnknown)
  return <span className={className} style={{ backgroundColor: value }} />
}

ColorSwatch.propTypes = {
  value: PropTypes.string.isRequired,
}

/**
 * Displays a single row of color swatches as defined in `colors`.
 * If the swatches do not fit, shows "+x more" text.
 */
const ColorSwatches = ({ colors, size, className, ...boxProps }) => {
  const { t } = useTranslation('marketplace')
  const isSmall = size === 'small'
  const _className = clsx(style.ColorSwatches, isSmall && style.isSmall, className)
  const swatchSize = isSmall ? (16 + 4) : (24 + 4)
  const moreSize = isSmall ? 56 : 64
  const values = colors || []
  const [maxSwatches, setMaxSwatches] = useState(values.length)
  const containerRef = useRef()
  useLayoutEffect(() => {
    if (containerRef.current) {
      const measure = () => {
        const availableSpace = containerRef.current.offsetWidth
        const maxSwatches = (values.length * swatchSize) > availableSpace
          ? Math.floor((availableSpace - moreSize) / swatchSize)
          : values.length
        setMaxSwatches(maxSwatches)
      }
      window.addEventListener('resize', measure)
      measure()
      return () => window.removeEventListener('resize', measure)
    }
  }, [values])
  return (
    <Box
      ref={containerRef}
      className={_className}
      display="flex" flexWrap="wrap" alignItems="center"
      {...boxProps}
    >
      {values.slice(0, maxSwatches).map((val, i) => <ColorSwatch key={i} value={val} />)}
      {(maxSwatches < values.length) && (
        <Typography variant="inherit" color="textSecondary" noWrap>
          {t('productColorSwatches.more.counter', { count: values.length - maxSwatches })}
        </Typography>
      )}
    </Box>
  )
}

ColorSwatches.propTypes = {
  colors: PropTypes.arrayOf(PropTypes.string),
  size: PropTypes.oneOf(['small', 'medium']),
  ...Box.propTypes,
}

ColorSwatches.defaultProps = {
  size: 'medium',
}

export default observer(ColorSwatches)
