import { action, computed, observable } from 'mobx'

class PaginationStore {
  @observable currentPage
  @observable pageSize
  @observable totalCount

  constructor (
    currentPage = 1,
    totalElements = 1,
    pageSize = 1,
  ) {
    this.currentPage = currentPage
    this.totalCount = totalElements
    this.pageSize = pageSize
  }

  @computed get totalPages () {
    return Math.ceil(this.totalCount / this.pageSize) || 1
  }

  @computed get hasPreviousPage () {
    return this.currentPage > 1
  }

  @computed get hasNextPage () {
    return this.currentPage < this.totalPages
  }

  @computed get params () {
    return {
      page: this.currentPage,
      pageSize: this.pageSize
    }
  }

  @action.bound
  resetPagination (page = 1) {
    this.currentPage = page
    this.totalCount = 0
  }

  @action.bound
  setTotalCount (count) {
    this.totalCount = count
  }

  @action.bound
  setPage (page) {
    this.currentPage = page
  }

  @action.bound
  reset () {
    this.currentPage = 1
  }

  @action.bound
  next () {
    this.setPage(this.currentPage + 1)
  }

  @action.bound
  previous () {
    this.setPage(this.currentPage - 1)
  }
}

export default PaginationStore
