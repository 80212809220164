import PropTypes from 'prop-types'

const refType = PropTypes.oneOfType([
  PropTypes.func,
  PropTypes.shape({ current: PropTypes.any })
])

const filtersPanelType = PropTypes.shape({
  open: PropTypes.bool,
  title: PropTypes.string,
  filtersCount: PropTypes.number,
  onSave: PropTypes.func,
  onClear: PropTypes.func,
  onClose: PropTypes.func.isRequired,
  children: PropTypes.node
})

const pricingRangeType = PropTypes.shape({
  active: PropTypes.bool,
  name: PropTypes.string.isRequired,
  from: PropTypes.number,
  to: PropTypes.number
})

const imageType = PropTypes.shape({
  id: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  thumbnail: PropTypes.string
})

const retailerItemImageType = PropTypes.shape({
  id: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  thumbnail: PropTypes.string,
  isMain: PropTypes.bool,
  selected: PropTypes.bool,
  setSelected: PropTypes.func.isRequired,
  setMain: PropTypes.func.isRequired,
})

const syncStatusBadgeType = PropTypes.shape({
  code: PropTypes.string,
  explanation: PropTypes.string,
  status: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  isResyncAllowed: PropTypes.bool
})

const loadingType = PropTypes.shape({
  isLoading: PropTypes.bool,
  isLoaded: PropTypes.bool
})

const importListItemType = PropTypes.shape({
  available: PropTypes.bool,
  quantity: PropTypes.number,
  isPrintOnDemand: PropTypes.bool,
  is3rdPartySupplier: PropTypes.bool,
  name: PropTypes.string,
  vendor: PropTypes.string,
  description: PropTypes.string,
  mainImage: imageType,
  selected: PropTypes.bool,
  setSelected: PropTypes.func,
  currentTab: PropTypes.string,
  changeTab: PropTypes.func,
  variants: PropTypes.arrayOf(PropTypes.object),
  variantsLoaded: PropTypes.bool,
  options: PropTypes.arrayOf(PropTypes.object),
  uniqueOptionNames: PropTypes.arrayOf(PropTypes.object),
  uniqueOptionValues: PropTypes.arrayOf(PropTypes.object),
  export: PropTypes.func,
  remove: PropTypes.func,
  currencyIso: PropTypes.string,
  pricingRanges: PropTypes.objectOf(pricingRangeType),
  shippingStore: PropTypes.object,
  logViewDetailsClickedBiEvent: PropTypes.func,
  logDesignClickedBiEvent: PropTypes.func,
})

export {
  refType,
  filtersPanelType,
  pricingRangeType,
  imageType,
  retailerItemImageType,
  syncStatusBadgeType,
  loadingType,
  importListItemType
}
