import { api } from './common'
const baseUrl = '/api/v3/products'

function getProduct (id) {
  return api.get(`${baseUrl}/${id}/`)
}

function getProducts (params = {}) {
  return api.get(`${baseUrl}/`, { params })
}

function putProduct (id, data) {
  return api.put(`${baseUrl}/${id}/`, data)
}

function postProduct (data) {
  return api.post(`${baseUrl}/`, data)
}

function deleteProduct (id) {
  return api.delete(`${baseUrl}/${id}/`)
}

function setProductsCategory (data, params = {}) {
  return api.post(`${baseUrl}/set_category/`, data, { params })
}

function setProductsActive (data, params = {}) {
  return api.post(`${baseUrl}/set_active/`, data, { params })
}

function postResyncProducts (data, params = {}) {
  return api.post(`${baseUrl}/resync/`, data, { params })
}

function postUnsyncProducts (data, params = {}) {
  return api.post(`${baseUrl}/unsync/`, data, { params })
}

function getProductsExportUrl (params = {}) {
  const url = `${baseUrl}/export/`
  return api.getUri({ url, params })
}

function postAsset (data) {
  return api.post('/api/v3/supplier/assets/', data)
}

function getProductCounters () {
  return api.get(`${baseUrl}/counters/`)
}

export {
  getProduct,
  getProducts,
  putProduct,
  postProduct,
  deleteProduct,
  setProductsCategory,
  setProductsActive,
  postResyncProducts,
  postUnsyncProducts,
  getProductsExportUrl,
  postAsset,
  getProductCounters,
}
