import React, { useEffect } from 'react'

import PropTypes from 'prop-types'

import {
  Button,
  Dialog,
  Fade,
  LinearProgress,
  Typography,
} from '@material-ui/core'
import { observer } from 'mobx-react'
import { useSnackbar } from 'notistack'
import { Link as RouterLink } from 'react-router-dom'

import { Alert } from 'shared/components/molecules'
import { MARKETPLACE_CODE_PRINT_ON_DEMAND } from 'shared/constants/marketplace'
import { RETAILER_ITEM_DRAFT } from 'shared/constants/retailerItems'

import { useStores } from 'retailer/stores/hooks'

import style from './ItemCustomizerModal.module.scss'

const ItemCustomizerModal = ({ onExited }) => {
  const { itemCustomizerStore } = useStores()
  const {
    productId,
    iframeUrl,
    savedAs,
    handleEvent,
  } = itemCustomizerStore

  const { enqueueSnackbar } = useSnackbar()

  const handleOnClose = (_event, reason) => {
    if (['backdropClick', 'escapeKeyDown'].includes(reason)) return
    itemCustomizerStore.hide()
  }

  /**
   * Runs when the modal finished closing.
   */
  const handleOnExited = () => {
    itemCustomizerStore.reset()
    onExited && onExited()
  }

  useEffect(() => {
    window.addEventListener('message', handleEvent)
    return () => {
      window.removeEventListener('message', handleEvent)
      itemCustomizerStore.reset()
    }
  }, [])

  useEffect(() => {
    if (savedAs) {
      const isDraft = savedAs === RETAILER_ITEM_DRAFT
      const actionPath = isDraft
        ? `/marketplace/${MARKETPLACE_CODE_PRINT_ON_DEMAND}/${productId}/drafts`
        : '/my-products/import-list'
      const actionLabel = isDraft ? 'Go to product page' : 'Go to Import List'
      enqueueSnackbar(
        (isDraft
          ? 'Your project has been successfully saved as a draft.'
          : 'Your customized product has been successfully saved.'),
        {
          key: 'pod-design-saved',
          variant: 'success',
          autoHideDuration: 3000,
          action: key => (
            <Button variant="outlined" component={RouterLink} to={actionPath}>
              {actionLabel}
            </Button>
          )
        }
      )
    }
  }, [savedAs])

  const editorVisible = itemCustomizerStore.productId && !itemCustomizerStore.error
  const statusVisible = !itemCustomizerStore.isLoaded || itemCustomizerStore.isSaving || itemCustomizerStore.error

  return (
    <Dialog
      fullScreen
      disableEscapeKeyDown
      onClose={handleOnClose}
      TransitionProps={{ onExited: handleOnExited }}
      open={itemCustomizerStore.open}
      className={style.ItemCustomizerModal}
    >
      {editorVisible && (
        <iframe
          className={style.Editor}
          src={iframeUrl}
          data-testid="ItemCustomizerModal-iframe"
        />
      )}
      {statusVisible && (
        <Fade in={statusVisible}>
          <div className={style.Wrapper}>
            {(itemCustomizerStore.isSaving || !itemCustomizerStore.isLoaded) && (
              <LinearProgress variant="indeterminate" className={style.Loader} />
            )}
            {itemCustomizerStore.error && (
              <Alert title="Something went wrong" severity="error" className={style.Alert}>
                <Typography>{itemCustomizerStore.error}</Typography>
              </Alert>
            )}
          </div>
        </Fade>
      )}
    </Dialog>
  )
}

ItemCustomizerModal.propTypes = {
  onExited: PropTypes.func,
}

export default observer(ItemCustomizerModal)
