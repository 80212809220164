import pick from 'lodash/pick'
import { action, computed, reaction } from 'mobx'
import qs from 'query-string'

import { BaseListStore } from 'shared/stores'

import { adaptGetRetailersResponse } from 'supplier/adapters/retailers'
import { getRetailers, postSetAccepted } from 'supplier/api/retailers'
import { RETAILER_FILTER_ALL, RETAILER_ORDERING_ORDERS_VALUE } from 'supplier/constants/retailers'
import { RetailerStore } from 'supplier/stores'

class RetailerListStore extends BaseListStore {
  /** @type {import('../root').RootStore} */
  root
  pathnameRegex = /^\/retailers$/

  listParamsGetter = () => pick(this, ['filter', 'search', 'ordering', 'offset'])

  /**
   * @param {import('../root').RootStore} root
   */
  constructor (root, data = {}) {
    super(root.routerStore, data)
    this.root = root
    reaction(this.listParamsGetter, this.listParamsReaction)
  }

  @computed
  get filter () {
    const params = new URLSearchParams(this.root.routerStore?.location.search)
    return params.get('filter') || RETAILER_FILTER_ALL
  }

  @computed
  get search () {
    const params = new URLSearchParams(this.root.routerStore?.location.search)
    return params.get('search') || undefined
  }

  @computed
  get ordering () {
    // TODO: sanitize as per allowed values
    const params = new URLSearchParams(this.root.routerStore?.location.search)
    return params.get('ordering') || RETAILER_ORDERING_ORDERS_VALUE
  }

  @action.bound
  setFilters (filter, search, ordering) {
    this.root.routerStore.push(this.getUrl({ filter, search, ordering, offset: 0 })) // offset reset added for verbosity
  }

  getUrl (params) {
    const { filter, search, ordering, offset } = params
    const urlSearchParams = qs.stringify({
      filter: filter === RETAILER_FILTER_ALL ? undefined : filter,
      search,
      ordering: ordering === RETAILER_ORDERING_ORDERS_VALUE ? undefined : ordering,
      offset: offset || undefined
    })
    return '/retailers' + (urlSearchParams ? `?${urlSearchParams}` : '')
  }

  doFetch () {
    return getRetailers(pick(this, ['filter', 'search', 'offset', 'ordering']))
      .then(response => {
        const results = adaptGetRetailersResponse(response.data).results
        this.setItems(results.map(row => new RetailerStore(row)))
        return response
      })
  }

  /** Params that make up the current collection (across all pages) */
  @computed get collectionParams () {
    return pick(this, ['filter', 'search'])
  }

  @action.bound
  setRetailersAccepted (retailers, value) {
    const payload = { value, objects: retailers.map(retailer => retailer.uuid) }
    return this.performBulkAction(postSetAccepted(payload, this.collectionParams))
  }
}

export default RetailerListStore
