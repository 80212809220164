import React, { useEffect, useState } from 'react'

import { observer } from 'mobx-react'
import { Helmet } from 'react-helmet'

import { ReactAppWrapper, ScrollResetter } from 'shared/components/atoms'

import { Sidebar } from 'retailer/components/organisms'
import { SidebarLeftTemplate } from 'retailer/components/templates'
import { useStores } from 'retailer/stores/hooks'

const InboxPage = () => {
  const [src, setSrc] = useState('/mail/inbox/')
  const { routerStore } = useStores()
  const writeTo = routerStore.getSearchParamValue('writeTo')

  useEffect(() => {
    const supplierId = parseInt(writeTo)
    if (supplierId) {
      setSrc(`/mail/write/${supplierId}/`)
    }
  }, [writeTo])

  return (
    <>
      <Helmet>
        <title>Inbox</title>
      </Helmet>
      <ScrollResetter />
      <SidebarLeftTemplate
        sidebar={<Sidebar />}
        main={<ReactAppWrapper src={src} />}
        disableGutters
      />
    </>
  )
}

export default observer(InboxPage)
