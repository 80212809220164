import React from 'react'

import PropTypes from 'prop-types'

import { TextField } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { observer } from 'mobx-react'
import { Controller } from 'react-hook-form'

/**
 * An attempt at making a fairly reusable Autocomplete field that can be connected to a React Hook Form.
 * Depending on the feature set you want, there might be quite some configuration needed.
 *
 * Note that you can pass on a custom `onChange` ASYNCHRONOUS function in order to perform some
 * action BEFORE the actual onChange happens. You can use that to create new option asynchronously, etc.
 * @returns {JSX.Element}
 * @constructor
 */
const RhfAutocomplete = ({ name, label, options, control, onChange, ControllerProps, AutocompleteProps, TextFieldProps, testId }) => {
  return (
    <Controller
      name={name}
      control={control}
      {...ControllerProps}
      render={({ onChange: renderOnChange, ...props }) => (
        <Autocomplete
          ListboxProps={{ 'data-testid': testId ? `${testId}-autocomplete` : undefined }}
          {...props}
          onChange={async (_, data) => {
            if (onChange) await onChange(renderOnChange, data)
            else renderOnChange(data)
          }}
          options={options}
          {...AutocompleteProps}
          renderInput={params => (
            <TextField
              {...params}
              label={label}
              data-testid={testId}
              {...TextFieldProps}
              InputLabelProps={{
                ...(TextFieldProps?.InputLabelProps || {})
              }}
              inputProps={{
                ...params.inputProps,
                ...(TextFieldProps?.inputProps || {}),
                autoComplete: 'disabled',
              }}
            />
          )}
        />
      )}
    />
  )
}

RhfAutocomplete.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  testId: PropTypes.string,
  options: PropTypes.array,
  control: PropTypes.any.isRequired,
  onChange: PropTypes.func,
  ControllerProps: PropTypes.object,
  AutocompleteProps: PropTypes.object,
  TextFieldProps: PropTypes.shape({
    InputLabelProps: PropTypes.object,
    InputProps: PropTypes.object,
    inputProps: PropTypes.object,
  })
}

RhfAutocomplete.defaultProps = {
  options: [],
  ControllerProps: {},
  AutocompleteProps: {},
  TextFieldProps: {
    InputLabelProps: {},
    InputProps: {},
    inputProps: {},
  }
}

export default observer(RhfAutocomplete)
