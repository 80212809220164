import React, { useEffect, useRef, useState } from 'react'

import PropTypes from 'prop-types'

import { yupResolver } from '@hookform/resolvers/yup'
import { Checkbox, FormControlLabel, FormHelperText, Grid, TextField } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { observer } from 'mobx-react'
import { useSnackbar } from 'notistack'
import { Controller, useForm } from 'react-hook-form'
import * as yup from 'yup'

import { CountrySelect } from 'shared/components/molecules'
import { CardForm } from 'shared/components/organisms'
import { getFieldErrorMessage, useRemoteErrors } from 'shared/utils/forms'

import { ShippingInformationStore } from 'retailer/stores'

import style from './ShippingInformationForm.module.scss'

const commonInputProps = {
  variant: 'outlined',
  fullWidth: true
}

/**
 * DRY helper for fields that are only required if sameAsBusinessInfo is set to false.
 * Provided schema will be validated only if the mentioned toggle is false.
 * @param {yup} schema Validation schema
 */
const conditionallyRequired = (schema) => (
  yup.string().when('sameAsBusinessInfo', {
    is: true,
    then: yup.string().nullable(),
    otherwise: schema
  })
)

const validationSchema = yup.object().shape({
  sameAsBusinessInfo: yup.boolean().required(),
  contactName: conditionallyRequired(
    yup.string()
      .required('Contact name is required')
      .min(2, 'Contact name must be at least 2 characters long')
      .max(100, 'Contact name cannot be longer than 100 characters')
  ),
  organizationName: conditionallyRequired(
    yup.string()
      .max(100, 'Organization name cannot be longer than 100 characters')
  ),
  address: conditionallyRequired(
    yup.string()
      .required('Address is required')
      .min(2, 'Address must be at least 2 characters long')
      .max(100, 'Address cannot be longer than 100 characters')
  ),
  zipCode: conditionallyRequired(
    yup.string()
      .required('Zip code is required')
      .min(2, 'Zip code must be at least 2 characters long')
      .max(100, 'Zip code cannot be longer than 100 characters')
  ),
  city: conditionallyRequired(
    yup.string()
      .required('City is required')
      .min(2, 'City must be at least 2 characters long')
      .max(100, 'City cannot be longer than 100 characters')
  ),
  state: conditionallyRequired(
    yup.string()
      .max(100, 'State cannot be longer than 100 characters')
  ),
  country: conditionallyRequired(
    yup.string()
      .min(2, 'Invalid value provided')
      .max(2, 'Invalid value provided')
      .required('Country is required')
  ),
  phoneNumber: conditionallyRequired(
    yup.string()
      .required('Phone number is required')
      .matches(/^(\+)?([\d\s-])*\d$/, 'Provided phone number is invalid')
      .max(100, 'Phone number cannot be longer than 100 characters')
  )
})

const ShippingInformationForm = ({ isUnlocked, store }) => {
  const getDefaultValues = () => ({
    sameAsBusinessInfo: store.sameAsBusinessInfo,
    contactName: store.contactName,
    organizationName: store.organizationName,
    address: store.address,
    zipCode: store.zipCode,
    city: store.city,
    state: store.state,
    country: store.country,
    phoneNumber: store.phoneNumber
  })
  const { enqueueSnackbar } = useSnackbar()
  const formRef = useRef(null)
  // DRF handling of uncontrolled checkboxes (especially the MUI ones) is really wanky, thus
  // the state of this one is stored in the state and controlled manually.
  const [sameAsBusinessInfo, setSameAsBusinessInfo] = useState(getDefaultValues().sameAsBusinessInfo)
  const { register, control, watch, handleSubmit, formState, errors, setError, reset } = useForm({
    defaultValues: getDefaultValues(),
    resolver: yupResolver(validationSchema)
  })

  const { nonFieldErrors } = useRemoteErrors(store.errors, setError)

  // Sometimes we refresh this form programmatically, especially when it is set to mirror
  // the business information form. In this case we update `lastUpdate` field whenever we do an update.
  // This effect will ensure to re-set the form to these initial data, unless it's already dirty, because
  // we don't want to change a form that is already being edited by the user.
  useEffect(() => {
    if (!formState.isDirty) reset(getDefaultValues())
  }, [store.lastUpdate, formState.isDirty])

  // Whether a form has changed is complicated here. If it was set to mirror user profile, and then is unchecked,
  // then the values did not change per se, but the overall form is considered changed.
  const formChanged = (() => {
    if (watch().sameAsBusinessInfo && getDefaultValues().sameAsBusinessInfo) return false
    return (
      formState.isDirty &&
      !!Object.keys(formState.dirtyFields).length &&
      watch() !== getDefaultValues()
    )
  })()

  const onSubmit = data => {
    return store.save(data).then(() => {
      onReset()
      enqueueSnackbar('Shipping information saved successfully', {
        variant: 'success'
      })
    })
  }

  const onReset = () => {
    reset(getDefaultValues())
    setSameAsBusinessInfo(store.sameAsBusinessInfo)
  }

  return (
    <CardForm
      className={style.ShippingInformationForm}
      title="Shipping Information"
      subheader="Add the address where you would like orders sent when you order a sample"
      formRef={formRef}
      canSubmit={formChanged && !Object.keys(errors).length}
      isSubmitting={store.isSaving}
      onDiscard={formChanged ? onReset : null}
    >
      <Grid
        container
        spacing={2}
        component="form"
        ref={formRef}
        onSubmit={handleSubmit(onSubmit)}
      >
        {nonFieldErrors && (
          <Grid item xs={12}>
            <Alert severity="error" className={style.Errors}>
              {nonFieldErrors.join(' ')}
            </Alert>
          </Grid>
        )}
        <Grid item xs={12}>
          <FormControlLabel
            control={<Checkbox />}
            id="sameAsBusiness" name="sameAsBusinessInfo" label="The same as business information"
            disabled={!isUnlocked} inputRef={register} checked={sameAsBusinessInfo}
            onChange={e => setSameAsBusinessInfo(e.target.checked)}
          />
          {!isUnlocked && (
            <FormHelperText>
              Please, fill in your Business information first before changing the shipping information
            </FormHelperText>
          )}
        </Grid>
        {(!sameAsBusinessInfo && isUnlocked) && (
          <>
            <Grid item xs={12}>
              <TextField
                id="contactName" name="contactName" label="Contact name"
                required inputRef={register({ required: true })} {...commonInputProps}
                error={!!errors.contactName} helperText={getFieldErrorMessage(errors, 'contactName')}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="organizationName" name="organizationName" label="Organization name (optional)"
                inputRef={register} {...commonInputProps}
                error={!!errors.organizationName} helperText={getFieldErrorMessage(errors, 'organizationName')}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="address" name="address" label="Address"
                required inputRef={register({ required: true })} {...commonInputProps}
                error={!!errors.address} helperText={getFieldErrorMessage(errors, 'address')}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                id="zipCode" name="zipCode" label="Zip code"
                required inputRef={register({ required: true })} {...commonInputProps}
                error={!!errors.zipCode} helperText={getFieldErrorMessage(errors, 'zipCode')}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                id="city" name="city" label="City"
                required inputRef={register({ required: true })} {...commonInputProps}
                error={!!errors.city} helperText={getFieldErrorMessage(errors, 'city')}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                id="state" name="state" label="State (optional)"
                inputRef={register} {...commonInputProps}
                error={!!errors.state} helperText={getFieldErrorMessage(errors, 'state')}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                {...commonInputProps}
                name="country"
                control={control}
                label="Country"
                error={!!errors.country}
                helperText={getFieldErrorMessage(errors, 'country')}
                required
                as={<CountrySelect />}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="phoneNumber" name="phoneNumber" label="Phone number"
                required inputRef={register({ required: true })} {...commonInputProps}
                error={!!errors.phoneNumber} helperText={getFieldErrorMessage(errors, 'phoneNumber')}
              />
            </Grid>
          </>
        )}
      </Grid>
    </CardForm>
  )
}

ShippingInformationForm.propTypes = {
  isUnlocked: PropTypes.bool,
  store: PropTypes.instanceOf(ShippingInformationStore).isRequired
}

ShippingInformationForm.defaultProps = {
  isUnlocked: false
}

export default observer(ShippingInformationForm)
