import { reaction } from 'mobx'
import qs from 'query-string'

import { adaptGetImportsResponse } from 'shared/adapters/imports'
import { getImports } from 'shared/api/imports'
import { BaseListStore } from 'shared/stores'

import { ImportStore } from 'supplier/stores'

class ImportListStore extends BaseListStore {
  /** @type {import('../root').RootStore} */
  root
  pathnameRegex = /^\/import-reports$/

  /**
   * @param {import('../root').RootStore} root
   */
  constructor (root, data = {}) {
    super(root.routerStore, data)
    this.root = root
    reaction(this.listParamsGetter, this.listParamsReaction)
  }

  getUrl (params) {
    const search = qs.stringify({ offset: params.offset || undefined })
    return '/import-reports' + (search ? `?${search}` : '')
  }

  doFetch () {
    const { offset } = this
    return getImports({ offset })
      .then(response => {
        const results = adaptGetImportsResponse(response.data).results
        this.setItems(results.map(row => new ImportStore(row)))
        return response
      })
  }
}

export default ImportListStore
