const OFAC_LIST = [
  'CU',
  'IR',
  'KP',
  'RU',
  'SY',
]

export {
  OFAC_LIST,
}
