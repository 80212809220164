import React from 'react'

import PropTypes from 'prop-types'

import { Card, CardContent, Grid, Link, Typography } from '@material-ui/core'
import clsx from 'clsx'
import { observer } from 'mobx-react'
import { Trans } from 'react-i18next'
import { Link as RouterLink } from 'react-router-dom'

import { Money } from 'shared/components/atoms'
import { MoneyRange, TooltipHelper } from 'shared/components/molecules'
import { useTranslation } from 'shared/hooks'

import { RetailerPricingRuleHelper } from 'retailer/components/organisms'
import CustomPropTypes from 'retailer/propTypes'
import { ItemStore, RetailerPricingRuleStore } from 'retailer/stores'
import { useIsDesktop } from 'retailer/theme/hooks'

import style from './MarketplaceItemPricing.module.scss'

const Profit = observer(({ item, testId }) => {
  const { t } = useTranslation('productPage')
  return (
    <Cell
      title={t('pricing.profit.label')}
      helperContent={item.hasNegativeProfit && t('pricing.negativeProfitWarning')}
    >
      <MoneyRange
        currency={item.currency}
        amountMin={item.minProfit}
        amountMax={item.maxProfit}
        testId={testId ? `${testId}-profit` : undefined}
        className={clsx(style.PriceTag, item.maxProfit > 0 && style.positive,
          item.maxProfit > 0 && item.minProfit < 0 && style.neutral,
          item.maxProfit < 0 && style.negative)}
        useRounding
        nowrap
      />
    </Cell>
  )
})

Profit.propTypes = {
  item: PropTypes.shape({
    hasNegativeProfit: PropTypes.bool,
    currency: PropTypes.string,
    minProfit: CustomPropTypes.moneyAmount,
    maxProfit: CustomPropTypes.moneyAmount,
  }).isRequired,
  testId: PropTypes.string,
}

const ShippingCost = observer(({ item, testId }) => {
  const { t } = useTranslation('productPage')
  const title = t('pricing.shippingRate.label')

  if (item.is3rdPartySupplier) {
    return (
      <Cell
        title={title}
        helperContent={
          <>
            <p>
              <Trans
                t={t} i18nKey="shippingCost.rateAutomaticallySetWarning"
                values={{ supplierName: item.designer.name }}
                components={{
                  money: <Money currency={item.retailerCurrency} amount={0} testId={testId} />
                }}
              />
            </p>
            <p>{t('shippingCost.shippingSelectionInfo')}</p>
          </>
        }
      >
        <Money
          amount={0}
          currency={item.retailerCurrency}
          testId={testId}
          className={style.PriceTag}
          useRounding
        />
      </Cell>
    )
  }

  if (!item.shipping.activeCountry.shippable) {
    return (
      <Cell title={title}>{t('shippingCost.doesNotShipToYourCountryWarning')}</Cell>
    )
  }

  return (
    <Cell title={title}>
      <Money
        amount={item.shipping.activeCountry.shippingCost}
        currency={item.retailerCurrency}
        testId={testId}
        className={style.PriceTag}
        useRounding
      />
    </Cell>
  )
})

ShippingCost.propTypes = {
  item: PropTypes.instanceOf(ItemStore).isRequired,
  testId: PropTypes.string,
}

const Cell = ({ title, helperContent, helperColor, ...props }) => {
  const isDesktop = useIsDesktop()
  return (
    <div className={style.Cell}>
      <Typography component="h5" variant="body2" color="textSecondary" gutterBottom>
        {title}
        {isDesktop && helperContent && (
          <TooltipHelper
            content={helperContent}
            color={helperColor}
            className={style.TooltipHelper}
          />
        )}
      </Typography>
      <Typography component="div" variant="body1" {...props} />
      {!isDesktop && helperContent && (
        <Typography
          component="div" className={style.HelperContent}
          variant="body2" color={helperColor || 'textSecondary'}
        >
          {helperContent}
        </Typography>
      )}
    </div>
  )
}

Cell.propTypes = {
  title: CustomPropTypes.nodes.isRequired,
  helperContent: CustomPropTypes.nodes,
  helperColor: PropTypes.string,
}

const MarketplaceItemPricing = ({ item, pricingRule, testId }) => {
  const { t } = useTranslation('productPage')
  return (
    <Card className={style.MarketplaceItemPricing}>
      <CardContent>
        <Grid container spacing={3}>

          <Grid item xs={12} sm={6}>
            <Cell title={t('pricing.productCost.label')}>
              <MoneyRange
                currency={item.currency}
                amountMin={item.minCost}
                amountMax={item.maxCost}
                testId={testId ? `${testId}-productCost` : undefined}
                className={clsx(style.PriceTag, style.cost)}
                useRounding
                nowrap
              />
            </Cell>
          </Grid>

          <Grid item xs={12} sm={6}>
            <ShippingCost item={item} testId={testId ? `${testId}-shippingCost` : undefined} />
          </Grid>

          <Grid item xs={12} sm={6}>
            <Cell
              title={t('pricing.retailPrice.label')}
              helperContent={<RetailerPricingRuleHelper pricingRule={pricingRule} />}
              helperColor={pricingRule ? undefined : 'error'}
            >
              <MoneyRange
                currency={item.currency}
                amountMin={item.minRetailPrice}
                amountMax={item.maxRetailPrice}
                testId={testId ? `${testId}-retailPrice` : undefined}
                className={style.PriceTag}
                useRounding
                nowrap
              />
            </Cell>
          </Grid>

          <Grid item xs={12} sm={6} className={clsx(item.isCustomizable && style.Centered)}>
            {item.isCustomizable
              ? (
                <Link component={RouterLink} to="/my-products/pricing-rules">
                  {t('pricing.pricingRulesLink.label')}
                </Link>
                )
              : <Profit item={item} testId={testId} />}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}

MarketplaceItemPricing.propTypes = {
  item: PropTypes.instanceOf(ItemStore).isRequired,
  pricingRule: PropTypes.instanceOf(RetailerPricingRuleStore),
  testId: PropTypes.string,
}

export default observer(MarketplaceItemPricing)
