import React from 'react'

import PropTypes from 'prop-types'

import { Typography } from '@material-ui/core'
import { observer } from 'mobx-react'

import { RetailerOrder } from 'retailer/components/organisms'
import { RetailerOrder as RetailerOrderStore } from 'retailer/stores'

import style from './RetailerOrdersList.module.scss'

const RetailerOrdersList = ({ orders }) => {
  return (
    <div className={style.RetailerOrdersList} data-testid="RetailerOrdersList">
      {(!orders || !orders.length) && (
        <Typography variant="subtitle2" align="center">
          There are no orders matching your criteria.
        </Typography>
      )}
      {orders.map(order => <RetailerOrder key={order.uuid} store={order} />)}
    </div>
  )
}

RetailerOrdersList.propTypes = {
  orders: PropTypes.arrayOf(
    PropTypes.instanceOf(RetailerOrderStore),
  ).isRequired,
}

RetailerOrdersList.defaultProps = {
  orders: [],
}

export default observer(RetailerOrdersList)
