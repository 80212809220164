import React from 'react'

import PropTypes from 'prop-types'

import { Checkbox, FormControlLabel, FormGroup } from '@material-ui/core'
import { observer } from 'mobx-react'
import { Controller } from 'react-hook-form'

import style from './MultiCheckboxWithRhf.module.scss'

/**
 * A wrapper over RHF Controller and MUI's FormControlLabel/Checkbox. It is not very effective
 * and DRY to make these two work together, so this helper comes in handy not to repeat code.
 *
 * @param {string}    name      Input `name` param (will be used as the key for submit data).
 * @param {Object}    control   RHF's `control` object (obtained from `useForm` hook).
 * @param {function}  getValues RHF's `getValues` function (obtained from `useForm` hook).
 * @param {Map}       options   A map to render checkboxes, where the key will be used as the value of the checkbox,
 *                              and the label will be displayed alongside the checkbox.
 * @param {function}  labelRenderFunc A function that receives the original label value
 *                                    and should return a value to display as a label.
 * @returns {JSX.Element}
 * @constructor
 */
const MultiCheckboxWithRhf = ({ name, control, getValues, options, labelRenderFunc }) => {
  return (
    <FormGroup className={style.MultiCheckboxWithRhf}>
      <Controller
        name={name}
        control={control}
        render={({ onChange, value }) => {
          const handleCheck = checkedValue => {
            const formValues = getValues()[name]
            return formValues?.includes(checkedValue)
              ? formValues?.filter(formValue => formValue !== checkedValue)
              : [...(formValues ?? []), checkedValue]
          }
          return Array.from(options).map(([key, label]) => {
            return (
              <FormControlLabel
                key={key}
                label={labelRenderFunc(label)}
                control={
                  <Checkbox
                    data-testid={'checkbox.' + key}
                    checked={value.includes(key) ?? false}
                    onChange={() => onChange(handleCheck(key))}
                  />
                }
              />
            )
          })
        }}
      />
    </FormGroup>
  )
}

MultiCheckboxWithRhf.propTypes = {
  name: PropTypes.string.isRequired,
  control: PropTypes.object.isRequired,
  getValues: PropTypes.func.isRequired,
  options: PropTypes.instanceOf(Map).isRequired,
  labelRenderFunc: PropTypes.func,
}

MultiCheckboxWithRhf.defaultProps = {
  labelRenderFunc: label => label
}

export default observer(MultiCheckboxWithRhf)
