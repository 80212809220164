import React from 'react'

import PropTypes from 'prop-types'

import { Chip } from '@material-ui/core'
import clsx from 'clsx'

import style from './NewFeatureBadge.module.scss'

const NewFeatureBadge = ({ className, size, label }) => (
  <Chip
    label={label}
    size="small" color="secondary"
    className={clsx(className, style.BetaBadge, size === 'small' && style.Small)}
  />
)

NewFeatureBadge.propTypes = {
  label: PropTypes.string,
  size: PropTypes.oneOf(['medium', 'small']),
  className: PropTypes.string,
}

NewFeatureBadge.defaultProps = {
  size: 'medium',
  label: 'BETA',
}

export default NewFeatureBadge
