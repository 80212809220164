import React from 'react'

import PropTypes from 'prop-types'

import { Button } from '@material-ui/core'
import { observer } from 'mobx-react'
import { useSnackbar } from 'notistack'

import { LoadingButton } from 'shared/components/atoms'
import { DataModal } from 'shared/components/organisms'

import { ImageGallery } from 'retailer/components/organisms'

import style from './ImportListProductVariantImageSelector.module.scss'

const ImportListProductVariantImageSelector = ({ variantImageSelectorStore }) => {
  const { enqueueSnackbar } = useSnackbar()
  const { selectImage, images, save, reset, isSaving } = variantImageSelectorStore

  const handleSave = async () => {
    const saved = await save()
    if (saved) {
      reset()
      return
    }
    enqueueSnackbar(
      'Could not save image for the variant',
      { variant: 'error' }
    )
  }

  return (
    <DataModal
      fullWidth
      onExited={reset}
      maxWidth="md"
      className={style.ImportListProductVariantImageSelector}
      title="Select variant image"
      renderBody={() => {
        return (
          <>
            <ImageGallery
              images={images}
              onSelect={(id, checked) => { if (checked) selectImage(id) }}
              ImageProps={{
                className: style.Image,
              }}
              testId="importList-importListProduct-imageGallery"
            />
          </>
        )
      }}
      renderActions={({ dismissModal }) => {
        return (
          <>
            <Button
              variant="outlined"
              color="primary"
              onClick={dismissModal}
            >
              Cancel
            </Button>
            <LoadingButton
              loading={isSaving}
              variant="contained"
              color="primary"
              onClick={handleSave}
              data-testid="importList-importListProduct-imageGallery-saveButton"
            >
              Save
            </LoadingButton>
          </>
        )
      }}
    />
  )
}

ImportListProductVariantImageSelector.propTypes = {
  variantImageSelectorStore: PropTypes.shape({
    variant: PropTypes.object,
    selectImage: PropTypes.func,
    images: PropTypes.arrayOf(PropTypes.object),
    reset: PropTypes.func,
    save: PropTypes.func,
    isSaving: PropTypes.bool,
  })
}

export default observer(ImportListProductVariantImageSelector)
