import React, { useEffect } from 'react'

import { CircularProgress, Container } from '@material-ui/core'
import { observer } from 'mobx-react'
import { Helmet } from 'react-helmet'

import { useBiEvents } from 'shared/bi/hooks'
import { ScrollResetter } from 'shared/components/atoms'

import { PageHeader } from 'retailer/components/molecules'
import { PricingActionsCard, PricingRulesCard } from 'retailer/components/organisms'
import { SidebarLeftTemplate } from 'retailer/components/templates'
import { useStores } from 'retailer/stores/hooks'

import style from './PricingRulesPage.module.scss'

const PricingRulesPage = () => {
  const { pricingRulesStore, userProfileStore } = useStores()
  const { logPageLoaded } = useBiEvents({ correlateWithLocation: true })

  const { fetch, isLoaded } = pricingRulesStore
  const { markAdjustedPricingRules } = userProfileStore

  useEffect(() => {
    (async () => {
      await Promise.all([
        fetch(),
        markAdjustedPricingRules(),
      ])
      logPageLoaded({ pageName: 'pricingRules' })
    })()
  }, [])

  const main = (
    <Container maxWidth="xl" disableGutters className={style.PricingRulesContent}>
      <PageHeader title="Pricing rules" mb={3} />
      {isLoaded
        ? (
          <>
            <PricingRulesCard pricingRulesStore={pricingRulesStore} />
            <PricingActionsCard pricingRulesStore={pricingRulesStore} />
          </>
          )
        : <CircularProgress />}
    </Container>
  )

  return (
    <>
      <Helmet>
        <title>Pricing rules</title>
      </Helmet>
      <ScrollResetter />
      <SidebarLeftTemplate main={main} />
    </>
  )
}

export default observer(PricingRulesPage)
