import { api } from './common'

const baseUrl = '/api/v3/imports'

function getInitialImport () {
  return api.get(`${baseUrl}/initial/`)
}

function getImports (params = {}) {
  return api.get(`${baseUrl}/`, { params })
}

function getImport (id) {
  return api.get(`${baseUrl}/${id}/`)
}

function getImportItems (importId, params = {}) {
  return api.get(`${baseUrl}/${importId}/items/`, { params })
}

function postImport (payload) {
  return api.post(`${baseUrl}/`, payload)
}

function getExportFailedUrl (importId) {
  const url = `${baseUrl}/${importId}/export_failed/`
  return api.getUri({ url })
}

export {
  getImports,
  getImport,
  getImportItems,
  getInitialImport,
  postImport,
  getExportFailedUrl,
}
