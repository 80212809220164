import PropTypes from 'prop-types'

const nodes = PropTypes.oneOfType([
  PropTypes.arrayOf(PropTypes.node),
  PropTypes.node
])

const brandObject = PropTypes.shape({
  uuid: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
})

const countryObject = PropTypes.shape({
  code: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
})

const categoryShape = {
  id: PropTypes.number.isRequired,
  uuid: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
}
categoryShape.children = PropTypes.arrayOf(PropTypes.shape(categoryShape))

const categoryTree = PropTypes.shape(categoryShape)

const paragraphs = PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.node]))

const moneyAmount = PropTypes.oneOfType([PropTypes.number, PropTypes.string])

export default {
  nodes,
  brandObject,
  countryObject,
  categoryTree,
  paragraphs,
  moneyAmount,
}
