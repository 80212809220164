import React, { useState } from 'react'

import PropTypes from 'prop-types'

import {
  Button,
  Checkbox,
  FormControlLabel,
  Typography,
} from '@material-ui/core'
import { observer } from 'mobx-react'

import { DataModal } from 'shared/components/organisms'

import {
  OrderModalActionState,
  UserProfileStore,
} from 'retailer/stores'

const OrderSyncModal = ({ orderStore, userProfileStore, onExited }) => {
  const [dismissPermanently, setDismissPermanently] = useState(false)
  const handleDismissChange = () => setDismissPermanently(!dismissPermanently)

  const dismissModalPermanently = () => {
    if (dismissPermanently) {
      userProfileStore.dismissAlibabaSyncModal()
    }
  }

  return (
    <DataModal
      maxWidth="sm"
      title="Sync & Pay on Alibaba"
      renderBody={() => (
        <>
          <Typography>
            Modalyst will sync this order with your Alibaba account. You will then be directed to pay for the order on Alibaba.
            Once you have paid for your first order in Alibaba, Modalyst can sync and pay for your Alibaba orders without being redirected.
          </Typography>
          <FormControlLabel
            control={<Checkbox checked={dismissPermanently} onChange={handleDismissChange} name="dismiss" />}
            label="Don't show this message again"
          />
        </>
      )}
      renderActions={({ dismissModal }) => (
        <>
          <Button onClick={dismissModal}>Cancel</Button>
          <Button
            onClick={() => {
              dismissModalPermanently()
              orderStore.order.placeOrder()
              dismissModal()
            }}
            variant="contained"
            color="primary"
          >
            Sync to Alibaba
          </Button>
        </>
      )}
      onExited={onExited}
    />
  )
}

OrderSyncModal.propTypes = {
  orderStore: PropTypes.instanceOf(OrderModalActionState),
  userProfileStore: PropTypes.instanceOf(UserProfileStore),
  onExited: PropTypes.func.isRequired,
}

export default observer(OrderSyncModal)
