import { MARKETPLACE_CODE_PRINT_ON_DEMAND, MARKETPLACE_CODE_READY_TO_SELL } from 'shared/constants/marketplace'

const getMarketplaceItemUrl = (uuid, isCustomizable = false) => {
  const marketplaceCode = isCustomizable
    ? MARKETPLACE_CODE_PRINT_ON_DEMAND
    : MARKETPLACE_CODE_READY_TO_SELL
  return `/marketplace/${marketplaceCode}/${uuid}`
}

export {
  getMarketplaceItemUrl
}
