import { action, observable } from 'mobx'

import { processApiError } from 'shared/api/utils'

import { adaptPostApprovalRequestErrors, adaptPostApprovalRequestResponse } from 'supplier/adapters/approvalRequests'
import { postApprovalRequest } from 'supplier/api/approvalRequests'

class ApprovalRequestStore {
  /** @type {import('../root').RootStore} */
  root

  @observable isSubmitting
  @observable isDone
  @observable.shallow remoteErrors

  /**
   * @param {import('../root').RootStore} root
   */
  constructor (root) {
    this.root = root
  }

  @action.bound
  setRemoteErrors (obj) {
    this.remoteErrors = obj
  }

  @action.bound
  setIsDone (value) {
    this.isDone = value
  }

  @action.bound
  setIsSubmitting (value) {
    this.isSubmitting = value
  }

  @action.bound
  reset () {
    this.isDone = undefined
    this.isSubmitting = undefined
    this.remoteErrors = undefined
  }

  @action.bound
  submit () {
    this.reset()
    return postApprovalRequest()
      .then(response => {
        this.setIsDone(true)
        return adaptPostApprovalRequestResponse(response.data)
      })
      .catch(error => {
        const errors = processApiError(error, adaptPostApprovalRequestErrors)
        this.setRemoteErrors(errors)
        this.setIsDone(false)
        throw error
      })
      .finally(() => {
        this.setIsSubmitting(false)
      })
  }
}

export default ApprovalRequestStore
