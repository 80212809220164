/* eslint camelcase: 0 */

import { keysToCamelCase, keysToSnakeCase } from 'shared/utils/api'

const adaptPayment = ({ paid_on, ...data }) => ({
  paidOn: paid_on && new Date(paid_on),
  ...keysToCamelCase(data)
})

const adaptOrderItem = data => keysToCamelCase(data)

const adaptOrder = ({
  retailer, placed_on, shipping_address, items, payment, sync_meta,
  expected_delivery_time,
  ...rest
}) => ({
  retailer: keysToCamelCase(retailer),
  payment: payment && adaptPayment(payment),
  items: items.map(adaptOrderItem),
  placedOn: placed_on && new Date(placed_on),
  expectedDeliveryTime: expected_delivery_time && new Date(expected_delivery_time),
  shippingAddress: shipping_address && keysToCamelCase(shipping_address),
  syncMeta: sync_meta && keysToCamelCase(sync_meta),
  ...keysToCamelCase(rest),
})

const adaptGetOrderResponse = adaptOrder

const adaptGetOrdersResponse = ({ results, ...meta }) => ({
  results: results.map(row => adaptOrder(row)),
  ...keysToCamelCase(meta)
})

const adaptPostFulfillOrderRequest = data => keysToSnakeCase(data)

const adaptPostFulfillOrderResponse = data => adaptOrder(data)

const adaptPostFulfillOrderErrors = ({ non_field_errors, ...fieldErrors }) => ({
  nonFieldErrors: non_field_errors,
  fieldErrors: keysToCamelCase(fieldErrors),
})

export {
  adaptOrder,
  adaptGetOrderResponse,
  adaptGetOrdersResponse,
  adaptPostFulfillOrderRequest,
  adaptPostFulfillOrderResponse,
  adaptPostFulfillOrderErrors,
}
