import { api } from 'shared/api/common'

const baseUrl = '/api/v3/supplier/settings'

function getBusinessSettings () {
  return api.get(`${baseUrl}/business/`)
}

function putBusinessSettings (payload) {
  return api.put(`${baseUrl}/business/`, payload)
}

function getShippingSettings () {
  return api.get(`${baseUrl}/shipping/`)
}

function putShippingSettings (payload) {
  return api.put(`${baseUrl}/shipping/`, payload)
}

export {
  getBusinessSettings,
  putBusinessSettings,
  getShippingSettings,
  putShippingSettings,
}
