import { computed, observable } from 'mobx'
import { v4 as uuid4 } from 'uuid'

import { ProductOptionStore } from 'supplier/stores'

/**
 * Holds state and some data for the "edit/add product option" use case
 *
 * XXX: note that the `currentOptions` should NOT contain options from
 *  eg. ProductStore ie. as received from the API, but the current state of
 *  the form. Refer to ProductOptionsCard and EditProdictOptionsModal for
 *  the remainder of the business logic.
 */
class EditProductOptionsStore {
  /** All options in the product form state */
  @observable.shallow currentOptions
  /** Default key */
  @observable key

  constructor (currentOptions, key = undefined) {
    this.currentOptions = currentOptions
    this.key = key
  }

  /** Keys used for options (except the edited one) */
  @computed get usedKeys () {
    return [...new Set(this.currentOptions.filter(option => option.key !== this.key).map(option => option.key))]
  }

  /** Initial options for the current key */
  @computed get keyOptions () {
    if (!this.key) return []
    return this.currentOptions.filter(option => option.key === this.key)
  }

  @computed get defaultValues () {
    const { key, keyOptions } = this
    return { key: key || '', options: keyOptions }
  }

  getNewOption = optionValue => {
    return new ProductOptionStore({ uuid: uuid4(), key: this.key, value: optionValue, protected: false })
  }
}

export default EditProductOptionsStore
