import { observable } from 'mobx'

class PurchaseOrderDesigner {
  @observable messageable = false
  @observable email = ''
  @observable id = null

  constructor (data) {
    Object.assign(this, data)
  }
}

export default PurchaseOrderDesigner
