import React from 'react'

import PropTypes from 'prop-types'

import { Button, IconButton, Tooltip, useMediaQuery } from '@material-ui/core'
import { useTheme } from '@material-ui/core/styles'
import { observer } from 'mobx-react'

import style from './BatchProductListActionItem.module.scss'

const BatchProductListActionItem = ({ name, icon, disabled, onClick }) => {
  const theme = useTheme()
  const mdUp = useMediaQuery(theme.breakpoints.up('md'))

  return (
    <div className={style.BatchProductListActionItem}>
      <Tooltip title={name}>
        <span>
          {!mdUp && (
            <Button
              variant="outlined"
              color="primary"
              size="large"
              startIcon={icon}
              disabled={disabled}
              onClick={onClick}
            >
              {name}
            </Button>
          )}
          {mdUp && (
            <IconButton
              color="primary"
              disabled={disabled}
              onClick={onClick}
            >
              {icon}
            </IconButton>
          )}
        </span>
      </Tooltip>
    </div>
  )
}

BatchProductListActionItem.propTypes = {
  name: PropTypes.string.isRequired,
  icon: PropTypes.node.isRequired,
  disabled: PropTypes.bool,
  onClick: PropTypes.func.isRequired
}

BatchProductListActionItem.defaultProps = {
  disabled: false
}

export default observer(BatchProductListActionItem)
