import React, { useState } from 'react'

import PropTypes from 'prop-types'

import { Grid, Hidden, Tab, Tabs, useMediaQuery, useTheme } from '@material-ui/core'
import clsx from 'clsx'
import { observer } from 'mobx-react'

import { PLANS_COLORS } from 'shared/constants/subscriptions'

import { SubscriptionPlanCard } from 'retailer/components/organisms'
import { SubscriptionPlanStore } from 'retailer/stores'

import style from './SubscriptionPlans.module.scss'

const SubscriptionPlans = ({ plans }) => {
  const theme = useTheme()
  const lgUp = useMediaQuery(theme.breakpoints.up('lg'))
  const [selectedTab, setSelectedTab] = useState(0)

  const handleChange = (event, newValue) => {
    setSelectedTab(newValue)
  }

  return (
    <Grid container spacing={4} className={style.SubscriptionPlans}>
      <Hidden lgUp>
        <Grid item xs={12}>
          <Tabs
            value={selectedTab}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            variant="fullWidth"
          >
            {plans.map((plan, index) => {
              return <Tab label={plan.shortName} value={index} id={index} key={index} />
            })}
          </Tabs>
        </Grid>
      </Hidden>
      {plans.map((plan, index) => {
        if (!(lgUp || selectedTab === index)) return null
        return (
          <Grid item xs={12} lg={4} key={plan.code}>
            <SubscriptionPlanCard
              plan={plan}
              className={clsx(style.Plan, plan.popular && style.featured)}
              color={PLANS_COLORS[index]}
            />
          </Grid>
        )
      })}
    </Grid>
  )
}

SubscriptionPlans.propTypes = {
  plans: PropTypes.arrayOf(PropTypes.instanceOf(SubscriptionPlanStore)).isRequired
}

export default observer(SubscriptionPlans)
