import qs from 'query-string'

import { api } from './common'

const baseUrl = '/api/v3/marketplace'

function getProducts (params = {}, cancelToken) {
  return api.get(`${baseUrl}/items`, {
    params,
    cancelToken,
    paramsSerializer: params => {
      return qs.stringify(params, { arrayFormat: 'separator', arrayFormatSeparator: ';' })
    }
  })
}

// TODO: rework MarketplaceItemStore to use getProducts and scrap
const getItems = (params = '', token) => {
  return api.get(`${baseUrl}/items?${params}`, {
    cancelToken: token || null
  })
}

const getCustomizableItems = (params = {}, cancelToken) => {
  return api.get(baseUrl + '/customizable/items', {
    params,
    cancelToken,
    paramsSerializer: params => {
      return qs.stringify(params, { arrayFormat: 'separator', arrayFormatSeparator: ';' })
    }
  })
}

const getItem = uuid => {
  return api.get(baseUrl + '/items/' + uuid)
}

const importItem = (id, headers = {}) => {
  return api.post(`${baseUrl}/items/${id}/import`, undefined, { headers })
}

const getItemShippingTable = uuid => {
  return api.get(baseUrl + '/items/' + uuid + '/shipping_table')
}

const placeManualOrder = (data, headers = {}) => {
  return api.post(baseUrl + '/manual_order/', data, { headers })
}

const setStaffPick = (uuid, flag, value, headers = {}) => {
  return api.post(`${baseUrl}/items/${uuid}/set_staff_pick/`, { flag, value }, { headers })
}

export {
  getItems,
  getCustomizableItems,
  getProducts,
  getItem,
  importItem,
  getItemShippingTable,
  placeManualOrder,
  setStaffPick,
}
