import React from 'react'

import PropTypes from 'prop-types'

import { Box, Typography } from '@material-ui/core'
import { observer } from 'mobx-react'

import { useTranslation } from 'shared/hooks'

import { CustomizableProductSlate, ProductSlate, VirtualizedGrid } from 'retailer/components/organisms'
import { ItemStore } from 'retailer/stores'

const MarketplaceItemMoreItemsList = ({ brandName, items, ...boxProps }) => {
  const { t } = useTranslation('productPage')
  if (!items) return null

  return (
    <Box {...boxProps}>
      <Typography variant="h4" component="h3" gutterBottom>
        {t('moreProductsFromSupplier.cardHeader.title', { name: brandName })}
      </Typography>
      <VirtualizedGrid
        items={items} itemMinWidth={225}
        renderItemContent={store => (store.isCustomizable
          ? <CustomizableProductSlate item={store} />
          : <ProductSlate item={store} />
        )}
      />
    </Box>
  )
}

MarketplaceItemMoreItemsList.propTypes = {
  brandName: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.instanceOf(ItemStore))
}

MarketplaceItemMoreItemsList.defaultProps = {
  brandName: 'this brand'
}

export default observer(MarketplaceItemMoreItemsList)
