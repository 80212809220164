/* eslint camelcase: 0 */
import { adaptStore } from 'shared/adapters/stores'
import { keysToCamelCase } from 'shared/utils/api'

/**
 * To adapt, we just change keys to camelCase.
 * @param {Object} context Context is a flat object of key-values.
 */
const adaptUserProfileContext = (context = {}) => keysToCamelCase(context)

// FIXME: adapter too specific to retailer, should be moved to src/retailer
const adaptUserProfile = ({ context_data, stores, ...rest }) => ({
  context: adaptUserProfileContext(context_data),
  stores: stores && stores.map(adaptStore),
  ...keysToCamelCase(rest)
})

const adaptShippingZone = keysToCamelCase
const adaptShippingCountry = keysToCamelCase
const adaptShippingTime = keysToCamelCase
const adaptShippingMaxRate = keysToCamelCase
const adaptBrand = keysToCamelCase

const adaptAppConfigResponse = ({
  urls, categories, active_brands,
  shipping_zones, shipping_countries, shipping_times, shipping_max_rates,
  ...rest
}) => ({
  categories: categories.map(cat => keysToCamelCase(cat)),
  activeBrands: active_brands && active_brands.map(adaptBrand),
  urls: keysToCamelCase(urls),
  shippingZones: shipping_zones && shipping_zones.map(adaptShippingZone),
  shippingCountries: shipping_countries && shipping_countries.map(adaptShippingCountry),
  shippingTimes: shipping_times && shipping_times.map(adaptShippingTime),
  shippingMaxRates: shipping_max_rates && shipping_max_rates.map(adaptShippingMaxRate),
  ...keysToCamelCase(rest)
})

const adaptCurrentRetailerAuthorization = ({ current_retailer_authorization, limits }) => {
  return {
    currentRetailerAuthorization: current_retailer_authorization,
    limits: limits,
  }
}

export {
  adaptUserProfileContext,
  adaptUserProfile,
  adaptAppConfigResponse,
  adaptCurrentRetailerAuthorization,
}
