import React, { useEffect, useState } from 'react'

import PropTypes from 'prop-types'

import {
  ListItem,
  ListItemText,
  CircularProgress,
  FormHelperText,
} from '@material-ui/core'
import { observer } from 'mobx-react'

import { MoneyInput } from 'shared/components/atoms'
import { useTranslation } from 'shared/hooks'

import {
  FiltersDrawerCheckboxList,
  FiltersDrawerSectionButton,
  FiltersDrawerSection,
} from 'retailer/components/molecules'
import { FiltersPanel } from 'retailer/components/organisms'

import style from './MarketplaceFiltersDrawer.module.scss'

const SECTION_SUPPLIERS = 1
const SECTION_SHIPPING = 2
const SECTION_PRICING = 3

/**
 * A generic section that displays a list of checkboxes as per the options prop
 */
const CheckboxListSection = observer(({ options, renderOptionLabel, ...rest }) => (
  <FiltersDrawerSection {...rest}>
    <FiltersDrawerCheckboxList options={options} renderOptionLabel={renderOptionLabel} />
  </FiltersDrawerSection>
))

CheckboxListSection.propTypes = {
  options: PropTypes.array.isRequired,
  renderOptionLabel: PropTypes.func,
}

const PricingSection = observer(({ open, onClose, pricingFiltersEditorStore }) => {
  const { t } = useTranslation('marketplace')

  const [invalid, setInvalid] = useState()
  const { costFrom, costTo, setCostFrom, setCostTo } = pricingFiltersEditorStore

  const handleInputBlur = () => {
    const min = parseFloat(costFrom)
    const max = parseFloat(costTo)
    setInvalid(!isNaN(min) && !isNaN(max) && min >= max)
  }

  return (
    <FiltersDrawerSection
      title={t('main.filterPrice.label')}
      open={open} onClose={onClose}
      className={style.PricingSection}
    >
      <ListItem component="div" className={style.PricingListItem}>
        <ListItemText>{t('main.filterPriceCost.checkbox')}</ListItemText>
        <MoneyInput
          variant="outlined" size="small"
          value={costFrom}
          onChange={event => setCostFrom(event.target.value)}
          onBlur={handleInputBlur}
          error={invalid}
          disableFraction
          placeholder={t('main.filterPriceFrom.placeholder')}
          testId="MarketplaceFiltersDrawer-pricingSection-fromMoneyInput"
        />
        <MoneyInput
          variant="outlined" size="small"
          value={costTo}
          onChange={event => setCostTo(event.target.value)}
          onBlur={handleInputBlur}
          error={invalid}
          disableFraction
          placeholder={t('main.filterPriceTo.placeholder')}
          testId="MarketplaceFiltersDrawer-pricingSection-toMoneyInput"
        />
        {invalid && (
          <FormHelperText error>{t('main.filterPriceCost.invalidRangeError')}</FormHelperText>
        )}
      </ListItem>
    </FiltersDrawerSection>
  )
})

PricingSection.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  pricingFiltersEditorStore: PropTypes.shape({
    costFrom: PropTypes.string.isRequired,
    costTo: PropTypes.string.isRequired,
    setCostFrom: PropTypes.func.isRequired,
    setCostTo: PropTypes.func.isRequired,
  }).isRequired,
}

const ShippingSection = observer(({ open, onClose, shippingFiltersEditorStore }) => {
  const { t } = useTranslation('marketplace')

  const [openSection, setOpenSection] = useState(false)
  const [direction, setDirection] = useState('left')

  const {
    shipsFromOptions,
    shipsFromSearch,
    shipsToOptions,
    shipsToSearch,
    shippingOptions,
    selectedShipsFromOptions,
    selectedShipsToOptions,
    selectedShippingOptions,
  } = shippingFiltersEditorStore

  const openSubsection = subsection => {
    setDirection('right')
    setOpenSection(subsection)
  }
  const handleCloseSelf = () => {
    setOpenSection(null)
    setDirection('left')
    onClose()
  }
  const handleCloseChild = () => setOpenSection(null)

  return (
    <>
      <FiltersDrawerSection
        title={t('main.filterShipping.label')}
        open={open && !openSection}
        direction={direction}
        onClose={handleCloseSelf}
      >
        <FiltersDrawerSectionButton
          primary={t('main.filterShippingGeneral.label')}
          secondary={`(${t('main.filterShippingGeneral.counter', { numberOfFilters: selectedShippingOptions.length })})`}
          onClick={() => openSubsection('shipping__general')}
        />
        <FiltersDrawerSectionButton
          primary={t('main.filterShippingShipsTo.label')}
          secondary={`(${t('main.filterShippingShipsTo.counter', { numberOfFilters: selectedShipsToOptions.length })})`}
          onClick={() => openSubsection('shipping__ships_to')}
        />
        <FiltersDrawerSectionButton
          primary={t('main.filterShippingShipsFrom.label')}
          secondary={`(${t('main.filterShippingShipsFrom.counter', { numberOfFilters: selectedShipsFromOptions.length })})`}
          onClick={() => openSubsection('shipping__ships_from')}
        />
      </FiltersDrawerSection>
      <CheckboxListSection
        title={t('main.filterShippingGeneral.label')}
        open={openSection === 'shipping__general'}
        onClose={handleCloseChild}
        options={shippingOptions}
        renderOptionLabel={label => t(`main.${label}.checkbox`)}
      />
      <CheckboxListSection
        title={t('main.filterShippingShipsTo.label')}
        open={openSection === 'shipping__ships_to'}
        onClose={handleCloseChild}
        options={shipsToOptions}
        search={shipsToSearch}
      />
      <CheckboxListSection
        title={t('main.filterShippingShipsFrom.label')}
        open={openSection === 'shipping__ships_from'}
        onClose={handleCloseChild}
        options={shipsFromOptions}
        search={shipsFromSearch}
      />
    </>
  )
})

ShippingSection.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  shippingFiltersEditorStore: PropTypes.shape({
    shipsFromOptions: PropTypes.array.isRequired,
    shipsFromSearch: PropTypes.object.isRequired,
    shipsToOptions: PropTypes.array.isRequired,
    shipsToSearch: PropTypes.object.isRequired,
    shippingOptions: PropTypes.array.isRequired,
    selectedShipsFromOptions: PropTypes.array.isRequired,
    selectedShipsToOptions: PropTypes.array.isRequired,
    selectedShippingOptions: PropTypes.array.isRequired,
  }).isRequired,
}

const SuppliersSection = observer(({ open, onClose, suppliersFiltersEditorStore }) => {
  const { t } = useTranslation('marketplace')

  const [openSection, setOpenSection] = useState(false)
  const [direction, setDirection] = useState('left')

  const {
    inventoryTypes,
    brandOptions,
    search,
  } = suppliersFiltersEditorStore

  const openSubsection = subsection => {
    setDirection('right')
    setOpenSection(subsection)
  }
  const handleCloseSelf = () => {
    setOpenSection(null)
    setDirection('left')
    onClose()
  }
  return (
    <>
      <FiltersDrawerSection
        title={t('main.filterSupplier.label')}
        open={open && !openSection}
        direction={direction}
        onClose={handleCloseSelf}
        search={search}
        searchPlaceholder={t('main.filterSupplierSearch.placeholder')}
      >
        {search.appliedQuery
          ? <FiltersDrawerCheckboxList options={brandOptions} />
          : (
              inventoryTypes.map(it => (
                <FiltersDrawerSectionButton
                  key={it.id}
                  primary={it.name}
                  secondary={`(${t('main.filterSupplier.counter', { numberOfFilters: it.selectedBrandOptions.length })})`}
                  onClick={() => openSubsection(it)}
                />
              ))
            )}
      </FiltersDrawerSection>
      {inventoryTypes.map(it => (
        <CheckboxListSection
          key={it.id}
          title={it.name}
          open={openSection === it}
          onClose={() => setOpenSection(null)}
          options={it.brandOptions}
          search={it.search}
          searchPlaceholder={t('main.filterSupplierSearch.placeholder')}
        />
      ))}
    </>
  )
})

SuppliersSection.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  suppliersFiltersEditorStore: PropTypes.shape({
    inventoryTypes: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      brandOptions: PropTypes.array.isRequired,
      selectedBrandOptions: PropTypes.array.isRequired,
      search: PropTypes.object.isRequired,
    })).isRequired,
    search: PropTypes.object.isRequired,
    brandOptions: PropTypes.array.isRequired,
  }).isRequired,
}

const MarketplaceFiltersDrawerContent = observer(({
  marketplaceFiltersEditorStore, excludeSections
}) => {
  const { t } = useTranslation('marketplace')

  const {
    suppliersFiltersEditorStore,
    shippingFiltersEditorStore,
    pricingFiltersEditorStore,
  } = marketplaceFiltersEditorStore
  const { productCostRangeText } = pricingFiltersEditorStore

  const [openSection, setOpenSection] = useState()
  const handleCloseChild = () => setOpenSection(null)

  return (
    <>
      <FiltersDrawerSection direction="right" open={!openSection} appear={false} mountOnEnter>
        {!excludeSections.includes('suppliers') && (
          <FiltersDrawerSectionButton
            primary={t('main.filterSupplier.label')}
            secondary={`(${t('main.filterSupplier.counter', { numberOfFilters: suppliersFiltersEditorStore.totalSelectedCount })})`}
            onClick={() => setOpenSection(SECTION_SUPPLIERS)}
          />
        )}
        {!excludeSections.includes('shipping') && (
          <FiltersDrawerSectionButton
            primary={t('main.filterShipping.label')}
            secondary={`(${t('main.filterShipping.counter', { numberOfFilters: shippingFiltersEditorStore.totalSelectedCount })})`}
            onClick={() => setOpenSection(SECTION_SHIPPING)}
          />
        )}
        {!excludeSections.includes('pricing') && (
          <FiltersDrawerSectionButton
            primary={t('main.filterPrice.label')}
            secondary={productCostRangeText && `(${productCostRangeText})`} // TODO: range should to be translated
            onClick={() => setOpenSection(SECTION_PRICING)}
          />
        )}
      </FiltersDrawerSection>
      {!excludeSections.includes('pricing') && (
        <PricingSection
          open={openSection === SECTION_PRICING}
          onClose={handleCloseChild}
          pricingFiltersEditorStore={pricingFiltersEditorStore}
        />
      )}
      {!excludeSections.includes('shipping') && (
        <ShippingSection
          open={openSection === SECTION_SHIPPING}
          onClose={handleCloseChild}
          shippingFiltersEditorStore={shippingFiltersEditorStore}
        />
      )}
      {!excludeSections.includes('suppliers') && (
        <SuppliersSection
          open={openSection === SECTION_SUPPLIERS}
          onClose={handleCloseChild}
          suppliersFiltersEditorStore={suppliersFiltersEditorStore}
        />
      )}
    </>
  )
})

MarketplaceFiltersDrawerContent.propTypes = {
  excludeSections: PropTypes.arrayOf(PropTypes.string).isRequired,
  marketplaceFiltersEditorStore: PropTypes.shape({
    suppliersFiltersEditorStore: PropTypes.shape({
      totalSelectedCount: PropTypes.number.isRequired,
    }).isRequired,
    shippingFiltersEditorStore: PropTypes.shape({
      totalSelectedCount: PropTypes.number.isRequired,
    }).isRequired,
    pricingFiltersEditorStore: PropTypes.shape({
      productCostRangeText: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
}

const MarketplaceFiltersDrawer = ({
  marketplaceFiltersEditorStore, onApply, excludeSections, allowFilters
}) => {
  const { t } = useTranslation('marketplace')

  const { ready, filtersVisible, totalSelectedCount, hideFilters, reset } = marketplaceFiltersEditorStore

  useEffect(() => {
    marketplaceFiltersEditorStore.initialize()
  }, [])

  const handleClear = () => reset()

  return (
    <FiltersPanel
      className={style.MarketplaceFiltersDrawer}
      open={filtersVisible && allowFilters}
      onClose={() => hideFilters()}
      onSave={() => onApply(marketplaceFiltersEditorStore.filterParams)}
      onClear={!!totalSelectedCount && handleClear}
      filtersCount={totalSelectedCount}
      title={t('main.filter.title')}
    >
      {ready
        ? (
          <MarketplaceFiltersDrawerContent
            marketplaceFiltersEditorStore={marketplaceFiltersEditorStore}
            excludeSections={excludeSections || []}
          />
          )
        : <CircularProgress />}
    </FiltersPanel>
  )
}

MarketplaceFiltersDrawer.propTypes = {
  excludeSections: PropTypes.arrayOf(PropTypes.string),
  marketplaceFiltersEditorStore: PropTypes.shape({
    ready: PropTypes.bool.isRequired,
    filtersVisible: PropTypes.bool.isRequired,
    hideFilters: PropTypes.func.isRequired,
    filterParams: PropTypes.object,
    initialize: PropTypes.func.isRequired,
    totalSelectedCount: PropTypes.number.isRequired,
    reset: PropTypes.func.isRequired,
  }).isRequired,
  onApply: PropTypes.func.isRequired,
  allowFilters: PropTypes.bool.isRequired,
}

export default observer(MarketplaceFiltersDrawer)
