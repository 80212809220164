import { action, observable, computed } from 'mobx'

import {
  DPO_STATUS_FULFILLED,
  DPO_STATUS_FULFILLING,
  DPO_STATUS_PAID,
  DPO_STATUS_REFUNDED,
  DPO_STATUS_RESERVED_CANCELLED,
} from 'shared/constants/orders'

class OrderStore {
  @observable status
  @observable active
  @observable selected = false

  @observable shippingAddress
  @observable courierName
  @observable trackingCode
  @observable expectedDeliveryTime

  @observable.shallow items

  @observable itemsTotal
  @observable shippingTotal
  @observable grandTotal

  constructor (data = {}) {
    this.update(data)
  }

  @action.bound
  update (data) {
    Object.assign(this, data)
  }

  @computed get isCancelled () {
    return this.status === DPO_STATUS_RESERVED_CANCELLED
  }

  @computed get isRefunded () {
    return this.status === DPO_STATUS_REFUNDED
  }

  @computed get isPaid () {
    return [DPO_STATUS_PAID, DPO_STATUS_FULFILLING, DPO_STATUS_FULFILLED].includes(this.status)
  }

  @computed get isFulfilled () {
    return this.status === DPO_STATUS_FULFILLED
  }

  @computed get isInvoiceable () {
    return this.isPaid && !this.isCancelled && !this.isRefunded
  }

  @computed get isFulfillable () {
    return this.isInvoiceable && !this.isFulfilled
  }

  @action.bound
  setSelected (value) {
    this.selected = value
  }
}

export default OrderStore
