import React from 'react'

import PropTypes from 'prop-types'

import { Box, CircularProgress, Fade, Grow, useTheme } from '@material-ui/core'
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded'
import clsx from 'clsx'

import style from './SuccessSpinner.module.scss'

const SuccessSpinner = ({ success, hideSpinner, size, className, ...rest }) => {
  const theme = useTheme()
  const showSpinner = !success && !hideSpinner
  return (
    <Box
      className={clsx(style.SuccessSpinner, className)}
      width={size} height={size}
      color={theme.palette.success.main}
      {...rest}
    >
      <Fade in={showSpinner} timeout={800}><CircularProgress size={size} /></Fade>
      <Grow in={success} timeout={800}><CheckCircleRoundedIcon /></Grow>
    </Box>
  )
}

SuccessSpinner.propTypes = {
  success: PropTypes.bool,
  hideSpinner: PropTypes.bool,
  size: PropTypes.number,
  className: PropTypes.string,
}

SuccessSpinner.defaultProps = {
  success: false,
  hideSpinner: false,
  size: 40,
}

export default SuccessSpinner
