import { api } from './common'

const baseUrl = '/api/v3/stores/'

const getStores = (params = {}) => {
  return api.get(baseUrl, { params })
}

const postStore = (data, headers = {}) => {
  return api.post(baseUrl, data, { headers })
}

const deleteStore = (uuid, headers = {}) => {
  return api.delete(`${baseUrl}${uuid}/`, { headers })
}

export {
  getStores,
  postStore,
  deleteStore,
}
