
import { observable, action, computed } from 'mobx'

import { ALIEXPRESS_URL } from 'shared/constants/integrations'

import SupplierAppIntegrationStore from './SupplierAppIntegrationStore'

class AliExpressIntegrationStore extends SupplierAppIntegrationStore {
  integrationNameDisplay = 'AliExpress'
  chromeExtensionStatusObserver
  @observable isChromeExtensionInstalled

  constructor (appConfigStore) {
    super('aliexpress', appConfigStore)

    this.chromeExtensionStatusObserver = new MutationObserver(() => {
      this.setIsChromeExtensionInstalled(this.detectChromeExtension())
    })
    this.chromeExtensionStatusObserver.observe(document.body, { attributes: true })
    this.setIsChromeExtensionInstalled(this.detectChromeExtension())
  }

  detectChromeExtension = () => {
    return document.body.classList.contains('modalyst-chrome-extension')
  }

  @computed get marketplaceUrl () {
    return this.isConnected && this.isChromeExtensionInstalled ? ALIEXPRESS_URL : false
  }

  @action.bound
  setIsChromeExtensionInstalled (value) {
    this.isChromeExtensionInstalled = value
  }
}

export default AliExpressIntegrationStore
