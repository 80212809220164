import React, { useReducer } from 'react'

import PropTypes from 'prop-types'

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
} from '@material-ui/core'

import { ModalTitle } from 'shared/components/molecules'

import ConfirmContext from './context'
import useConfirm from './hooks'
import { initialState, reducer } from './reducer'

const ConfirmDialog = () => {
  const { onConfirm, onCancel, onExited, confirmState } = useConfirm()
  const { ok = 'OK', cancel = 'Cancel', onEntered } = confirmState.config ?? {}

  const handleContentClick = event => {
    // if clicked event target (clicked node) is a link, close the modal
    if (event.target.nodeName.toLowerCase() === 'a') {
      onCancel()
    }
  }

  return (
    <Dialog
      open={confirmState.show}
      TransitionProps={{ onEntered, onExited }}
      data-testid="confirmDialog"
    >
      <ModalTitle
        id="confirm-dialog-title"
        data-testid="confirmDialog-title"
        onCloseClick={onCancel}
      >
        {confirmState.title}
      </ModalTitle>
      <DialogContent>
        <DialogContentText
          id="confirm-dialog-message"
          data-testid="confirmDialog-message"
          onClick={handleContentClick}
          component="div"
        >
          {confirmState.message}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} color="primary" data-testid="confirmDialog-cancelButton">
          {cancel}
        </Button>
        <Button onClick={onConfirm} color="primary" autoFocus variant="contained" data-testid="confirmDialog-okButton">
          {ok}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

const ConfirmContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState)

  return (
    <ConfirmContext.Provider value={[state, dispatch]}>
      {children}
      <ConfirmDialog />
    </ConfirmContext.Provider>
  )
}

ConfirmContextProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
}

export default ConfirmContextProvider
