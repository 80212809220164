import React, { forwardRef, useImperativeHandle, useRef } from 'react'

import PropTypes from 'prop-types'

import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  Grid,
  Tab,
  Tabs,
  TextField,
  Tooltip,
  Typography
} from '@material-ui/core'
import { Warning } from '@material-ui/icons'
import { Alert } from '@material-ui/lab'
import {
  pricingRuleSavePricingRuleClicked,
  pricingRuleUserClickedToCreatePricingRule,
} from '@wix/bi-logger-modalyst/v2'
import { observer } from 'mobx-react'
import { useSnackbar } from 'notistack'
import NumberFormat from 'react-number-format'

import { useBiEvents } from 'shared/bi/hooks'
import { LoadingButton, Paragraphs } from 'shared/components/atoms'
import { ModalTitle } from 'shared/components/molecules'
import { useConfirm } from 'shared/utils/confirmation'

import { PricingRule, ToggleButtonGroup } from 'retailer/components/molecules'
import CustomPropTypes from 'retailer/propTypes'
import { INVENTORY_TYPE_TABS } from 'retailer/stores/PricingRulesStore/constants'
import { useIsDesktop } from 'retailer/theme/hooks'

import style from './PricingRulesCard.module.scss'

/**
 * @typedef {import('../../../stores/PricingRulesStore/PricingRulesStore').default} PricingRulesStore
 */

const CompareAtPriceWarning = () => (
  <Alert severity="warning" component={Box} mb={3}>
    <Typography variant="body2" paragraph>
      In Shopify, the "Compare At Price" is the full retail price and the "Price" is{' '}
      the discounted price. You cannot set the discount to be higher than the full retail price.
    </Typography>
    <Typography variant="body2">
      As you have set one to be a multiplied margin and the other to be an addition{' '}
      margin, there are circumstances when the Compare At Price will not be higher{' '}
      than the Price. For those items, we will automatically set the "Compare At Price"{' '}
      to be equal to the "Price."
    </Typography>
  </Alert>
)

const SavedDialog = forwardRef(({ onExited }, ref) => {
  const [open, setOpen] = React.useState(false)
  const show = () => setOpen(true)
  const dismiss = () => setOpen(false)

  useImperativeHandle(ref, () => ({ show, dismiss }))

  return (
    <Dialog
      open={open}
      TransitionProps={{ onExited }}
      aria-labelledby="save-dialog-title"
      PaperProps={{ 'data-testid': 'pricingRules-saveAndApplyConfirmationModal' }}
    >
      <ModalTitle id="save-dialog-title" onCloseClick={dismiss}>
        Pricing Rules Saved
      </ModalTitle>
      <DialogContent>
        <Typography variant="body1" color="textSecondary" paragraph>
          Your changes have been saved.
        </Typography>
        <Typography variant="body1">
          Pricing Rules must be set for each product catalogue.
          Each marketplace - such as Independent Brands, Trendy Items{' '}
          and AliExpress Items - need to be set individually.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={dismiss}
          variant="contained" color="primary"
          data-testid="pricingRules-saveAndApplyConfirmationModal-okButton"
        >
          OK
        </Button>
      </DialogActions>
    </Dialog>
  )
})

SavedDialog.propTypes = {
  onExited: PropTypes.func,
}

/**
 * PricingRulesCard component displays the pricing rules for different inventory types.
 * It allows users to set markups, margins, and other pricing configurations.
 *
 * @component
 * @param {Object} props
 * @param {PricingRulesStore} props.pricingRulesStore
 * @returns {JSX.Element} PricingRulesCard node
 */
const PricingRulesCard = ({ pricingRulesStore }) => {
  const { confirm } = useConfirm()
  const savedDialogRef = useRef(null)
  const isDesktop = useIsDesktop()
  const { enqueueSnackbar } = useSnackbar()
  const { correlationId, logBiEvent } = useBiEvents({ correlateWithLocation: true })

  const {
    inventoryType,
    inventoryTypeMessages,
    inventoryTypeTabs,
    currentInventoryTypePricingRules,
    setInventoryType,
    save,
    isSaving,
    biData,
  } = pricingRulesStore

  const {
    priceRule,
    compareAtPriceAvailable,
    compareAtPriceRule,
    includeShippingRateInPrice,
    setIncludeShippingRateInPrice,
    fixedShippingRate,
    setFixedShippingRate,
    changePriceEnding,
    priceEnding,
    setChangePriceEnding,
    setPriceEnding,
    isValid,
    errors,
    isCompareAtPricePotentiallyLower,
  } = currentInventoryTypePricingRules

  const handleChangeInventoryType = (event, newValue) => {
    setInventoryType(newValue)
  }

  const handleSaveClick = async () => {
    logBiEvent(pricingRuleSavePricingRuleClicked({ ...biData, correlationId }))
    const confirmed = await confirm(
      'Save and apply pricing rules',
      <>
        {isCompareAtPricePotentiallyLower && <CompareAtPriceWarning />}
        <Typography paragraph>
          When you save pricing rules, they will be applied to all your products, except products with locked prices.
        </Typography>
        <Typography>
          Are you sure you want to save settings and apply pricing rules?
        </Typography>
      </>,
      { ok: 'Save and apply' }
    )
    if (confirmed) {
      logBiEvent(pricingRuleUserClickedToCreatePricingRule({ ...biData, correlationId }))
      try {
        await save()
        savedDialogRef.current?.show()
      } catch (error) {
        enqueueSnackbar(
          'We could not finish this operation. Please try again or contact our support team.',
          { variant: 'error' }
        )
      }
    }
  }

  return (
    <Card elevation={0} data-testid="PricingRulesCard">

      <CardContent>
        <Typography variant="h4" gutterBottom>
          Set your markups and margins
        </Typography>
        <Typography variant="body2" color="textSecondary" paragraph>
          Adjust the default markup to reach your desired profit. You can set different pricing rules for each inventory type, allowing you to have varying rules for Independent Brands, Trendy Items, and AliExpress. <a target="_blank" rel="noreferrer" href="https://support.modalyst.co/en/articles/1383822-what-are-pricing-rules">Learn more about pricing rules</a>.
        </Typography>
      </CardContent>

      {/* Tabs */}
      <Tabs
        className={style.InventoryTypeTabs}
        variant="scrollable" scrollButtons="auto"
        value={inventoryType} onChange={handleChangeInventoryType}
      >
        {inventoryTypeTabs.map(obj => (
          <Tab key={obj.key} value={obj.key} label={obj.name} />
        ))}
      </Tabs>

      <Divider />

      {/* Description */}
      <CardContent className={style.Description}>
        <Grid container spacing={isDesktop ? 2 : 1}>
          <Grid item lg={3}>
            <Typography variant="h5">{inventoryTypeMessages.name}</Typography>
          </Grid>
          <Grid item lg={9}>
            <Paragraphs contents={inventoryTypeMessages.description} variant="body2" color="textSecondary" />
          </Grid>
        </Grid>
      </CardContent>

      <Divider />

      <CardContent>
        <Typography variant="h4">Pricing rules</Typography>
      </CardContent>

      {/* Compare at price */}
      {compareAtPriceAvailable && (
        <PricingRule
          allowDisable
          pricingRuleStore={compareAtPriceRule}
          code="compareAtPrice"
          title="Compare at price"
          titleHelp="The Compare At Price is the full retail value of each item. For example, if your item costs $10 at full retail value, and you want to sell it for $5, the Compare At Price is $10."
          productCostLabel={inventoryTypeMessages.productCost}
          helpText={(
            <>
              <strong>Note:</strong> You will want to set the Compare At Price markup{' '}
              <strong>higher</strong> than the Price markup.
            </>
          )}
          markupError={errors.compareAtPrice || errors.compareAtPriceLowerThanPrice}
        />
      )}

      {compareAtPriceAvailable && <Divider />}

      {/* Price */}
      <PricingRule
        pricingRuleStore={priceRule}
        code="price"
        title="Retail Price"
        titleHelp="This is the price that’s shown to customers."
        productCostLabel={inventoryTypeMessages.productCost}
        markupError={errors.price}
      />

      <Divider />

      {/* Shipping rate */}
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={12} md={8}>
            <Typography variant="h5" gutterBottom>Include the shipping rate in the retail price</Typography>
            <Paragraphs
              variant="body2" color="textSecondary"
              contents={inventoryTypeMessages.shippingRateText}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <ToggleButtonGroup
              value={includeShippingRateInPrice}
              onChange={(event, value) => setIncludeShippingRateInPrice(value)}
              testId="pricingRules-shippingRate"
              options={[
                { value: false, label: "Don't add", testId: 'pricingRules-shippingRate-button' },
                { value: true, label: 'Add', testId: 'pricingRules-shippingRate-button' },
              ]}
            />
          </Grid>
        </Grid>
      </CardContent>

      {/* Flat shipping rate */}
      {inventoryTypeMessages.flatShippingRate && (
        <CardContent className={style.FlatShippingRate}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={8}>
              <Typography variant="h5" gutterBottom>
                {inventoryTypeMessages.flatShippingRate.title}
              </Typography>
              <Paragraphs
                variant="body2" color="textSecondary"
                contents={inventoryTypeMessages.flatShippingRate.text}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                inputProps={{ style: { textAlign: 'center' } }}
                value={`$${inventoryTypeMessages.flatShippingRate.value}`}
                size="small" fullWidth variant="outlined" disabled
              />
            </Grid>
          </Grid>
        </CardContent>
      )}

      {/* Override shipping rate */}
      <CardContent className={style.OverrideShippingRate}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={8}>
            <Typography variant="h5" gutterBottom>
              {inventoryTypeMessages.overrideShippingRateTitle}
            </Typography>
            <Paragraphs
              variant="body2" color="textSecondary"
              contents={inventoryTypeMessages.overrideShippingRateText}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Tooltip
              title={
                includeShippingRateInPrice
                  ? ''
                  : (
                      'You can only override the shipping rate if you have selected ' +
                    'to "ADD" the shipping rate to the item price. Please select ' +
                    '"Add" before adjusting the override amount.'
                    )
              }
            >
              <NumberFormat
                value={fixedShippingRate}
                onValueChange={({ value }) => setFixedShippingRate(value)}
                fixedDecimalScale decimalScale={2}
                allowNegative={false} isAllowed={({ value }) => value.length <= 13}
                customInput={TextField}
                disabled={!includeShippingRateInPrice}
                size="small" fullWidth variant="outlined"
                inputProps={{ style: { textAlign: 'center' }, 'data-testid': 'pricingRules-fixedShippingRate-input' }}
              />
            </Tooltip>
          </Grid>
        </Grid>
      </CardContent>

      <Divider />

      {/* Change Price Ending */}
      {inventoryTypeMessages.changePriceEnding && (
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12} md={8}>
              <Typography variant="h5" gutterBottom>Change price ending</Typography>
              <Paragraphs
                variant="body2" color="textSecondary"
                contents={inventoryTypeMessages.changePriceEnding}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Box mb={2}>
                <ToggleButtonGroup
                  value={changePriceEnding}
                  onChange={(event, value) => setChangePriceEnding(value)}
                  testId="pricingRules-changePriceEnding"
                  options={[
                    { value: false, label: "Don't change", testId: 'pricingRules-changePriceEnding-button' },
                    { value: true, label: 'Change', testId: 'pricingRules-changePriceEnding-button' },
                  ]}
                />
              </Box>
              <NumberFormat
                value={priceEnding}
                format=".##"
                allowLeadingZeros
                onValueChange={({ value }) => setPriceEnding(value)}
                allowNegative={false}
                isAllowed={({ value }) => value.length <= 2}
                customInput={TextField}
                disabled={!changePriceEnding}
                InputProps={{ endAdornment: errors.priceEnding ? <Tooltip title={errors.priceEnding}><Warning color="error" /></Tooltip> : undefined }}
                error={errors.priceEnding}
                size="small" fullWidth variant="outlined"
                inputProps={{ style: { textAlign: 'center' }, 'data-testid': 'pricingRules-priceEnding-input' }}
              />
            </Grid>
          </Grid>
        </CardContent>
      )}

      {/* Actions */}

      <CardActions>
        <LoadingButton
          variant="contained" color="primary"
          disabled={!isValid || isSaving} loading={isSaving}
          onClick={handleSaveClick}
          testId="pricingRules-saveButton"
        >
          Save
        </LoadingButton>
      </CardActions>

      <SavedDialog ref={savedDialogRef} />

    </Card>
  )
}

const pricingRulePropType = PropTypes.shape({
  markup: PropTypes.string.isRequired,
  markupType: PropTypes.oneOf(['disabled', 'added', 'multiplied']).isRequired,
  setMarkup: PropTypes.func.isRequired,
  setMarkupType: PropTypes.func.isRequired,
})

PricingRulesCard.propTypes = {
  pricingRulesStore: PropTypes.shape({
    inventoryType: PropTypes.oneOf(INVENTORY_TYPE_TABS),
    inventoryTypeMessages: PropTypes.shape({
      name: PropTypes.string.isRequired,
      description: CustomPropTypes.paragraphs.isRequired,
      productCost: PropTypes.string.isRequired,
      shippingRateText: CustomPropTypes.paragraphs.isRequired,
      flatShippingRate: PropTypes.shape({
        title: PropTypes.string.isRequired,
        text: CustomPropTypes.paragraphs.isRequired,
        value: PropTypes.string.isRequired,
      }),
      overrideShippingRateTitle: PropTypes.string.isRequired,
      overrideShippingRateText: CustomPropTypes.paragraphs.isRequired,
      changePriceEnding: PropTypes.string.isRequired,
    }).isRequired,
    inventoryTypeTabs: PropTypes.arrayOf(PropTypes.shape({
      key: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    })).isRequired,
    currentInventoryTypePricingRules: PropTypes.shape({
      priceRule: pricingRulePropType.isRequired,
      compareAtPriceAvailable: PropTypes.bool,
      compareAtPriceRule: pricingRulePropType.isRequired,
      includeShippingRateInPrice: PropTypes.bool,
      setIncludeShippingRateInPrice: PropTypes.func.isRequired,
      fixedShippingRate: PropTypes.string,
      setFixedShippingRate: PropTypes.func.isRequired,
      changePriceEnding: PropTypes.bool,
      priceEnding: PropTypes.string,
      setChangePriceEnding: PropTypes.func.isRequired,
      setPriceEnding: PropTypes.func.isRequired,
      isValid: PropTypes.bool,
      isCompareAtPricePotentiallyLower: PropTypes.bool,
      errors: PropTypes.shape({
        price: PropTypes.string,
        compareAtPrice: PropTypes.string,
        compareAtPriceLowerThanPrice: PropTypes.string,
        priceEnding: PropTypes.string,
      }).isRequired,
    }).isRequired,
    setInventoryType: PropTypes.func.isRequired,
    save: PropTypes.func.isRequired,
    isSaving: PropTypes.bool,
    biData: PropTypes.object.isRequired,
  }).isRequired,
}

export default observer(PricingRulesCard)
