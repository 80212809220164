import React from 'react'

import PropTypes from 'prop-types'

import { Button, IconButton } from '@material-ui/core'
import { CloseRounded } from '@material-ui/icons'
import { Alert } from '@material-ui/lab'
import { observer } from 'mobx-react'
import { Link as RouterLink } from 'react-router-dom'

import style from './UniversalNotification.module.scss'

const UniversalNotification = ({ visible, content, severity, dismissible, action }) => {
  if (!visible) return null
  return (
    <Alert
      variant="filled"
      severity={severity}
      elevation={4}
      className={style.UniversalNotification}
      action={
        <div className={style.Buttons}>
          {action && (
            <Button
              color="inherit"
              component={action.to ? RouterLink : 'a'} to={action.to} href={action.href}
              onClick={action.handleAction}
              rel="opener"
              target="_blank"
              className={style.ActionButton}
              startIcon={action.icon}
            >
              {action.text}
            </Button>
          )}
          {dismissible && (
            <IconButton color="inherit">
              <CloseRounded />
            </IconButton>
          )}
        </div>
      }
    >
      {content}
    </Alert>
  )
}

UniversalNotification.propTypes = {
  visible: PropTypes.bool,
  content: PropTypes.node.isRequired,
  severity: PropTypes.oneOf(['info', 'warning', 'error', 'success']),
  dismissible: PropTypes.bool,
  action: PropTypes.shape({
    text: PropTypes.string.isRequired,
    to: PropTypes.string,
    href: PropTypes.href,
    handleAction: PropTypes.func,
    icon: PropTypes.node
  })
}

UniversalNotification.defaultProps = {
  visible: false,
  severity: 'info',
  dismissible: false
}

export default observer(UniversalNotification)
