import React from 'react'

import PropTypes from 'prop-types'

import { Badge, Chip, Link } from '@material-ui/core'
import {
  alibabaAliexpressSettingsClicked,
  goToMyStoresClicked,
  logOutClicked,
  upgradeStoreClicked,
  userProfileMenuTriggered,
} from '@wix/bi-logger-modalyst/v2'
import { observer } from 'mobx-react'
import { NavLink } from 'react-router-dom'

import { useBiEvents } from 'shared/bi/hooks'
import { Home, Basket, Cart, Help, Inbox, Layers, Settings } from 'shared/icons'

import { BiLink } from 'retailer/components/atoms'
import { List, MainMenuItem } from 'retailer/components/molecules'
import { useStores } from 'retailer/stores/hooks'

import style from './MainMenu.module.scss'

const BI_ORIGIN = 'main navigation'

const MyProductsMenu = observer(() => {
  const { mainMenuStore } = useStores()
  const { retailerItemsCount } = mainMenuStore

  React.useEffect(() => {
    retailerItemsCount.fetch()
  }, [retailerItemsCount])

  return (
    <List className={style.Submenu}>
      <NavLink
        className={style.WithChip}
        key="importList"
        data-testid="MainMenu-menuItem-importList"
        to="/my-products/import-list"
      >
        Import List
        {!!retailerItemsCount.importList && (
          <Chip
            label={retailerItemsCount.importList}
            data-testid="MainMenu-menuItem-importList-itemsCountChip"
            color="primary"
            className={style.Chip}
          />
        )}
      </NavLink>
      <NavLink
        className={style.WithChip}
        key="syncList"
        data-testid="MainMenu-menuItem-syncList"
        to="/my-products/sync-list"
      >
        Sync List
        {!!retailerItemsCount.syncList && (
          <Chip
            label={retailerItemsCount.syncList}
            data-testid="MainMenu-menuItem-syncList-itemsCountChip"
            color="primary"
            className={style.Chip}
          />
        )}
      </NavLink>
      <NavLink
        className={style.WithChip}
        key="archiveList"
        data-testid="MainMenu-menuItem-archiveList"
        to="/my-products/archive-list"
      >
        Archive List
        {!!retailerItemsCount.archiveList && (
          <Chip
            label={retailerItemsCount.archiveList}
            data-testid="MainMenu-menuItem-archiveList-itemsCountChip"
            color="primary"
            className={style.Chip}
          />
        )}
      </NavLink>
      <NavLink
        key="pricingRules"
        data-testid="MainMenu-menuItem-pricingRules"
        to="/my-products/pricing-rules"
      >
        Pricing Rules
      </NavLink>
    </List>
  )
})

const SettingsMenu = observer(() => {
  const { userProfileStore } = useStores()
  const { correlationId, logBiEvent } = useBiEvents()
  const { currentStore } = userProfileStore

  const handleLogoutClick = async event => {
    event.preventDefault()
    await logBiEvent(logOutClicked({ correlationId, origin: BI_ORIGIN }))
    userProfileStore.logout({ correlationId })
  }

  return (
    <List className={style.Submenu}>
      <BiLink
        component={NavLink}
        key="connectStore"
        data-testid="MainMenu-menuItem-connectStore"
        to="/my-stores"
        biData={() => goToMyStoresClicked({ origin: BI_ORIGIN })}
      >
        Connect Your Store
      </BiLink>
      <BiLink
        component={NavLink}
        key="connectToSupplier"
        data-testid="MainMenu-menuItem-connectToSupplier"
        to="/connect-suppliers"
        biData={() => alibabaAliexpressSettingsClicked({ origin: BI_ORIGIN })}
      >
        Alibaba & AliExpress
      </BiLink>
      <BiLink
        component={NavLink}
        key="managePlan"
        data-testid="MainMenu-menuItem-managePlan"
        to="/settings/manage-store-plan"
        biData={() => upgradeStoreClicked({
          origin: BI_ORIGIN,
          storeName: currentStore.name,
          targetStoreId: currentStore.uuid,
          numProductsLeft: currentStore.productsLeft,
        })}
      >
        Manage Store Plan
      </BiLink>
      <NavLink
        key="storeProfile"
        data-testid="MainMenu-menuItem-storeProfile"
        to="/settings/store-profile"
      >
        Store Profile
      </NavLink>
      <NavLink
        key="businessInfo"
        data-testid="MainMenu-menuItem-businessInfo"
        to="/settings/business-info"
      >
        Business Information
      </NavLink>
      <BiLink
        component={NavLink}
        key="userSettings"
        data-testid="MainMenu-menuItem-userSettings"
        to="/settings/user-settings"
        biData={() => userProfileMenuTriggered({ origin: BI_ORIGIN })}
      >
        User Settings
      </BiLink>
      <Link
        key="logout"
        data-testid="MainMenu-menuItem-logout"
        href="/logout/"
        onClick={handleLogoutClick}
      >
        Logout
      </Link>
    </List>
  )
})

const MainMenu = ({ className }) => {
  const { mainMenuStore } = useStores()
  const { ordersCount, retailerItemsCount } = mainMenuStore

  React.useEffect(() => {
    mainMenuStore.fetchOrdersCount()
  }, [mainMenuStore])

  return (
    <List className={`${style.MainMenu} ${className}`}>
      <MainMenuItem
        key="dashboard"
        testId="MainMenu-menuItem-dashboard"
        text="Dashboard"
        href="/home"
        icon={<Home />}
      />
      <MainMenuItem
        key="findProducts"
        testId="MainMenu-menuItem-findProducts"
        text="Find Products"
        href="/marketplace/ready-to-sell"
        icon={<Layers />}
      />
      <MainMenuItem
        key="myProducts"
        testId="MainMenu-menuItem-myProducts"
        text="My Products"
        href="/my-products"
        icon={(
          <Badge
            className={style.Badge}
            badgeContent={retailerItemsCount.total}
            max={999}
            color="secondary"
            overlap="rectangular"
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            <Basket />
          </Badge>
        )}
        submenu={<MyProductsMenu />}
      />
      <MainMenuItem
        key="orders"
        text="Orders"
        testId="MainMenu-menuItem-orders"
        href="/orders"
        icon={(
          <Badge
            className={style.Badge}
            badgeContent={ordersCount}
            max={99}
            color="secondary"
            overlap="rectangular"
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            <Cart />
          </Badge>
        )}
      />
      <MainMenuItem
        key="inbox"
        testId="MainMenu-menuItem-inbox"
        text="Inbox"
        href="/inbox"
        icon={<Inbox />}
      />
      <MainMenuItem
        key="settings"
        testId="MainMenu-menuItem-settings"
        text="Settings"
        href="/settings"
        icon={<Settings />}
        submenu={<SettingsMenu />}
      />
      <MainMenuItem
        key="helpCenter"
        testId="MainMenu-menuItem-helpCenter"
        text="Help Center"
        href="https://support.modalyst.co"
        icon={<Help />}
        absolute
      />
      <button style={{ display: 'none' }} onClick={() => { throw new Error('error to report in Sentry') }}>Throw me an error!</button>
    </List>
  )
}

MainMenu.propTypes = {
  className: PropTypes.string,
}

MainMenu.defaultProps = {
  className: '',
}

export default observer(MainMenu)
