import React, { useState } from 'react'

import PropTypes from 'prop-types'

import {
  Button,
  Grid,
  CircularProgress,
  IconButton,
  Menu,
  MenuItem,
  Typography,
  Divider,
  Tooltip
} from '@material-ui/core'
import { ArrowDropDown } from '@material-ui/icons'
import { observer } from 'mobx-react'
import { Link as RouterLink } from 'react-router-dom'

import { integrationsNames } from 'shared/constants/integrations'
import {
  DPO_STATUS_NEW,
  DPO_STATUS_PAYMENT_PENDING,
  DPO_STATUS_PENDING,
  DPO_STATUS_RESERVED,
  DPO_STATUS_RESERVED_CANCELLED,
  ORDER_STATUS_TO_ORDER,
  ACTION_MARK_AS_ORDERED,
  ACTION_MARK_AS_PAID,
  ACTION_MARK_AS_FULFILLED,
  ACTION_CANCEL,
  ACTION_DOWNLOAD_INVOICE,
  ACTION_PAY,
  ACTION_PLACE_ORDER,
} from 'shared/constants/orders'

import { PurchaseOrder as PurchaseOrderStore } from 'retailer/stores'
import { useStores } from 'retailer/stores/hooks'

import style from './PurchaseOrderActions.module.scss'

/**
 * Depending on order's statuses, the action button display different label
 * and performs a different action.
 * @param {PurchaseOrder} order
 * @returns {{func: (function(*): *), label: string}}
 */
const PurchaseOrderMainActionButton = observer(props => {
  const { order, testId, isSyncing } = props
  const { ordersStore, userProfileStore } = useStores()

  let actionLabel, buttonAction
  const integrationName = integrationsNames.get(order.orderType)
  if (isSyncing) { // 1.1. Syncing overrides all
    if (order.isAutomaticPaymentEnabled) {
      actionLabel = 'Paying for Order'
    } else {
      actionLabel = 'Syncing'
    }
  } else if (order.isPaymentInProgress) actionLabel = 'Paying for Order' // 1.2. Payment in progress
  else if (order.unifiedStatus === ORDER_STATUS_TO_ORDER) {
    // 2. Order is yet to be ordered
    // 2.1. Not yet ordered but already cancelled, no action possible
    if (order.status === DPO_STATUS_RESERVED_CANCELLED) return null
    // 2.2. Not yet ordered but cannot be ordered for some reason
    //      TODO: How to handle this situation, e.g. no AliExpress connection?
    if (!order.isActionAvailable(ACTION_PLACE_ORDER)) {
      return (
        <Typography component="span" variant="body2">
          Unable to order{integrationName ? ` on ${integrationName}` : ''}
        </Typography>
      )
    }
    if (order.isAutomaticPaymentAvailable) {
      if (order.isAutomaticPaymentEnabled) {
        actionLabel = 'Pay for Order'
        if (userProfileStore.context.dismissedAlibabaAutomaticPaymentsModal) {
          buttonAction = () => order.placeOrder()
        } else {
          buttonAction = () => ordersStore.selectOrderForSyncAndPay(order)
        }
      } else {
        actionLabel = `Sync to ${integrationName || 'Integrated App'}`
        if (userProfileStore.context.dismissedAlibabaSyncModal) {
          buttonAction = () => order.placeOrder()
        } else {
          buttonAction = () => ordersStore.selectOrderForSync(order)
        }
      }
    } else {
      actionLabel = `Sync to ${integrationName || 'Integrated App'}`
      buttonAction = () => order.placeOrder()
    }
  } else if (
    order.unifiedStatus !== ORDER_STATUS_TO_ORDER &&
    [
      DPO_STATUS_NEW,
      DPO_STATUS_PAYMENT_PENDING,
      DPO_STATUS_PENDING,
      DPO_STATUS_RESERVED
    ].includes(order.status)
  ) {
    if (!order.isActionAvailable(ACTION_PAY)) {
      return (
        <Typography component="span" variant="body2">
          Unable to pay{integrationName ? ` on ${integrationName}` : ''}
        </Typography>
      )
    }
    // 3. Order not yet paid
    actionLabel = 'Pay for Order'
    if (order.is3rdPartySupplierOrder) {
      // 3.1. For AliExpress/Alibaba orders open External Order Payment modal
      buttonAction = () => ordersStore.selectOrderForExternalPayment(order)
      if (userProfileStore.context.confirmedExternalOrderPaymentAliexpress || userProfileStore.context.confirmedExternalOrderPaymentAlibaba) {
        buttonAction = () => window.open(order.checkoutUrl, '_blank')
      }
    } else if (order.usesDraftOrders) {
      // 3.2. For Draft Orders open External Order Payment modal
      buttonAction = () => ordersStore.selectOrderForExternalPayment(order)
      if (userProfileStore.context.confirmedExternalOrderPaymentDraftOrders) {
        buttonAction = () => window.open(order.checkoutUrl, '_blank')
      }
    } else {
      // 3.3. Otherwise open the default Modalyst payment modal
      buttonAction = () => ordersStore.selectOrderForModalystPayment(order)
    }
  }

  if (!actionLabel) {
    return null
  }

  /**
   * Special case: when order uses draft orders, but checkoutUrl is none,
   * that means the order has not been created and we must show a disabled button
   * with proper tooltip to inform the user about that.
   */
  if (order.usesDraftOrders && !order.checkoutUrl) {
    return (
      <Tooltip title="You cannot pay for the order at the moment, due to temporary product unavailability">
        <div>
          <Button
            color="primary"
            variant="contained"
            disableElevation
            disabled
          >
            Pay for Order
          </Button>
        </div>
      </Tooltip>
    )
  }

  return (
    <Button
      color="primary"
      variant="contained"
      data-testid={testId}
      disableElevation
      disabled={isSyncing || order.isPaymentInProgress}
      onClick={buttonAction}
    >
      {(isSyncing || order.isPaymentInProgress) && (
        <CircularProgress
          size={14}
          className={style.Loader}
          color="inherit"
        />)}
      {actionLabel}
    </Button>
  )
})

PurchaseOrderMainActionButton.propTypes = {
  isSyncing: PropTypes.bool,
  order: PropTypes.shape({
    status: PropTypes.string,
    orderType: PropTypes.string,
    usesDraftOrders: PropTypes.bool,
    checkoutUrl: PropTypes.string,
    is3rdPartySupplierOrder: PropTypes.bool,
    isPaymentInProgress: PropTypes.bool,
    unifiedStatus: PropTypes.string,
    isActionAvailable: PropTypes.bool,
    isAutomaticPaymentAvailable: PropTypes.bool,
    isAutomaticPaymentEnabled: PropTypes.bool,
    placeOrder: PropTypes.func.isRequired,
  }),
  testId: PropTypes.string,
}

const PurchaseOrderActions = ({ order, testId }) => {
  const [menuElement, setMenuElement] = useState(null)
  const { ordersStore } = useStores()

  const openMenu = (event) => {
    setMenuElement(event.currentTarget)
  }

  const handleClose = () => {
    setMenuElement(null)
  }

  const markAsOrdered = () => {
    ordersStore.selectOrderForMarkAsOrdered(order)
    handleClose()
  }

  const markAsPaid = () => {
    ordersStore.selectOrderForMarkAsPaid(order)
    handleClose()
  }

  const cancel = () => {
    ordersStore.selectOrderForCancel(order)
    handleClose()
  }

  const markAsFulfilled = () => {
    ordersStore.selectOrderForMarkAsFulfilled(order)
    handleClose()
  }

  const downloadInvoice = () => {
    window.open(
      `/my_dropshipping/orders/${order.uuid}/invoice/`,
      '_blank'
    )
  }

  return (
    <>
      <Grid
        container
        justifyContent="space-between"
        className={style.ActionButtons}
      >
        <Grid item>
          <PurchaseOrderMainActionButton
            testId={`${testId}-mainActionButton`}
            order={order}
            isSyncing={order.isSyncing}
          />
          <IconButton
            className={style.MoreButton}
            data-testid={`${testId}-moreActionsButton`}
            aria-controls="moreActions"
            aria-haspopup="true"
            onClick={openMenu}
            disabled={order.isSyncing}
          >
            <ArrowDropDown />
          </IconButton>
          <Menu
            id="moreActions"
            anchorEl={menuElement}
            keepMounted
            open={Boolean(menuElement)}
            onClose={handleClose}
            className={style.MoreMenu}
          >
            {!order.availableActions.length && (
              <MenuItem disabled><em>No actions available</em></MenuItem>
            )}
            {order.isActionAvailable(ACTION_MARK_AS_ORDERED) && (
              <MenuItem onClick={markAsOrdered} data-testid={`${testId}-markAsOrdered`}>
                Mark as ordered
              </MenuItem>
            )}
            {order.isActionAvailable(ACTION_MARK_AS_PAID) && (
              <MenuItem onClick={markAsPaid} data-testid={`${testId}-markAsPaid`}>
                Mark as paid
              </MenuItem>
            )}
            {order.isActionAvailable(ACTION_MARK_AS_FULFILLED) && (
              <MenuItem onClick={markAsFulfilled} data-testid={`${testId}-markAsFulfilled`}>
                Mark as fulfilled
              </MenuItem>
            )}
            {order.isActionAvailable(ACTION_CANCEL) && (
              <MenuItem onClick={cancel} data-testid={`${testId}-cancel`}>
                Cancel order
              </MenuItem>
            )}
            {order.isActionAvailable(ACTION_DOWNLOAD_INVOICE) && (
              <MenuItem onClick={downloadInvoice} data-testid={`${testId}-downloadInvoice`}>
                Download invoice
              </MenuItem>
            )}
            {order.designer.messageable && (
              <div>
                <Divider className={style.Divider} />
                <MenuItem
                  component={RouterLink} to={`/inbox?writeTo=${order.designer.id}`}
                  data-testid={`${testId}-messageSupplier`}
                >
                  Message the supplier
                </MenuItem>
              </div>
            )}
          </Menu>
        </Grid>
      </Grid>
    </>
  )
}

PurchaseOrderActions.propTypes = {
  order: PropTypes.instanceOf(PurchaseOrderStore).isRequired,
  testId: PropTypes.string,
}

export default observer(PurchaseOrderActions)
