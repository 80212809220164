import { observable, action } from 'mobx'

class ProductImageStore {
  uuid
  @observable src
  @observable data
  @observable file

  constructor (data = {}) {
    Object.assign(this, data)
  }

  @action.bound
  setSrc (value) {
    this.src = value
  }
}

export default ProductImageStore
