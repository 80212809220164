import { api } from 'shared/api/common'

const baseUrl = '/api/v3/supplier/retailers'

function getRetailers (params = {}) {
  return api.get(`${baseUrl}/`, { params })
}

function postSetAccepted (data) {
  return api.post(`${baseUrl}/set_accepted/`, data)
}

export {
  getRetailers,
  postSetAccepted,
}
