import { keysToSnakeCase } from 'shared/utils/api'

const adaptCsvExportTypes = data => {
  return data
}

const adaptCsvExportForApi = data => keysToSnakeCase(data)

export {
  adaptCsvExportTypes,
  adaptCsvExportForApi
}
