import { api } from 'shared/api/common'

const baseUrl = '/api/v3/supplier/orders'

function getOrder (id) {
  return api.get(`${baseUrl}/${id}/`)
}

function getOrders (params = {}) {
  return api.get(`${baseUrl}/`, { params })
}

function postFulfillOrder (id, data) {
  return api.post(`${baseUrl}/${id}/fulfill/`, data)
}

function postCancelOrders (data) {
  return api.post(`${baseUrl}/cancel/`, data)
}

function getOrdersExportUrl (params = {}) {
  const url = `${baseUrl}/export/`
  return api.getUri({ url, params })
}

function getOrderPackingSlipUrl (id) {
  const url = `${baseUrl}/${id}/packing_slip/`
  return api.getUri({ url })
}

export {
  getOrder,
  getOrders,
  postFulfillOrder,
  postCancelOrders,
  getOrdersExportUrl,
  getOrderPackingSlipUrl,
}
