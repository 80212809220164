import { computed, observable } from 'mobx'

import { ALL_VALUE } from 'shared/constants/filters'
import { ORDER_STATUS_OPEN } from 'shared/constants/orders'
import { SelectFilterStore } from 'shared/stores'

class OrderFulfillmentStatusFilter extends SelectFilterStore {
  @observable parent

  constructor (parent, name, value, active, values) {
    super(name, value, active, values)
    this.parent = parent
  }

  @computed get activeValue () {
    if (this.isDisabled) {
      return ''
    }
    return this.value === ALL_VALUE ? '' : this.value
  }

  @computed get isDisabled () {
    return (
      this.parent.orderStatusFilter &&
      ![ALL_VALUE, ORDER_STATUS_OPEN].includes(
        this.parent.orderStatusFilter.value
      )
    )
  }
}

export default OrderFulfillmentStatusFilter
