import React from 'react'

import PropTypes from 'prop-types'

import { IconButton } from '@material-ui/core'
import { MoreVertRounded } from '@material-ui/icons'
import clsx from 'clsx'
import { observer } from 'mobx-react'

import style from './MoreButton.module.scss'

const MoreButton = ({ className, testId, ...rest }) => {
  return (
    <IconButton
      color="primary"
      className={clsx(style.MoreButton, className)}
      data-testid={testId}
      {...rest}
    >
      <MoreVertRounded />
    </IconButton>
  )
}

MoreButton.propTypes = {
  className: PropTypes.string,
  testId: PropTypes.string,
}

export default observer(MoreButton)
