import React from 'react'

import PropTypes from 'prop-types'

import { observer } from 'mobx-react'

import { useTranslation } from 'shared/hooks'

import { StarRating } from 'retailer/components/atoms'
import { MarketplaceItemDataRow } from 'retailer/components/organisms'
import { ItemStore } from 'retailer/stores'

import style from './MarketplaceItemProductRating.module.scss'

const MarketplaceItemProductRating = ({ item }) => {
  const { t } = useTranslation('productPage')
  const { hasStarRating, starRating, reviewCount } = item

  if (!hasStarRating) return null

  return (
    <MarketplaceItemDataRow
      title={t('productData.rating.title')}
      content={
        <StarRating
          starRating={starRating}
          reviewCount={reviewCount}
          className={style.MarketplaceItemProductRating}
          expanded
        />
      }
    />
  )
}

MarketplaceItemProductRating.propTypes = {
  item: PropTypes.instanceOf(ItemStore).isRequired
}

export default observer(MarketplaceItemProductRating)
