import React from 'react'

import PropTypes from 'prop-types'

import { Grid, Typography } from '@material-ui/core'
import { observer } from 'mobx-react'

import { Time } from 'shared/components/atoms'

import { RetailerSubscription } from 'retailer/stores'

import style from './CurrentSubscriptionPlan.module.scss'

const Cost = observer(({ isFree, isLifetime, monthlyPrice }) => {
  if (isFree) {
    return (
      <Typography>Free</Typography>
    )
  }

  if (isLifetime) {
    return (
      <Typography>
        Your current plan is special promo plan with a lifetime offering.<br />
        You will not be charged a monthly fee nor an annual fee.<br />
        This plan is only available on your current store and is non-transferable.
      </Typography>
    )
  }
  return (
    <Typography>
      {monthlyPrice} per month
    </Typography>
  )
})

Cost.propTypes = {
  isFree: PropTypes.bool,
  isLifetime: PropTypes.bool,
  monthlyPrice: PropTypes.string.isRequired
}

Cost.defaultProps = {
  isFree: false,
  isLifetime: false
}

const CurrentSubscriptionPlan = ({ subscription }) => {
  const plan = subscription.plan

  return (
    <div className={style.CurrentSubscriptionPlan}>
      <Typography variant="h4" component="h3" gutterBottom>
        Your plan
      </Typography>
      <Grid container spacing={4}>
        <Grid item xs={12} md={4} lg={3}>
          <Typography gutterBottom className={style.Header}>
            Current plan
          </Typography>
          <Typography>
            {plan.shortName + ` (${plan.period ? plan.period : 'Lifetime'})`}
          </Typography>
          <Typography gutterBottom className={style.Header}>
            Cost
          </Typography>
          <Cost isFree={plan.free} monthlyPrice={plan.monthlyPrice} isLifetime={plan.lifetime} />
        </Grid>
        <Grid item xs={12} md={4} lg={3}>
          <Typography gutterBottom className={style.Header}>
            Active until
          </Typography>
          <Typography>
            {plan.period && (
              <Time value={subscription.validTo} format="MMMM D, YYYY hh:mm a" />
            )}
            {!plan.period && 'This plan is perpetual and will never expire'}
          </Typography>
          {subscription.autoRenewalCancelled && (
            <>
              <Typography gutterBottom className={style.Header}>
                Auto renewal turned off
              </Typography>
              <Typography>
                <strong>Automatic renewal has been turned off.</strong>
              </Typography>
              <Typography>
                The subscription will not renew after that date and your plan
                will be changed to Hobby automatically.
              </Typography>
            </>
          )}
        </Grid>
        {!plan.active && (
          <Grid item xs={12} md={4} lg={6}>
            <Typography gutterBottom className={style.Header}>
              Grandfathered Plan
            </Typography>
            <Typography>
              <strong>We no longer offer this plan.</strong>
            </Typography>
            <Typography>
              As a valued customer, you are grandfathered into this plan and we will continue to honor it for you.
              Should you opt to join another plan, we will then honor that new plan going forward.
            </Typography>
          </Grid>
        )}
      </Grid>
    </div>
  )
}

CurrentSubscriptionPlan.propTypes = {
  subscription: PropTypes.instanceOf(RetailerSubscription).isRequired
}

export default observer(CurrentSubscriptionPlan)
