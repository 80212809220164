import { computed, observable } from 'mobx'

import { AppConfigStore as BaseAppConfigStore } from 'shared/stores'

import { getAppConfig } from 'supplier/api/config'

class AppConfigStore extends BaseAppConfigStore {
  @observable.shallow shippingZones = []
  @observable.shallow shippingCountries = []
  @observable.shallow shippingTimes = []

  @computed get shippingZonesMap () {
    return new Map(this.shippingZones.map(obj => ([obj.id, obj])))
  }

  @computed get shippingCountriesMap () {
    return new Map(this.shippingCountries.map(obj => ([obj.id, obj])))
  }

  @computed get shippingTimesMap () {
    return new Map(this.shippingTimes.map(obj => ([obj.id, obj])))
  }

  @computed get countriesShippableFrom () {
    return this.shippingCountries.reduce((result, curr) => result.concat(curr.countries), [])
  }

  doFetch () {
    return getAppConfig()
  }
}

export default AppConfigStore
