import React from 'react'

import PropTypes from 'prop-types'

import { Grow, Popover, useMediaQuery } from '@material-ui/core'
import { useTheme } from '@material-ui/core/styles'
import clsx from 'clsx'
import { observer } from 'mobx-react'

import { HorizontalNestedList } from 'shared/components/molecules'
import { useTranslation } from 'shared/hooks'
import { adjustContainerScroll } from 'shared/utils/dom'

import CustomPropTypes from 'retailer/propTypes'

import style from './MarketplaceCategoryPicker.module.scss'

const MarketplaceCategoryPicker = ({
  open, container, onClose,
  categoryTree, filterTopCategories, depth, onPick, allowSelectRoot,
  onMouseEnter, onMouseLeave,
  className, ...rest
}) => {
  const { t } = useTranslation('marketplace')
  const theme = useTheme()
  const isLg = useMediaQuery(theme.breakpoints.up('lg'))
  const width = container?.offsetWidth || 'auto'

  return (
    <Popover
      className={clsx(style.MarketplaceCategoryPicker, className)}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      transformOrigin={{ vertical: 'top', horizontal: 'left' }}
      container={container}
      TransitionProps={{
        onEnter: () => { adjustContainerScroll(false) },
        onExit: () => { adjustContainerScroll(true) },
      }}
      PaperProps={{ className: style.Paper, style: { left: 0, width }, onMouseEnter, onMouseLeave }}
      open={open}
      onClose={onClose}
      transitionDuration={150}
      TransitionComponent={Grow}
      keepMounted={false}
      {...rest}
    >
      <HorizontalNestedList
        onPick={onPick}
        onClose={onClose}
        data={categoryTree}
        className={style.List}
        filterTopItems={filterTopCategories}
        allowSelectRoot={allowSelectRoot}
        renderInnerTitle={item => t('categories.viewAllInCategory.label', { categoryName: item.name })}
        multicolumn={isLg}
        depth={depth}
        dense
      />
    </Popover>

  )
}

MarketplaceCategoryPicker.propTypes = {
  open: PropTypes.bool.isRequired,
  container: PropTypes.oneOfType([PropTypes.element, PropTypes.func, PropTypes.object]),
  categoryTree: CustomPropTypes.categoryTree.isRequired,
  filterTopCategories: PropTypes.func,
  allowSelectRoot: PropTypes.bool,
  depth: PropTypes.number,
  onPick: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  className: PropTypes.string,
  ...Popover.propTypes,
}

export default observer(MarketplaceCategoryPicker)
