import dayjs from 'dayjs'
import { observable, computed, action } from 'mobx'

import { adaptGetSupplierAppAuthorizationResponse } from 'shared/adapters/integrations'
import { changeDefaultStoreForSupplierAppAuthorization, getSupplierAppAuthorization } from 'shared/api/integrations'

import { SUPPLIER_APP_INTEGRATION_STATUSES } from 'retailer/constants'

/**
 * A base SupplierAppIntegration store. Just provide the integrationName and it will call
 * the backend API automatically.
 * If you need more custom behavior, you can extend from it.
 * See `AliExpressIntegrationStore` for example.
 */
class SupplierAppIntegrationStore {
  integrationName
  appConfigStore
  @observable authorization
  @observable isLoading = false
  @observable error
  @observable marketplaceNavigationRequested

  constructor (integrationName, appConfigStore) {
    this.integrationName = integrationName
    this.appConfigStore = appConfigStore
  }

  @computed get connectionText () {
    // Your integration with AliExpress is active
    switch (this.status) {
      case SUPPLIER_APP_INTEGRATION_STATUSES.connected:
        return `Your connection to ${this.integrationNameDisplay} will expire ${this.timeToExpire}`
      case SUPPLIER_APP_INTEGRATION_STATUSES.expired:
        return `Your connection to ${this.integrationNameDisplay} expired ${this.timeToExpire}`
      default:
        return `Connect your account to ${this.integrationNameDisplay}`
    }
  }

  @computed get status () {
    if (this.isLoading === false) {
      const { connected, expired, unconnected } = SUPPLIER_APP_INTEGRATION_STATUSES
      if (this.authorization) {
        return this.authorization.isExpired ? expired : connected
      }
      return unconnected
    }
    return undefined
  }

  @computed get isConnected () {
    return this.status && this.status === SUPPLIER_APP_INTEGRATION_STATUSES.connected
  }

  @computed get timeToExpire () {
    return dayjs(this.authorization.accessTokenExpireTime).fromNow()
  }

  @action.bound
  fetchAuthorization () {
    this.isLoading = true
    getSupplierAppAuthorization(this.integrationName)
      .then((response) => {
        const data = adaptGetSupplierAppAuthorizationResponse(response.data)
        this.authorization = data
      })
      .catch(error => {
        this.error = error
      })
      .finally(() => {
        this.isLoading = false
      })
  }

  @action.bound
  changeDefaultStoreForSupplierAppAuthorization (storeUuid) {
    return changeDefaultStoreForSupplierAppAuthorization(this.integrationName, storeUuid)
      .then((response) => {
        const data = adaptGetSupplierAppAuthorizationResponse(response.data)
        Object.assign(this.authorization, data)
        return true
      })
      .catch(() => {
        return false
      })
  }
}

export default SupplierAppIntegrationStore
