import { ALL_VALUE } from 'shared/constants/filters'
import { ALIBABA, ALIEXPRESS, MODALYST } from 'shared/constants/integrations'
import {
  ORDER_STATUS_CANCELLED,
  ORDER_STATUS_FULFILLED,
  ORDER_STATUS_OPEN,
  ORDER_STATUS_PARTIALLY_CANCELLED,
  ORDER_STATUS_TO_ORDER,
  ORDER_STATUS_PENDING_PAYMENT,
  ORDER_STATUS_PENDING_FULFILLMENT,
  ORDER_STATUS_PARTIALLY_FULFILLED,
} from 'shared/constants/orders'

const orderStatuses = [
  {
    value: ALL_VALUE,
    label: 'All',
  },
  {
    value: ORDER_STATUS_OPEN,
    label: 'Open',
  },
  {
    value: ORDER_STATUS_FULFILLED,
    label: 'Fulfilled',
  },
  {
    value: ORDER_STATUS_CANCELLED,
    label: 'Cancelled',
  },
  {
    value: ORDER_STATUS_PARTIALLY_CANCELLED,
    label: 'Partially Cancelled',
  },
]

const orderFulfillmentStatuses = [
  {
    value: ALL_VALUE,
    label: 'All',
  },
  {
    value: ORDER_STATUS_TO_ORDER,
    label: 'Pending Sync to 3rd Party Supplier',
  },
  {
    value: ORDER_STATUS_PENDING_PAYMENT,
    label: 'Pending Payment',
  },
  {
    value: ORDER_STATUS_PENDING_FULFILLMENT,
    label: 'Paid & Pending Fulfillment',
  },
  {
    value: ORDER_STATUS_PARTIALLY_FULFILLED,
    label: 'Partially Fulfilled',
  },
  {
    value: ORDER_STATUS_FULFILLED,
    label: 'Fulfilled',
  }
]

const orderSources = [
  {
    value: ALL_VALUE,
    label: 'All',
  },
  {
    value: MODALYST,
    label: 'Modalyst',
  },
  {
    value: ALIEXPRESS,
    label: 'AliExpress',
  },
  {
    value: ALIBABA,
    label: 'Alibaba'
  }
]

export {
  orderStatuses,
  orderFulfillmentStatuses,
  orderSources,
}
