import React from 'react'

import PropTypes from 'prop-types'

import { Grid, Link as MuiLink } from '@material-ui/core'
import { LaunchRounded } from '@material-ui/icons'

import { ALIBABA, ALIEXPRESS } from 'shared/constants/marketplace'

import { MarketplaceBanner } from 'retailer/components/molecules'
import { useStores } from 'retailer/stores/hooks'

import { AlibabaBannerx2, AliExpressBannerx2, BeautyBannerx2, NewSuppliersBannerx2 } from './images'
import style from './MarketplaceBanners.module.scss'

const Link = ({ children, ...props }) => {
  return (
    <MuiLink className={style.TooltipLink} {...props}>
      {children}
      <LaunchRounded className={style.TooltipIcon} />
    </MuiLink>
  )
}

Link.propTypes = {
  children: PropTypes.node
}

Link.defaultProps = {
  children: null
}

const MarketplaceBanners = () => {
  const { connectToSuppliersStore } = useStores()

  return (
    <Grid container spacing={2} className={style.MarketplaceBanners}>
      <Grid item xs={6} md={3}>
        <MarketplaceBanner
          name="Beauty"
          url="/retailer/marketplace/ready-to-sell?categoryId=47f1f2f0-a1fa-4dbf-8b2d-9ff9c1a503b6"
          image={BeautyBannerx2}
        />
      </Grid>
      <Grid item xs={6} md={3}>
        <MarketplaceBanner
          name="New Suppliers"
          url="/retailer/marketplace/ready-to-sell?ordering=-approved_at%2C-quantity"
          image={NewSuppliersBannerx2}
        />
      </Grid>
      <Grid item xs={6} md={3}>
        <MarketplaceBanner
          name="Alibaba"
          image={AlibabaBannerx2}
          url={connectToSuppliersStore.alibaba?.marketplaceUrl || undefined}
          onClick={() => connectToSuppliersStore.navigateToMarketplace(ALIBABA)}
          newTab
          tooltip="Now you can dropship Alibaba items instead of buying them in bulk."
        />
      </Grid>
      <Grid item xs={6} md={3}>
        <MarketplaceBanner
          name="AliExpress"
          image={AliExpressBannerx2}
          url={connectToSuppliersStore.aliexpress?.marketplaceUrl || undefined}
          onClick={() => connectToSuppliersStore.navigateToMarketplace(ALIEXPRESS)}
          newTab
          tooltip={
            <>
              {'You must have the '}
              <Link
                href="https://chrome.google.com/webstore/detail/modalyst-aliexpress-drops/epcpfbncpjfomakgdophbanbkdeonkhc"
                target="_blank"
                rel="noopener noreferrer"
              >
                Modalyst Chrome Extension
              </Link>
              {' installed to import items directly from AliExpress'}
            </>
          }
        />
      </Grid>
    </Grid>
  )
}

MarketplaceBanners.propTypes = {

}

MarketplaceBanners.defaultProps = {

}

export default MarketplaceBanners
