const ITEM_TABS_ID = 'itemTabs'

const TABS = {
  DESCRIPTION: 'description',
  VARIANTS: 'variants',
  SHIPPING: 'shipping',
  RETURNS: 'returns',
}

export {
  ITEM_TABS_ID,
  TABS
}
