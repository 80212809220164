import { action } from 'mobx'

import { adaptGetInitialImportResponse } from 'shared/adapters/imports'
import { getInitialImport } from 'shared/api/imports'
import { IMPORT_STATUSES_IN_PROGRESS } from 'shared/constants/imports'

import { BaseProductImportManagerStore, IntegrationImportStore } from 'supplier/stores'

class ProductIntegrationImportManagerStore extends BaseProductImportManagerStore {
  /** @type {import('../root').RootStore} */
  root

  /**
   * @param {import('../root').RootStore} root
   */
  constructor (root) {
    super()
    this.root = root
  }

  /** Check if there is a running initial import eg. at an application startup */
  @action.bound
  checkOngoingImportRun () {
    getInitialImport()
      .then(response => {
        const data = adaptGetInitialImportResponse(response.data)
        // we only set the import store if the sync import is in progress
        // otherwise we forget it and assume the user has been
        // notified about the completion by other means
        if (IMPORT_STATUSES_IN_PROGRESS.includes(data.status)) {
          this.setImportRun(new IntegrationImportStore(data))
          this.pollImportRun() // poll to update progress
        }
      })
      .catch(error => {
        if (error.response && error.response.status === 404) return
        throw error
      })
  }

  /**
   * Fetches and applies the changes to the initial import.
   *
   * Can be called directly when we expect the import instance to be created
   * *soon* (eg. right after connecting an integration) - in such case it
   * handles the delay between the invocation and actual creation by repeatedly
   * calling itself
   */
  @action.bound
  pollImportRun () {
    this._clearPollTimeout() // clear the timeout just in case
    getInitialImport()
      .then(response => {
        const data = adaptGetInitialImportResponse(response.data)
        if (this.importRun) this.importRun.update(data)
        else this.setImportRun(new IntegrationImportStore(data))
        // keep polling for the changes only if the status is "in progress"
        if (IMPORT_STATUSES_IN_PROGRESS.includes(data.status)) {
          this._poll()
        }
      })
      .catch(error => {
        // import instance may not be created yet, so we keep polling
        if (error.response && error.response.status === 404) {
          this._poll()
        }
      })
  }
}

export default ProductIntegrationImportManagerStore
