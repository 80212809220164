import {
  removeProductClicked,
  productEdited,
} from '@wix/bi-logger-modalyst/v2'
import merge from 'lodash/merge'
import set from 'lodash/set'
import sortBy from 'lodash/sortBy'
import uniqBy from 'lodash/uniqBy'
import unset from 'lodash/unset'
import { action, computed, observable } from 'mobx'

import {
  adaptImportListItem,
  adaptRetailerItemVariant,
  adaptRenameOption, adaptRetailerItemDescriptionErrorsToFieldErrors,
  adaptRetailerItemDescriptionTabForm,
  adaptRetailerItemGeneralErrorsToFieldErrors,
  adaptRetailerItemGeneralTabForm,
} from 'shared/adapters/retailerItems'
import {
  addProductTags,
  getRetailerItem,
  getRetailerItemVariants,
  lockRetailerItemPrice,
  patchRetailerItem, removeAllProductTags,
  removeProductTag,
  renameRetailerItemOption,
  unlockRetailerItemPrice,
  updateRetailerItemOption,
} from 'shared/api/retailerItems'
import { RETAILER_AUTHORIZATION_WIX } from 'shared/constants/accounts'
import { TAB_DESCRIPTION, TAB_GENERAL, TAB_MEDIA, TAB_VARIANTS } from 'shared/constants/importList'
import { STYLE_COMMERCE } from 'shared/constants/integrations'
import {
  IMPORT_LIST_MAX_VARIANTS_SHOPIFY,
  IMPORT_LIST_MAX_VARIANTS_WIX,
  IMPORT_LIST_PRODUCT_MAX_IMAGES,
} from 'shared/constants/retailerItems'
import { ProductVariantSimpleStore } from 'shared/stores'
import { getBiHeadersFromEvent } from 'shared/stores/BiLoggerStore/utils'

import {
  RetailerProductStore,
  ImportListProductVariantStore,
  ImportListProductImageStore,
  ImportListProductShippingStore, ImportListProductVariantImageSelectorStore,
} from 'retailer/stores'
import { adaptImageForBiEvent } from 'retailer/stores/ImportListProductStore/utils'
import {
  flattenApiErrors,
  getImagesFieldName, getImportListProductDefaultValues,
  getOptionNameFieldName,
  getOptionValueFieldName, getVariantsNonFieldName,
} from 'retailer/utils/importList'

class ImportListProductStore extends RetailerProductStore {
  @observable error
  @observable errors = {
    fieldErrors: {},
    nonFieldErrors: {},
  }

  @observable isChangingMainImage = false
  @observable productTypeId = null

  /**
   * {{shippingCost:number,shippingProvider:sting,shippingProviderName:string}}
   */
  @observable supplierProductShipping
  @observable shippingStore

  @observable quantity
  @observable hidden
  @observable priceLockedChangeInProgress = false
  @observable activeVariantsCount
  @observable variantsCount
  @observable unsureInventory

  @observable selected = false
  @observable currentTab = TAB_GENERAL

  @observable.shallow categoryIds = []
  @observable.shallow collectionIds = []
  @observable.shallow tagIds = []

  /** An extended list of variants for editing purposes, loaded on demand */
  @observable variants = []
  @observable isFetchingVariants = false
  @observable variantsLoaded = false

  @observable variantImageSelectorStore = new ImportListProductVariantImageSelectorStore(this)

  /**
   * @param {import('../context').RootStore} root
   * @param {Object} data
   * @param {ProductBiContext} biContext
   */
  constructor (root, data, biContext) {
    super(root, data, biContext)
    this.shippingStore = new ImportListProductShippingStore(this)
  }

  @computed get parent () {
    return this.root.importListPageStore
  }

  @computed get currentRetailerAuthorization () {
    return this?.parent?.currentRetailerAuthorization || ''
  }

  @computed get maxImagesLimit () {
    return this?.parent?.retailerAuthorization?.limits?.assets || IMPORT_LIST_PRODUCT_MAX_IMAGES
  }

  @computed get formDefaultValues () {
    return getImportListProductDefaultValues(this)
  }

  @computed get variantsErrors () {
    const errors = {}

    const variantsCountLimits = new Map([
      [RETAILER_AUTHORIZATION_WIX, IMPORT_LIST_MAX_VARIANTS_WIX],
    ])
    const maxVariants = variantsCountLimits.get(this.currentRetailerAuthorization) || IMPORT_LIST_MAX_VARIANTS_SHOPIFY
    if (
      (!this.variants.length && this.activeVariantsCount > maxVariants) ||
      this.variants.filter(variant => variant.active).length > maxVariants
    ) {
      set(errors, getVariantsNonFieldName(), `A product can have at most ${maxVariants} active variants`)
    }

    return errors
  }

  @computed get imagesErrors () {
    const selectedCount = this.orderedImages.filter(image => image.selected).length
    const allCount = this.orderedImages.length

    const errors = {}

    if (allCount && !selectedCount) {
      set(
        errors,
        getImagesFieldName(),
        'To import a product, select at least 1 image.'
      )
    }
    if (selectedCount > this.maxImagesLimit) {
      set(
        errors,
        getImagesFieldName(),
        `To import a product, select up to ${this.maxImagesLimit} images.`
      )
    }

    return errors
  }

  /**
   * Returns all errors, from both the item itself and its variants.
   */
  @computed get allErrors () {
    return merge({}, this.errors, ...this.variants.map(variant => variant.errors))
  }

  /**
   * @returns {string}
   */
  @computed get retailerAuthorization () {
    return this.parent.currentRetailerAuthorization
  }

  /**
   * Ensure that the main image is always brought to front.
   */
  @computed get orderedImages () {
    const mainImage = this.images.find(image => image.isMain)
    const images = this.images.filter(image => !image.isMain)
    if (mainImage) images.unshift(mainImage)
    return images
  }

  @computed get computedActiveVariantsCount () {
    if (this.variantsLoaded) return this.variants.filter(variant => variant.active).length
    return this.activeVariantsCount
  }

  @computed get inactiveVariantsIds () {
    return this.variantsLoaded
      ? this.variants.filter(v => !v.active).map(v => v.uuid)
      : this.simpleVariants.filter(v => !v.active).map(v => v.id)
  }

  @computed get available () {
    const variantsActive = this.variants.length ? this.variants.some(variant => variant.active) : true
    return (
      this.quantity > 0 &&
      !this.hidden &&
      this.activeVariantsCount > 0 &&
      variantsActive
    )
  }

  @computed get isStyleCommerce () {
    return this.source === STYLE_COMMERCE
  }

  @computed get uniqueOptionNames () {
    return sortBy(
      uniqBy(this.options, 'key').map(option => (
        { uuid: option.uuid, key: option.key, position: option.position }
      )), ['position']
    )
  }

  @computed get uniqueOptionValues () {
    return sortBy(uniqBy(this.options, 'value'), ['position'])
  }

  @computed get availableCollections () {
    return this.parent.collections
  }

  @computed get collections () {
    return this.availableCollections.filter(collection => this.collectionIds.includes(collection.uuid))
  }

  @computed get availableCategories () {
    return this.parent.categories
  }

  @computed get categories () {
    return this.availableCategories.filter(category => this.categoryIds.includes(category.uuid))
  }

  @computed get availableTags () {
    return this.parent.tags
  }

  @computed get tags () {
    return this.availableTags.filter(tag => this.tagIds.includes(tag.uuid))
  }

  @computed get productTypes () {
    return this.parent.productTypes
  }

  @computed get productType () {
    return this.productTypes.find(type => type.uuid === this.productTypeId)
  }

  @action.bound
  clearError (path) {
    unset(this.errors.fieldErrors, path)
  }

  @action.bound
  setSelected (value) {
    this.selected = !!value
  }

  @action.bound
  changeTab (tabId) {
    this.currentTab = tabId
  }

  /**
   * Intentionally avoids calling super.assignData, to avoid issues where MobX doesn't properly pick up
   * changes on `this.images` and we end up with instances of `ImageStore` instead of `ImportListProductImageStore`.
   * @param {Object} mainImage
   * @param {Object[]} images
   * @param {Object} pricingRanges
   * @param {Object[]} variants
   * @param {Object} data
   */
  @action.bound
  assignData ({ mainImage, images, pricingRanges, variants, ...data }) {
    Object.assign(this, data)
    this.mainImage = mainImage ? new ImportListProductImageStore(this, mainImage) : null
    this.images = images ? images.map(image => new ImportListProductImageStore(this, image)) : []
    this.simpleVariants = variants.map(v => new ProductVariantSimpleStore(this, v))
    this._assignPricingRanges(pricingRanges)
  }

  @action.bound
  async loadVariants () {
    if (this.isFetchingVariants) return false

    this.isFetchingVariants = true
    try {
      const variantsResponse = await getRetailerItemVariants(this.uuid)
      this.variants = variantsResponse.data.map(
        variant => new ImportListProductVariantStore(this, adaptRetailerItemVariant(variant))
      )
      this.variantsLoaded = true
      this.isFetchingVariants = false
      return true
    } catch (e) {
      return false
    }
  }

  @action.bound
  async patch (data, biEvent) {
    const response = await patchRetailerItem({
      uuid: this.uuid,
      data: data,
      headers: biEvent ? getBiHeadersFromEvent(biEvent) : {},
    })
    this.assignData(adaptImportListItem(response.data))
  }

  @action.bound
  async saveGeneralTab (data, biEvent) {
    unset(this.errors.fieldErrors, TAB_GENERAL)
    try {
      await this.patch(adaptRetailerItemGeneralTabForm(data), biEvent)
      return true
    } catch (e) {
      const errors = adaptRetailerItemGeneralErrorsToFieldErrors(e.response.data)
      Object.entries(errors).forEach(([fieldName, errorList]) => {
        set(this.errors.fieldErrors, `${TAB_GENERAL}.${fieldName}`, flattenApiErrors(errorList).join(' '))
      })
      return false
    }
  }

  @action.bound
  async saveDescription (data, biEvent) {
    unset(this.errors.fieldErrors, TAB_DESCRIPTION)
    try {
      await this.patch(adaptRetailerItemDescriptionTabForm(data), biEvent)
      return true
    } catch (e) {
      const errors = adaptRetailerItemDescriptionErrorsToFieldErrors(e.response.data)
      Object.entries(errors).forEach(([fieldName, errorList]) => {
        set(this.errors.fieldErrors, `${TAB_DESCRIPTION}.${fieldName}`, flattenApiErrors(errorList).join(' '))
      })
      return false
    }
  }

  @action.bound
  async addProductType (name) {
    return await this.parent.addProductType(name)
  }

  @action.bound
  async addTag (tag) {
    unset(this.errors.fieldErrors, 'tags')
    try {
      const response = await addProductTags(this.uuid, tag)
      this.tagIds = [this.tagIds, response.data.uuid]
      this.availableTags.push(response.data)
      return response.data
    } catch (e) {
      set(this.errors.fieldErrors, 'tags', flattenApiErrors(e.response.data).join(' '))
    }
  }

  @action.bound
  async addTags (tags, biEvent) {
    unset(this.errors.fieldErrors, 'tags')
    try {
      const response = await addProductTags(this.uuid, tags, biEvent ? getBiHeadersFromEvent(biEvent) : {})
      this.tagIds = [this.tagIds, ...response.data.map(tag => tag.uuid)]
      return response.data
    } catch (e) {
      set(this.errors.fieldErrors, 'tags', flattenApiErrors(e.response.data).join(' '))
      return undefined
    }
  }

  @action.bound
  async removeTag (tagId, biEvent) {
    unset(this.errors.fieldErrors, 'tags')
    try {
      await removeProductTag(this.uuid, tagId, biEvent ? getBiHeadersFromEvent(biEvent) : {})
      this.tagIds = this.tagIds.filter(iterTagId => iterTagId !== tagId)
      return true
    } catch (e) {
      set(this.errors.fieldErrors, 'tags', flattenApiErrors(e.response.data).join(' '))
      return false
    }
  }

  @action.bound
  async removeAllTags (biEvent) {
    unset(this.errors.fieldErrors, 'tags')
    try {
      await removeAllProductTags(this.uuid, biEvent ? getBiHeadersFromEvent(biEvent) : {})
      this.tagIds = []
      return true
    } catch (e) {
      set(this.errors.fieldErrors, 'tags', flattenApiErrors(e.response.data).join(' '))
      return false
    }
  }

  @action.bound
  async changeOption (uuid, { variantUuid, value }) {
    const fieldName = getOptionValueFieldName(uuid, variantUuid)
    unset(this.errors.fieldErrors, fieldName)
    const option = this.options.find(option => option.uuid === uuid)
    const biEvent = await this.logProductEdited({
      modalystVariantIds: variantUuid,
      settingTab: TAB_VARIANTS,
      settingField: `option ${option.key} (${option.uuid}) changed`,
      oldValue: option.value,
      newValue: value,
    })
    try {
      await updateRetailerItemOption(uuid, { value }, getBiHeadersFromEvent(biEvent))
      await this.loadVariants()
      return true
    } catch (e) {
      set(this.errors.fieldErrors, fieldName, flattenApiErrors(e.response.data).join(' '))
      return false
    }
  }

  @action.bound
  async renameOption ({ uuid, optionName, newOptionName }) {
    const fieldName = getOptionNameFieldName(uuid)
    unset(this.errors.fieldErrors, fieldName)
    const option = this.options.find(option => option.uuid === uuid)
    const biEvent = await this.logProductEdited({
      settingTab: TAB_VARIANTS,
      settingField: `Rename Option: ${option.key} (${uuid})`,
      oldValue: optionName,
      newValue: newOptionName,
    })
    try {
      const response = await renameRetailerItemOption(
        this.uuid,
        adaptRenameOption({ optionName, newOptionName }),
        getBiHeadersFromEvent(biEvent)
      )
      this.assignData(adaptImportListItem(response.data))
      await this.loadVariants()
      return true
    } catch (e) {
      set(this.errors.fieldErrors, fieldName, flattenApiErrors(e.response.data).join(' '))
      return false
    }
  }

  @action.bound
  async togglePriceLocked () {
    const action = this.priceLocked ? unlockRetailerItemPrice : lockRetailerItemPrice
    this.priceLockedChangeInProgress = true

    try {
      const biEvent = await this.logAutoPriceStatusChanged({
        selectionType: 'toggle',
        status: this.priceLocked,
      })
      await action(this.uuid, getBiHeadersFromEvent(biEvent))
    } catch (e) {
      this.error = e.response.data
      this.priceLockedChangeInProgress = false
      return false
    }

    const promises = [this.fetch()]
    // If unlocking prices, reload variants as well as their prices could change
    if (action === unlockRetailerItemPrice) promises.push(this.loadVariants())
    await Promise.all(promises)
    this.priceLockedChangeInProgress = false
    return true
  }

  /**
   * Select or deselect an image to be exported with the product.
   * @param {string} id
   * @param {boolean} selected
   * @returns {Promise<boolean>}
   */
  @action.bound
  async selectImage ({ id, selected }) {
    const image = this.orderedImages.find(image => image.id === id)
    const biEvent = await this.logProductEdited({
      settingTab: 'media',
      settingField: 'imageSelectedChanged',
      oldValue: adaptImageForBiEvent(image),
      newValue: adaptImageForBiEvent({ ...image, selected }),
    })
    return await image.setSelected(selected, biEvent)
  }

  @action.bound
  async setMainImage (uuid) {
    this.isChangingMainImage = true
    const biEvent = await this.logProductEdited({
      settingTab: TAB_MEDIA,
      settingField: 'mainImage',
      oldValue: adaptImageForBiEvent(this.orderedImages.find(image => image.isMain)),
      newValue: adaptImageForBiEvent(this.orderedImages.find(image => image.id === uuid)),
    })
    const updated = await this.patch({ main_image_id: uuid }, biEvent)
    if (!updated) {
      this.isChangingMainImage = false
      return false
    }
    this.images.forEach(image => { image.isMain = image.id === uuid })
    this.isChangingMainImage = false
    return true
  }

  @action.bound
  async export () {
    return await this.parent.exportItem(this)
  }

  @action.bound
  remove () {
    this.parent.productRemove.chooseProduct(this)
  }

  @action.bound
  async fetch () {
    const response = await getRetailerItem(this.uuid)
    this.assignData(adaptImportListItem(response.data))
  }

  @action.bound
  async logRemoveProductClicked (biData = {}) {
    return await this.root.biLoggerStore.log(removeProductClicked({ ...this.biEventData, ...biData }))
  }

  @action.bound
  async logProductEdited (biData = {}) {
    return await this.root.biLoggerStore.log(productEdited({ ...this.biEventData, ...biData }))
  }

  @computed get biEventData () {
    return {
      ...super._biEventData,
      productTypes: this.productType?.name || null,
      productTags: this.tags.map(t => t.name),
    }
  }
}

export default ImportListProductStore
