import React from 'react'

import PropTypes from 'prop-types'

import { Link } from '@material-ui/core'
import { observer } from 'mobx-react'
import { Trans } from 'react-i18next'

import { ITEM_TABS_ID, TABS } from 'shared/constants/marketplaceItem'
import { useTranslation } from 'shared/hooks'
import { getIntegrationName } from 'shared/utils/integrations'

import { MarketplaceItemDataRow } from 'retailer/components/organisms'
import { ItemStore } from 'retailer/stores'

import style from './MarketplaceItemReturnsData.module.scss'

const MarketplaceItemReturnsData = ({ marketplaceItemStore }) => {
  const { t } = useTranslation('productPage')
  const { item, selectTab } = marketplaceItemStore

  if (item.is3rdPartySupplier) {
    return (
      <>
        <MarketplaceItemDataRow
          title={t('productData.thirdPartyReturns.title')}
          content={
            <div className={style.Container}>
              {t('productData.thirdPartyReturns.content')}
            </div>
          }
        />
        <MarketplaceItemDataRow
          title=" "
          content={
            <Link href={item.externalUrl} target="_blank" rel="noopener noreferrer">
              {t('productData.thirdPartyReturns.cta', { platformName: getIntegrationName(item.inventoryType) })}
            </Link>
          }
        />
      </>
    )
  }

  return (
    <>
      <MarketplaceItemDataRow
        title={t('productData.returns.title')}
        content={
          <div className={style.Container}>
            <Trans t={t} i18nKey="productData.returns.content" />
          </div>
        }
      />
      <MarketplaceItemDataRow
        title=" "
        content={
          <Link
            href={`#${ITEM_TABS_ID}`}
            onClick={() => selectTab(TABS.RETURNS)}
          >
            {t('productData.returns.cta')}
          </Link>
        }
      />
    </>
  )
}

MarketplaceItemReturnsData.propTypes = {
  marketplaceItemStore: PropTypes.shape({
    selectTab: PropTypes.func.isRequired,
    item: PropTypes.instanceOf(ItemStore).isRequired,
  }).isRequired,
}

export default observer(MarketplaceItemReturnsData)
