import React from 'react'

import PropTypes from 'prop-types'

import {
  Button,
  Checkbox,
  Divider,
  Grid,
  Link,
  MenuItem,
  Paper,
  Tab,
  Tabs,
  Tooltip,
  Typography,
} from '@material-ui/core'
import { ErrorOutlineRounded, WarningRounded } from '@material-ui/icons'
import clsx from 'clsx'
import isEmpty from 'lodash/isEmpty'
import merge from 'lodash/merge'
import { observer } from 'mobx-react'
import { useSnackbar } from 'notistack'
import { FormProvider, useForm } from 'react-hook-form'
import { Link as RouterLink } from 'react-router-dom'

import {
  IMPORT_LIST_ITEM_TABS,
  TAB_DESCRIPTION,
  TAB_GENERAL, TAB_MEDIA,
  TAB_PRICING,
  TAB_VARIANTS,
} from 'shared/constants/importList'
import { useDeepCompareMemo, useTranslation } from 'shared/hooks'
import { importListItemType } from 'shared/types'
import { useRemoteErrors } from 'shared/utils/forms'
import { getMarketplaceItemUrl } from 'shared/utils/urls'

import { Image, PricingRangeDisplay } from 'retailer/components/molecules'
import {
  ImportListProductDescriptionTab,
  ImportListProductGeneralTab,
  ImportListProductMediaTab,
  ImportListProductPricingTab,
  ImportListProductVariantsTab,
  MoreButtonWithMenu,
} from 'retailer/components/organisms'
import {
  checkIfTabHasErrors,
  getOptionNameFieldName,
  parseRemoteErrorsForRhf,
  validateImportListProduct,
} from 'retailer/utils/importList'

import style from './ImportListProduct.module.scss'

const Quantity = observer(({ quantity, showDetails, testId }) => {
  const { t } = useTranslation('importList')

  let text = 'Out of stock'
  if (quantity > 0 && showDetails) text = t('main.productHeaderStock.label', { totalNumberOfProductsInStock: quantity })
  if (quantity > 0 && !showDetails) text = 'In stock'

  return (
    <Typography variant="body2" color={quantity > 0 ? 'inherit' : 'error'} data-testid={testId}>
      {text}
    </Typography>
  )
})

Quantity.propTypes = {
  quantity: PropTypes.number.isRequired,
  showDetails: PropTypes.bool,
  testId: PropTypes.string,
}

Quantity.defaultProps = {
  showDetails: true
}

const ImportListProduct = ({ item, currentRetailerAuthorization }) => {
  const { t } = useTranslation('importList')
  const { enqueueSnackbar } = useSnackbar()
  const rhf = useForm({
    mode: 'all',
    criteriaMode: 'firstError',
    context: { item, currentRetailerAuthorization },
    resolver: async (values, context) => (
      await validateImportListProduct(values, context, rhf.formState.dirtyFields)
    ),
    shouldFocusError: false,
    shouldUnregister: false,
    defaultValues: item.formDefaultValues,
  })
  const { errors, setError, register } = rhf

  register(`${TAB_DESCRIPTION}.description`)

  item.uniqueOptionNames
    .map(option => getOptionNameFieldName(option.uuid))
    .map(name => register(name))

  const { fieldErrors } = useRemoteErrors(item.allErrors, setError, parseRemoteErrorsForRhf)

  // Merge RHF and store errors into a single object
  // We can't rely on the `setError` behavior as errors set in this way are not kept in-between submits.
  // The downside is that it's now a developer's task to ensure errors are properly removed from store when needed.
  const allErrors = useDeepCompareMemo(
    () => merge({}, errors, fieldErrors, item.imagesErrors, item.variantsErrors),
    [errors, fieldErrors, item.variantsErrors, item.imagesErrors]
  )

  const tabs = new Map([
    [TAB_GENERAL, <ImportListProductGeneralTab item={item} errors={allErrors} key={TAB_GENERAL} />],
    [TAB_DESCRIPTION, <ImportListProductDescriptionTab item={item} errors={allErrors} key={TAB_DESCRIPTION} />],
    [TAB_VARIANTS, <ImportListProductVariantsTab item={item} errors={allErrors} key={TAB_VARIANTS} />],
    [TAB_PRICING, <ImportListProductPricingTab item={item} errors={allErrors} key={TAB_PRICING} />],
    [TAB_MEDIA, <ImportListProductMediaTab item={item} errors={allErrors} key={TAB_MEDIA} />],
  ])

  const handleCustomize = () => {
    item.logDesignClickedBiEvent()
    item.editDesign()
  }

  return (
    <Paper
      elevation={0}
      className={clsx(
        style.ImportListProduct,
        !item.available && style.unavailable,
      )}
      data-testid="importList-importListProduct"
    >
      <div className={style.HeadWrapper}>
        <div className={style.Head}>
          <Grid container alignItems="center" spacing={2} style={{ flexWrap: 'nowrap' }}>
            <Grid item>
              <Checkbox
                color="primary"
                edge="start"
                checked={item.selected}
                onClick={() => item.setSelected(!item.selected)}
                data-testid="importList-importListProduct-selectProductCheckbox"
              />
            </Grid>
            <Grid item>
              <Image
                image={item.mainImage}
                inProgress={item.isChangingMainImage}
                imgProps={{
                  className: clsx(style.HeadImage, item.isStyleCommerce && style.style_commerce)
                }}
                testId="importList-importListProduct-thumbnailImage"
              />
            </Grid>
            <Grid item xs className={style.ProductInfo}>
              <Link
                className={style.ProductName}
                component={RouterLink}
                to={getMarketplaceItemUrl(item.itemUuid, item.isPrintOnDemand)}
              >
                <Tooltip title={item.name}>
                  <Typography variant="body2" data-testid="importList-importListProduct-productName">
                    {item.name}
                  </Typography>
                </Tooltip>
              </Link>
              <Grid container spacing={1} className={style.PricingRanges}>
                <Grid item>
                  <PricingRangeDisplay
                    pricingNameOverride={`${t('main.productHeaderPrice.label')}:`}
                    pricing={item.pricingRanges.price}
                    currencyIso={item.currencyIso}
                    MoneyRangeProps={{
                      currencyDisplay: 'symbol',
                      testId: 'importList-importListProduct-productHeaderPrice'
                    }}
                  />
                </Grid>
                <Grid item>
                  <Divider orientation="vertical" />
                </Grid>
                <Grid item>
                  <PricingRangeDisplay
                    className={clsx(
                      (item.pricingRanges.profit?.from && item.pricingRanges.profit.from < 0) && style.negative
                    )}
                    pricingNameOverride={`${t('main.productHeaderProfit.label')}:`}
                    pricing={item.pricingRanges.profit}
                    currencyIso={item.currencyIso}
                    MoneyRangeProps={{
                      currencyDisplay: 'symbol',
                      testId: 'importList-importListProduct-productHeaderProfit'
                    }}
                  />
                </Grid>
              </Grid>
              <Quantity
                quantity={item.quantity} showDetails={!item.isPrintOnDemand}
                testId="importList-importListProduct-productHeaderQuantity"
              />
            </Grid>
            <Grid item>
              <MoreButtonWithMenu
                menuId={`${item.uuid}-moreMenu`}
                testId="importList-importListProduct-3dotsMenu"
                render={({ close }) => {
                  const menuItems = [
                    <MenuItem
                      key="viewProduct"
                      component={RouterLink}
                      to={getMarketplaceItemUrl(item.itemUuid, item.isPrintOnDemand)}
                      onClick={() => item.logViewDetailsClickedBiEvent()}
                      data-testid="importList-importListProduct-3dotsMenu-viewProduct"
                    >
                      {t('main.moreActionsAliexpress.label', { externalSupplierName: 'Modalyst' })}
                    </MenuItem>,
                    <MenuItem disabled key="revertToOriginal" data-testid="importList-importListProduct-3dotsMenu-revert">
                      {t('main.moreActionsOriginal.label')}
                    </MenuItem>,
                    <MenuItem
                      key="orderSample"
                      onClick={() => {
                        close()
                        item.orderSample()
                      }}
                      data-testid="importList-importListProduct-3dotsMenu-orderSample"
                    >
                      {t('main.moreActionsSample.label')}
                    </MenuItem>,
                    <MenuItem
                      key="remove"
                      onClick={() => {
                        close()
                        item.remove()
                      }}
                      className={style.RemoveAction}
                      data-testid="importList-importListProduct-3dotsMenu-remove"
                    >
                      {t('main.moreActionsRemove.label')}
                    </MenuItem>,
                  ]
                  if (item.is3rdPartySupplier) {
                    menuItems.unshift(
                      <MenuItem
                        key="viewOn3rdPartyMarketplace"
                        href={item.productExternalUrl}
                        target="_blank"
                        component="a"
                        rel="noopener noreferrer"
                        onClick={close}
                        data-testid="importList-importListProduct-3dotsMenu-viewOn3rdParty"
                      >
                        {t('main.moreActionsAliexpress.label', { externalSupplierName: item.thirdPartySupplierName })}
                      </MenuItem>
                    )
                  }
                  return menuItems
                }}
              />
            </Grid>
            {item.isPrintOnDemand && (
              <Grid item>
                <Button
                  onClick={handleCustomize}
                  variant="contained"
                  color="primary"
                  className={style.CustomizeButton}
                  data-testid="importList-importListProduct-customizeButton"
                >
                  {t('main.printOnDemandCustomize.button')}
                </Button>
              </Grid>
            )}
            <Grid item>
              <Tooltip
                title={
                  (!item.available || !isEmpty(allErrors))
                    ? 'Fix errors for this product before adding it to the store'
                    : ''
                }
              >
                <span>
                  <Button
                    className={style.MainButton}
                    variant="contained"
                    color="primary"
                    disabled={!item.available || !isEmpty(allErrors)}
                    onClick={async () => {
                      const exported = await item.export()
                      if (exported) enqueueSnackbar(t('main.productAddedToStore.toast'), { variant: 'success' })
                      else enqueueSnackbar('Could not add product to store', { variant: 'error' })
                    }}
                    data-testid="importList-importListProduct-addToStoreButton"
                  >
                    {t('main.productAddToStore.cta')}
                  </Button>
                </span>
              </Tooltip>
            </Grid>
          </Grid>
        </div>
      </div>
      <div className={style.Body}>
        <Tabs
          value={item.currentTab}
          onChange={(_, value) => item.changeTab(value)}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
          className={style.Tabs}
          data-testid="importList-importListProduct-tabs"
        >
          {Array.from(IMPORT_LIST_ITEM_TABS).map(([tabId, tabName]) => {
            const label = tabId === TAB_VARIANTS
              ? t(
                tabName,
                {
                  numberOfAvailableVariants: item.computedActiveVariantsCount,
                  totalNumberOfVariants: item.variantsCount
                }
              )
              : t(tabName)
            let icon = checkIfTabHasErrors(allErrors[tabId])
              ? <ErrorOutlineRounded className={clsx(style.TabError, style.blocking)} />
              : null
            if (
              !icon &&
              tabId === TAB_PRICING &&
              item.pricingRanges?.profit?.from &&
              item.pricingRanges.profit.from < 0
            ) {
              icon = <WarningRounded className={style.TabError} />
            }
            return (
              <Tab
                key={tabId}
                value={tabId}
                label={label}
                icon={icon}
                className={style.TabLabel}
                data-testid={`importList-importListProduct-tabs-${tabId}`}
              />
            )
          })}
        </Tabs>
        <FormProvider {...rhf}>
          <form>
            {tabs.get(item.currentTab) || null}
          </form>
        </FormProvider>
      </div>
    </Paper>
  )
}

ImportListProduct.propTypes = {
  item: importListItemType.isRequired,
  currentRetailerAuthorization: PropTypes.string,
}

ImportListProduct.defaultProps = {
  currentRetailerAuthorization: '',
}

export default observer(ImportListProduct)
