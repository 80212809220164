import React from 'react'

const INVENTORY_TYPE_INDIE_BRANDS = 'indie_brands'
const INVENTORY_TYPE_PRINT_ON_DEMAND = 'print_on_demand'
const INVENTORY_TYPE_BEAUTY = 'beauty'
const INVENTORY_TYPE_ALIBABA = 'alibaba'
const INVENTORY_TYPE_ALIEXPRESS = 'aliexpress'
const INVENTORY_TYPE_TRENDY_ITEMS = 'trendy_items'
// const INVENTORY_TYPE_NAME_BRANDS = 'name_brands'

const _shippingRateText1 = 'Automatically add the Shipping Rate into the Compare At Price and Product Price of each item, so you can easily offer free shipping without it affecting margins.'

const _shippingRateText2 = <>Please note, this will automatically add the shipping rate from the supplier’s location to your store’s location.To learn more about Shipping, go <a href="http://support.modalyst.co/modalyst-for-retailers/shipping-and-returns/how-are-shipping-costs-determined" target="_blank" rel="noreferrer">here</a></>

const _shippingRateText3 = 'Please note, this will automatically add the domestic shipping rate into the price of the item. We do not automatically add the international shipping rate, as that varies by location.'

const changePriceEndingText = 'Allow for consistent price endings by automatically rounding up the retail price to the cent value you choose.'

const _buildOverrideShippingRateText = itemsName => (
  `You may select to override the suppliers' cost of shipping with a different amount. This gives you the option to increase or decrease the shipping rate while offering free shipping to your customers in your store. This amount will override the shipping rate for ${itemsName}. The same rate will be applied to both domestic and international suppliers. The shipping rate override is only applied when you have selected to "ADD" the shipping rate to the item price.`
)

export const INVENTORY_TYPES_CONFIG = new Map([
  [INVENTORY_TYPE_PRINT_ON_DEMAND, {
    name: 'Print on demand',
    description: ['Create private label products with your own logo and design, and ship them directly to customers.'],
    productCost: 'Print on Demand Item Cost',
    shippingRateText: ['Automatically add the default $6 shipping rate to the retail price so you can offer free shipping to customers.'],
    overrideShippingRateTitle: 'Change the shipping rate',
    overrideShippingRateText: ['Your new shipping rate will be added to the retail price. Increasing it can boost profits; decreasing it means you pay the difference to the supplier if it’s less than $6.', 'Your storefront shipping settings will still apply to customers.'],
    changePriceEnding: [changePriceEndingText],
  }],

  [INVENTORY_TYPE_INDIE_BRANDS, {
    name: 'Indie brands',
    description: [
      'Modalyst has a network of +600 handpicked, independent suppliers from US/Europe. Differentiate your store with our unique suppliers featuring apparel, accessories, home & beauty.',
      'We keep pricing consistent with supplier\'s product price. We have automatically set the markup to x1.67, which is the Suggested Retail Price that the supplier has for each item. You can adjust this to change your margins.'
    ],
    productCost: 'Indie Brand Item Cost',
    shippingRateText: [_shippingRateText1, _shippingRateText2],
    overrideShippingRateTitle: 'Override Shipping Rate for all Independent Brand Items with this Amount',
    overrideShippingRateText: [_buildOverrideShippingRateText('all Independent Brands')],
    changePriceEnding: [changePriceEndingText],
  }],

  // TODO: Deprecated - we no longer use Name Brands and this could be removed probably
  // [INVENTORY_TYPE_NAME_BRANDS, {
  //   name: 'Name brands',
  //   description: [
  //     'Now you can sell items from well-known brands like Burberry, Moschino, Hogan, Puma, Armani, Moncler, and many more. All orders are shipped from Italy, and arrive in 3 business days for one flat shipping rate to US, Canada, Australia and New Zealand, 3-5 working days to Europe and Africa.'
  //   ],
  //   productCost: 'Name Brands Item Cost',
  //   shippingRateText: [_shippingRateText1],
  //   overrideShippingRateTitle: 'Override Shipping Rate for all Name Brands Items with this Amount',
  //   overrideShippingRateText: [
  //     'You may select to override the suppliers\' cost of shipping with a different amount. This gives you the option to increase or decrease the shipping rate while offering free shipping to your customers in your store. This amount will override the shipping rate for all Name Brands. The shipping rate override is only applied when you have selected to "ADD" the shipping rate to the item price.'
  //   ],
  //   flatShippingRate: {
  //     title: 'Standard shipping to United States',
  //     text: [
  //       'Together with our Name Brands partnership, we have negotiated a standard flat rate for UPS packages to be sent from Italy worldwide. Europe: Free Shipping (DHL); USA, Canada: $5; Australia, Asia, New Zealand, Central/South America: $10; Africa: $15'
  //     ],
  //     value: '5.00',
  //   }
  // }],

  [INVENTORY_TYPE_TRENDY_ITEMS, {
    name: 'Trendy items',
    description: [
      'Source and sell products from our top trending brands - featuring tens of thousands of products for young women and teens. With an affordable selection of trendy apparel and accessories, this category has everything from winter jackets and sweaters, to summer swimwear and tank tops.'
    ],
    productCost: 'Trendy Item Cost',
    shippingRateText: [
      _shippingRateText1,
      'Please note, this will automatically add the shipping rate from the supplier’s location to your store’s location. To learn more about Shipping, go here'
    ],
    overrideShippingRateTitle: 'Override Shipping Rate for all Trendy Items with this Amount',
    overrideShippingRateText: [_buildOverrideShippingRateText('Trendy Brands')],
    changePriceEnding: [changePriceEndingText],
  }],

  [INVENTORY_TYPE_ALIEXPRESS, {
    name: 'AliExpress',
    description: [
      'Offer a wide variety of products Sell anything such as kitchen gadgets and electronics to baby clothing and home decor. Most items ship from China. Faster shipping is available.'
    ],
    productCost: 'AliExpress Item Cost',
    shippingRateText: [_shippingRateText1, _shippingRateText3],
    overrideShippingRateTitle: 'Override Shipping Rate for all AliExpress Items with this Amount',
    overrideShippingRateText: [_buildOverrideShippingRateText('AliExpress Goods')],
    changePriceEnding: [changePriceEndingText],
  }],

  [INVENTORY_TYPE_ALIBABA, {
    name: 'Alibaba',
    description: [
      'Offer a wide variety of products Sell anything such as kitchen gadgets and electronics to baby clothing and home decor. Most items ship from China. Faster shipping is available.'
    ],
    productCost: 'Alibaba Item Cost',
    shippingRateText: [_shippingRateText1, _shippingRateText3],
    overrideShippingRateTitle: 'Override Shipping Rate for all Alibaba Items with this Amount',
    overrideShippingRateText: [_buildOverrideShippingRateText('Alibaba Goods')],
    changePriceEnding: [changePriceEndingText],
  }],

  [INVENTORY_TYPE_BEAUTY, {
    name: 'Beauty',
    description: ['Offer a wide variety of beauty products.'],
    productCost: 'Beauty Item Cost',
    shippingRateText: [_shippingRateText1, _shippingRateText3],
    overrideShippingRateTitle: 'Override Shipping Rate for all Beauty Items with this Amount',
    overrideShippingRateText: [_buildOverrideShippingRateText('Beauty Goods')],
    changePriceEnding: [changePriceEndingText],
  }],
])

export const INVENTORY_TYPE_TABS = [...INVENTORY_TYPES_CONFIG.keys()]

export const MARKUP_INVALID_ERROR = 'Please provide a valid value for markup'
export const MARKUP_MULT_BY_ZERO_ERROR = 'You have set your Pricing Rules to multiply all products by 0. This means that your products will all be set to a price of 0.'
export const MARKUP_COMPARE_AT_PRICE_LOWER_THAN_PRICE_ERROR = 'You cannot set the "Compare at Price" to be lower than the "Price"'
