import { action, observable } from 'mobx'

import { buySubscription } from 'shared/api/subscriptions'

class SubscriptionPlanChangeStore {
  @observable managePlanStore
  @observable plan
  @observable billingPeriod

  @observable error = {
    hasError: false,
    message: null
  }

  @observable inProgress = false
  @observable isSuccess = false
  @observable canReuseBillingInfo = false
  @observable reuseBillingInfo = false

  @observable recurlyError
  @observable recurlyToken = {
    id: null,
    type: null,
  }

  constructor (managePlanStore, plan) {
    this.managePlanStore = managePlanStore
    this.plan = plan
    this.billingPeriod = plan.wixCycle
    this.canReuseBillingInfo = !managePlanStore.retailerSubscription.plan.free
    this.reuseBillingInfo = !managePlanStore.retailerSubscription.plan.free
  }

  /**
   * Return the currently selected plan, but for given period (monthly or yearly).
   * @param {string} period Monthly or yearly
   * @returns {SubscriptionPlanStore|undefined}
   */
  getPlanForPeriod (period) {
    return this.managePlanStore.subscriptionPlans.find(
      plan => (plan.wixPlanId === this.plan.wixPlanId) && (plan.wixCycle === period)
    )
  }

  /**
   * @param {SubscriptionPlan} subscriptionPlan
   * @returns {boolean}
   */
  isCurrentPlan (subscriptionPlan) {
    return this.managePlanStore.retailerSubscription.plan.code === subscriptionPlan.code
  }

  /**
   * @param {boolean} hasError
   * @param {string[]} [messages]
   */
  @action.bound
  setError (hasError, messages = []) {
    this.error = {
      hasError,
      messages
    }
  }

  /**
   * @param {boolean} value
   */
  @action.bound
  setReuseBillingInfo (value) {
    this.reuseBillingInfo = value
  }

  @action.bound
  changeBillingPeriod (value) {
    this.billingPeriod = value
  }

  /**
   * Set recurly token. `id` is the actual token, and `type` is billing type (card, paypal, etc.).
   * @param {?string} id
   * @param {?string} type
   */
  @action.bound
  setRecurlyToken ({ id = null, type = null }) {
    this.recurlyToken = { id, type }
  }

  @action.bound
  setRecurlyError (error) {
    this.recurlyError = error
  }

  /**
   * @param {boolean} value
   */
  @action.bound
  setInProgress (value) {
    this.inProgress = value
  }

  @action.bound
  buyPlan () {
    this.setError(false)
    this.setInProgress(true)
    // User can change billing period within the modal itself, so let's always fetch
    // the proper plan for the selected period.
    const planToBuy = this.getPlanForPeriod(this.billingPeriod)
    return buySubscription({
      planCode: planToBuy.code,
      recurlyToken: this.recurlyToken?.id
    })
      .then(() => {
        // FIXME: We have to wait some time for the backend to process the actual change.
        //        Ideally we would have some websocket communication for these, but until then
        //        we have to hope that 3000ms is enough ;)
        return setTimeout(() => {
          this.managePlanStore.reload()
          this.isSuccess = true
          this.setInProgress(false)
        }, 3000)
      })
      .catch(e => {
        this.setError(true, e?.response?.data?.detail)
        this.setInProgress(false)
      })
  }
}

export default SubscriptionPlanChangeStore
