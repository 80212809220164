import { computed, observable } from 'mobx'

import { IMPORT_FORMATS } from 'shared/constants/imports'

class ImportStore {
  @observable importType
  @observable status

  constructor (data = {}) {
    Object.assign(this, data)
  }

  @computed get format () {
    return IMPORT_FORMATS.get(this.importType)
  }
}

export default ImportStore
