export const RETAILER_FILTER_ALL = 'all'
export const RETAILER_FILTER_ACTIVE = 'active'
export const RETAILER_FILTER_BLOCKED = 'blocked'

export const RETAILER_FILTER_MAP = new Map([
  [RETAILER_FILTER_ALL, 'All retailers'],
  [RETAILER_FILTER_ACTIVE, 'Active retailers'],
  [RETAILER_FILTER_BLOCKED, 'Blocked retailers'],
])

export const RETAILER_ORDERING_NAME = 'sender__store'
export const RETAILER_ORDERING_PRODUCTS_SYNCED = '-summary__products_synced_count'
export const RETAILER_ORDERING_ORDERS_COUNT = '-summary__orders_paid_count'
export const RETAILER_ORDERING_ORDERS_VALUE = '-summary__orders_paid_value'
