import React from 'react'

import PropTypes from 'prop-types'

import {
  Button,
  FormControlLabel,
  Radio,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core'
import { observer } from 'mobx-react'

import Money from 'shared/components/atoms/Money'
import Alert from 'shared/components/molecules/Alert'
import { DataModal } from 'shared/components/organisms'

import Country from 'retailer/components/atoms/Country'
import { OrderItemShippingMethodEditState } from 'retailer/stores'

import style from './OrderItemShippingMethodModal.module.scss'

const OrderItemShippingMethodModal = props => {
  const { store, onExited } = props
  const errors = store.errors?.nonFieldErrors
  const country = store.orderItem.purchaseOrder?.retailerOrder?.address?.country

  const [chosenShippingMethod, setChosenShippingMethod] = React.useState(store.shippingMethod)

  const handleChange = event => setChosenShippingMethod(event.target.value)
  const handleConfirm = () => store.updateShippingMethod(chosenShippingMethod)

  // load shipping method options on mount and set the currently chosen method in local state
  React.useEffect(() => {
    async function fetchShippingMethodData () {
      await store.fetchShippingMethodData()
      setChosenShippingMethod(store.shippingMethod)
    }
    fetchShippingMethodData()
  }, [store])

  return (
    <DataModal
      className={style.OrderItemShippingMethodModal}
      title="Choose shipping method"
      showSpinner={store.requestInProgress}
      hide={store.updateDone}
      onExited={onExited}
      renderBody={({ showSpinner }) => (

        <div className={style.OrderItemShippingMethodModalBody}>

          <div className={style.Information}>
            <Typography gutterBottom>
              The selected shipping method is displayed in the price table.
              This is provided by the supplier and based on the order's shipping address.
            </Typography>
            <Typography gutterBottom>
              Note: This is the cost to send an individual item and does not include
              premium or bulk sending options.
            </Typography>
            {country && (
              <Typography gutterBottom>
                Shipping price is calculated based on the destination country: <Country iso={country} />
              </Typography>
            )}
          </div>

          {Array.isArray(errors) && errors.length && (
            <Alert severity="error" className={style.ErrorContainer}>
              {errors.join(' ')}
            </Alert>
          )}

          <TableContainer className={style.ShippingMethodsTableContainer}>
            <Table size="small" className={style.ShippingMethodsTable}>
              <TableHead>
                <TableRow>
                  <TableCell>Shipping method</TableCell>
                  <TableCell width="30%">Estimated delivery time</TableCell>
                  <TableCell width="30%">Shipping cost</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {store.shippingMethodOptions && store.shippingMethodOptions.map(({
                  shippingProvider,
                  shippingCost,
                  minEstimatedDelivery,
                  maxEstimatedDelivery
                }) => (
                  <TableRow key={shippingProvider}>
                    <TableCell padding="checkbox">
                      <FormControlLabel
                        control={<Radio />}
                        disabled={showSpinner}
                        value={shippingProvider}
                        checked={chosenShippingMethod === shippingProvider}
                        onChange={handleChange}
                        name="shipping_method"
                        label={shippingProvider}
                        classes={{ label: style.RadioLabel }}
                      />
                    </TableCell>
                    <TableCell>
                      {minEstimatedDelivery === maxEstimatedDelivery
                        ? minEstimatedDelivery
                        : `${minEstimatedDelivery}-${maxEstimatedDelivery}`} days
                    </TableCell>
                    <TableCell><Money amount={shippingCost} /></TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

        </div>

      )}
      renderActions={({ dismissModal, showSpinner }) => (
        <>
          <Button onClick={dismissModal} color="primary" size="large" disabled={showSpinner}>Cancel</Button>
          <Button
            onClick={handleConfirm}
            color="primary"
            variant="contained"
            size="large"
            disabled={showSpinner || !chosenShippingMethod}
          >
            Update
          </Button>
        </>
      )}
    />
  )
}

OrderItemShippingMethodModal.propTypes = {
  store: PropTypes.instanceOf(OrderItemShippingMethodEditState).isRequired,
  onExited: PropTypes.func,
}

export default observer(OrderItemShippingMethodModal)
