import React, { forwardRef } from 'react'

import PropTypes from 'prop-types'

import { ButtonBase, Typography } from '@material-ui/core'
import { Add } from '@material-ui/icons'
import clsx from 'clsx'

import style from './FileUploadArea.module.scss'

const FileUploadArea = forwardRef(({ label, fullWidth, isDragActive, className, inputProps, ...other }, ref) => {
  const classNames = clsx(
    style.FileUploadArea,
    isDragActive && style.isDragging,
    fullWidth && style.isFullWidth,
    className)
  return (
    <ButtonBase ref={ref} component="div" className={classNames} {...other}>
      {inputProps && <input {...inputProps} />}
      <Typography>{label}</Typography>
      <Add />
    </ButtonBase>
  )
})

FileUploadArea.propTypes = {
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  className: PropTypes.string,
  isDragActive: PropTypes.bool,
  fullWidth: PropTypes.bool,
  /** Pass input element props to add a file input inside the area */
  inputProps: PropTypes.object,
}

FileUploadArea.defaultProps = {
  isDragActive: false,
  fullWidth: false,
}

export default FileUploadArea
