import React from 'react'

import PropTypes from 'prop-types'

import { Button, FormHelperText, FormLabel, Grid } from '@material-ui/core'
import { AddPhotoAlternateRounded, ImageRounded } from '@material-ui/icons'
import clsx from 'clsx'
import { observer } from 'mobx-react'
import ImageUploading from 'react-images-uploading'

import style from './ImageUpload.module.scss'

/**
 * Handles uploading a single image, it's removal and errors.
 * @param file
 * @param onChange
 * @param onClear
 * @param error
 * @param className
 * @param {Object} props
 * @returns {JSX.Element}
 * @constructor
 */
const ImageUpload = ({ file, onChange, onClear, error, className, ...props }) => {
  return (
    <ImageUploading
      value={file ? [file] : []}
      onChange={(imageList, _) => onChange(imageList[0])}
      {...props}
    >
      {({
        imageList,
        onImageUpload,
        onImageRemove,
        errors
      }) => {
        return (
          <Grid container alignItems="center" spacing={4} className={clsx(style.ImageUpload, className)}>
            <Grid item xs={12} sm="auto">
              <FormLabel className={style.LogoLabel}>Store logo</FormLabel>
              {!imageList.length && (
                <div className={clsx(style.Image, style.unfilled)}>
                  <ImageRounded className={style.Icon} />
                </div>
              )}
              {imageList.map((image, index) => {
                const src = typeof image === 'object' ? image.dataUrl : image
                return (
                  <div key={image}>
                    <img src={src} alt="" className={style.Image} />
                    <Button
                      variant="text"
                      color="primary"
                      size="small"
                      className={style.DeleteButton}
                      onClick={() => { if (onClear) onClear(); onImageRemove(index) }}
                    >
                      Delete
                    </Button>
                  </div>
                )
              })}
            </Grid>
            <Grid item xs={12} sm={8}>
              <Button
                variant="outlined"
                color="primary"
                size="large"
                component="label"
                startIcon={<AddPhotoAlternateRounded />}
                onClick={onImageUpload}
              >
                {imageList.length ? 'Change' : 'Upload'} Your Store Logo
              </Button>
              <FormHelperText>JPG, GIF or PNG. Max size of 800K</FormHelperText>
              {(errors && errors.maxFileSize) && (
                <FormHelperText error>This file is too large</FormHelperText>
              )}
              {(errors && errors.acceptType) && (
                <FormHelperText error>Only JPG, PNG and GIF files are accepted</FormHelperText>
              )}
              {error && (
                <FormHelperText error key={error}>{error.message}</FormHelperText>
              )}
            </Grid>
          </Grid>
        )
      }}
    </ImageUploading>
  )
}

ImageUpload.propTypes = {
  file: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  onChange: PropTypes.func.isRequired,
  onClear: PropTypes.func,
  error: PropTypes.shape({
    message: PropTypes.string,
    type: PropTypes.string
  }),
  className: PropTypes.string,
  ...ImageUploading.propTypes
}

ImageUpload.defaultProps = {
  file: null,
  className: ''
}

export default observer(ImageUpload)
