import React from 'react'

import {
  Button,
  CardActions,
  CardContent,
  CardHeader,
  Drawer,
  IconButton,
  Typography,
} from '@material-ui/core'
import { CloseRounded } from '@material-ui/icons'
import clsx from 'clsx'
import { observer } from 'mobx-react'

import { useTranslation } from 'shared/hooks'
import { filtersPanelType } from 'shared/types'

import style from './FiltersPanel.module.scss'

/**
 * A simple logic-less component to display a pop up panel. Most notably used for displaying filters.
 * @param {Boolean}   open          Whether the panel should show up.
 * @param {String}    title         Title of the panel, `Filters` by default.
 * @param {Number}    filtersCount  How many filters are applied at the given moment.
 * @param {function}  onSave        Function to run when clicking `Apply` button.
 * @param {function}  onClear       Function to run when `Clear all` button is clicked.
 * @param {function}  onClose   Function that will close the panel, i.e. change the `open` value.
 * @param {Node}      children      Content of the panel. Could be anything.
 * @returns {JSX.Element}
 * @constructor
 */
const FiltersPanel = ({
  open, title, filtersCount, onSave, onClear, onClose, className, children
}) => {
  const { t } = useTranslation('common')
  return (
    <Drawer
      data-testid="filtersPanel"
      keepMounted={false}
      open={open}
      anchor="right"
      onClose={onClose}
      className={clsx(style.FiltersPanel, className)}
      PaperProps={{ className: style.Paper }}
    >
      <>
        <CardHeader
          className={style.FiltersPanelHeader}
          title={title}
          action={
            <IconButton
              aria-label="close"
              data-testid="filtersPanelClose"
              color="primary"
              onClick={onClose}
            >
              <CloseRounded />
            </IconButton>
          }
        />
        <CardContent
          className={style.FiltersPanelContent}
        >
          {children}
        </CardContent>
        <CardActions
          className={style.FiltersPanelActions}
        >
          <Typography variant="body2">
            {!!filtersCount && (
              <span>
                {t('filtersPanel.filtersCount.label', { filtersCount })}
              </span>
            )}
            {onClear && (
              <Button
                color="primary"
                variant="text"
                onClick={onClear}
                data-testid="filtersClearButton"
              >
                {t('filtersPanel.clearFilters.label')}
              </Button>
            )}
          </Typography>
          <Button
            disabled={!onSave}
            color="primary"
            variant="contained"
            onClick={onSave}
            data-testid="filtersApplyButton"
          >
            {t('filtersPanel.applyFilters.label')}
          </Button>
        </CardActions>
      </>
    </Drawer>
  )
}

FiltersPanel.propTypes = {
  ...filtersPanelType.isRequired
}

FiltersPanel.defaultProps = {
  title: 'Filters',
  filtersCount: 0,
}

export default observer(FiltersPanel)
