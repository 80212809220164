import { observable } from 'mobx'

class AddressStore {
  @observable companyName
  @observable name
  @observable address
  @observable city
  @observable state
  @observable zipCode
  @observable country
  @observable phone

  /**
   * @param {Object} data Initial data
   */
  constructor (data = {}) {
    Object.assign(this, data)
  }
}

export default AddressStore
