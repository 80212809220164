/* eslint camelcase: 0 */

import { adaptSubscriptionPlan } from 'shared/adapters/subscriptions'
import { keysToCamelCase } from 'shared/utils/api'

const adaptRetailerSubscription = data => {
  return {
    validFrom: data.valid_from,
    validTo: data.valid_to,
    planId: data.plan,
    planName: data.plan_name,
    autoRenewalCancelled: data.auto_renewal_cancelled,
    isActive: data.is_active,
    productsLeft: data.products_left,
    isLimitless: data.is_limitless,
    warning: data.warning,
    plan: adaptSubscriptionPlan(data.plan)
  }
}

const adaptRetailerPricingRule = ({ price, compared_at_price, ...rest }) => {
  return {
    price: price ? keysToCamelCase(price) : null,
    comparedAtPrice: compared_at_price ? keysToCamelCase(compared_at_price) : null,
    ...keysToCamelCase(rest)
  }
}

export {
  adaptRetailerSubscription,
  adaptRetailerPricingRule
}
