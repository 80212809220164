import React from 'react'

import PropTypes from 'prop-types'

import { Grid, Paper, Typography, Button, Link } from '@material-ui/core'
import clsx from 'clsx'
import { observer } from 'mobx-react'
import { Link as RouterLink } from 'react-router-dom'

import { Time, Label } from 'shared/components/atoms'
import { Alert } from 'shared/components/molecules'
import { integrationIcons, integrationsNames, MODALYST } from 'shared/constants/integrations'

import { PurchaseOrderActions } from 'retailer/components/molecules'
import { OrderTable } from 'retailer/components/organisms'
import { PurchaseOrder as PurchaseOrderStore } from 'retailer/stores'

import style from './PurchaseOrder.module.scss'
import {
  ERROR_NO_SUPPLIER_CONNECTION,
  errorsReadable,
} from './utils'

const PlacementLabel = ({ isDone, testId }) => (
  <Label label={isDone ? 'Synced' : 'Pending Sync'} status={isDone ? 'success' : 'warning'} testId={testId} />
)

PlacementLabel.propTypes = {
  isDone: PropTypes.bool.isRequired,
  testId: PropTypes.string,
}

const PaymentLabel = ({ isDone, testId }) => (
  <Label label={isDone ? 'Paid' : 'Pending Payment'} status={isDone ? 'success' : 'warning'} testId={testId} />
)

PaymentLabel.propTypes = {
  isDone: PropTypes.bool.isRequired,
  testId: PropTypes.string,
}

const FulfillmentLabel = ({ isDone, testId }) => (
  <Label label={isDone ? 'Fulfilled' : 'Pending Fulfillment'} status={isDone ? 'success' : 'warning'} testId={testId} />
)

FulfillmentLabel.propTypes = {
  isDone: PropTypes.bool.isRequired,
  testId: PropTypes.string,
}

const getOrderAlertAction = (code, store) => {
  const integrationName = integrationsNames.get(store.orderType)
  switch (code) {
    case ERROR_NO_SUPPLIER_CONNECTION:
      return (
        <Button
          component={RouterLink}
          variant="outlined"
          color="primary"
          disableElevation
          to="/connect-suppliers"
          target="_blank"
        >
          Connect to {integrationName}
        </Button>
      )
    default:
      return undefined
  }
}

const PurchaseOrder = ({ store, className, testId }) => {
  const {
    modalystOrderNumber,
    externalOrderId,
    brand,
    orderType,
    alerts,
    info,
    courierName,
    trackingCode,
    is3rdPartySupplierOrder,
    isAlibaba,
    isCancelled,
    isRefunded,
    isSynced,
    isPaid,
    isFulfilled,
    expectedDeliveryTime,
  } = store

  const OrderTypeIcon = integrationIcons.get(orderType)

  return (
    <Paper variant="outlined" className={clsx(className, style.PurchaseOrder)} data-testid={testId}>
      <Grid container className={style.Wrapper}>

        <Grid item container className={style.OrderInformation}>
          <Grid item className={style.SupplierInfo}>
            {/* TODO: Bulk orders checkbox */}
            {/* <Checkbox */}
            {/*  disabled */}
            {/*  checked={false} */}
            {/*  color="primary" */}
            {/* /> */}
            {OrderTypeIcon && <OrderTypeIcon width={22} height={22} />}
            <Typography variant="subtitle2" className={style.SupplierName} data-testid={`${testId}-supplierName`}>
              {/* FIXME: this should be more generic, but right now applies only to the Alibaba orders */}
              {/* FIXME: this should be more generic, but right now applies only to the Alibaba orders */}
              {isAlibaba ? 'Alibaba' : brand}
            </Typography>
          </Grid>
          <Grid item className={style.OrderNumber}>
            {orderType !== MODALYST && (
              <Typography data-testid={`${testId}-externalOrderId`}>
                {integrationsNames.get(orderType)} order
                {externalOrderId ? ` #${externalOrderId}` : ' —'}
              </Typography>
            )}
            <Typography data-testid={`${testId}-modalystOrderNumber`}>
              Modalyst order #{modalystOrderNumber}
            </Typography>
            {courierName && (
              <Typography data-testid={`${testId}-courierName`}>
                Courier name: {courierName}
              </Typography>
            )}
            {trackingCode && (
              <Typography data-testid={`${testId}-trackingCode`}>
                Tracking code: {trackingCode}
              </Typography>
            )}
            {expectedDeliveryTime && (
              <Typography>
                Expected delivery time: <Time value={expectedDeliveryTime} testId={`${testId}-expectedDeliveryTime`} />
              </Typography>
            )}
            {store.designer.messageable && (
              <Typography>
                {'Supplier email: '}
                <Link href={`mailto:${store.designer.email}`} data-testid={`${testId}-supplierEmail`}>
                  {store.designer.email}
                </Link>
              </Typography>
            )}
          </Grid>

          <Grid item className={style.Statuses}>
            {isCancelled && <Label status="error" label="Cancelled" testId={`${testId}-cancelledLabel`} />}
            {isRefunded && <Label status="success" label="Refunded" testId={`${testId}-refundLabel`} />}
            {!isCancelled && !isRefunded && (
              <>
                {is3rdPartySupplierOrder && <PlacementLabel isDone={isSynced} testId={`${testId}-placementLabel`} />}
                {isSynced && <PaymentLabel isDone={isPaid} testId={`${testId}-paymentLabel`} />}
                {isPaid && <FulfillmentLabel isDone={isFulfilled} testId={`${testId}-fulfillmentLabel`} />}
              </>
            )}
          </Grid>
        </Grid>

        {alerts && alerts.length > 0 && (
          <Grid item className={style.Errors}>
            {alerts.map(({ code, message }) => (
              <Alert severity="error" key={code} action={getOrderAlertAction(code, store)} testId={`${testId}-alert`}>
                {errorsReadable.get(code) || message}
              </Alert>
            ))}
          </Grid>
        )}

        {info && info.length > 0 && (
          <Grid item className={style.Errors}>
            {info.map(({ code, message }) => (
              <Alert severity="info" key={code} testId={`${testId}-infoAlert`}>
                {message}
              </Alert>
            ))}
          </Grid>
        )}

        <Grid item className={style.OrderTable}>
          <OrderTable order={store} testId={`${testId}-orderTable`} />
        </Grid>

        <Grid item className={style.Actions}>
          <PurchaseOrderActions order={store} testId={`${testId}-orderActions`} />
        </Grid>

      </Grid>
    </Paper>
  )
}

PurchaseOrder.propTypes = {
  store: PropTypes.instanceOf(PurchaseOrderStore).isRequired,
  className: PropTypes.string,
  testId: PropTypes.string,
}

export default observer(PurchaseOrder)
