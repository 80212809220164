import { action, observable } from 'mobx'

const defaultFunc = () => new Promise((resolve, reject) => {
  reject(
    new Error('Invalid action specified')
  )
})

const noop = () => null

class SingleBatchActionStore {
  @observable parent
  @observable open = false
  @observable inProgress = false
  @observable success = false
  @observable error

  @observable actionName
  @observable actionFunc = defaultFunc

  @observable before = noop
  @observable after = noop

  constructor (parent, before = noop, after = noop) {
    this.parent = parent
    this.before = before
    this.after = after
  }

  @action.bound
  setOpen (value) {
    this.open = Boolean(value)
  }

  @action.bound
  startAction (actionName, actionFunc) {
    this.actionName = actionName
    this.actionFunc = actionFunc
    this.setOpen(true)
  }

  @action.bound
  run () {
    this.inProgress = true
    this.before()
    return this.actionFunc()
      .then(() => {
        this.success = true
        this.error = undefined
        this.after()
      })
      .catch(e => {
        this.error = e
        this.success = false
      })
      .finally(() => {
        this.inProgress = false
      })
  }

  @action.bound
  reset () {
    this.open = false
    this.inProgress = false
    this.success = false
    this.error = undefined

    this.actionName = undefined
    this.actionFunc = defaultFunc

    this.before = noop
    this.after = noop
  }
}

export default SingleBatchActionStore
