import React, { useEffect } from 'react'

import PropTypes from 'prop-types'

import {
  Box,
  Button, CircularProgress,
  FormControl,
  FormLabel, Grid,
  RadioGroup, Typography,
} from '@material-ui/core'
import { DescriptionRounded } from '@material-ui/icons'
import { Skeleton } from '@material-ui/lab'
import times from 'lodash/times'
import { observer } from 'mobx-react'

import { RadioTile } from 'shared/components/atoms'
import { Alert } from 'shared/components/molecules'
import { DataModal } from 'shared/components/organisms'
import { integrationIcons } from 'shared/constants/integrations'
import { useTranslation } from 'shared/hooks'

import { SyncListCsvExportStore } from 'retailer/stores'

import style from './SyncListCsvExportModal.module.scss'

const CSV_FILETYPE_GENERIC = 'genericcsv'
const CSV_FILETYPE_SHOPIFY = 'shopifycsv'

const SyncListCsvExportModal = ({ store }) => {
  useEffect(() => {
    store.fetchChoices()
  }, [])

  const { t } = useTranslation('syncedProducts')

  const skeleton = (
    <>
      <Grid container spacing={2} className={style.Selector}>
        {times(3, idx => {
          return (
            <Grid item xs={12} sm={6} md={4} key={idx}>
              <Skeleton variant="rect" height={150} width="100%" />
            </Grid>
          )
        })}
      </Grid>
    </>
  )

  const getTypeLabel = (type, name) => {
    // FIXME: refactor with #182547656
    switch (type) {
      case CSV_FILETYPE_GENERIC:
        return t('exportCSV.modal.generic.label')
      case CSV_FILETYPE_SHOPIFY:
        return t('exportCSV.modal.shopify.label')
    }
    return name
  }

  const selector = (
    <FormControl fullWidth className={style.Selector}>
      <FormLabel focused={false}>
        {t('exportCSV.modal.select.label')}
      </FormLabel>
      <RadioGroup
        row
        className={style.Group}
        value={store.selectedFormat}
        onChange={e => store.setSelectedFormat(e.target.value)}
      >
        {store.choices.map(({ type, name }) => {
          const integrationName = type.replace('csv', '')
          return (
            <RadioTile
              key={type}
              value={type}
              disabled={store.isExporting || store.isSuccess}
              icon={integrationIcons.get(integrationName) || DescriptionRounded}
              label={getTypeLabel(type, name)}
              size="small"
            />
          )
        })}
      </RadioGroup>
    </FormControl>
  )

  return (
    <DataModal
      className={style.SyncListCsvExportModal}
      title={t('exportCSV.modal.title')}
      maxWidth="md"
      fullWidth
      onExited={() => store.reset()}
      renderBody={() => {
        return (
          <Box mt={2}>
            {store.isSuccess && (
              <Alert severity="success" title="CSV Exported" className={style.Alert}>
                Your CSV file with {store.selectedCount} products has been successfully exported.
                It might take a few minutes for it to be delivered to your e-mail.
              </Alert>
            )}
            {store.error && (
              <Alert severity="error" title="CSV Not Exported" className={style.Alert}>
                <Typography gutterBottom>
                  Your CSV file with {store.selectedCount} could not be exported due to an error: {String(store.error)}
                </Typography>
                <Typography>
                  You can try exporting it again. If the error persists, please contact the support.
                </Typography>
              </Alert>
            )}
            <Typography gutterBottom>
              {t('exportCSV.modal.subtitle')}
            </Typography>
            {store.loading.isLoaded ? selector : skeleton}
          </Box>
        )
      }}
      renderActions={({ dismissModal }) => {
        if (store.isSuccess) {
          return (
            <Button variant="outlined" onClick={dismissModal}>
              Close
            </Button>
          )
        }
        return (
          <>
            <Button variant="outlined" onClick={dismissModal}>
              {t('exportCSV.modal.cancel.cta')}
            </Button>
            <Button
              variant="contained"
              color="primary"
              disabled={!store.canBeExported && !store.isExporting}
              onClick={() => store.export()}
              startIcon={store.isExporting && <CircularProgress size={20} />}
            >
              {t('exportCSV.modal.export.cta')}
            </Button>
          </>
        )
      }}
    />
  )
}

SyncListCsvExportModal.propTypes = {
  store: PropTypes.instanceOf(SyncListCsvExportStore).isRequired
}

export default observer(SyncListCsvExportModal)
