import React, { createContext, useContext } from 'react'

import ReactGA from 'react-ga'
import { useHistory } from 'react-router-dom'

function getPathFromLocation (location) {
  return location.pathname + location.search
}

function getEventConfig (action, labelArgs = null, value = 1) {
  const label = typeof action.label === 'function' ? action.label(labelArgs) : action.label
  return Object.assign({}, action, { value, label })
}

const trackingContext = createContext({
  initialized: false,
})

/**
 * Enqueue a GA event
 *
 * @param {Object} location A location object
*/
const reportPageview = (location) => {
  const currentPath = getPathFromLocation(location)
  ReactGA.set({ page: currentPath })
  ReactGA.pageview(currentPath)
}

/**
 * Enqueue a GA event
 *
 * @param {Object} action An event config object from ACTIONS
 * @param {Object} labelArgs Parameters for the label function
 * @param {number|string} value Event value
*/
const reportEvent = (action, labelArgs, value = 1) => {
  const eventConfig = getEventConfig(action, labelArgs, value)
  ReactGA.event(eventConfig)
}

/**
 * Enqueue a GA event and return a promise that resolves after `delay`
 *
 * @param {Object} action An event config object from ACTIONS
 * @param {Object} labelArgs Parameters for the label function
 * @param {number|string} value Event value
 * @param {number} delay The delay for the timeout in msec
 */
function reportEventDelayed (action, labelArgs, value = 1, delay = 300) {
  return new Promise((resolve, reject) => {
    reportEvent(action, labelArgs, value)
    setTimeout(() => {
      resolve(true)
    }, delay)
  })
}

/**
 * Google Analytics hook
 *
 * Use in components located in React Router tree
 * XXX: the hook and dependant functions use ReactGA, which is apparently not
 *  compatible with G-type streams and should only be used with Universal Analytics
 *  (with tracking id starting with 'UA-').
 *  You can set up a joint GA4+UA service in Google Analytics dashboard. Read more:
 *  https://stackoverflow.com/questions/62135901/reactga-not-working-for-g-type-tracking-id
 *
 * @returns {Object} Utility functions
 */
function useAnalytics (trackingId, debug = false) {
  const { listen } = useHistory()
  const context = useContext(trackingContext)

  React.useEffect(() => {
    if (trackingId && !context.initialized) {
      context.initialized = true
      ReactGA.initialize(trackingId, { debug, testMode: process.env.NODE_ENV === 'test' })

      // initial pageview
      ReactGA.pageview(getPathFromLocation(window.location))

      listen((location) => {
        reportPageview(location)
      })
    }
  }, [context.initialized, trackingId, debug])

  return { reportPageview, reportEvent, reportEventDelayed }
}

export {
  useAnalytics,
}
