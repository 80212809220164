import { action, observable } from 'mobx'

import { adaptSocialMediaErrors } from 'shared/adapters/settings'
import { getSocialMedia, saveSocialMedia } from 'shared/api/settings'
import { processApiError } from 'shared/api/utils'
import { LoadableStore } from 'shared/stores'

class SocialMediaSettingsStore {
  parent

  @observable loading = new LoadableStore()
  @observable isSaving = false
  @observable errors

  @observable facebook
  @observable instagram
  @observable website

  constructor (parent) {
    this.parent = parent
  }

  @action.bound
  fetch () {
    this.loading.setLoading()
    return getSocialMedia().then(response => {
      Object.assign(this, response.data)
      this.loading.setLoaded()
    })
  }

  @action.bound
  save (data) {
    this.isSaving = true
    return saveSocialMedia(data)
      .then(response => {
        Object.assign(this, response.data)
      })
      .catch(error => {
        this.errors = processApiError(error, adaptSocialMediaErrors)
        throw error
      })
      .finally(() => {
        this.isSaving = false
      })
  }
}

export default SocialMediaSettingsStore
