import { action, computed, observable } from 'mobx'

class FilterStore {
  mutable
  name
  data
  @observable _value
  @observable active

  /**
   * @param {String} name Name of the filter
   * @param {*} value Value of the filter
   * @param {Boolean} active Whether the filter should be active by default
   * @param {Boolean} mutable Should the value of the filter be mutable?
   *                          This should be false for selectable multi-filters.
   */
  constructor (name, value, active = false, mutable = true, data = undefined) {
    this.mutable = mutable
    this.name = name
    this._value = value
    this.active = active
    this.data = data
  }

  @computed get value () {
    return this._value
  }

  /**
   * For immutable filters, return the filter name as it represents the filter value.
   * @returns {*|null}
   */
  @computed get activeValue () {
    if (!this.mutable) return this.active ? this.name : null
    return this.value
  }

  @action.bound
  setFilter (value) {
    this._value = value
    this.activate()
  }

  @action.bound
  clearFilter () {
    if (this.mutable) this._value = null
    this.deactivate()
  }

  @action.bound
  setActive (value) {
    this.active = value
  }

  @action.bound
  activate () {
    this.active = true
  }

  @action.bound
  deactivate () {
    this.active = false
  }

  @action.bound
  toggle () {
    this.active = !this.active
  }
}

export default FilterStore
