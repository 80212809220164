import React, { forwardRef, useEffect, useState } from 'react'

import PropTypes from 'prop-types'

import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
  DragOverlay,
  defaultDropAnimation,
} from '@dnd-kit/core'
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  rectSortingStrategy,
  useSortable,
} from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import { Avatar, ButtonBase, IconButton, Paper, Zoom } from '@material-ui/core'
import { Delete, SwapHoriz } from '@material-ui/icons'
import clsx from 'clsx'
import xor from 'lodash/xor'
import { observer } from 'mobx-react'
import { useSnackbar } from 'notistack'
import { createPortal } from 'react-dom'
import ImageUploading from 'react-images-uploading'

import FileUploadArea from 'shared/components/molecules/FileUploadArea'

import SupplierPropTypes from 'supplier/propTypes'

import style from './ImageGallery.module.scss'

const DEFAULT_RULES = {
  maxNumber: 10,
  maxFileSize: 1 * 1024 * 1024, // max 1MB per file
  resolutionType: 'more',
  resolutionWidth: 500,
  resolutionHeight: 500,
}

const Image = observer(forwardRef((
  { imageStore, featured, selected, selectable, onRemoveClick, onImageClick, className, DragHandleProps, testId, ...other }, ref
) => {
  const { src, data } = imageStore
  const classNames = clsx(
    style.Image,
    style.Tile,
    featured && style.isFeatured,
    !!data && style.isLocal,
    selectable && style.isSelectable,
    selected && style.isSelected,
    className,
  )
  const Wrapper = selectable ? ButtonBase : Paper
  const WrapperProps = selectable ? { component: Paper, onClick: onImageClick } : {}

  const handleRemoveClick = event => {
    event.stopPropagation()
    onRemoveClick(event)
  }

  return (
    <Zoom in duration={500}>
      <Wrapper className={classNames} {...WrapperProps} {...other} ref={ref}>
        <Avatar variant="square" src={src || data} data-testid={`${testId}-image`} />
        {DragHandleProps && (
          <div className={clsx(style.ImageButton, style.DragHandle)} {...DragHandleProps}>
            <SwapHoriz />
          </div>
        )}
        {onRemoveClick && (
          <IconButton
            size="small" onClick={handleRemoveClick} className={clsx(style.ImageButton, style.RemoveButton)}
            data-testid={testId ? `${testId}-removeButton` : undefined}
          >
            <Delete />
          </IconButton>
        )}
      </Wrapper>
    </Zoom>
  )
}))

Image.propTypes = {
  imageStore: SupplierPropTypes.image,
  selected: PropTypes.bool,
  featured: PropTypes.bool,
  onRemoveClick: PropTypes.func,
  onImageClick: PropTypes.func,
  selectable: PropTypes.bool,
  className: PropTypes.string,
  DragHandleProps: PropTypes.object,
  testId: PropTypes.string,
}

Image.defaultProps = {
  featured: false,
  selected: false,
}

const SortableImage = ({ id, testId, ...props }) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({ id })

  const inlineStyle = {
    transform: CSS.Transform.toString(transform),
    transition,
  }

  return (
    <Image
      ref={setNodeRef}
      style={inlineStyle}
      DragHandleProps={{ ...attributes, ...listeners }}
      {...props}
      className={style.SortableImage}
      testId={testId}
    />
  )
}

SortableImage.propTypes = {
  id: PropTypes.string.isRequired,
  testId: PropTypes.string,
}

const DraggedImage = props => {
  useEffect(() => {
    document.body.style.cursor = 'grabbing'
    return () => {
      document.body.style.cursor = ''
    }
  }, [])
  return (
    <Image {...props} className={style.DraggedImage} elevation={8} />
  )
}

const defaultDropAnimationConfig = {
  ...defaultDropAnimation,
  dragSourceOpacity: 0.5,
}

const ErrorNotifier = ({ errors, rules }) => {
  const { enqueueSnackbar } = useSnackbar()
  const options = { variant: 'error' }
  useEffect(() => {
    if (!errors) return
    if (errors.maxNumber) {
      enqueueSnackbar(`The number of new images exceeds the maximum of ${rules.maxNumber}`, options)
    }
    if (errors.acceptType) {
      enqueueSnackbar('Some of the selected files are not images', options)
    }
    if (errors.maxFileSize) {
      enqueueSnackbar(`The image file size needs to be below ${Math.round(rules.maxFileSize / 1024 / 1024)}MB`, options)
    }
    if (errors.resolution) {
      enqueueSnackbar(`The image size needs to be above ${rules.resolutionWidth}x${rules.resolutionHeight} pixels`, options)
    }
  }, [errors])
  return null
}

const ImageList = observer(({ children, images }) => {
  return <>{children}</>
})

ImageList.propTypes = {
  images: PropTypes.arrayOf(SupplierPropTypes.image).isRequired,
  children: PropTypes.arrayOf(PropTypes.node),
}

const SortableImageList = ({ images, children, onSwap }) => {
  const [draggedId, setDraggedId] = useState(null)
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  )
  const getIndexById = id => images.findIndex(img => img.uuid === id)
  const draggedIndex = draggedId ? getIndexById(draggedId) : -1
  const draggedImage = draggedIndex > -1 ? images[draggedIndex] : undefined

  const handleDragStart = ({ active }) => {
    if (!active) return
    setDraggedId(active.id)
  }
  const handleDragCancel = () => {
    setDraggedId(null)
  }
  const handleDragEnd = ({ active, over }) => {
    setDraggedId(null)
    if (active.id !== over.id) {
      const oldIndex = getIndexById(active.id)
      const newIndex = getIndexById(over.id)
      onSwap(oldIndex, newIndex)
    }
  }

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
      onDragCancel={handleDragCancel}
    >
      <SortableContext
        items={images}
        strategy={rectSortingStrategy}
      >
        {children}
      </SortableContext>
      {createPortal(
        <DragOverlay
          adjustScale={false}
          dropAnimation={defaultDropAnimationConfig}
        >
          {draggedImage && <DraggedImage imageStore={draggedImage} />}
        </DragOverlay>,
        document.body
      )}
    </DndContext>
  )
}

SortableImageList.propTypes = {
  ...ImageList.propTypes,
  onSwap: PropTypes.func.isRequired,
}

const ImageGallery = ({
  images, onImagesChange, selectedImages, onSelectedImagesChange,
  disableSort, disableSelect, disableDelete, limitSelected, testId
}) => {
  const selectable = !disableSelect
  const sortable = !disableSort
  const deleteable = !disableDelete
  const rules = DEFAULT_RULES

  const toggleSelected = item => {
    if (
      onSelectedImagesChange && !disableSelect &&
      (!limitSelected || selectedImages.includes(item) || selectedImages.length < limitSelected)
    ) {
      onSelectedImagesChange(xor(selectedImages, [item]))
    }
  }
  const handleImagesChangedByUploader = (images, addIndex) => {
    if (onImagesChange) onImagesChange(images, addIndex)
  }
  const handleSwap = (oldIndex, newIndex) => {
    const swapped = arrayMove(images, oldIndex, newIndex)
    onImagesChange(swapped)
  }

  let ImageComponent, ListComponent, ListProps
  if (sortable) {
    ImageComponent = SortableImage
    ListComponent = SortableImageList
    ListProps = { onSwap: handleSwap }
  } else {
    ImageComponent = Image
    ListComponent = ImageList
    ListProps = {}
  }

  return (
    <ImageUploading
      multiple
      value={images}
      onChange={handleImagesChangedByUploader}
      dataURLKey="data"
      {...rules}
    >
      {({
        imageList,
        onImageUpload,
        onImageRemove,
        isDragging, // not related to sorting...
        dragProps, // ...but dragging file from a filesystem to a drop area
        errors,
      }) => (
        <div className={style.ImageGallery}>
          <ErrorNotifier errors={errors} rules={rules} />
          <ListComponent images={imageList} {...ListProps}>
            {imageList.map((item, index) => (
              <ImageComponent
                key={item.uuid}
                id={item.uuid}
                imageStore={item}
                featured={index === 0}
                selected={selectedImages.includes(item)}
                selectable={selectable}
                onImageClick={selectable ? () => toggleSelected(item) : undefined}
                onRemoveClick={deleteable ? () => onImageRemove(index) : undefined}
                testId={testId ? `${testId}-imageComponent` : undefined}
              />
            ))}
          </ListComponent>
          {onImagesChange && (
            <FileUploadArea
              label="Add photo"
              onClick={onImageUpload}
              isDragActive={isDragging} {...dragProps}
              className={style.Tile}
              data-testid={testId ? `${testId}-fileUploadArea` : undefined}
            />
          )}
        </div>
      )}
    </ImageUploading>
  )
}

ImageGallery.propTypes = {
  images: PropTypes.arrayOf(SupplierPropTypes.image),
  onImagesChange: PropTypes.func,
  selectedImages: PropTypes.arrayOf(SupplierPropTypes.image),
  onSelectedImagesChange: PropTypes.func,
  disableDelete: PropTypes.bool,
  disableSelect: PropTypes.bool,
  disableSort: PropTypes.bool,
  limitSelected: PropTypes.number,
  testId: PropTypes.string,
}

ImageGallery.defaultProps = {
  selectedImages: [],
}

export default observer(ImageGallery)
