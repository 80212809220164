export const BIEV_MARKETPLACE_SEARCH_APPLIED = 1
export const BIEV_MARKETPLACE_CATEGORY_CLICKED = 2
export const BIEV_VIEW_PRODUCT_DETAILS_CLICKED = 3
export const BIEV_DESIGN_PRODUCT_CLICKED = 4
export const BIEV_ADD_TO_IMPORT_LIST_CLICKED = 5
export const BIEV_REMOVE_PRODUCT_CLICKED = 6
export const BIEV_ADD_TO_SYNC_LIST_CLICKED = 7
export const BIEV_UNSYNC_PRODUCT_CLICKED = 8
export const BIEV_PRODUCT_AUTO_PRICE_CHANGED = 9
export const BIEV_EXPORT_PRODUCTS_TO_CSV_CLICKED = 10
export const BIEV_ORDER_SAMPLE_CLICKED = 11
export const BIEV_PLACE_SAMPLE_ORDER_CLICKED = 12
export const BIEV_PAGE_LOADED = 1002
export const BIEV_PRODUCT_EDITED = 13
export const BIEV_USER_PROFILE_MENU_TRIGGERED = 14
export const BIEV_STORE_SELECTOR_TRIGGERED = 15
export const BIEV_ADD_NEW_STORE_CLICKED = 16
export const BIEV_ADD_NEW_STORE_MODAL_SHOWN = 17
export const BIEV_ADD_NEW_STORE_MODAL_NEXT_CLICKED = 18
export const BIEV_CONNECT_STORE_MODAL_STORE_NAME_SUBMITTED = 19
export const BIEV_GO_TO_MY_STORES_CLICKED = 20
export const BIEV_SELECT_STORE_CLICKED = 21
export const BIEV_STORE_CARD_3DOTS_CLICKED = 22
export const BIEV_DELETE_STORE_CLICKED = 23
export const BIEV_DELETE_STORE_CONFIRM_MODAL_SHOWN = 24
export const BIEV_DELETE_STORE_CONFIRM_MODAL_CONFIRMED = 25
export const BIEV_DISCONNECT_STORE_CLICKED = 26
export const BIEV_DISCONNECT_STORE_CONFIRM_MODAL_SHOWN = 27
export const BIEV_ALIBABA_ALIEXPRESS_SETTINGS_CLICKED = 28
export const BIEV_CONNECT_STORE_CLICKED = 29
export const BIEV_CONNECT_STORE_MODAL_SHOWN = 30
export const BIEV_CONNECT_STORE_MODAL_NEXT_CLICKED = 31
export const BIEV_UPGRADE_STORE_CLICKED = 32
export const BIEV_DISCONNECT_STORE_CONFIRM_MODAL_CONFIRMED = 33
export const BIEV_LOGOUT_CLICKED = 34
export const BIEV_POD_EDITOR_SAVE_CLICKED = 42
export const BIEV_POD_EDITOR_LOADED = 43
export const BIEV_PRICING_RULES_SAVE_CLICKED = 53
export const BIEV_PRICING_RULES_SAVE_CONFIRMED = 54

export const BI_EVENT_COMMON_PARAMS = [
  'uuid', 'msid', 'instanceId', 'accountId', 'businessId', 'retailerStorePlatform',
  'isPremium', 'subscriptionPlanId', 'storeUrl',
  'correlationId', 'isDebug',
]

/**
 * Mock event id.
 *
 * When developing, use as a provision for events not yet published in the BI catalog.
 */
export const BIEV_MOCK = 999

/** Use as a provision for missing updates to bi-logger-modalyst  */
export const mockBiEventFunction = params => (
  { src: 145, endpoint: 'modalyst', evid: BIEV_MOCK, params }
)

/** Specifies fields for each evid so we can eg. clean the data by removing extra fields */
export const BI_EVENT_PARAMS_MAP = new Map([
  [BIEV_MOCK, ['origin', 'eventName']],
  [BIEV_MARKETPLACE_SEARCH_APPLIED, [
    'marketplace', 'categoryPath', 'searchTerm', 'duration', 'resultsNum', 'batchSize',
    'orderingType',
  ]],
  [BIEV_MARKETPLACE_CATEGORY_CLICKED, [
    'marketplace', 'categoriesPath', 'pathLength', 'resultsNum',
  ]],
  [BIEV_VIEW_PRODUCT_DETAILS_CLICKED, [
    'marketplace', 'origin', 'retailerProductId', 'modalystProductId',
    'appliedFilters', 'appliedSort', 'position',
  ]],
  [BIEV_DESIGN_PRODUCT_CLICKED, [
    'marketplace', 'origin', 'retailerProductId', 'modalystProductId', 'supplierProductId',
    'productName', 'appliedFilters', 'appliedSort', 'position',
  ]],
  [BIEV_ADD_TO_IMPORT_LIST_CLICKED, [
    'marketplace', 'origin', 'modalystProductId', 'productName', 'productDescription',
    'shippingRate', 'itemsCost', 'retailerPrices', 'isDynamicPrice', 'retailerVariantIds',
    'modalystVariantIds', 'variantsCount', 'supplierId', 'currency', 'isShippingInCost',
    'searchTerm', 'position', 'mainImageUrl', 'imagesNum', 'productCategory',
    'productTypes', 'productTags', 'options',
    'podDistinctImagesUploaded', 'podTotalImagesUploaded', 'podImagesDetails', 'podTextAdded',
  ]],
  [BIEV_ADD_TO_SYNC_LIST_CLICKED, [
    'marketplace', 'origin', 'isBulk',
    'retailerProductId', 'modalystProductId', 'productName', 'productDescription',
    'itemsCost', 'retailerPrices', 'isDynamicPrice',
    'retailerVariantIds', 'modalystVariantIds', 'variantsCount', 'hiddenVariantsCount', 'hiddenVariantIds',
    'currency', 'isShippingInCost', 'mainImageUrl', 'imagesNum',
  ]],
  [BIEV_PRODUCT_AUTO_PRICE_CHANGED, [
    'marketplace', 'origin', 'status', 'selectionType',
    'retailerProductId', 'modalystProductId', 'supplierProductId', 'productName',
    'retailerVariantIds', 'modalystVariantIds', 'variantsCount',
    'supplierId', 'isBulk',
  ]],
  [BIEV_EXPORT_PRODUCTS_TO_CSV_CLICKED, [
    'marketplace', 'origin',
    'retailerProductId', 'modalystProductId', 'supplierProductId', 'productName',
    'retailerVariantIds', 'modalystVariantIds', 'variantsCount', 'supplierId',
    'isBulk',
  ]],
  [BIEV_ORDER_SAMPLE_CLICKED, [
    'marketplace', 'origin',
    'retailerProductId', 'modalystProductId', 'supplierProductId', 'productName',
    'retailerVariantIds', 'modalystVariantIds', 'variantsCount', 'supplierId',
  ]],
  [BIEV_PLACE_SAMPLE_ORDER_CLICKED, [
    'correlationId', 'marketplace', 'origin',
    'retailerProductId', 'modalystProductId', 'supplierProductId', 'productName',
    'retailerVariantIds', 'modalystVariantIds', 'variantsCount', 'supplierId',
    'retailerOrderId', 'modalystOrderId', 'modalystOrderNumber', 'itemsQuantity',
    'numItems', 'numProducts', 'totalAmount', 'shippingRate', 'itemCost',
    'isShippingInCost', 'modalystServiceFee',
  ]],
  [BIEV_REMOVE_PRODUCT_CLICKED, [
    'marketplace', 'origin',
    'retailerProductId', 'modalystProductId', 'supplierProductId', 'productName',
    'retailerVariantIds', 'modalystVariantIds', 'variantsCount', 'supplierId', 'isBulk',
  ]],
  [BIEV_UNSYNC_PRODUCT_CLICKED, [
    'marketplace', 'origin',
    'retailerProductId', 'modalystProductId',
    'retailerVariantIds', 'modalystVariantIds',
    'isBulk',
  ]],
  [BIEV_PRODUCT_EDITED, [
    'marketplace', 'origin', 'retailerProductId', 'modalystProductId', 'supplierProductId', 'shippingRate',
    'itemsCost', 'retailerPrices', 'isDynamicPrice', 'retailerVariantIds', 'modalystVariantIds', 'variantsCount',
    'supplierId', 'currency', 'isShippingInCost', 'settingTab', 'settingField', 'newValue', 'oldValue',
  ]],
  [BIEV_USER_PROFILE_MENU_TRIGGERED, [
    'origin',
  ]],
  [BIEV_STORE_SELECTOR_TRIGGERED, [
  ]],
  [BIEV_ADD_NEW_STORE_CLICKED, [
    'origin',
  ]],
  [BIEV_ADD_NEW_STORE_MODAL_SHOWN, [
    'origin',
  ]],
  [BIEV_ADD_NEW_STORE_MODAL_NEXT_CLICKED, [
    'origin', 'storeName', 'action', 'country',
  ]],
  [BIEV_CONNECT_STORE_MODAL_STORE_NAME_SUBMITTED, [
    'storeName', 'targetStoreId',
  ]],
  [BIEV_GO_TO_MY_STORES_CLICKED, [
    'origin',
  ]],
  [BIEV_SELECT_STORE_CLICKED, [
    'origin', 'storeName', 'targetStoreId',
  ]],
  [BIEV_STORE_CARD_3DOTS_CLICKED, [
    'storeName', 'targetStoreId',
  ]],
  [BIEV_DELETE_STORE_CLICKED, [
    'origin', 'storeName', 'targetStoreId',
  ]],
  [BIEV_DELETE_STORE_CONFIRM_MODAL_SHOWN, [
    'storeName', 'targetStoreId',
  ]],
  [BIEV_DELETE_STORE_CONFIRM_MODAL_CONFIRMED, [
    'storeName', 'targetStoreId',
  ]],
  [BIEV_DISCONNECT_STORE_CLICKED, [
    'storeName', 'targetStoreId',
  ]],
  [BIEV_DISCONNECT_STORE_CONFIRM_MODAL_SHOWN, [
    'storeName', 'targetStoreId',
  ]],
  [BIEV_ALIBABA_ALIEXPRESS_SETTINGS_CLICKED, [
    'origin',
  ]],
  [BIEV_CONNECT_STORE_CLICKED, [
    'origin', 'storeName', 'targetStoreId',
  ]],
  [BIEV_CONNECT_STORE_MODAL_SHOWN, [
    'storeName', 'targetStoreId',
  ]],
  [BIEV_CONNECT_STORE_MODAL_NEXT_CLICKED, [
    'storeName', 'targetStoreId', 'action',
  ]],
  [BIEV_UPGRADE_STORE_CLICKED, [
    'origin', 'numProductsLeft', 'storeName', 'targetStoreId',
  ]],
  [BIEV_DISCONNECT_STORE_CONFIRM_MODAL_CONFIRMED, [
    'storeName', 'targetStoreId',
  ]],
  [BIEV_LOGOUT_CLICKED, [
    'origin',
  ]],
  [BIEV_PAGE_LOADED, [
    'marketplace', 'modalystProductId', 'supplierProductId', 'pageName',
  ]],
  [BIEV_POD_EDITOR_SAVE_CLICKED, [
    'ctaName', 'impressionId', 'origin',
    'modalystProductId', 'productId', 'retailerProductId',
  ]],
  [BIEV_POD_EDITOR_LOADED, [
    'impressionId', 'origin', 'pageName',
    'productName', 'modalystProductId', 'productId', 'retailerProductId', 'supplierProductId',
  ]],
  [BIEV_PRICING_RULES_SAVE_CLICKED, [
    'origin', 'retailerStorePlatform', 'type',
    'markupLong', 'markupType',
    'isPriceChanged', 'priceEnding',
    'isShippingIncluded', 'shippingRateLong',
  ]],
  [BIEV_PRICING_RULES_SAVE_CONFIRMED, [
    'origin', 'retailerStorePlatform', 'type',
    'markupLong', 'markupType',
    'isPriceChanged', 'priceEnding',
    'isShippingIncluded', 'shippingRateLong',
  ]],
])
