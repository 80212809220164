/**
 * @param {ImportListProductImageStore} store
 * @returns {{thumbnail, id, position, selected, url}|undefined}
 */
const adaptImageForBiEvent = store => {
  if (!store) return undefined
  const { id, position, selected, thumbnail, url } = store
  return { id, position, selected, thumbnail, url }
}

export {
  adaptImageForBiEvent
}
