const displayPrice = (price) => {
  /**
   *   Display algorithm for Modalyst prices. For numbers bigger than 10k it cuts decimal places, and
   *   for numbers bigger than one million and billion cuts to 'XXX.XXXM' and 'XXX.XXXB' format.
   */
  if (price <= 9999.99) {
    return { amount: price, suffix: '', minFractionDigits: 2, maxFractionDigits: 2 }
  } else if (price > 9999.99 && price <= 999999.99) {
    return { amount: Math.round(price), suffix: '', minFractionDigits: 0, maxFractionDigits: 0 }
  } else if (price > 999999.99 && price <= 999999999) {
    return { amount: Math.round(price / 1000) / 1000, suffix: 'M', minFractionDigits: 0, maxFractionDigits: 3 }
  } else {
    return { amount: Math.round(price / 1000) / 1000000, suffix: 'B', minFractionDigits: 0, maxFractionDigits: 3 }
  }
}

export {
  displayPrice
}
