import React from 'react'

import PropTypes from 'prop-types'

import { Box, Button, IconButton, Popover } from '@material-ui/core'
import { Clear, ExpandMore } from '@material-ui/icons'
import clsx from 'clsx'
import { bindPopover, bindTrigger, usePopupState } from 'material-ui-popup-state/hooks'
import { observer } from 'mobx-react'

import { HorizontalNestedList } from 'shared/components/molecules'
import { useTranslation } from 'shared/hooks'

import CustomPropTypes from 'retailer/propTypes'

import style from './MarketplaceCategoryDropdown.module.scss'

/**
 * Renders a compact category select (button + popover) for selecting
 * the current category on the marketplace
 */
const MarketplaceCategoryDropdown = ({ marketplaceStore }) => {
  const { category, setCategory, categoriesTree } = marketplaceStore

  const { t } = useTranslation('marketplace')
  const popupState = usePopupState({ variant: 'popover', popupId: 'setCategoryPopover' })

  const handlePick = cat => {
    setCategory(cat === categoriesTree ? undefined : cat)
    popupState.close()
  }
  const handleClearClick = () => setCategory(undefined)
  const handleClose = popupState.close
  const renderTitle = cat => (cat === categoriesTree
    ? t('categories.allProducts.label')
    : t('categories.viewAllInCategory.label', { categoryName: cat.name })
  )

  return (
    <Box className={style.MarketplaceCategoryDropdown}>
      <Button
        variant="outlined" color="secondary"
        endIcon={<ExpandMore />}
        className={style.TriggerButton}
        {...bindTrigger(popupState)}
      >
        {category ? category.name : 'All products'}
      </Button>
      <Box className={clsx(style.ClearButtonContainer, category && style.isEnabled)}>
        <IconButton onClick={handleClearClick} size="small">
          <Clear />
        </IconButton>
      </Box>

      <Popover
        {...bindPopover(popupState)}
        className={style.SetCategoryPopover}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        PaperProps={{ className: style.Paper, style: { width: '100%' } }}
        data-testid="marketplaceCategoryPopover"
      >
        <HorizontalNestedList
          onPick={handlePick} onClose={handleClose}
          allowSelectRoot renderInnerTitle={renderTitle}
          className={style.HorizontalNestedList} dense
          data={categoriesTree}
          testId="marketplaceCategoryPopover-HorizontalNestedList"
        />
      </Popover>
    </Box>
  )
}

MarketplaceCategoryDropdown.propTypes = {
  marketplaceStore: PropTypes.shape({
    category: PropTypes.object,
    setCategory: PropTypes.func.isRequired,
    categoriesTree: CustomPropTypes.categoryTree.isRequired,
  }).isRequired,
}

export default observer(MarketplaceCategoryDropdown)
