import { api } from './common'

const baseUrl = '/api/v3'

const getRetailerSubscription = () => {
  return api.get(baseUrl + '/retailer_subscription/')
}

export {
  getRetailerSubscription
}
