import React from 'react'

import PropTypes from 'prop-types'

import { Button, Typography } from '@material-ui/core'
import { observer } from 'mobx-react'

import { Alert } from 'shared/components/molecules'
import { DataModal } from 'shared/components/organisms'

import { SubscriptionPlanCancelStore } from 'retailer/stores'
import { useStores } from 'retailer/stores/hooks'

import style from './PlanDowngradeModal.module.scss'

const PlanDowngradeModal = ({ store, onExited }) => {
  const { mainMenuStore } = useStores()

  const cancelSubscription = () => {
    return store.cancelSubscription().then(() => {
      mainMenuStore.retailerSubscription.refresh()
    })
  }

  return (
    <DataModal
      onExited={onExited}
      className={style.PlanDowngradeModal}
      title="Do you want to cancel your plan?"
      maxWidth="md"
      showSpinner={store.inProgress}
      fullWidth
      renderBody={() => {
        if (store.isSuccess) {
          return (
            <Alert title="Success" severity="success">
              Your plan has been successfully downgraded to Hobby.
            </Alert>
          )
        }
        return (
          <>
            {store.error.hasError && (
              <Alert title="Error" severity="error" className={style.Error}>
                <Typography>
                  {store.error.errorMessage || 'Something went wrong.'}
                </Typography>
                <Typography>
                  Please try again or contact the support.
                </Typography>
              </Alert>
            )}
            <Typography paragraph>
              You are attempting to switch to the Hobby Plan.
            </Typography>
            <Typography paragraph>
              If you elect to switch, this may cause an immediate loss of content, features, and/or privileges offered
              by your paid plan.
            </Typography>
            <Typography paragraph>
              Please note that no refunds or credits for subscription fees will be provided to you
              if you elect to downgrade. The plan goes into effect immediately
            </Typography>
          </>
        )
      }}
      renderActions={({ dismissModal, showSpinner }) => {
        if (store.isSuccess) {
          return (
            <Button
              variant="outlined"
              onClick={dismissModal}
            >
              Close
            </Button>
          )
        }
        return (
          <>
            <Button
              variant="outlined"
              disabled={showSpinner}
              onClick={dismissModal}
            >
              No, I don't want to downgrade
            </Button>
            <Button
              color="primary"
              variant="contained"
              disabled={showSpinner}
              onClick={cancelSubscription}
            >
              Yes, downgrade to Hobby
            </Button>
          </>
        )
      }}
    />
  )
}

PlanDowngradeModal.propTypes = {
  store: PropTypes.instanceOf(SubscriptionPlanCancelStore).isRequired,
  onExited: PropTypes.func
}

PlanDowngradeModal.defaultProps = {
  onExited: () => null
}

export default observer(PlanDowngradeModal)
