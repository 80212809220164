import React, { forwardRef } from 'react'

import PropTypes from 'prop-types'

import { useHistory } from 'react-router'
import { Link, NavLink } from 'react-router-dom'
import { v4 as uuid4 } from 'uuid'

import { useStores } from 'retailer/stores/hooks'

/**
 * Router link that fires a BI event upon click
 *
 * It *will not* fire an event if the user uses "open in new tab" feature.
 */
const BiLink = forwardRef(({ to, onClick, biData = {}, waitForEvent, component, ...linkProps }, ref) => {
  const history = useHistory()
  const { biLoggerStore } = useStores()
  const Component = component ?? Link

  const handleClick = async event => {
    onClick && onClick(event)

    if (event.defaultPrevented) return
    event.preventDefault()

    biData = (typeof biData === 'function') ? biData() : biData
    const { params, ...base } = biData

    const correlationId = uuid4()
    const logObject = { ...base, params: { ...params, correlationId } }

    const promise = biLoggerStore.log(logObject)
    if (waitForEvent) {
      await promise
    }

    history.push(to, { correlationId })
    return true
  }
  return (
    <Component ref={ref} onClick={handleClick} to={to} {...linkProps} />
  )
})

BiLink.propTypes = {
  component: PropTypes.oneOf([Link, NavLink]),
  to: PropTypes.oneOfType([
    PropTypes.string, PropTypes.func, PropTypes.object
  ]).isRequired,
  biData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  /**
   * Enternal onClick handler; will be called BEFORE logging the BI event and navigating.
   * `preventDefault` in the handler to prevent navigating AND logging the event. */
  onClick: PropTypes.func,
  /**
   * Should the app wait until the bi log request is complete before navigating?
   * Use to ensure that the event is logged when linkind to assets outside react-router
   * (causing full app refresh) or outbound links (target=_blank).
   * */
  waitForEvent: PropTypes.bool,
}

BiLink.defaultProps = {
  biData: {},
  waitForEvent: false,
}

export default BiLink
