import biLogger from '@wix/web-bi-logger'
import isNumber from 'lodash/isNumber'
import isObjectLike from 'lodash/isObjectLike'
import isString from 'lodash/isString'
import pick from 'lodash/pick'

const TRANSFORMER_PARAMS_TO_SKIP = ['evid', 'src']

export const getWixBiLogger = defaults => (
  biLogger
    .factory()
    .updateDefaults(defaults)
    .withTransformer(params => {
      return Object.fromEntries(Object.entries(params).map(([key, val]) => {
        if (isString(val) || key.startsWith('_') || TRANSFORMER_PARAMS_TO_SKIP.includes(key)) return [key, val]
        if (typeof val === 'boolean') return [key, val.toString()]
        if (isNumber(val)) return [key, Math.round(val * 100000)]
        if (isObjectLike(val)) return [key, JSON.stringify(val)]
        return [key, val]
      }))
    })
    .onError(console.error)
    .logger()
)

/** Gets BI-related headers for params object */
export const getBiHeadersFromParams = params => params && (
  {
    'BI-Correlation-Id': params.correlationId,
  }
)

/**
 * Gets BI-related headers for API request from a LogObject
 * @param {import('@wix/bi-logger-modalyst/v2/types').LogObject} logObject
 */
export const getBiHeadersFromEvent = logObject => (
  logObject && getBiHeadersFromParams(logObject.params)
)

/**
 * Gets common BI-related properties of a LogObject to reuse in a subsequent calls
 * @param {import('@wix/bi-logger-modalyst/v2/types').LogObject} logObject
 */
export const getBiContext = logObject => pick(logObject?.params, ['correlationId', 'origin'])
