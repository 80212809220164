import React from 'react'

import PropTypes from 'prop-types'

import { Box, Button, Grid, Typography } from '@material-ui/core'
import { KeyboardArrowRightRounded } from '@material-ui/icons'
import { Alert, AlertTitle, Skeleton } from '@material-ui/lab'
import { observer } from 'mobx-react'
import { Link as RouterLink } from 'react-router-dom'

import { Money } from 'shared/components/atoms'
import { DataModal } from 'shared/components/organisms'
import { loadingType } from 'shared/types'

import { ManualOrderVariant } from 'retailer/components/organisms'

import style from './ManuallyOrderItemModal.module.scss'

const description = (
  <Typography variant="body1" className={style.About}>
    You can order a sample of this product using the form below.
    Select which variants you would like to order and the quantity.<br />
    Once you click “Place an order”, visit the <RouterLink to="/orders">Orders Section</RouterLink>{' '}
    to update its shipping address and pay for the order.
  </Typography>
)

const ManuallyOrderItemModal = ({ manualOrderStore }) => {
  const {
    item,
    loading,
    variants,
    isSaving,
    isSuccess,
    error,
    readyToPlace,
    placeOrder,
    reset,
    totalCost,
    totalShippingCost,
    totalOrderValue,
  } = manualOrderStore

  return (
    <DataModal
      showSpinner={isSaving}
      onExited={reset}
      title="Order a sample"
      className={style.ManuallyOrderItemModal}
      maxWidth="lg"
      fullWidth
      renderBody={({ showSpinner }) => {
        if (loading.isLoading) {
          return (
            <>
              {description}
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Skeleton variant="rect" height={100} />
                </Grid>
                <Grid item xs={12}>
                  <Skeleton variant="rect" height={100} />
                </Grid>
                <Grid item xs={12}>
                  <Skeleton variant="rect" height={100} />
                </Grid>
              </Grid>
            </>
          )
        }

        if (isSuccess) {
          return (
            <Alert severity="success" className={style.Alert}>
              <AlertTitle>Order has been placed</AlertTitle>
              Your order has been created. Go to the orders section to edit address and pay for it.
            </Alert>
          )
        }

        return (
          <>
            {error && (
              <Alert severity="error" variant="filled" className={style.Alert}>
                <AlertTitle>Something went wrong</AlertTitle>
                We could not process your order. Please try again in a moment.
              </Alert>
            )}
            {description}
            <Grid container spacing={2}>
              {variants.map(variant => {
                return (
                  <Grid item xs={12} key={variant.id}>
                    <ManualOrderVariant
                      testId="manualOrderModal-manualOrderVariant"
                      variant={variant}
                      currency={item.retailerCurrency}
                      disabled={showSpinner}
                    />
                  </Grid>
                )
              })}
              <Grid item xs={12} className={style.Summary}>
                <Box className={style.Cost}>
                  Cost: <Money amount={totalCost} currency={item.retailerCurrency} testId="manualOrderModal-cost" />
                </Box>
                <Box className={style.Shipping}>
                  + <Money amount={totalShippingCost} currency={item.retailerCurrency} testId="manualOrderModal-shippingCost" /> Shipping
                </Box>
                <Box className={style.Total}>
                  Total: <Money amount={totalOrderValue} currency={item.retailerCurrency} testId="manualOrderModal-totalCost" />
                </Box>
              </Grid>
            </Grid>
          </>
        )
      }}
      renderActions={({ dismissModal, showSpinner }) => {
        if (isSuccess) {
          return (
            <>
              <Button
                disabled={showSpinner}
                data-testid="manualOrderModal-closeButton"
                onClick={dismissModal}
                color="primary"
                size="large"
              >
                Close
              </Button>
              <Button
                data-testid="manualOrderModal-ordersButton"
                onClick={dismissModal}
                component={RouterLink} to="/orders" target="_blank" rel="opener"
                endIcon={<KeyboardArrowRightRounded />}
                color="primary"
                variant="contained"
                size="large"
              >
                Orders
              </Button>
            </>
          )
        }
        return (
          <>
            <Button
              disabled={showSpinner}
              data-testid="manualOrderModal-cancelButton"
              onClick={dismissModal}
              color="primary"
              size="large"
            >
              Cancel
            </Button>
            <Button
              disabled={!readyToPlace || showSpinner}
              data-testid="manualOrderModal-placeOrderButton"
              onClick={() => placeOrder()}
              color="primary"
              variant="contained"
              size="large"
            >
              Place an order
            </Button>
          </>
        )
      }}
    />
  )
}

ManuallyOrderItemModal.propTypes = {
  manualOrderStore: PropTypes.shape({
    item: PropTypes.shape({
      retailerCurrency: PropTypes.string
    }),
    loading: loadingType.isRequired,
    variants: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string.isRequired
    })),
    isSaving: PropTypes.bool,
    isSuccess: PropTypes.bool,
    error: PropTypes.bool,
    readyToPlace: PropTypes.bool,
    placeOrder: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
    totalCost: PropTypes.number.isRequired,
    totalShippingCost: PropTypes.number.isRequired,
    totalOrderValue: PropTypes.number.isRequired,
  }).isRequired,
}

export default observer(ManuallyOrderItemModal)
