/* eslint camelcase: 0 */

import { unstable_createMuiStrictModeTheme } from '@material-ui/core'

import sharedTheme from 'shared/theme/muiTheme'

import { overrides } from './common'

const muiTheme = unstable_createMuiStrictModeTheme({
  themeName: 'Old Retailer Theme',
  direction: 'ltr', // RTL option,
  palette: sharedTheme.palette,
  breakpoints: sharedTheme.breakpoints,
  typography: sharedTheme.typography,
  props: sharedTheme.props,
  overrides: {
    ...sharedTheme.overrides,
    ...overrides(sharedTheme),
  }
})

export { muiTheme }
