import React, { forwardRef, useImperativeHandle, useRef, useState } from 'react'

import PropTypes from 'prop-types'

import {
  FormControl,
  InputLabel,
  OutlinedInput,
  IconButton,
  FormHelperText,
  MenuItem,
  Select,
} from '@material-ui/core'
import {
  Code,
  FormatAlignCenter,
  FormatAlignJustify,
  FormatAlignLeft,
  FormatAlignRight,
  FormatBold,
  FormatItalic,
  FormatListBulleted,
  FormatListNumbered,
  FormatUnderlined,
} from '@material-ui/icons'
import clsx from 'clsx'
import { Editor as DraftJsEditor, EditorState, RichUtils } from 'draft-js'
import 'draft-js/dist/Draft.css'

import style from './RichTextInput.module.scss'
import { htmlToContentState, contentStateToHtml } from './utils'

const editorStatePropType = PropTypes.shape({
  getSelection: PropTypes.func.isRequired,
  getCurrentContent: PropTypes.func.isRequired,
  getCurrentInlineStyle: PropTypes.func.isRequired,
})

const BLOCK_STYLE_CONTROLS = [
  { label: 'Paragraph', style: 'unstyled' },
  { label: 'H1', style: 'header-one' },
  { label: 'H2', style: 'header-two' },
  { label: 'H3', style: 'header-three' },
  { label: 'H4', style: 'header-four' },
  { label: 'H5', style: 'header-five' },
  { label: 'H6', style: 'header-six' },
  { label: 'Blockquote', style: 'blockquote' },
  { label: 'Bulleted list', style: 'unordered-list-item' },
  { label: 'Numbered list', style: 'ordered-list-item' },
]

const INLINE_STYLE_CONTROLS = [
  { label: 'Bold', icon: FormatBold, style: 'BOLD' },
  { label: 'Italic', icon: FormatItalic, style: 'ITALIC' },
  { label: 'Underline', icon: FormatUnderlined, style: 'UNDERLINE' },
]

const LIST_CONTROLS = [
  { label: 'Bulleted list', icon: FormatListBulleted, style: 'unordered-list-item' },
  { label: 'Numbered list', icon: FormatListNumbered, style: 'ordered-list-item' },
]

const ALIGNMENT_CONTROLS = [
  { label: 'Align left', icon: FormatAlignLeft, style: 'align-left' },
  { label: 'Align center', icon: FormatAlignCenter, style: 'align-center' },
  { label: 'Align right', icon: FormatAlignRight, style: 'align-right' },
  { label: 'Justify', icon: FormatAlignJustify, style: 'align-justify' },
]

const FormatButton = ({ value, active, Icon, onToggle, disabled }) => {
  const className = clsx(style.FormatButton, active && style.isActive)
  const handleClick = event => onToggle(value)
  return (
    <IconButton size="small" onClick={handleClick} className={className} disabled={disabled}>
      <Icon />
    </IconButton>
  )
}

FormatButton.propTypes = {
  value: PropTypes.string.isRequired,
  active: PropTypes.bool.isRequired,
  Icon: PropTypes.elementType.isRequired,
  onToggle: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
}

const BlockTypeControls = ({ editorState, onToggle, disabled }) => {
  const selection = editorState.getSelection()
  const blockType = editorState.getCurrentContent().getBlockForKey(selection.getStartKey()).getType()
  const handleOnChange = event => {
    onToggle(event.target.value)
  }
  return (
    <div className={style.ToolbarGroup}>
      <Select
        value={blockType}
        onChange={handleOnChange}
        className={style.BlockTypeSelect}
        disabled={disabled}
      >
        {BLOCK_STYLE_CONTROLS.map(({ label, style }) => (
          <MenuItem key={label} value={style}>{label}</MenuItem>
        ))}
      </Select>
    </div>
  )
}

BlockTypeControls.propTypes = {
  editorState: editorStatePropType.isRequired,
  onToggle: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
}

const InlineStyleControls = ({ editorState, onToggle, disabled }) => {
  const currentStyle = editorState.getCurrentInlineStyle()
  return (
    <div className={style.ToolbarGroup}>
      {INLINE_STYLE_CONTROLS.map(({ label, style, icon }) => (
        <FormatButton
          key={label}
          label={label}
          value={style}
          onToggle={onToggle}
          disabled={disabled}
          active={currentStyle.has(style)}
          Icon={icon}
        />
      ))}
    </div>
  )
}

InlineStyleControls.propTypes = {
  editorState: editorStatePropType.isRequired,
  onToggle: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
}

const ListTypeControls = ({ editorState, onToggle, disabled }) => {
  const selection = editorState.getSelection()
  const blockType = editorState.getCurrentContent().getBlockForKey(selection.getStartKey()).getType()
  return (
    <div className={style.ToolbarGroup}>
      {LIST_CONTROLS.map(({ label, style, icon }) => (
        <FormatButton
          key={label}
          label={label}
          value={style}
          onToggle={onToggle}
          active={style === blockType}
          disabled={disabled}
          Icon={icon}
        />
      ))}
    </div>
  )
}

ListTypeControls.propTypes = {
  editorState: editorStatePropType.isRequired,
  onToggle: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
}

/**
 * Text alignment controls
 *
 * TODO: implement. Currently this component only renders the toolbar.
 * Block-level text alignment is not supported by DraftJS out of the box.
 * See https://gist.github.com/bultas/981cde34b3d1a2cb9b558ca9467bca77
 * for possible solution.
 */
const AlignmentControls = ({ editorState: _, onToggle, disabled }) => {
  return (
    <div className={style.ToolbarGroup}>
      {ALIGNMENT_CONTROLS.map(({ label, style, icon }) => (
        <FormatButton
          key={label}
          label={label}
          value={style}
          onToggle={onToggle}
          active={false}
          disabled={disabled}
          Icon={icon}
        />
      ))}
    </div>
  )
}

AlignmentControls.propTypes = {
  editorState: editorStatePropType.isRequired,
  onToggle: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
}

/**
 * Internal editor component with a dual mode (rich text/textarea)
 *
 */
const Editor = ({ inputRef, defaultValue, className, ...otherProps }) => {
  const richAreaRef = useRef()
  const codeAreaRef = useRef()

  const [editorState, setEditorState] = useState(
    () => EditorState.createWithContent(htmlToContentState(defaultValue || ''))
  )

  const handleRichChange = data => {
    setEditorState(data)
  }

  const [isCodeMode, setIsCodeMode] = useState(false)
  const [codeDefaultValue, setCodeDefaultValue] = useState(defaultValue || '')

  const handleModeToggle = () => {
    if (isCodeMode) {
      // get value from ref, convert and update editor state
      const html = codeAreaRef.current?.value
      setEditorState(EditorState.createWithContent(htmlToContentState(html || '')))
    } else {
      // convert editor state to htmland set state for textarea's default value
      const html = contentStateToHtml(editorState.getCurrentContent())
      setCodeDefaultValue(html)
    }
    setIsCodeMode(!isCodeMode)
  }

  // common imperative interface for both modes. inputRef is passed down from a MUI wrapper.
  useImperativeHandle(inputRef, () => ({
    focus: () => {
      const ref = isCodeMode ? codeAreaRef : richAreaRef
      if (ref.current) {
        ref.current.focus()
      }
    },
    get value () {
      return isCodeMode
        ? codeAreaRef.current?.value
        : contentStateToHtml(editorState.getCurrentContent())
    },
    set value (val) {
      if (isCodeMode && codeAreaRef.current) {
        codeAreaRef.current.value = val
      } else {
        setEditorState(EditorState.createWithContent(htmlToContentState(val || '')))
      }
    }
  }))

  const { onFocus, onBlur } = otherProps
  const handleOnFocus = onFocus
  const handleOnBlur = onBlur

  const handleInlineStyleToggle = style => {
    handleRichChange(RichUtils.toggleInlineStyle(editorState, style))
  }

  const handleBlockTypeToggle = type => {
    handleRichChange(RichUtils.toggleBlockType(editorState, type))
  }

  const handleAlignmentToggle = type => {
    handleRichChange(RichUtils.toggleBlockType(editorState, type))
  }

  const editArea = isCodeMode
    ? (
      <textarea
        ref={codeAreaRef}
        className={style.CodeEditor}
        defaultValue={codeDefaultValue}
        {...otherProps}
      />
      )
    : (
      <DraftJsEditor
        ref={richAreaRef}
        editorState={editorState}
        onChange={handleRichChange}
        onFocus={handleOnFocus}
        onBlur={handleOnBlur}
      />
      )

  return (
    <div className={clsx(style.Editor, className)}>
      <div className={style.Toolbar}>
        <div className={style.FormatControls}>
          <BlockTypeControls disabled={isCodeMode} editorState={editorState} onToggle={handleBlockTypeToggle} />
          <InlineStyleControls disabled={isCodeMode} editorState={editorState} onToggle={handleInlineStyleToggle} />
          <ListTypeControls disabled={isCodeMode} editorState={editorState} onToggle={handleBlockTypeToggle} />
          <AlignmentControls disabled editorState={editorState} onToggle={handleAlignmentToggle} />
          <div />
        </div>
        <div className={style.ToolbarGroup}>
          <IconButton size="small" onClick={handleModeToggle} color={isCodeMode ? 'primary' : 'default'}>
            <Code />
          </IconButton>
        </div>
      </div>

      {editArea}
    </div>
  )
}

Editor.propTypes = {
  inputRef: PropTypes.func.isRequired,
  defaultValue: PropTypes.string,
  className: PropTypes.string,
}

/**
 * Renders editor "chrome" from Material UI.
 *
 * `Editor` component is responsible for rendering the actual editor, toolbars etc
 *
 * Problem with rich input acting as a controlled input:
 * DraftJS component keeps the state as a custom state object rather than a HTML
 * string. If we convert that state to HTML in handleOnChange and pass it up the
 * tree using onChange prop, it will come back in `value` prop,
 * which WILL mess up the editor state and be quite slow, as we need
 * to convert it back to EditorState on every change (or apply some costly
 * comparison to internally stored HTML value).
 *
 * The simplest solution is to enforce use of this component as an UNCONTROLLED
 * input and only get the content as HTML on form submit attempt, blur etc.
 * Luckily, React Hook Form supports uncontrolled elements.
 */
const RichTextInput = forwardRef((props, ref) => {
  const {
    value, // get only to throw an exception
    id, label, fullWidth, required, helperText, error, testId,
    ...otherProps
  } = props

  if (value !== undefined) {
    throw new Error(
      'For performance reasons, RichTextInput component can act only as an uncontrolled input. ' +
      'See component jsdoc for details.'
    )
  }

  return (
    <FormControl variant="outlined" required={required} error={error} fullWidth={fullWidth}>
      <InputLabel htmlFor={id} shrink required={required}>{label}</InputLabel>
      <OutlinedInput
        notched required multiline fullWidth={fullWidth}
        id={id} label={label}
        inputComponent={Editor}
        inputRef={ref}
        error={error}
        data-testid={testId}
        {...otherProps}
      />
      <FormHelperText error={error}>{helperText}</FormHelperText>
    </FormControl>
  )
})

RichTextInput.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  fullWidth: PropTypes.bool,
  required: PropTypes.bool,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  value: PropTypes.string,
  defaultValue: PropTypes.string,
  testId: PropTypes.string,
}

RichTextInput.defaultProps = {

}

export default RichTextInput
