import React, { useEffect } from 'react'

import PropTypes from 'prop-types'

import { Button, Grid, Menu, MenuItem, Portal, Typography, useMediaQuery, useTheme } from '@material-ui/core'
import { ArrowDropDownRounded } from '@material-ui/icons'
import { camelCase } from 'lodash-es'
import { bindMenu, bindTrigger, usePopupState } from 'material-ui-popup-state/hooks'
import { observer } from 'mobx-react'

import { useTranslation } from 'shared/hooks'
import { refType } from 'shared/types'

import style from './BatchActionsBar.module.scss'

/**
 * A reusable component to display a bar with the number of selected objects, and a list of actions
 * which can be performed on these objects (i.e. a batch action).
 *
 * Note that this component will not display the bar in itself, but instead inject it
 * into the DOM (in container from `containerRef`) using `<Portal>`.
 *
 * @param {Number}      count         The number of selected objects.
 * @param {Object[]}    actions       An array of actions, in the form of {name: String, handler: function}.
 * @param {Boolean}     compact       Whether to wrap actions into a pop up menu instead of displaying them all.
 * @param {Object}      containerRef  A ref to the element in which the bar should be displayed.
 * @param {Element}     selectAllButton
 * @returns {JSX.Element}
 * @constructor
 */
const BatchActionsBar = ({
  count, actions, compact, containerRef, selectAllButton
}) => {
  const { t } = useTranslation('common')
  const popupState = usePopupState({ variant: 'popover', popupId: 'selection' })
  const theme = useTheme()
  const desktopLayoutBreakpoint = useMediaQuery(theme.breakpoints.up('xl'))

  useEffect(() => {
    if (!compact) popupState.close()
  }, [compact])

  return (
    <Portal container={containerRef.current}>
      <Grid
        container
        spacing={desktopLayoutBreakpoint ? 3 : 1}
        className={style.BatchActionsBar}
        data-testid="batchActionsBar"
      >
        {selectAllButton && (
          <Grid item>
            {selectAllButton}
          </Grid>
        )}
        <Grid item>
          <Typography className={style.Label} data-testid="batchActionsBar-selectedNumberLabel">
            {t('batchActionsBar.selected.label', { selectedNumber: count })}
          </Typography>
        </Grid>
        <Grid item style={{ flexGrow: 1 }} />
        {compact && (
          <Grid item>
            <Button
              size="large"
              variant="outlined"
              color="primary"
              endIcon={<ArrowDropDownRounded />}
              {...bindTrigger(popupState)}
            >
              {t('batchActionsBar.actions.cta')}
            </Button>
            <Menu
              {...bindMenu(popupState)}
              getContentAnchorEl={null}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
            >
              {actions.map(action => {
                return (
                  <MenuItem
                    key={action.name}
                    onClick={() => action.handler()}
                    data-testid={`batchActionsBar-action-${camelCase(action.name)}`}
                  >
                    {action.name}
                  </MenuItem>
                )
              })}
            </Menu>
          </Grid>
        )}
        {!compact && actions.map(action => {
          return (
            <Grid item key={action.name}>
              <Button
                onClick={() => action.handler()} variant="text" color="primary"
                data-testid={`batchActionsBar-action-${camelCase(action.name)}`}
              >
                {action.name}
              </Button>
            </Grid>
          )
        })}
      </Grid>
    </Portal>
  )
}

BatchActionsBar.propTypes = {
  count: PropTypes.number.isRequired,
  actions: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    handler: PropTypes.func.isRequired
  })).isRequired,
  compact: PropTypes.bool,
  containerRef: refType.isRequired,
  selectAllButton: PropTypes.node,
}

BatchActionsBar.defaultProps = {
  compact: false,
  selectAllButton: null,
}

export default observer(BatchActionsBar)
