import React from 'react'

import PropTypes from 'prop-types'

import { Switch } from 'react-router-dom'

function flatten (target, children) {
  React.Children.forEach(children, (child) => {
    if (React.isValidElement(child)) {
      if (child.type === React.Fragment) {
        flatten(target, child.props.children)
      } else {
        target.push(child)
      }
    }
  })
}

/**
 * Use instead of base Switch to allow for grouping Routes in React.Fragment
 */
function DeepSwitch ({ children }) {
  const flattenedChildren = []
  flatten(flattenedChildren, children)
  return React.createElement.apply(
    React,
    [Switch, null].concat(flattenedChildren)
  )
}

DeepSwitch.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
}

export default DeepSwitch
