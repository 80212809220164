import { action, observable } from 'mobx'

import { adaptUserRequestedData } from 'shared/adapters/settings'
import { getUserRequestedDataRemoval, requestPersonalData } from 'shared/api/settings'
import { LoadableStore } from 'shared/stores'

class UserRequestDataStore {
  parent

  @observable loading = new LoadableStore()
  @observable userRequestedPersonalData = false
  @observable isSaving = false
  @observable error = false

  constructor (parent) {
    this.parent = parent
  }

  @action.bound
  fetch () {
    this.loading.setLoading()
    return getUserRequestedDataRemoval()
      .then(response => {
        Object.assign(this, adaptUserRequestedData(response.data))
        this.loading.setLoaded()
        this.error = false
      })
      .catch(() => {
        this.error = true
      })
  }

  @action.bound
  requestData () {
    this.isSaving = true
    return requestPersonalData()
      .then(() => {
        this.userRequestedPersonalData = true
        this.error = false
      })
      .catch(() => {
        this.error = true
      })
      .finally(() => {
        this.isSaving = false
      })
  }
}

export default UserRequestDataStore
