/* eslint camelcase: 0 */

const { keysToCamelCase, keysToSnakeCase } = require('shared/utils/api')

const adaptStore = ({ subscription_plan, store_installation, ...rest }) => ({
  subscriptionPlan: subscription_plan && keysToCamelCase(subscription_plan),
  storeInstallation: store_installation && keysToCamelCase(store_installation),
  ...keysToCamelCase(rest)
})

const adaptGetStoresResponse = ({ results, ...meta }) => ({
  results: results.map(row => adaptStore(row)),
  ...keysToCamelCase(meta)
})

const adaptPostStoreRequest = keysToSnakeCase

const adaptPostStoreErrors = ({ non_field_errors, ...rest }) => ({
  nonFieldErrors: non_field_errors,
  fieldErrors: keysToCamelCase(rest),
})

export {
  adaptStore,
  adaptGetStoresResponse,
  adaptPostStoreRequest,
  adaptPostStoreErrors,
}
