import React, { forwardRef } from 'react'

import PropTypes from 'prop-types'

import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'
import clsx from 'clsx'
import merge from 'lodash/merge'
import { observer } from 'mobx-react'

import { ofacFilteredCountries } from 'shared/utils/countries'

import style from './CountrySelect.module.scss'

/**
 * Returns an emoji flag for given country.
 * ISO 3166-1 alpha-2. No support for IE 11.
 * Sourced from: https://material-ui.com/components/autocomplete/#country-select
 * @param {string} isoCode
 * @returns {string}
 */
const countryToFlag = (isoCode) => {
  return typeof String.fromCodePoint !== 'undefined'
    ? isoCode.toUpperCase().replace(
      /./g, (char) => String.fromCodePoint(char.charCodeAt(0) + 127397)
    )
    : isoCode
}

/**
 * Get country object from `country list.
 * @param {string} code ISO code for the country
 * @returns {{code:string,name:string}}
 */
const getCountry = code => typeof (code) === 'string' ? ofacFilteredCountries.find(c => c.code === code) : code

/**
 * Renders an emoji flag alongside the country name.
 * @param {string} isoCode
 * @returns {string}
 */
const getCountryNameWithFlag = isoCode => {
  const country = getCountry(isoCode)
  return country ? countryToFlag(country.code) + ' ' + country.name : isoCode
}

/**
 * Autocomplete component that lists and allows to search for countries by name
 * and outputs ISO country code as value
 */
const CountrySelect = forwardRef((props, ref) => {
  const {
    className,
    error,
    helperText,
    value,
    label,
    countries,
    variant,
    required,
    disabled,
    inputRef,
    onChange,
    TextFieldProps,
    testId,
    ...other
  } = props

  const filteredCountries = countries
    ? ofacFilteredCountries.filter(country => countries.includes(country.code))
    : ofacFilteredCountries

  return (
    <Autocomplete
      {...other}
      ref={ref}
      disabled={disabled}
      autoHighlight
      disableClearable
      options={filteredCountries}
      className={clsx(style.CountrySelect, className)}
      getOptionLabel={isoCode => getCountryNameWithFlag(isoCode)}
      getOptionSelected={(option, isoCode) => option === getCountry(isoCode)}
      renderOption={isoCode => getCountryNameWithFlag(isoCode)}
      onChange={(event, selected) => {
        onChange(selected ? selected.code : undefined, event)
      }}
      value={value}
      renderInput={params => {
        merge(params, TextFieldProps)
        return (
          <TextField
            {...params}
            ref={inputRef}
            label={label}
            variant={variant}
            required={required}
            error={error}
            helperText={helperText}
            data-testid={`${testId}-countrySelect`}
          />
        )
      }}
    />
  )
})

CountrySelect.propTypes = {
  ...Autocomplete.propTypes,
  /** Array of country codes to display as options */
  countries: PropTypes.arrayOf(PropTypes.string),
  required: PropTypes.bool,
  TextFieldProps: PropTypes.object,
  testId: PropTypes.string,
}

CountrySelect.defaultProps = {
  ...Autocomplete.defaultProps,
  required: false,
  TextFieldProps: {}
}

export default observer(CountrySelect)
