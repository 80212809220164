import React from 'react'

import PropTypes from 'prop-types'

import dayjs from 'dayjs'
import { observer } from 'mobx-react'

const Time = ({ value, format, relative, className, testId }) => {
  if (relative) return <span className={className} data-testid={testId}>{dayjs().to(value, true)}</span>
  return <span className={className} data-testid={testId}>{dayjs(value).format(format)}</span>
}

Time.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(Date)
  ]),
  format: PropTypes.string,
  relative: PropTypes.bool,
  className: PropTypes.string,
  testId: PropTypes.string,
}

Time.defaultProps = {
  format: 'MMMM D, YYYY',
  relative: false,
  className: ''
}

export default observer(Time)
