const ERROR_GENERIC = 'error_generic'
const ERROR_ORDER_ALREADY_PLACED = 'error_order_already_placed'
const ERROR_NO_SUPPLIER_CONNECTION = 'no_supplier_connection'

const errorsReadable = new Map([
  [
    ERROR_ORDER_ALREADY_PLACED,
    'This order has already been placed!'
  ],
  [
    ERROR_GENERIC,
    'We had problem with connection. Please click Order on AliExpress again.'
  ]
])

export {
  ERROR_NO_SUPPLIER_CONNECTION,
  ERROR_GENERIC,
  errorsReadable,
}
