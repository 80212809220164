import React, { forwardRef, useImperativeHandle } from 'react'

import PropTypes from 'prop-types'

import { Dialog, DialogActions, DialogContent, LinearProgress } from '@material-ui/core'
import clsx from 'clsx'
import { observer } from 'mobx-react'

import { ModalTitle } from 'shared/components/molecules'

import style from './DataModal.module.scss'

/**
 * Modal component serving as a base for viewing/editing store data structures
 *
 * Usage:
 *  1. Set an object to view/edit in store and make it a condition for mounting the DataModal-based component
 *  2. Use DataModal's render props to render the form and/or data; run actions on the store from there.
 *  3. Call 'dismissModal' from modal body and/or actions when you're done or want to cancel
 *  3. When executing async store actions:
 *    - set `showSpinner` prop to true(ish) to indicate operation in progress
 *    - set `hide` prop to true(ish) when you're done → the Modal will hide and eventually trigger `onExited`
 *  5. Use `onExited` func prop for clean up, ie.
 *    - unset the object to view/edit from the store (will unmount Modal component)
 *    - reset flag for `hide` to false(ish) for future life cycles
 *
 */
const DataModal = forwardRef(({
  children,
  hide,
  renderActions,
  renderBody,
  showSpinner,
  title,
  className,
  contentRef,
  onExited,
  testId,
  ...rest
}, ref) => {
  const [open, setOpen] = React.useState(!hide)
  const dismissModal = () => setOpen(false)

  useImperativeHandle(ref, () => ({
    dismiss: dismissModal
  }))

  const actions = renderActions && renderActions({ dismissModal, showSpinner })
  const transitionProps = { onExited }

  return (
    <Dialog
      open={open && !hide}
      aria-labelledby="dialog-title"
      maxWidth="md"
      className={clsx(style.DataModal, className)}
      TransitionProps={transitionProps}
      data-testid={testId}
      {...rest}
    >
      <ModalTitle
        className={style.ModalTitle} onCloseClick={dismissModal}
        testId={testId ? `${testId}-title` : undefined}
      >
        {title}
      </ModalTitle>
      <LinearProgress className={clsx(style.ProgressBar, showSpinner && style.isActive)} color="primary" />
      <DialogContent className={clsx(style.ModalContent, showSpinner && style.isLocked)} ref={contentRef}>
        {renderBody && renderBody({
          dismissModal, showSpinner
        })}
      </DialogContent>
      {actions &&
        <DialogActions className={style.ModalActions}>
          {actions}
        </DialogActions>}
    </Dialog>
  )
})

DataModal.propTypes = {
  ...Dialog.propTypes,
  title: PropTypes.string.isRequired,
  hide: PropTypes.bool,
  showSpinner: PropTypes.bool,
  renderActions: PropTypes.func,
  renderBody: PropTypes.func,
  contentRef: PropTypes.func,
  onExited: PropTypes.func,
  testId: PropTypes.string,
}

DataModal.defaultProps = {
  hide: false,
  showSpinner: false,
}

export default observer(DataModal)
