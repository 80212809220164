import React from 'react'

import { Alert, AlertTitle } from '@material-ui/lab'
import { observer } from 'mobx-react'

import { useTranslation } from 'shared/hooks'

import style from './ItemNotAvailableAlert.module.scss'

const ItemNotAvailableAlert = () => {
  const { t } = useTranslation('common')
  return (
    <Alert severity="error" variant="filled" className={style.ItemNotAvailableAlert}>
      <AlertTitle>{t('productNotAvailableAlert.title')}</AlertTitle>
      {t('productNotAvailableAlert.message')}
    </Alert>
  )
}

export default observer(ItemNotAvailableAlert)
