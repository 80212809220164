import React from 'react'

import PropTypes from 'prop-types'

import { Chip } from '@material-ui/core'

import style from './Label.module.scss'

const STATUS_SUCCESS = 'success'
const STATUS_WARNING = 'warning'
const STATUS_ERROR = 'error'
const STATUS_DEFAULT = 'default'

const Label = ({ status, className, testId, ...props }) => {
  const statusMap = new Map([
    [STATUS_SUCCESS, style.success],
    [STATUS_WARNING, style.warning],
    [STATUS_ERROR, style.error],
    [STATUS_DEFAULT, style.default]
  ])

  return (
    <Chip
      className={
        `${style.Label}
        ${statusMap.get(status) || statusMap.get(STATUS_DEFAULT)}
        ${className}`
      }
      data-testid={testId}
      {...props}
    />
  )
}

Label.propTypes = {
  status: PropTypes.oneOf([
    STATUS_SUCCESS,
    STATUS_WARNING,
    STATUS_ERROR,
  ]),
  className: PropTypes.string,
  testId: PropTypes.string,
}

Label.defaultProps = {
  className: '',
}

export default Label
