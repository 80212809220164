import React from 'react'

import PropTypes from 'prop-types'

import { Typography } from '@material-ui/core'
import { observer } from 'mobx-react'

import { connectableIntegrations, integrationsNames } from 'shared/constants/integrations'

import style from './StoreIntegrationCheckWarning.module.scss'

const NotAllowedToInstallWarning = props => {
  return (
    <div className={style.Warning}>
      <Typography paragraph>
        This store cannot be connected to your account.{' '}
        Ensure the application you are installing matches your account type.{' '}
        For more information, please contact our support at{' '}
        <a href="mailto:team@modalyst.co">team@modalyst.co</a>.
      </Typography>
      <div className={style.Actions}>
        {props.switchAccount}
      </div>
    </div>
  )
}

/* eslint-disable react/no-unused-prop-types */
const warningPropTypes = {
  results: PropTypes.object.isRequired,
  integrationType: PropTypes.oneOf(connectableIntegrations),
  username: PropTypes.string,
  switchAccount: PropTypes.node,
  createNewAccount: PropTypes.node,
  onSignOutClick: PropTypes.func,
  manageIntegrations: PropTypes.node,
  TitleProps: PropTypes.object,
}
/* eslint-enable react/no-unused-prop-types */

NotAllowedToInstallWarning.propTypes = { ...warningPropTypes }

const HasAuthorizationInRemovalWarning = props => {
  return (
    <div className={style.Warning}>
      <Typography paragraph>
        We are currently uninstalling your store. Once this action is completed, you may re-install Modalyst again.
        In the meantime, you can visit other pages on the site.
      </Typography>
      <div className={style.Actions}>
        {props.manageIntegrations}
      </div>
    </div>
  )
}

HasAuthorizationInRemovalWarning.propTypes = warningPropTypes

const InstalledByAnotherUserWarning = props => {
  const integrationName = integrationsNames.get(props.integrationType)
  const username = props.results.installationExistsForUser
  return (
    <div className={style.Warning}>
      <Typography {...props.TitleProps}>
        {`This store is already installed on Modalyst account '${username}'`}
      </Typography>
      <Typography paragraph>
        {`Your ${integrationName} store can only be installed on one Modalyst account at a time.
        If you would like to continue with this account, you must first uninstall
        Modalyst from the ${username} account.`}
      </Typography>
    </div>
  )
}

InstalledByAnotherUserWarning.propTypes = warningPropTypes

const HasCurrentAuthorizationWarning = props => {
  return (
    <div className={style.Warning}>
      <Typography {...props.TitleProps}>
        This Modalyst account is already connected to a store
      </Typography>
      <Typography paragraph>
        Each Modalyst account can only be connected to one store.
      </Typography>
      <Typography paragraph>
        To connect this store to Modalyst, you must set up a new Modalyst account.
        You can sign out from your current account using the button below and register a new account,
        or sign in to a different account. Once your are signed in, you can immediately connect
        your store to that account.
      </Typography>
      <Typography paragraph>
        We are working on a functionality which will allow you to connect
        multiple stores to one Modalyst account.
      </Typography>
      <div className={style.Actions}>
        {props.createNewAccount}
      </div>
    </div>
  )
}

HasCurrentAuthorizationWarning.propTypes = warningPropTypes

const StoreIntegrationCheckWarning = props => {
  if (props.results.canInstallIntegration === false) {
    return (
      <NotAllowedToInstallWarning {...props} />
    )
  }
  if (props.results.hasAuthorizationInRemoval) {
    return (
      <HasAuthorizationInRemovalWarning {...props} />
    )
  }
  if (
    props.results.installationExistsForUser ||
    props.results.isAuthorizedOnAnotherBusiness
  ) {
    return (
      <InstalledByAnotherUserWarning {...props} />
    )
  }
  if (props.results.hasCurrentAuthorization) {
    return (
      <HasCurrentAuthorizationWarning {...props} />
    )
  }
  return null
}

StoreIntegrationCheckWarning.propTypes = {
  integrationType: PropTypes.string.isRequired,
  results: PropTypes.object,
  switchAccount: PropTypes.node,
  createNewAccount: PropTypes.node,
  manageIntegrations: PropTypes.node,
  signOutFn: PropTypes.func,
  TitleProps: PropTypes.object,
}

StoreIntegrationCheckWarning.defaultProps = {
  TitleProps: { variant: 'h5', paragraph: true }
}

export default observer(StoreIntegrationCheckWarning)
