import { action, observable } from 'mobx'

import { adaptAddressErrors, adaptAddressReverse, adaptRetailerOrder } from 'shared/adapters/orders'
import { getOrder, updateOrderShippingAddress } from 'shared/api/orders'
import { processApiError } from 'shared/api/utils'

class OrderShippingAddressEditState {
  @observable order
  @observable remoteErrors
  @observable updateInProgress
  @observable updateDone

  constructor (order) {
    this.order = order
  }

  @action.bound
  setUpdateInProgress (value) {
    this.updateInProgress = value
  }

  @action.bound
  setUpdateDone (value) {
    this.updateDone = value
  }

  @action.bound
  setRemoteErrors (data) {
    this.remoteErrors = data
  }

  @action.bound
  updateRemote (data) {
    if (this.updateInProgress) {
      return
    }

    this.setRemoteErrors(undefined)
    this.setUpdateInProgress(true)

    updateOrderShippingAddress(this.order.uuid, adaptAddressReverse(data))
      // unset in-progress flag on success and reload the order (may have changed as per the new shipping destination)
      .then(({ data: addressData }) => {
        this.setUpdateDone(true)
        return getOrder(this.order.uuid)
      })
      // update order store
      .then(({ data: orderData }) => {
        this.order.update(adaptRetailerOrder(orderData))
      })
      // handle errors
      .catch(error => {
        this.setRemoteErrors(processApiError(error, adaptAddressErrors))
      })
      // wrap up both scenarios
      .finally(() => {
        this.setUpdateInProgress(false)
      })
  }
}

export default OrderShippingAddressEditState
