const REMOVAL_REASON_ALL = 'all'
const REMOVAL_REASON_BY_RETAILER = 'removed_by_retailer_action'
const REMOVAL_REASON_RETAILER_ITEM_REMOVED = 'removed_retailer_item_from_db'
const REMOVAL_REASON_INTEGRATION = 'removed_by_webhook_from_integrated_store'
const REMOVAL_REASON_ITEM_MARKED_AS_REMOVED = 'modalyst_item_marked_as_removed'
const REMOVAL_REASON_ITEM_REMOVED = 'modalyst_item_removed_from_db'
const REMOVAL_REASON_PREMIUM_NOT_ALLOWED = 'modalyst_item_removed_premium_items_for_basic_plan'
const REMOVAL_REASON_APP_UNINSTALL = 'removed_due_to_app_uninstall'
const REMOVAL_REASON_OTHER = 'other'

const removalReasonsUserFriendly = new Map([
  [REMOVAL_REASON_ALL, 'All'],
  [REMOVAL_REASON_BY_RETAILER, 'Removed by Retailer'],
  [REMOVAL_REASON_INTEGRATION, 'Removed from Integrated Store'],
  [REMOVAL_REASON_ITEM_MARKED_AS_REMOVED, 'Item Removed by Supplier'],
  [REMOVAL_REASON_OTHER, 'Other']
])

const ACTION_RESTORE_TO_IMPORT_LIST = 'import'
const ACTION_RESTORE_TO_SYNC_LIST = 'export'

const actionsUserFriendly = new Map([
  [ACTION_RESTORE_TO_IMPORT_LIST, 'Restore to Import List'],
  [ACTION_RESTORE_TO_SYNC_LIST, 'Restore to Sync List']
])

const RESTORE_DESTINATION_IMPORT_LIST = 'new'
const RESTORE_DESTINATION_SYNC_LIST = 'published'

export {
  REMOVAL_REASON_ALL,
  REMOVAL_REASON_BY_RETAILER,
  REMOVAL_REASON_RETAILER_ITEM_REMOVED,
  REMOVAL_REASON_INTEGRATION,
  REMOVAL_REASON_ITEM_MARKED_AS_REMOVED,
  REMOVAL_REASON_ITEM_REMOVED,
  REMOVAL_REASON_PREMIUM_NOT_ALLOWED,
  REMOVAL_REASON_APP_UNINSTALL,
  REMOVAL_REASON_OTHER,
  removalReasonsUserFriendly,
  ACTION_RESTORE_TO_IMPORT_LIST,
  ACTION_RESTORE_TO_SYNC_LIST,
  actionsUserFriendly,
  RESTORE_DESTINATION_IMPORT_LIST,
  RESTORE_DESTINATION_SYNC_LIST
}
