import { REMOVAL_REASON_ALL } from 'shared/constants/archivedRetailerItems'
import { keysToCamelCase, keysToSnakeCase } from 'shared/utils/api'

// eslint-disable-next-line camelcase
const adaptArchivedItem = ({ created, main_image, readable_removal_reason, ...rest }) => {
  return {
    created: new Date(created),
    image: main_image,
    removalReasonReadable: readable_removal_reason,
    ...keysToCamelCase(rest)
  }
}

const adaptArchivedItemQueryParams = ({ removalReason, nonRestorable, ...rest }) => {
  return {
    removal_reason: removalReason === REMOVAL_REASON_ALL ? '' : removalReason,
    restorable: !nonRestorable,
    ...keysToSnakeCase(rest)
  }
}

export {
  adaptArchivedItem,
  adaptArchivedItemQueryParams
}
