import React, { useMemo } from 'react'

import PropTypes from 'prop-types'

import {
  CardContent,
  Grid,
  TextField,
  Tooltip,
  Typography
} from '@material-ui/core'
import { Help, Warning } from '@material-ui/icons'
import { observer } from 'mobx-react'
import NumberFormat from 'react-number-format'

import { TooltipHelper } from 'shared/components/molecules'

import { ToggleButtonGroup } from 'retailer/components/molecules'

import style from './PricingRule.module.scss'

const COMPARE_AT_PRICE_TOOLTIP =
  'If you select that option, we will leave Compare At Price blank in your ' +
  'Import List, as well as when you sync items to your store.This means that ' +
  'no Compare At Price will sync with your store'
const MULTIPLIED_TOOLTIP =
  'For example, if a product costs $5 and you multiply it by a 2x markup, then the new retail price is $10.'
const ADDED_TOOLTIP =
  'This adds a fixed amount to the retail price. For example, if a product costs $5 and the added markup is $10, the new retail price is $15.'
const OPTIONS = [
  { value: 'disabled', label: 'Disabled', tooltip: COMPARE_AT_PRICE_TOOLTIP },
  { value: 'multiplied', label: 'Multiplied markup', tooltip: MULTIPLIED_TOOLTIP },
  { value: 'added', label: 'Added markup', tooltip: ADDED_TOOLTIP },
]

const PricingRule = ({
  code,
  pricingRuleStore,
  allowDisable,
  title,
  titleHelp,
  productCostLabel,
  helpText,
  markupError,
}) => {
  const markupOperator = pricingRuleStore.markupType === 'added' ? '+' : '×'
  const disabled = pricingRuleStore.markupType === 'disabled'
  const endAdornment = markupError
    ? <Tooltip title={markupError}><Warning color="error" /></Tooltip>
    : undefined

  const testIdBase = `pricingRules-${code}Markup`

  const buttonGroupOptions = useMemo(() => (
    OPTIONS
      .filter(o => (allowDisable || o.value !== 'disabled'))
      .map(obj => ({ ...obj, testId: `${testIdBase}-markupSwitch-button` }))
  ), [allowDisable, code])

  return (
    <CardContent className={style.PricingRule}>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12} sm={disabled ? 4 : 12} xl={disabled ? 8 : 6} className={style.Formula}>

          <div className={style.FormulaPart}>
            <Typography variant="h5">
              <span>{title}</span>
              <TooltipHelper content={titleHelp} IconComponent={Help} fontSize="small" />
            </Typography>
            {!disabled && <span className={style.FormulaOperator}>=</span>}
          </div>

          {!disabled && (
            <div className={style.FormulaPart}>
              <Typography variant="body2">{productCostLabel}</Typography>
              <span className={style.FormulaOperator}>{markupOperator}</span>
            </div>
          )}

        </Grid>
        {!disabled && (
          <Grid item xs={12} sm={4} xl={2}>
            <NumberFormat
              value={pricingRuleStore.markup}
              onValueChange={({ value }) => pricingRuleStore.setMarkup(value)}
              fixedDecimalScale decimalScale={2}
              allowNegative={false} isAllowed={({ value }) => value.length <= 13}
              customInput={TextField}
              InputProps={{ endAdornment }}
              inputProps={{ style: { textAlign: 'center' }, 'data-testid': `${testIdBase}-markupInput` }}
              size="small" fullWidth variant="outlined" error={!!markupError}
            />
          </Grid>
        )}
        <Grid item xs={12} sm={8} xl={4}>
          <ToggleButtonGroup
            size="small"
            value={pricingRuleStore.markupType}
            onChange={(event, value) => pricingRuleStore.setMarkupType(value)}
            options={buttonGroupOptions}
            data-testid={`${testIdBase}-markupSwitch`}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body2" color="textSecondary">
            {helpText}
          </Typography>
        </Grid>
      </Grid>
    </CardContent>
  )
}

PricingRule.propTypes = {
  pricingRuleStore: PropTypes.shape({
    markup: PropTypes.string.isRequired,
    markupType: PropTypes.oneOf(['disabled', 'multiplied', 'added']).isRequired,
    setMarkup: PropTypes.func.isRequired,
    setMarkupType: PropTypes.func.isRequired,
  }),
  code: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  titleHelp: PropTypes.string,
  productCostLabel: PropTypes.string.isRequired,
  markupError: PropTypes.string,
  helpText: PropTypes.node,
  allowDisable: PropTypes.bool,
}

export default observer(PricingRule)
