import React from 'react'

import PropTypes from 'prop-types'

import {
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  useMediaQuery,
} from '@material-ui/core'
import { useTheme } from '@material-ui/core/styles'
import { observer } from 'mobx-react'

import { PaginationStore } from 'retailer/stores'

import style from './BatchProductListActions.module.scss'

/**
 * Renders a batch selector/batch actions component used throughout Import/Sync/Archive Lists.
 * It allows to select all items on the page, as well as all items on all pages,
 * and run actions on those selected items.
 *    - For `actions` param provide an array of <BatchProductListActionItem> components.
 *    - For `pagination` param provide an instance of PaginationStore from the parent page.
 */
const BatchProductListActions = ({
  selectedAll,
  selectedAllOnAllPages,
  selectedSome,
  disabled,
  actions,
  pagination,
  togglePageSelection,
  selectAllOnAllPages,
  deselectAll,
  totalCountUnknown,
  className
}) => {
  const theme = useTheme()
  const mdUp = useMediaQuery(theme.breakpoints.up('md'))

  const checkbox = (
    <Checkbox
      color="primary"
      checked={selectedAll}
      onChange={togglePageSelection}
      indeterminate={selectedSome}
      disabled={disabled}
    />
  )

  return (
    <Grid container spacing={4} className={className}>
      <Grid item xs={12} xl="auto">
        <Grid container className={style.BatchProductListActions}>
          {!mdUp && (
            <FormControlLabel
              control={checkbox}
              label="Select All"
              labelPlacement="end"
            />
          )}
          {mdUp && checkbox}
          <Divider orientation={!mdUp ? 'horizontal' : 'vertical'} flexItem className={style.Divider} />
          {actions}
        </Grid>
      </Grid>
      {(selectedAll && !selectedAllOnAllPages && pagination.totalCount > pagination.pageSize) && (
        <Grid item xs={12} xl>
          <div className={style.SelectAllBanner}>
            <span>All products on this page are selected.</span>
            <Button color="primary" onClick={selectAllOnAllPages}>
              Select all {totalCountUnknown ? '' : pagination.totalCount} products on all pages
            </Button>
          </div>
        </Grid>
      )}
      {(selectedAll && selectedAllOnAllPages) && (
        <Grid item xs={12} xl>
          <div className={style.SelectAllBanner}>
            <span>{totalCountUnknown ? 'All' : pagination.totalCount} products <strong>on all pages</strong> are selected.</span>
            <Button color="primary" onClick={deselectAll}>
              Clear selection
            </Button>
          </div>
        </Grid>
      )}
    </Grid>
  )
}

BatchProductListActions.propTypes = {
  selectedAll: PropTypes.bool,
  selectedAllOnAllPages: PropTypes.bool,
  selectedSome: PropTypes.bool,
  disabled: PropTypes.bool,
  actions: PropTypes.arrayOf(PropTypes.node).isRequired,
  pagination: PropTypes.instanceOf(PaginationStore).isRequired,
  togglePageSelection: PropTypes.func.isRequired,
  selectAllOnAllPages: PropTypes.func.isRequired,
  deselectAll: PropTypes.func.isRequired,
  totalCountUnknown: PropTypes.bool,
  className: PropTypes.string
}

BatchProductListActions.defaultProps = {
  selectedAll: false,
  selectedAllOnAllPages: false,
  selectedSome: false,
  disabled: false,
  totalCountUnknown: false,
  className: ''
}

export default observer(BatchProductListActions)
