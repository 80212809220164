import React, { useState } from 'react'

import PropTypes from 'prop-types'

import { Button, Popover } from '@material-ui/core'
import dayjs from 'dayjs'
import { observer } from 'mobx-react'
import {
  DateRange as DateRangeImported,
  DateRangePicker as DateRangePickerImported,
} from 'react-date-range'
import { useMediaQuery } from 'react-responsive'

import { Calendar } from 'shared/icons'
import { queries } from 'shared/responsive'

import { DateRangeStore } from 'retailer/stores'

const DateRangePicker = ({ store }) => {
  const { id, state, setState } = store
  const [anchorElement, setAnchorElement] = useState(null)
  const handleClick = (event) => {
    setAnchorElement(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorElement(null)
  }

  const open = Boolean(anchorElement)
  const buttonId = open ? id : undefined
  const isMd = useMediaQuery(queries.md)

  const DateRangePickerElement = (
    isMd ? DateRangePickerImported : DateRangeImported
  )
  const now = new Date()

  return (
    <>
      <Button
        aria-describedby={buttonId}
        onClick={handleClick}
        startIcon={<Calendar />}
      >
        {dayjs(state.startDate).format('MMMM D, YYYY')}
        &nbsp;—&nbsp;
        {dayjs(state.endDate).format('MMMM D, YYYY')}
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorElement}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <DateRangePickerElement
          months={1}
          editableDateInputs={false}
          onChange={item => setState(item[id])}
          moveRangeOnFirstSelection={false}
          maxDate={now}
          ranges={[state]}
        />
      </Popover>
    </>
  )
}

DateRangePicker.propTypes = {
  store: PropTypes.instanceOf(DateRangeStore).isRequired,
}

export default observer(DateRangePicker)
