import { action, observable } from 'mobx'

import { adaptUserRequestedAccountRemoval } from 'shared/adapters/settings'
import { cancelAccountRemoval, getUserRequestedAccountRemoval, requestAccountRemoval } from 'shared/api/settings'
import { LoadableStore } from 'shared/stores'

class UserDeleteAccountStore {
  parent

  @observable loading = new LoadableStore()
  @observable userRequestedAccountRemoval = false
  @observable requestDatetime = null
  @observable isSaving = false
  @observable error

  constructor (parent) {
    this.parent = parent
  }

  @action.bound
  fetch () {
    this.loading.setLoading()
    return getUserRequestedAccountRemoval()
      .then(response => {
        Object.assign(this, adaptUserRequestedAccountRemoval(response.data))
        this.loading.setLoaded()
      })
  }

  @action.bound
  requestRemoval () {
    this.isSaving = true
    return requestAccountRemoval()
      .then(response => {
        Object.assign(this, adaptUserRequestedAccountRemoval(response.data))
        this.error = false
      })
      .catch(() => {
        this.error = true
      })
      .finally(() => {
        this.isSaving = false
      })
  }

  @action.bound
  cancelRemoval () {
    this.isSaving = true
    return cancelAccountRemoval()
      .then(() => {
        this.userRequestedAccountRemoval = false
        this.error = false
      })
      .catch(() => {
        this.error = true
      })
      .finally(() => {
        this.isSaving = false
      })
  }
}

export default UserDeleteAccountStore
