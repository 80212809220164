import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import isFunction from 'lodash/isFunction'

/**
 * Calculate the total number of values that are present in the fields object.
 * This is especially useful for use in forms.
 *
 * By default, each entry in the object is counted as one, but if the value is an array,
 * then each array element is counted towards the sum. The function also handles merged objects (recursively).
 * @param {Object} fields
 * @returns {Number}
 */
const calculateNumberOfActiveValues = (fields = {}) => {
  return Object.values(fields).reduce((sum, value) => {
    if (value === null || value === undefined) return sum // Ignore nulls and undefined
    if (isFunction(value)) return sum // Ignore functions (?)
    if (Array.isArray(value)) {
      if (!value.length) return sum // Ignore empty arrays
      return sum + value.length // Count array elements
    }
    if (typeof value === 'object') {
      if (isEmpty(value)) return sum // Ignore empty objects
      return sum + calculateNumberOfActiveValues(value) // Recursively calculate nested objects
    }
    return sum + 1 // Otherwise just count in the value as single value
  }, 0)
}

/**
 * @param {Object} dirtyFields
 * @param {string} fieldName
 * @returns {boolean}
 */
const checkFieldDirty = (dirtyFields, fieldName) => {
  return !!get(dirtyFields, fieldName)
}

export {
  calculateNumberOfActiveValues,
  checkFieldDirty,
}
