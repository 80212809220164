import React, { useEffect } from 'react'

import { Container, Typography } from '@material-ui/core'
import { observer } from 'mobx-react'
import { Helmet } from 'react-helmet'

import { ScrollResetter } from 'shared/components/atoms'

import { BusinessInformationForm, ShippingInformationForm } from 'retailer/components/organisms'
import { useStores } from 'retailer/stores/hooks'

import style from './BusinessInfoPage.module.scss'

const BusinessInfoPage = () => {
  const { settingsStore: { businessInformationSettings, shippingInformationSettings } } = useStores()

  useEffect(() => {
    businessInformationSettings.fetch()
    shippingInformationSettings.fetch()
  }, [])

  return (
    <div>
      <Helmet>
        <title>Business Info</title>
      </Helmet>
      <ScrollResetter />
      <Container maxWidth="xl">
        <Typography variant="h2" className={style.PageHeader}>
          Business Information
        </Typography>
        {businessInformationSettings.loading.isLoaded && (
          <BusinessInformationForm store={businessInformationSettings} />
        )}
        {(businessInformationSettings.loading.isLoaded && shippingInformationSettings.loading.isLoaded) && (
          <ShippingInformationForm
            isUnlocked={businessInformationSettings.isComplete}
            store={shippingInformationSettings}
          />
        )}
      </Container>
    </div>
  )
}

export default observer(BusinessInfoPage)
