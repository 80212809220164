import React from 'react'

import PropTypes from 'prop-types'

import { Backdrop, Fade, Modal, Paper } from '@material-ui/core'
import { observer } from 'mobx-react'

import style from './ImageZoom.module.scss'

/**
 * Opens up a single image in a modal.
 * Useful when you want to allow user to enlarge a small image.
 */
const ImageZoom = ({ isZoomed, handleClose, src, testId }) => {
  return (
    <Modal
      open={isZoomed}
      onClose={handleClose}
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
      keepMounted
      closeAfterTransition
    >
      <Fade in={isZoomed}>
        <div className={style.ImageZoom} onClick={handleClose}>
          <Paper component="img" src={src} alt="" className={style.Image} data-testid={testId} />
        </div>
      </Fade>
    </Modal>
  )
}

ImageZoom.propTypes = {
  isZoomed: PropTypes.bool,
  handleClose: PropTypes.func.isRequired,
  src: PropTypes.string,
  testId: PropTypes.string,
}

ImageZoom.defaultProps = {
  isZoomed: false
}

export default observer(ImageZoom)
