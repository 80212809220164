const USER_TYPE_RETAILER = 'B'
const USER_TYPE_SUPPLIER = 'D'

const ONLINE_CHANNEL_SHOPIFY = 'Shopify'
const ONLINE_CHANNEL_WIX = 'Wix'
const ONLINE_CHANNEL_OTHER = 'Other'

const RETAILER_AUTHORIZATION_NONE = ''
const RETAILER_AUTHORIZATION_SHOPIFY = 'shopify'
const RETAILER_AUTHORIZATION_WIX = 'wix'

export {
  USER_TYPE_RETAILER,
  USER_TYPE_SUPPLIER,
  ONLINE_CHANNEL_SHOPIFY,
  ONLINE_CHANNEL_WIX,
  ONLINE_CHANNEL_OTHER,
  RETAILER_AUTHORIZATION_NONE,
  RETAILER_AUTHORIZATION_SHOPIFY,
  RETAILER_AUTHORIZATION_WIX,
}
