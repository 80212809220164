import Cookies from 'js-cookie'
import { action, computed, observable } from 'mobx'

import SupplierAppIntegrationStore from './SupplierAppIntegrationStore'
const DISMISS_ALIBABA_MODAL_COOKIE_NAME = 'dismiss_alibaba_marketplace_modal'

class AlibabaIntegrationStore extends SupplierAppIntegrationStore {
  integrationNameDisplay = 'Alibaba'

  @observable marketplaceModalDismissed

  constructor (appConfigStore) {
    super('alibaba', appConfigStore)
    this.marketplaceModalDismissed = !!Cookies.get(DISMISS_ALIBABA_MODAL_COOKIE_NAME)
  }

  @computed get marketplaceUrl () {
    return this.isConnected && this.marketplaceModalDismissed
      ? this.appConfigStore.alibabaDropshippingPageRedirectUrl
      : false
  }

  @action.bound
  setMarketplaceModalDismissed (value) {
    this.marketplaceModalDismissed = value
    Cookies.set(DISMISS_ALIBABA_MODAL_COOKIE_NAME, value ? '1' : '', { expires: 365 })
  }
}

export default AlibabaIntegrationStore
