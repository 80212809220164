import React, { forwardRef } from 'react'

import PropTypes from 'prop-types'

import { Button, CircularProgress } from '@material-ui/core'
import { Sync } from '@material-ui/icons'
import clsx from 'clsx'

import style from './LoadingButton.module.scss'

/**
 * Drop-in replacement for @mui-lab/LoadingButton, remove after upgrading to mui v5
 */
const LoadingButton = forwardRef(({
  disabled, loading, loadingPosition, startIcon, endIcon, className, children, testId, ...props
}, ref) => {
  const classNames = clsx(style.LoadingButton, loading && style.isLoading, className)
  return (
    <Button
      ref={ref}
      startIcon={(loading && loadingPosition === 'start') ? <Sync /> : startIcon}
      endIcon={(loading && loadingPosition === 'end') ? <Sync /> : endIcon}
      disabled={disabled || loading}
      className={classNames}
      data-testid={testId}
      {...props}
    >
      {loading && loadingPosition === 'center' && <CircularProgress size="1.5em" />}
      <span className={style.LabelContent}>{children}</span>
    </Button>
  )
})

LoadingButton.propTypes = {
  loading: PropTypes.bool,
  loadingPosition: PropTypes.oneOf(['start', 'end', 'center']),
  testId: PropTypes.string,
  ...Button.propTypes,
}

LoadingButton.defaultProps = {
  loading: false,
  loadingPosition: 'center',
}

export default LoadingButton
