import React from 'react'

import PropTypes from 'prop-types'

import { IconButton, InputAdornment, TextField, Tooltip } from '@material-ui/core'
import clsx from 'clsx'
import { observer } from 'mobx-react'
import { Search } from 'wix-ui-icons-common'

import style from './SearchField.module.scss'

/**
 * Reusable search field based on TextField. It will call `onSubmit` function with the value from the input.
 *
 * @param {'start'|'end'}   buttonPlacement
 * @param {function}        onSubmit
 * @param {string}          size
 * @param {String}          className
 * @param {Object}          props
 * @returns {JSX.Element}
 * @constructor
 */
const SearchField = ({ buttonPlacement, onSubmit, size, className, ...props }) => {
  const handleSubmit = e => {
    e.preventDefault()
    onSubmit(e.target.elements.search.value)
  }

  const adornment = (
    <InputAdornment position={buttonPlacement}>
      <Tooltip title="Search" arrow>
        <IconButton
          data-testid="searchFieldSubmit"
          color="primary"
          type="submit"
          size={size === 'default' ? 'medium' : size}
        >
          <Search fontSize={size} />
        </IconButton>
      </Tooltip>
    </InputAdornment>
  )

  return (
    <form
      data-testid="searchFieldForm"
      onSubmit={handleSubmit}
      className={clsx(style.SearchField, size === 'small' && style.small, className)}
    >
      <TextField
        name="search"
        className={style.Search}
        InputProps={{
          className: style.Input,
          startAdornment: buttonPlacement === 'start' && adornment,
          endAdornment: buttonPlacement === 'end' && adornment,
        }}
        {...props}
      />
    </form>
  )
}

SearchField.propTypes = {
  buttonPlacement: PropTypes.oneOf(['start', 'end']),
  onSubmit: PropTypes.func.isRequired,
  size: PropTypes.oneOf(['default', 'small']),
  className: PropTypes.string,
  ...TextField.propTypes
}

SearchField.defaultProps = {
  buttonPlacement: 'end',
  size: 'default',
  className: ''
}

export default observer(SearchField)
