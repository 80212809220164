/* eslint camelcase: 0 */

import { keysToCamelCase, keysToSnakeCase } from 'shared/utils/api'

const adaptGetBusinessSettingsResponse = data => keysToCamelCase(data)

const adaptPutBusinessSettingsRequest = data => keysToSnakeCase(data)

const adaptPutBusinessSettingsErrors = ({ non_field_errors, ...field_errors }) => ({
  nonFieldErrors: non_field_errors,
  fieldErrors: keysToCamelCase(field_errors),
})

const adaptGetShippingSettingsResponse = data => keysToCamelCase(data)

const adaptPutShippingSettingsRequest = data => keysToSnakeCase(data)

const adaptPutShippingSettingsErrors = ({ non_field_errors, ...field_errors }) => ({
  nonFieldErrors: non_field_errors,
  fieldErrors: keysToCamelCase(field_errors),
})

export {
  adaptGetBusinessSettingsResponse,
  adaptPutBusinessSettingsRequest,
  adaptPutBusinessSettingsErrors,
  adaptGetShippingSettingsResponse,
  adaptPutShippingSettingsRequest,
  adaptPutShippingSettingsErrors,
}
