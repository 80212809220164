import Cookies from 'js-cookie'
import { action, computed, observable } from 'mobx'

import { UUID_REGEX_STRING } from 'shared/constants'
import { USER_TYPE_RETAILER } from 'shared/constants/accounts'
import { SHOPIFY, WIX } from 'shared/constants/integrations'
import { StoreIntegrationStore } from 'shared/stores'
import { extractShopifyStoreId } from 'shared/utils/integrations'

import {
  STEP_START,
  STEP_BACK_FROM_PERMISSIONS_GRANT,
  STEP_SHOPIFY_INSTRUCTIONS,
  STEP_AUTHORIZATION_CHECK_FAILED,
  STEP_SERVER_ERROR,
  STEP_CONFIRMATION_FAILED,
  STEP_AUTHORIZATION_CREATED,
  STEP_AUTHORIZATION_CHECK_IN_PROGRESS,
} from './constants'

const CONNECT_STORE_REGEX = new RegExp(
  `\\/my-stores\\/(?<businessId>${UUID_REGEX_STRING})\\/connect(\\/(?<platform>wix|shopify))?`
)

class ConnectStoreStore {
  /** @type {import('../context').RootStore} */
  root

  /** step = status in a Finite State Machine */
  @observable step = STEP_START

  /** platform to connect to chosen by the user */
  @observable integrationType

  /** an instance of StoreIntegrationStore */
  @observable storeIntegration

  /** The request to authorization API (including the redirects) is in progress */
  @observable isAuthorizationInProgress = false

  referral = 'retailer-my-stores'

  /**
   * @param {import('../context').RootStore} root
   * @param {Object} [data] data to initialize with
   */
  constructor (root) {
    this.root = root
  }

  @action.bound setStep (value) {
    this.step = value
  }

  @action.bound reset () {
    this.step = STEP_START
    this.integrationType = undefined
    this.storeIntegration = undefined
    this.isAuthorizationInProgress = false
  }

  @action.bound saveShopifyAuthorizationState () {
    const { businessId, referral } = this
    const expires = new Date(Date.now() + (60 * 60 * 1000))
    const payload = JSON.stringify({ b: businessId, r: referral })
    Cookies.set('shopify_authorization_state', payload, { expires })
  }

  /** Updates update flow state according to URL
   *
   * Should be called whenever the respective component (eg. ConnectStoreModal) appears
   * or a certain URL is navigated to to detect whether the view was called as a step
   * in the Oauth flow.
   */
  @action.bound async checkAuthorizationState () {
    const params = new URLSearchParams(this.root.routerStore.location.search)
    const authData = this.getAuthorizationData(params)
    // if Oauth params are set, meaning:
    // the integration has returned from the permissions grant,
    // immediately perform a confirmation
    if (authData) {
      this.setStep(STEP_BACK_FROM_PERMISSIONS_GRANT)

      const integrationType = this.getIntegrationTypeFromOauthParams(params)
      const business = this.getBusinessIdFromState(authData.state)
      const storeName = this.getStoreNameFromOauthParams(params)

      this.isAuthorizationInProgress = true
      this.storeIntegration = new StoreIntegrationStore({
        business,
        integrationType,
        integrationProfileType: USER_TYPE_RETAILER,
        storeName,
        referral: this.referral,
      })

      // re-check (important for Wix, since only now we know the instance id)
      try {
        await this.storeIntegration.checkAuthorization()
      } catch (error) {
        this.setStep(STEP_SERVER_ERROR)
        this.setIsAuthorizationInProgress(false)
        return
      }

      if (!this.storeIntegration.authorizationPossible) {
        this.setStep(STEP_AUTHORIZATION_CHECK_FAILED)
        this.setIsAuthorizationInProgress(false)
        return
      }

      try {
        await this.storeIntegration.confirmAuthorization(authData)
        this.root.userProfileStore.fetch() // updates authorization info
        this.setStep(STEP_AUTHORIZATION_CREATED)
        this.setIsAuthorizationInProgress(false)
      } catch (error) {
        this.setStep(STEP_CONFIRMATION_FAILED)
        this.setIsAuthorizationInProgress(false)
        throw error
      }
    } else if (this.designatedPlatform) {
      this.setConnectToPlatform(this.designatedPlatform)
      this.confirmIntegrationType()
    } else {
      this.setStep(STEP_START)
    }
  }

  @computed get businessId () {
    const match = this.root.routerStore.location.pathname.match(CONNECT_STORE_REGEX)
    return match ? match.groups.businessId : undefined
  }

  @computed get designatedPlatform () {
    const match = this.root.routerStore.location.pathname.match(CONNECT_STORE_REGEX)
    return match ? match.groups.platform : undefined
  }

  @action.bound setConnectToPlatform (value) {
    this.integrationType = value
  }

  @action.bound setIsAuthorizationInProgress (value) {
    this.isAuthorizationInProgress = value
  }

  @computed get showPrimaryAction () {
    return ![
      STEP_BACK_FROM_PERMISSIONS_GRANT,
      STEP_AUTHORIZATION_CHECK_IN_PROGRESS,
    ].includes(this.step)
  }

  @computed get primaryActionDisabled () {
    switch (true) {
      case this.step === STEP_START && !!this.integrationType:
      case [
        STEP_SHOPIFY_INSTRUCTIONS,
        STEP_CONFIRMATION_FAILED,
        STEP_AUTHORIZATION_CHECK_FAILED,
        STEP_AUTHORIZATION_CREATED
      ].includes(this.step):
        return false
      default: return true
    }
  }

  @computed get primaryActionLabel () {
    switch (this.step) {
      case STEP_AUTHORIZATION_CHECK_FAILED:
      case STEP_AUTHORIZATION_CREATED:
      case STEP_CONFIRMATION_FAILED:
        return 'connectStoreModal.dismissAction.cta'
      default: return 'connectStoreModal.nextAction.cta'
    }
  }

  @computed get showCancelAction () {
    return [
      STEP_START,
      STEP_SHOPIFY_INSTRUCTIONS,
      STEP_SERVER_ERROR,
    ].includes(this.step)
  }

  @computed get cancelActionDisabled () {
    return !!this.isAuthorizationInProgress
  }

  // step change actions

  @action.bound confirmIntegrationType () {
    switch (this.integrationType) {
      case WIX:
        if (this.designatedPlatform) {
          // settings this step will display a spinner instead of tiles
          // needed if user goes to "connect" straight from "create" with Wix selected
          this.step = STEP_AUTHORIZATION_CHECK_IN_PROGRESS
        }
        this.checkAuthorization() // storeName is undefined for Wix
        break
      case SHOPIFY:
        this.step = STEP_SHOPIFY_INSTRUCTIONS
        break
      default: break
    }
  }

  @action.bound confirmInstructions () {
    if (this.integrationType === SHOPIFY) {
      this.saveShopifyAuthorizationState()
    }
    this.checkAuthorization()
  }

  @action.bound checkAuthorization () {
    this.storeIntegration = new StoreIntegrationStore({
      business: this.businessId,
      integrationType: this.integrationType,
      integrationProfileType: USER_TYPE_RETAILER,
      referral: this.referral,
    })
    this.isAuthorizationInProgress = true
    this.storeIntegration.checkAuthorization()
      .then(data => {
        if (this.storeIntegration.authorizationPossible) {
          window.location.replace(data.installUrl)
        } else {
          this.setIsAuthorizationInProgress(false)
          this.setStep(STEP_AUTHORIZATION_CHECK_FAILED)
        }
      })
      .catch(() => {
        this.setIsAuthorizationInProgress(false)
      })
  }

  // helpers

  getAuthorizationData (params) {
    return params.has('code') && Object.fromEntries(params)
  }

  getIntegrationTypeFromOauthParams (params) {
    switch (true) {
      case params.has('instanceId'): return WIX
      case params.has('shop'): return SHOPIFY
      default: return undefined
    }
  }

  getStoreNameFromOauthParams (params) {
    switch (true) {
      case params.has('instanceId'): return params.get('instanceId')
      case params.has('shop'): return extractShopifyStoreId(params.get('shop'))
      default: return undefined
    }
  }

  getBusinessIdFromState (value) {
    const paramsString = Buffer.from(value, 'base64').toString('utf8')
    const params = new URLSearchParams(paramsString)
    return params.get('b')
  }
}

export default ConnectStoreStore
