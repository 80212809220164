import { action, computed, observable } from 'mobx'

import { adaptSubscriptionPlan } from 'shared/adapters/subscriptions'
import { getSubscriptionPlans } from 'shared/api/subscriptions'
import { PLAN_YEARLY, PLAN_MONTHLY } from 'shared/constants/settings'
import { LoadableStore } from 'shared/stores'

import {
  RetailerSubscription,
  SubscriptionPlanStore,
  SubscriptionPlanCancelStore,
  SubscriptionPlanChangeStore, RetailerExceedsProductLimitStore,
} from 'retailer/stores'

class ManagePlanStore {
  @observable loading = new LoadableStore()
  @observable retailerSubscription = new RetailerSubscription()
  @observable subscriptionPlans = []
  @observable subscriptionPeriod = PLAN_YEARLY
  subscriptionPeriodsAvailable = [
    [PLAN_YEARLY, 'Yearly'],
    [PLAN_MONTHLY, 'Monthly']
  ]

  @observable planCancelStore
  @observable planChangeStore
  @observable retailerExceedsProductLimitStore

  @computed get wixError () {
    return this.subscriptionPlans.some(plan => !!plan.wixCheckoutUrl.error)
  }

  @computed get wixErrorCodes () {
    return this.subscriptionPlans.filter(plan => !!plan.wixCheckoutUrl.error).map(plan => plan.wixCheckoutUrl.error)
  }

  @computed get plansForPeriod () {
    return this.subscriptionPlans.filter(plan => !plan.period || plan.period === this.subscriptionPeriod)
  }

  /**
   * Load available plans and refresh user's subscription.
   * @returns {Promise<(AxiosResponse<any>|void)[]>}
   */
  @action.bound
  reload () {
    const promises = [
      getSubscriptionPlans().then(response => {
        this.subscriptionPlans = response.data.results.map(
          plan => new SubscriptionPlanStore(adaptSubscriptionPlan(plan))
        )
      }),
      this.retailerSubscription.fetch()
    ]
    return Promise.all(promises)
  }

  /**
   * Uses reload buh with indicating loading state. Used for initial data fetch.
   * @returns {Promise<(AxiosResponse<*>|void)[]>}
   */
  @action.bound
  fetch () {
    this.loading.setLoading()
    return this.reload().finally(this.loading.setLoaded)
  }

  @action.bound
  changeSubscriptionPeriod (value) {
    this.subscriptionPeriod = value
  }

  @action.bound
  selectPlanForChange (plan) {
    this.planChangeStore = new SubscriptionPlanChangeStore(this, plan)
  }

  @action.bound
  resetPlanForChange () {
    this.planChangeStore = null
  }

  @action.bound
  startPlanCancellation () {
    this.planCancelStore = new SubscriptionPlanCancelStore(this)
  }

  @action.bound
  resetPlanCancellation () {
    this.planCancelStore = null
  }

  @action.bound
  setRetailerExceedsProductLimitStore (plan) {
    this.retailerExceedsProductLimitStore = new RetailerExceedsProductLimitStore(this, plan)
  }

  @action.bound
  resetRetailerExceedsProductLimitStore () {
    this.retailerExceedsProductLimitStore = null
  }
}

export default ManagePlanStore
