import { action, computed, reaction } from 'mobx'
import qs from 'query-string'

import { BaseListStore } from 'shared/stores'

import { adaptGetWarehousesResponse, adaptPostWarehouseRequest, adaptPostWarehouseResponse, adaptPostWarehouseSetDefaultResponse } from 'supplier/adapters/warehouses'
import { deleteWarehouse, getWarehouses, postWarehouse, postWarehouseSetDefault } from 'supplier/api/warehouses'
import { WarehouseStore } from 'supplier/stores'

/**
 * Store for managing Warehouses
 *
 * XXX: currently unused and waiting until we are prepared to go back
 * to allowing users to define multiple shipping origins aka warehouses
 */
class WarehouseListStore extends BaseListStore {
  /** @type {import('../root').RootStore} */
  root

  /**
   * @param {import('../root').RootStore} root
   */
  constructor (root, data = {}) {
    super(root.routerStore, data)
    this.root = root
    reaction(this.listParamsGetter, this.listParamsReaction)
  }

  @computed get originAreas () {
    const userShippingCountries = this.items.map(item => item.shippingCountry.id)
    return this.appConfigStore.shippingCountries.filter(
      ({ id }) => !userShippingCountries.includes(id)
    )
  }

  getUrl (params) {
    const search = qs.stringify({ offset: params.offset || undefined })
    return '/shipping' + (search ? `?${search}` : '')
  }

  doFetch () {
    const { offset } = this
    return getWarehouses({ offset })
      .then(response => {
        const results = adaptGetWarehousesResponse(response.data).results
        this.setItems(results.map(row => new WarehouseStore(row)))
        return response
      })
  }

  @action.bound
  addWarehouse (area) {
    const payload = { shippingCountryId: area.id }
    return postWarehouse(adaptPostWarehouseRequest(payload))
      .then(response => {
        const data = adaptPostWarehouseResponse(response.data)
        this.items.push(new WarehouseStore(data))
        return data
      })
  }

  @action.bound
  deleteWarehouse (warehouseStore) {
    return deleteWarehouse(warehouseStore.id)
      .then(_response => {
        this.items.remove(warehouseStore)
        return true
      })
  }

  @action.bound
  setDefaultWarehouse (warehouseStore) {
    return postWarehouseSetDefault(warehouseStore.id)
      .then(response => {
        const data = adaptPostWarehouseSetDefaultResponse(response.data)
        warehouseStore.update(data)
        return data
      })
  }
}

export default WarehouseListStore
