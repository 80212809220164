import { action, computed, observable } from 'mobx'

import { integrationsNames } from 'shared/constants/integrations'

import { ShippingCountryStore } from 'retailer/stores'

const DEFAULT_COUNTRY = 'US'

class ItemShippingStore {
  @observable item
  @observable.shallow countries = []
  @observable retailerCountry
  @observable selectedCountry = DEFAULT_COUNTRY

  constructor (item, { countries = [], defaultCountry = DEFAULT_COUNTRY, ...rest } = {}) {
    this.item = item
    this.countries = countries.map(
      country => new ShippingCountryStore(country)
    )
    this.retailerCountry = new ShippingCountryStore(defaultCountry)
    Object.assign(this, rest)
  }

  @computed get thirdPartyName () {
    return this.isExternal ? integrationsNames.get(this.item.inventoryType) : null
  }

  @computed get thirdPartyRatesUrl () {
    return this.item.externalUrl
  }

  @computed get activeCountry () {
    return this.countries.find(
      country => country.countryCode === this.selectedCountry,
    )
  }

  @computed get isExternal () {
    return this.item.isAliExpress || this.item.isAlibaba
  }

  @action.bound
  selectCountry (code) {
    this.selectedCountry = code
  }
}

export default ItemShippingStore
