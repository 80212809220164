import React from 'react'

import PropTypes from 'prop-types'

import { Typography } from '@material-ui/core'
import clsx from 'clsx'
import { observer } from 'mobx-react'

import { Country } from 'retailer/components/atoms'

import style from './Address.module.scss'

const Address = ({ data, className, ...rest }) => {
  const { companyName, recipientName, address, city, zipcode, state, country } = data
  return (
    <Typography component="div" className={clsx(style.Address, className)} {...rest}>
      {companyName && <span>{companyName}</span>}
      {recipientName && <span>{recipientName}</span>}
      {address && <span>{address}</span>}
      {(city || zipcode || state) && (
        <span>
          {city}{city && state ? ', ' : ''} {state} {zipcode}
        </span>
      )}
      {country && <span><Country iso={country} /></span>}
    </Typography>
  )
}

Address.propTypes = {
  data: PropTypes.shape({
    companyName: PropTypes.string,
    recipientName: PropTypes.string,
    address: PropTypes.string.isRequired,
    city: PropTypes.string.isRequired,
    zipcode: PropTypes.string.isRequired,
    state: PropTypes.string,
    country: PropTypes.string,
  }).isRequired,
  className: PropTypes.string,
}

export default observer(Address)
