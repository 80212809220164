import React, { useState } from 'react'

import PropTypes from 'prop-types'

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Box,
  Grid,
  LinearProgress,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
  withStyles,
} from '@material-ui/core'
import {
  AddAlertRounded,
  CheckRounded,
  ChevronRightRounded,
  EditRounded,
  ExpandMoreRounded,
  LockRounded,
  NotificationImportantRounded,
} from '@material-ui/icons'
import clsx from 'clsx'
import Cookies from 'js-cookie'
import { observer } from 'mobx-react'
import { Link as RouterLink } from 'react-router-dom'

import style from './OnboardingWidget.module.scss'

const ONBOARDING_WIDGET_EXPANDED_COOKIE = 'onboardingWidgetExpanded'

const StepsProgressBar = withStyles((theme) => ({
  root: { height: '.5em', borderRadius: '.25em' },
  colorPrimary: { backgroundColor: theme.palette.primary[400] },
  bar: { borderRadius: 5 },
}))(LinearProgress)

const ProgressIndicator = ({ completed, total }) => {
  const value = 100 * completed / total
  return (
    <div className={style.ProgressIndicator}>
      <Typography
        variant="body2" className={style.ProgressIndicatorText}
        data-testid="onboardingWidget-progressIndicator-progressIndicatorText"
      >
        <span>{completed}/{total}</span> steps completed
      </Typography>
      <StepsProgressBar
        variant="determinate"
        value={value}
        color="primary"
        className={style.ProgressIndicatorBar}
      />
    </div>
  )
}

ProgressIndicator.propTypes = {
  completed: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
}

const SetupStep = ({ completed, disabled, title, subTitle, href, to }) => {
  const ActionIcon = completed ? EditRounded : ChevronRightRounded
  const className = clsx(style.Step, completed && style.StepCompleted, disabled && style.StepDisabled)
  const component = to ? RouterLink : 'a'
  return (
    <ListItem
      button disabled={disabled} component={component} href={href} to={to} className={className}
      data-testid="onboardingWidget-setupStep"
      data-test-state={completed ? 'completed' : 'incomplete'}
    >
      <ListItemAvatar>
        <Avatar>
          {completed ? <CheckRounded /> : (disabled ? <LockRounded /> : <AddAlertRounded />)}
        </Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={title} secondary={subTitle} className={style.StepText}
        data-testid="onboardingWidget-setupStep-text"
      />
      {!disabled && <ActionIcon className={style.StepActionIcon} />}
    </ListItem>
  )
}

SetupStep.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  subTitle: PropTypes.string,
  completed: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
  href: PropTypes.string,
  to: PropTypes.string,
}

SetupStep.defaultProps = {
  disabled: false,
}

const OnboardingWidget = ({
  children,
  title,
  description,
  numStepsCompleted,
  numStepsTotal,
  defaultExpanded,
  ...paperProps
}) => {
  const savedExpanded = Cookies.get(ONBOARDING_WIDGET_EXPANDED_COOKIE)
  const [expanded, setExpanded] = useState(savedExpanded === undefined ? defaultExpanded : !!parseInt(savedExpanded))

  const handleOnChange = () => {
    const value = !expanded
    setExpanded(value)
    Cookies.set(ONBOARDING_WIDGET_EXPANDED_COOKIE, value ? 1 : 0, { path: '/', expires: 365 })
  }

  return (
    <Accordion
      expanded={expanded}
      onChange={handleOnChange}
      {...paperProps}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreRounded />}
        className={style.Header}
        data-testid="onboardingWidget-header"
      >
        <div className={style.TitleContainer}>
          <NotificationImportantRounded
            color="primary"
            className={style.TitleIcon}
          />
          <Typography variant="body1" component="h3" data-testid="onboardingWidget-onboardingTitle-onboardingTitleText">
            {title}
          </Typography>
        </div>
        <div className={style.ProgressIndicatorContainer}>
          <ProgressIndicator completed={numStepsCompleted} total={numStepsTotal} />
        </div>
      </AccordionSummary>
      <AccordionDetails className={style.Content}>
        <Grid container direction="row" spacing={0}>
          <Grid item xs={12} lg={5}>
            <Box px={2} pb={2}>
              {description}
            </Box>
          </Grid>
          <Grid item xs={12} lg={7} className={style.StepsListContainer}>
            <List dense disablePadding component="nav" className={style.StepsList}>
              {children}
            </List>
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  )
}

OnboardingWidget.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.node.isRequired,
  numStepsCompleted: PropTypes.number.isRequired,
  numStepsTotal: PropTypes.number.isRequired,
  children: PropTypes.node.isRequired,
  defaultExpanded: PropTypes.bool,
  className: PropTypes.string,
}

OnboardingWidget.defaultProps = {
  defaultExpanded: false,
}

export default observer(OnboardingWidget)
export { SetupStep }
