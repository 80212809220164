import { ERROR_GENERIC_API_ERROR } from 'shared/constants/messages'

/**
 * Parse an error raised during the API request into internal representation for use in forms, dialogs etc.
 *
 * Converts a range of possible request outcomes into a standardized internal representation that consists of:
 *  - fieldErrors - related to fields from the POST/PUT/PATCH payload, usually related to form fields
 *  - nonFieldErrors - cross-field validation, general, critical, connectivity errors etc.
 *
 * @param {Error} apiError error instance as received from api, may contain response object
 * @param {Function} errorAdapter function to parse the 400 response received from the api into internal representation
 * @param {String} errorFieldName name of a response field that holds the single API error message
 * @returns {Object} an error message object, consisting of fieldErrors and nonFieldErrors
 */
const processApiError = (apiError, errorAdapter, errorFieldName = null) => {
  errorFieldName = errorFieldName || 'detail'
  if (apiError.response) {
    const { data, status } = apiError.response
    if (status === 400) {
      return errorAdapter(data)
    } else {
      return { nonFieldErrors: [data[errorFieldName] || ERROR_GENERIC_API_ERROR] }
    }
  }
  return { nonFieldErrors: [ERROR_GENERIC_API_ERROR] }
}

export {
  processApiError
}
