import { action, computed, observable } from 'mobx'

import { adaptGetGuideResponse } from 'supplier/adapters/guides'
import { getGuide } from 'supplier/api/guides'

class GuideStore {
  /** @type {import('../root').RootStore} */
  root

  @observable isLoading
  @observable isNotFound
  @observable url
  @observable title
  @observable content

  /**
   * @param {import('../root').RootStore} root
   */
  constructor (root, data = {}) {
    this.root = root
    this.update(data)
  }

  @computed get currentUrl () {
    return this.url && this.url.split('/').filter(chunk => !!chunk).join('/')
  }

  @action.bound
  update (data) {
    Object.assign(this, data)
  }

  @action.bound
  setIsLoading (value) {
    this.isLoading = value
  }

  @action.bound
  setIsNotFound (value) {
    this.isNotFound = value
  }

  @action.bound
  setTitle (value) {
    this.title = value
  }

  @action.bound
  fetch (url) {
    this.reset()
    this.isNotFound = undefined
    this.isLoading = true

    return getGuide(url)
      .then(response => {
        const data = adaptGetGuideResponse(response.data)
        this.update(data)
        return data
      })
      .catch(error => {
        if (error.response?.status === 404) {
          this.setIsNotFound(true)
          this.setTitle('Not found')
        }
      })
      .finally(() => {
        this.setIsLoading(false)
      })
  }

  @action.bound
  reset () {
    this.title = undefined
    this.url = undefined
    this.content = undefined
    this.isNotFound = undefined
  }
}

export default GuideStore
