import pick from 'lodash/pick'
import { action, computed, observable, reaction } from 'mobx'
import qs from 'query-string'

import { BaseListStore } from 'shared/stores'

import { adaptGetOrdersResponse, adaptOrder } from 'supplier/adapters/orders'
import { getOrders, getOrdersExportUrl, postCancelOrders } from 'supplier/api/orders'
import { FulfillOrderStore, OrderStore } from 'supplier/stores'

const PATHNAME_REGEX = /^\/orders\/(?<filter>[a-z-]+){1}$/

class OrderListStore extends BaseListStore {
  /** @type {import('../root').RootStore} */
  root
  pathnameRegex = PATHNAME_REGEX

  @observable fulfillOrderStore

  listParamsGetter = () => ({ filter: this.filter, query: this.query, offset: this.offset })

  /**
   * @param {import('../root').RootStore} root
   */
  constructor (root, data = {}) {
    super(root.routerStore, data)
    this.root = root
    reaction(this.listParamsGetter, this.listParamsReaction)
  }

  @computed
  get filter () {
    return this.pathnameMatch?.groups.filter
  }

  @computed
  get query () {
    const params = new URLSearchParams(this.root.routerStore?.location.search)
    return params.get('query') || undefined
  }

  @action.bound
  setFilters (filter, query) {
    this.root.routerStore.push(this.getUrl({ filter, query, offset: 0 })) // offset reset added for verbosity
  }

  getUrl (params) {
    const { filter, query, offset } = params
    const search = qs.stringify({ query, offset: offset || undefined })
    return `/orders/${filter}` + (search ? `?${search}` : '')
  }

  doFetch () {
    const { filter, query, offset } = this
    return getOrders({ filter, query, offset })
      .then(response => {
        const results = adaptGetOrdersResponse(response.data).results
        this.setItems(results.map(row => new OrderStore(row)))
        return response
      })
  }

  /** Params that make up the current collection (across all pages) */
  @computed get collectionParams () {
    return pick(this, ['filter', 'query'])
  }

  @action.bound
  beginFulfillOrder (order) {
    this.fulfillOrderStore = new FulfillOrderStore(order)
  }

  @action.bound
  resetFulfillOrder () {
    this.fulfillOrderStore = undefined
  }

  @action.bound
  cancelOrders (orders) {
    const payload = {
      objects: orders.map(order => order.uuid)
    }
    // TODO: set in progress & lock view
    return postCancelOrders(payload)
      .then(({ data }) => {
        data.forEach(updated => {
          const updatedIndex = this.items.findIndex(item => item.uuid === updated.uuid)
          if (updatedIndex > -1) {
            this.items[updatedIndex] = new OrderStore(adaptOrder(updated))
          }
        })
        return data
      })
  }

  /** Export CSV of currently selected orders */
  @action.bound
  exportSelectedOrders () {
    const ids = this.allOnAllPagesSelected ? undefined : this.selectedIds
    window.open(getOrdersExportUrl({ ...this.collectionParams, ids }))
    this.setAllSelected(false)
  }
}

export default OrderListStore
