import { action, computed, observable } from 'mobx'

import { AsyncBatchActionsStore, LoadableStore } from 'shared/stores'

/**
 * Generic store for iterative async functions, like performing an action on multiple items.
 * A good example here can be removing retailer items in batch.
 * The store should receive a `getObjects` async function, and a `processorFunc` that will
 * be processing these exact objects.
 */
class IteratingBatchActionStore {
  @observable initialized = false
  @observable loading = new LoadableStore()
  @observable error
  @observable asyncHandlerStore

  @observable objects = []

  @computed get count () {
    return this.objects.length
  }

  @action.bound
  initialize ({ ...asyncBatchActionsStoreParams } = {}) {
    this.initialized = true
    this.loading.setLoading()
    return this.getObjects()
      .then(objects => {
        this.objects = objects
        this.asyncHandlerStore = new AsyncBatchActionsStore({
          ...asyncBatchActionsStoreParams,
          processorFunc: this.processObject,
          iterable: this.objects
        })
      })
      .catch(error => {
        this.error = error
        throw error
      })
      .finally(this.loading.setLoaded)
  }

  /**
   * Method that will be invoked against each of the items from `this.objects` array.
   * @param {*} obj
   * @returns {Promise<*>}
   */
  @action.bound
  async processObject (obj) {
    return Promise.reject(
      Error('processObject method must be implemented')
    )
  }

  /**
   * This method should return an array of objects, which will be saved to `this.objects` for further processing.
   * @returns {Promise<*>}
   */
  @action.bound
  async getObjects () {
    return Promise.reject(
      Error('getObjects method has to be implemented')
    )
  }

  @action.bound
  async run () {
    await this.asyncHandlerStore.run()
  }

  @action.bound
  stop () {
    return this.asyncHandlerStore.stop()
  }

  @action.bound
  reset () {
    this.initialized = false
    this.loading.reset()
    this.error = undefined
    this.asyncHandlerStore = undefined
    this.objects = []
  }
}

export default IteratingBatchActionStore
