export const PRODUCT_VIEWGROUP_ADD_PRODUCTS = 'add-products'
export const PRODUCT_VIEWGROUP_MY_PRODUCTS = 'products'

export const PRODUCT_FILTER_ALL = 'all'
export const PRODUCT_FILTER_PUBLISHABLE = 'publishable'
export const PRODUCT_FILTER_PUBLISHED = 'published'
export const PRODUCT_FILTER_UNPUBLISHED = 'unpublished'
export const PRODUCT_FILTER_INVALID = 'invalid'
export const PRODUCT_FILTER_SYNC_FAILED = 'sync-failed'
export const PRODUCT_FILTER_UNSYNCED = 'unsynced'

export const STATUS_IN_INTEGRATION_ACTIVE = 'active'
export const STATUS_IN_INTEGRATION_DRAFT = 'draft'

export const PRODUCT_LIST_PATHNAME_REGEX = /\/(?<viewgroup>products|add-products)\/(?!new$)(?<filter>[a-z-]+)$/

export const PRODUCT_COLLECTION_TITLES = new Map([
  [PRODUCT_FILTER_ALL, 'All products'],
  [PRODUCT_FILTER_PUBLISHABLE, 'My products'],
  [PRODUCT_FILTER_PUBLISHED, 'Published products'],
  [PRODUCT_FILTER_UNPUBLISHED, 'Unpublished products'],
  [PRODUCT_FILTER_INVALID, 'Incomplete products (action required)'],
  [PRODUCT_FILTER_SYNC_FAILED, 'Products failed to sync'],
  [PRODUCT_FILTER_UNSYNCED, 'Unsynced products'],
])

export const ACTION_PUBLISH = 'publish'
export const ACTION_UNPUBLISH = 'unpublish'
export const ACTION_UNSYNC = 'unsync'
export const ACTION_RESYNC = 'resync'
export const ACTION_CHANGE_CATEGORY = 'change_category'

export const ACTION_LABELS = new Map([
  [ACTION_PUBLISH, 'Publish'],
  [ACTION_UNPUBLISH, 'Unpublish'],
  [ACTION_UNSYNC, 'Unsync'],
  [ACTION_RESYNC, 'Re-sync'],
  [ACTION_CHANGE_CATEGORY, 'Change category'],
])

export const ACTION_DESCRIPTIONS = new Map([
  [ACTION_PUBLISH, 'Publish product in the Modalyst marketplace'],
  [ACTION_UNPUBLISH, 'Remove product from the Modalyst marketplace'],
  [ACTION_UNSYNC, 'Disable sync with integrated store and remove from marketplace'],
  [ACTION_RESYNC, 'Enable sync with integrated store'],
  [ACTION_CHANGE_CATEGORY, 'Change product category'],
])
