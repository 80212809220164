/* eslint camelcase: 0 */
import sortBy from 'lodash/sortBy'

import {
  retailerItemExportStatusToSyncStatus,
  syncListOrderingToApiParam,
  importListOrderingToApiParam,
  syncStatusToRetailerItemExportStatus,
} from 'shared/constants/retailerItems'
import { keysToCamelCase, keysToSnakeCase } from 'shared/utils/api'
import { toTitleCase } from 'shared/utils/text'
import { toCamelCase, toSnakeCase } from 'shared/utils/text/text'

const adaptRetailerItemsCount = ({
  new_count,
  published_count,
  unpublished_count
}) => {
  return {
    importList: new_count,
    syncList: published_count,
    archiveList: unpublished_count
  }
}

/**
 * For `discounted_price` price we want to show it as `Integration Price`.
 * Otherwise just change the key to its title case.
 * @param {String} key
 * @returns {String}
 */
const adaptPricingRangeName = key => {
  return toTitleCase(key)
}

/**
 * @param {String} key
 * @param {Object} data
 * @returns {*&{name: String, from: String, to: String}}
 */
const adaptPricingRange = (key, data) => {
  return {
    name: adaptPricingRangeName(key),
    ...keysToCamelCase(data)
  }
}

/**
 * Constructs an object where price type is the key, and its value is another object with data (from, to, etc.).
 * @param data
 * @returns {{}|{[p: string]: *}}
 */
const adaptPricingRanges = data => {
  if (!data) return {}
  return Object.fromEntries(
    Object.entries(data).map(([key, data]) => {
      return [toCamelCase(key), adaptPricingRange(key, data)]
    })
  )
}

const adaptRetailerItemImage = ({ uuid, image, thumbnail, ...rest }) => {
  return {
    id: uuid,
    url: image,
    thumbnail,
    ...keysToCamelCase(rest)
  }
}

const adaptExportStatus = exportStatus => {
  const mappedStatus = retailerItemExportStatusToSyncStatus.get(exportStatus)
  return mappedStatus || exportStatus
}

const adaptOptions = data => {
  return sortBy(data.map(option => keysToCamelCase(option)), 'position')
}

const adaptRetailerProduct = ({
  created, long_description, pricing_ranges, images, main_image, variants, options,
  created_from, customized_item_draft_ref, customized_item_job_ref, custom_editor_config,
  ...rest
}) => ({
  created: new Date(created),
  description: long_description,
  pricingRanges: pricing_ranges && adaptPricingRanges(pricing_ranges),
  images: images ? images.map(image => adaptRetailerItemImage(image)) : [],
  mainImage: main_image ? adaptRetailerItemImage(main_image) : null,
  variants: variants && variants.map(keysToCamelCase),
  options: options && adaptOptions(options),
  createdFrom: created_from === '' ? null : created_from,
  customizedItemDraftRef: customized_item_draft_ref === '' ? null : customized_item_draft_ref,
  customizedItemJobRef: customized_item_job_ref === '' ? null : customized_item_job_ref,
  customEditorConfig: custom_editor_config === '' ? undefined : custom_editor_config,
  ...keysToCamelCase(rest),
})

const adaptImportListItem = ({
  supplier_product_shipping, ...rest
}) => {
  return {
    ...adaptRetailerProduct(rest),
    supplierProductShipping: supplier_product_shipping ? keysToCamelCase(supplier_product_shipping) : null,
  }
}

const adaptRetailerItemVariant = ({ image, ...rest }) => {
  return {
    image: image ? adaptRetailerItemImage(image) : null,
    ...keysToCamelCase(rest)
  }
}

const adaptSyncListItem = ({
  export_status, sync_status_badge, ...rest
}) => {
  return {
    ...adaptRetailerProduct(rest),
    exportStatus: adaptExportStatus(export_status),
    syncStatusBadge: keysToCamelCase(sync_status_badge)
  }
}

const adaptImportListApiParams = ({ supplier, ordering, ...rest }) => {
  return {
    inventory_types: supplier.map(it => toSnakeCase(it)).join(','),
    ordering: importListOrderingToApiParam.get(ordering),
    ...keysToSnakeCase(rest)
  }
}

const adaptSyncListApiParams = ({ exportStatuses, inventoryTypes, ordering, ...rest }) => {
  return {
    export_statuses: exportStatuses.map(es => syncStatusToRetailerItemExportStatus.get(es)).join(','),
    inventory_types: inventoryTypes.map(it => toSnakeCase(it)).join(','),
    ordering: syncListOrderingToApiParam.get(ordering),
    ...keysToSnakeCase(rest)
  }
}

const adaptImportListCounters = data => keysToCamelCase(data)

const adaptSyncListCounters = data => keysToCamelCase(data)

const adaptProductType = data => keysToCamelCase(data)

const adaptProductTypes = data => data.map(adaptProductType)

const adaptTags = data => data.map(tag => keysToCamelCase(tag))

const adaptCategories = data => data.map(category => keysToCamelCase(category))

const adaptCollections = data => data.map(collection => keysToCamelCase(collection))

const adaptRetailerItemGeneralTabForm = ({ name, vendor, productType, collections, categories }) => {
  return {
    name: name,
    vendor: vendor,
    product_type_id: productType?.uuid,
    collection_ids: collections ? collections.map(collection => collection.uuid) : undefined,
    category_ids: categories ? categories.map(category => category.uuid) : undefined
  }
}

const adaptRetailerItemGeneralErrorsToFieldErrors = ({
  name,
  vendor,
  product_type_id,
  collection_ids,
  category_ids,
}) => {
  return {
    name,
    vendor,
    productType: product_type_id,
    collections: collection_ids,
    categories: category_ids,
  }
}

const adaptRetailerItemDescriptionTabForm = ({ description }) => {
  return {
    long_description: description,
  }
}

const adaptRetailerItemDescriptionErrorsToFieldErrors = ({
  long_description
}) => {
  return {
    description: long_description,
  }
}

const adaptRenameOption = data => keysToSnakeCase(data)

const adaptImportListProductPricingPayload = ({ comparedAtPrice, price }) => {
  return {
    price,
    compared_at_price: comparedAtPrice,
  }
}

const adaptShippingOption = ({
  min_estimated_delivery,
  max_estimated_delivery,
  shipping_provider,
  shipping_cost,
  converted_shipping_cost,
  shipping_provider_name,
}) => {
  return {
    minEstimatedDelivery: min_estimated_delivery,
    maxEstimatedDelivery: max_estimated_delivery,
    shippingProvider: shipping_provider,
    shippingCost: shipping_cost,
    convertedShippingCost: converted_shipping_cost,
    shippingProviderName: shipping_provider_name,
  }
}

const adaptShippingProviderForApi = ({
  minEstimatedDelivery,
  maxEstimatedDelivery,
  shippingProvider,
  shippingCost,
  convertedShippingCost,
  shippingProviderName,
}) => {
  return {
    min_estimated_delivery: minEstimatedDelivery,
    max_estimated_delivery: maxEstimatedDelivery,
    shipping_provider: shippingProvider,
    shipping_cost: shippingCost,
    converted_shipping_cost: convertedShippingCost,
    shipping_provider_name: shippingProviderName,
  }
}

export {
  adaptRetailerItemsCount,
  adaptRetailerItemVariant,
  adaptPricingRange,
  adaptPricingRanges,
  adaptRetailerProduct,
  adaptImportListItem,
  adaptImportListCounters,
  adaptImportListApiParams,
  adaptSyncListItem,
  adaptSyncListApiParams,
  adaptSyncListCounters,
  adaptProductType,
  adaptProductTypes,
  adaptTags,
  adaptCategories,
  adaptCollections,
  adaptRetailerItemGeneralTabForm,
  adaptRetailerItemGeneralErrorsToFieldErrors,
  adaptRetailerItemDescriptionTabForm,
  adaptRetailerItemDescriptionErrorsToFieldErrors,
  adaptRenameOption,
  adaptImportListProductPricingPayload,
  adaptShippingOption,
  adaptShippingProviderForApi,
}
