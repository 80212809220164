import React from 'react'

import PropTypes from 'prop-types'

import { Button, Tooltip } from '@material-ui/core'
import clsx from 'clsx'
import { observer } from 'mobx-react'

import { ExternalLink } from 'shared/icons'

import style from './ExternalSourceLink.module.scss'

const ExternalSourceLink = ({ url, icon, title, className }) => {
  const button = (
    <Button
      color="primary"
      href={url}
      target="_blank"
      size="small"
      rel="noopener noreferrer"
      variant="outlined"
      startIcon={icon}
      endIcon={<ExternalLink width={16} height={16} />}
      className={clsx(style.ExternalSourceLink, className)}
    />
  )

  if (!title) return button

  return (
    <Tooltip title={title}>
      {button}
    </Tooltip>
  )
}

ExternalSourceLink.propTypes = {
  url: PropTypes.string.isRequired,
  icon: PropTypes.node.isRequired,
  title: PropTypes.string,
  className: PropTypes.string
}

ExternalSourceLink.defaultProps = {
  className: ''
}

export default observer(ExternalSourceLink)
