import get from 'lodash/get'
import set from 'lodash/set'
import unset from 'lodash/unset'
import { action, computed, observable } from 'mobx'

import { adaptRetailerItemVariant, adaptImportListProductPricingPayload } from 'shared/adapters/retailerItems'
import { patchRetailerItemVariant } from 'shared/api/retailerItems'
import { TAB_PRICING, TAB_VARIANTS } from 'shared/constants/importList'
import { ImageStore } from 'shared/stores'
import { getBiHeadersFromEvent } from 'shared/stores/BiLoggerStore/utils'

import { constructPricingFieldName, flattenApiErrors, getSkuFieldName } from 'retailer/utils/importList'

class ImportListProductVariantStore {
  @observable parent

  @observable errors = {
    fieldErrors: {},
    nonFieldErrors: {},
  }

  @observable active = false
  @observable uuid
  @observable sku
  @observable imageId
  @observable image
  @observable quantity

  @observable cost
  @observable price
  @observable retailPrice
  @observable comparedAtPrice
  @observable profit
  @observable shipping
  @observable currency = 'USD'

  @observable options = []

  constructor (parent, data = {}) {
    this.parent = parent
    this.assignData(data)
  }

  @computed get optionNames () {
    return this.options.map(option => option.value).join(' | ')
  }

  @action.bound
  clearError (path) {
    unset(this.errors.fieldErrors, path)
  }

  @action.bound
  assignData (data = {}) {
    Object.assign(this, data)
    this.image = data?.image ? new ImageStore(data.image) : null
  }

  @action.bound
  async setActive (value) {
    const biEvent = await this.parent.logProductEdited({
      modalystVariantIds: this.uuid,
      settingTab: TAB_VARIANTS,
      settingField: 'variantActivatedChanged',
      oldValue: this.active,
      newValue: !!value,
    })
    try {
      await this.patch({ active: !!value }, biEvent)
      return true
    } catch (e) {
      return false
    }
  }

  @action.bound
  async updatePrices ({ price, comparedAtPrice }) {
    const adaptedData = adaptImportListProductPricingPayload({ price, comparedAtPrice })
    const fieldName = Object.keys(adaptedData)[0]
    unset(this.errors.fieldErrors, constructPricingFieldName(this.uuid, fieldName))
    const biEvent = await this.parent.logProductEdited({
      modalystVariantIds: this.uuid,
      settingTab: TAB_PRICING,
      settingField: `${this.uuid}.${fieldName}`,
      oldValue: `${this.currency} ${get(this, fieldName, null)}`,
      newValue: `${this.currency} ${adaptedData[fieldName]}`
    })
    try {
      await this.patch(adaptedData, biEvent)
    } catch (e) {
      set(
        this.errors.fieldErrors,
        constructPricingFieldName(this.uuid, fieldName),
        flattenApiErrors(e.response.data).join(' '),
      )
    }
  }

  @action.bound
  async updateSku (newSku) {
    const fieldName = getSkuFieldName(this.uuid)
    unset(this.errors.fieldErrors, fieldName)
    const biEvent = await this.parent.logProductEdited({
      modalystVariantIds: this.uuid,
      settingTab: TAB_VARIANTS,
      settingField: 'sku',
      oldValue: this.sku,
      newValue: newSku,
    })
    try {
      await this.patch({ sku: newSku }, biEvent)
      return true
    } catch (e) {
      set(this.errors.fieldErrors, fieldName, flattenApiErrors(e.response.data).join(' '))
      return false
    }
  }

  @action.bound
  async patch (data, biEvent) {
    const response = await patchRetailerItemVariant(this.uuid, data, biEvent ? getBiHeadersFromEvent(biEvent) : {})
    Object.assign(this, adaptRetailerItemVariant(response.data))
    return response
  }
}

export default ImportListProductVariantStore
