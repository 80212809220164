import {
  addProductToImportListClicked,
} from '@wix/bi-logger-modalyst/v2'
import axios from 'axios'
import pick from 'lodash/pick'
import { action, computed, observable, reaction } from 'mobx'

import { adaptGetProductDraftsParams, adaptGetProductDraftsResponse } from 'shared/adapters/productDrafts'
import { deleteProductDraft, getProductDrafts, importProductDraft } from 'shared/api/productDrafts'
import { UUID_REGEX_STRING } from 'shared/constants'
import { MARKETPLACE_CODE_PRINT_ON_DEMAND } from 'shared/constants/marketplace'
import { RETAILER_ITEM_DRAFT } from 'shared/constants/retailerItems'
import { BaseListStore } from 'shared/stores'
import { getBiHeadersFromEvent } from 'shared/stores/BiLoggerStore/utils'

import { ProductDraftStore } from 'retailer/stores'

const PRODUCT_DETAILS_PATHNAME_REGEX = new RegExp(
  `\\/marketplace\\/${MARKETPLACE_CODE_PRINT_ON_DEMAND}\\/(?<productId>${UUID_REGEX_STRING})\\/drafts`
)

class ProductDraftListStore extends BaseListStore {
  /** @type {import('../context').RootStore} */
  root

  cancelTokenSource = axios.CancelToken.source()
  pathnameRegex = PRODUCT_DETAILS_PATHNAME_REGEX

  @observable shouldFetchMore

  listParamsGetter = () => pick(this, ['productId'])
  listParamsReaction = params => {
    if (!!this.pathnameMatch && this.items !== undefined) {
      this.fetchFresh()
    }
  }

  /**
   * @param {import('../context').RootStore} root
   * @param {Object} [data] data to initialize with
   */
  constructor (root, data = {}) {
    super(root.routerStore, data)
    this.root = root
    reaction(this.listParamsGetter, this.listParamsReaction)
  }

  @computed get productId () {
    return this.pathnameMatch?.groups.productId
  }

  @computed get hasMore () {
    return this.items === undefined || this.items.length < this.totalCount
  }

  @action.bound setShouldFetchMore (value) {
    this.shouldFetchMore = value
    if (value) this.fetchMore()
  }

  @action.bound fetchMore () {
    if (this.hasMore && !this.isLoading) {
      this.fetch()
    }
  }

  @action.bound cancelFetch () {
    if (this.cancelTokenSource) this.cancelTokenSource.cancel()
    this.cancelTokenSource = axios.CancelToken.source()
  }

  @action.bound fetchFresh () {
    this.cancelFetch()
    this.reset()
    this.fetch()
  }

  @computed get fetchParams () {
    return {
      offset: this.items?.length || 0,
      ...this.listParamsGetter(),
    }
  }

  /**
   * Fetch action overridden to finetune a sequence of actions and
   * implement a more resilient infinite scroll
   *
   * FIXME: improperly handles server error - immediately fires another request instead of backing off
   */
  @action.bound fetch () {
    if (!this.pathnameMatch) return

    this.setIsLoading(true)
    const params = adaptGetProductDraftsParams({
      status: RETAILER_ITEM_DRAFT,
      ...this.fetchParams,
    })
    return getProductDrafts(params, this.cancelTokenSource.token)
      .then(response => {
        const items = this.items || []
        const data = adaptGetProductDraftsResponse(response.data)
        this.setMeta(data.count)
        this.setItems([...items, ...data.results.map((obj, pos) => (
          new ProductDraftStore(this.root, obj, { ...this.biContext, position: 1 + pos + items.length })
        ))])
        this.setIsLoading(false)

        // if "tail" is within view, load next batch
        if (this.shouldFetchMore) this.fetchMore()

        return response
      })
      .finally(() => {
        this.setIsLoading(false)
      })
  }

  @action.bound deleteDraft (draft) {
    return deleteProductDraft(draft.id)
      .then(() => {
        this.fetchFresh()
      })
  }

  /** @param {ProductDraftStore} draft  */
  @action.bound async moveDraftToImportList (draft) {
    const biEvent = await this.root.biLoggerStore.log(addProductToImportListClicked(draft.biEventData))
    await importProductDraft(draft.id, getBiHeadersFromEvent(biEvent))
    await this.fetchFresh()
  }

  /**
   * BI Context for child stores
   * @type {import('../../types').ProductBiContext
   */
  @computed get biContext () {
    return {
      origin: 'draft_list',
      appliedFilters: this.fetchParams,
      appliedSort: this.ordering,
    }
  }
}

export default ProductDraftListStore
