import { action, computed } from 'mobx'

import {
  UserBasicInfoSettingsStore,
  UserDeleteAccountStore,
  UserLoginInfoSettingsStore,
  UserRequestDataStore,
} from 'shared/stores'

class AccountSettingsStore {
  /** @type {import('../root').RootStore} */
  root

  userBasicInfoSettings = new UserBasicInfoSettingsStore(this)
  userLoginInfoSettings = new UserLoginInfoSettingsStore(this)
  userRequestDataStore = new UserRequestDataStore(this)
  userDeleteAccountStore = new UserDeleteAccountStore(this)

  /**
   * @param {import('../root').RootStore} root
   */
  constructor (root) {
    this.root = root
  }

  @action.bound
  fetch () {
    return Promise.all([
      this.userBasicInfoSettings.fetch(),
      this.userLoginInfoSettings.fetch(),
      this.userRequestDataStore.fetch(),
      this.userDeleteAccountStore.fetch(),
    ])
  }

  @computed get isLoaded () {
    return (
      this.userBasicInfoSettings.loading.isLoaded &&
      this.userLoginInfoSettings.loading.isLoaded &&
      this.userRequestDataStore.loading.isLoaded &&
      this.userDeleteAccountStore.loading.isLoaded
    )
  }
}

export default AccountSettingsStore
