import React, { useState } from 'react'

import PropTypes from 'prop-types'

import { Button, Chip } from '@material-ui/core'
import clsx from 'clsx'
import { observer } from 'mobx-react'

import { useTranslation } from 'shared/hooks'

import style from './ChipList.module.scss'

/**
 * A generic Chip list component. It can show only given number of Chips,
 * and optionally allow expanding and collapsing the entire list.
 * @param chips       A list of objects. You must include a unique id, and a label.
 *                    You can add any other attributes, which will be converted to Chips' props.
 * @param maxChips    Maximum number of Chips which can be displayed. If there are more,
 *                    another Chip will be displayed indicating, e.g. `+3 more`.
 * @param expandable  If true, the last `+X more` chip will be clickable.
 *                    It will expand/collapse the entire list.
 * @param onClear     Function that should clear all filters. If provided, a button will be added.
 * @param ChipProps   These props will be applied to all the chips automatically,
 *                    but the will NOT override props provided in `chips` array.
 * @param className   Container class name.
 * @returns {JSX.Element}
 * @constructor
 */
const ChipList = ({ chips, maxChips, expandable, onClear, ChipProps, className, ...props }) => {
  const { t } = useTranslation('common')
  const [isExpanded, setIsExpanded] = useState()

  const chipsToDisplay = (() => {
    if (isExpanded) return chips
    if (maxChips) return chips.slice(0, maxChips)
    return chips
  })()

  return (
    <div className={clsx(style.ChipList, className)} {...props}>
      {chipsToDisplay.map(({ id, label, ...rest }) => {
        return (
          <Chip
            data-testid="chip"
            key={id}
            label={label}
            variant="default"
            color="primary"
            size="small"
            {...ChipProps}
            {...rest}
            className={clsx(style.Chip, ChipProps?.className, rest?.className)}
          />
        )
      })}
      {(maxChips < chips.length) && (
        <Chip
          data-testid="moreChipsWrapped"
          label={
            isExpanded
              ? t('chipList.showLess.label')
              : t('chipList.showMore.label', { moreCount: chips.length - chipsToDisplay.length })
          }
          variant={expandable ? 'outlined' : 'default'}
          color="primary"
          size="small"
          className={style.Chip}
          onClick={expandable ? () => setIsExpanded(!isExpanded) : null}
        />
      )}
      {onClear && (
        <Button
          data-testid="clearAllButton"
          variant="text"
          color="primary"
          onClick={onClear}
          className={style.ClearAllButton}
        >
          {t('chipList.clearAll.label')}
        </Button>
      )}
    </div>
  )
}

ChipList.propTypes = {
  chips: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.any.isRequired,
    label: PropTypes.string.isRequired,
    onClick: PropTypes.func,
    onDelete: PropTypes.func
  })).isRequired,
  maxChips: PropTypes.number,
  expandable: PropTypes.bool,
  onClear: PropTypes.func,
  ChipProps: PropTypes.object,
  className: PropTypes.string,
}

ChipList.defaultProps = {
  chips: [],
  expandable: false,
  ChipProps: {},
  className: '',
}

export default observer(ChipList)
