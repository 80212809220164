import React from 'react'

import PropTypes from 'prop-types'

import { InputAdornment, TextField } from '@material-ui/core'
import { ErrorRounded } from '@material-ui/icons'
import clsx from 'clsx'
import { observer } from 'mobx-react'
import { Controller } from 'react-hook-form'
import NumberFormat from 'react-number-format'

import { TooltipHelper } from 'shared/components/molecules'

import style from './ImportListProductTableField.module.scss'

const sharedTextFieldProps = {
  fullWidth: true,
  size: 'small',
  variant: 'outlined',
}

const textFieldPropTypes = {
  inputRef: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  prefix: PropTypes.string,
  placement: PropTypes.string,
  className: PropTypes.string,
  testId: PropTypes.string,
}

const getInputProps = ({ prefix, error }) => ({
  startAdornment: prefix
    ? (
      <InputAdornment position="start" disableTypography disablePointerEvents>
        {prefix}
      </InputAdornment>
      )
    : null,
  endAdornment: error
    ? (
      <InputAdornment position="end" disableTypography>
        <TooltipHelper content={error} IconComponent={ErrorRounded} className={style.Error} />
      </InputAdornment>
      )
    : null
})

const TextTypeField = observer(
  ({ inputRef, name, value, error, onChange, onBlur, prefix, placement, className, testId, ...props }) => {
    return (
      <TextField
        {...sharedTextFieldProps}
        inputRef={inputRef}
        id={name}
        data-testid={testId}
        name={name}
        value={value}
        error={!!error}
        onChange={e => onChange(e.target.value)}
        onBlur={onBlur}
        className={clsx(style.ImportListProductTableField, className)}
        InputProps={getInputProps({ prefix, error })}
        inputProps={{ className: clsx(style.Input, placement === 'head' && style.HeadInput) }}
        {...props}
      />
    )
  }
)

TextTypeField.propTypes = textFieldPropTypes

const MoneyTypeField = observer(
  ({ inputRef, name, value, error, onChange, onBlur, prefix, placement, className, ...props }) => {
    return (
      <NumberFormat
        {...sharedTextFieldProps}
        inputRef={inputRef}
        id={name}
        name={name}
        value={value}
        thousandSeparator=","
        onValueChange={({ value }) => onChange(value)}
        onBlur={onBlur}
        fixedDecimalScale
        decimalScale={2}
        customInput={TextField}
        error={!!error}
        className={clsx(style.ImportListProductTableField, className)}
        InputProps={getInputProps({ prefix, error })}
        inputProps={{ className: clsx(style.Input, placement === 'head' && style.HeadInput) }}
        {...props}
      />
    )
  }
)

MoneyTypeField.propTypes = textFieldPropTypes

/**
 * A simple wrapper over `<TextField>` to simplify adding inputs into the variants table
 * in the Import List product component.
 */
const ImportListProductTableField = ({
  control, name, defaultValue, placement, useNumberFormat, prefix, error, className, testId, ...props
}) => {
  const TextFieldComponent = useNumberFormat ? MoneyTypeField : TextTypeField

  return (
    <Controller
      control={control}
      key={name}
      name={name}
      defaultValue={defaultValue}
      render={({ ref, ...field }) => {
        return (
          <TextFieldComponent
            testId={testId}
            inputRef={ref}
            error={error}
            className={className}
            prefix={prefix}
            placement={placement}
            {...field}
            {...props}
          />
        )
      }}
    />
  )
}

ImportListProductTableField.propTypes = {
  control: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  defaultValue: PropTypes.string.isRequired,
  placement: PropTypes.oneOf(['default', 'head']),
  useNumberFormat: PropTypes.bool,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  className: PropTypes.string,
  testId: PropTypes.string,
  ...TextField.propTypes,
}

ImportListProductTableField.defaultProps = {
  defaultValue: '',
  placement: 'default',
}

export default observer(ImportListProductTableField)
