export const IMPORT_PENDING = 'pending'
export const IMPORT_STARTED = 'started'
export const IMPORT_COMPLETED = 'completed'
export const IMPORT_FAILED = 'failed'

export const IMPORT_STATUSES = [IMPORT_PENDING, IMPORT_STARTED, IMPORT_COMPLETED, IMPORT_FAILED]
export const IMPORT_STATUSES_IN_PROGRESS = [IMPORT_PENDING, IMPORT_STARTED]
export const IMPORT_STATUSES_ENDED = [IMPORT_COMPLETED, IMPORT_FAILED]

export const IMPORT_PENDING_MESSAGE = 'Waiting for the import to start...'

export const IMPORT_FORMAT_CSV = 0
export const IMPORT_FORMAT_XLSX = 1

export const IMPORT_FORMATS = new Map([
  [IMPORT_FORMAT_CSV, 'CSV'],
  [IMPORT_FORMAT_XLSX, 'XLSX'],
])

export const MIME_TYPE_TO_IMPORT_FORMAT = {
  'text/csv': IMPORT_FORMAT_CSV,
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': IMPORT_FORMAT_XLSX,
}

export const IMPORT_FILE_ACCEPT_EXTENSIONS = '.csv,.xlsx'
export const IMPORT_FILE_MAX_SIZE = 20 * 1024 * 1024 // 20MB
export const IMPORT_ERROR_MESSAGES = {
  'file-invalid-type': 'Invalid file type - we only accept CSV and XLSX files.',
  'file-too-large': 'File is too large - only files up to 20MB are allowed.',
}

export const ITEM_IMPORT_PENDING = 'pending'
export const ITEM_IMPORT_CREATED = 'created'
export const ITEM_IMPORT_UPDATED = 'updated'
export const ITEM_IMPORT_UNCHANGED = 'unchanged'
export const ITEM_IMPORT_FAILED = 'failed'

export const ITEM_IMPORT_STATUSES = [
  ITEM_IMPORT_PENDING,
  ITEM_IMPORT_CREATED,
  ITEM_IMPORT_UPDATED,
  ITEM_IMPORT_UNCHANGED,
  ITEM_IMPORT_FAILED,
]

export const EXPORT_FORMAT_CSV = 'csv'
export const EXPORT_FORMAT_XLSX = 'xlsx'
