import { action, observable } from 'mobx'

import { disableRetailerItemImage, enableRetailerItemImage } from 'shared/api/retailerItems'
import { getBiHeadersFromEvent } from 'shared/stores/BiLoggerStore/utils'
import ImageStore from 'shared/stores/ImageStore'

class ImportListProductImageStore extends ImageStore {
  @observable error
  @observable parent
  @observable isMain
  @observable selected

  constructor (parent, data) {
    super(data)
    this.parent = parent
  }

  @action.bound
  async setSelected (value, biEvent) {
    const action = value
      ? enableRetailerItemImage(this.parent.uuid, { uuid: this.id }, biEvent ? getBiHeadersFromEvent(biEvent) : {})
      : disableRetailerItemImage(this.parent.uuid, this.id, biEvent ? getBiHeadersFromEvent(biEvent) : {})

    try {
      await action
      this.selected = !!value
      return true
    } catch (e) {
      if (e.response.status === 404) {
        // 404 means that we tried to disable image which is already disabled
        this.selected = false
        return true
      }
      this.error = e.response.data
      return false
    }
  }

  @action.bound
  async setMain () {
    return await this.parent.setMainImage(this.id)
  }
}

export default ImportListProductImageStore
