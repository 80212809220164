import React from 'react'

import PropTypes from 'prop-types'

import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import { observer } from 'mobx-react'
import { Controller, useForm } from 'react-hook-form'

import { CountrySelect, Alert } from 'shared/components/molecules'
import { DataModal } from 'shared/components/organisms'
import { SHIPPING_ADDRESS_VALIDATION_RELAXED, SHIPPING_ADDRESS_VALIDATION_STRICT } from 'shared/constants/orders'
import { getFieldErrorMessage, submitForm, useRemoteErrors } from 'shared/utils/forms'

import style from './OrderShippingAddressModal.module.scss'

const VALIDATION_SCHEMES = {
  [SHIPPING_ADDRESS_VALIDATION_RELAXED]: {
    country: { required: 'Please select a country' },
  },
  [SHIPPING_ADDRESS_VALIDATION_STRICT]: {
    name: { required: true },
    country: { required: 'Please select a country' },
    address: { required: 'Please enter a valid street address' },
    state: { required: 'Please enter the name of a state or province' },
    zipCode: { required: 'Please enter a valid code' },
    city: { required: 'Please enter the name of the city' },
    phone: { required: 'Please enter the phone number' },
  }
}

/**
 * Form for editing shipping address on order
 */
const OrderShippingAddressForm = React.forwardRef((props, ref) => {
  const { disableFields, onSubmit, order, remoteErrors } = props
  const { control, errors, handleSubmit, register, setError } = useForm({ defaultValues: order.address })
  const scheme = VALIDATION_SCHEMES[order.shippingAddressValidationMode || SHIPPING_ADDRESS_VALIDATION_RELAXED]

  const sharedProps = {
    variant: 'outlined', // TODO: consider overriding in muiTheme
    fullWidth: true,
    disabled: disableFields,
  }

  const { nonFieldErrors } = useRemoteErrors(remoteErrors, setError)

  const getPhoneHelperText = () => {
    const error = getFieldErrorMessage(errors, 'phone')
    if (error) return error
    if (order.hasAlibabaOrders || order.hasAliexpressOrders) {
      return (
        `It is required to provide a phone number for this order.
        To ensure that a supplier cannot contact your customer, we suggest that you add your phone number to the order.`
      )
    }
    return null
  }

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className={style.OrderShippingAddressForm}
      ref={ref}
    >
      {nonFieldErrors && (
        <Alert severity="error" className={style.NonFieldError}>
          {nonFieldErrors.join(' ')}
        </Alert>
      )}

      <Grid container spacing={3}>
        <Grid item xs={12}>
          <TextField
            {...sharedProps}
            data-testid="companyName"
            label="Company name"
            name="companyName"
            inputRef={register(scheme.companyName)}
            required={!!scheme.companyName?.required}
            error={!!errors.companyName}
            helperText={getFieldErrorMessage(errors, 'companyName')}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            {...sharedProps}
            data-testid="name"
            label="Name"
            name="name"
            inputRef={register(scheme.name)}
            required={!!scheme.name?.required}
            error={!!errors.name}
            helperText={getFieldErrorMessage(errors, 'name')}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            {...sharedProps}
            data-testid="address"
            label="Street"
            name="address"
            inputRef={register(scheme.address)}
            required={!!scheme.address?.required}
            error={!!errors.address}
            helperText={getFieldErrorMessage(errors, 'address')}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            {...sharedProps}
            data-testid="city"
            label="City"
            name="city"
            inputRef={register(scheme.city)}
            required={!!scheme.city?.required}
            error={!!errors.city}
            helperText={getFieldErrorMessage(errors, 'city')}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <TextField
            {...sharedProps}
            data-testid="state"
            label="State / Province"
            name="state"
            inputRef={register(scheme.state)}
            required={!!scheme.state?.required}
            error={!!errors.state}
            helperText={getFieldErrorMessage(errors, 'state')}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <TextField
            {...sharedProps}
            data-testid="zipCode"
            label="ZIP Code"
            name="zipCode"
            inputRef={register(scheme.zipCode)}
            required={!!scheme.zipCode?.required}
            error={!!errors.zipCode}
            helperText={getFieldErrorMessage(errors, 'zipCode')}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Controller
            {...sharedProps}
            data-testid="country"
            name="country"
            control={control}
            label="Country"
            rules={scheme.country}
            required={!!scheme.country?.required}
            error={!!errors.country}
            helperText={getFieldErrorMessage(errors, 'country')}
            as={<CountrySelect />}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            {...sharedProps}
            data-testid="phone"
            label="Phone number"
            name="phone"
            inputRef={register(scheme.phone)}
            required={!!scheme.phone?.required}
            error={!!errors.phone}
            helperText={getPhoneHelperText()}
          />
        </Grid>
      </Grid>
      {/* React Hook Form does not submit the form on Enter if there is no submit input present */}
      <input data-testid="submit" type="submit" className={style.SubmitInput} />
    </form>
  )
})

OrderShippingAddressForm.propTypes = {
  disableFields: PropTypes.bool,
  onSubmit: PropTypes.func,
  order: PropTypes.object,
  remoteErrors: PropTypes.object,
}

OrderShippingAddressForm.defaultProps = {
  disableFields: false,
  order: { address: {} },
}

OrderShippingAddressForm.displayName = 'OrderShippingAddressForm'

const ObserverOrderShippingAddressForm = observer(OrderShippingAddressForm)

/**
 * OrderShippingAddressForm wrapped in modal and observing store
 */
const OrderShippingAddressModal = observer(props => {
  const { store, onExited } = props
  const {
    order,
    updateRemote,
    updateInProgress,
    updateDone,
    remoteErrors,
  } = store

  const formEl = React.useRef(null)
  const handleSubmitClick = () => submitForm(formEl)

  return (
    <DataModal
      title="Edit shipping destination"
      showSpinner={updateInProgress}
      hide={updateDone}
      onExited={onExited}
      renderBody={({ showSpinner }) => (
        <ObserverOrderShippingAddressForm
          ref={formEl}
          order={order}
          remoteErrors={remoteErrors}
          disableFields={showSpinner}
          onSubmit={data => updateRemote(data)}
        />
      )}
      renderActions={({ dismissModal, showSpinner }) => (
        <>
          <Button onClick={dismissModal} color="primary" size="large" disabled={showSpinner}>Cancel</Button>
          <Button
            onClick={handleSubmitClick} color="primary" variant="contained" size="large" disabled={showSpinner}
            data-testid="orderShippingAddressModal-submitButton"
          >
            Update
          </Button>
        </>
      )}
    />
  )
})

OrderShippingAddressModal.propTypes = {
  store: PropTypes.shape({
    order: PropTypes.object.isRequired,
    updateRemote: PropTypes.func.isRequired,
    updateInProgress: PropTypes.bool,
    updateDone: PropTypes.bool,
    remoteErrors: PropTypes.object,
  }).isRequired,
  onExited: PropTypes.func,
}

export default OrderShippingAddressModal
export {
  OrderShippingAddressForm,
}
