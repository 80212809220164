import React, { useEffect } from 'react'

import PropTypes from 'prop-types'

import { Button, Container, Link, Typography } from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'
import { observer } from 'mobx-react'
import { useSnackbar } from 'notistack'
import { Helmet } from 'react-helmet'
import { Link as RouterLink } from 'react-router-dom'

import { ScrollResetter } from 'shared/components/atoms'
import { Alert } from 'shared/components/molecules'
import {
  ACTION_AUTO_PRICE_ON,
  ACTION_AUTO_PRICE_OFF,
  ACTION_MOVE_TO_IMPORT_LIST,
  ACTION_REMOVE_FROM_STORE,
  PRODUCT_LIST_ORDERING_RECENTLY_ADDED,
  productListOrderingUserFriendly,
} from 'shared/constants/retailerItems'
import { useTranslation } from 'shared/hooks'

import { PageHeader } from 'retailer/components/molecules'
import {
  ListFiltersBar,
  ManuallyOrderItemModal,
  SyncedProductsList,
  SyncListBatchActionModal,
  SyncListCsvExportModal, SyncListFilters,
  UniversalNotifications,
  EmptyListSplash, DestructiveActionConfirmationModal, ItemCustomizerModal,
} from 'retailer/components/organisms'
import { SidebarLeftTemplate } from 'retailer/components/templates'
import { useStores } from 'retailer/stores/hooks'

import style from './SyncListPage.module.scss'

const Main = observer(({ syncListPageStore }) => {
  const { t } = useTranslation('syncedProducts')
  const { setFilters, search, ordering, inventoryTypes, showOutOfStock, status } = syncListPageStore

  if (syncListPageStore.counters.all === 0) {
    return (
      <EmptyListSplash
        heading={t('main.emptyState.title')}
        content={
          <Typography variant="body1">
            {t('main.emptyState.text')}
          </Typography>
        }
        actions={
          <Button
            component={RouterLink} to="/my-products/import-list"
            variant="outlined"
            color="primary"
            size="large"
          >
            {t('main.emptyState.cta')}
          </Button>
        }
      />
    )
  }

  return (
    <div className={style.MainContainer}>
      <ListFiltersBar
        className={style.FiltersBar}
        label={
          t('main.productNumber.label', {
            numberOfSyncedProducts: syncListPageStore.counters.all
          })
        }
        filtersAsPanel={{
          handleOpen: () => syncListPageStore.setFiltersPanelOpen(true),
          handleClose: () => syncListPageStore.setFiltersPanelOpen(false),
          panel: (
            <SyncListFilters
              open={syncListPageStore.filtersPanelOpen}
              onSubmit={data => {
                setFilters({ search, ordering, ...data })
              }}
              onClose={() => syncListPageStore.setFiltersPanelOpen(false)}
              filters={{
                inventoryTypes: syncListPageStore.inventoryTypes,
                status: syncListPageStore.status,
                showOutOfStock: syncListPageStore.showOutOfStock
              }}
            />
          )
        }}
        searchFilter={{
          setFilter: value => setFilters({ search: value, ordering, inventoryTypes, showOutOfStock, status }),
          clearFilter: () => setFilters({ search: undefined, ordering, inventoryTypes, showOutOfStock, status }),
          activeValue: search || ''
        }}
        orderingFilter={{
          setFilter: value => setFilters({ search, ordering: value, inventoryTypes, showOutOfStock, status }),
          activeValue: ordering || PRODUCT_LIST_ORDERING_RECENTLY_ADDED,
          values: productListOrderingUserFriendly,
        }}
        activeFilters={syncListPageStore.activeFilters}
        onClearAll={() => syncListPageStore.setFilters({ ordering })}
        selection={{
          active: syncListPageStore.selectionActive,
          count: syncListPageStore.selectedCount,
          actions: [
            {
              name: t('table.actions.unsync.cta'),
              handler: () => syncListPageStore.batchAction.startAction(ACTION_MOVE_TO_IMPORT_LIST)
            },
            {
              name: t('table.actions.remove.cta'),
              handler: () => syncListPageStore.batchAction.startAction(ACTION_REMOVE_FROM_STORE)
            },
            {
              name: t('table.actions.autoOn.cta'),
              handler: () => syncListPageStore.batchAction.startAction(ACTION_AUTO_PRICE_ON)
            },
            {
              name: t('table.actions.autoOff.cta'),
              handler: () => syncListPageStore.batchAction.startAction(ACTION_AUTO_PRICE_OFF)
            },
            {
              name: t('table.actions.export.cta'),
              handler: () => syncListPageStore.csvExport.setOpen(true)
            }
          ]
        }}
        handleOpenFilters={() => syncListPageStore.setFiltersPanelOpen(true)}
      />
      <SyncedProductsList syncListPageStore={syncListPageStore} />
    </div>
  )
})

Main.propTypes = {
  syncListPageStore: PropTypes.shape({
    isLoading: PropTypes.bool,
    items: PropTypes.arrayOf(PropTypes.object),
    filtersPanelOpen: PropTypes.bool,
    setFiltersPanelOpen: PropTypes.func.isRequired,
    selectionActive: PropTypes.bool,
    selectedCount: PropTypes.number.isRequired,
    counters: PropTypes.shape({
      all: PropTypes.number.isRequired
    }).isRequired,
    batchAction: PropTypes.object.isRequired, // TODO,
    csvExport: PropTypes.object.isRequired, // TODO,
    setFilters: PropTypes.func.isRequired,
    search: PropTypes.string,
    ordering: PropTypes.string,
    inventoryTypes: PropTypes.arrayOf(PropTypes.string),
    showOutOfStock: PropTypes.bool,
    status: PropTypes.arrayOf(PropTypes.string),
    activeFilters: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      onDelete: PropTypes.func
    })),
    batchEnableAutoPrice: PropTypes.func.isRequired,
    batchDisableAutoPrice: PropTypes.func.isRequired,
  }).isRequired
}

// XXX: duplicate of the new "not connected" Alert as per SH-49 (see Alerts component)
const NoStoreConnectedAlert = observer(() => {
  const { t } = useTranslation('syncedProducts')

  return (
    <Alert
      variant="filled"
      severity="warning"
      icon={false}
      className={style.NotConnectedAlert}
      action={
        <Button
          component={RouterLink}
          to="/my-stores"
          size="small"
          variant="outlined"
          color="inherit"
        >
          {t('main.storeNotConnectedExportCSV.button')}
        </Button>
      }
    >
      {t('main.storeNotConnected.error')}
    </Alert>
  )
})

const SyncListPage = () => {
  const { t } = useTranslation('syncedProducts')
  const { syncListPageStore, manualOrderStore } = useStores()
  const { enqueueSnackbar } = useSnackbar()

  useEffect(() => {
    syncListPageStore.fetch()
    return () => {
      syncListPageStore.reset()
      manualOrderStore.reset()
    }
  }, [])

  const skeleton = (
    <div className={style.ListSkeleton}>
      <Skeleton variant="rect" width="100%" height={60} />
      <Skeleton variant="rect" width="100%" height={60} />
      <Skeleton variant="rect" width="100%" height="60vh" />
      <Skeleton variant="rect" className={style.PaginationSkeleton} />
    </div>
  )

  return (
    <SidebarLeftTemplate
      notifications={<UniversalNotifications />}
      main={
        <Container maxWidth="xl" disableGutters>
          <Helmet>
            <title>{t('main.title')}</title>
          </Helmet>
          <ScrollResetter />
          <PageHeader
            mb={3}
            title={t('main.title')}
            subtitle={t('main.subtitle', {
              syncListLink: (
                <Link
                  key="main.link"
                  target="_blank"
                  rel="noopener noreferrer"
                  href={t('main.URL')}
                >
                  {t('main.link')}
                </Link>
              )
            })}
          />
          {!syncListPageStore.hasStoreConnected && <NoStoreConnectedAlert />}
          {syncListPageStore.initialized ? <Main syncListPageStore={syncListPageStore} /> : skeleton}
          <ItemCustomizerModal />
          {syncListPageStore.csvExport.open && (
            <SyncListCsvExportModal store={syncListPageStore.csvExport} />
          )}
          {syncListPageStore.batchAction.initialized && (
            <SyncListBatchActionModal syncListBatchActionStore={syncListPageStore.batchAction} />
          )}
          {manualOrderStore.open && (
            <ManuallyOrderItemModal manualOrderStore={manualOrderStore} />
          )}
          {syncListPageStore.productRemove.isOpen && (
            <DestructiveActionConfirmationModal
              title="Remove from store"
              testId="syncListPage-RemoveFromStoreModal"
              onConfirm={async () => {
                const removed = await syncListPageStore.productRemove.remove()
                if (!removed) return
                enqueueSnackbar(
                  `${syncListPageStore.productRemove.product?.name || 'The product'} has been removed`,
                  { variant: 'success' }
                )
                syncListPageStore.productRemove.reset()
              }}
              confirmLabel="Remove from store"
              onCancel={() => syncListPageStore.productRemove.reset()}
              inProgress={syncListPageStore.productRemove.inProgress}
            >
              {!!syncListPageStore.productRemove.error && (
                <Alert
                  className={style.RemoveAlert}
                  severity="error"
                  title="Error when removing the product"
                >
                  <Typography variant="body2">
                    {syncListPageStore.productRemove.error}
                  </Typography>
                </Alert>
              )}
              <Typography paragraph>
                Are you sure you want to remove{' '}
                <strong>{syncListPageStore.productRemove.product?.name || 'the product'}</strong> from your store?
              </Typography>
              <Typography>
                This product will be moved to your Archive List and can be added back to your Lists at a later time.
              </Typography>
            </DestructiveActionConfirmationModal>
          )}
        </Container>
      }
    />
  )
}

export default observer(SyncListPage)
