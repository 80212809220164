import { action, observable } from 'mobx'

import {
  adaptChangePasswordErrors,
  adaptChangePasswordForm,
} from 'shared/adapters/settings'
import { changePassword, getUserName } from 'shared/api/settings'
import { processApiError } from 'shared/api/utils'
import { LoadableStore } from 'shared/stores'

class UserLoginInfoSettingsStore {
  parent

  @observable loading = new LoadableStore()
  @observable username = ''
  @observable isSaving
  @observable errors

  constructor (parent) {
    this.parent = parent
  }

  @action.bound
  fetch () {
    this.loading.setLoading()
    return getUserName().then(response => {
      Object.assign(this, response.data)
      this.loading.setLoaded()
    })
  }

  @action.bound
  changePassword (data) {
    this.isSaving = true
    return changePassword(adaptChangePasswordForm(data))
      .then(() => {
        window.location.replace('/logout')
      })
      .catch(error => {
        this.errors = processApiError(error, adaptChangePasswordErrors)
        throw error
      })
      .finally(() => {
        this.isSaving = false
      })
  }
}

export default UserLoginInfoSettingsStore
