import { action, computed, observable } from 'mobx'

import { getUserProfile } from 'shared/api/profiles'
import { CATEGORIES_PRINT_ON_DEMAND_ROOT_NAME, CATEGORIES_ROOT_NAME } from 'shared/constants/categories'

import { adaptGetUserProfileResponse } from 'supplier/adapters/profiles'

class UserProfileStore {
  /** @type {import('../root').RootStore} */
  root

  @observable context = {}
  @observable storeAuthorization
  @observable isShippingSetUp
  @observable isApproved
  @observable hasPendingApprovalRequest
  @observable shippingFrom
  @observable productsMadeIn
  @observable inventoryType

  /**
   * @param {import('../root').RootStore} root
   */
  constructor (root, data = {}) {
    this.root = root
    this.update(data)
  }

  @action.bound
  initialize () {
    return this.fetch()
  }

  @action.bound
  update (data = {}) {
    Object.assign(this, data)
  }

  @action.bound
  fetch () {
    return getUserProfile().then(response => {
      const data = adaptGetUserProfileResponse(response.data)
      this.update(data)
      return data
    })
  }

  @computed get rootCategory () {
    switch (this.inventoryType) {
      case 'print_on_demand': return CATEGORIES_PRINT_ON_DEMAND_ROOT_NAME
      default: return CATEGORIES_ROOT_NAME
    }
  }

  @computed get categoriesTree () {
    return this.root.appConfigStore.categoryRootsMap.get(this.rootCategory)
  }
}

export default UserProfileStore
