import { keysToCamelCase, keysToSnakeCase } from 'shared/utils/api'

const adaptRetailer = data => keysToCamelCase(data)

const adaptGetRetailersResponse = ({ results, ...meta }) => ({
  results: results.map(row => adaptRetailer(row)),
  ...keysToCamelCase(meta)
})

const adaptPostSetAcceptedRequest = data => keysToSnakeCase(data)

export {
  adaptGetRetailersResponse,
  adaptPostSetAcceptedRequest,
}
