import { api } from 'shared/api/common'

const baseUrl = '/api/v3/supplier/warehouses'

function getWarehouse (id) {
  return api.get(`${baseUrl}/${id}/`)
}

function getWarehouses (params = {}) {
  return api.get(`${baseUrl}/`, { params })
}

function putWarehouse (id, data) {
  return api.put(`${baseUrl}/${id}/`, data)
}

function postWarehouse (data) {
  return api.post(`${baseUrl}/`, data)
}

function postWarehouseSetDefault (id) {
  return api.post(`${baseUrl}/${id}/set_default/`)
}

function deleteWarehouse (id) {
  return api.delete(`${baseUrl}/${id}/`)
}

export {
  getWarehouse,
  getWarehouses,
  putWarehouse,
  postWarehouse,
  postWarehouseSetDefault,
  deleteWarehouse
}
