import { api } from './common'

const baseUrl = '/api/v3'

// all calls use withCredentials to enable setting of session cookies by the response

const login = (data, headers = {}) => {
  return api.post(`${baseUrl}/accounts/login/`, data, { withCredentials: true, headers })
}

const logout = (data, headers = {}) => {
  return api.post(`${baseUrl}/accounts/logout/`, data, { withCredentials: true, headers })
}

const resetPassword = data => {
  return api.post(`${baseUrl}/accounts/reset_password/`, data, { withCredentials: true })
}

const register = data => {
  return api.post(`${baseUrl}/accounts/register/`, data, { withCredentials: true })
}

export {
  login,
  logout,
  resetPassword,
  register,
}
