import React, { useEffect, useMemo } from 'react'

import PropTypes from 'prop-types'

import { observer } from 'mobx-react'
import { Redirect } from 'react-router'

import { FullPageSpinner } from 'shared/components/atoms'

import { NotFound } from 'retailer/pages'
import { useStores } from 'retailer/stores/hooks'

const SelectStoreRedirect = ({ match }) => {
  const { userProfileStore } = useStores()
  const { ready, currentStore, stores, onBeforeStoreSwitch } = userProfileStore
  const { params: { storeId, path } } = match

  const targetStore = useMemo(() => (
    currentStore?.uuid !== storeId &&
    stores?.find(store => store.uuid === storeId)
  ), [storeId, stores, currentStore])

  useEffect(() => {
    if (targetStore) {
      onBeforeStoreSwitch(targetStore.id)
      window.location.assign('/retailer/' + (path || '')) // full reload
    }
  }, [targetStore])

  if (!ready || targetStore) {
    return <FullPageSpinner />
  } else if (currentStore.uuid === storeId) {
    return <Redirect to={`/${path || ''}`} />
  } else {
    return <NotFound />
  }
}

SelectStoreRedirect.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      storeId: PropTypes.string.isRequired,
      path: PropTypes.string,
    }).isRequired,
  }).isRequired,
}

export default observer(SelectStoreRedirect)
