import { action, computed, observable } from 'mobx'

import {
  adaptAuthorizationCheckRequest,
  adaptAuthorizationCheckResponse,
  adaptAuthorizationCheckErrors,
  adaptCreateRetailerAuthorizationRequest,
  adaptCreateRetailerAuthorizationErrors,
  adaptCreateRetailerAuthorizationResponse,
} from 'shared/adapters/integrations'
import { checkRetailerAuthorization, createRetailerAuthorization } from 'shared/api/integrations'
import { processApiError } from 'shared/api/utils'

/**
 * Contains store integration logic:
 * - check for whether the user can install the store
 * - confirmation of authorization
 */
class StoreIntegrationStore {
  @observable isAuthenticated
  @observable username

  /** The referral / type of flow for the installation */
  referral

  /** The integration type to connect with */
  @observable integrationType
  /** The type of account to create the integration for (Retailer/Supplier) */
  @observable integrationProfileType
  /** The store name/id to connect with */
  @observable storeName
  /** The UUID of a business to connect the store to */
  @observable business
  /** Authorization data received in the 2nd step of OAuth */
  @observable authorizationData
  /** The results of the authorization check */
  @observable authorizationCheckResults
  /** Where the user is installing the integration from (Modalyst/integration app repo) */
  @observable authorizationOrigin

  @observable isAuthorizationCheckInProgress
  @observable authorizationCheckRemoteErrors

  @observable confirmAuthorizationRemoteErrors
  @observable isConfirmAuthorizationInProgress
  @observable isConfirmAuthorizationSuccessful

  constructor (options) {
    Object.assign(this, options)
  }

  @computed get authorizationPossible () {
    const results = this.authorizationCheckResults
    if (!this.isAuthenticated || !results) return undefined
    return (
      results.canInstallIntegration === true &&
      results.hasCurrentAuthorization === false &&
      results.hasAuthorizationInRemoval === false &&
      results.isAuthorizedOnAnotherBusiness === false &&
      (
        !results.installationExistsForUser ||
        results.installationExistsForUser === this.username
      )
    )
  }

  @computed get authorizationPossibleOnNewBusiness () {
    const results = this.authorizationCheckResults
    if (!this.isAuthenticated || !results) return undefined
    return (
      results.canInstallIntegration === true &&
      !results.installationExistsForUser &&
      !results.isAuthorizedOnAnotherBusiness
    )
  }

  @action.bound
  reset () {
    this.authorizationCheckResults = undefined
    this.isAuthenticated = undefined
    this.username = undefined
    this.business = undefined

    this.authorizationCheckRemoteErrors = undefined
    this.confirmAuthorizationRemoteErrors = undefined
    this.isConfirmAuthorizationSuccessful = undefined
  }

  @action.bound
  setStoreName (value) {
    this.storeName = value
  }

  // authorization check setters

  @action.bound
  setIsAuthorizationCheckInProgress (value) {
    this.isAuthorizationCheckInProgress = value
  }

  @action.bound
  setAuthorizationCheckRemoteErrors (data) {
    this.authorizationCheckRemoteErrors = data
  }

  @action.bound
  setIsAuthenticated (value) {
    this.isAuthenticated = value
  }

  @action.bound
  setUsername (value) {
    this.username = value
  }

  @action.bound
  setBusiness (value) {
    this.business = value
  }

  @action.bound
  setAuthorizationCheckResults (data) {
    this.authorizationCheckResults = data
  }

  // confirm authorization setters

  @action.bound
  setIsConfirmAuthorizationInProgress (value) {
    this.isConfirmAuthorizationInProgress = value
  }

  @action.bound
  setConfirmAuthorizationRemoteErrors (data) {
    this.confirmAuthorizationRemoteErrors = data
  }

  @action.bound
  setIsConfirmAuthorizationSuccessful (value) {
    this.isConfirmAuthorizationSuccessful = value
  }

  // actions

  /**
   * Check if an authorization can be created for the current user
   * Also checks for lack of authentication as a side effect
   * @returns Promise
   */
  @action.bound
  checkAuthorization () {
    if (this.isAuthorizationCheckInProgress) return

    this.setAuthorizationCheckResults(undefined)
    this.setAuthorizationCheckRemoteErrors(undefined)
    this.setIsAuthorizationCheckInProgress(true)

    const payload = {
      integrationType: this.integrationType,
      integrationProfileType: this.integrationProfileType,
      storeName: this.storeName,
      business: this.business,
      referral: this.referral,
    }

    return checkRetailerAuthorization(adaptAuthorizationCheckRequest(payload))
      .then(response => {
        const data = adaptAuthorizationCheckResponse(response.data)
        const { username, permissionsRequestUrl, ...checkResults } = data

        // order matters
        this.setUsername(username)
        this.setAuthorizationCheckResults(checkResults)
        this.setIsAuthenticated(true)

        return data
      })
      .catch(error => {
        // note: this block handles check REQUEST errors (integration type, store name, connectivity),
        // not data sucessfuly retuned from check (even if it signifies problems with authorization)

        const signedOut = error.response && error.response.status === 403

        // no auth is not really a request error, so exit without setting remote errors
        if (signedOut) {
          this.setIsAuthenticated(false)
          throw error
        }
        // if this is NOT a 403 (but still an error response), it means the user is signed in
        if (error.response) {
          this.setIsAuthenticated(true)
        }

        this.setAuthorizationCheckRemoteErrors(processApiError(error, adaptAuthorizationCheckErrors))

        throw error
      })
      .finally(() => {
        this.setIsAuthorizationCheckInProgress(false)
      })
  }

  @action.bound
  confirmAuthorization (authorizationData, options = {}) {
    if (this.isConfirmAuthorizationInProgress) return

    const { createBusiness = false } = options

    this.setIsConfirmAuthorizationSuccessful(undefined)
    this.setConfirmAuthorizationRemoteErrors(undefined)
    this.setIsConfirmAuthorizationInProgress(true)

    const payload = {
      integrationType: this.integrationType,
      integrationProfileType: this.integrationProfileType,
      origin: this.authorizationOrigin || undefined,
      storeName: this.storeName,
      business: this.business,
      referral: this.referral,
      authorizationData,
      createBusiness,
    }

    return createRetailerAuthorization(adaptCreateRetailerAuthorizationRequest(payload))
      .then(response => {
        const data = adaptCreateRetailerAuthorizationResponse(response.data)
        this.setIsConfirmAuthorizationSuccessful(true)

        return data
      })
      .catch(error => {
        this.setIsConfirmAuthorizationSuccessful(false)
        this.setConfirmAuthorizationRemoteErrors(processApiError(error, adaptCreateRetailerAuthorizationErrors))

        throw error
      })
      .finally(() => {
        this.setIsConfirmAuthorizationInProgress(false)
      })
  }
}

export default StoreIntegrationStore
