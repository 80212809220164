import { convertFromHTML, ContentState, convertToRaw } from 'draft-js'
import draftToHtml from 'draftjs-to-html'

const htmlToContentState = html => {
  const content = convertFromHTML(html)
  return ContentState.createFromBlockArray(content.contentBlocks, content.entityMap)
}

const contentStateToHtml = contentState => {
  const rawContentState = convertToRaw(contentState)
  return draftToHtml(rawContentState)
}

export {
  htmlToContentState,
  contentStateToHtml,
}
