import React, { useEffect } from 'react'

import { Box, Container, Typography } from '@material-ui/core'
import { observer } from 'mobx-react'
import { Helmet } from 'react-helmet'

import { ScrollResetter } from 'shared/components/atoms'

import {
  PublicInfoForm,
  StoreLocationForm,
  StoreSocialMediaForm,
} from 'retailer/components/organisms'
import { useStores } from 'retailer/stores/hooks'

import style from './StoreProfilePage.module.scss'

const StoreProfilePage = () => {
  const { settingsStore: { profileSettings, locationSettings, socialMediaSettings } } = useStores()

  useEffect(() => {
    profileSettings.fetch()
    locationSettings.fetch()
    socialMediaSettings.fetch()
  }, [])

  return (
    <div className={style.StoreProfilePage}>
      <Helmet>
        <title>Store Profile</title>
      </Helmet>
      <ScrollResetter />
      <Container maxWidth="xl">
        <Box className={style.PageHeader}>
          <Typography variant="h2" gutterBottom>
            Store Profile
          </Typography>
        </Box>
        {profileSettings.loading.isLoaded && <PublicInfoForm store={profileSettings} />}
        {locationSettings.loading.isLoaded && <StoreLocationForm store={locationSettings} />}
        {socialMediaSettings.loading.isLoaded && <StoreSocialMediaForm store={socialMediaSettings} />}
      </Container>
    </div>
  )
}

export default observer(StoreProfilePage)
