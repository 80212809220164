import React from 'react'

import PropTypes from 'prop-types'

import { Skeleton } from '@material-ui/lab'
import times from 'lodash/times'
import { observer } from 'mobx-react'

import { Alert } from 'shared/components/molecules'

import { Pagination } from 'retailer/components/molecules'
import { ArchiveListItem } from 'retailer/components/organisms'
import { ArchiveListPageStore } from 'retailer/stores'

import style from './ArchivedProductsList.module.scss'

const ArchivedProductsList = ({ store }) => {
  const { items, pagination, changePage } = store
  const noResults = !items?.length

  const paginationElement = (
    <Pagination
      itemName={store.nonRestorableFilter.active ? 'not restorable product' : 'restorable product'}
      totalItems={pagination.totalCount}
      count={pagination.totalPages}
      page={pagination.currentPage}
      onChange={(_, page) => changePage(page)}
      className={style.Pagination}
      disabled={store.fetchInProgress}
      asButton
    />
  )

  return (
    <div className={style.ArchivedProductsList}>
      {!noResults && paginationElement}
      <div className={style.List}>
        {(noResults && !store.fetchInProgress) && (
          <Alert title="No results" severity="info" testId="archiveList-noResultsAlert">
            Archive List is empty or your filters did not match any items.
          </Alert>
        )}
        {store.fetchInProgress && (
          <>
            {times(store.pagination.pageSize, (idx) => {
              return (
                <Skeleton key={idx} variant="rect" width="100%" className={style.ListItemSkeleton} />
              )
            })}
          </>
        )}
        {!store.fetchInProgress && items.map(item => {
          return (
            <ArchiveListItem key={item.id} className={style.ListItem} store={item} />
          )
        })}
      </div>
      {!noResults && paginationElement}
    </div>
  )
}

ArchivedProductsList.propTypes = {
  store: PropTypes.instanceOf(ArchiveListPageStore).isRequired
}

export default observer(ArchivedProductsList)
