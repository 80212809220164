import { computed, observable } from 'mobx'

import { getAppConfig } from 'shared/api/profiles'
import { AppConfigStore as BaseAppConfigStore } from 'shared/stores'

class AppConfigStore extends BaseAppConfigStore {
  @observable.shallow activeBrands
  @observable aliexpressOauthAuthorizeUrl
  @observable alibabaAppAuthorizeUrl
  @observable alibabaOauthAuthorizeUrl
  @observable alibabaDropshippingPageRedirectUrl
  @observable recurlyPublicToken
  @observable biDebug

  doFetch () {
    return getAppConfig()
  }

  @computed get activeBrandsUuidMap () {
    return new Map([...this.activeBrands].map(brand => [brand.uuid, brand]))
  }

  @computed get inventoryTypesMap () {
    return [...this.activeBrands].reduce(
      (inventories, brand) => {
        const { inventoryType } = brand
        if (!(inventories.has(inventoryType))) inventories.set(inventoryType, [])
        inventories.get(inventoryType).push(brand)
        return inventories
      }, new Map()
    )
  }
}

export default AppConfigStore
