import { action, observable } from 'mobx'

import {
  adaptMarkFulfilledErrors,
  adaptMarkFulfilledReverse,
  adaptPurchaseOrder,
} from 'shared/adapters/orders'
import {
  cancelOrder,
  markOrdered,
  markOrderFulfilled,
  markOrderPaid,
} from 'shared/api/orders'
import { processApiError } from 'shared/api/utils'

class OrderModalActionState {
  @observable order
  @observable inProgress = false
  @observable done = false
  @observable error
  @observable remoteErrors

  constructor (order) {
    this.order = order
  }

  @action.bound
  setDone (value) {
    this.done = value
  }

  @action.bound
  setError (value) {
    this.error = value
  }

  @action.bound
  setRemoteErrors (data) {
    this.remoteErrors = data
  }

  @action.bound
  setInProgress (value) {
    this.inProgress = value
  }

  @action.bound
  markOrdered (externalId) {
    this.setInProgress(true)
    return markOrdered(this.order.uuid, externalId)
      .then(({ data }) => {
        this.order.update(adaptPurchaseOrder(data))
        return this.order.retailerOrder ? this.order.retailerOrder.refreshFromRemote() : true
      })
      .then(() => this.setDone(true))
      .catch(error => this.setError(error.response.data.error))
      .finally(() => this.setInProgress(false))
  }

  @action.bound
  markPaid () {
    this.setInProgress(true)
    return markOrderPaid(this.order.uuid)
      .then(({ data }) => {
        this.order.update(adaptPurchaseOrder(data))
        return this.order.retailerOrder ? this.order.retailerOrder.refreshFromRemote() : true
      })
      .then(() => this.setDone(true))
      .catch(error => this.setError(error.response.data.error))
      .finally(() => this.setInProgress(false))
  }

  @action.bound
  markCancelled () {
    this.setInProgress(true)
    return cancelOrder(this.order.uuid)
      .then(({ data }) => {
        this.order.update(adaptPurchaseOrder(data))
        return this.order.retailerOrder ? this.order.retailerOrder.refreshFromRemote() : true
      })
      .then(() => this.setDone(true))
      .catch(error => this.setError(error.response.data.error))
      .finally(() => this.setInProgress(false))
  }

  @action.bound
  markFulfilled (data) {
    this.inProgress = true
    this.remoteErrors = undefined
    return markOrderFulfilled(this.order.uuid, adaptMarkFulfilledReverse(data))
      .then(response => {
        this.order.update(adaptPurchaseOrder(response.data))
        return (
          this.order.retailerOrder
            ? this.order.retailerOrder.refreshFromRemote()
            : true
        )
      })
      .then(() => this.setDone(true))
      .catch(error => {
        this.setRemoteErrors(processApiError(error, adaptMarkFulfilledErrors))
      })
      .finally(() => this.setInProgress(false))
  }
}

export default OrderModalActionState
