import { action, computed, observable } from 'mobx'

import { confirmExternalOrderPayment } from 'shared/api/profiles'
import {
  EXTERNAL_ORDER_CONFIRMATION_TYPE_ALIBABA,
  EXTERNAL_ORDER_CONFIRMATION_TYPE_ALIEXPRESS,
  EXTERNAL_ORDER_CONFIRMATION_TYPE_DRAFT_ORDERS
} from 'shared/constants/profiles'

import OrderModalActionState from '../OrderModalActionState'

/**
 * Handles the modal for external order payments, e.g. for Draft Orders.
 * See <ExternalOrderPaymentModal /> component.
 */
class ExternalOrderPaymentStore extends OrderModalActionState {
  @observable doNotShowAgain = false

  /**
   * Calculate the order type for confirmation of `do not show again`.
   * @returns {string}
   */
  @computed get confirmationOrderType () {
    if (this.order.isAlibaba) return EXTERNAL_ORDER_CONFIRMATION_TYPE_ALIBABA
    if (this.order.isAliExpress) return EXTERNAL_ORDER_CONFIRMATION_TYPE_ALIEXPRESS
    if (this.order.usesDraftOrders) return EXTERNAL_ORDER_CONFIRMATION_TYPE_DRAFT_ORDERS
  }

  /**
   * Set `doNotShowAgain` to given value.
   * @param {Boolean} value
   */
  @action.bound
  setDoNotShowAgain (value) {
    this.doNotShowAgain = Boolean(value)
  }

  @action.bound
  confirmExternalOrderPayment () {
    this.setInProgress(true)
    return confirmExternalOrderPayment(this.confirmationOrderType)
      .then(() => {
        this.setDone(true)
      })
      .catch(error => {
        this.setError(error.response.data.error)
      })
      .finally(() => {
        this.setInProgress(false)
      })
  }
}

export default ExternalOrderPaymentStore
