import React, { forwardRef, useMemo } from 'react'

import PropTypes from 'prop-types'

import { Button } from '@material-ui/core'
import { observer } from 'mobx-react'

import { useTranslation } from 'shared/hooks'

import { useStores } from 'retailer/stores/hooks'

/**
 * Prop injector component for the "Add product to import list" action.
 *
 * Ref forwarded to wrapped component.
 * */
const AddProductAction = forwardRef(({
  testId, component: Component, item, onClick: onClickProp, ...rest
}, ref) => {
  const { t } = useTranslation('marketplace')

  const { userProfileStore } = useStores()

  const { available, isLimited, isExported, isImported, isImporting, isImportedOrExported } = item
  const onlyInProPlan = available && isLimited
  const limitReached = userProfileStore.productsLeft <= 0
  const disabled = !isImportedOrExported && (isImporting || !available || onlyInProPlan || limitReached)

  const handleAddToImportListClick = () => {
    item.addToImportList()
    onClickProp && onClickProp()
  }

  const handleViewInSyncListClick = event => {
    onClickProp && onClickProp()
  }

  const handleViewInImportListClick = event => {
    onClickProp && onClickProp()
  }

  const label = useMemo(() => {
    switch (true) {
      case isExported: return t('productAction.viewInSyncList.cta')
      case isImported: return t('productAction.viewInImportList.cta')
      default: return t('productAction.addToImportList.cta')
    }
  })

  const title = useMemo(() => {
    switch (true) {
      case isImportedOrExported: return ''
      case onlyInProPlan: return t('productAction.addToImportList.onlyOnProPlanWarning')
      case !available: return t('productAction.addToImportList.notAvailableWarning')
      case limitReached: return t('productAction.addToImportList.limitReachedWarning')
      default: return ''
    }
  }, [isImportedOrExported, onlyInProPlan, available, limitReached])

  const to = useMemo(() => {
    switch (true) {
      case isExported: return '/my-products/sync-list'
      case isImported: return '/my-products/import-list'
      default: return undefined
    }
  }, [isExported, isImported])

  const status = useMemo(() => {
    switch (true) {
      case isImporting: return 'adding'
      case isImportedOrExported: return 'added'
      default: return 'new'
    }
  }, [isImporting, isImportedOrExported])

  const onClick = useMemo(() => {
    switch (true) {
      case isExported: return handleViewInSyncListClick
      case isImported: return handleViewInImportListClick
      default: return handleAddToImportListClick
    }
  }, [isExported, isImported])

  const injectedProps = { title, onClick, disabled, to, status, ref, testId, ...rest }
  return <Component {...injectedProps}>{label}</Component>
})

AddProductAction.propTypes = {
  item: PropTypes.shape({
    isExported: PropTypes.bool.isRequired,
    isImported: PropTypes.bool.isRequired,
    isImporting: PropTypes.bool.isRequired,
    isImportedOrExported: PropTypes.bool.isRequired,
    isLimited: PropTypes.bool.isRequired,
    available: PropTypes.bool.isRequired,
    addToImportList: PropTypes.func.isRequired,
  }).isRequired,
  onClick: PropTypes.func,
  component: PropTypes.elementType,
  testId: PropTypes.string,
}

AddProductAction.defaultProps = {
  component: Button,
}

export default observer(AddProductAction)
