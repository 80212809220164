import React from 'react'

import PropTypes from 'prop-types'

import { Typography, Button } from '@material-ui/core'
import {
  Pagination as MaterialUiPagination,
  PaginationItem,
} from '@material-ui/lab'
import clsx from 'clsx'
import { observer } from 'mobx-react'
import { Link } from 'react-router-dom'

import style from './Pagination.module.scss'

const getNumeral = (count, singular) => {
  if (count === 0) return singular + 's'
  if (count > 1) return singular + 's'
  return singular
}

/**
 * A simple Pagination component, that does few things out of the box for you:
 *    - it displays the total number of available items and pages
 *    - it automatically adds/updates the `?page=X` query param in the URL (using React Router)
 *    - it can also run any onChange handler if you provide it
 */
const Pagination = ({ itemName, totalItems, count, asButton, className, ...props }) => {
  return (
    <div className={clsx(style.Pagination, className)}>
      <Typography variant="body2" className={style.Counter} data-testid="pagination-totalItemsShown">
        Showing{' '}
        {totalItems} {getNumeral(totalItems, itemName)}{' '}
        in {count} {getNumeral(count, 'page')}
      </Typography>
      <MaterialUiPagination
        showFirstButton
        showLastButton
        shape="rounded"
        size="large"
        renderItem={(item) => (
          <PaginationItem
            component={asButton ? Button : Link}
            to={`?page=${item.page}`}
            {...item}
          />
        )}
        count={count}
        {...props}
      />
    </div>
  )
}

Pagination.propTypes = {
  itemName: PropTypes.string,
  totalItems: PropTypes.number.isRequired,
  count: PropTypes.number.isRequired,
  asButton: PropTypes.bool,
  page: PropTypes.number.isRequired,
  onChange: PropTypes.func,
  className: PropTypes.string
}

Pagination.defaultProps = {
  itemName: 'item',
  asButton: false,
  onChange: () => null,
  className: ''
}

export default observer(Pagination)
