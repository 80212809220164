import React from 'react'

import PropTypes from 'prop-types'

import { Grid } from '@material-ui/core'
import { StarRounded } from '@material-ui/icons'
import { Rating } from '@material-ui/lab'
import clsx from 'clsx'
import { observer } from 'mobx-react'

import style from './StarRating.module.scss'

const StarRating = ({ starRating, reviewCount, expanded, className }) => {
  if (!reviewCount) {
    return (
      <div className={clsx(style.StarRating, className)}>
        No reviews yet
      </div>
    )
  }
  return (
    <Grid container spacing={1} alignItems="center" className={clsx(style.StarRating, className)}>
      <Grid item className={style.IconGridItem}>
        {expanded && <Rating value={starRating} precision={0.5} readOnly />}
        {!expanded && <StarRounded className={style.Icon} />}
      </Grid>
      <Grid item>
        {starRating} ({reviewCount})
      </Grid>
    </Grid>
  )
}

StarRating.propTypes = {
  starRating: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  reviewCount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  expanded: PropTypes.bool,
  className: PropTypes.string,
}

StarRating.defaultProps = {
  starRating: 0,
  reviewCount: 0,
  expanded: false,
  className: ''
}

export default observer(StarRating)
