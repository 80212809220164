import { action, computed, observable } from 'mobx'

import { adaptRetailerItemsCount } from 'shared/adapters/retailerItems'
import { getRetailerItemsCount } from 'shared/api/retailerItems'

class RetailerItemsCount {
  @observable importList = 0
  @observable syncList = 0
  @observable archiveList = 0

  @action.bound
  fetch () {
    getRetailerItemsCount().then(response => {
      Object.assign(this, adaptRetailerItemsCount(response.data))
    })
  }

  @computed get total () {
    return this.importList + this.syncList + this.archiveList
  }
}

export default RetailerItemsCount
