import React from 'react'

import PropTypes from 'prop-types'

import {
  Grid,
  IconButton,
  Paper,
  Tooltip,
  Typography,
} from '@material-ui/core'
import { observer } from 'mobx-react'

import { Money, Time, Label } from 'shared/components/atoms'
import { Alert } from 'shared/components/molecules'
import {
  integrationIcons,
  integrationsNames,
} from 'shared/constants/integrations'
import {
  PROGRESS_FULL,
  PROGRESS_PARTIAL,
  PROGRESS_PENDING,
} from 'shared/constants/orders'
import { ExternalLink } from 'shared/icons'

import { Address } from 'retailer/components/molecules'
import { RetailerOrder as RetailerOrderStore } from 'retailer/stores'

import PurchaseOrder from '../PurchaseOrder/PurchaseOrder' // Circular Dep?
import style from './RetailerOrder.module.scss'

const PlacementLabel = observer(({ status, testId }) => {
  switch (status) {
    case PROGRESS_PENDING: return <Label label="Pending Sync" status="warning" testId={testId} />
    case PROGRESS_PARTIAL: return <Label label="Partially Synced" status="warning" testId={testId} />
    case PROGRESS_FULL: return <Label label="Synced" status="success" testId={testId} />
    default: return null
  }
})

PlacementLabel.propTypes = {
  status: PropTypes.oneOf([PROGRESS_PENDING, PROGRESS_PARTIAL, PROGRESS_FULL]),
  testId: PropTypes.string,
}

const PaymentLabel = observer(({ status, testId }) => {
  switch (status) {
    case PROGRESS_PENDING: return <Label label="Pending Payment" status="warning" testId={testId} />
    case PROGRESS_PARTIAL: return <Label label="Partially Paid" status="warning" testId={testId} />
    case PROGRESS_FULL: return <Label label="Paid" status="success" testId={testId} />
    default: return null
  }
})

PaymentLabel.propTypes = {
  status: PropTypes.oneOf([PROGRESS_PENDING, PROGRESS_PARTIAL, PROGRESS_FULL]),
  testId: PropTypes.string,
}

const FulfillmentLabel = observer(({ status, testId }) => {
  switch (status) {
    case PROGRESS_PENDING: return <Label label="Pending Fulfillment" status="warning" testId={testId} />
    case PROGRESS_PARTIAL: return <Label label="Partially Fulfilled" status="warning" testId={testId} />
    case PROGRESS_FULL: return <Label label="Fulfilled" status="success" testId={testId} />
    default: return null
  }
})

FulfillmentLabel.propTypes = {
  status: PropTypes.oneOf([PROGRESS_PENDING, PROGRESS_PARTIAL, PROGRESS_FULL]),
  testId: PropTypes.string,
}

const RefundLabel = observer(({ status, testId }) => {
  switch (status) {
    case PROGRESS_PARTIAL: return <Label label="Partially Refunded" status="warning" testId={testId} />
    case PROGRESS_FULL: return <Label label="Refunded" status="error" testId={testId} />
    default: return null
  }
})

RefundLabel.propTypes = {
  status: PropTypes.oneOf([PROGRESS_PENDING, PROGRESS_PARTIAL, PROGRESS_FULL]),
  testId: PropTypes.string,
}

const CancellationLabel = observer(({ status, testId }) => {
  switch (status) {
    case PROGRESS_PARTIAL: return <Label label="Partially Cancelled" status="error" testId={testId} />
    case PROGRESS_FULL: return <Label label="Cancelled" status="error" testId={testId} />
    default: return null
  }
})

CancellationLabel.propTypes = {
  status: PropTypes.oneOf([PROGRESS_PENDING, PROGRESS_PARTIAL, PROGRESS_FULL]),
  testId: PropTypes.string,
}

const Alerts = observer(({
  alerts
}) => {
  if (!alerts || alerts.length === 0) {
    return null
  }
  return (
    <div className={style.Alerts}>
      {alerts.map(({ code, message }) => {
        return (
          <Alert
            key={code}
            severity="error"
            variant="filled"
            data-testid="retailerOrder-alert"
          >
            {message}
          </Alert>
        )
      })}
    </div>
  )
})

Alerts.propTypes = {
  alerts: PropTypes.arrayOf(PropTypes.shape({
    code: PropTypes.string.isRequired,
    message: PropTypes.string.isRequired,
  }))
}

const RetailerOrder = ({ store }) => {
  const {
    orderNumber,
    externalUrl,
    integrationType,
    placementProgress,
    paymentProgress,
    fulfillmentProgress,
    cancellationProgress,
    refundProgress,
    placedOn,
    alerts,
    purchaseOrders,
    totalCosts,
    paidByCustomer,
    profit,
    isPaidByCustomer,
    isManual,
    has3rdPartySupplierOrders,
  } = store
  const IntegrationIcon = integrationIcons.get(integrationType)
  const integrationName = integrationsNames.get(integrationType)

  return (
    <Paper variant="outlined" className={style.RetailerOrder} data-testid="retailerOrder">

      <Grid container className={style.Header}>
        <Grid item container className={style.OrderInformation}>
          <Grid item className={style.BasicInformation}>
            <Tooltip arrow title={`${integrationName} Order`}>
              <IntegrationIcon
                width={26}
                height={26}
              />
            </Tooltip>
            <Typography variant="body1" className={style.OrderNumber} data-testid="retailerOrder-orderNumber">
              {isManual ? 'Manual Order' : `Order #${orderNumber}`}
            </Typography>
            {externalUrl && (
              <Tooltip arrow title={`Open order on ${integrationName}`}>
                <IconButton
                  color="primary"
                  size="small"
                  href={externalUrl}
                  target="_blank"
                >
                  <ExternalLink width={20} height={20} />
                </IconButton>
              </Tooltip>
            )}
          </Grid>
          <Grid item className={style.DatesAndNumbers}>
            <Typography>
              Order date: <Time value={placedOn} testId="retailerOrder-orderDate" />
            </Typography>
            {!isManual && (
              <Typography data-testid="retailerOrder-customerPaymentStatus">
                {isPaidByCustomer ? 'Paid by customer' : 'Pending payment by customer'}
              </Typography>
            )}

          </Grid>
          <Grid item className={style.Statuses}>
            {has3rdPartySupplierOrders && <PlacementLabel status={placementProgress} testId="retailerOrder-placementLabel" />}
            <PaymentLabel status={paymentProgress} testId="retailerOrder-paymentLabel" />
            <FulfillmentLabel status={fulfillmentProgress} testId="retailerOrder-fulfillmentLabel" />
            <CancellationLabel status={cancellationProgress} testId="retailerOrder-cancellationLabel" />
            <RefundLabel status={refundProgress} testId="retailerOrder-refundLabel" />
          </Grid>
        </Grid>
        <Grid item className={style.Address}>
          <Address order={store} testId="retailerOrder-address" />
        </Grid>
      </Grid>

      <Alerts alerts={alerts} />

      <div className={style.OrdersList}>
        {purchaseOrders.map(purchaseOrder => (
          <PurchaseOrder
            key={purchaseOrder.uuid}
            store={purchaseOrder}
            testId="retailerOrder-purchaseOrder"
          />
        ))}
      </div>

      <Grid container className={style.Footer}>
        <Grid
          item
          component={Typography}
          variant="h5"
          className={style.TotalCosts}
        >
          Total: <Money amount={totalCosts} testId="retailerOrder-totalCosts" />
        </Grid>
        {!isManual && (
          <Grid item className={style.Profit}>
            <Label
              status={profit > 0 ? 'success' : 'error'}
              testId="retailerOrder-profit"
              label={`$ ${profit} Profit`}
              size="small"
            />
          </Grid>
        )}
        {!isManual && (
          <Grid item component={Typography} variant="body2" className={style.PaidByCustomer}>
            {isPaidByCustomer ? 'Total paid by customer:' : 'Total to pay by customer:'}
            <Money amount={paidByCustomer} testId="retailerOrder-paidByCustomer" />
          </Grid>
        )}
      </Grid>

    </Paper>
  )
}

RetailerOrder.propTypes = {
  store: PropTypes.instanceOf(RetailerOrderStore).isRequired,
}

export default observer(RetailerOrder)
