import { useContext } from 'react'

import ConfirmContext from './context'
import { HIDE_CONFIRM, RESET_CONFIRM, SHOW_CONFIRM } from './reducer'

function useConfirm () {
  const [confirmState, dispatch] = useContext(ConfirmContext)

  /** Dismiss the modal with the "confirmed" result */
  const onConfirm = () => {
    closeConfirm()
    confirmState.resolve && confirmState.resolve(true)
  }

  /** Dismiss the modal with the "cancelled" result */
  const onCancel = () => {
    closeConfirm()
    confirmState.resolve && confirmState.resolve(false)
  }

  /** Call to cleanup after the modal exits */
  const onExited = () => dispatch({ type: RESET_CONFIRM })

  /** Call to bring up the confirm modal with specified title, message and config */
  const confirm = (title, message, config = {}) => {
    // if there's a pending request, resolve to false
    confirmState.resolve && confirmState.resolve(false)

    return new Promise((resolve, reject) => {
      dispatch({
        type: SHOW_CONFIRM,
        payload: {
          title,
          message,
          config,
          resolve,
        }
      })
    })
  }

  const closeConfirm = () => dispatch({ type: HIDE_CONFIRM })

  return { confirm, onConfirm, onCancel, onExited, confirmState }
}

export default useConfirm
