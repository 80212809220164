import React from 'react'

import PropTypes from 'prop-types'

import { Link } from 'retailer/components/atoms'

import style from './IconLink.module.scss'

const IconLink = ({ className, icon, href, text, nav, absolute }) => {
  return (
    <Link
      href={href}
      className={`${style.IconLink} ${className}`}
      nav={nav}
      absolute={absolute}
    >
      {icon}
      <span className={style.link}>{text}</span>
    </Link>
  )
}

IconLink.propTypes = {
  className: PropTypes.string,
  icon: PropTypes.node.isRequired,
  href: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  nav: PropTypes.bool,
  absolute: PropTypes.bool
}

IconLink.defaultProps = {
  className: '',
  nav: false,
  absolute: false
}

export default IconLink
