import { Sign } from 'shared/icons'
import { AliExpress, Shopify, BigCommerce, Wix, Alibaba, Storenvy, Volusion } from 'shared/icons/external'

const ALIEXPRESS = 'aliexpress'
const ALIBABA = 'alibaba'
const MODALYST = 'modalyst'
const SHOPIFY = 'shopify'
const BIGCOMMERCE = 'bigcommerce'
const WIX = 'wix'
const STORENVY = 'storenvy'
const VOLUSION = 'volusion'
const STYLE_COMMERCE = 'style_commerce'
const KORNIT = 'kornit'

const integrationsNames = new Map([
  [ALIEXPRESS, 'AliExpress'],
  [ALIBABA, 'Alibaba'],
  [MODALYST, 'Modalyst'],
  [SHOPIFY, 'Shopify'],
  [BIGCOMMERCE, 'BigCommerce'],
  [WIX, 'Wix'],
  [ALIBABA, 'Alibaba'],
  [STYLE_COMMERCE, 'Modalyst'],
  [KORNIT, 'Modalyst']
])

export const THIRD_PARTY_PLATFORMS = [ALIEXPRESS, ALIBABA]

const integrationIcons = new Map([
  [null, Sign],
  [MODALYST, Sign],
  [ALIEXPRESS, AliExpress],
  [ALIBABA, Alibaba],
  [SHOPIFY, Shopify],
  [BIGCOMMERCE, BigCommerce],
  [WIX, Wix],
  [STORENVY, Storenvy],
  [STORENVY + 'extended', Storenvy],
  [VOLUSION, Volusion],
  [STYLE_COMMERCE, Sign],
  [KORNIT, Sign]
])

const connectableIntegrations = [SHOPIFY, WIX]

const ALIEXPRESS_URL = 'https://www.aliexpress.com/'
const CHROME_EXTENSION_URL = 'https://chrome.google.com/webstore/detail/modalyst/epcpfbncpjfomakgdophbanbkdeonkhc'

export {
  ALIBABA,
  ALIEXPRESS,
  MODALYST,
  SHOPIFY,
  BIGCOMMERCE,
  WIX,
  STORENVY,
  VOLUSION,
  STYLE_COMMERCE,
  KORNIT,
  integrationsNames,
  integrationIcons,
  connectableIntegrations,
  ALIEXPRESS_URL,
  CHROME_EXTENSION_URL,
}
