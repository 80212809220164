import React, { forwardRef } from 'react'

import PropTypes from 'prop-types'

import { observer } from 'mobx-react'
import { Link as RouterLink } from 'react-router-dom'

import { getMarketplaceItemUrl } from 'shared/utils/urls'

/**
 * A react-router Link to product details page with URL generation built-in,
 * that opens the target in a new tab.
 * Can be customized with any props handled by Link.
 * Dispatches BI event.
 */
const ProductLink = forwardRef(({ component, productStore, sameTab, ...rest }, ref) => {
  const { id, isCustomizable, logViewDetailsClickedBiEvent } = productStore
  const productDetailsUrl = getMarketplaceItemUrl(id, isCustomizable)
  const Component = component || RouterLink

  const onClick = event => {
    logViewDetailsClickedBiEvent()
  }
  const props = Object.assign(
    { ref, to: productDetailsUrl, onClick },
    sameTab ? {} : { target: '_blank', rel: 'opener' },
    rest
  )
  return <Component component={component && RouterLink} {...props} />
})

ProductLink.propTypes = {
  component: PropTypes.elementType,
  sameTab: PropTypes.bool,
  productStore: PropTypes.shape({
    id: PropTypes.string.isRequired,
    isCustomizable: PropTypes.bool.isRequired,
    logViewDetailsClickedBiEvent: PropTypes.func.isRequired,
  }).isRequired,
}

export default observer(ProductLink)
