import React, { useRef } from 'react'

import PropTypes from 'prop-types'

import { yupResolver } from '@hookform/resolvers/yup'
import { Grid, TextField } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { observer } from 'mobx-react'
import { useSnackbar } from 'notistack'
import { Controller, useForm } from 'react-hook-form'
import * as yup from 'yup'

import { CountrySelect } from 'shared/components/molecules'
import { CardForm } from 'shared/components/organisms'
import { getFieldErrorMessage, useRemoteErrors } from 'shared/utils/forms'

import { BusinessInformationStore } from 'retailer/stores'

import style from './BusinessInformationForm.module.scss'

const commonInputProps = {
  variant: 'outlined',
  required: true,
  fullWidth: true
}

const validationSchema = yup.object().shape({
  businessName: yup.string()
    .min(2, 'Business name must be at least 2 characters long')
    .max(100, 'Business name cannot be longer than 100 characters long')
    .required('Business name is required'),
  streetAddress: yup.string()
    .min(3, 'Street address must be at least 3 characters long')
    .max(255, 'Street address cannot be longer than 255 characters')
    .required('Street address is required'),
  suiteApt: yup.string()
    .max(64, 'Suite and apartment number cannot be longer than 64 characters')
    .required('Suite and apartment number is required'),
  taxId: yup.string()
    .ensure()
    .max(100, 'Tax ID cannot be longer than 100 characters'),
  zipCode: yup.string()
    .max(20, 'ZIP code cannot be longer than 20 characters')
    .required('ZIP code is required'),
  city: yup.string()
    .max(128, 'City name cannot be longer than 128 characters')
    .required('City name is required'),
  state: yup.string()
    .ensure()
    .max(100, 'State name cannot be longer than 100 characters'),
  country: yup.string()
    .min(2, 'Invalid value provided')
    .max(2, 'Invalid value provided')
    .required('Country is required'),
  phoneNumber: yup.string()
    .matches(/^(\+)?([\d\s-])*\d$/, 'Provided phone number is invalid')
    .max(100, 'Phone number cannot be longer than 100 characters')
    .required('Phone number is required'),
  invoiceNote: yup.string()
    .max(10000, 'Invoice note cannot be longer than 10,000 characters')
})

const BusinessInformationForm = ({ store }) => {
  const getDefaultValues = () => ({
    businessName: store.businessName,
    streetAddress: store.streetAddress,
    suiteApt: store.suiteApt,
    taxId: store.taxId,
    zipCode: store.zipCode,
    city: store.city,
    state: store.state,
    country: store.country,
    phoneNumber: store.phoneNumber,
    invoiceNote: store.invoiceNote
  })
  const { enqueueSnackbar } = useSnackbar()
  const formRef = useRef(null)
  const { handleSubmit, formState, reset, errors, setError, register, control } = useForm({
    defaultValues: getDefaultValues(),
    resolver: yupResolver(validationSchema)
  })
  const { nonFieldErrors } = useRemoteErrors(store.errors, setError)

  const onSubmit = data => {
    store.save(data).then(() => {
      reset(getDefaultValues())
      enqueueSnackbar('Business information saved successfully', {
        variant: 'success'
      })
    })
  }

  const formChanged = formState.isDirty && !!Object.keys(formState.dirtyFields).length

  return (
    <CardForm
      className={style.BusinessInformationForm}
      title="Business Information"
      subheader="This information will be used as your address on your branded invoices"
      formRef={formRef}
      canSubmit={formChanged && !Object.keys(errors).length}
      isSubmitting={store.isSaving}
      onDiscard={formChanged ? () => reset(getDefaultValues()) : null}
    >
      <form ref={formRef} onSubmit={handleSubmit(onSubmit)}>
        {nonFieldErrors && (
          <Alert severity="error" className={style.Errors}>
            {nonFieldErrors.join(' ')}
          </Alert>
        )}
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              id="businessName" name="businessName" label="Business name"
              inputRef={register({ required: true })} {...commonInputProps}
              error={!!errors.businessName} helperText={getFieldErrorMessage(errors, 'businessName')}
            />
          </Grid>
          <Grid item xs={12} sm={8} md={9}>
            <TextField
              id="streetAddress" name="streetAddress" label="Street name"
              inputRef={register({ required: true })} {...commonInputProps}
              error={!!errors.streetAddress} helperText={getFieldErrorMessage(errors, 'streetAddress')}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={3}>
            <TextField
              id="suiteApt" name="suiteApt" label="Suite / Apt."
              inputRef={register({ required: true })} {...commonInputProps}
              error={!!errors.suiteApt} helperText={getFieldErrorMessage(errors, 'suiteApt')}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="taxId" name="taxId" label="Tax ID (optional)"
              inputRef={register} variant="outlined" fullWidth
              error={!!errors.taxId}
              helperText={getFieldErrorMessage(errors, 'taxId') || 'Provide VAT, BN or ABN number'}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              id="city" name="city" label="City"
              inputRef={register({ required: true })} {...commonInputProps}
              error={!!errors.city} helperText={getFieldErrorMessage(errors, 'city')}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              id="state" name="state" label="State (optional)"
              inputRef={register} variant="outlined" fullWidth
              error={!!errors.state} helperText={getFieldErrorMessage(errors, 'state')}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              id="zipCode" name="zipCode" label="ZIP code"
              inputRef={register({ required: true })} {...commonInputProps}
              error={!!errors.zipCode} helperText={getFieldErrorMessage(errors, 'zipCode')}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              {...commonInputProps}
              name="country"
              control={control}
              label="Country"
              error={!!errors.country}
              helperText={getFieldErrorMessage(errors, 'country')}
              required
              as={<CountrySelect />}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="phoneNumber" name="phoneNumber" label="Phone number"
              inputRef={register({ required: true })} {...commonInputProps}
              error={!!errors.phoneNumber} helperText={getFieldErrorMessage(errors, 'phoneNumber')}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="invoiceNote" name="invoiceNote" label="Invoice note (optional)" variant="outlined"
              inputRef={register} error={!!errors.invoiceNote}
              multiline minRows={6} fullWidth
              helperText={
                getFieldErrorMessage(errors, 'invoiceNote') ||
                'Add a personal note to your customers on each invoice (optional)'
              }
            />
          </Grid>
        </Grid>
      </form>
    </CardForm>
  )
}

BusinessInformationForm.propTypes = {
  store: PropTypes.instanceOf(BusinessInformationStore).isRequired
}

export default observer(BusinessInformationForm)
