import React, { forwardRef } from 'react'

import PropTypes from 'prop-types'

import {
  alpha,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Chip,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
  useTheme,
} from '@material-ui/core'
import { MoreHoriz } from '@material-ui/icons'
import {
  connectStoreClicked,
  deleteStoreClicked,
  deleteStoreConfirmModalConfirmed,
  deleteStoreConfirmModalShown,
  disconnectStoreClicked,
  disconnectStoreConfirmModalConfirmed,
  disconnectStoreConfirmModalShown,
  selectStoreClicked,
  storeCard3dotsClicked,
  upgradeStoreClicked,
} from '@wix/bi-logger-modalyst/v2'
import clsx from 'clsx'
import { usePopupState, bindTrigger, bindMenu } from 'material-ui-popup-state/hooks'
import { observer } from 'mobx-react'
import { useSnackbar } from 'notistack'
import { Trans } from 'react-i18next'
import { Link as RouterLink, useRouteMatch } from 'react-router-dom'
import { v4 as uuid4 } from 'uuid'
import { Cart, Refresh, StatusWarning as BaseStatusWarning } from 'wix-ui-icons-common'

import { useBiEvents } from 'shared/bi/hooks'
import { TooltipHelper } from 'shared/components/molecules'
import { SHOPIFY, WIX } from 'shared/constants/integrations'
import { useTranslation } from 'shared/hooks'
import { ShopifyLogo, WixLogo } from 'shared/icons/external'
import { useConfirm } from 'shared/utils/confirmation'

import { BiLink } from 'retailer/components/atoms'

import style from './MyStoreCard.module.scss'

const BI_ORIGIN = 'my stores card'
const BI_ORIGIN_3DOTS = 'my stores card 3dots'

const PLATFORM_LOGOS = new Map([
  [WIX, WixLogo],
  [SHOPIFY, ShopifyLogo],
])

const PLATFORM_LOGO_CLASSES = new Map([
  [WIX, style.WixLogo],
  [SHOPIFY, style.ShopifyLogo],
])

// Fix to missing ref forwarding in Wix Icons (required for Tooltip)
const StatusWarning = forwardRef((props, ref) => (
  <span ref={ref}><BaseStatusWarning {...props} /></span>
))

/** Reddish warning icon wrapped in a tooltip */
const WarningIcon = props => (
  <TooltipHelper IconComponent={StatusWarning} className={style.WarningIcon} {...props} />
)

/** Icon for "Connected to" */
const PlatformIcon = ({ platform }) => {
  const Icon = PLATFORM_LOGOS.get(platform)
  return <Icon className={PLATFORM_LOGO_CLASSES.get(platform)} />
}

PlatformIcon.propTypes = {
  platform: PropTypes.oneOf([...PLATFORM_LOGOS.keys()]),
}

/** Dropdown menu with actions and a trigger for the same */
const MoreActions = observer(({ store, editSettingsUrl, connectUrl, onDisconnect, onDelete }) => {
  const { storeInstallation } = store

  const popupState = usePopupState({ variant: 'popover', popupId: 'myStoresCardMoreActionsMenu' })
  const { logBiEvent } = useBiEvents()
  const { t } = useTranslation('myStores')
  const biParams = { storeName: store.name, targetStoreId: store.uuid, origin: BI_ORIGIN_3DOTS }

  const onEnter = () => logBiEvent(storeCard3dotsClicked(biParams))

  const handleMenuItemClick = () => popupState.close()
  const handleDisconnectClick = async () => {
    popupState.close()
    const biEvent = await logBiEvent(disconnectStoreClicked(biParams))
    onDisconnect(biEvent)
  }
  const handleDeleteClick = async () => {
    popupState.close()
    const biEvent = await logBiEvent(deleteStoreClicked(biParams))
    onDelete(biEvent)
  }

  return (
    <>
      <IconButton
        aria-label="more menu"
        data-testid="MyStoreCard-moreMenu"
        {...bindTrigger(popupState)}
      >
        <MoreHoriz />
      </IconButton>
      <Menu
        {...bindMenu(popupState)}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        className={style.MoreMenu}
        MenuListProps={{ component: 'nav' }}
        TransitionProps={{ onEnter }}
      >
        <MenuItem
          button onClick={handleMenuItemClick}
          data-testid="MyStoreCard-moreMenu-editStoreSettings"
          component={RouterLink} to={editSettingsUrl}
        >
          {t('editStoreSettings.cta')}
        </MenuItem>
        {storeInstallation && (
          <MenuItem
            button component="a" style={{ gap: '.25em' }}
            data-testid="MyStoreCard-moreMenu-openStore"
            href={storeInstallation.siteAdminUrl} target="_blank"
            onClick={() => popupState.close()}
          >
            <span>{t('openOn.cta')}</span>
            <PlatformIcon platform={storeInstallation.platform} />
          </MenuItem>
        )}
        {connectUrl && (
          <MenuItem
            button onClick={handleMenuItemClick}
            data-testid="MyStoreCard-moreMenu-connectStore"
            component={BiLink} to={connectUrl}
            biData={() => connectStoreClicked(biParams)}
          >
            {t('connectStore.cta')}
          </MenuItem>
        )}
        {onDisconnect && (
          <MenuItem
            button component="a" onClick={handleDisconnectClick}
            className={style.DestructiveActionMenuItem}
            data-testid="MyStoreCard-moreMenu-disconnectStore"
          >
            {t('disconnectStore.cta')}
          </MenuItem>
        )}
        {onDelete && (
          <MenuItem
            button component="a" onClick={handleDeleteClick}
            className={style.DestructiveActionMenuItem}
            data-testid="MyStoreCard-moreMenu-deleteStore"
          >
            {t('deleteStore.cta')}
          </MenuItem>
        )}
      </Menu>
    </>
  )
})

MoreActions.propTypes = {
  store: PropTypes.shape({
    uuid: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    storeInstallation: PropTypes.shape({
      platform: PropTypes.oneOf([WIX, SHOPIFY]),
      siteAdminUrl: PropTypes.string.isRequired,
    }),
  }).isRequired,
  editSettingsUrl: PropTypes.string.isRequired,
  connectUrl: PropTypes.string,
  onDisconnect: PropTypes.func,
  onDelete: PropTypes.func,
}

/** Renders the name of the external store */
const SiteName = observer(({ text }) => text)

SiteName.propTypes = {
  text: PropTypes.string.isRequired,
}

/** Renders the "Connected to ... on ..." message */
const ConnectedToSite = observer(({ storeInstallation }) => {
  const { t } = useTranslation('myStores')
  const { platform, siteName } = storeInstallation
  const i18nKey = siteName ? 'connectedTo.label' : 'connected.label'
  const storeName = <SiteName text={siteName} />
  return (
    <>
      <span><Trans t={t} i18nKey={i18nKey} components={{ storeName }} /></span>
      {' '}
      <PlatformIcon platform={platform} />
    </>
  )
})

ConnectedToSite.propTypes = {
  storeInstallation: PropTypes.shape({
    platform: PropTypes.oneOf([WIX, SHOPIFY]),
    siteName: PropTypes.string,
  }).isRequired,
}

/** Store card/slate for use in detailed stores list (such as MyStores page) */
const MyStoreCard = ({ store, onDelete }) => {
  const { t } = useTranslation('myStores')
  const theme = useTheme()
  const { url } = useRouteMatch()
  const { confirm } = useConfirm()
  const { logBiEvent } = useBiEvents()
  const { enqueueSnackbar } = useSnackbar()

  const {
    uuid,
    name,
    isPremium,
    productsLeft,
    productsLimit,
    unlimitedProducts,
    newOrdersCount,
    platform,
    isConnected,
    disconnectUrl,
    isDeleteable,
    isDeleteInProgress,
    storeInstallation,
  } = store

  const limitReached = productsLimit && productsLeft < 0.1 * productsLimit

  const connectUrl = isConnected ? undefined : `${url}/${uuid}/connect`
  const editSettingsUrl = `/${uuid}/settings/store-profile`
  const syncListUrl = `/${uuid}/my-products/sync-list`

  const handleDisconnect = async biEvent => {
    const isWix = platform === WIX
    const correlationId = biEvent?.params?.correlationId || uuid4()
    const biParams = { correlationId, storeName: name, targetStoreId: uuid }
    const keyChunk = isWix ? 'disconnectFromWixModal' : 'disconnectFromShopifyModal'
    const modalTitle = t(`${keyChunk}.title`)
    const modalContent = (
      <Trans
        t={t} i18nKey={`${keyChunk}.body`}
        components={{ 1: <RouterLink to={syncListUrl}>Sync List</RouterLink> }}
      />
    )
    const modalConfig = {
      ok: t(`${keyChunk}.disconnect.cta`),
      cancel: t(`${keyChunk}.cancel.cta`),
      onEntered: () => logBiEvent(disconnectStoreConfirmModalShown(biParams))
    }

    if (await confirm(modalTitle, modalContent, modalConfig)) {
      await logBiEvent(disconnectStoreConfirmModalConfirmed(biParams))
      window.location.assign(disconnectUrl)
    }
  }

  const handleDelete = async biEvent => {
    if (!onDelete) return
    const correlationId = biEvent?.params?.correlationId || uuid4()
    const biParams = { correlationId, storeName: name, targetStoreId: uuid }
    const modalTitle = t('deleteStoreModal.title')
    const modalContent = t('deleteStoreModal.body')
    const modalConfig = {
      ok: t('deleteStoreModal.delete.cta'),
      cancel: t('deleteStoreModal.cancel.cta'),
      onEntered: () => logBiEvent(deleteStoreConfirmModalShown(biParams))
    }
    if (await confirm(modalTitle, modalContent, modalConfig)) {
      try {
        await logBiEvent(deleteStoreConfirmModalConfirmed(biParams))
        await onDelete(store, { params: { correlationId } })
        enqueueSnackbar(t('deleteStore.success.toast'), { variant: 'success' })
      } catch (error) {
        const message = error.response?.data?.detail || t('deleteStore.unsuccessful.toast')
        enqueueSnackbar(message, { variant: 'error' })
      }
    }
  }

  return (
    <Card
      className={clsx(style.MyStoreCard, isDeleteInProgress && style.isBeingUpdated)}
      elevation={0}
      data-testid="MyStoreCard"
    >
      <CardHeader
        title={isPremium && (
          <Chip
            size="small" color="secondary"
            label={t('premium.label')}
            data-testid="MyStoresCard_premiumBadge"
          />
        )}
        action={
          <MoreActions
            store={store}
            onDisconnect={disconnectUrl && handleDisconnect}
            onDelete={isDeleteable ? handleDelete : undefined}
            connectUrl={connectUrl}
            editSettingsUrl={editSettingsUrl}
          />
        }
      />
      <Box
        textAlign="center" p={2} pt={0}
        className={style.ConnectedPlatform}
      >
        <Typography
          variant="h6" gutterBottom
          data-testid="MyStoresCard_storeName"
        >
          {name}
        </Typography>
        <Typography
          variant="body2" align="center" color="textSecondary"
          data-testid="MyStoresCard_connectedTo"
        >
          {storeInstallation
            ? <ConnectedToSite storeInstallation={storeInstallation} />
            : (
              <Tooltip title={t('connectStore.tooltip')}>
                <BiLink
                  to={connectUrl}
                  biData={() => connectStoreClicked({ origin: BI_ORIGIN, storeName: name, targetStoreId: uuid })}
                  data-testid="MyStoresCard_connectLink"
                >
                  {t('connectStore.cta')}
                </BiLink>
              </Tooltip>
              )}
        </Typography>
      </Box>
      <List className={style.StatsList}>
        <ListItem dense>
          <ListItemIcon>
            <Cart />
          </ListItemIcon>
          <ListItemText
            data-testid="MyStoresCard_newOrdersCountText"
            primary={
              <Trans
                t={t} i18nKey="newOrders.label"
                values={{ count: newOrdersCount }}
              />
            }
          />
        </ListItem>
        <ListItem dense>
          <ListItemIcon>
            <Refresh />
          </ListItemIcon>
          <ListItemText
            data-testid="MyStoresCard_productsLeftText"
            primary={unlimitedProducts
              ? <Trans t={t} i18nKey="productsUnlimited.label" />
              : (
                <Trans
                  t={t} i18nKey="productsLeft.label"
                  values={{ count: productsLeft }}
                />
                )}
          />
          {limitReached && (
            <WarningIcon
              content={t('productsLeft.tooltip')}
              data-testid="MyStoresCard_productsLeftWarning"
            />
          )}
        </ListItem>
      </List>
      <CardContent
        className={style.UpgradeCta}
        component={Box} height={theme.spacing(6)}
        bgcolor={limitReached ? alpha(theme.palette.secondary.light, 0.3) : undefined}
      >
        <Typography align="center" variant="body2">
          {limitReached && (
            <>
              {t('addMore.toast')}{' '}
              <BiLink
                to={`/${uuid}/settings/manage-store-plan`}
                biData={() => upgradeStoreClicked({
                  origin: BI_ORIGIN,
                  storeName: name,
                  targetStoreId: uuid,
                  numProductsLeft: productsLeft,
                })}
              >
                {t('addMore.cta')}
              </BiLink>
            </>
          )}
        </Typography>
      </CardContent>
      <Divider />
      <Box display="flex" justifyContent="center" py={1.5}>
        <Button
          variant="outlined" color="primary"
          data-testid="MyStoresCard_selectButton"
          component={BiLink} to={`/${uuid}`}
          biData={() => selectStoreClicked({ origin: BI_ORIGIN, storeName: name, targetStoreId: uuid })}
        >
          {t('selectStore.cta')}
        </Button>
      </Box>
    </Card>
  )
}

MyStoreCard.propTypes = {
  store: PropTypes.shape({
    uuid: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    platform: PropTypes.oneOf([WIX, SHOPIFY]),
    productsLeft: PropTypes.number.isRequired,
    productsLimit: PropTypes.number,
    unlimitedProducts: PropTypes.bool,
    newOrdersCount: PropTypes.number.isRequired,
    isPremium: PropTypes.bool,
    isConnected: PropTypes.bool.isRequired,
    disconnectUrl: PropTypes.string,
    isDeleteable: PropTypes.bool,
    isDeleteInProgress: PropTypes.bool,
    storeInstallation: PropTypes.object,
  }),
  onDelete: PropTypes.func,
}

export default observer(MyStoreCard)
