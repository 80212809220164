import React from 'react'

import PropTypes from 'prop-types'

import { Button } from '@material-ui/core'
import { observer } from 'mobx-react'

import { useStores } from 'retailer/stores/hooks'

/** Prop injector component for the "design product" action */
const DesignProductAction = ({ component: Component, productStore, onClick: onClickProp, ...rest }) => {
  const { itemCustomizerStore } = useStores()
  const { available, isLimited, isCustomizable, logDesignClickedBiEvent } = productStore

  const disabled = !isCustomizable || !available || isLimited
  const onClick = async () => {
    if (disabled) return
    const { params: { origin, correlationId } } = await logDesignClickedBiEvent()
    itemCustomizerStore.editDesign({
      productId: productStore.id,
      externalId: productStore.externalId,
      customEditorConfig: productStore.customEditorConfig || undefined,
      productName: productStore.name,
      biContext: { origin, correlationId },
    })
    onClickProp && onClickProp()
  }

  const injectedProps = { disabled, onClick, ...rest }
  return <Component {...injectedProps} />
}

DesignProductAction.propTypes = {
  /** Store for supplier product  */
  productStore: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    name: PropTypes.string.isRequired,
    externalId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    isLimited: PropTypes.bool.isRequired,
    available: PropTypes.bool.isRequired,
    isCustomizable: PropTypes.bool.isRequired,
    customEditorConfig: PropTypes.string,
    logDesignClickedBiEvent: PropTypes.func.isRequired,
  }).isRequired,
  onClick: PropTypes.func,
  component: PropTypes.elementType,
  children: PropTypes.node,
}

DesignProductAction.defaultProps = {
  component: Button,
}

export default observer(DesignProductAction)
