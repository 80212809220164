import { observable } from 'mobx'

class RetailerExceedsProductLimitStore {
  @observable managePlanStore
  @observable plan

  constructor (managePlanStore, plan) {
    this.managePlanStore = managePlanStore
    this.plan = plan
  }
}

export default RetailerExceedsProductLimitStore
