import { action, observable } from 'mobx'

import AlibabaIntegrationStore from './AlibabaIntegrationStore'
import AliExpressIntegrationStore from './AliExpressIntegrationStore'

/**
 * Main store for this React app. Compose sub-stores within it.
 */
class ConnectToSuppliersStore {
  aliexpress
  alibaba
  isLoading

  @observable marketplaceNavigationRequest

  constructor (appConfigStore) {
    this.aliexpress = new AliExpressIntegrationStore(appConfigStore)
    this.alibaba = new AlibabaIntegrationStore(appConfigStore)
  }

  @action.bound
  setIsLoading (value) {
    this.isLoading = value
  }

  @action.bound
  fetchAuthorizations () {
    this.setIsLoading(true)
    return Promise.all([
      this.aliexpress.fetchAuthorization(),
      this.alibaba.fetchAuthorization(),
    ])
      .finally(() => {
        this.setIsLoading(false)
      })
  }

  @action.bound
  navigateToMarketplace (integrationName) {
    const integration = this[integrationName]
    const url = integration.marketplaceUrl
    if (url === false) {
      // user needs to be shown some info before proceeding to the integration
      this.marketplaceNavigationRequest = integration
    }
    return url
  }

  @action.bound
  finishMarketplaceNavigation () {
    this.marketplaceNavigationRequest = undefined
  }
}

export default ConnectToSuppliersStore
