import React from 'react'

import PropTypes from 'prop-types'

import { Typography } from '@material-ui/core'
import { DeleteOutlineRounded, RestoreFromTrashRounded } from '@material-ui/icons'
import { Alert } from '@material-ui/lab'
import { observer } from 'mobx-react'
import { useSnackbar } from 'notistack'

import { Time } from 'shared/components/atoms'
import { CardForm } from 'shared/components/organisms'
import { UserDeleteAccountStore } from 'shared/stores'

import style from './UserDeleteAccountForm.module.scss'

const UserDeleteAccountForm = ({ store }) => {
  const { enqueueSnackbar } = useSnackbar()

  const requestRemove = () => {
    store.requestRemoval().then(() => {
      enqueueSnackbar('Account removal requested', {
        variant: 'success',
      })
    })
  }

  const cancelRemove = () => {
    store.cancelRemoval().then(() => {
      enqueueSnackbar('Account removal request was cancelled', {
        variant: 'success',
      })
    })
  }

  const handleClick = store.userRequestedAccountRemoval ? cancelRemove : requestRemove

  return (
    <CardForm
      className={style.UserDeleteAccountForm}
      title="Delete account"
      subheader={
        `If you would like to permanently delete your account, we can do it for you.
        Your account information will be deleted within 14 days. This action can be reversed within 14 days.
        For more information, please contact support.`
      }
      submitText={store.userRequestedAccountRemoval ? 'Cancel removal' : 'Delete account'}
      submitIcon={store.userRequestedAccountRemoval ? <RestoreFromTrashRounded /> : <DeleteOutlineRounded />}
      submitButtonProps={store.userRequestedAccountRemoval ? {} : { variant: 'text' }}
      discardAvailable={false}
      onSave={handleClick}
      isSubmitting={store.isSaving}
      canSubmit={!store.isSaving}
      testId="UserDeleteAccountForm"
    >
      {store.error && (
        <Alert severity="error" className={style.Error}>
          Something went wrong. Please try again later.
        </Alert>
      )}
      {store.userRequestedAccountRemoval && (
        <>
          <Typography variant="body1" className={style.Bold} gutterBottom data-testid="UserDeleteAccountForm-requestedActionText">
            The request to permanently delete your account has been received
            on <Time value={store.requestDatetime} format="MMMM D, YYYY hh:mm a" />.<br />
            Your account will be permanently deleted within 14 days from this date.
          </Typography>
          <Typography variant="body1">
            If you have done this by mistake, you can cancel the request.
          </Typography>
        </>
      )}
    </CardForm>
  )
}

UserDeleteAccountForm.propTypes = {
  store: PropTypes.instanceOf(UserDeleteAccountStore).isRequired,
}

export default observer(UserDeleteAccountForm)
