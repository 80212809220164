import React, { useState } from 'react'

import PropTypes from 'prop-types'

import { ButtonBase, CircularProgress } from '@material-ui/core'
import clsx from 'clsx'
import { observer } from 'mobx-react'

import { ImageZoom } from 'shared/components/organisms'
import { BrokenImage } from 'shared/icons'
import { imageType } from 'shared/types'

import style from './Image.module.scss'

const Image = ({ image, inProgress, ButtonProps, imgProps, testId }) => {
  const [isZoomed, setIsZoomed] = useState(false)

  if (!image) {
    return (
      <ButtonBase
        disabled
        className={style.ImageButton}
        data-testid={`${testId}Button`}
        {...ButtonProps}
      >
        <BrokenImage className={clsx(style.Image, imgProps?.className)} />
      </ButtonBase>
    )
  }

  return (
    <>
      <ButtonBase
        onClick={() => setIsZoomed(true)}
        className={style.ImageButton}
        disabled={!image?.url}
        {...ButtonProps}
        data-testid={testId ? `${testId}Button` : undefined}
      >
        <img
          data-testid={testId}
          data-test-state={inProgress ? 'in-progress' : 'done'}
          src={image.url || image.thumbnail}
          alt=""
          {...imgProps}
          className={clsx(style.Image, imgProps?.className)}
        />
        {inProgress && (
          <div className={style.ImageInProgress}>
            <CircularProgress size={32} disableShrink />
          </div>
        )}
      </ButtonBase>
      {!!image?.url && (
        <ImageZoom
          testId={testId ? `${testId}-zoom` : undefined}
          src={image.url}
          isZoomed={isZoomed}
          handleClose={() => setIsZoomed(false)}
        />
      )}
    </>
  )
}

Image.propTypes = {
  image: imageType,
  inProgress: PropTypes.bool,
  ButtonProps: PropTypes.object,
  imgProps: PropTypes.object,
  testId: PropTypes.string,
}

Image.defaultProps = {
  ButtonProps: {},
  imgProps: {},
}

export default observer(Image)
