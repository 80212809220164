import {
  viewProductDetailsClicked,
  designProductClicked,
  orderSampleClicked,
  removeProductClicked,
  addProductToSyncListClicked,
  autoPriceStatusChanged,
  unsyncProductClicked,
  exportToCsvClicked,
} from '@wix/bi-logger-modalyst/v2'
import head from 'lodash/head'
import pick from 'lodash/pick'
import { action, computed, observable } from 'mobx'

import { ALIBABA, ALIEXPRESS } from 'shared/constants/marketplace'
import { RETAILER_ITEM_PRINT_ON_DEMAND_TYPE } from 'shared/constants/retailerItems'
import { ImageStore, ProductVariantSimpleStore } from 'shared/stores'
import { getBiContext } from 'shared/stores/BiLoggerStore/utils'

import { PricingRangeStore } from 'retailer/stores'

/**
 * A base class for all stores based on a Retailer Product entity.
 */
class RetailerProductStore {
  /** @type {import('../root').RootStore} */
  root
  /** @type {import('../../types').ProductBiContext} */
  biContext

  uuid
  itemUuid
  itemExternalId
  inventoryType
  supplierId
  shippingIncludedInPrice
  productExternalUrl
  storeProductId
  isPremium
  source
  vendor
  retailerAuthorizationType
  created

  @observable name
  @observable status
  @observable description = ''
  @observable mainImage
  @observable options = []
  @observable images = []
  @observable priceLocked = false
  @observable pricingRanges = {}
  @observable currencyIso

  /** A read-only, simplified list of variants loaded from the main resource */
  @observable.shallow simpleVariants = []

  @observable createdFrom
  @observable customizedItemDraftRef
  @observable customizedItemJobRef
  @observable customEditorConfig

  /**
   * @param {import('../root').RootStore} root
   * @param {Object} data
   * @param {import('../../types').ProductBiContext} biContext
   */
  constructor (root, data, biContext) {
    this.root = root
    this.biContext = biContext
    this.assignData(data)
  }

  @action.bound assignData ({ variants, mainImage, pricingRanges, ...data }) {
    Object.assign(this, data)
    this.mainImage = mainImage ? new ImageStore(mainImage) : null
    this.simpleVariants = variants.map(v => new ProductVariantSimpleStore(this, v))
    this._assignPricingRanges(pricingRanges)
  }

  @action.bound
  _assignPricingRanges (ranges) {
    if (!ranges) return
    Object.keys(ranges).forEach(key => {
      this.pricingRanges[key] = new PricingRangeStore(ranges[key])
    })
  }

  @action.bound assignSimpleVariants (variants) {
  }

  @computed get isPrintOnDemand () {
    return this.inventoryType === RETAILER_ITEM_PRINT_ON_DEMAND_TYPE
  }

  @computed get isAliExpress () {
    return this.inventoryType === ALIEXPRESS
  }

  @computed get isAlibaba () {
    return this.inventoryType === ALIBABA
  }

  @computed get is3rdPartySupplier () {
    return this.isAliExpress || this.isAlibaba
  }

  @computed get thirdPartySupplierName () {
    if (!this.is3rdPartySupplier) return
    if (this.isAliExpress) return 'Aliexpress'
    if (this.isAlibaba) return 'Alibaba'
  }

  @computed get thirdPartySupplierUrl () {
    return this.is3rdPartySupplier
      ? this.productExternalUrl
      : undefined
  }

  @computed get inactiveVariantsIds () {
    return this.simpleVariants.filter(v => !v.active).map(v => v.id)
  }

  @action.bound
  async orderSample () {
    const biEvent = await this.root.biLoggerStore.log(orderSampleClicked(this.biEventData))
    if (this.is3rdPartySupplier) this.root.routerStore.openInBlank(this.thirdPartySupplierUrl)
    else this.root.manualOrderStore.start(this.itemUuid, this.uuid, getBiContext(biEvent))
  }

  get _biEventData () {
    const shippingRate = head(this.simpleVariants)?.shipping
    return {
      ...this.biContext, // origin, position etc
      retailerProductId: this.uuid,
      marketplace: this.isPrintOnDemand ? 'POD' : 'RTS',
      modalystProductId: this.itemUuid,
      supplierProductId: this.itemExternalId || null,
      supplierId: this.supplierId,
      productName: this.name,
      productDescription: this.description,
      mainImageUrl: this.mainImage?.thumbnail || this.mainImage?.url || null,
      imagesNum: this.images.length, // XXX: all images (including deselected)
      isDynamicPrice: !this.priceLocked,
      retailerVariantIds: this.simpleVariants.map(v => v.id),
      variantsCount: this.simpleVariants.length,
      itemsCost: Object.fromEntries(this.simpleVariants.map(v => [v.id, v.cost])),
      retailerPrices: Object.fromEntries(this.simpleVariants.map(v => [v.id, v.price])),
      isShippingInCost: this.shippingIncludedInPrice,
      shippingRate: shippingRate ? parseFloat(shippingRate) : null,
      options: this.options.map(o => pick(o, ['uuid', 'key', 'value'])),
      hiddenVariantsCount: this.inactiveVariantsIds.length,
      hiddenVariantIds: this.inactiveVariantsIds,
      modalystVariantIds: this.simpleVariants.map(v => v.productVariantId), // TODO: to confirm discrepancy with BE
      currency: this.currencyIso,

      isBulk: false,

      // TODO: data received from POD editor in "edit/customize -> save to import list" scenario
      podDistinctImagesUploaded: undefined,
      podTotalImagesUploaded: undefined,
      podImagesDetails: undefined,
      podTextAdded: undefined,
    }
  }

  @computed get biEventData () {
    return this._biEventData
  }

  @action.bound
  editDesign () {
    this.root.itemCustomizerStore.editDesign({
      productId: this.itemUuid,
      externalId: this.itemExternalId,
      id: this.uuid,
      status: this.status,
      createdFrom: this.createdFrom,
      draftRef: this.customizedItemDraftRef,
      customEditorConfig: this.customEditorConfig,
      productName: this.name,
      storeProductId: this.storeProductId,
      biContext: this.biContext,
    })
  }

  @action.bound
  async logViewDetailsClickedBiEvent () {
    return await this.root.biLoggerStore.log(viewProductDetailsClicked(this.biEventData))
  }

  @action.bound
  async logDesignClickedBiEvent () {
    return await this.root.biLoggerStore.log(designProductClicked(this.biEventData))
  }

  @action.bound
  async logAutoPriceStatusChanged (params = {}) {
    return await this.root.biLoggerStore.log(autoPriceStatusChanged({ ...this.biEventData, ...params }))
  }

  @action.bound
  async logAddProductToSyncListClicked (params = {}) {
    return await this.root.biLoggerStore.log(addProductToSyncListClicked({ ...this.biEventData, ...params }))
  }

  @action.bound
  async logUnsyncProductClicked (params = {}) {
    return await this.root.biLoggerStore.log(unsyncProductClicked({ ...this.biEventData, ...params }))
  }

  @action.bound
  async logRemoveProductClicked (params = {}) {
    return await this.root.biLoggerStore.log(removeProductClicked({ ...this.biEventData, ...params }))
  }

  @action.bound
  async logExportToCsvClicked (params = {}) {
    return await this.root.biLoggerStore.log(exportToCsvClicked({ ...this.biEventData, ...params }))
  }
}

export default RetailerProductStore
