import React from 'react'

import PropTypes from 'prop-types'

import { Typography, Button, LinearProgress } from '@material-ui/core'
import { ArrowForwardRounded, ReportProblemOutlined } from '@material-ui/icons'
import { upgradeStoreClicked } from '@wix/bi-logger-modalyst/v2'
import clsx from 'clsx'
import { observer } from 'mobx-react'

import { BiLink } from 'retailer/components/atoms'
import { RetailerSubscription } from 'retailer/stores'
import { useStores } from 'retailer/stores/hooks'

import style from './RetailerSubscriptionBox.module.scss'

const ProductLimit = ({ warning, productsLeft, isLimitless, testId }) => {
  if (isLimitless) {
    return (
      <Typography
        variant="body2"
        className={clsx(style.LineElement, warning && style.Warning)}
        data-testid={testId}
      >
        Unlimited products
      </Typography>
    )
  }
  return (
    <Typography
      variant="body2"
      className={clsx(style.LineElement, warning && style.Warning)}
      data-testid={testId}
    >
      {warning && (
        <ReportProblemOutlined
          fontSize="small"
          className={style.WarningIcon}
        />
      )}
      {productsLeft} products left
    </Typography>
  )
}

ProductLimit.propTypes = {
  isLimitless: PropTypes.bool,
  warning: PropTypes.bool,
  productsLeft: PropTypes.number,
  testId: PropTypes.string
}

const RetailerSubscriptionBox = ({ subscription }) => {
  // TODO: move required properties to StoreStore, use instead of RetailerSubscription
  const { userProfileStore: { currentStore } } = useStores()
  const { planName, productsLeft, isLimitless, warning, loading } = subscription
  return (
    <Button
      disabled={loading.isLoading}
      data-testid="retailerSubscriptionBox-button"
      component={BiLink} to="/settings/manage-store-plan"
      biData={() => upgradeStoreClicked({
        origin: 'main navigation',
        storeName: currentStore.name,
        targetStoreId: currentStore.uuid,
        numProductsLeft: currentStore.productsLeft,
      })}
      variant="outlined"
      className={style.Wrapper}
    >
      <div className={style.RetailerSubscriptionBox}>
        {loading.isLoading || !loading.isLoaded
          ? (
            <LinearProgress className={style.Loader} />
            )
          : (
            <>
              <Typography
                variant="h6"
                data-testid="retailerSubscriptionBox-currentPlanText"
                className={style.PlanName}
              >
                {planName} plan
              </Typography>
              <ProductLimit
                warning={warning}
                productsLeft={productsLeft}
                isLimitless={isLimitless}
                testId="retailerSubscriptionBox-currentPlanProductLimit"
              />
              {warning && (
                <Typography variant="body2" className={style.LineElement}>
                  Upgrade account
                  <ArrowForwardRounded
                    fontSize="small"
                    className={style.ArrowIcon}
                  />
                </Typography>
              )}
            </>
            )}
      </div>
    </Button>
  )
}

RetailerSubscriptionBox.propTypes = {
  subscription: PropTypes.instanceOf(RetailerSubscription).isRequired
}

export default observer(RetailerSubscriptionBox)
