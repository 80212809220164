/* eslint camelcase: 0 */

import { keysToCamelCase, keysToSnakeCase } from 'shared/utils/api'

const adaptAuthorizationCheckRequest = data => keysToSnakeCase(data)

const adaptAuthorizationCheckResponse = data => keysToCamelCase(data)

const adaptAuthorizationCheckErrors = ({ non_field_errors, ...data }) => ({
  nonFieldErrors: non_field_errors,
  fieldErrors: {
    integrationType: data.integration_type,
    integrationProfileType: data.integration_profile_type,
    storeName: data.store_name,
  }
})

const adaptCreateRetailerAuthorizationRequest = keysToSnakeCase

const adaptCreateRetailerAuthorizationResponse = data => keysToCamelCase(data)

const adaptCreateRetailerAuthorizationErrors = ({ non_field_errors, ...data }) => ({
  nonFieldErrors: non_field_errors,
  fieldErrors: {
    integrationType: data.integration_type,
    integrationProfileType: data.integration_profile_type,
    storeName: data.store_name,
    origin: data.origin,
    authorizationData: data.authorization_data,
  }
})

const adaptGetSupplierAppAuthorizationResponse = data => keysToCamelCase(data)

export {
  adaptAuthorizationCheckRequest,
  adaptAuthorizationCheckResponse,
  adaptAuthorizationCheckErrors,
  adaptCreateRetailerAuthorizationRequest,
  adaptCreateRetailerAuthorizationResponse,
  adaptCreateRetailerAuthorizationErrors,
  adaptGetSupplierAppAuthorizationResponse,
}
