import PropTypes from 'prop-types'

import { useAnalytics } from 'shared/analytics'

/**
 * Report React Router location changes to Google Analytics
 *
 * Needs to be placed inside the Router Component.
 */
const RouteTracker = ({ trackingId, debug }) => {
  useAnalytics(trackingId, debug)
  return null
}

RouteTracker.propTypes = {
  trackingId: PropTypes.string,
  debug: PropTypes.bool,
}

RouteTracker.defaultProps = {
  debug: false,
}

export default RouteTracker
