import { autoPriceStatusChanged, removeProductClicked, unsyncProductClicked } from '@wix/bi-logger-modalyst/v2'
import { action, computed, observable } from 'mobx'

import { adaptSyncListApiParams } from 'shared/adapters/retailerItems'
import {
  getRetailerItemsIds, lockRetailerItemPrice,
  moveRetailerItemToImportList,
  removeRetailerItem,
  unlockRetailerItemPrice,
} from 'shared/api/retailerItems'
import {
  ACTION_AUTO_PRICE_OFF,
  ACTION_AUTO_PRICE_ON,
  ACTION_MOVE_TO_IMPORT_LIST,
  ACTION_REMOVE_FROM_STORE
} from 'shared/constants/retailerItems'
import { IteratingBatchActionStore } from 'shared/stores'
import { getBiHeadersFromEvent } from 'shared/stores/BiLoggerStore/utils'

class SyncListBatchActionStore extends IteratingBatchActionStore {
  root

  @observable syncListStore
  @observable action
  @observable biHeaders

  constructor (root, syncListStore) {
    super()
    this.root = root
    this.syncListStore = syncListStore
  }

  /**
   * @returns {(function(*): Promise<AxiosResponse<any>>) | *}
   */
  @computed get actionFunc () {
    const actionsMap = new Map([
      [ACTION_MOVE_TO_IMPORT_LIST, moveRetailerItemToImportList],
      [ACTION_REMOVE_FROM_STORE, removeRetailerItem],
      [ACTION_AUTO_PRICE_ON, unlockRetailerItemPrice],
      [ACTION_AUTO_PRICE_OFF, lockRetailerItemPrice]
    ])
    return actionsMap.get(this.action)
  }

  @action.bound
  startAction (action, biHeaders) {
    this.action = action
    this.biHeaders = biHeaders
    return this.initialize({
      onError: e => console.error(e),
      after: () => this.syncListStore.fetch()
    })
  }

  @action.bound
  async run () {
    const biEvent = await this.logBiEvent()
    this.biHeaders = getBiHeadersFromEvent(biEvent)
    await this.asyncHandlerStore.run()
  }

  @action.bound
  async processObject (obj) {
    if (!this.actionFunc) throw Error(`Action '${this.action}' not implemented!`)
    return await this.actionFunc(obj, this.biHeaders)
  }

  @action.bound
  async getObjects () {
    if (!this.syncListStore.allOnAllPagesSelected) {
      // Current page items only
      return Promise.resolve(this.syncListStore.selectedItems.map(item => item.id))
    }
    const allIdsResponse = await getRetailerItemsIds(adaptSyncListApiParams(this.syncListStore.apiParams))
    return Promise.resolve(allIdsResponse.data)
  }

  @action.bound
  async logBiEvent () {
    const listStore = this.syncListStore
    const params = { ...listStore.biContext, isBulk: true }
    switch (this.action) {
      case ACTION_AUTO_PRICE_OFF: case ACTION_AUTO_PRICE_ON:
        params.status = this.action === ACTION_AUTO_PRICE_ON
        params.selectionType = `auto price ${params.status ? 'on' : 'off'} button`
        return listStore.oneSelected
          ? await listStore.firstSelected.logAutoPriceStatusChanged(params)
          : await this.root.biLoggerStore.log(autoPriceStatusChanged(params))
      case ACTION_MOVE_TO_IMPORT_LIST: // set for "Unsync"!
        return listStore.oneSelected
          ? await listStore.firstSelected.logUnsyncProductClicked(params)
          : await this.root.biLoggerStore.log(unsyncProductClicked(params))
      case ACTION_REMOVE_FROM_STORE:
        return listStore.oneSelected
          ? await listStore.firstSelected.logRemoveProductClicked(params)
          : await this.root.biLoggerStore.log(removeProductClicked(params))
      default: return undefined
    }
  }

  @action.bound
  reset () {
    super.reset()
    this.action = undefined
    this.biHeaders = undefined
  }
}

export default SyncListBatchActionStore
