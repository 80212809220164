import React from 'react'

import PropTypes from 'prop-types'

import { Grid, IconButton, Paper, Tooltip, Typography, useMediaQuery, useTheme } from '@material-ui/core'
import { CloseRounded } from '@material-ui/icons'
import clsx from 'clsx'
import { observer } from 'mobx-react'

import { Money } from 'shared/components/atoms'

import { QuantityChanger } from 'retailer/components/molecules'

import style from './ManualOrderVariant.module.scss'

const VariantPriceSum = observer(({ price, quantity, currency, testId }) => {
  switch (quantity) {
    case 0: return <Money amount={0} currency={currency} testId={testId ? `${testId}-singleVariantCost` : undefined} />
    case 1: return <Money amount={price} currency={currency} testId={testId ? `${testId}-singleVariantCost` : undefined} />
    default: return (
      <>
        {quantity} × <Money amount={price} currency={currency} testId={testId ? `${testId}-singleVariantCost` : undefined} />{' '}
        =&nbsp;<Money amount={quantity * price} currency={currency} testId={testId ? `${testId}-multipliedVariantCost` : undefined} />
      </>
    )
  }
})

VariantPriceSum.propTypes = {
  price: PropTypes.number.isRequired,
  quantity: PropTypes.number.isRequired,
  currency: PropTypes.string.isRequired,
  testId: PropTypes.string,
}

const ManualOrderVariant = ({ variant, currency, disabled, testId }) => {
  const theme = useTheme()
  const mdUp = useMediaQuery(theme.breakpoints.up('md'))
  const {
    sku, image, price, options,
    quantity, quantityToOrder, setQuantityToOrder,
  } = variant

  const handleQuantityChange = value => {
    setQuantityToOrder(Math.max(0, Math.min(quantityToOrder + value, quantity)))
  }

  return (
    <Paper
      className={clsx(style.ManualOrderVariant, disabled && style.disabled, !!quantityToOrder && style.added)}
      data-testid={testId}
    >
      {!!quantityToOrder && (
        <Tooltip title="Remove variant from the order">
          <IconButton
            data-testid={testId ? `${testId}-removeVariantButton` : undefined}
            size="small"
            className={style.RemoveButton}
            onClick={() => setQuantityToOrder(0)}
            disabled={disabled}
          >
            <CloseRounded />
          </IconButton>
        </Tooltip>
      )}
      <Grid container spacing={2} alignItems="center">
        {mdUp && (
          <Grid item xs={12} md={1}>
            <div className={style.ImageWrapper}>
              {image && <img src={image} className={style.Image} alt="" />}
            </div>
          </Grid>
        )}
        <Grid item xs md={5}>
          <Typography variant="body1" className={style.ItemName} data-testid={testId ? `${testId}-variantOptions` : undefined}>
            {options.map(({ value }) => value).join(', ')}<br />
          </Typography>
          <Typography variant="body2" data-testid={testId ? `${testId}-variantSku` : undefined}>
            SKU {sku}
          </Typography>
        </Grid>
        <Grid item xs="auto" md={4}>
          <QuantityChanger
            testId={testId ? `${testId}-quantityChanger` : undefined}
            quantity={quantityToOrder}
            maxQuantity={quantity}
            onChange={handleQuantityChange}
            vertical={!mdUp}
            disabled={disabled}
          />
        </Grid>
        <Grid item xs={12} md={2} className={style.Price}>
          {!mdUp && (
            <Typography variant="body1" className={style.Sum}>
              Sum:
            </Typography>
          )}
          <VariantPriceSum
            price={price} quantity={quantityToOrder} currency={currency}
            testId={testId ? `${testId}-variantPriceSum` : undefined}
          />
        </Grid>
      </Grid>
    </Paper>
  )
}

ManualOrderVariant.propTypes = {
  variant: PropTypes.shape({
    id: PropTypes.string.isRequired,
    options: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.string
      })
    ),
    sku: PropTypes.string.isRequired,
    price: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    image: PropTypes.string.isRequired,
    quantity: PropTypes.number.isRequired,
    quantityToOrder: PropTypes.number.isRequired,
    setQuantityToOrder: PropTypes.func.isRequired,
  }).isRequired,
  currency: PropTypes.string,
  disabled: PropTypes.bool,
  testId: PropTypes.string,
}

ManualOrderVariant.defaultProps = {
  currency: 'USD',
  disabled: false
}

export default observer(ManualOrderVariant)
