/**
 * If you need to programmatically start a download of a file,
 * you can use this little helper to do so. Just provide the URL to download the file
 * and a fileName for the downloaded file.
 * @param {string} url
 * @param {string} fileName
 */
const downloadFromUrl = (url, fileName) => {
  const a = document.createElement('a')
  a.href = url
  a.download = fileName || 'download'
  a.click()
  a.remove()
}

export {
  downloadFromUrl
}
