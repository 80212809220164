import { exportToCsvClicked } from '@wix/bi-logger-modalyst/v2'
import { action, computed, observable } from 'mobx'

import { adaptCsvExportForApi, adaptCsvExportTypes } from 'shared/adapters/csv'
import { exportCsv, getCsvExportTypes } from 'shared/api/csv'
import { getRetailerItemsIds } from 'shared/api/retailerItems'
import { LoadableStore } from 'shared/stores'
import { getBiHeadersFromEvent } from 'shared/stores/BiLoggerStore/utils'

class SyncListCsvExportStore {
  /** @type {import('../context').RootStore} */
  root

  @observable syncListStore
  @observable open = false
  @observable loading = new LoadableStore()
  @observable isExporting = false
  @observable isSuccess = false
  @observable error = null

  @observable choices = [] // FIXME: refactor with #182547656
  @observable selectedFormat = ''

  /**
   * @param {import('../context').RootStore} root
   * @param {import('../index').SyncListPageStore} syncListStore
   */
  constructor (root, syncListStore) {
    this.root = root
    this.syncListStore = syncListStore
  }

  @computed get selectedCount () {
    if (this.syncListStore.selectedAllOnAllPages) return this.syncListStore.pagination.totalCount
    return this.syncListStore.selectedCount
  }

  @computed get canBeExported () {
    return this.loading.isLoaded && this.selectedFormat !== ''
  }

  _fetchAllIds () {
    return getRetailerItemsIds(this.syncListStore.apiQueryParams).then(response => {
      return response.data
    })
  }

  _getIds () {
    return new Promise((resolve, reject) => {
      if (!this.syncListStore.selectedAllOnAllPages) {
        resolve(
          this.syncListStore.selectedItems.map(item => item.id) // Current page
        )
      }

      return this._fetchAllIds()
        .then(allIds => resolve(allIds))
        .catch(error => reject(error))
    })
  }

  @action.bound
  setOpen (value) {
    this.open = Boolean(value)
  }

  @action.bound
  reset () {
    this.setOpen(false)
    this.loading.reset()
    this.isExporting = false
    this.isSuccess = false
    this.error = null
    this.choices = []
    this.selectedFormat = ''
  }

  @action.bound
  setSelectedFormat (value) {
    this.selectedFormat = value
  }

  @action.bound
  fetchChoices () {
    // FIXME: refactor with #182547656
    this.loading.setLoading()
    return getCsvExportTypes().then(response => {
      this.choices = adaptCsvExportTypes(response.data)
      this.loading.setLoaded()
    })
  }

  @action.bound
  async export () {
    this.isExporting = true
    try {
      const biParams = { ...this.syncListStore.biContext, isBulk: true }
      const biEvent = this.syncListStore.oneSelected
        ? await this.syncListStore.firstSelected.logExportToCsvClicked(biParams)
        : await this.root.biLoggerStore.log(exportToCsvClicked(biParams))
      const itemIds = await this._getIds()
      const data = { filetype: this.selectedFormat, itemIds, allNewItems: false, allPublishedItems: false }
      await exportCsv(adaptCsvExportForApi(data), getBiHeadersFromEvent(biEvent))
      this.isSuccess = true
      this.isExporting = false
    } catch (e) {
      this.isExporting = false
      this.error = e
      throw e
    }
  }
}

export default SyncListCsvExportStore
