import React, { useEffect, useRef, useState } from 'react'

import PropTypes from 'prop-types'

import { Box, InputBase, IconButton, Fade, Divider } from '@material-ui/core'
import { Clear, SearchOutlined } from '@material-ui/icons'
import { observer } from 'mobx-react'

import { useTranslation } from 'shared/hooks'

import style from './ProductSearchForm.module.scss'

const ProductSearchForm = ({ marketplaceStore, ...boxProps }) => {
  const { t } = useTranslation('marketplace')
  const searchInputRef = useRef(null)

  const { search, setSearch, setSearchStartTime } = marketplaceStore

  const [searchInputValue, setSearchInputValue] = useState(search || '')

  useEffect(() => {
    setSearchInputValue(search || '')
  }, [search])

  const handleSearchInputChange = event => setSearchInputValue(event.target.value)
  const handleClearClick = () => {
    setSearchInputValue('')
    setSearch('')
  }
  const handleSubmit = event => {
    event.preventDefault()
    setSearchStartTime(performance.now())
    setSearch(searchInputValue)
  }

  return (
    <Box
      component="form"
      onSubmit={handleSubmit}
      className={style.ProductSearchForm}
      {...boxProps}
    >
      <InputBase
        color="primary"
        value={searchInputValue}
        onChange={handleSearchInputChange}
        placeholder={t('main.search.placeholder')}
        inputRef={searchInputRef}
        data-testid="ProductSearchForm-primarySearchInput"
        endAdornment={
          <>
            <Fade in={!!searchInputValue}>
              <Box display="flex" alignItems="center">
                <IconButton
                  type="button" size="small" onClick={handleClearClick}
                  data-testid="ProductSearchForm-primarySearchInput-clearButton"
                >
                  <Clear fontSize="small" />
                </IconButton>
                <Divider orientation="vertical" flexItem />
              </Box>
            </Fade>
            <IconButton type="submit" size="small" data-testid="ProductSearchForm-primarySearchInput-submitButton">
              <SearchOutlined />
            </IconButton>
          </>
        }
        className={style.SearchInput}
      />
    </Box>
  )
}

ProductSearchForm.propTypes = {
  marketplaceStore: PropTypes.shape({
    search: PropTypes.string,
    setSearch: PropTypes.func.isRequired,
    setSearchStartTime: PropTypes.func.isRequired,
  }),
}

export default observer(ProductSearchForm)
