import React from 'react'

import PropTypes from 'prop-types'

import { Box, Button, Card, CardContent, CardMedia, Collapse, IconButton, Typography } from '@material-ui/core'
import { Close } from '@material-ui/icons'
import clsx from 'clsx'

import { Wix } from 'shared/icons/external'

import { UserFlags } from 'retailer/constants'
import { useStores } from 'retailer/stores/hooks'

import PrintfulLogo from './PrintfulLogo'
import ThinPlus from './ThinPlus'
import wixPrintOnDemandBannerImg from './wix-print-on-demand.png'
import s from './WixPrintOnDemandBanner.module.scss'

const WixPrintOnDemandBanner = ({ className, ...boxProps }) => {
  const { userProfileStore } = useStores()
  const [isDismissed, setIsDismissed] = React.useState(false)

  const wixDashboardUrl = userProfileStore.currentStore.storeInstallation.siteAdminUrl
  const url = wixDashboardUrl ? `${wixDashboardUrl}/wix-stores/explore-products` : 'https://manage.wix.com/'

  const handleDismissed = async () => {
    await userProfileStore.setContextAttribute(UserFlags.DISMISSED_WIX_PRINT_ON_DEMAND_BANNER, true)
  }

  return (
    <Collapse in={!isDismissed} onExited={handleDismissed}>
      <Card component={Box} className={clsx(s.WixPrintOnDemandBanner, className)} {...boxProps}>
        <IconButton
          size="small" className={s.DismissButton}
          aria-label="dismiss"
          onClick={() => setIsDismissed(true)}
        >
          <Close />
        </IconButton>
        <CardMedia component={Box} image={wixPrintOnDemandBannerImg} title="Wix Print on Demand" />
        <CardContent pl={0}>
          <div className={s.WixPlusPrintful}><Wix /><i><ThinPlus /></i><PrintfulLogo /></div>
          <Typography variant="h5" gutterBottom>
            Source, design and sell your print on demand products on Wix
          </Typography>
          <Typography variant="body2" paragraph>
            A new way to add products to your store and customize them for your own unique designs.
          </Typography>
          <Button href={url} target="_blank" variant="contained" color="primary">Go to Wix</Button>
        </CardContent>
      </Card>
    </Collapse>
  )
}

WixPrintOnDemandBanner.propTypes = {
  className: PropTypes.string,
}

export default WixPrintOnDemandBanner
