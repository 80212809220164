import React from 'react'

import PropTypes from 'prop-types'

import { AppBar as MuiAppBar, Box, IconButton, Toolbar } from '@material-ui/core'
import { Menu } from '@material-ui/icons'
import { observer } from 'mobx-react'
import { Link as RouterLink } from 'react-router-dom'

import { UserProfileMenu } from 'shared/components/molecules'
import { LogoRetailer, Sign } from 'shared/icons'

import { StoreSelectorMenu } from 'retailer/components/organisms'
import { useStores } from 'retailer/stores/hooks'
import { useIsDesktop } from 'retailer/theme/hooks'

import style from './AppBar.module.scss'

const MobileToolbar = observer(({ userProfileStore, onMenuToggleClick }) => {
  return (
    <Toolbar variant="dense" component={Box} justifyContent="space-between">
      <IconButton
        edge="start"
        color="inherit"
        aria-label="menu"
        data-testid="appBar_menuButton"
        onClick={onMenuToggleClick}
      >
        <Menu />
      </IconButton>
      {userProfileStore.ready && (
        <StoreSelectorMenu userProfileStore={userProfileStore} />
      )}
      <Sign className={style.SignLogo} />
    </Toolbar>
  )
}
)

MobileToolbar.propTypes = {
  onMenuToggleClick: PropTypes.func,
  userProfileStore: PropTypes.shape({
    ready: PropTypes.bool.isRequired,
  }).isRequired,
}

const DesktopToolbar = observer(({ userProfileStore, disableStoreSelector }) => {
  return (
    <Toolbar>
      <RouterLink to="/" className={style.LogoLink}>
        <LogoRetailer height={27} />
      </RouterLink>
      {userProfileStore.ready && (
        <Box
          flexGrow={1} ml={4}
          display="flex" flexDirection="row-reverse" justifyContent="space-between"
        >
          <UserProfileMenu userProfileStore={userProfileStore} />
          {!disableStoreSelector && (
            <StoreSelectorMenu userProfileStore={userProfileStore} />
          )}
        </Box>
      )}
    </Toolbar>
  )
})

DesktopToolbar.propTypes = {
  disableStoreSelector: PropTypes.bool,
  userProfileStore: PropTypes.shape({
    ready: PropTypes.bool.isRequired,
  }).isRequired,
}

/**
 * Retailer's main App Bar
 *
 * @param {import('@material-ui/core/AppBar/index').AppBarProps} props
 */
const AppBar = ({ disableStoreSelector, ...props }) => {
  const { mainMenuStore, userProfileStore } = useStores()
  const isDesktop = useIsDesktop()
  const color = isDesktop ? 'default' : 'secondary'

  const handleSidebarToggle = mainMenuStore.toggle

  return (
    <MuiAppBar position="fixed" color={color} className={style.AppBar} {...props}>
      {isDesktop
        ? (
          <DesktopToolbar
            userProfileStore={userProfileStore}
            disableStoreSelector={disableStoreSelector}
          />
          )
        : (
          <MobileToolbar
            onMenuToggleClick={handleSidebarToggle}
            userProfileStore={userProfileStore}
          />
          )}
    </MuiAppBar>
  )
}

AppBar.propTypes = {
  disableStoreSelector: PropTypes.bool,
}

export default observer(AppBar)
