import { observable } from 'mobx'

/**
 * A store class representing a relation with a specific retailer
 */
class RetailerStore {
  @observable id
  @observable uuid
  @observable name
  @observable location
  @observable productsSyncedCount
  @observable productsImportedCount
  @observable ordersPaidCount
  @observable ordersPaidValue
  @observable accepted

  constructor (data = {}) {
    Object.assign(this, data)
  }
}

export default RetailerStore
