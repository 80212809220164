import React from 'react'

import PropTypes from 'prop-types'

import {
  Box,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Switch
} from '@material-ui/core'
import isEmpty from 'lodash/isEmpty'
import { observer } from 'mobx-react'

import { SearchField } from 'shared/components/organisms'
import { FilterStore, SearchStore } from 'shared/stores'

import style from './ArchiveListFilters.module.scss'

const ArchiveListFilters = ({ searchFilter, removalReasonFilter, nonRestorableFilter }) => {
  const handleSearchSubmit = value => {
    if (!isEmpty(value)) {
      searchFilter.setFilter(value)
    } else {
      searchFilter.clearFilter()
    }
  }

  return (
    <div className={style.ArchiveListFilters}>
      <Grid container spacing={4}>
        <Grid item xs={12} sm={8} lg={9}>
          <SearchField
            onSubmit={handleSearchSubmit}
            name="search"
            variant="outlined"
            label="Search Products"
            defaultValue={searchFilter.activeValue}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={4} lg={3}>
          <FormControl variant="outlined" fullWidth>
            <InputLabel id="removalReason">Removal Reason</InputLabel>
            <Select
              labelId="removalReason"
              value={removalReasonFilter.activeValue}
              onChange={e => removalReasonFilter.setFilter(e.target.value)}
              label="Removal Reason"
              fullWidth
              className={style.Input}
            >
              {Array.from(removalReasonFilter.data.valuesMap.entries()).map(([key, value]) => {
                return (
                  <MenuItem key={key} value={key}>{value}</MenuItem>
                )
              })}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      <Box className={style.RestorableSwitcher}>
        <FormControlLabel
          control={
            <Switch
              onChange={() => nonRestorableFilter.toggle()}
              checked={nonRestorableFilter.active}
              color="primary"
            />
          }
          label="Show non-restorable products"
          labelPlacement="start"
        />
      </Box>
    </div>
  )
}

ArchiveListFilters.propTypes = {
  searchFilter: PropTypes.instanceOf(SearchStore),
  removalReasonFilter: PropTypes.instanceOf(FilterStore),
  nonRestorableFilter: PropTypes.instanceOf(FilterStore)
}

export default observer(ArchiveListFilters)
