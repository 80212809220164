import { init, configureScope } from '@sentry/react'
import { Integrations } from '@sentry/tracing'

export function initSentry (appName) {
  const dsn = process.env.REACT_APP_SENTRY_DSN

  if (process.env.NODE_ENV === 'production' && dsn) {
    init({
      dsn,
      integrations: [
        new Integrations.BrowserTracing(),
      ],
      release: COMMITHASH, // set in webpack config
      tracesSampleRate: 1.0,
    })

    configureScope((scope) => {
      scope.setTag('app_name', appName)
    })
  }
}
