import React from 'react'

import PropTypes from 'prop-types'

import { Box, CardContent, Link, MenuItem, Select, Typography } from '@material-ui/core'
import { observer } from 'mobx-react'
import { Trans } from 'react-i18next'

import { CountryFlag, Money } from 'shared/components/atoms'
import { useTranslation } from 'shared/hooks'

import { getExternalUrlProps } from 'retailer/components/utils'

import style from './ProductSlateShippingSection.module.scss'

const localPropTypes = {
  shippingCountry: PropTypes.shape({
    countryCode: PropTypes.string.isRequired,
    countryName: PropTypes.string.isRequired,
    shippable: PropTypes.bool.isRequired,
    shippingCost: PropTypes.number,
    shippingTime: PropTypes.string,
  }),
}

const ThirdPartyShipping = observer(({ shipping, t }) => (
  <Typography
    component={Link} variant="inherit"
    className={style.ThirdPartyShipping}
    {...getExternalUrlProps(shipping.thirdPartyRatesUrl)}
  >
    {t('productShipping.viewThirdPartyPlatformRates.cta', { name: shipping.thirdPartyName })}
  </Typography>
))

ThirdPartyShipping.propTypes = {
  shipping: PropTypes.shape({
    thirdPartyName: PropTypes.string.isRequired,
    thirdPartyRatesUrl: PropTypes.string.isRequired,
  }).isRequired,
  t: PropTypes.func.isRequired,
}

const Shipping = observer(({ shipping, currency, t }) => {
  const {
    countries,
    selectCountry,
    activeCountry: { shippable, shippingTime, shippingCost, countryCode, countryName },
  } = shipping

  return (
    <>
      <Select
        value={countryCode}
        renderValue={iso => <CountryFlag isoCode={iso} height={12} />}
        onChange={e => selectCountry(e.target.value)}
        className={style.ShippingCountrySelect}
        data-testid="ProductSlate-shippingSection-countrySelect"
        data-test-state={countryName}
      >
        {countries.map(country => {
          return (
            <MenuItem value={country.countryCode} key={country.countryCode}>
              <CountryFlag isoCode={country.countryCode} height={12} />
              <Box ml={1}>{country.countryName}</Box>
            </MenuItem>
          )
        })}
      </Select>
      <Typography
        component="div"
        variant="inherit" color="textSecondary"
        className={style.ShippingTime}
        data-testid="ProductSlate-shippingSection-shippingInfo"
      >
        {shippable
          ? (
            <Trans
              t={t} i18nKey="productShipping.shipsToCountry.label"
              values={{ shippingTime }}
              components={{
                money: <Money
                  amount={shippingCost} currency={currency}
                  testId="ProductSlate-shippingSection-shippingInfo-shippingPrice"
                       />
              }}
            />
            )
          : t('productShipping.doesNotShipToCountry.label', { countryName })}
      </Typography>
    </>
  )
})

Shipping.propTypes = {
  shipping: PropTypes.shape({
    countries: PropTypes.arrayOf(localPropTypes.shippingCountry).isRequired,
    activeCountry: localPropTypes.shippingCountry.isRequired,
    selectCountry: PropTypes.func.isRequired,
  }).isRequired,
  currency: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
}

const UsOnlyShipping = observer(({ t }) => {
  return (
    <>
      <CountryFlag isoCode="US" height={24} className={style.UsShippingFlag} />
      <Typography
        component="div"
        variant="inherit" color="textSecondary"
        className={style.ShippingTime}
      >
        {t('productShipping.usOnlyShipping.label', { numberOfBusinessDays: '4-6' })}
      </Typography>
    </>
  )
})

UsOnlyShipping.propTypes = {
  t: PropTypes.func.isRequired,
}

const ProductSlateShippingSection = ({ shipping, currency, isUsOnly }) => {
  const { t } = useTranslation('marketplace')

  return (
    <CardContent
      component={Box}
      display="flex" alignItems="center" borderTop={1} borderColor="divider" height={56} py={3 / 4}
    >
      {shipping.isExternal
        ? <ThirdPartyShipping shipping={shipping} t={t} />
        : isUsOnly
          ? <UsOnlyShipping t={t} />
          : <Shipping shipping={shipping} currency={currency} t={t} />}
    </CardContent>
  )
}

ProductSlateShippingSection.propTypes = {
  shipping: PropTypes.shape({
    isExternal: PropTypes.bool.isRequired
  }).isRequired,
  currency: PropTypes.string.isRequired,
  isUsOnly: PropTypes.bool,
}

ProductSlateShippingSection.defaultProps = {
  isUsOnly: false
}

export default observer(ProductSlateShippingSection)
