/* eslint camelcase: 0 */

const { keysToCamelCase, keysToSnakeCase, adaptFieldArrayErrors } = require('shared/utils/api')

const adaptProductVariant = data => keysToCamelCase(data)

const adaptProduct = ({ variants, sync_meta, ...rest }) => ({
  variants: variants && variants.map(row => adaptProductVariant(row)),
  syncMeta: sync_meta && keysToCamelCase(sync_meta),
  ...keysToCamelCase(rest)
})

const adaptGetProductResponse = adaptProduct

const adaptPutProductRequest = ({ variants, ...rest }) => ({
  variants: variants ? variants.map(row => keysToSnakeCase(row)) : undefined,
  ...keysToSnakeCase(rest)
})

const adaptPutProductErrors = ({ non_field_errors, variants, ...rest }) => ({
  nonFieldErrors: non_field_errors,
  fieldErrors: {
    variants: adaptFieldArrayErrors(variants, keysToCamelCase),
    ...keysToCamelCase(rest)
  },
})

const adaptPostProductRequest = adaptPutProductRequest
const adaptPostProductErrors = adaptPutProductErrors

const adaptGetProductsResponse = ({ results, ...meta }) => ({
  results: results.map(row => adaptProduct(row)),
  ...keysToCamelCase(meta)
})

const adaptBulkActionResponse = data => keysToCamelCase(data)

const adaptGetProductCountersResponse = data => keysToCamelCase(data)

export {
  adaptProduct,
  adaptGetProductResponse,
  adaptGetProductsResponse,
  adaptPutProductRequest,
  adaptPostProductRequest,
  adaptPutProductErrors,
  adaptPostProductErrors,
  adaptBulkActionResponse,
  adaptGetProductCountersResponse,
}
