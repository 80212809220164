import { action, computed, observable } from 'mobx'

import { adaptBulkActionResponse } from 'shared/adapters/products'

/**
 * Base store for URL-driven list views
 *
 * When subclassing
 * - remember to override 'abstract' methods getUrl and doFetch
 * - add `reaction` in subclass constructor
 *
 * Caveats:
 *  - FIXME: react-router Redirect causes double reaction
 *  - TODO: no url change -> no reaction, even if it's required eg. components is remounted in HMR
 * Future:
 *    TODO: cancel ongoing request when calling fetch
 *    TODO: support for "inifite scroll" collections
 *    TODO: add support for page number-based pagination
 *    TODO: auto reset (when user navigates away)
 */
class BaseListStore {
  limit = 25
  pathnameRegex = undefined

  @observable totalCount
  @observable items
  @observable isLoading

  @observable allOnAllPagesSelected = false

  // default reaction functions
  // - override listParamsGetter to add more params to put into the url (eg. filters) besides the offset
  // - override listParamsReaction to add more side effects besides calling doFetch and
  //   ensure the reaction should actually be performed
  //   (the store monitors the changes to the URL constantly as long as it's instantiated!)
  listParamsGetter = () => ({ offset: this.offset })
  listParamsReaction = params => {
    // only perform the fetch
    // - if the current url matches the specified regex
    // - only after the initial fetch has been completed (from component's useEffect)
    if (this.pathnameMatch && this.items !== undefined) {
      this.fetch()
    }
  }

  constructor (routerStore, data = {}) {
    this.routerStore = routerStore
    Object.assign(this, data)

    // add the below in subclass contructor after calling super(routerStore, data)
    // reaction(this.listParamsGetter, this.listParamsReaction)
  }

  @computed get offset () {
    const params = new URLSearchParams(this.routerStore?.location.search)
    return parseInt(params.get('offset')) || 0
  }

  @computed get count () {
    return this.items && this.items.length
  }

  @computed get pathnameMatch () {
    if (!this.pathnameRegex) {
      throw new Error('Set the pathnameRegex in the BaseListStore subclass')
    }
    return this.routerStore?.location.pathname.match(this.pathnameRegex)
  }

  @action.bound
  reset () {
    this.items = undefined
    this.totalCount = undefined
    this.isLoading = undefined
    this.allOnAllPagesSelected = false
  }

  /** If the list is currently used, refresh the collection as per current params */
  @action.bound
  refresh () {
    if (this.items === undefined) return // FIXME: could use a more reliable check
    this.fetch()
  }

  @action.bound
  setIsLoading (value) {
    this.isLoading = value
  }

  @action.bound
  setMeta (totalCount) {
    this.totalCount = totalCount
  }

  @action.bound
  setItems (value) {
    this.items = value
  }

  @action.bound
  next () {
    if (this.offset + this.count < this.totalCount) {
      this.routerStore.push(this.getUrl(
        { ...this.listParamsGetter(), offset: this.offset + this.count }
      ))
    }
  }

  @action.bound
  previous () {
    if (this.offset > 0) {
      this.routerStore.push(this.getUrl(
        { ...this.listParamsGetter(), offset: this.offset - Math.min(this.limit, this.offset) }
      ))
    }
  }

  @action.bound
  fetch () {
    this.setIsLoading(true)
    return this.doFetch()
      .then(response => {
        if (response?.data) {
          this.setMeta(response.data?.count)
        }
        return response
      })
      .finally(() => {
        this.setIsLoading(false)
      })
  }

  getUrl () {
    throw new Error('This method needs to be overridden in a subclass')
  }

  doFetch () {
    throw new Error('This method needs to be overridden in a subclass')
  }

  @computed get selectedItems () {
    return this.items ? this.items.filter(item => item.selected) : []
  }

  @computed get allOnPageSelected () {
    return !!this.items && this.items.length === this.selectedItems.length
  }

  @computed get partSelected () {
    return this.selectedItems.length > 0 && !this.allOnPageSelected
  }

  @computed get selectedIds () {
    return this.selectedItems.map(p => p.uuid)
  }

  @action.bound
  setAllOnAllPagesSelected (value) {
    this.allOnAllPagesSelected = !!value
    this.setAllSelected(!!value)
  }

  @action.bound
  setAllSelected (value) {
    if (Array.isArray(this.items)) this.items.forEach(item => item.setSelected(!!value))
    if (!value) {
      this.allOnAllPagesSelected = false
    }
  }

  @action.bound
  toggleAllSelected () {
    this.setAllSelected(!this.partSelected && !this.allOnPageSelected)
  }

  @action.bound
  toggleItemSelected (item) {
    item.setSelected(!item.selected)
    this.allOnAllPagesSelected = false
  }

  @action.bound
  performBulkAction (promise) {
    this.setIsLoading(true)
    return promise
      .then(({ data }) => {
        this.fetch()
        return adaptBulkActionResponse(data)
      })
      .finally(() => {
        this.setIsLoading(false)
      })
  }
}

export default BaseListStore
