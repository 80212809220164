import React, { useEffect } from 'react'

import PropTypes from 'prop-types'

import { observer } from 'mobx-react'

import style from './ReactAppWrapper.module.scss'

const ReactAppWrapper = ({ src }) => {
  useEffect(() => {
    document.body.classList.add('disable-scroll')
    return () => {
      document.body.classList.remove('disable-scroll')
    }
  }, [])

  return (
    <iframe className={style.ReactAppWrapper} src={src} data-testid="reactAppWrapper-iframe" />
  )
}

ReactAppWrapper.propTypes = {
  src: PropTypes.string.isRequired,
}

export default observer(ReactAppWrapper)
