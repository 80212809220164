import React from 'react'

import PropTypes from 'prop-types'

import { ListItemText, Menu, MenuItem } from '@material-ui/core'
import {
  alibabaAliexpressSettingsClicked,
  logOutClicked,
  userProfileMenuTriggered,
} from '@wix/bi-logger-modalyst/v2'
import { bindMenu, bindTrigger, usePopupState } from 'material-ui-popup-state/hooks'
import { observer } from 'mobx-react'

import { useBiEvents } from 'shared/bi/hooks'
import { UserProfileButton } from 'shared/components/molecules'
import { useTranslation } from 'shared/hooks'

import { BiLink } from 'retailer/components/atoms'

import style from './UserProfileMenu.module.scss'

const BI_ORIGIN = 'top actions'

/**
 * Menu containing links and triggers related to User Profile
 */
const UserProfileMenu = ({ userProfileStore }) => {
  const popupState = usePopupState({ variant: 'popover', popupId: 'userProfileMenu' })
  const { correlationId, logBiEvent } = useBiEvents()
  const { t } = useTranslation('settings')

  const handleLogoutClick = async () => {
    await logBiEvent(logOutClicked({ correlationId, origin: BI_ORIGIN }))
    userProfileStore.logout({ correlationId })
  }

  return (
    <>
      <UserProfileButton
        {...bindTrigger(popupState)}
        userProfileStore={userProfileStore}
        data-testid="UserProfileMenu-UserProfileButton"
      />
      <Menu
        {...bindMenu(popupState)}
        keepMounted
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: -15, horizontal: 'center' }}
        getContentAnchorEl={null}
        PaperProps={{ 'data-testid': 'UserProfileMenu-PopoverPaper' }}
        className={style.UserProfileMenu}
      >
        <MenuItem
          button component={BiLink} to="/settings/user-settings"
          biData={() => userProfileMenuTriggered({ origin: BI_ORIGIN })}
          data-testid="UserProfileMenu-accountSettings"
        >
          {t('userMenu.accountSettings.button')}
        </MenuItem>
        <MenuItem
          button component={BiLink} to="/connect-suppliers"
          biData={() => alibabaAliexpressSettingsClicked({ origin: BI_ORIGIN })}
          data-testid="UserProfileMenu-alibabaAndAliexpressSettings"
        >
          {t('userMenu.aliExpressSettings.button')}
        </MenuItem>
        <MenuItem
          button onClick={handleLogoutClick}
          href="/logout/"
          color="error"
          data-testid="UserProfileMenu-logout"
        >
          <ListItemText primaryTypographyProps={{ color: 'error' }}>
            {t('userMenu.logOut.button')}
          </ListItemText>
        </MenuItem>
      </Menu>
    </>
  )
}

UserProfileMenu.propTypes = {
  userProfileStore: PropTypes.shape({
    logout: PropTypes.func.isRequired,
  }).isRequired,
}

export default observer(UserProfileMenu)
