import React from 'react'

import PropTypes from 'prop-types'

import {
  Avatar,
  Box,
  Button,
  Chip,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Popover,
} from '@material-ui/core'
import { Add, Check, Language } from '@material-ui/icons'
import {
  addNewStoreClicked,
  goToMyStoresClicked,
  selectStoreClicked,
  storeSelectorTriggered,
} from '@wix/bi-logger-modalyst/v2'
import clsx from 'clsx'
import isEmpty from 'lodash/isEmpty'
import {
  usePopupState,
  bindTrigger,
  bindPopover,
} from 'material-ui-popup-state/hooks'
import { observer } from 'mobx-react'
import { ChevronDown, ChevronUp } from 'wix-ui-icons-common'

import { useBiEvents } from 'shared/bi/hooks'
import { integrationIcons, WIX } from 'shared/constants/integrations'
import { useTranslation } from 'shared/hooks'

import { BiLink } from 'retailer/components/atoms'

import style from './StoreSelectorMenu.module.scss'

const BI_ORIGIN = 'store selector'

const StoreSelectorMenu = ({ userProfileStore }) => {
  const popupState = usePopupState({ variant: 'popover', popupId: 'storeSelectorMenu' })
  const { logBiEvent } = useBiEvents()

  const { currentStore, storeSelectorOptions } = userProfileStore

  const onEnter = () => logBiEvent(storeSelectorTriggered({}))
  const handleNavClick = () => popupState.close()

  if (isEmpty(storeSelectorOptions)) return null

  return (
    <>
      <StoreButton
        {...bindTrigger(popupState)}
        currentStore={currentStore}
        storeSelectorMenuIsOpen={popupState.isOpen}
      />
      <Popover
        {...bindPopover(popupState)}
        keepMounted
        transformOrigin={{ vertical: -15, horizontal: 'center' }}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        getContentAnchorEl={null}
        PaperProps={{ className: style.Paper, 'data-testid': 'StoreSelectorMenu-PopoverPaper' }}
        TransitionProps={{ onEnter }}
      >
        <StoreMenuHeader onNavClick={handleNavClick} />
        <List disablePadding component={Box} flexGrow={1} overflow="auto">
          {storeSelectorOptions.map((store, index) => (
            <StoreMenuItem key={store.uuid} store={store} />
          ))}
        </List>
        <StoreMenuFooter onNavClick={handleNavClick} />
      </Popover>
    </>
  )
}

StoreSelectorMenu.propTypes = {
  anchorEl: PropTypes.object,
  anchorOrigin: PropTypes.shape({ vertical: PropTypes.string, horizontal: PropTypes.number | PropTypes.string }),
  keepMounted: PropTypes.bool,
  onClose: PropTypes.func,
  open: PropTypes.bool,
  transformOrigin: PropTypes.shape({ vertical: PropTypes.number, horizontal: PropTypes.string }),
  userProfileStore: PropTypes.shape({
    currentStore: PropTypes.object.isRequired,
    storeSelectorOptions: PropTypes.array.isRequired,
  }).isRequired,
}

/**
 * A button that displays the name and platform icon of the provided Store
 */
const StoreButton = observer(({ onClick, currentStore, storeSelectorMenuIsOpen }) => {
  const { name, storeInstallation } = currentStore
  const platform = storeInstallation?.platform
  const StoreIcon = integrationIcons.get(platform) || Language

  return (
    <Button
      className={style.StoreButton}
      color="inherit"
      startIcon={<StoreIcon width={platform === WIX ? 30 : 24} />}
      endIcon={storeSelectorMenuIsOpen ? <ChevronUp /> : <ChevronDown />}
      onClick={onClick}
      data-testid="StoreSelectorMenu-StoreButton"
      data-test-state={platform}
    >
      <span className={style.StoreButtonLabelText}>{name}</span>
    </Button>
  )
})

StoreButton.propTypes = {
  currentStore: PropTypes.shape({
    name: PropTypes.string.isRequired,
    storeInstallation: PropTypes.shape({
      platform: PropTypes.string,
    }),
  }),
  onClick: PropTypes.func.isRequired,
  storeSelectorMenuIsOpen: PropTypes.bool,
}

const StoreMenuHeader = ({ onNavClick }) => {
  const { t } = useTranslation('myStores')

  return (
    <ListItem className={style.StoreMenuHeader}>
      <ListItemText primary={t('storeSelector.title')} />
      <Button
        startIcon={<Add />} color="primary"
        component={BiLink} to="/my-stores/new"
        onClick={() => onNavClick()}
        biData={() => addNewStoreClicked({ origin: BI_ORIGIN })}
        data-testid="StoreSelectorMenu-addNewStoreButton"
      >
        {t('newStore.cta')}
      </Button>
    </ListItem>
  )
}

StoreMenuHeader.propTypes = {
  onNavClick: PropTypes.func.isRequired,
}

const StoreMenuFooter = ({ onNavClick }) => {
  const { t } = useTranslation('myStores')

  return (
    <Button
      color="primary" size="large" className={style.FooterButton}
      component={BiLink} to="/my-stores"
      data-testid="StoreSelectorMenu-myStoresButton"
      onClick={onNavClick}
      biData={() => goToMyStoresClicked({ origin: BI_ORIGIN })}
    >
      {t('goToStores.cta')}
    </Button>
  )
}

StoreMenuFooter.propTypes = {
  onNavClick: PropTypes.func.isRequired,
}

const StoreMenuItem = ({ store }) => {
  const { t } = useTranslation('myStores')

  const { uuid, isCurrent, isConnected, isPremium, name, storeInstallation } = store
  const platform = storeInstallation?.platform
  const StoreIcon = integrationIcons.get(platform) || Language

  return (
    <ListItem
      button
      className={clsx(style.Store, isPremium && style.isPremium)}
      component={BiLink} to={`/${uuid}`}
      biData={() => selectStoreClicked({ origin: BI_ORIGIN, storeName: name, targetStoreId: uuid })}
      waitForEvent
      data-testid="StoreSelectorMenu-StoreListItem"
      data-test-state={isCurrent ? 'current' : 'not current'}
    >
      <ListItemAvatar>
        <Avatar
          className={style.Avatar}
          data-testid="StoreSelectorMenu-StoreListItem-platformIcon"
          data-test-state={platform}
        >
          <StoreIcon width={platform === WIX ? 26 : 20} />
        </Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={<span data-testid="StoreSelectorMenu-StoreListItem-storeName">{name}</span>}
        secondary={isConnected ? null : 'Not connected'}
      />
      {isPremium && (
        <Chip
          label={t('premium.label')}
          size="small" color="secondary"
          className={style.PremiumChip}
          data-testid="StoreSelectorMenu-StoreListItem-premiumChip"
        />
      )}
      <Box width={24} ml={1} mr={-1}>
        {isCurrent && <Check />}
      </Box>
    </ListItem>
  )
}

StoreMenuItem.propTypes = {
  store: PropTypes.shape({
    uuid: PropTypes.string.isRequired,
    isCurrent: PropTypes.bool,
    isConnected: PropTypes.bool,
    isPremium: PropTypes.bool,
    name: PropTypes.string,
    storeInstallation: PropTypes.shape({
      platform: PropTypes.string,
    }),
  }),
}

export default observer(StoreSelectorMenu)
