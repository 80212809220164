
import { computed } from 'mobx'

import { WIX } from 'shared/constants/integrations'

class DashboardStore {
  /** @type {import('../context').RootStore} */
  root

  constructor (root) {
    this.root = root
  }

  @computed
  get shouldRenderWixPrintOnDemandBanner () {
    const { userProfileStore } = this.root
    const dismissed = userProfileStore.context.dismissedWixPrintOnDemandBanner
    console.log('dismissed', dismissed, userProfileStore.context)
    return userProfileStore.currentStore?.storeInstallation?.platform === WIX && !dismissed
  }
}

export default DashboardStore
