import React, { forwardRef } from 'react'

import PropTypes from 'prop-types'

import { InputAdornment, TextField } from '@material-ui/core'
import clsx from 'clsx'
import NumberFormat from 'react-number-format'

import style from './MoneyInput.module.scss'

const MoneyInput = forwardRef(({ value, disableFraction, onChange, currency, className, testId, ...rest }, ref) => {
  rest.InputProps = {
    className: style.Input,
    startAdornment: <InputAdornment position="start">{currency.toUpperCase()}</InputAdornment>
  }

  const handleOnValueChange = ({ value: v }) => {
    if (!onChange) return
    onChange({ target: { name: rest.name, value: v } })
  }

  return (
    <NumberFormat
      ref={ref}
      value={value}
      onValueChange={handleOnValueChange}
      fixedDecimalScale decimalScale={disableFraction ? 0 : 2}
      customInput={TextField}
      className={clsx(style.MoneyInput, className)}
      data-testid={testId}
      {...rest}
    />
  )
})

MoneyInput.propTypes = {
  currency: PropTypes.string,
  disableFraction: PropTypes.bool,
  value: PropTypes.string,
  onChange: PropTypes.func,
  className: PropTypes.string,
  testId: PropTypes.string,
}

MoneyInput.defaultProps = {
  disableFraction: false,
  currency: 'USD',
}

export default MoneyInput
