import React from 'react'

import PropTypes from 'prop-types'

import { Container, Link, Typography } from '@material-ui/core'
import { observer } from 'mobx-react'
import { Trans } from 'react-i18next'

import { TABS } from 'shared/constants/marketplaceItem'
import { useTranslation } from 'shared/hooks'

import { ItemStore } from 'retailer/stores'

import style from './MarketplaceItemDescriptionTab.module.scss'

const MarketplaceItemDescriptionTab = ({ item }) => {
  const { t } = useTranslation('productPage')

  if (item.is3rdPartySupplier && item.description === '') {
    return (
      <Container key={TABS.DESCRIPTION} className={style.MarketplaceItemDescriptionTab}>
        <Typography paragraph>
          <Trans
            t={t} i18nKey="tabs.thirdPartyDescription.cta"
            values={{ name: item.designer.name }}
            components={{ link: <Link href={item.externalUrl} target="_blank" rel="noopener noreferrer" /> }}
          />
        </Typography>
      </Container>
    )
  }
  return (
    <Container key={TABS.DESCRIPTION} className={style.MarketplaceItemDescriptionTab}>
      <div dangerouslySetInnerHTML={{ __html: item.description }} />
    </Container>
  )
}

MarketplaceItemDescriptionTab.propTypes = {
  item: PropTypes.instanceOf(ItemStore).isRequired
}

export default observer(MarketplaceItemDescriptionTab)
