import React, { useRef, useState } from 'react'

import PropTypes from 'prop-types'

import { yupResolver } from '@hookform/resolvers/yup'
import { FormHelperText, Grid, IconButton, InputAdornment, Link, TextField, Tooltip } from '@material-ui/core'
import { VisibilityOffRounded, VisibilityRounded } from '@material-ui/icons'
import { Alert } from '@material-ui/lab'
import { observer } from 'mobx-react'
import { useSnackbar } from 'notistack'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'

import { RhfTextField } from 'shared/components/atoms'
import { CardForm } from 'shared/components/organisms'
import {
  PASSWORD_HELP_TEXT,
  PASSWORD_VALIDATION_CAPITAL_LETTER_MESSAGE,
  PASSWORD_VALIDATION_CAPITAL_LETTER_REGEX,
  PASSWORD_VALIDATION_DIGIT_MESSAGE,
  PASSWORD_VALIDATION_DIGIT_REGEX,
  PASSWORD_VALIDATION_MAX_LENGTH,
  PASSWORD_VALIDATION_MIN_LENGTH,
  PASSWORD_VALIDATION_SPECIAL_CHAR_MESSAGE,
  PASSWORD_VALIDATION_SPECIAL_CHAR_REGEX
} from 'shared/constants/passwordValidation'
import { UserLoginInfoSettingsStore } from 'shared/stores'
import { getFieldErrorMessage, useRemoteErrors } from 'shared/utils/forms'

import style from './UserLoginInfoSettings.module.scss'

const validationSchema = yup.object().shape({
  password: yup.string().required('Please provide the current password'),
  newPassword: yup.string()
    .required('Please provide a new password')
    .min(8, PASSWORD_VALIDATION_MIN_LENGTH)
    .max(128, PASSWORD_VALIDATION_MAX_LENGTH)
    .matches(
      PASSWORD_VALIDATION_SPECIAL_CHAR_REGEX,
      PASSWORD_VALIDATION_SPECIAL_CHAR_MESSAGE
    )
    .matches(
      PASSWORD_VALIDATION_CAPITAL_LETTER_REGEX,
      PASSWORD_VALIDATION_CAPITAL_LETTER_MESSAGE
    )
    .matches(
      PASSWORD_VALIDATION_DIGIT_REGEX,
      PASSWORD_VALIDATION_DIGIT_MESSAGE
    )
})

const UserLoginInfoSettings = ({ store }) => {
  const defaultValues = {
    password: '',
    newPassword: ''
  }
  const formRef = useRef(null)
  const { enqueueSnackbar } = useSnackbar()
  const [showPassword, setShowPassword] = useState(false)
  const { control, handleSubmit, formState, reset, errors, setError } = useForm({
    mode: 'onChange',
    defaultValues,
    resolver: yupResolver(validationSchema)
  })
  const { nonFieldErrors } = useRemoteErrors(store.errors, setError)

  const onSubmit = data => {
    return store.changePassword(data).then(() => {
      reset(defaultValues)
      enqueueSnackbar('Password has been changed', {
        variant: 'success',
      })
    })
  }

  return (
    <CardForm
      title="Login info"
      className={style.UserLoginInfoSettings}
      formRef={formRef}
      canSubmit={formState.isDirty && formState.isValid && !Object.keys(errors).length}
      isSubmitting={store.isSaving}
      onDiscard={formState.isDirty ? () => reset(defaultValues) : null}
      testId="UserLoginInfoSettings"
    >
      {nonFieldErrors && (
        <Alert severity="error" className={style.Errors}>
          {nonFieldErrors.join(' ')}
        </Alert>
      )}
      <form
        ref={formRef}
        onSubmit={handleSubmit(onSubmit)}
      >
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TextField
              id="username"
              name="username"
              label="User Name"
              value={store.username}
              variant="outlined"
              fullWidth
              disabled
              data-testid="UserLoginInfoSettings-username"
            />
            <FormHelperText className={style.UsernameHelper}>
              If you would like to change your user name, please <Link href="//www.modalyst.co/contact-us/">contact our support</Link>.
            </FormHelperText>
          </Grid>
          <Grid item xs={12}>
            <RhfTextField
              control={control}
              name="password"
              label="Current password"
              error={getFieldErrorMessage(errors, 'password')}
              required
              InputProps={{ type: 'password' }}
              testId="UserLoginInfoSettings-currentPassword"
            />
          </Grid>
          <Grid item xs={12}>
            <RhfTextField
              control={control}
              name="newPassword"
              label="New password"
              error={getFieldErrorMessage(errors, 'newPassword')}
              required
              autoComplete="new-password"
              testId="UserLoginInfoSettings-newPassword"
              InputProps={{
                type: showPassword ? 'text' : 'password',
                endAdornment: formState.dirtyFields?.newPassword && (
                  <Tooltip title={showPassword ? 'Hide password' : 'Show password'}>
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="Toggle password visibility"
                        onClick={() => setShowPassword(!showPassword)}
                        data-testid="UserLoginInfoSettings-newPassword-toggleVisibilityButton"
                      >
                        {showPassword ? <VisibilityOffRounded /> : <VisibilityRounded />}
                      </IconButton>
                    </InputAdornment>
                  </Tooltip>
                )
              }}
              helperText={PASSWORD_HELP_TEXT}
            />
          </Grid>
        </Grid>
      </form>
    </CardForm>
  )
}

UserLoginInfoSettings.propTypes = {
  store: PropTypes.instanceOf(UserLoginInfoSettingsStore).isRequired
}

export default observer(UserLoginInfoSettings)
