import React from 'react'

import PropTypes from 'prop-types'

import {
  Button,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core'
import { observer } from 'mobx-react'

import { Money, Time } from 'shared/components/atoms'
import { Alert } from 'shared/components/molecules'
import { DataModal } from 'shared/components/organisms'
import { PayPal as PayPalLogo } from 'shared/icons/external'

import OrderPaymentState from 'retailer/stores/OrderPaymentState'

import style from './ModalystOrderPaymentModal.module.scss'

/**
 * Displays summary information about Purchase Order and its items
 *
 * @param {object} props
 */
const PurchaseOrderSummaryTable = observer(({ order }) => {
  return (
    <TableContainer className={style.ItemsTableContainer}>
      <Table size="small" className={style.ItemsTable}>
        <TableHead>
          <TableRow>
            <TableCell>Order #</TableCell>
            <TableCell>Brand</TableCell>
            <TableCell>Date</TableCell>
            <TableCell>Shipping</TableCell>
            <TableCell># of items</TableCell>
            <TableCell>Item Cost</TableCell>
            <TableCell>Total</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {order.items.map(item => (
            <TableRow key={item.uuid}>
              <TableCell>{order.modalystOrderNumber}</TableCell>
              <TableCell>{order.brand}</TableCell>
              <TableCell>
                <Time value={order.created} />
              </TableCell>
              <TableCell>
                <Money amount={item.shippingCost} />
              </TableCell>
              <TableCell>{item.quantity}</TableCell>
              <TableCell>
                <Money amount={item.itemCost} />
              </TableCell>
              <TableCell>
                <Money amount={item.totalCosts} />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TableCell colSpan="6" align="right">Subtotal:</TableCell>
            <TableCell>
              <Money amount={order.totalCost} />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell colSpan="6" align="right">Shipping:</TableCell>
            <TableCell>
              <Money amount={order.totalShippingCost} />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell colSpan="6" align="right">Total amount:</TableCell>
            <TableCell>
              <Money amount={order.totalPayment} />
            </TableCell>
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  )
})

PurchaseOrderSummaryTable.propTypes = {
  order: PropTypes.shape({
    items: PropTypes.arrayOf(PropTypes.shape({
      quantity: PropTypes.number.isRequired,
      itemCost: PropTypes.string.isRequired,
      shippingCost: PropTypes.string.isRequired,
      totalCosts: PropTypes.string.isRequired,
    })).isRequired,
    modalystOrderNumber: PropTypes.string.isRequired,
    brand: PropTypes.string.isRequired,
    created: PropTypes.instanceOf(Date).isRequired,
    totalCost: PropTypes.string.isRequired,
    totalShippingCost: PropTypes.string.isRequired,
    modalystRetailerCommission: PropTypes.string.isRequired,
    totalPayment: PropTypes.string.isRequired,
  }).isRequired,
}

const ModalystOrderPaymentModal = props => {
  const { store, onExited } = props
  const order = store.order
  const errors = store.setupErrors

  return (
    <DataModal
      title="Confirm payment"
      showSpinner={store.requestInProgress}
      hide={false}
      onExited={onExited}
      renderBody={() => (
        <div className={style.ModalystOrderPaymentModalBody}>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            className={style.PaymentProcessorInformation}
          >
            <Grid>
              <Typography>
                Payment will be made using PayPal
              </Typography>
            </Grid>
            <Grid>
              <PayPalLogo />
            </Grid>
          </Grid>

          {Array.isArray(errors) && errors.length && (
            <Alert severity="error" className={style.ErrorContainer}>
              {errors.join(' ')}
            </Alert>
          )}

          <PurchaseOrderSummaryTable order={order} />
        </div>
      )}
      renderActions={({ dismissModal, showSpinner }) => (
        <>
          <Button
            onClick={dismissModal}
            color="primary"
            size="large"
            disabled={showSpinner}
          >
            Cancel
          </Button>
          <Button
            onClick={store.handlePaymentStart}
            color="primary"
            variant="contained"
            size="large"
            disabled={showSpinner}
          >
            Pay now
          </Button>
        </>
      )}
    />
  )
}

ModalystOrderPaymentModal.propTypes = {
  onExited: PropTypes.func,
  store: PropTypes.instanceOf(OrderPaymentState).isRequired,
}

export {
  PurchaseOrderSummaryTable,
}
export default observer(ModalystOrderPaymentModal)
