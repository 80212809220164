import React from 'react'

import PropTypes from 'prop-types'

import { Box, Chip, Link } from '@material-ui/core'
import every from 'lodash/every'
import isEmpty from 'lodash/isEmpty'
import { observer } from 'mobx-react'

import { Money } from 'shared/components/atoms'
import { SHIPPING_SPEED_OPTIONS } from 'shared/constants/marketplace'
import { useTranslation } from 'shared/hooks'

import CustomPropTypes from 'retailer/propTypes'

import style from './AppliedMarketplaceFilters.module.scss'

const FilterChip = observer(props => (
  <Chip className={style.FilterChip} color="primary" size="small" {...props} />
))

const AppliedMarketplaceFilters = ({ marketplaceStore, ...rest }) => {
  const { t } = useTranslation('marketplace')

  const {
    brands,
    shipsFromCountries,
    shipsToCountries,
    shipping,
    costFrom,
    costTo,
    clearFilter,
    clearFilterValue,
    clearAllFilters,
  } = marketplaceStore

  const noFiltersApplied = every([
    brands, shipsFromCountries, shipsToCountries, shipping,
    costFrom, costTo,
  ], isEmpty)

  if (noFiltersApplied) return null

  return (
    <Box className={style.AppliedMarketplaceFilters} {...rest}>
      <div className={style.ChipContainer} data-testid="AppliedMarketplaceFilters-container">
        {brands.map(brand => (
          <FilterChip
            key={brand.uuid}
            label={brand.name}
            onDelete={() => clearFilterValue('brandIds', brand.uuid)}
            data-testid="AppliedMarketplaceFilters-filterChip"
          />
        ))}
        {shipping && shipping.map(value => (
          <FilterChip
            key={`shipping_${value}`}
            label={t(`main.${SHIPPING_SPEED_OPTIONS.get(value)}.checkbox`)}
            onDelete={() => clearFilterValue('shipping', value)}
            data-testid="AppliedMarketplaceFilters-filterChip"
          />
        ))}
        {shipsFromCountries.map(country => (
          <FilterChip
            key={`shipsFrom_${country.iso}`}
            label={`${t('main.filterShippingShipsFrom.label')}: ${country.name}`}
            onDelete={() => clearFilterValue('shipsFrom', country.code)}
            data-testid="AppliedMarketplaceFilters-filterChip"
          />
        ))}
        {shipsToCountries.map(country => (
          <FilterChip
            key={`shipsTo_${country.uuid}`}
            label={`${t('main.filterShippingShipsTo.label')}: ${country.name}`}
            onDelete={() => clearFilterValue('shipsTo', country.code)}
            data-testid="AppliedMarketplaceFilters-filterChip"
          />
        ))}
        {costFrom && (
          <FilterChip
            key="costFrom"
            label={<>{t('main.filterPriceFrom.label')}: <Money amount={costFrom} /></>}
            onDelete={() => clearFilter('costFrom')}
            data-testid="AppliedMarketplaceFilters-filterChip"
          />
        )}
        {costTo && (
          <FilterChip
            key="costTo"
            label={<>{t('main.filterPriceTo.label')}: <Money amount={costTo} /></>}
            onDelete={() => clearFilter('costTo')}
            data-testid="AppliedMarketplaceFilters-filterChip"
          />
        )}

        <Link
          onClick={() => clearAllFilters()} className={style.ClearButton}
          data-testid="AppliedMarketplaceFilters-clearAllFilters"
        >
          {t('main.filtersAppliedClear.cta')}
        </Link>
      </div>
    </Box>
  )
}

AppliedMarketplaceFilters.propTypes = {
  marketplaceStore: PropTypes.shape({
    brands: PropTypes.arrayOf(CustomPropTypes.brandObject).isRequired,
    shipsFromCountries: PropTypes.arrayOf(CustomPropTypes.countryObject).isRequired,
    shipsToCountries: PropTypes.arrayOf(CustomPropTypes.countryObject).isRequired,
    shipping: PropTypes.arrayOf(PropTypes.string),
    costFrom: PropTypes.string,
    costTo: PropTypes.string,
    clearFilter: PropTypes.func.isRequired,
    clearFilterValue: PropTypes.func.isRequired,
    clearAllFilters: PropTypes.func.isRequired,
  })
}

AppliedMarketplaceFilters.defaultProps = {

}

export default observer(AppliedMarketplaceFilters)
