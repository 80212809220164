import React, { useState } from 'react'

import PropTypes from 'prop-types'

import {
  Button,
  CircularProgress,
  ImageList,
  ImageListItem,
} from '@material-ui/core'
import { BrokenImageRounded } from '@material-ui/icons'
import clsx from 'clsx'
import { observer } from 'mobx-react'

import { ImageZoom } from 'shared/components/organisms'

import { ItemStore } from 'retailer/stores'

import style from './MarketplaceItemGallery.module.scss'

const MarketplaceItemGallery = ({ item }) => {
  const [isZoomed, setIsZoomed] = useState(false)
  const [selectedImage, setSelectedImage] = useState(item.galleryImages[0])
  const [selectedImageLoaded, setSelectedImageLoaded] = useState(false)

  const handleClick = (image) => {
    const container = document.getElementById('thumbnails')
    const element = document.getElementById(image.id)

    setSelectedImageLoaded(false)
    setSelectedImage(image)
    container.scrollTo({
      top: 0,
      left: element.offsetLeft + (element.offsetWidth / 2) - (container.offsetWidth / 2),
      behavior: 'smooth',
    })
  }

  const handleOpenZoom = () => {
    if (selectedImageLoaded) setIsZoomed(true)
  }

  const selectedImageElement = (
    <div
      className={clsx(style.SelectedImageContainer, selectedImageLoaded && style.zoomable)}
      onClick={handleOpenZoom}
    >
      {!selectedImage && (
        <div className={style.Placeholder}>
          <BrokenImageRounded className={style.PlaceholderIcon} />
        </div>
      )}
      {selectedImage && (
        <>
          <img
            src={selectedImage?.url}
            alt=""
            className={clsx(style.SelectedImage, item.isStyleCommerce && style.focusBottom)}
            onLoad={() => setSelectedImageLoaded(true)}
          />
          <div className={clsx(style.SelectedImageLoader, selectedImageLoaded && style.hidden)}>
            <div className={style.LoaderWrapper}>
              <CircularProgress color="inherit" />
            </div>
          </div>
        </>
      )}
    </div>
  )

  return (
    <div className={style.MarketplaceItemGallery}>
      {selectedImageElement}
      <ImageZoom
        isZoomed={isZoomed}
        handleClose={() => setIsZoomed(false)}
        src={selectedImage?.url}
      />
      <ImageList rowHeight={150} cols={3.5} className={style.Slider} id="thumbnails">
        {item.galleryImages.map(image => {
          return (
            <ImageListItem cols={1} key={image.id} id={image.id}>
              <Button
                className={clsx(style.ThumbnailButton, selectedImage?.id === image.id && style.current)}
                disabled={selectedImage?.id === image.id}
                onClick={() => handleClick(image)}
              >
                <img
                  src={image.thumbnail}
                  alt=""
                  className={clsx(style.Thumbnail, item.isStyleCommerce && style.focusBottom)}
                />
              </Button>
            </ImageListItem>
          )
        })}
      </ImageList>
    </div>
  )
}

MarketplaceItemGallery.propTypes = {
  item: PropTypes.instanceOf(ItemStore).isRequired,
}

export default observer(MarketplaceItemGallery)
