import { api } from './common'

const baseUrl = '/api/v3/notifications/'

const getRetailerNotifications = () => {
  return api.get(baseUrl)
}

export {
  getRetailerNotifications
}
