import React from 'react'

import PropTypes from 'prop-types'

import { Box } from '@material-ui/core'
import clsx from 'clsx'
import { observer } from 'mobx-react'

import { Alerts, AppBar, Sidebar } from 'retailer/components/organisms'

import style from './SidebarLeftTemplate.module.scss'

const SidebarLeftTemplate = ({ notifications, main, disableGutters }) => {
  return (
    <Box
      className={style.SidebarLeftTemplate}
      pt={{ xs: 6, lg: 7.5 }}
    >
      <AppBar elevation={0} />
      <Sidebar />
      <main className={style.MainContainer}>
        {notifications && React.cloneElement(notifications, { mx: 2, mt: 2 })}
        <div className={clsx(style.Main, disableGutters && style.disableGutters)}>
          {main}
        </div>
        <Alerts />
      </main>
    </Box>
  )
}

SidebarLeftTemplate.propTypes = {
  notifications: PropTypes.node,
  main: PropTypes.node.isRequired,
  disableGutters: PropTypes.bool,
}

SidebarLeftTemplate.defaultProps = {
  notifications: null,
  disableGutters: false,
}

export default observer(SidebarLeftTemplate)
