import React from 'react'

import PropTypes from 'prop-types'

import { Button, Typography } from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'
import { observer } from 'mobx-react'

import { Alert } from 'shared/components/molecules'
import { DataModal, ProgressBar } from 'shared/components/organisms'
import {
  ACTION_AUTO_PRICE_OFF,
  ACTION_AUTO_PRICE_ON,
  ACTION_MOVE_TO_IMPORT_LIST,
  ACTION_REMOVE_FROM_STORE,
} from 'shared/constants/retailerItems'
import { useTranslation } from 'shared/hooks'

import style from './SyncListBatchActionModal.module.scss'

const ActionDescription = observer(({ action, selectedCount }) => {
  const { t } = useTranslation('syncedProducts')

  switch (action) {
    case ACTION_MOVE_TO_IMPORT_LIST:
      return (
        <div key={ACTION_MOVE_TO_IMPORT_LIST}>
          <Typography paragraph>
            {t('backToImportList.modal.unsync.description')}
          </Typography>
          <Typography paragraph>
            {t('backToImportList.modal.description')}
          </Typography>
          <Typography paragraph component="ul">
            <li>
              <strong>{t('backToImportList.modal.changes.bullet')}</strong><br />
              {t('backToImportList.modal.changes.subtitle')}
            </li>
            <li>
              <strong>{t('backToImportList.modal.shopify.bullet')}</strong><br />
              {t('backToImportList.modal.shopify.subtitle')}
            </li>
          </Typography>
        </div>
      )
    case ACTION_REMOVE_FROM_STORE:
      return (
        <div key={ACTION_REMOVE_FROM_STORE}>
          <Typography paragraph>
            {t('removeProducts.modal.subititle')}
          </Typography>
        </div>
      )
    case ACTION_AUTO_PRICE_ON:
      return (
        <div key={ACTION_AUTO_PRICE_ON}>
          <Typography paragraph>
            {t('autoprice.modal.subtitle')}
          </Typography>
        </div>
      )
    case ACTION_AUTO_PRICE_OFF:
      return (
        <div key={ACTION_AUTO_PRICE_OFF}>
          <Typography paragraph>
            {t('disableAutoprice.modal.subtitle')}
          </Typography>
        </div>
      )
    default: return null
  }
})

const actionsTitleUserFriendly = new Map([
  [ACTION_MOVE_TO_IMPORT_LIST, 'backToImportList.modal.title'],
  [ACTION_REMOVE_FROM_STORE, 'removeProducts.modal.title'],
  [ACTION_AUTO_PRICE_ON, 'autoprice.modal.title'],
  [ACTION_AUTO_PRICE_OFF, 'disableAutoprice.modal.title']
])

const actionsUserFriendly = new Map([
  [ACTION_MOVE_TO_IMPORT_LIST, 'backToImportList.modal.move.cta'],
  [ACTION_REMOVE_FROM_STORE, 'removeProducts.modal.remove.cta'],
  [ACTION_AUTO_PRICE_ON, 'autoprice.modal.enable.cta'],
  [ACTION_AUTO_PRICE_OFF, 'disableAutoprice.modal.disable.cta']
])

const actionsUserFriendlyCancel = new Map([
  [ACTION_MOVE_TO_IMPORT_LIST, 'backToImportList.modal.cancel.cta'],
  [ACTION_REMOVE_FROM_STORE, 'removeProducts.modal.cancel.cta'],
  [ACTION_AUTO_PRICE_ON, 'autoprice.modal.cancel.cta'],
  [ACTION_AUTO_PRICE_OFF, 'disableAutoprice.modal.cancel.cta']
])

ActionDescription.propTypes = {
  action: PropTypes.string.isRequired,
  selectedCount: PropTypes.number.isRequired
}

const ActionButtons = observer(({
  action,
  isLoading,
  inProgress,
  isFinished,
  dismissModal,
  handleAction,
  handleStopAction
}) => {
  const { t } = useTranslation('syncedProducts')

  if (inProgress) {
    return (
      <Button
        variant="contained"
        color="primary"
        onClick={handleStopAction}
        data-testid="syncList-batchActionModal-stopButton"
      >
        {t('moveToImportList.modal.cancel.cta')}
      </Button>
    )
  }
  if (isFinished) {
    return (
      <Button variant="outlined" onClick={dismissModal} data-testid="syncList-batchActionModal-dismissButton">
        {t('moveToImportList.modal.gotIt.cta')}
      </Button>
    )
  }
  return (
    <>
      <Button
        variant="outlined"
        onClick={dismissModal}
        disabled={isLoading}
        data-testid="syncList-batchActionModal-cancelButton"
      >
        {t(actionsUserFriendlyCancel.get(action))}
      </Button>
      <Button
        variant="contained"
        color="primary"
        onClick={handleAction}
        disabled={isLoading}
        data-testid="syncList-batchActionModal-actionButton"
      >
        {t(actionsUserFriendly.get(action))}
      </Button>
    </>
  )
})

ActionButtons.propTypes = {
  action: PropTypes.string.isRequired,
  inProgress: PropTypes.bool,
  isFinished: PropTypes.bool,
  isLoading: PropTypes.bool,
  dismissModal: PropTypes.func.isRequired,
  handleAction: PropTypes.func.isRequired,
  handleStopAction: PropTypes.func.isRequired
}

const SyncListBatchActionModal = ({ syncListBatchActionStore }) => {
  const {
    action,
    reset,
    loading,
    error,
    asyncHandlerStore,
    count,
    run: handleRun,
    stop: handleStop
  } = syncListBatchActionStore

  const { t } = useTranslation('syncedProducts')

  return (
    <DataModal
      className={style.SyncListBatchActionModal}
      title={t(actionsTitleUserFriendly.get(action), { numberOfProducts: count })}
      maxWidth="md"
      fullWidth
      onExited={() => reset()}
      renderBody={() => {
        if (error) {
          return (
            <Typography>
              {String(error)}
            </Typography>
          )
        }
        if (!loading.isLoaded) {
          return (
            <>
              <Typography paragraph component={Skeleton} />
              <Typography paragraph component={Skeleton} />
              <Typography paragraph component={Skeleton} width="50%" />
              <Typography paragraph component={Skeleton} />
              <Typography paragraph component={Skeleton} width="50%" />
            </>
          )
        }
        if (asyncHandlerStore.inProgress) {
          return (
            <ProgressBar
              plural="Products"
              processedCount={asyncHandlerStore.processed}
              totalCount={count}
            />
          )
        }
        if (asyncHandlerStore.isFinished && asyncHandlerStore.isStopped) {
          return (
            <>
              <Typography paragraph>
                The process was stopped before it finished. {asyncHandlerStore.processed} products
                were processed of total {count}.
              </Typography>
              {Boolean(asyncHandlerStore.errors) && (
                <Alert title="There Were Errors" severity="error">
                  {asyncHandlerStore.errors} products could not be processed due to errors.
                </Alert>
              )}
            </>
          )
        }
        if (asyncHandlerStore.isFinished && !asyncHandlerStore.isStopped) {
          return (
            <>
              <Typography paragraph>
                Processing products has finished!
              </Typography>
              {Boolean(asyncHandlerStore.errors) && (
                <Alert title="There Were Errors" severity="error">
                  {asyncHandlerStore.errors} products could not be processed due to errors.
                </Alert>
              )}
            </>
          )
        }
        return <ActionDescription action={action} selectedCount={count} />
      }}
      renderActions={({ dismissModal }) => {
        return (
          <ActionButtons
            action={action}
            dismissModal={dismissModal}
            isLoading={loading.isLoading}
            inProgress={asyncHandlerStore?.inProgress}
            isFinished={asyncHandlerStore?.isFinished}
            handleStopAction={handleStop}
            handleAction={handleRun}
          />
        )
      }}
    />
  )
}

SyncListBatchActionModal.propTypes = {
  syncListBatchActionStore: PropTypes.shape({
    action: PropTypes.string.isRequired,
    reset: PropTypes.func.isRequired,
    error: PropTypes.instanceOf(Error),
    loading: PropTypes.shape({
      isLoading: PropTypes.bool,
      isLoaded: PropTypes.bool,
    }).isRequired,
    asyncHandlerStore: PropTypes.shape({
      inProgress: PropTypes.bool,
      isFinished: PropTypes.bool,
      isStopped: PropTypes.bool,
      processed: PropTypes.number,
      errors: PropTypes.number,
    }),
    count: PropTypes.number,
    run: PropTypes.func.isRequired,
    stop: PropTypes.func.isRequired
  }).isRequired
}

export default observer(SyncListBatchActionModal)
