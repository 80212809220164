import React from 'react'

import PropTypes from 'prop-types'

import { displayPrice } from 'shared/utils/pricing'

const Money = ({
  currency, amount, whenNaN, currencyDisplay, symbolOverride, className, useRounding, CurrencyProps, AmountProps, testId
}) => {
  let output = Number.parseFloat(amount)

  const hideCode = currencyDisplay === false

  if (Number.isNaN(output)) return <span className={className} data-testid={testId}>{whenNaN}</span>

  const displayedOutput = useRounding
    ? displayPrice(amount)
    : { amount: amount, suffix: '', minFractionDigits: 2, maxFractionDigits: 2 }

  const renderers = {
    minusSign: val => val,
    plusSign: val => val,
    currency: val => hideCode ? null : <span key="currency" {...CurrencyProps}>{symbolOverride || val}</span>,
    literal: val => hideCode ? null : val,
    amount: val => <span key="amount" {...AmountProps}>{val}{displayedOutput.suffix}</span>,
  }

  output = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currencyDisplay: currencyDisplay || 'symbol',
    currency,
    minimumFractionDigits: displayedOutput.minFractionDigits,
    maximumFractionDigits: displayedOutput.maxFractionDigits
  }).formatToParts(displayedOutput.amount)

  // Merges plus or minus sign into the amount part
  output = output.reduce((parts, { type, value }) => {
    switch (type) {
      case 'currency': case 'literal': parts.set(type, value); break
      case 'minusSign': case 'plusSign': parts.set('amount', value + (parts.get('amount') || '')); break
      default: parts.set('amount', (parts.get('amount') || '') + value)
    }
    return parts
  }, new Map())

  const orderOfParts = [
    'currency',
    'literal',
    'amount'
  ]

  return (
    <span className={className} data-testid={testId}>
      {
        [...output.entries()]
          .sort((prev, next) => {
            return orderOfParts.indexOf(prev[0]) - orderOfParts.indexOf(next[0])
          })
          .map(([type, content]) => renderers[type](content))
      }
    </span>
  )
}

Money.propTypes = {
  amount: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  currency: PropTypes.string,
  whenNaN: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  className: PropTypes.string,
  useRounding: PropTypes.bool,
  currencyDisplay: PropTypes.oneOf(['symbol', 'narrowSymbol', 'code', 'name', false]),
  symbolOverride: PropTypes.string,
  CurrencyProps: PropTypes.object,
  AmountProps: PropTypes.object,
  testId: PropTypes.string,
}

Money.defaultProps = {
  currency: 'USD',
  whenNaN: '-',
  className: '',
  currencyDisplay: 'symbol',
  useRounding: false,
}

export default Money
