import { action, computed, observable } from 'mobx'

import { ALL_VALUE } from 'shared/constants/filters'
import { DEFAULT_ORDERING } from 'shared/constants/marketplace'
import { FilterStore } from 'shared/stores'

class SelectFilterStore extends FilterStore {
  @observable.shallow values = []

  constructor (name, value, active, values = []) {
    super(name, value, active)
    this.values = values
  }

  @computed get activeValue () {
    return this.value === ALL_VALUE ? '' : this.value
  }

  @action.bound
  setValues (values) {
    this.values = values
  }

  @action.bound
  clearFilter () {
    this._value = ALL_VALUE
    this.deactivate()
  }

  @action.bound
  setFilter (value) {
    if (value === DEFAULT_ORDERING) {
      this._value = ALL_VALUE
    } else {
      this._value = value
    }
    this.activate()
  }
}

export default SelectFilterStore
