import { debounce } from '@material-ui/core'
import camelCase from 'lodash/camelCase'
import isEmpty from 'lodash/isEmpty'
import sumBy from 'lodash/sumBy'
import { action, computed, observable } from 'mobx'

import { inventoryTypesUserFriendly, SHIPPING_SPEED_OPTIONS } from 'shared/constants/marketplace'
import { sortInventoryTypes } from 'shared/utils/inventoryTypes'

/** A store for a basic checkbox item, handles (de)selecting filter values */
class SelectableStore {
  id
  value
  label
  @observable selected

  constructor (id, label, value = undefined, selected = false) {
    this.id = id
    this.label = label
    this.value = value
    this.selected = selected
  }

  @action.bound setSelected (value) {
    this.selected = !!value
  }

  @action.bound toggle () {
    this.selected = !this.selected
  }
}

/** Stores and sets values for searching in a collection of filter values */
class SearchStore {
  /** A momentary value, as typed in by the user. Copied to appliedQuery with debounce. */
  @observable query = ''
  /** Stores a value that should be applied when searching */
  @observable appliedQuery = ''

  applyQuery = debounce(action(value => {
    this.appliedQuery = value.toLowerCase()
  }), 200)

  @action.bound reset () {
    this.query = ''
    this.appliedQuery = ''
  }

  @action.bound setQuery (value = '') {
    this.query = value || ''
    this.applyQuery(this.query)
  }
}

class InventoryTypeStore {
  parent
  search = new SearchStore()

  @observable id

  constructor (parent, id) {
    this.parent = parent
    this.id = id
  }

  @computed get name () {
    return inventoryTypesUserFriendly.get(camelCase(this.id)) || this.id
  }

  @computed get _brandOptions () {
    return this.parent._brandOptions.filter(({ value: obj }) => obj.inventoryType === this.id)
  }

  @computed get brandOptions () {
    const query = this.search.appliedQuery
    return query
      ? this._brandOptions.filter(({ label }) => label.toLowerCase().includes(query))
      : this._brandOptions
  }

  @computed get selectedBrandOptions () {
    return this.brandOptions.filter(obj => obj.selected)
  }
}

class SuppliersFiltersEditorStore {
  /** @type {import('../context').RootStore} */
  root

  search = new SearchStore()

  constructor (root, data = {}) {
    this.root = root
    Object.assign(this, data)
  }

  @computed get inventoryTypes () {
    const inventoryTypes = Array.from(new Set(this.brands.map(brand => brand.inventoryType)))
    return sortInventoryTypes(inventoryTypes).map(code => new InventoryTypeStore(this, code))
  }

  @computed get brands () {
    return this.root.appConfigStore.activeBrands
  }

  @computed get _brandOptions () {
    return this.brands.map(obj => new SelectableStore(obj.uuid, obj.name, obj))
  }

  @computed get brandOptions () {
    const query = this.search.appliedQuery
    return query
      ? this._brandOptions.filter(({ label }) => label.toLowerCase().includes(query))
      : this._brandOptions
  }

  /**
   * If user searched in all inventory types, return selected options from
   * that list. If not, return selected options from inventory types (with their
   * search applied)
   **/
  @computed get selectedBrandOptions () {
    return this.search.appliedQuery
      ? this.brandOptions.filter(obj => obj.selected)
      : [].concat(...this.inventoryTypes.map(it => it.selectedBrandOptions))
  }

  @computed get totalSelectedCount () {
    return this.selectedBrandOptions.length
  }

  @action.bound reset () {
    this.search.reset()
    this.inventoryTypes.forEach(obj => obj.search.reset())
    this._brandOptions.forEach(obj => obj.setSelected(false))
  }

  @action.bound updateFromParams ({ brandIds }) {
    this._brandOptions.forEach(
      obj => obj.setSelected(isEmpty(brandIds) ? false : brandIds.includes(obj.id))
    )
  }

  @computed get filterParams () {
    const brandIds = this.selectedBrandOptions.length
      ? this.selectedBrandOptions.map(obj => obj.value.uuid)
      : undefined
    return { brandIds }
  }
}

class ShippingFiltersEditorStore {
  /** @type {import('../context').RootStore} */
  root

  shipsToSearch = new SearchStore()
  shipsFromSearch = new SearchStore()

  @observable shippingOptions = [...SHIPPING_SPEED_OPTIONS.entries()].map(
    ([value, name]) => new SelectableStore(value, name)
  )

  constructor (root, data = {}) {
    this.root = root
    Object.assign(this, data)
  }

  @computed get selectedShippingOptions () {
    return this.shippingOptions.filter(obj => obj.selected)
  }

  /** "Ships from" selectables for internal use - before filtering */
  @computed get _shipsFromOptions () {
    return this.root.appConfigStore.shipsFromCountries.map(obj => new SelectableStore(obj.code, obj.name, obj))
  }

  /** "Public" "Ships from" selectables - with filtering applied */
  @computed get shipsFromOptions () {
    const query = this.shipsFromSearch.appliedQuery
    return query
      ? this._shipsFromOptions.filter(({ label }) => label.toLowerCase().includes(query))
      : this._shipsFromOptions
  }

  @computed get selectedShipsFromOptions () {
    return this.shipsFromOptions.filter(obj => obj.selected)
  }

  /** "Ships to" selectables for internal use - before filtering */
  @computed get _shipsToOptions () {
    return this.root.appConfigStore.shipsToCountries.map(obj => new SelectableStore(obj.code, obj.name, obj))
  }

  /** "Public" "Ships to" selectables - with filtering applied */
  @computed get shipsToOptions () {
    const query = this.shipsToSearch.appliedQuery
    return query
      ? this._shipsToOptions.filter(({ label }) => label.toLowerCase().includes(query))
      : this._shipsToOptions
  }

  @computed get selectedShipsToOptions () {
    return this.shipsToOptions.filter(obj => obj.selected)
  }

  @computed get totalSelectedCount () {
    return (
      this.selectedShippingOptions.length +
      this.selectedShipsToOptions.length +
      this.selectedShipsFromOptions.length
    )
  }

  @action.bound reset () {
    this.shippingOptions.forEach(obj => obj.setSelected(false))
    this.shipsFromOptions.forEach(obj => obj.setSelected(false))
    this.shipsToOptions.forEach(obj => obj.setSelected(false))
    this.shipsFromSearch.reset()
    this.shipsToSearch.reset()
  }

  @action.bound updateFromParams ({ shipsFrom, shipsTo, shipping }) {
    this.shipsFromOptions.forEach(obj => obj.setSelected(!!shipsFrom && shipsFrom.includes(obj.id)))
    this.shipsToOptions.forEach(obj => obj.setSelected(!!shipsTo && shipsTo.includes(obj.id)))
    this.shippingOptions.forEach(obj => obj.setSelected(!!shipping && shipping.includes(obj.id)))
  }

  @computed get filterParams () {
    const {
      selectedShipsFromOptions: shipsFrom,
      selectedShipsToOptions: shipsTo,
      selectedShippingOptions: shipping,
    } = this
    return {
      shipsFrom: isEmpty(shipsFrom) ? undefined : shipsFrom.map(obj => obj.value.code),
      shipsTo: isEmpty(shipsTo) ? undefined : shipsTo.map(obj => obj.value.code),
      shipping: isEmpty(shipping) ? undefined : shipping.map(obj => obj.id),
    }
  }
}

class PricingFiltersEditorStore {
  /** @type {import('../context').RootStore} */
  root

  @observable costFrom = ''
  @observable costTo = ''

  constructor (root) {
    this.root = root
  }

  @action.bound setCostFrom (value) {
    this.costFrom = value
  }

  @action.bound setCostTo (value) {
    this.costTo = value
  }

  @computed get productCostRangeText () {
    const from = this.costFrom && `from ${this.costFrom}`
    const to = this.costTo && `to ${this.costTo}`
    return from + (from && to && ' ') + to
  }

  @computed get totalSelectedCount () {
    return !!this.costFrom + !!this.costTo
  }

  @computed get validRange () {
    const min = parseInt(this.costFrom)
    const max = parseInt(this.costTo)
    return {
      costFrom: !isNaN(min) && min ? min.toString() : undefined,
      costTo: !isNaN(max) && max && max > (min || 0) ? max.toString() : undefined,
    }
  }

  @action.bound reset () {
    this.setCostFrom('')
    this.setCostTo('')
  }

  @action.bound updateFromParams ({ costFrom, costTo }) {
    this.setCostFrom(costFrom || '')
    this.setCostTo(costTo || '')
  }

  @computed get filterParams () {
    return this.validRange
  }
}

class MarketplaceFiltersEditorStore {
  /** @type {import('../context').RootStore} */
  root

  suppliersFiltersEditorStore
  shippingFiltersEditorStore
  pricingFiltersEditorStore

  @observable filtersVisible = false

  constructor (root, data = {}) {
    this.root = root
    this.suppliersFiltersEditorStore = new SuppliersFiltersEditorStore(root)
    this.shippingFiltersEditorStore = new ShippingFiltersEditorStore(root)
    this.pricingFiltersEditorStore = new PricingFiltersEditorStore(root)
    Object.assign(this, data)
  }

  @action.bound initialize () {
  }

  @computed get ready () {
    return true
  }

  @computed get substores () {
    return [
      this.suppliersFiltersEditorStore,
      this.shippingFiltersEditorStore,
      this.pricingFiltersEditorStore,
    ]
  }

  @computed get totalSelectedCount () {
    return sumBy(this.substores, store => store.totalSelectedCount)
  }

  @action.bound setFiltersVisible (value) {
    this.filtersVisible = value
  }

  @action.bound showFilters (params = {}) {
    this.updateFromParams(params)
    this.setFiltersVisible(true)
  }

  @action.bound hideFilters () {
    this.reset()
    this.setFiltersVisible(false)
  }

  @action.bound reset () {
    this.substores.forEach(store => store.reset())
  }

  @action.bound updateFromParams (params) {
    this.substores.forEach(store => store.updateFromParams(params))
  }

  /** All filter values as a plain object to feed into marketplaceStore's setFilters */
  @computed get filterParams () {
    return Object.assign({}, ...this.substores.map(store => store.filterParams))
  }
}

export default MarketplaceFiltersEditorStore
