import React from 'react'

import PropTypes from 'prop-types'

import { Box, Container } from '@material-ui/core'
import { observer } from 'mobx-react'

import { AppBar, Sidebar } from 'retailer/components/organisms'

import style from './AppBarOnlyTemplate.module.scss'

/**
 * A template that shows only the main App Bar and the content inside a Container,
 * with NO notifications and sidebar.
 *
 * TODO: how should the app bar's menu toggle behave on small screens?
 *
 * @param {Object} props
 * @param {import('@material-ui/core/Container/index').ContainerProps} props.ContainerProps
 */
const AppBarOnlyTemplate = ({ main, ContainerProps = {}, disableStoreSelector }) => {
  return (
    <Box
      className={style.AppBarOnlyTemplate}
      pt={{ xs: 6, md: 7.5 }}
    >
      <AppBar elevation={0} disableStoreSelector={disableStoreSelector} />
      <Sidebar temporary />
      <Container component="main" {...ContainerProps}>
        {main}
      </Container>
    </Box>
  )
}

AppBarOnlyTemplate.propTypes = {
  main: PropTypes.node,
  ContainerProps: PropTypes.object,
  disableStoreSelector: PropTypes.bool,
}

export default observer(AppBarOnlyTemplate)
