import { observable } from 'mobx'

const priceObject = {
  markupType: null,
  markup: null
}

class RetailerPricingRuleStore {
  @observable inventoryType
  @observable price = priceObject
  @observable comparedAtPrice = priceObject
  @observable freeItemPrice
  @observable includeShippingRateInPrice
  @observable fixedShippingRate
  @observable currency

  constructor (data) {
    Object.assign(this, data)
  }
}

export default RetailerPricingRuleStore
