import React from 'react'

import PropTypes from 'prop-types'

import { ShoppingBasketRounded, StorefrontRounded } from '@material-ui/icons'
import { observer } from 'mobx-react'

import { ACTION_RESTORE_TO_SYNC_LIST, ACTION_RESTORE_TO_IMPORT_LIST } from 'shared/constants/archivedRetailerItems'

import { BatchProductListActionItem } from 'retailer/components/molecules'
import { BatchProductListActions } from 'retailer/components/organisms'
import { ArchiveListPageStore } from 'retailer/stores'

import style from './ArchiveListSelector.module.scss'

const ArchiveListSelector = ({ store }) => {
  return (
    <BatchProductListActions
      className={style.ArchiveListSelector}
      disabled={store.nonRestorableFilter.active}
      selectedAll={store.selectedAll}
      selectedAllOnAllPages={store.selectedAllOnAllPages}
      selectedSome={store.selectedSome}
      togglePageSelection={store.toggleSelectAll}
      selectAllOnAllPages={store.selectAllOnAllPages}
      deselectAll={store.deselectAll}
      pagination={store.pagination}
      actions={[
        <BatchProductListActionItem
          key="importList"
          name="Restore to Import List"
          icon={<ShoppingBasketRounded />}
          disabled={!store.selectedSome && !store.selectedAll}
          onClick={() => store.batchRestore.startAction(ACTION_RESTORE_TO_IMPORT_LIST)}
        />,
        <BatchProductListActionItem
          key="syncList"
          name="Restore to Sync List"
          icon={<StorefrontRounded />}
          disabled={!store.selectedSome && !store.selectedAll}
          onClick={() => store.batchRestore.startAction(ACTION_RESTORE_TO_SYNC_LIST)}
        />
      ]}
    />
  )
}

ArchiveListSelector.propTypes = {
  store: PropTypes.instanceOf(ArchiveListPageStore).isRequired
}

export default observer(ArchiveListSelector)
