import React,
{
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
} from 'react'

import PropTypes from 'prop-types'

import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  FormLabel,
  RadioGroup,
  TextField,
} from '@material-ui/core'
import { Language } from '@material-ui/icons'
import { Alert } from '@material-ui/lab'
import { addNewStoreModalShown } from '@wix/bi-logger-modalyst/v2'
import { observer } from 'mobx-react'
import { Controller, useForm } from 'react-hook-form'

import { useBiEvents } from 'shared/bi/hooks'
import { LoadingButton, RadioTile } from 'shared/components/atoms'
import { CountrySelect, TooltipHelper } from 'shared/components/molecules'
import { DataModal } from 'shared/components/organisms'
import { SHOPIFY, WIX } from 'shared/constants/integrations'
import { useTranslation } from 'shared/hooks'
import { Shopify, WixLogo } from 'shared/icons/external'
import { getFieldErrorMessage, submitForm, useRemoteErrors } from 'shared/utils/forms'

import style from './CreateStoreModal.module.scss'

const getValidationScheme = t => ({
  name: {
    required: t('addStoreModal.storeNameField.requiredError'),
    maxLength: {
      value: 120,
      message: t('addStoreModal.storeNameField.maxLengthError', { maxLength: 120 }),
    },
  },
  integrationType: {
    required: t('addStoreModal.platformField.requiredError'),
  },
  country: {
    required: t('addStoreModal.countryField.requiredError'),
  },
})

const PlatformSelect = observer(forwardRef((props, ref) => {
  const { name, onChange, value, helperText, ...formControlProps } = props
  const radioProps = { size: 'small' }

  const { t } = useTranslation('myStores')

  return (
    <FormControl fullWidth {...formControlProps} ref={ref}>
      <FormLabel className={style.RadioTileGroupLabel}>
        {t('addStoreModal.platformField.label')}
      </FormLabel>
      <RadioGroup
        row className={style.RadioTileGroup}
        name={name} value={value} onChange={onChange}
      >
        <RadioTile
          value={WIX}
          icon={WixLogo} label="Wix"
          data-testid="createStoreModal_platformTileWix"
          {...radioProps}
        />
        <RadioTile
          value={SHOPIFY}
          icon={Shopify} label="Shopify"
          data-testid="createStoreModal_platformTileShopify"
          {...radioProps}
        />
        <RadioTile
          value="other"
          icon={Language}
          label={
            <>
              {t('addStoreModal.platformRadioOther.label')}{' '}
              <TooltipHelper
                content={t('addStoreModal.platformRadioOther.tooltip')}
                className={style.RadioTileTooltipHelper}
              />
            </>
          }
          data-testid="createStoreModal_platformTileOther"
          {...radioProps}
        />
      </RadioGroup>
      <FormHelperText>{helperText}</FormHelperText>
    </FormControl>
  )
}))

PlatformSelect.propTypes = {
  helperText: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
}

const CreateStoreForm = observer(forwardRef(({ createStoreStore, onSubmit }, ref) => {
  const { t } = useTranslation('myStores')
  const scheme = useMemo(() => getValidationScheme(t), [t])

  const {
    countryChoices,
    formDefaults,
    setFormValid,
    isSaving,
    remoteErrors,
  } = createStoreStore

  const { control, handleSubmit, register, errors, formState, setError } = useForm({
    defaultValues: formDefaults,
    mode: 'onChange',
  })
  const { isValid } = formState
  const formRef = useRef(null)

  // sync react hook form valid state with store
  useEffect(() => setFormValid(isValid), [isValid])

  const { nonFieldErrors } = useRemoteErrors(remoteErrors, setError)

  useImperativeHandle(ref, () => ({
    submit: () => submitForm(formRef)
  }))

  return (
    <form ref={formRef} onSubmit={handleSubmit(onSubmit)} noValidate>
      <TextField
        name="name"
        required disabled={isSaving}
        inputRef={register(scheme.name)}
        error={!!errors.name}
        label={t('addStoreModal.storeNameField.label')}
        placeholder={t('addStoreModal.storeNameField.placeholder')}
        helperText={getFieldErrorMessage(errors, 'name')}
        fullWidth variant="outlined"
        data-testid="CreateStoreModal-CreateStoreForm-storeName"
      />

      <Controller
        data-testid="createStoreModal_integrationTypeRadioGroup"
        name="integrationType"
        control={control}
        rules={scheme.integrationType}
        required={!!scheme.integrationType?.required}
        disabled={isSaving}
        error={!!errors.integrationType}
        helperText={getFieldErrorMessage(errors, 'integrationType') || ' '}
        as={<PlatformSelect />}
        className={style.PlatformSelect}
      />

      <Controller
        data-testid="createStoreModal_countrySelect"
        name="country"
        control={control}
        label={t('addStoreModal.countryField.label')}
        placeholder={t('addStoreModal.countryField.placeholder')}
        rules={scheme.country}
        required={!!scheme.country.required}
        error={!!errors.country}
        helperText={
          getFieldErrorMessage(errors, 'country') ||
          t('addStoreModal.countryField.tooltip')
        }
        as={
          <CountrySelect countries={countryChoices} />
        }
        variant="outlined" fullWidth
      />

      {nonFieldErrors && (
        <Alert severity="error" component={Box} mt={2}>
          {nonFieldErrors?.join('')}
        </Alert>
      )}

      <input type="submit" style={{ display: 'none' }} />
    </form>
  )
}))

CreateStoreForm.propTypes = {
  createStoreStore: PropTypes.shape({
    countryChoices: PropTypes.arrayOf(PropTypes.string).isRequired,
    formDefaults: PropTypes.object.isRequired,
    isSaving: PropTypes.bool,
    remoteErrors: PropTypes.object,
    setFormValid: PropTypes.func.isRequired,
    submitFormData: PropTypes.func.isRequired,
  }).isRequired,
  onSubmit: PropTypes.func.isRequired,
}

const CreateStoreModal = ({ createStoreStore, onFormSubmit, onExited }) => {
  const modalRef = useRef(null)
  const formRef = useRef(null)

  const { correlationId, logBiEvent } = useBiEvents({ correlateWithLocation: true })

  const { t } = useTranslation('myStores')

  const { isFormValid, isSaving, isDone } = createStoreStore

  useEffect(() => {
    (async () => {
      await logBiEvent(addNewStoreModalShown({ correlationId, origin: 'retailer' }))
    })()
  }, [])

  const onSubmit = data => onFormSubmit(data, { correlationId })

  const handleSubmitClick = () => {
    formRef?.current && formRef.current.submit()
  }

  return (
    <DataModal
      hide={isDone}
      ref={modalRef}
      fullWidth maxWidth="sm"
      title={t('addStoreModal.title')}
      renderBody={() => (
        <CreateStoreForm
          ref={formRef}
          createStoreStore={createStoreStore}
          onSubmit={onSubmit}
        />
      )}
      renderActions={({ dismissModal }) => (
        <>
          <Button
            variant="outlined" color="primary"
            disabled={isSaving}
            onClick={dismissModal}
          >
            {t('addStoreModal.cancelAction.cta')}
          </Button>
          <LoadingButton
            variant="contained" color="primary"
            loading={isSaving}
            disabled={isSaving || !isFormValid}
            onClick={handleSubmitClick}
            testId="CreateStoreModal-nextButton"
          >
            {t('addStoreModal.nextAction.cta')}
          </LoadingButton>
        </>
      )}
      onExited={onExited}
    />

  )
}

CreateStoreModal.propTypes = {
  createStoreStore: PropTypes.shape({
    isFormValid: PropTypes.bool,
    isSaving: PropTypes.bool,
    isDone: PropTypes.bool,
  }).isRequired,
  onExited: PropTypes.func,
  onFormSubmit: PropTypes.func.isRequired,
}

export default observer(CreateStoreModal)
