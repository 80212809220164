import React, { forwardRef } from 'react'

import PropTypes from 'prop-types'

import { IconButton, Typography } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { withStyles } from '@material-ui/styles'
import clsx from 'clsx'

const dialogTitleStyles = {
  root: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '16px 24px',
    backgroundColor: 'inherit',
  },
  title: {
    color: 'inherit',
  },
  close: {
    color: 'inherit',
    margin: '-8px -12px -8px 0',
  },
}

const CustomDialogTitle = forwardRef(function DialogTitle (props, ref) {
  const { children, classes, className, onCloseClick, testId, ...other } = props
  return (
    <div className={clsx(classes.root, className)} ref={ref} {...other}>
      <Typography component="h3" className={classes.title} data-testid={testId}>{children}</Typography>
      <IconButton
        aria-label="close" className={classes.close} onClick={onCloseClick}
        testId={testId ? `${testId}-closeButton` : undefined}
      >
        <CloseIcon />
      </IconButton>
    </div>
  )
})

CustomDialogTitle.propTypes = {
  children: PropTypes.node,
  classes: PropTypes.object,
  className: PropTypes.string,
  onCloseClick: PropTypes.func,
  testId: PropTypes.string,
}

const ModalTitle = withStyles(dialogTitleStyles, { name: 'MuiDialogTitle' })(CustomDialogTitle)

export default ModalTitle
