import { action, computed, observable } from 'mobx'

import {
  restoreArchivedRetailerItemToImportList,
  restoreArchivedRetailerItemToSyncList,
} from 'shared/api/archivedRetailerItems'
import { toTitleCase } from 'shared/utils/text'

class ArchivedItemStore {
  parent

  id
  itemUuid
  name
  source
  vendor
  image
  removalReason
  removalReasonReadable
  restorable
  created

  @observable selected = false

  constructor (parent, data) {
    this.parent = parent
    Object.assign(this, data)
  }

  /**
   * @returns {String}
   */
  @computed get readableVendor () {
    if (!this.vendor || this.vendor === '') return toTitleCase(this.source)
    return this.vendor
  }

  @action.bound
  setSelected (value) {
    this.selected = Boolean(value)
  }

  @action.bound
  restoreToImportList () {
    return restoreArchivedRetailerItemToImportList(this.id)
  }

  @action.bound
  restoreToSyncList () {
    return restoreArchivedRetailerItemToSyncList(this.id)
  }
}

export default ArchivedItemStore
