import React, { useContext } from 'react'

import PropTypes from 'prop-types'

import { observer } from 'mobx-react'

const FeatureContext = React.createContext()

// hooks

const useFeatureFlags = () => (useContext(FeatureContext) || {})

// components

const FeatureProvider = observer(({ children, featureFlagsStore }) => {
  return (
    <FeatureContext.Provider value={featureFlagsStore}>
      {children}
    </FeatureContext.Provider>
  )
})

FeatureProvider.propTypes = {
  featureFlagsStore: PropTypes.object.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
}

const RequireFeature = ({ name, children }) => {
  const featureFlags = useFeatureFlags()
  return featureFlags[name] ? children : null
}

RequireFeature.propTypes = {
  name: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
}

export {
  useFeatureFlags,
  FeatureProvider,
  RequireFeature,
}
