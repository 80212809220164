import { action, observable } from 'mobx'

import { setShippingCountry } from 'shared/api/retailerPricingRules'

class SetPrimaryShippingCountryStore {
  /** @type {import('../context').RootStore} */
  root

  @observable country
  @observable isSaving
  @observable isDone = false
  @observable isError = false

  constructor (root) {
    this.root = root
    this.country = this.root.userProfileStore.currentStore.country
  }

  @action.bound setCountry (iso) {
    this.country = iso
  }

  @action.bound async save () {
    this.isSaving = true
    try {
      await setShippingCountry({ iso: this.country })
      await this.root.userProfileStore.refresh()
      this.isSaving = false
      this.isDone = true
    } catch (error) {
      this.isSaving = false
      this.isError = true
    }
  }
}

export default SetPrimaryShippingCountryStore
