import { action, observable } from 'mobx'

/**
 * Holds and mutates common properties and value objects of API operation
 */
class ApiStatusStore {
  @observable isLoading
  @observable isLoaded
  @observable isSaving
  @observable isDeleting
  @observable remoteErrors

  @action.bound
  reset () {
    this.isLoading = undefined
    this.isLoaded = undefined
    this.isSaving = undefined
    this.isDeleting = undefined
    this.remoteErrors = undefined
  }

  @action.bound
  setIsLoading (value) {
    this.isLoading = value
  }

  @action.bound
  setIsLoaded (value) {
    this.isLoaded = value
  }

  @action.bound
  setIsSaving (value) {
    this.isSaving = value
  }

  @action.bound
  setIsDeleting (value) {
    this.isDeleting = value
  }

  @action.bound
  setRemoteErrors (value) {
    this.remoteErrors = value
  }
}

export default ApiStatusStore
