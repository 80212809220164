import { RESTORE_DESTINATION_IMPORT_LIST, RESTORE_DESTINATION_SYNC_LIST } from 'shared/constants/archivedRetailerItems'

import { api } from './common'

const baseUrl = '/api/v3/archived_retailer_items/'

/**
 * @param {string} queryString
 * @returns {Promise<AxiosResponse<any>>}
 */
const getArchivedRetailerItems = queryString => {
  return api.get(baseUrl + '?' + queryString)
}

const getArchivedRetailerItem = id => {
  return api.get(baseUrl + id + '/')
}

const restoreArchivedRetailerItemToImportList = id => {
  return api.put(baseUrl + id + '/restore/', {
    destination: RESTORE_DESTINATION_IMPORT_LIST
  })
}

const restoreArchivedRetailerItemToSyncList = id => {
  return api.put(baseUrl + id + '/restore/', {
    destination: RESTORE_DESTINATION_SYNC_LIST
  })
}

const getAllRestorableItemIds = () => {
  return api.get(baseUrl + 'all_restorable_ids/')
}

export {
  getArchivedRetailerItems,
  getArchivedRetailerItem,
  restoreArchivedRetailerItemToImportList,
  restoreArchivedRetailerItemToSyncList,
  getAllRestorableItemIds
}
