import React, { useRef, useState } from 'react'

import PropTypes from 'prop-types'

import { Checkbox, FormControl, FormControlLabel, FormGroup, FormLabel, FormHelperText } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { observer } from 'mobx-react'
import { useSnackbar } from 'notistack'
import { useForm, Controller } from 'react-hook-form'

import { CardForm } from 'shared/components/organisms'
import { useRemoteErrors } from 'shared/utils/forms'

import { MarketplacesSettingsStore } from 'retailer/stores'

import style from './MarketplacesSettingsForm.module.scss'

const MarketplacesSettingsForm = ({ store }) => {
  const ref = useRef(null)
  const { enqueueSnackbar } = useSnackbar()
  const [checked, setChecked] = useState(store.marketplaces)
  const { handleSubmit, formState, errors, reset, control, register, setError } = useForm({
    defaultValues: {
      marketplaces: checked
    }
  })
  const { nonFieldErrors } = useRemoteErrors(store.errors, setError)

  const onSubmit = (data) => {
    store.save(data).then(() => {
      handleReset()
      enqueueSnackbar('Marketplaces settings saved successfully', {
        variant: 'success'
      })
    })
  }

  const handleCheck = (value) => {
    if (checked.includes(value)) {
      setChecked(checked.filter(checkedValue => checkedValue !== value))
    } else {
      setChecked([...checked, value])
    }
  }

  const handleReset = () => {
    setChecked(store.marketplaces)
    reset()
  }

  return (
    <CardForm
      title="Marketplaces"
      subheader="Which marketplaces do you sell through?"
      formRef={ref}
      canSubmit={formState.isDirty && !Object.keys(errors).length}
      isSubmitting={store.isSaving}
      onDiscard={formState.isDirty ? handleReset : null}
    >
      {nonFieldErrors && (
        <Alert severity="error" className={style.Errors}>
          {nonFieldErrors.join(' ')}
        </Alert>
      )}
      <form
        className={style.MarketplacesSettingsForm}
        onSubmit={handleSubmit(onSubmit)}
        ref={ref}
      >
        <FormControl component="fieldset" error={!!errors.marketplaces?.message}>
          <FormLabel component="legend" hidden>
            Which marketplaces do you sell through?
          </FormLabel>
          <FormHelperText>{errors.marketplaces?.message}</FormHelperText>
          <Controller
            name="marketplaces"
            control={control}
            defaultValue={checked}
            render={({ onChange }) => {
              return (
                <FormGroup>
                  {store.allChoices.map(provider => {
                    return (
                      <FormControlLabel
                        key={provider}
                        control={
                          <Checkbox
                            id={`marketplaces.${provider}`}
                            name="marketplaces"
                            value={provider}
                            checked={checked.includes(provider)}
                            onChange={() => onChange(handleCheck(provider))}
                            inputRef={register()}
                          />
                        }
                        label={provider}
                      />
                    )
                  })}
                </FormGroup>
              )
            }}
          />
        </FormControl>
      </form>
    </CardForm>
  )
}

MarketplacesSettingsForm.propTypes = {
  store: PropTypes.instanceOf(MarketplacesSettingsStore).isRequired
}

export default observer(MarketplacesSettingsForm)
