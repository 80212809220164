import React, { useCallback, useEffect, useRef, useState } from 'react'

import PropTypes from 'prop-types'

import { Typography } from '@material-ui/core'
import { observer } from 'mobx-react'
import { useSnackbar } from 'notistack'
import { useFormContext } from 'react-hook-form'

import { RichTextInput } from 'shared/components/organisms'
import { TAB_DESCRIPTION } from 'shared/constants/importList'
import { useTranslation } from 'shared/hooks'
import { importListItemType } from 'shared/types'
import { getFieldErrorMessage } from 'shared/utils/forms'

import { checkIfTabHasErrors, getDescriptionFieldName } from 'retailer/utils/importList'

import style from './ImportListProductDescriptionTab.module.scss'

const fieldName = getDescriptionFieldName()

const ImportListProductDescriptionTab = ({ item, errors }) => {
  const { t } = useTranslation('importList')
  const { getValues, setValue, handleSubmit, trigger, control, register, reset, watch } = useFormContext()
  const inputRef = useRef(null)
  const { enqueueSnackbar } = useSnackbar()
  const [biEvent, setBiEvent] = useState(null)

  const resetForm = useCallback((data = {}) => {
    reset({
      ...getValues(),
      [TAB_DESCRIPTION]: { description: data.description }
    }, { errors: true })
    trigger()
  }, [item, reset, trigger])

  register(fieldName)

  const onSubmit = async data => {
    const descriptionData = data[TAB_DESCRIPTION]
    resetForm({ description: descriptionData.description })
    const saved = await item.saveDescription(descriptionData, biEvent)
    if (saved) {
      resetForm({ description: item.description })
      enqueueSnackbar(`${item.name || 'Item'} description was saved`, { variant: 'success' })
    } else {
      enqueueSnackbar(
        `${item.name || 'Item'} description was not saved. Please, ensure there are no errors`,
        { variant: 'error' }
      )
    }
  }

  const onError = () => {
    if (checkIfTabHasErrors(errors[TAB_DESCRIPTION])) {
      enqueueSnackbar(
      `${item.name || 'Item'} description was not saved. Please, ensure there are no errors`,
      { variant: 'error' }
      )
      return
    }
    return onSubmit(getValues())
  }

  useEffect(() => { trigger() }, [trigger])

  return (
    <div className={style.ImportListProductDescriptionTab}>
      <RichTextInput
        fullWidth
        error={!!getFieldErrorMessage(errors, fieldName)}
        helperText={getFieldErrorMessage(errors, fieldName)}
        label="Description"
        testId="importList-importListProduct-descriptionTab-description"
        name={fieldName}
        defaultValue={control.defaultValuesRef.current?.[TAB_DESCRIPTION]?.description}
        ref={inputRef}
        onBlur={async e => {
          e.persist()
          const previousValue = getValues(fieldName)
          const descriptionValue = inputRef.current.value
          if (descriptionValue === previousValue) return
          const biEvent = await item.logProductEdited({
            settingTab: TAB_DESCRIPTION,
            settingField: 'description',
            oldValue: previousValue,
            newValue: descriptionValue,
          })
          setBiEvent(biEvent)
          await setValue(fieldName, descriptionValue, { shouldDirty: true, shouldValidate: true })
          return handleSubmit(onSubmit, onError)(e)
        }}
        className={style.Editor}
      />
      <Typography className={style.Counter}>
        {t('main.productDescription.characters.label')}: {watch(fieldName).length}/8000
      </Typography>
    </div>
  )
}

ImportListProductDescriptionTab.propTypes = {
  item: importListItemType.isRequired,
  errors: PropTypes.object,
}

ImportListProductDescriptionTab.defaultProps = {
  errors: {},
}

export default observer(ImportListProductDescriptionTab)
