import { action, computed } from 'mobx'

import { RetailerProductStore } from 'retailer/stores'

class ProductDraftStore extends RetailerProductStore {
  @computed get id () { return this.uuid }
  @computed get productId () { return this.itemUuid }
  @computed get draftRef () { return this.customizedItemDraftRef }
  @computed get jobRef () { return this.customizedItemJobRef }

  @action.bound async edit () {
    await this.logDesignClickedBiEvent()
    this.editDesign()
  }
}

export default ProductDraftStore
