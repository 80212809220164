import React from 'react'

import PropTypes from 'prop-types'

import { Avatar, ListItem, ListItemAvatar, ListItemText } from '@material-ui/core'
import { Check, PriorityHigh, Launch } from '@material-ui/icons'
import clsx from 'clsx'
import { observer } from 'mobx-react'
import { OutboundLink } from 'react-ga'

import style from './SupplierAppConnectionStep.module.scss'

const SupplierAppConnectionStep = ({ eventLabel, to, target, status, disabled, primary, secondary }) => {
  const _active = to && !disabled
  const _to = _active ? to : undefined
  const _target = _active ? target : undefined

  const linkProps = eventLabel
    ? { component: _active ? OutboundLink : 'div', to: _to, target: _target, eventLabel }
    : { component: _active ? 'a' : 'div', href: _to, target: _target }

  const rootClassName = clsx(style.Root, style[`Root-${status}`])
  const icon = status === 'error' ? <PriorityHigh /> : <Check />
  return (
    <ListItem
      {...linkProps}
      button={!disabled}
      disabled={disabled}
      alignItems="flex-start"
      className={rootClassName}
    >
      <ListItemAvatar className={style.Avatar}>
        <Avatar>{icon}</Avatar>
      </ListItemAvatar>
      <ListItemText primary={primary} secondary={secondary} />
      {!disabled && <Launch className={style.ActionIcon} />}
    </ListItem>
  )
}

SupplierAppConnectionStep.propTypes = {
  to: PropTypes.string,
  target: PropTypes.string,
  eventLabel: PropTypes.string,
  primary: PropTypes.string.isRequired,
  secondary: PropTypes.string,
  status: PropTypes.oneOf(['default', 'success', 'error']).isRequired,
  disabled: PropTypes.bool,
}

SupplierAppConnectionStep.defaultProps = {
  status: 'default',
  disabled: false,
}

export default observer(SupplierAppConnectionStep)
