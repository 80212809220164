import { computed, observable } from 'mobx'

import { DPO_STATUS_REFUNDED, DPO_STATUS_RESERVED_CANCELLED, ORDER_STATUS_TO_ORDER } from 'shared/constants/orders'

class Order {
  @observable purchaseOrder

  @observable uuid
  @observable image

  @observable itemCost
  @observable totalCosts
  @observable shippingCost
  @observable shippingMethod
  @observable itemName
  @observable options = {}
  @observable quantity
  @observable sku

  @observable itemUuid
  @observable itemUrl
  @observable itemExternalUrl

  /**
   * @param {PurchaseOrder} parent  Parent PurchaseOrder
   * @param {Object}        data    Optional initial data
   */
  constructor (parent, data = {}) {
    this.purchaseOrder = parent
    Object.assign(this, data)
  }

  @computed get isShippingMethodEditable () {
    return (
      this.purchaseOrder.is3rdPartySupplierOrder &&
      this.purchaseOrder.unifiedStatus === ORDER_STATUS_TO_ORDER &&
      ![DPO_STATUS_RESERVED_CANCELLED, DPO_STATUS_REFUNDED].includes(
        this.purchaseOrder.status
      )
    )
  }
}

export default Order
