import { action, computed, observable, reaction } from 'mobx'
import qs from 'query-string'

import { adaptGetImportResponse, adaptGetImportItemsResponse } from 'shared/adapters/imports'
import { getExportFailedUrl, getImport, getImportItems } from 'shared/api/imports'
import { BaseListStore } from 'shared/stores'

import { ImportStore, ImportedProductStore } from 'supplier/stores'

export const PATHNAME_REGEX = /\/import-reports\/(?<importId>[0-9a-z-]+){1}(\/(?<status>[a-z-]+)){0,1}$/

class ImportItemsStore extends BaseListStore {
  pathnameRegex = PATHNAME_REGEX

  listParamsGetter = () => ({ importId: this.importId, status: this.status, offset: this.offset })

  constructor (routerStore, data = {}) {
    super(routerStore, data)
    reaction(this.listParamsGetter, this.listParamsReaction)
  }

  @computed get importId () {
    return this.pathnameMatch?.groups.importId
  }

  @computed get status () {
    return this.pathnameMatch?.groups.status
  }

  getUrl (params) {
    const { importId, status, offset } = params
    const search = qs.stringify({ offset: offset || undefined })
    return `/import-reports/${importId}/${status}` + (search ? `?${search}` : '')
  }

  doFetch () {
    const { importId, status, offset } = this
    return getImportItems(importId, { offset, status })
      .then(response => {
        const results = adaptGetImportItemsResponse(response.data).results
        this.setItems(results.map(row => new ImportedProductStore(row)))
        return response
      })
  }
}

/**
 * Store responsible for loading and handling of Import details
 */
class ImportDetailsStore {
  /** @type {import('../root').RootStore} */
  root

  @observable isLoading
  @observable isNotFound

  @observable importStore
  @observable itemsStore

  /**
   * @param {import('../root').RootStore} root
   */
  constructor (root, data = {}) {
    this.root = root
    this.update(data)
    this.itemsStore = new ImportItemsStore(root.routerStore)
  }

  @computed get importId () {
    return this.itemsStore.importId
  }

  @computed get itemsStatus () {
    return this.itemsStore.status
  }

  @computed get hasFailedItems () {
    return this.importStore && this.importStore.productsFailedCount > 0
  }

  @action.bound
  update (data) {
    Object.assign(this, data)
  }

  @action.bound
  reset () {
    this.isLoading = undefined
    this.isNotFound = undefined
    this.importStore = undefined
    this.itemsStore.reset()
  }

  @action.bound
  setIsLoading (value) {
    this.isLoading = value
  }

  @action.bound
  setIsNotFound (value) {
    this.isNotFound = value
  }

  @action.bound
  setImportStore (obj) {
    this.importStore = obj
  }

  @action.bound
  fetch (id) {
    this.setIsLoading(true)
    return getImport(id)
      .then(response => {
        const data = adaptGetImportResponse(response.data)
        this.setImportStore(new ImportStore(data))
      })
      .catch(error => {
        this.setIsNotFound(error.response && error.response.status === 404)
      })
      .finally(() => {
        this.setIsLoading(false)
      })
  }

  /** Export failed import items */
  @action.bound
  exportFailed (format) {
    window.open(getExportFailedUrl(this.importId))
  }
}

export default ImportDetailsStore
