class DesignerStore {
  uuid
  name
  fulfillmentStars
  leadTimes
  shippingTimeDomestic
  shippingTimeInternational

  constructor (data) {
    Object.assign(this, data)
  }
}

export default DesignerStore
