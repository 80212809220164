import { action, observable } from 'mobx'

class ProductOptionStore {
  uuid
  @observable key
  @observable value
  @observable protected = true

  constructor (data = {}) {
    Object.assign(this, data)
  }

  @action.bound
  setKey (value) {
    this.key = value
  }
}

export default ProductOptionStore
