export const overrides = baseTheme => ({
  MuiAppBar: {
    root: {
      zIndex: baseTheme.zIndex.drawer + 1,
    },
    colorDefault: {
      backgroundColor: '#FFF',
    },
    colorSecondary: {
      backgroundColor: '#152138',
    },
  },
  MuiToolbar: {
    regular: {
      [baseTheme.breakpoints.up('sm')]: {
        minHeight: baseTheme.spacing(7.5),
      },
    }
  },
})
