const TAB_GENERAL = 'general'
const TAB_DESCRIPTION = 'description'
const TAB_VARIANTS = 'variants'
const TAB_PRICING = 'pricing'
const TAB_MEDIA = 'media'

/**
 * The tab name has to be processed using the `useTranslation` hook.
 * @type {Map<string, string>}
 */
const IMPORT_LIST_ITEM_TABS = new Map([
  [TAB_GENERAL, 'main.productGeneral.label'],
  [TAB_DESCRIPTION, 'main.productDescription.label'],
  [TAB_VARIANTS, 'main.productVariants.label'],
  [TAB_PRICING, 'main.productPricing.label'],
  [TAB_MEDIA, 'main.productMedia.label'],
])

export {
  TAB_GENERAL,
  TAB_DESCRIPTION,
  TAB_VARIANTS,
  TAB_PRICING,
  TAB_MEDIA,
  IMPORT_LIST_ITEM_TABS,
}
