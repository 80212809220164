import { action, computed } from 'mobx'

import { setContextAttribute } from 'shared/api/profiles'

import { adaptGetUserProfileResponse } from 'supplier/adapters/profiles'

class OnboardingStore {
  /** @type {import('../root').RootStore} */
  root

  /**
   * @param {import('../root').RootStore} root
   */
  constructor (root) {
    this.root = root
  }

  @computed get data () {
    return this.root.userProfileStore.context
  }

  @computed get dismissed () {
    return !!this.root.userProfileStore.context.dismissedOnboardingWidget
  }

  @computed get steps () {
    const { isShippingSetUp, hasPendingApprovalRequest, isApproved } = this.root.userProfileStore
    return {
      productInfoComplete: !!this.data.productInfoComplete,
      shippingInfoComplete: !!isShippingSetUp,
      businessInfoComplete: !!this.data.businessInfoComplete,
      submittedApprovalRequest: !!(isApproved || hasPendingApprovalRequest),
    }
  }

  @computed get hasCompletedRequiredSteps () {
    return Object.entries(this.steps).filter(([key, _]) => [
      'productInfoComplete', 'shippingInfoComplete', 'businessInfoComplete'
    ].includes(key)).every(([_, val]) => !!val)
  }

  @computed get canRequestApproval () {
    const { isApproved, hasPendingApprovalRequest } = this.root.userProfileStore
    return this.hasCompletedRequiredSteps && !isApproved && !hasPendingApprovalRequest
  }

  @computed get stepsAllCount () {
    return Object.keys(this.steps).length
  }

  @computed get stepsDone () {
    return Object.entries(this.steps).filter(([key, val]) => val).map(([key, val]) => key)
  }

  @computed get stepsDoneCount () {
    return this.stepsDone.length
  }

  @computed get finished () {
    return this.stepsDoneCount === this.stepsAllCount
  }

  @computed get isApproved () {
    return this.root.userProfileStore.isApproved
  }

  @computed get mainTitle () {
    const { isApproved, hasPendingApprovalRequest } = this.root.userProfileStore
    if (isApproved) return 'Your account has been approved'
    if (hasPendingApprovalRequest) return 'Your application is under review'
    return 'Set up your account and get approved'
  }

  @computed get productInfoCompleteTitles () {
    const { storeConnected, productInfoComplete } = this.data
    switch (true) {
      case storeConnected && productInfoComplete: return [
        'You have synced your products',
        'Click here to manage your collection',
      ]
      case productInfoComplete: return [
        'Your have added your products',
        'Click here to manage your collection',
      ]
      case storeConnected && !productInfoComplete: return [
        'Review your products',
        'Some of the synced products need action before publishing',
      ]
      default: return [
        'Add your products',
        undefined,
      ]
    }
  }

  @computed get shippingInfoCompleteTitles () {
    const { shippingInfoComplete } = this.steps
    switch (true) {
      case !!shippingInfoComplete: return [
        'You have added your shipping information',
        'Click here to change the shipping options',
      ]
      default: return [
        'Set up shipping information',
        undefined,
      ]
    }
  }

  @computed get businessInfoCompleteTitles () {
    const { businessInfoComplete } = this.steps
    switch (true) {
      case businessInfoComplete: return [
        'You have provided the company information',
        undefined,
      ]
      default: return [
        'Complete your business profile',
        'Click here to edit your business profile',
      ]
    }
  }

  @computed get submittedApprovalRequestTitles () {
    const { isApproved, hasPendingApprovalRequest } = this.root.userProfileStore
    switch (true) {
      case isApproved: return [
        'Your request has been accepted',
        'You can now sell your products on Modalyst'
      ]
      case hasPendingApprovalRequest: return [
        'Approval request has been sent',
        'Please wait for us to review your application',
      ]
      default: return [
        'Submit your brand for approval',
        'All of the steps above need to be completed',
      ]
    }
  }

  @action.bound
  dismiss () {
    setContextAttribute('dismissed_onboarding_widget', true).then(response => {
      const data = adaptGetUserProfileResponse(response.data)
      this.root.userProfileStore.update(data)
    })
  }
}

export default OnboardingStore
