import { addProductToSyncListClicked, removeProductClicked } from '@wix/bi-logger-modalyst/v2'
import { action, computed, observable } from 'mobx'

import { adaptImportListApiParams } from 'shared/adapters/retailerItems'
import {
  exportRetailerItem,
  getRetailerItemsIds,
  removeRetailerItem,
} from 'shared/api/retailerItems'
import {
  ACTION_ADD_TO_STORE,
  ACTION_REMOVE_FROM_STORE,
} from 'shared/constants/retailerItems'
import { IteratingBatchActionStore } from 'shared/stores'
import { getBiHeadersFromEvent } from 'shared/stores/BiLoggerStore/utils'

class ImportListBatchActionStore extends IteratingBatchActionStore {
  root

  @observable importListPageStore
  @observable action
  @observable biHeaders

  constructor (root, importListPageStore) {
    super()
    this.root = root
    this.importListPageStore = importListPageStore
  }

  /**
   * @returns {(function(*): Promise<AxiosResponse<any>>) | *}
   */
  @computed get actionFunc () {
    const actionsMap = new Map([
      [ACTION_ADD_TO_STORE, exportRetailerItem],
      [ACTION_REMOVE_FROM_STORE, removeRetailerItem],
    ])
    return actionsMap.get(this.action)
  }

  @action.bound
  startAction (action) {
    this.action = action
    return this.initialize({
      onError: e => console.error(e),
      after: () => this.importListPageStore.fetch()
    })
  }

  @action.bound
  async run () {
    const biEvent = await this.logBiEvent()
    this.biHeaders = getBiHeadersFromEvent(biEvent)
    await this.asyncHandlerStore.run()
  }

  @action.bound
  async getObjects () {
    if (!this.importListPageStore.allOnAllPagesSelected) {
      // Current page items only
      return Promise.resolve(this.importListPageStore.selectedItems.map(item => item.uuid))
    }
    const allIdsResponse = await getRetailerItemsIds(adaptImportListApiParams(this.importListPageStore.apiParams))
    return Promise.resolve(allIdsResponse.data)
  }

  @action.bound
  async processObject (obj) {
    if (!this.actionFunc) throw Error(`Action '${this.action}' not implemented!`)
    return await this.actionFunc(obj, this.biHeaders)
  }

  @action.bound
  async logBiEvent () {
    const listStore = this.importListPageStore
    const params = { ...listStore.biContext, isBulk: true }
    switch (this.action) {
      case ACTION_ADD_TO_STORE:
        return listStore.oneSelected
          ? await listStore.firstSelected.logAddProductToSyncListClicked(params)
          : await this.root.biLoggerStore.log(addProductToSyncListClicked(params))
      case ACTION_REMOVE_FROM_STORE:
        return listStore.oneSelected
          ? await listStore.firstSelected.logRemoveProductClicked(params)
          : await this.root.biLoggerStore.log(removeProductClicked(params))
      default: return undefined
    }
  }

  @action.bound
  reset () {
    super.reset()
    this.action = undefined
    this.biHeaders = undefined
  }
}

export default ImportListBatchActionStore
