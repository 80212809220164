import { computed, observable } from 'mobx'

import { ALIEXPRESS } from 'shared/constants/integrations'

class MarketplaceItemBrandStore {
  @observable uuid
  @observable name
  @observable source
  @observable inventoryType
  @observable externalId
  @observable followersCount = 0
  @observable productsCount = 0

  constructor (data) {
    Object.assign(this, data)
  }

  @computed get id () {
    return this.uuid
  }

  @computed get externalUrl () {
    if (!this.externalId) return
    if (this.source === ALIEXPRESS) return 'https://aliexpress.com/store/' + this.externalId
  }
}

export default MarketplaceItemBrandStore
