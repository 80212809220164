import React from 'react'

import PropTypes from 'prop-types'

import { Grid, LinearProgress, Typography } from '@material-ui/core'
import clsx from 'clsx'
import { observer } from 'mobx-react'

import style from './ProgressBar.module.scss'

const ProgressBar = ({ plural, indeterminate, processedCount, totalCount, className }) => {
  if (indeterminate) {
    return (
      <Grid container spacing={2} alignItems="center" className={clsx(style.ProgressBar, className)}>
        <Grid item xs={12}>
          <LinearProgress
            variant="indeterminate"
            className={style.Bar}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography align="center" variant="body2">
            Processing {plural}...
          </Typography>
        </Grid>
      </Grid>
    )
  }

  const percent = Math.round(processedCount / totalCount * 100)
  return (
    <Grid container spacing={2} alignItems="center" className={clsx(style.ProgressBar, className)}>
      <Grid item xs>
        <LinearProgress
          value={percent}
          variant="determinate"
          className={style.Bar}
        />
      </Grid>
      <Grid item xs="auto" className={style.Percentage}>
        <Typography variant="body2" align="right">
          {percent}%
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography align="center" variant="body2">
          {processedCount + 1}/{totalCount} {plural}
        </Typography>
      </Grid>
    </Grid>
  )
}

ProgressBar.propTypes = {
  plural: PropTypes.string,
  indeterminate: PropTypes.bool,
  processedCount: PropTypes.number,
  totalCount: PropTypes.number,
  className: PropTypes.string
}

ProgressBar.defaultProps = {
  plural: 'Objects',
  indeterminate: false,
  className: ''
}

export default observer(ProgressBar)
