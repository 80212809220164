import { api } from './common'

const baseUrl = '/api/v3/product_drafts/'

const getProductDrafts = (params = {}, cancelToken) => {
  return api.get(baseUrl, { params, cancelToken })
}

const postProductDraft = (data = {}, headers = {}) => {
  return api.post(baseUrl, data, { headers })
}

const putProductDraft = (uuid, data, headers = {}) => {
  return api.put(`${baseUrl}${uuid}/`, data, { headers })
}

const importProductDraft = (uuid, headers = {}) => {
  return api.post(`${baseUrl}${uuid}/import/`, undefined, { headers })
}

const deleteProductDraft = uuid => {
  return api.delete(`${baseUrl}${uuid}/`)
}

export {
  postProductDraft,
  putProductDraft,
  importProductDraft,
  getProductDrafts,
  deleteProductDraft,
}
