import { action, observable } from 'mobx'

import { adaptShippingOption, adaptShippingProviderForApi } from 'shared/adapters/retailerItems'
import { getRetailerItemShipping, updateRetailerItemShipping } from 'shared/api/retailerItems'
import { LoadableStore } from 'shared/stores'

class ImportListProductShippingStore {
  @observable parent
  @observable error
  @observable loading = new LoadableStore()
  @observable isOpen = false
  @observable selectedProvider

  @observable shippingOptions = []

  constructor (parent) {
    this.parent = parent
    this.selectedProvider = this.parent?.supplierProductShipping
  }

  @action.bound
  setOpen (value) {
    this.isOpen = !!value
    if (!!value && !this.loading.isLoaded) this.fetch()
  }

  @action.bound
  selectProvider (provider) {
    this.selectedProvider = provider
  }

  @action.bound
  async fetch () {
    this.loading.setLoading()
    try {
      const response = await getRetailerItemShipping(this.parent.uuid)
      this.shippingOptions = response.data.shipping_info_list.map(adaptShippingOption)
      this.loading.setLoaded()
      return true
    } catch (e) {
      this.error = parseInt(e.response.status, 10)
      this.loading.setLoaded()
      return false
    }
  }

  @action.bound
  async save () {
    await updateRetailerItemShipping(
      this.parent.uuid, adaptShippingProviderForApi(this.selectedProvider)
    )
    await this.parent.fetch()
    await this.parent.loadVariants()
    this.reset()
  }

  @action.bound
  reset () {
    this.error = undefined
    this.loading.reset()
    this.setOpen(false)
    this.selectedProvider = undefined
    this.shippingOptions = []
  }
}

export default ImportListProductShippingStore
