import React from 'react'

import PropTypes from 'prop-types'

import { Box, Container } from '@material-ui/core'
import clsx from 'clsx'
import { observer } from 'mobx-react'
import { Helmet } from 'react-helmet'

import { TopContentBanner } from 'retailer/components/molecules'
import { Alerts, AppBar, Sidebar } from 'retailer/components/organisms'

import style from './SidebarWithHeaderTemplate.module.scss'

const SidebarWithHeaderTemplate = ({
  pageTitle,
  notifications, header, main,
  marketplaceStore,
  HeaderProps, ContainerProps,
}) => {
  const { className: headerClassName } = HeaderProps || {}

  return (
    <Box
      className={style.SidebarWithHeaderTemplate}
      pt={{ xs: 6, md: 7.5 }}
    >
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <AppBar elevation={0} />
      <Sidebar />
      <main className={style.MainContainer}>
        {/* FIXME: replace with render prop */}
        {marketplaceStore.showUnlimitedProductsBanner &&
          <TopContentBanner>
            Your plan includes unlimited print-on-demand products.
          </TopContentBanner>}
        <header className={clsx(style.Header, headerClassName)}>
          <Container disableGutters {...ContainerProps}>
            {notifications && React.cloneElement(notifications, { mb: { xs: 2, lg: 3 } })}
            {header}
          </Container>
        </header>
        <div className={style.Main}>
          <Container disableGutters {...ContainerProps}>
            {main}
          </Container>
        </div>
        <Alerts />
      </main>
    </Box>
  )
}

SidebarWithHeaderTemplate.propTypes = {
  pageTitle: PropTypes.string.isRequired,
  notifications: PropTypes.node,
  header: PropTypes.node,
  main: PropTypes.node.isRequired,
  marketplaceStore: PropTypes.shape({
    showUnlimitedProductsBanner: PropTypes.bool.isRequired
  }),
  ContainerProps: PropTypes.shape({
    className: PropTypes.string,
  }),
  HeaderProps: PropTypes.shape({
    className: PropTypes.string,
  }),
}

SidebarWithHeaderTemplate.defaultProps = {
  notifications: null,
  marketplaceStore: {
    showUnlimitedProductsBanner: false
  }
}

export default observer(SidebarWithHeaderTemplate)
