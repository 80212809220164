import React, { useState } from 'react'

import PropTypes from 'prop-types'

import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Dialog, DialogActions, DialogContent, DialogTitle,
  Grid,
  Link,
  Typography,
} from '@material-ui/core'
import { GradeRounded } from '@material-ui/icons'
import { observer } from 'mobx-react'
import { Link as RouterLink } from 'react-router-dom'

import { ExternalSourceLink, Time } from 'shared/components/atoms'
import { TextTruncate } from 'shared/components/molecules'
import { MARKETPLACE_CODE_PRINT_ON_DEMAND, MARKETPLACE_CODE_READY_TO_SELL } from 'shared/constants/marketplace'
import { useTranslation } from 'shared/hooks'
import { getIntegrationIcon } from 'shared/utils/integrations'

import { getExternalUrlProps } from 'retailer/components/utils'
import { ItemStore } from 'retailer/stores'

import style from './SupplierInfoBox.module.scss'

const Stats = observer(({ productsCount, rating, joinDate }) => {
  const { t } = useTranslation('productPage')
  return (
    <Grid container spacing={4} className={style.StatsContainer}>
      <Grid item xs="auto">
        <Typography variant="h6" component="span" className={style.StatValue}>
          <GradeRounded fontSize="small" className={style.RatingStar} /> {rating}
        </Typography>
        <Typography variant="body1" color="textSecondary">
          {t('supplierInfo.rating.label')}
        </Typography>
      </Grid>
      <Grid item xs="auto">
        <Typography variant="h6" component="span" className={style.StatValue}>
          <Time className={style.TimeDisplay} value={joinDate} format="MMMM YYYY" />
        </Typography>
        <Typography variant="body1" color="textSecondary">
          {t('supplierInfo.dateJoined.label')}
        </Typography>
      </Grid>
      <Grid item xs="auto">
        <Typography variant="h6" component="span" className={style.StatValue}>
          {productsCount}
        </Typography>
        <Typography variant="body1" color="textSecondary">
          {t('supplierInfo.productsCount.label')}
        </Typography>
      </Grid>
    </Grid>
  )
})

Stats.propTypes = {
  productsCount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  rating: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  joinDate: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string])
}

Stats.defaultProps = {
  productsCount: 0,
  rating: 5.0,
  joinDate: new Date()
}

const SupplierInfoBox = ({ item }) => {
  const { t } = useTranslation('productPage')
  const [modalOpen, setModalOpen] = useState(false)
  const marketplaceType = item?.isCustomizable ? MARKETPLACE_CODE_PRINT_ON_DEMAND : MARKETPLACE_CODE_READY_TO_SELL
  const externalUrl = item.brand.externalUrl || undefined
  const brandId = item.isAliExpress || item.isAlibaba ? item.designer?.mainBrand.uuid : item.brand.uuid
  const moreProductsLinkProps = {
    component: externalUrl ? undefined : RouterLink,
    href: externalUrl,
    to: externalUrl ? undefined : `/marketplace/${marketplaceType}?brandIds=${brandId}`,
    ...(externalUrl ? getExternalUrlProps(externalUrl) : { target: '_blank', rel: 'opener' })
  }
  const ExternalIcon = getIntegrationIcon('alibaba')

  const subheader = (() => {
    if (item.isAliExpress && item.externalUrl) {
      return (
        <Link href={item.externalUrl} rel="noopener noreferrer" target="_blank">
          {t('supplierInfo.thirdPartyPlatformLink.cta', { platformName: 'AliExpress' })}
        </Link>
      )
    }
    return t('supplierInfo.cardHeader.subtitle')
  })()

  return (
    <>
      <Card className={style.SupplierInfoBox}>
        <CardHeader
          title={
            <>
              {item.brand.name}
              {item.brand.externalUrl && (
                <ExternalSourceLink
                  url={item.brand.externalUrl}
                  icon={ExternalIcon ? <ExternalIcon height={16} /> : null}
                  title={t('supplierInfo.cardHeader.openExternalPageCta')}
                  className={style.ExternalLink}
                />
              )}
            </>
          }
          titleTypographyProps={{ variant: 'h6', className: style.SupplierName }}
          subheader={subheader}
          subheaderTypographyProps={{ variant: 'subtitle2' }}
        />
        <CardContent>
          <Stats
            productsCount={item.brand.productsCount}
            rating={parseFloat(item.designer.fulfillmentStars).toFixed(1)}
            joinDate={item.designer.joinDate}
          />
          <Typography variant="body1">
            <TextTruncate
              text={item.designer.description}
              maxWords={30}
              moreButton={
                <Link
                  onClick={() => setModalOpen(!modalOpen)}
                  className={style.ReadMoreButton}
                >
                  {t('supplierInfo.descriptionReadMore.cta')}
                </Link>
              }
            />
          </Typography>
        </CardContent>
        <CardActions>
          <Button color="primary" {...moreProductsLinkProps}>
            {t('supplierInfo.moreProducts.cta')}
          </Button>
        </CardActions>
      </Card>
      <Dialog
        open={modalOpen}
        maxWidth="sm"
        onClose={() => setModalOpen(false)}
        keepMounted
        fullWidth
      >
        <DialogTitle>
          {t('supplierInfo.descriptionModal.title', { name: item.brand.name })}
        </DialogTitle>
        <DialogContent>
          <Typography variant="body1">
            {item.designer.description}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setModalOpen(false)}>
            {t('supplierInfo.descriptionModalClose.button')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

SupplierInfoBox.propTypes = {
  item: PropTypes.instanceOf(ItemStore).isRequired
}

export default observer(SupplierInfoBox)
