import PropTypes from 'prop-types'

import { getName } from 'country-list'

const Country = ({ iso }) => {
  return getName(iso) || iso
}

Country.propTypes = {
  iso: PropTypes.string.isRequired,
}

export default Country
