import React, { useEffect, useMemo } from 'react'

import { Box, Grid, Hidden } from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'
import IntersectionObserver from '@researchgate/react-intersection-observer'
import times from 'lodash/times'
import { observer } from 'mobx-react'

import { MARKETPLACE_CODE_PRINT_ON_DEMAND } from 'shared/constants/marketplace'
import { useTranslation } from 'shared/hooks'

import {
  BottomLoader,
  MarketplaceBreadcrumbs,
  PageHeader,
  ProductCategoriesNavbar,
  ProductSearchForm,
} from 'retailer/components/molecules'
import {
  AppliedMarketplaceFilters,
  CustomizableProductSlate,
  ItemCustomizerModal,
  MarketplaceCategoryDropdown,
  MarketplaceFiltersDrawer,
  MarketplaceToolbar,
  ProductSlate,
  UniversalNotification,
  UniversalNotifications,
  VirtualizedGrid,
} from 'retailer/components/organisms'
import { SidebarWithHeaderTemplate } from 'retailer/components/templates'
import { useStores } from 'retailer/stores/hooks'

import style from './MarketplacePage.module.scss'

const ContentSkeleton = () => (
  <Box mt={3}>
    <Grid container spacing={2}>
      {times(12).map(idx => (
        <Grid item xs={12} sm={6} lg={4} xl={3} key={idx}>
          <Skeleton variant="rect" height={500} />
        </Grid>
      ))}
    </Grid>
  </Box>
)

const MarketplacePage = () => {
  const { marketplaceStore, marketplaceFiltersEditorStore } = useStores()
  const { t } = useTranslation('marketplace')

  const {
    isLoading,
    items,
    hasMore,
    marketplaceCode,
    search,
    category,
    categoryId,
    setCategory,
    fetchFresh,
    fetchMore,
    reset,
  } = marketplaceStore

  const isPrintOnDemand = marketplaceCode === MARKETPLACE_CODE_PRINT_ON_DEMAND
  const pageTitle = isPrintOnDemand ? t('main.printOnDemandPageTitle') : t('main.readyToSellPageTitle')
  const SlateComponent = isPrintOnDemand ? CustomizableProductSlate : ProductSlate

  const handleLoadMore = ({ isIntersecting }) => {
    if (isIntersecting && !isLoading) {
      fetchMore()
    }
  }

  useEffect(() => {
    fetchFresh()
    return () => reset()
  }, [])

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'instant' })
  }, [marketplaceCode])

  const handleApplyFilters = params => {
    marketplaceStore.setFilters({ categoryId, search, ...params })
    marketplaceFiltersEditorStore.hideFilters()
  }

  const header = (
    <>
      <PageHeader title={pageTitle} />
      <ProductSearchForm
        marketplaceStore={marketplaceStore}
        mt={{ xs: 2, md: 3 }} mb={{ xs: 0, md: 3 }}
      />
      <Hidden smDown>
        <ProductCategoriesNavbar marketplaceStore={marketplaceStore} />
      </Hidden>
    </>
  )

  const content = isLoading && items === undefined
    ? <ContentSkeleton />
    : (
      <>
        <VirtualizedGrid
          items={items} itemMinWidth={225}
          renderItemContent={store => <SlateComponent item={store} />}
        />
        <IntersectionObserver onChange={handleLoadMore}>
          <BottomLoader active={hasMore} />
        </IntersectionObserver>
      </>
      )

  const podEolAlert = useMemo(() => {
    const today = new Date()
    const level = today >= new Date('2024-07-01') ? 'error' : 'warning'
    return isPrintOnDemand && today >= new Date('2024-05-20') && today <= new Date('2024-07-14') ? level : false
  }, [isPrintOnDemand])

  return (
    <>
      <SidebarWithHeaderTemplate
        pageTitle={pageTitle}
        notifications={
          <UniversalNotifications>
            {isPrintOnDemand && podEolAlert && (
              <UniversalNotification
                visible
                severity={podEolAlert}
                content="Starting July 15, 2024, print on demand services will be discontinued. You can still edit and sell products until September 9, 2024."
                action={{
                  text: 'Learn more',
                  href: 'https://support.wix.com/en/article/wix-stores-creating-modalyst-print-on-demand-products',
                }}
              />
            )}
          </UniversalNotifications>
        }
        marketplaceStore={marketplaceStore}
        header={header}
        HeaderProps={{ className: style.Header }}
        ContainerProps={{ className: style.Container }}
        main={(
          <>
            <Hidden mdUp>
              <MarketplaceCategoryDropdown marketplaceStore={marketplaceStore} />
            </Hidden>
            {category && (
              <Hidden smDown>
                <MarketplaceBreadcrumbs
                  category={category}
                  onClick={category => setCategory(category || null)}
                  px={3 / 2} mb={2} mt={-2}
                />
              </Hidden>
            )}
            <MarketplaceToolbar
              marketplaceStore={marketplaceStore}
              marketplaceFiltersEditorStore={marketplaceFiltersEditorStore}
              mt={2} mb={3}
            />
            <AppliedMarketplaceFilters marketplaceStore={marketplaceStore} mt={-1} mb={2} />
            {content}
            <MarketplaceFiltersDrawer
              marketplaceFiltersEditorStore={marketplaceFiltersEditorStore}
              excludeSections={marketplaceCode === MARKETPLACE_CODE_PRINT_ON_DEMAND ? ['suppliers'] : undefined}
              onApply={handleApplyFilters}
              allowFilters={marketplaceStore.allowFilters}
            />
            {isPrintOnDemand && <ItemCustomizerModal />}
          </>
        )}
      />
    </>
  )
}

export default observer(MarketplacePage)
