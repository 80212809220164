import React from 'react'

import PropTypes from 'prop-types'

import { Table, TableContainer } from '@material-ui/core'
import clsx from 'clsx'
import { observer } from 'mobx-react'

import style from './ImportListProductTable.module.scss'

const ImportListProductTable = ({ className, children }) => {
  return (
    <TableContainer className={clsx(style.ImportListProductTable, className)}>
      <Table stickyHeader>
        {children}
      </Table>
    </TableContainer>
  )
}

ImportListProductTable.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node
}

export default observer(ImportListProductTable)
