import React from 'react'

import PropTypes from 'prop-types'

import { IconButton } from '@material-ui/core'
import clsx from 'clsx'
import { observer } from 'mobx-react'

import { Money } from 'shared/components/atoms'
import { Edit } from 'shared/icons'

import { Order } from 'retailer/stores'
import { useStores } from 'retailer/stores/hooks'

import style from './OrderItemShippingCost.module.scss'

const OrderItemShippingCost = ({ className, showAmount, orderItem, testId }) => {
  const { ordersStore } = useStores()

  if (orderItem.purchaseOrder.is3rdPartySupplierOrder) {
    return (
      <span className={clsx(className, style.OrderItemShippingCostRoot)}>
        {orderItem.shippingMethod
          ? (
            <>
              <span>{orderItem.shippingMethod}</span>
              {showAmount && <Money amount={orderItem.shippingCost} testId={testId} />}
            </>
            )
          : (
            <span className={style.NoShippingMethod}>Select shipping method</span>
            )}
        {orderItem.isShippingMethodEditable && (
          <IconButton
            color="primary"
            size="small"
            data-testid="shipping_method_edit_button"
            onClick={() => ordersStore.selectOrderItemForShippingMethodEditing(orderItem)}
          >
            <Edit />
          </IconButton>
        )}
      </span>
    )
  }
  return '—'
}

OrderItemShippingCost.propTypes = {
  className: PropTypes.string,
  showAmount: PropTypes.bool,
  orderItem: PropTypes.instanceOf(Order).isRequired,
  testId: PropTypes.string,
}

OrderItemShippingCost.defaultProps = {
  showAmount: true,
}

export default observer(OrderItemShippingCost)
