import React from 'react'

import PropTypes from 'prop-types'

import { Button, Typography } from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'
import clsx from 'clsx'
import { observer } from 'mobx-react'

import { Alert } from 'shared/components/molecules'
import { DataModal, ProgressBar } from 'shared/components/organisms'
import { ACTION_ADD_TO_STORE, ACTION_REMOVE_FROM_STORE, actionsUserFriendly } from 'shared/constants/retailerItems'

import style from './ImportListBatchActionModal.module.scss'

const ActionDescription = observer(({ action, count }) => {
  switch (action) {
    case ACTION_ADD_TO_STORE:
      return (
        <div key={ACTION_ADD_TO_STORE}>
          <Typography>
            Selected {count} {count === 1 ? 'product' : 'products'} will be added to your store
            and will be able to receive orders.
          </Typography>
        </div>
      )
    case ACTION_REMOVE_FROM_STORE:
      return (
        <div key={ACTION_REMOVE_FROM_STORE}>
          <Typography>
            Once removed from the import list, selected {count} {count === 1 ? 'product' : 'products'} will be
            in your archive. You can always add them again later.
          </Typography>
        </div>
      )
  }
})

ActionDescription.propTypes = {
  action: PropTypes.string.isRequired,
  count: PropTypes.number.isRequired,
}

const ActionButtons = observer(({
  action,
  isLoading,
  inProgress,
  isFinished,
  dismissModal,
  handleAction,
  handleStopAction
}) => {
  if (inProgress) {
    return (
      <Button
        variant="contained"
        color="primary"
        onClick={handleStopAction}
        data-testid="importList-batchActionModal-stopButton"
      >
        Stop the process
      </Button>
    )
  }
  if (isFinished) {
    return (
      <Button
        color="primary"
        variant="outlined"
        onClick={dismissModal}
        data-testid="importList-batchActionModal-closeButton"
      >
        Close
      </Button>
    )
  }
  return (
    <>
      <Button
        className={clsx(action === ACTION_REMOVE_FROM_STORE && style.DestructiveButton)}
        variant="outlined"
        color="primary"
        onClick={dismissModal}
        disabled={isLoading}
        data-testid="importList-batchActionModal-cancelButton"
      >
        Cancel
      </Button>
      <Button
        className={clsx(action === ACTION_REMOVE_FROM_STORE && style.DestructiveButton)}
        variant="contained"
        color="primary"
        onClick={handleAction}
        disabled={isLoading}
        data-testid="importList-batchActionModal-primaryButton"
      >
        {actionsUserFriendly.get(action)}
      </Button>
    </>
  )
})

ActionButtons.propTypes = {
  action: PropTypes.string.isRequired,
  inProgress: PropTypes.bool,
  isFinished: PropTypes.bool,
  isLoading: PropTypes.bool,
  dismissModal: PropTypes.func.isRequired,
  handleAction: PropTypes.func.isRequired,
  handleStopAction: PropTypes.func.isRequired
}

const ImportListBatchActionModal = ({ batchActionStore }) => {
  const {
    action,
    asyncHandlerStore,
    count,
    loading,
    reset: handleReset,
    run: handleRun,
    stop: handleStop,
  } = batchActionStore
  return (
    <DataModal
      className={style.ImportListBatchActionModal}
      onExited={handleReset}
      fullWidth
      maxWidth="md"
      title={actionsUserFriendly.get(action)}
      renderBody={() => {
        if (!loading.isLoaded) {
          return (
            <>
              <Typography paragraph component={Skeleton} />
              <Typography paragraph component={Skeleton} />
              <Typography paragraph component={Skeleton} width="50%" />
              <Typography paragraph component={Skeleton} />
              <Typography paragraph component={Skeleton} width="50%" />
            </>
          )
        }
        if (asyncHandlerStore.inProgress) {
          return (
            <ProgressBar
              plural="Products"
              processedCount={asyncHandlerStore.processed}
              totalCount={count}
            />
          )
        }
        if (asyncHandlerStore.isFinished && asyncHandlerStore.isStopped) {
          return (
            <>
              <Typography paragraph>
                The process was stopped before it finished. {asyncHandlerStore.processed} products
                were processed of total {count}.
              </Typography>
              {Boolean(asyncHandlerStore.errors) && (
                <Alert title="There Were Errors" severity="error">
                  {asyncHandlerStore.errors} products could not be processed due to errors.
                </Alert>
              )}
            </>
          )
        }
        if (asyncHandlerStore.isFinished && !asyncHandlerStore.isStopped) {
          return (
            <>
              <Typography paragraph>
                Processing products has finished!
              </Typography>
              {Boolean(asyncHandlerStore.errors) && (
                <Alert title="There Were Errors" severity="error">
                  {asyncHandlerStore.errors} products could not be processed due to errors.
                </Alert>
              )}
            </>
          )
        }
        return (
          <>
            <ActionDescription action={action} count={count} />
          </>
        )
      }}
      renderActions={({ dismissModal }) => {
        return (
          <ActionButtons
            action={action}
            dismissModal={dismissModal}
            isLoading={loading.isLoading}
            inProgress={asyncHandlerStore?.inProgress}
            isFinished={asyncHandlerStore?.isFinished}
            handleStopAction={handleStop}
            handleAction={handleRun}
          />
        )
      }}
    />
  )
}

ImportListBatchActionModal.propTypes = {
  batchActionStore: PropTypes.shape({
    action: PropTypes.any,
    asyncHandlerStore: PropTypes.shape({
      inProgress: PropTypes.bool,
      processed: PropTypes.number,
      isFinished: PropTypes.bool,
      isStopped: PropTypes.bool,
      errors: PropTypes.number,
    }),
    reset: PropTypes.func.isRequired,
    count: PropTypes.number.isRequired,
    loading: PropTypes.shape({
      isLoaded: PropTypes.bool,
      isLoading: PropTypes.bool,
    }),
    run: PropTypes.func.isRequired,
    stop: PropTypes.func.isRequired,
  })
}

export default observer(ImportListBatchActionModal)
