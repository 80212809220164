import { action, observable } from 'mobx'

import { processApiError } from 'shared/api/utils'

import {
  adaptPostFulfillOrderRequest,
  adaptPostFulfillOrderResponse,
  adaptPostFulfillOrderErrors,
} from 'supplier/adapters/orders'
import { postFulfillOrder } from 'supplier/api/orders'

class FulfillOrderStore {
  @observable order
  @observable inProgress = false
  @observable done = false
  @observable remoteErrors

  constructor (order) {
    this.order = order
  }

  @action.bound
  setDone (value) {
    this.done = value
  }

  @action.bound
  setRemoteErrors (data) {
    this.remoteErrors = data
  }

  @action.bound
  setInProgress (value) {
    this.inProgress = value
  }

  @action.bound
  submit (data) {
    this.inProgress = true
    this.remoteErrors = undefined
    const payload = adaptPostFulfillOrderRequest(data)
    return postFulfillOrder(this.order.uuid, payload)
      .then(response => {
        const data = adaptPostFulfillOrderResponse(response.data)
        this.order.update(data)
        this.setDone(true)
        return data
      })
      .catch(error => {
        this.setRemoteErrors(processApiError(error, adaptPostFulfillOrderErrors))
      })
      .finally(() => this.setInProgress(false))
  }
}

export default FulfillOrderStore
