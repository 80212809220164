import React, { useEffect } from 'react'

import { Container, Typography } from '@material-ui/core'
import { observer } from 'mobx-react'
import { Helmet } from 'react-helmet'

import { useBiEvents } from 'shared/bi/hooks'
import { ScrollResetter } from 'shared/components/atoms'
import {
  UserBasicInfoSettings,
  UserDeleteAccountForm,
  UserLoginInfoSettings,
  UserRequestDataForm,
} from 'shared/components/organisms'
import { useTranslation } from 'shared/hooks'

import { useStores } from 'retailer/stores/hooks'

import style from './UserSettingsPage.module.scss'

const UserSettingsPage = () => {
  const { logPageLoaded } = useBiEvents({ correlateWithLocation: true })

  const {
    settingsStore: {
      userBasicInfoSettings,
      userLoginInfoSettings,
      userRequestDataStore,
      userDeleteAccountStore
    }
  } = useStores()
  const { t } = useTranslation('settings')

  useEffect(() => {
    logPageLoaded({ pageName: 'account settings' })
    let mounted = true
    if (mounted) {
      userBasicInfoSettings.fetch()
      userLoginInfoSettings.fetch()
      userRequestDataStore.fetch()
      userDeleteAccountStore.fetch()
    }
    return () => {
      mounted = false
    }
  }, [])

  return (
    <div className={style.UserSettingsPage}>
      <Helmet>
        <title>{t('accountSettings.title')}</title>
      </Helmet>
      <ScrollResetter />
      <Container maxWidth="xl">
        <Typography variant="h2" className={style.PageHeader}>
          {t('accountSettings.title')}
        </Typography>
        {userBasicInfoSettings.loading.isLoaded && (
          <UserBasicInfoSettings store={userBasicInfoSettings} />
        )}
        {userLoginInfoSettings.loading.isLoaded && (
          <UserLoginInfoSettings store={userLoginInfoSettings} />
        )}
        {userRequestDataStore.loading.isLoaded && (
          <UserRequestDataForm store={userRequestDataStore} />
        )}
        {userDeleteAccountStore.loading.isLoaded && (
          <UserDeleteAccountForm store={userDeleteAccountStore} />
        )}
      </Container>
    </div>
  )
}

export default observer(UserSettingsPage)
