import { useMemo } from 'react'

import { useMediaQuery, useTheme } from '@material-ui/core'

import { useStores } from 'retailer/stores/hooks'

import { muiTheme as muiThemeV1 } from './v1'
import { muiTheme as muiThemeV2 } from './v2'

const useRetailerTheme = () => {
  const { routerStore } = useStores()

  return useMemo(() => {
    const pathname = routerStore.location.pathname
    switch (true) {
      case !!pathname.match(/^\/home$/):
      case !!pathname.match(/^\/marketplace\/(ready-to-sell|print-on-demand)/):
      case !!pathname.match(/^\/my-products\/(import-list|sync-list|pricing-rules)/):
      case !!pathname.match(/^\/my-stores/):
        return muiThemeV2
      default:
        return muiThemeV1
    }
  }, [routerStore.location.pathname])
}

const useIsDesktop = () => {
  const theme = useTheme()
  return useMediaQuery(theme.breakpoints.up('lg'))
}

export {
  useRetailerTheme,
  useIsDesktop,
}
