import { computed, observable } from 'mobx'

class ProductVariantStore {
  parent

  id
  @observable sku
  @observable quantity
  @observable unitPrice
  @observable unitPriceOriginal
  @observable msrpOriginal
  @observable options = []
  @observable images = []

  constructor (parent, data = {}) {
    this.parent = parent
    Object.assign(this, data)
  }

  @computed get key () {
    return String(this.id)
  }

  /** Option objects chosen for this variant */
  @computed get optionsData () {
    return this.parent.options.filter(option => this.options.includes(option.uuid))
  }

  @computed get originalCurrency () {
    return this.parent.originalCurrency
  }
}

export default ProductVariantStore
