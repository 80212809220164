/* eslint camelcase: 0 */

import { keysToCamelCase, keysToSnakeCase } from 'shared/utils/api'

const adaptPricingRule = keysToCamelCase

const adaptInventoryTypePricingRules = ({ compared_at_price, price, price_ending, ...rest }) => ({
  compareAtPrice: compared_at_price && adaptPricingRule(compared_at_price),
  price: price && adaptPricingRule(price),
  priceEnding: String(price_ending).padStart(2, '0'),
  ...keysToCamelCase(rest)
})

export const adaptGetPricingRulesResponse = ({ results, ...rest }) => ({
  results: results && results.map(adaptInventoryTypePricingRules),
  ...keysToCamelCase(rest)
})

export const adaptPostPricingRulesRequest = ({ compareAtPrice, price, ...rest }) => ({
  compared_at_price: compareAtPrice ? keysToSnakeCase(compareAtPrice) : undefined,
  price: price ? keysToSnakeCase(price) : null,
  ...keysToSnakeCase(rest),
})
