import React, { useEffect, useState } from 'react'

import PropTypes from 'prop-types'

import { Button, Container, Box } from '@material-ui/core'
import { KeyboardArrowLeftRounded } from '@material-ui/icons'
import { Skeleton } from '@material-ui/lab'
import { observer } from 'mobx-react'
import { Helmet } from 'react-helmet'
import { useHistory, useRouteMatch, Route, Link as RouterLink } from 'react-router-dom'

import { ScrollResetter } from 'shared/components/atoms'
import { MARKETPLACE_CODE_PRINT_ON_DEMAND, MARKETPLACE_CODE_READY_TO_SELL } from 'shared/constants/marketplace'
import { useTranslation } from 'shared/hooks'

import { ItemNotAvailableAlert } from 'retailer/components/molecules'
import {
  ItemCustomizerModal,
  ManuallyOrderItemModal,
  MarketplaceItemActions, MarketplaceItemData,
  MarketplaceItemGallery,
  MarketplaceItemHeader, MarketplaceItemMoreItemsList,
  MarketplaceItemPricing, MarketplaceItemTabs,
  ProductDraftsModal,
  SupplierInfoBox, UniversalNotifications,
} from 'retailer/components/organisms'
import { SidebarLeftTemplate } from 'retailer/components/templates'
import { NotFound } from 'retailer/pages'
import { useStores } from 'retailer/stores/hooks'

import style from './MarketplaceProductPage.module.scss'

const BackButton = props => (
  <Button component={RouterLink} startIcon={<KeyboardArrowLeftRounded />} {...props} />
)

const Main = observer(({ marketplaceItemStore, manualOrderStore }) => {
  const { t } = useTranslation('productPage')

  const { item, pricingRule, moreItems } = marketplaceItemStore

  const backPath = item?.isCustomizable
    ? `/marketplace/${MARKETPLACE_CODE_PRINT_ON_DEMAND}`
    : `/marketplace/${MARKETPLACE_CODE_READY_TO_SELL}`

  return (
    <Box className={style.Main}>
      <Helmet>
        <title>{item?.name || t('pageHeader.pageTitle.label')}</title>
      </Helmet>

      <Box mb={{ xs: 3, lg: 0 }} gridArea="backbutton">
        <BackButton to={backPath} data-testid="productDetailPage-backToMarketplaceButton">{t('returnToMarketplace.cta')}</BackButton>
      </Box>

      <Box my={{ xs: 4, lg: 0 }} gridArea="gallery">
        {item
          ? <MarketplaceItemGallery item={item} />
          : <Skeleton variant="rect" height="75vh" />}
      </Box>

      <Box gridArea="description">

        {item && !item.available && (
          <Box>
            <ItemNotAvailableAlert />
          </Box>
        )}

        <Box mb={4}>
          {item
            ? <MarketplaceItemHeader item={item} testId="productDetailPage-marketplaceItemHeader" />
            : <Skeleton variant="rect" height={125} />}
        </Box>

        <Box my={4}>
          {item
            ? <MarketplaceItemPricing item={item} pricingRule={pricingRule} testId="productDetailPage-marketplaceItemPricing" />
            : <Skeleton variant="rect" height={125} />}
        </Box>

        <Box my={4}>
          {item
            ? <MarketplaceItemActions item={item} testId="productDetailPage-marketplaceItemActions" />
            : <Skeleton variant="rect" height={56} />}
        </Box>

        <Box my={4}>
          {item
            ? <MarketplaceItemData marketplaceItemStore={marketplaceItemStore} testId="productDetailPage-marketplaceItemData" />
            : <Skeleton variant="rect" height="50vh" />}
        </Box>

      </Box>

      <Box my={{ xs: 4, lg: 0 }} gridArea="supplier">
        {item
          ? !item?.isCustomizable && <SupplierInfoBox item={item} />
          : <Skeleton variant="rect" height={300} />}
      </Box>

      <Box my={{ xs: 4, lg: 0 }} gridArea="tabs">
        {item
          ? <MarketplaceItemTabs store={marketplaceItemStore} testId="productDetailPage-marketplaceItemTabs" />
          : <Skeleton variant="rect" height={250} />}
      </Box>

      {moreItems && (
        <Box mt={4} gridArea="more">
          <MarketplaceItemMoreItemsList
            brandName={item.brand.name}
            items={moreItems}
          />
        </Box>
      )}
    </Box>
  )
})

Main.propTypes = {
  marketplaceItemStore: PropTypes.shape({
    item: PropTypes.shape({
      name: PropTypes.string.isRequired,
      is3rdPartySupplier: PropTypes.bool.isRequired,
      isCustomizable: PropTypes.bool.isRequired,
      available: PropTypes.bool.isRequired,
      brand: PropTypes.shape({
        name: PropTypes.string.isRequired,
      }).isRequired,
    }),
    pricingRule: PropTypes.object,
    moreItems: PropTypes.array,
  }).isRequired,
  manualOrderStore: PropTypes.object.isRequired,
}

const MarketplaceProductPage = () => {
  const { t } = useTranslation('productPage')

  const [error, setError] = useState(false)
  const history = useHistory()
  const { path, url, params } = useRouteMatch()
  const { marketplaceItemStore, manualOrderStore, productDraftListStore } = useStores()
  const { item, loading } = marketplaceItemStore

  useEffect(() => {
    marketplaceItemStore
      .fetch(params.id)
      .catch(error => setError(error?.response && error.response.status === 404))
    return () => {
      marketplaceItemStore.reset()
    }
  }, [])

  if (error) return <NotFound />

  const handleProductDraftsModalOnExited = () => {
    history.replace(url)
    marketplaceItemStore.fetch(marketplaceItemStore.item.id)
  }
  const handleCustomizerModalExited = () => {
    marketplaceItemStore.fetch(marketplaceItemStore.item.id)
  }

  return (
    <>
      <Helmet>
        <title>{t('pageHeader.pageTitle.label')}</title>
      </Helmet>
      <ScrollResetter />
      <SidebarLeftTemplate
        notifications={<UniversalNotifications />}
        main={
          <Container maxWidth="xl" disableGutters>
            <Main marketplaceItemStore={marketplaceItemStore} manualOrderStore={manualOrderStore} />
          </Container>
        }
      />
      {item?.isCustomizable && (
        <ItemCustomizerModal onExited={handleCustomizerModalExited} />
      )}
      {loading.isLoaded && manualOrderStore.open && (
        <ManuallyOrderItemModal manualOrderStore={manualOrderStore} />
      )}
      <Route
        path={`${path}/drafts`}
        render={props => (
          <ProductDraftsModal
            productDraftListStore={productDraftListStore}
            onExited={handleProductDraftsModalOnExited}
            {...props}
          />
        )}
      />
    </>
  )
}

export default observer(MarketplaceProductPage)
