import React from 'react'

import PropTypes from 'prop-types'

import clsx from 'clsx'
import { observer } from 'mobx-react'
import { NavLink, Link as RouterLink } from 'react-router-dom'

import style from './Link.module.scss'

const Link = ({ className, href, block, inverted, nav, absolute, current, children, onClick, testId }) => {
  let Element = nav ? NavLink : RouterLink
  if (absolute) Element = 'a'
  return (
    <Element
      href={href}
      to={href}
      className={clsx(
        style.Link,
        block && style.block,
        inverted && style.invert,
        current && style.current,
        className
      )}
      onClick={onClick}
      data-testid={testId}
    >
      {children}
    </Element>
  )
}

Link.propTypes = {
  className: PropTypes.string,
  href: PropTypes.string.isRequired,
  block: PropTypes.bool,
  inverted: PropTypes.bool,
  nav: PropTypes.bool,
  absolute: PropTypes.bool,
  current: PropTypes.bool,
  children: PropTypes.node,
  onClick: PropTypes.func,
  testId: PropTypes.string
}

Link.defaultProps = {
  className: '',
  block: false,
  inverted: false,
  nav: false,
  absolute: false,
  current: false,
  children: null
}

export default observer(Link)
