import { observable, computed, action, runInAction } from 'mobx'

import { adaptPurchaseOrder } from 'shared/adapters/orders'
import { placeOrder, getPurchaseOrder } from 'shared/api/orders'
import { ALIBABA, ALIEXPRESS } from 'shared/constants/integrations'
import {
  ORDER_STATUS_TO_ORDER,
  DPO_STATUS_PAID,
  DPO_STATUS_FULFILLING,
  DPO_STATUS_FULFILLED,
  DPO_STATUS_REFUNDED,
  DPO_STATUS_RESERVED_CANCELLED,
} from 'shared/constants/orders'

import { Order, PurchaseOrderDesigner } from 'retailer/stores'

class PurchaseOrder {
  @observable uuid
  @observable brand
  @observable externalOrderId
  @observable modalystOrderNumber
  @observable orderType
  @observable totalCost
  @observable totalShippingCost
  @observable usesDraftOrders
  @observable shippingMethod
  @observable courierName
  @observable trackingCode
  @observable isSyncing = false
  @observable status
  @observable unifiedStatus
  @observable checkoutUrl
  @observable retailerOrder
  @observable items = []

  @observable alerts = []
  @observable info = []
  @observable availableActions = []

  @observable designer

  /**
   * @param {RetailerOrder} parent  Parent RetailerOrder
   * @param {Object}        data    Optional initial data
   */
  constructor (parent, data = {}) {
    this.retailerOrder = parent
    this.update(data)
  }

  @action.bound
  update (data) {
    const { items, designer, ...rest } = data
    this.designer = new PurchaseOrderDesigner(designer)
    this.items = items ? items.map(item => new Order(this, item)) : []
    Object.assign(this, rest)
  }

  isActionAvailable (action) {
    return this.availableActions.includes(action)
  }

  @computed get totalItemsCost () {
    return this.items.reduce(
      (summedCost, item) => summedCost + item.itemCost, 0,
    )
  }

  @computed get isAliExpress () {
    return this.orderType === ALIEXPRESS
  }

  @computed get isAlibaba () {
    return this.orderType === ALIBABA
  }

  @computed get is3rdPartySupplierOrder () {
    return [ALIBABA, ALIEXPRESS].includes(this.orderType)
  }

  @computed get usesItemLevelShippingSelection () {
    return this.isAliExpress
  }

  @computed get usesOrderLevelShippingSelection () {
    return this.isAlibaba
  }

  @computed get isCancelled () {
    return this.status === DPO_STATUS_RESERVED_CANCELLED
  }

  @computed get isRefunded () {
    return this.status === DPO_STATUS_REFUNDED
  }

  @computed get isSynced () {
    return this.unifiedStatus !== ORDER_STATUS_TO_ORDER
  }

  @computed get isPaid () {
    return this.isSynced && [DPO_STATUS_PAID, DPO_STATUS_FULFILLING, DPO_STATUS_FULFILLED].includes(this.status)
  }

  @computed get isFulfilled () {
    return this.status === DPO_STATUS_FULFILLED
  }

  @action.bound
  addItems (...items) {
    this.items.push(...items)
  }

  @action.bound
  refreshFromRemote () {
    return getPurchaseOrder(this.uuid)
      .then(({ data }) => {
        this.update(adaptPurchaseOrder(data))
      })
  }

  @action.bound
  placeOrder () {
    this.isSyncing = true
    this.alerts = []
    this.info = []
    placeOrder(this.uuid).catch(error => {
      runInAction(() => {
        this.alerts = error.response.data.error
        this.unifiedStatus = ORDER_STATUS_TO_ORDER
      })
    })
  }
}

export default PurchaseOrder
