import React, { useMemo } from 'react'

import PropTypes from 'prop-types'

import { createSvgIcon, SvgIcon, useTheme } from '@material-ui/core'
import { v4 as uuid4 } from 'uuid'

const RatingStar = ({ percent, ...props }) => {
  const theme = useTheme()
  const Icon = useMemo(() => {
    const gid = `RatingStarGradient-${uuid4()}`
    return createSvgIcon(
      <>
        <defs>
          <linearGradient id={gid} x1="0" x2="1" y1="0" y2="0">
            <stop id="stop1" offset={`${percent}%`} stopColor={theme.palette.warning.main} />
            <stop id="stop2" offset={`${percent}%`} stopColor={theme.palette.grey[500]} />
          </linearGradient>
        </defs>
        <path
          d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z"
          fill={`url(#${gid})`}
        />
      </>
    )
  }, [percent])
  return <Icon {...props} />
}

RatingStar.propTypes = {
  percent: PropTypes.number.isRequired,
  ...SvgIcon.propTypes,
}

export default RatingStar
