import { api } from './common'

const apiV3 = '/api/v3/retailer_items'
const apiV2 = '/api/v2'

const getRetailerItemsCount = () => {
  return api.get(`${apiV3}/badges/`)
}

const getRetailerItemsCounters = (status) => {
  return api.get(apiV3 + '/counters/', {
    params: { status }
  })
}

const getRetailerItems = (params = {}) => {
  return api.get(apiV3 + '/', { params })
}

/**
 * The endpoint has the same interface as the one for getting the retailer items,
 * but it does not use pagination and returns UUIDs only.
 * @param {Object} params
 * @returns {Promise<AxiosResponse<any>>}
 */
const getRetailerItemsIds = (params = {}) => {
  return api.get(apiV3 + '/' + 'ids/', { params })
}

const getRetailerItem = uuid => {
  return api.get(apiV3 + '/' + uuid + '/')
}

const patchRetailerItem = ({ uuid, data, headers = {} }) => {
  return api.patch(apiV2 + `/items/data/${uuid}/`, data, { headers })
}

const removeRetailerItem = (uuid, headers = {}) => {
  return api.delete(apiV2 + `/items/data/${uuid}/`, { headers })
}

const getRetailerItemVariants = uuid => {
  return api.get(apiV3 + '/' + uuid + '/retailer_item_variants/')
}

const lockRetailerItemPrice = (uuid, headers = {}) => {
  return api.post(apiV2 + `/items/data/${uuid}/price_lock/`, undefined, { headers })
}

const unlockRetailerItemPrice = (uuid, headers = {}) => {
  return api.delete(apiV2 + `/items/data/${uuid}/price_lock/`, { headers })
}

const removeRetailerItemFromStore = (uuid, headers = {}) => {
  return api.delete(apiV2 + '/items/data/' + uuid, { headers })
}

const moveRetailerItemToImportList = (uuid, headers = {}) => {
  return api.delete(apiV2 + `/items/data/${uuid}/publish/`, { headers })
}

const exportRetailerItem = (uuid, headers = {}) => {
  return api.post(apiV2 + `/items/data/${uuid}/publish/`, undefined, { headers })
}

const resyncRetailerItem = uuid => {
  return api.post(apiV2 + `/items/data/${uuid}/resync/`)
}

const exportSyncListCsv = data => {
  return api.post(apiV2 + '/csv_export/', data)
}

const exportRetailerItemToCsv = (uuid, headers = {}) => {
  return api.get(`${apiV3}/${uuid}/export_to_csv/`, { headers })
}

const getProductTypes = () => {
  return api.get(apiV2 + '/product_types/')
}

const addProductType = data => {
  return api.post(apiV2 + '/product_types/', data)
}

const getProductTags = () => {
  return api.get(apiV2 + '/items/tags/')
}

const addProductTags = (uuid, data, headers = {}) => {
  return api.post(apiV2 + `/items/data/${uuid}/tags/`, data, { headers })
}

const removeProductTag = (uuid, tagId, headers = {}) => {
  return api.delete(apiV2 + `/items/data/${uuid}/tags/${tagId}/`, { headers })
}

const removeAllProductTags = (uuid, headers = {}) => {
  return api.post(apiV2 + `/items/data/${uuid}/tags/remove_all/`, { headers })
}

const getProductCategories = () => {
  return api.get(apiV2 + '/categories/')
}

const updateRetailerItemOption = (uuid, data, headers = {}) => {
  return api.patch(apiV2 + `/items/options/${uuid}/`, data, { headers })
}

const renameRetailerItemOption = (retailerItemUuid, data, headers = {}) => {
  return api.post(apiV3 + `/${retailerItemUuid}/change_option_name/`, data, { headers })
}

const patchRetailerItemVariant = (uuid, data, headers = {}) => {
  return api.patch(apiV2 + `/items/variants/${uuid}/`, data, headers)
}

const enableRetailerItemImage = (uuid, data, headers = {}) => {
  return api.post(apiV2 + `/items/data/${uuid}/images/`, data, { headers })
}

const disableRetailerItemImage = (retailerItemUuid, imageUuid, headers = {}) => {
  return api.delete(apiV2 + `/items/data/${retailerItemUuid}/images/${imageUuid}/`, { headers })
}

const getRetailerItemShipping = uuid => {
  return api.get(apiV2 + `/items/data/${uuid}/get_shipping/`)
}

const updateRetailerItemShipping = (uuid, data) => {
  return api.put(apiV2 + `/items/data/${uuid}/update_supplier_product_shipping/`, data)
}

const getRetailerItemCollections = () => {
  return api.get(apiV2 + '/collections/')
}

export {
  getRetailerItemsCount,
  getRetailerItemsCounters,
  getRetailerItems,
  getRetailerItemsIds,
  getRetailerItem,
  patchRetailerItem,
  removeRetailerItem,
  getRetailerItemVariants,
  lockRetailerItemPrice,
  unlockRetailerItemPrice,
  removeRetailerItemFromStore,
  moveRetailerItemToImportList,
  exportRetailerItem,
  resyncRetailerItem,
  exportSyncListCsv,
  exportRetailerItemToCsv,
  getProductTypes,
  addProductType,
  getProductTags,
  addProductTags,
  removeProductTag,
  removeAllProductTags,
  getProductCategories,
  updateRetailerItemOption,
  renameRetailerItemOption,
  patchRetailerItemVariant,
  enableRetailerItemImage,
  disableRetailerItemImage,
  getRetailerItemShipping,
  updateRetailerItemShipping,
  getRetailerItemCollections,
}
