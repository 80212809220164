import React from 'react'

import PropTypes from 'prop-types'

import { Box, Button, Card, CardContent, Dialog, DialogActions, DialogContent, Grid, Typography } from '@material-ui/core'
import { observer } from 'mobx-react'

import { LoadingButton, Paragraphs } from 'shared/components/atoms'
import { Alert, ModalTitle } from 'shared/components/molecules'
import { CheckMark } from 'shared/icons'

import { SetPrimaryShippingCountryModal } from 'retailer/components/organisms'

const PricingActionModal = observer(({ pricingActionStore, onExited }) => {
  const {
    title,
    buttonLabel,
    confirmContent,
    doneContent,
    confirm,
    inProgress,
    isDone,
    isError,
  } = pricingActionStore

  const [open, setOpen] = React.useState(true)
  const dismiss = () => setOpen(false)

  return (
    <Dialog
      maxWidth="md" fullWidth
      open={open} TransitionProps={{ onExited }}
      aria-labelledby="pricing-action-modal-title"
    >
      <ModalTitle id="pricing-action-modal-title" onCloseClick={dismiss}>
        {title}
      </ModalTitle>
      <DialogContent>
        {isDone && (
          <>
            <Box my={3} textAlign="center"><CheckMark /></Box>
            <Box my={3} textAlign="center"><Paragraphs contents={doneContent} /></Box>
          </>
        )}
        {!isDone && (
          <>
            <Paragraphs contents={confirmContent} />
            {isError && (
              <Alert severity="error" component={Box} mt={3}>
                We could not finish this operation. Please try again or contact our support team.
              </Alert>
            )}
          </>
        )}
      </DialogContent>
      <DialogActions>
        {!isDone && (
          <Button onClick={dismiss} disabled={inProgress} color="primary">Cancel</Button>
        )}
        <LoadingButton
          variant="contained" color="primary"
          loading={inProgress}
          disabled={inProgress}
          onClick={isDone ? dismiss : confirm}
          testId="pricingRules-pricingActionModal-actionSubmitButton"
        >
          {isDone ? 'OK' : buttonLabel}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
})

PricingActionModal.propTypes = {
  pricingActionStore: PropTypes.shape({
    title: PropTypes.string.isRequired,
    buttonLabel: PropTypes.string.isRequired,
    confirmContent: PropTypes.arrayOf(PropTypes.string).isRequired,
    doneContent: PropTypes.arrayOf(PropTypes.string).isRequired,
    confirm: PropTypes.func.isRequired,
    inProgress: PropTypes.bool,
    isDone: PropTypes.bool,
    isError: PropTypes.bool,
  }).isRequired,
  onExited: PropTypes.func.isRequired,
}

const PricingActionsCard = ({ pricingRulesStore }) => {
  const {
    startPricingAction,
    resetPricingAction,
    pricingActionStore,
    startSetPrimaryShippingCountry,
    resetSetPrimaryShippingCountry,
    setPrimaryShippingCountryStore,
  } = pricingRulesStore

  return (
    <Card elevation={0}>
      <CardContent>
        <Grid container spacing={3}>

          <Grid item lg={6}>
            <Typography variant="h5" gutterBottom>Unlock all prices</Typography>
            <Typography variant="body2" color="textSecondary" paragraph>
              When you lock your prices, we stop syncing any price updates to your store. If you would like to apply a new set of pricing rules to all of your items, you must first unlock all items and then save your new pricing rules. This action cannot be reversed.
            </Typography>
            <Button
              variant="outlined" color="secondary"
              data-testid="pricingRules-actions-unlockAllPricesButton"
              onClick={() => startPricingAction('unlockPrices')}
            >
              Unlock all prices
            </Button>
          </Grid>

          <Grid item lg={6}>
            <Typography variant="h5" gutterBottom>Change currency</Typography>
            <Typography variant="body2" color="textSecondary" paragraph>
              Modalyst will check the currency which is set in your integrated store and change your Modalyst settings accordingly. This action will unlock all prices and apply pricing rules to them with a new currency.
            </Typography>
            <Button
              variant="outlined" color="secondary"
              data-testid="pricingRules-actions-changeCurrencyButton"
              onClick={() => startPricingAction('changeCurrency')}
            >
              Change currency
            </Button>
          </Grid>

          <Grid item lg={6}>
            <Typography variant="h5" gutterBottom>Primary shipping country</Typography>
            <Typography variant="body2" color="textSecondary" paragraph>
              The shipping costs displayed are based on the country which you select as your primary shipping country. This is where you expect most of your customers to be located.
            </Typography>
            <Button
              variant="outlined" color="secondary"
              data-testid="pricingRules-actions-changeCountryButton"
              onClick={() => startSetPrimaryShippingCountry()}
            >
              Primary shipping country
            </Button>
          </Grid>

        </Grid>
      </CardContent>

      {pricingActionStore && (
        <PricingActionModal
          pricingActionStore={pricingActionStore}
          onExited={() => resetPricingAction()}
        />
      )}

      {setPrimaryShippingCountryStore && (
        <SetPrimaryShippingCountryModal
          setPrimaryShippingCountryStore={setPrimaryShippingCountryStore}
          onExited={() => resetSetPrimaryShippingCountry()}
        />
      )}

    </Card>
  )
}

PricingActionsCard.propTypes = {
  pricingRulesStore: PropTypes.shape({
    startSetPrimaryShippingCountry: PropTypes.func.isRequired,
    resetSetPrimaryShippingCountry: PropTypes.func.isRequired,
    setPrimaryShippingCountryStore: PropTypes.object,
    startPricingAction: PropTypes.func.isRequired,
    resetPricingAction: PropTypes.func.isRequired,
    pricingActionStore: PropTypes.object,
  })
}

export default observer(PricingActionsCard)
