import React, { forwardRef, useCallback, useLayoutEffect, useMemo, useRef, useState } from 'react'

import PropTypes from 'prop-types'

import { Box, Tab, Tabs } from '@material-ui/core'
import clsx from 'clsx'
import { observer } from 'mobx-react'
import { useDebouncedCallback } from 'use-debounce'

import { useTranslation } from 'shared/hooks'
import { getTopmostAncestorOf } from 'shared/utils'

import { MarketplaceCategoryPicker } from 'retailer/components/organisms'
import CustomPropTypes from 'retailer/propTypes'

import style from './ProductCategoriesNavbar.module.scss'

const CUTOFF_SEQUENCE_RTS = [[1080, 6], [900, 5], [770, 4], [650, 3], [510, 2], [0, 0]]
const CUTOFF_SEQUENCE_POD = [[1100, 7], [970, 6], [860, 5], [750, 4], [630, 3], [530, 2], [0, 0]]

const CategoryTabs = observer(forwardRef(({
  tabCategories, value, pickerValue, disableMore,
  onChange, onTabMouseEnter, onMouseLeave
}, ref) => {
  const { t } = useTranslation('marketplace')

  // handle current top category not having a tab (eg. was selected from "more...")
  const availableValue = [0, ...tabCategories].includes(value) ? value : false
  return (
    <Tabs
      ref={ref}
      value={availableValue}
      onChange={onChange}
      className={clsx(style.CategoryTabs, pickerValue && style.pickerVisible)}
      onMouseLeave={onMouseLeave}
    >
      <Tab key="all" label={t('categories.allProducts.label')} disableRipple />
      {tabCategories.map(cat => (
        <Tab
          key={cat.uuid}
          value={cat}
          label={cat.name}
          className={clsx(pickerValue === cat && style.isActive)}
          disableRipple
          onMouseEnter={event => onTabMouseEnter(cat, event)}
        />
      ))}
      {!disableMore && (
        <Tab
          key="more"
          value="more"
          label={t('categories.browseMore.label')}
          onMouseEnter={() => onTabMouseEnter('more')}
          className={clsx(pickerValue === 'more' && style.isActive)}
          disableRipple
        />
      )}
    </Tabs>
  )
}))

CategoryTabs.propTypes = {
  /** Top level categories to show in the navbar */
  tabCategories: PropTypes.arrayOf(CustomPropTypes.categoryTree).isRequired,
  /** Value indicating a currently selected category */
  value: PropTypes.oneOfType([CustomPropTypes.categoryTree, PropTypes.number]).isRequired,
  /** Value indicating a tab that opened the category picker */
  pickerValue: PropTypes.oneOfType([CustomPropTypes.categoryTree, PropTypes.string, PropTypes.string]),
  disableMore: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  onTabMouseEnter: PropTypes.func.isRequired,
  onMouseLeave: PropTypes.func,
}

const ProductCategoriesNavbar = ({ marketplaceStore }) => {
  const { category, setCategory, categoriesTree } = marketplaceStore

  const containerRef = useRef(null)
  const tabsRef = useRef(null)

  const [tabsNum, setTabsNum] = useState()
  // A REALLY DUMB but cheap/effective way to determine the number of shown tabs.
  // Needs to change if we can no longer assume specific length/order of category
  // names. In any case, breaking changes should be handled by MUI tab scroll.
  const calculateTabsNum = useCallback(() => {
    const containerWidth = containerRef.current?.offsetWidth
    const cutoffSequence = categoriesTree.name === 'root' ? CUTOFF_SEQUENCE_RTS : CUTOFF_SEQUENCE_POD
    setTabsNum(cutoffSequence.find(([bp, _num]) => (containerWidth || 0) >= bp)[1])
  }, [containerRef.current])

  const tabCategories = useMemo(() => {
    return categoriesTree.children.slice(0, tabsNum)
  }, [categoriesTree, tabsNum])

  useLayoutEffect(() => {
    calculateTabsNum()
    window.addEventListener('resize', calculateTabsNum)
    return () => {
      window.removeEventListener('resize', calculateTabsNum)
    }
  }, [])

  const [pickerOpen, setPickerOpen] = useState(false)
  const [pickerCategory, setPickerCategory] = useState(categoriesTree)
  const activeTopCategory = category ? getTopmostAncestorOf(category) : undefined
  const pickFromAll = pickerCategory === categoriesTree
  const pickerTabValue = pickerOpen ? (pickFromAll ? 'more' : pickerCategory) : undefined
  const hasMoreCategories = tabCategories.length < categoriesTree.children.length

  /** Debounced setter for pickerOpen */
  const debouncedSetPickerOpen = useDebouncedCallback(value => {
    setPickerOpen(value)
  }, 150)

  /** When the cursor hovers on a tab OR the tab is tapped */
  const handleTabMouseEnter = tabValue => {
    if (tabValue) { // exclude "all products" === 0
      setPickerCategory(tabValue === 'more' ? categoriesTree : tabValue)
      debouncedSetPickerOpen(true)
    }
  }

  /** When the cursor leaves the tabs container OR the popover picker */
  const handleMouseLeave = event => {
    debouncedSetPickerOpen.cancel()
    debouncedSetPickerOpen(false)
  }

  /** When the tab is changed (by a click/tap or any internal Tabs method) */
  const handleTabChange = (event, obj) => {
    // For touch controlled UI, tapping the tab will cause mouseenter immediately
    // followed by click (unless tab has focus). We address this by delaying tab
    // "selection" until after the picker is actually shown.
    if (debouncedSetPickerOpen.isPending()) return

    if (obj === 'more') {
      setPickerCategory(categoriesTree)
      setPickerOpen(true)
    } else if (obj === 0 || (pickerOpen && pickerCategory === obj)) {
      // this handles:
      // - click on 'all' (-> sets category to false-ish, hide picker)
      // - 'click' on the tab that triggered the picker (-> set that category, hide picker)
      setCategory(obj, true)
      setPickerOpen(false)
    } else if (!pickerOpen) {
      // handles events in touch-enabled environments (for cases when mouseenter
      // is not fired prior to click)
      setPickerCategory(obj)
      setPickerOpen(true)
    }
  }

  /** When a category is selected in the popover picker */
  const handlePick = category => {
    setCategory(category, true)
    debouncedSetPickerOpen(false)
  }

  /** When the mouse enters the popover picker */
  const handlePickerMouseEnter = event => {
    // cancel any pending calls to setPickerOpen that would hide the picker when moving
    // the cursor from the tabs bar (-> onMouseLeave triggered) to the popover picker
    debouncedSetPickerOpen.cancel()
    debouncedSetPickerOpen(true)
  }

  const filterAllCategories = cat => !tabCategories.includes(cat)

  return (
    <Box ref={containerRef}>
      <CategoryTabs
        tabCategories={tabCategories}
        value={activeTopCategory || 0}
        pickerValue={pickerTabValue}
        onChange={handleTabChange}
        disableMore={!hasMoreCategories}
        ref={tabsRef}
        onTabMouseEnter={handleTabMouseEnter}
        onMouseLeave={handleMouseLeave}
      />
      <MarketplaceCategoryPicker
        className={style.CategoryPicker}
        open={pickerOpen}
        onClose={() => setPickerOpen(false)}
        categoryTree={pickerCategory}
        filterTopCategories={pickFromAll ? filterAllCategories : undefined}
        depth={pickFromAll ? 4 : 3}
        container={containerRef.current}
        anchorEl={tabsRef.current}
        onPick={handlePick}
        allowSelectRoot={!pickFromAll}
        onMouseEnter={handlePickerMouseEnter}
        onMouseLeave={handleMouseLeave}
      />
    </Box>
  )
}

ProductCategoriesNavbar.propTypes = {
  marketplaceStore: PropTypes.shape({
    category: PropTypes.object,
    setCategory: PropTypes.func.isRequired,
    categoriesTree: CustomPropTypes.categoryTree.isRequired,
  }).isRequired,
}

export default observer(ProductCategoriesNavbar)
