import React from 'react'

import PropTypes from 'prop-types'

import { InputAdornment, TextField } from '@material-ui/core'
import { observer } from 'mobx-react'
import { Controller } from 'react-hook-form'

/**
 * MUI TextField wrapped in a react-hook-form's Controller
 *
 * Can take a component with the same interface as TextField as an alternative.
 * Outputs a *controlled* input - may affect performance in some cases.
 * Supports read-only mode (ignores rules, callbacks etc).
 */
const RhfTextField = ({
  control, id, name, rules, defaultValue, required, error, helperText,
  readOnly, readOnlyAdornment, InputProps, component: Component, testId, ...props
}) => {
  const _readOnlyInputProps = readOnly
    ? Object.assign(
      { readOnly },
      readOnlyAdornment
        ? { endAdornment: <InputAdornment position="end">{readOnlyAdornment}</InputAdornment> }
        : {}
    )
    : {}
  const _inputProps = Object.assign({}, InputProps || {}, _readOnlyInputProps)
  const _rules = readOnly ? {} : rules
  const _required = !readOnly && (required === undefined ? !!_rules?.required : required)

  return (
    <Controller
      control={control}
      key={name}
      name={name}
      rules={_rules}
      defaultValue={defaultValue}
      render={({ ref, name, value, onChange, onBlur }) => (
        <Component
          inputRef={ref}
          id={id || name}
          name={name}
          value={value}
          required={_required}
          onChange={!readOnly && (
            eventOrValue => onChange(eventOrValue?.target ? eventOrValue.target.value : eventOrValue)
          )}
          onBlur={!readOnly && onBlur}
          error={!!error}
          helperText={error || helperText}
          data-testid={testId}
          InputProps={_inputProps}
          {...props}
        />
      )}
    />
  )
}

RhfTextField.propTypes = {
  control: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  defaultValue: PropTypes.any,
  id: PropTypes.string,
  label: PropTypes.string,
  rules: PropTypes.object,
  /** Error message. If true-ish, it is assumed that the input is in error state. */
  error: PropTypes.string,
  fullWidth: PropTypes.bool,
  required: PropTypes.bool,
  variant: PropTypes.string,
  helperText: PropTypes.string,
  /** Optional component that shares the same interface with TextField */
  component: PropTypes.elementType,
  InputProps: PropTypes.object,
  readOnly: PropTypes.bool,
  readOnlyAdornment: PropTypes.node,
  testId: PropTypes.string,
}

RhfTextField.defaultProps = {
  fullWidth: true,
  variant: 'outlined',
  component: TextField,
  readOnly: false,
}
export default observer(RhfTextField)
