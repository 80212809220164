import React from 'react'

import PropTypes from 'prop-types'

import { IconButton, Tooltip } from '@material-ui/core'
import { observer } from 'mobx-react'
import { Link as RouterLink } from 'react-router-dom'

import { integrationsNames } from 'shared/constants/integrations'
import { ExternalLink } from 'shared/icons'

import { Order } from 'retailer/stores'

import style from './OrderItemName.module.scss'

const Name = observer(({ name, url, testId }) => {
  if (!url || url === '') {
    return (
      <span>{name}</span>
    )
  }
  return (
    <Tooltip
      arrow
      placement="top"
      title="Open item on Modalyst marketplace"
    >
      <RouterLink to={url} target="_blank" rel="opener" className={style.Name} data-testid={testId}>
        {name}
      </RouterLink>
    </Tooltip>
  )
})

Name.propTypes = {
  name: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  testId: PropTypes.string,
}

const OrderItemName = ({ item, testId }) => {
  const { itemUuid, itemName, itemExternalUrl } = item
  const integrationName = integrationsNames.get(item.purchaseOrder.orderType)
  return (
    <div className={style.OrderItemName}>
      <Name name={itemName} url={`/marketplace/${itemUuid}`} testId={testId} />
      {itemExternalUrl && (
        <Tooltip
          arrow
          placement="top"
          title={`Open item on ${integrationName}`}
        >
          <IconButton
            className={style.ExternalButton}
            color="primary"
            size="small"
            href={itemExternalUrl}
            target="_blank"
          >
            <ExternalLink width={15} height={15} />
          </IconButton>
        </Tooltip>
      )}
    </div>
  )
}

OrderItemName.propTypes = {
  item: PropTypes.instanceOf(Order),
  testId: PropTypes.string,
}

export default observer(OrderItemName)
