import React from 'react'

import PropTypes from 'prop-types'

import { Chip } from '@material-ui/core'
import { DeleteOutlined, LockOutlined } from '@material-ui/icons'
import clsx from 'clsx'

import style from './TagChip.module.scss'

/** A Chip but with a more taggy feel to it */
const TagChip = ({ className, disabled, ...props }) => {
  return (
    <Chip
      className={clsx(style.TagChip, className)}
      {...props}
      disabled={disabled}
      deleteIcon={disabled ? <LockOutlined /> : <DeleteOutlined />}
    />
  )
}

TagChip.propTypes = {
  disabled: PropTypes.bool,
  className: PropTypes.string,
}

TagChip.defaultProps = {
  disabled: false,
}

export default TagChip
