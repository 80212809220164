/* eslint camelcase: 0 */

/**
 * Adapts an order for a single item, this basically means:
 * `DropshippingOrder` class on backend.
 */
const adaptItem = ({
  uuid,
  item_cost,
  total_costs,
  shipping_cost,
  shipping_method,
  item_name,
  options,
  quantity,
  main_image,
  sku,
  item_uuid,
  item_url,
  item_external_url,
}) => {
  return {
    uuid,
    itemCost: item_cost,
    totalCosts: parseFloat(total_costs),
    shippingCost: parseFloat(shipping_cost),
    shippingMethod: shipping_method,
    itemName: item_name,
    options,
    quantity,
    image: main_image,
    sku,
    itemUuid: item_uuid,
    itemUrl: item_url,
    itemExternalUrl: item_external_url,
  }
}

const adaptPurchaseOrderDesigner = ({
  messageable,
  email,
  id
}) => {
  return {
    messageable,
    email,
    id,
  }
}

/**
 * Adapts purchase order, which translated to `DropshippingPurchaseOrder`
 * class on backend. It also adapts nested items.
 */
const adaptPurchaseOrder = ({
  uuid,
  created,
  brand,
  external_order_id,
  modalyst_order_number,
  order_type,
  total_shipping_costs,
  total_costs,
  courier_name,
  tracking_code,
  expected_delivery_time,
  modalyst_retailer_commission,
  total_payment,
  uses_draft_orders,
  shipping_method,
  is_syncing,
  is_payment_in_progress,
  status,
  unified_status,
  items,
  alerts,
  info,
  checkout_url,
  available_actions,
  is_automatic_payment_available,
  is_automatic_payment_enabled,
  designer,
}) => {
  return {
    uuid,
    created,
    brand,
    externalOrderId: external_order_id,
    modalystOrderNumber: modalyst_order_number,
    orderType: order_type,
    totalShippingCost: parseFloat(total_shipping_costs),
    totalCost: parseFloat(total_costs),
    modalystRetailerCommission: parseFloat(modalyst_retailer_commission),
    totalPayment: parseFloat(total_payment),
    usesDraftOrders: uses_draft_orders,
    shippingMethod: shipping_method,
    courierName: courier_name,
    trackingCode: tracking_code,
    expectedDeliveryTime: expected_delivery_time,
    isSyncing: is_syncing,
    isPaymentInProgress: is_payment_in_progress,
    status,
    unifiedStatus: unified_status,
    items: items.map(item => adaptItem(item)),
    alerts,
    info,
    checkoutUrl: checkout_url,
    availableActions: available_actions,
    isAutomaticPaymentAvailable: is_automatic_payment_available,
    isAutomaticPaymentEnabled: is_automatic_payment_enabled,
    designer: designer ? adaptPurchaseOrderDesigner(designer) : {},
  }
}

/**
 * Adapts retailer order address.
 */
const adaptAddress = ({
  company_name,
  recipient_name,
  address,
  city,
  state,
  zipcode,
  country,
  phone,
}) => {
  return {
    companyName: company_name,
    name: recipient_name,
    address,
    city,
    state,
    zipCode: zipcode,
    country,
    phone,
  }
}

const adaptAddressReverse = data => ({
  company_name: data.companyName || null,
  recipient_name: data.name || '',
  address: data.address || '',
  city: data.city || '',
  state: data.state || '',
  zipcode: data.zipCode || '',
  country: data.country || null,
  phone: data.phone || '',
})

const adaptAddressErrors = data => ({
  nonFieldErrors: data.non_field_errors,
  fieldErrors: adaptAddress(data.field_errors),
})

const adaptPurchaseOrderPayment = data => ({
  continueUrl: data.continue_url,
  referenceCode: data.reference_code,
  paymentProvider: data.payment_provider,
})

const adaptShippingMethodOption = data => ({
  minEstimatedDelivery: data.min_estimated_delivery,
  maxEstimatedDelivery: data.max_estimated_delivery,
  shippingProvider: data.shipping_provider,
  shippingCost: data.shipping_cost,
  convertedShippingCost: data.converted_shipping_cost,
})

const adaptOrderItemShippingMethod = data => ({
  shippingMethod: data.shipping_method,
  shippingCost: data.shipping_cost,
  shippingMethodOptions: data.shipping_method_options.map(adaptShippingMethodOption),
})

const adaptOrderItemShippingMethodReverse = data => ({
  shipping_method: data.shippingMethod,
})

const adaptOrderItemShippingMethodErrors = data => ({
  nonFieldErrors: data.non_field_errors,
  fieldErrors: {
    shippingMethod: data.field_errors?.shipping_method,
  },
})

const adaptMarkFulfilled = data => ({
  courierName: data.courier_name,
  trackingCode: data.tracking_code,
})

const adaptMarkFulfilledErrors = data => ({
  nonFieldErrors: data.non_field_errors,
  fieldErrors: adaptMarkFulfilled(data.field_errors),
})

const adaptMarkFulfilledReverse = data => ({
  courier_name: data.courierName,
  tracking_code: data.trackingCode,
})

/**
 * Adapts the entire RetailerOrder with suborders, address and so on.
 */
const adaptRetailerOrder = ({
  uuid,
  placed_on,
  order_number,
  external_id,
  external_url,
  integration_type,
  integration_status,
  placement_progress,
  payment_progress,
  fulfillment_progress,
  cancellation_progress,
  refund_progress,
  is_paid_by_customer,
  address,
  is_shipping_address_locked,
  shipping_address_validation_mode,
  supplier_orders,
  total_costs,
  paid_by_customer,
  profit,
  alerts,
}) => {
  return {
    uuid,
    placedOn: placed_on,
    orderNumber: order_number,
    externalId: external_id,
    externalUrl: external_url,
    integrationType: integration_type,
    integrationStatus: integration_status,
    placementProgress: placement_progress,
    paymentProgress: payment_progress,
    fulfillmentProgress: fulfillment_progress,
    cancellationProgress: cancellation_progress,
    refundProgress: refund_progress,
    isPaidByCustomer: is_paid_by_customer,
    address: address && adaptAddress(address),
    isShippingAddressLocked: is_shipping_address_locked,
    shippingAddressValidationMode: shipping_address_validation_mode,
    purchaseOrders: supplier_orders.map(order => adaptPurchaseOrder(order)),
    totalCosts: parseFloat(total_costs),
    paidByCustomer: paid_by_customer,
    profit,
    alerts,
  }
}

export {
  adaptItem,
  adaptPurchaseOrder,
  adaptAddress,
  adaptAddressErrors,
  adaptAddressReverse,
  adaptOrderItemShippingMethod,
  adaptOrderItemShippingMethodErrors,
  adaptOrderItemShippingMethodReverse,
  adaptMarkFulfilled,
  adaptMarkFulfilledErrors,
  adaptMarkFulfilledReverse,
  adaptPurchaseOrderPayment,
  adaptRetailerOrder,
}
