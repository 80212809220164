// Categories
const RETAILER = 'Retailer'

// Actions
const SEARCH = 'Search'
const CLICK_COLLECTION_BANNER = 'Click Collection Banner'
const VIEW_AD_ITEM = 'View Ad Item'
const CLICK_AD_ITEM = 'Click Ad Item'
const ADD_IMPORT_LIST = 'Add to import list'
const ADD_SYNC_LIST = 'Add to sync list'
const VIEW_IMPORT_LIST = 'View in import list'
const VIEW_SYNC_LIST = 'View in sync list'
const VIEW_ITEM_DETAIL = 'View product detail page'
const SEE_INTEGRATION_AD_MODAL = 'See integration ad modal'
const SEE_INTEGRATION_MODAL = 'See integration modal'
const CONFIRM_INTEGRATION_DROPSHIPPING = 'Confirm integration dropshipping'
const CLICK_MARKETPLACE_BANNER = 'Click marketplace banner'

// Action Context
export const CONTEXT_MARKETPLACE = 'searchResults'
export const CONTEXT_ITEM_DETAIL = 'productPage'
export const CONTEXT_CONNECT_SUPPLIERS = 'connectSuppliers'

export const EVENT_LABELS = {
  connectIntegration: integrationName => `Connect ${integrationName}`,
  joinIntegrationDropshipping: integrationName => `Join ${integrationName} dropshipping program`,
  installBrowserExtension: integrationName => `Install browser extension for ${integrationName}`,
  navigateToIntegrationProducts: integrationName => `Go to product list of ${integrationName}`,
  clickMarketplaceBanner: bannerName => `Clicked banner ${bannerName}`,
}

/**
 * ACTIONS is structured as follows:
 *  ACTIONS.<action>.<UI component or context used to perform the action (optional)>
 */
export const ACTIONS = {
  search: {
    landingPageButton: {
      category: RETAILER,
      action: SEARCH,
      label: 'Search Landing Page Button'
    },
    resultsButton: {
      category: RETAILER,
      action: SEARCH,
      label: 'Search Results Button'
    },
    popularCategories: {
      category: RETAILER,
      action: SEARCH,
      label: (category) => `Categories Link: ${category}`
    },
    categories: {
      category: RETAILER,
      action: SEARCH,
      label: (category) => `Predefined Search Query Link: ${category}`
    },
    priceRanges: {
      category: RETAILER,
      action: SEARCH,
      label: (range) => `Price Ranges: ${range}`
    },
    premiumProducts: {
      category: RETAILER,
      action: SEARCH,
      label: 'Premium Products'
    },
    fourDayDelivery: {
      category: RETAILER,
      action: SEARCH,
      label: '4 Day Delivery'
    },
    moreFromBrand: {
      category: RETAILER,
      action: SEARCH,
      label: 'Find more from this brand'
    },
    collectionBanner: {
      category: RETAILER,
      action: CLICK_COLLECTION_BANNER,
      label: (collectionName) => collectionName,
    },
  },
  viewAdItem: {
    [CONTEXT_MARKETPLACE]: {
      category: RETAILER,
      action: VIEW_AD_ITEM,
      label: adCode => adCode
    },
  },
  clickAdItem: {
    [CONTEXT_MARKETPLACE]: {
      category: RETAILER,
      action: CLICK_AD_ITEM,
      label: adCode => adCode
    },
  },
  clickMarketplaceBanner: {
    [CONTEXT_MARKETPLACE]: {
      category: RETAILER,
      action: CLICK_MARKETPLACE_BANNER,
      label: name => name
    },
  },
  // a modal re the above is shown eg. when missing a connection / extension / with the info about a dynamic URL
  seeIntegrationModal: {
    [CONTEXT_MARKETPLACE]: {
      category: RETAILER,
      action: SEE_INTEGRATION_MODAL,
      label: ([modalType, integrationName]) => `Shown ${modalType} modal for ${integrationName}`,
    }
  },
  // a promotional modal for an integration is shown
  seeIntegrationAdModal: {
    [CONTEXT_MARKETPLACE]: {
      category: RETAILER,
      action: SEE_INTEGRATION_AD_MODAL,
      label: integrationName => `Shown ad modal for ${integrationName}`,
    }
  },
  addImportList: {
    [CONTEXT_MARKETPLACE]: {
      category: RETAILER,
      action: ADD_IMPORT_LIST,
      label: 'Search Results'
    },
    shippingFromMyCountry: {
      category: RETAILER,
      action: ADD_IMPORT_LIST,
      label: 'Shipping From My Country'
    },
    freeShipping: {
      category: RETAILER,
      action: ADD_IMPORT_LIST,
      label: 'Free Shipping'
    },
    [CONTEXT_ITEM_DETAIL]: {
      category: RETAILER,
      action: ADD_IMPORT_LIST,
      label: 'Product Page'
    },
  },
  addSyncList: {
    single: {
      category: RETAILER,
      action: ADD_SYNC_LIST,
      label: 'Single'
    },
    bulk: {
      category: RETAILER,
      action: ADD_SYNC_LIST,
      label: 'Bulk'
    },
  },
  viewImportList: {
    [CONTEXT_MARKETPLACE]: {
      category: RETAILER,
      action: VIEW_IMPORT_LIST,
      label: 'Search Results'
    },
    [CONTEXT_ITEM_DETAIL]: {
      category: RETAILER,
      action: VIEW_IMPORT_LIST,
      label: 'Product Page'
    },
    shippingFromMyCountry: {
      category: RETAILER,
      action: VIEW_IMPORT_LIST,
      label: 'Shipping From My Country'
    },
    freeShipping: {
      category: RETAILER,
      action: VIEW_IMPORT_LIST,
      label: 'Free Shipping'
    },
  },
  viewSyncList: {
    [CONTEXT_MARKETPLACE]: {
      category: RETAILER,
      action: VIEW_SYNC_LIST,
      label: 'Search Results'
    },
    [CONTEXT_ITEM_DETAIL]: {
      category: RETAILER,
      action: VIEW_SYNC_LIST,
      label: 'Product Page'
    },
    shippingFromMyCountry: {
      category: RETAILER,
      action: VIEW_SYNC_LIST,
      label: 'Shipping From My Country'
    },
    freeShipping: {
      category: RETAILER,
      action: VIEW_SYNC_LIST,
      label: 'Free Shipping'
    },
  },
  viewItemDetail: {
    [CONTEXT_MARKETPLACE]: {
      category: RETAILER,
      action: VIEW_ITEM_DETAIL,
      label: 'Search Results'
    },
    shippingFromMyCountry: {
      category: RETAILER,
      action: VIEW_ITEM_DETAIL,
      label: 'Shipping From My Country'
    },
    freeShipping: {
      category: RETAILER,
      action: VIEW_ITEM_DETAIL,
      label: 'Free Shipping'
    },
  },

  // user has confirmed that they joined the integration dropshipping program
  confirmIntegrationDropshipping: {
    [CONTEXT_CONNECT_SUPPLIERS]: {
      category: RETAILER,
      action: CONFIRM_INTEGRATION_DROPSHIPPING,
      label: integrationName => integrationName
    }
  },
}
