import React, { useCallback, useEffect } from 'react'

import PropTypes from 'prop-types'

import { yupResolver } from '@hookform/resolvers/yup'
import {
  Button,
  FormControlLabel,
  Grid,
  Hidden,
  InputAdornment,
  Popover,
  Switch,
  TableBody,
  TableCell,
  TableHead,
  TableRow, TextField,
  Typography,
} from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'
import clsx from 'clsx'
import getSymbolFromCurrency from 'currency-symbol-map'
import isEmpty from 'lodash/isEmpty'
import pick from 'lodash/pick'
import times from 'lodash/times'
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state'
import { observer } from 'mobx-react'
import { useSnackbar } from 'notistack'
import { Controller, useForm, useFormContext } from 'react-hook-form'
import NumberFormat from 'react-number-format'
import * as yup from 'yup'

import { Money } from 'shared/components/atoms'
import { Alert, TooltipHelper } from 'shared/components/molecules'
import { RETAILER_AUTHORIZATION_SHOPIFY } from 'shared/constants/accounts'
import { TAB_PRICING } from 'shared/constants/importList'
import { useTranslation } from 'shared/hooks'
import { importListItemType } from 'shared/types'
import { getFieldErrorMessage } from 'shared/utils/forms'
import { ForIntegration } from 'shared/utils/integrations'

import { Image, ImportListProductTableField } from 'retailer/components/molecules'
import { ImportListProductShippingSelector, ImportListProductTable } from 'retailer/components/organisms'
import {
  checkIfTabHasErrors,
  constructPricingFieldName,
  getComparedAtPriceFieldName,
  getPriceFieldName,
  getPricingTabDefaultValues,
  getPricingTabFieldNames,
  getVariantsTabDefaultValues,
  getVariantTabFieldNames,
} from 'retailer/utils/importList'

import style from './ImportListProductPricingTab.module.scss'

const validationSchema = yup.object({
  price: yup.number('Provide a valid price')
    .required('Provide a valid price')
    .positive('Price must be set'),
  comparedAtPrice: yup.number('Provide a valid compare at price')
    .required('Provide a valid compare at price')
    .positive('Compare at price must be set')
})

const validate = ({ price, comparedAtPrice }) => {
  const errors = {}
  if (comparedAtPrice < price) {
    errors.comparedAtPrice = { type: 'custom', message: 'Compared at price cannot be lower than price' }
  }
  return errors
}

// eslint-disable-next-line no-unused-vars
const SetAllPricesAction = observer(() => {
  const { t } = useTranslation('importList')
  const { control, handleSubmit, errors } = useForm({
    mode: 'all',
    shouldFocusError: false,
    resolver: async (values, context) => {
      const parsedValues = {
        price: parseFloat(values?.price) || 0,
        comparedAtPrice: parseFloat(values?.comparedAtPrice) || 0,
      }
      const errors = validate(parsedValues)
      if (!isEmpty(errors)) return { values, errors }
      return yupResolver(validationSchema)(parsedValues, context)
    }
  })

  const onSubmit = data => {
    console.info(data)
  }

  return (
    <PopupState variant="popover" popupId="dd">
      {(popupState) => (
        <>
          <Button
            variant="text"
            size="small"
            color="primary"
            {...bindTrigger(popupState)}
          >
            {t('main.productPricingSamePrice.cta')}
          </Button>
          <Popover
            {...bindPopover(popupState)}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
          >
            <form onSubmit={handleSubmit(onSubmit)} className={style.SetAllPricesBox}>
              <Controller
                control={control}
                name="price"
                defaultValue=""
                render={({ onChange }) => {
                  return (
                    <NumberFormat
                      fullWidth
                      required
                      label={t('main.productPricingSamePriceEnterPrice.label')}
                      variant="outlined"
                      size="small"
                      customInput={TextField}
                      fixedDecimalScale
                      decimalScale={2}
                      onValueChange={({ value }) => onChange(value)}
                      error={!!getFieldErrorMessage(errors, 'price')}
                      helperText={getFieldErrorMessage(errors, 'price')}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            $
                          </InputAdornment>
                        )
                      }}
                    />
                  )
                }}
              />
              <Controller
                control={control}
                name="comparedAtPrice"
                defaultValue=""
                render={({ onChange }) => {
                  return (
                    <NumberFormat
                      customInput={TextField}
                      style={{ marginTop: 16 }}
                      fullWidth
                      required
                      fixedDecimalScale
                      decimalScale={2}
                      onValueChange={({ value }) => onChange(value)}
                      error={!!getFieldErrorMessage(errors, 'comparedAtPrice')}
                      helperText={getFieldErrorMessage(errors, 'comparedAtPrice')}
                      label="Compare at price"
                      variant="outlined"
                      size="small"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            $
                          </InputAdornment>
                        )
                      }}
                    />
                  )
                }}
              />
              <div className={style.SetAllPricesActions}>
                <Button
                  color="primary"
                  variant="outlined"
                  size="small"
                  onClick={() => popupState.close()}
                >
                  {t('main.productPricingSamePriceCancel.button')}
                </Button>
                <Button
                  color="primary"
                  variant="contained"
                  size="small"
                  type="submit"
                >
                  {t('main.productPricingSamePriceSave.button')}
                </Button>
              </div>
            </form>
          </Popover>
        </>
      )}
    </PopupState>
  )
})

const Actions = observer(({ item, onAutoPriceToggle }) => {
  const { t } = useTranslation('importList')
  const tooltipContent = t('main.productPricingAutoPrice.tooltip')

  return (
    <div className={style.Toolbar}>
      <div className={style.Actions}>
        {item.is3rdPartySupplier && (
          <Button
            onClick={() => item.shippingStore.setOpen(true)}
            variant="text"
            size="small"
            color="primary"
          >
            {t('main.productPricingUpdateShipping.cta')}
          </Button>
        )}
        {/* <SetAllPricesAction /> TODO: Implement */}
      </div>
      <div className={style.AutoSwitcher}>
        <FormControlLabel
          control={
            <Switch
              data-testid="importList-importListProduct-pricingTab-priceLockSwitch"
              color="primary"
              disabled={item.priceLockedChangeInProgress}
              checked={!item.priceLocked}
              onChange={onAutoPriceToggle}
            />
          }
          label={
            <Typography variant="body2">
              {t('main.productPricingAutoPrice.cta')} <TooltipHelper content={tooltipContent} />
            </Typography>
          }
          labelPlacement="end"
        />
      </div>
    </div>
  )
})

Actions.propTypes = {
  item: importListItemType.isRequired,
  onAutoPriceToggle: PropTypes.func.isRequired,
}

const ImportListProductPricingTab = ({ item, errors }) => {
  const { t } = useTranslation('importList')
  const {
    control,
    formState,
    handleSubmit,
    getValues,
    reset,
    trigger,
    register,
    watch,
  } = useFormContext()
  const { enqueueSnackbar } = useSnackbar()

  getVariantTabFieldNames(item).forEach(fieldName => register(fieldName))
  getPricingTabFieldNames(item).forEach(fieldName => register(fieldName))

  const resetForm = useCallback((data = {}) => {
    const resetData = { ...getValues(), ...data }
    reset(resetData, { errors: true })
    trigger()
  }, [reset, trigger])

  useEffect(() => {
    if (!item.variantsLoaded) {
      item.loadVariants().then(() => {
        resetForm({ ...getVariantsTabDefaultValues(item), ...getPricingTabDefaultValues(item) })
      })
    }
  }, [item.variantsLoaded])

  if (!item.variantsLoaded) {
    return (
      <div className={style.Loader}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Skeleton variant="rect" height={60} />
          </Grid>
          <Grid item xs={12}>
            <Skeleton variant="rect" height={30} />
          </Grid>
          {times(3, idx => (
            <Grid item xs={12} key={idx}>
              <Skeleton variant="rect" height={100} />
            </Grid>
          ))}
        </Grid>
      </div>
    )
  }

  const { isDirty, dirtyFields } = formState

  const onAutoPriceChange = async (_, checked) => {
    const isSuccess = await item.togglePriceLocked(checked)
    if (isSuccess) {
      resetForm(getPricingTabDefaultValues(item))
      enqueueSnackbar(
        `Auto price update has been ${checked ? 'activated' : 'deactivated'}`,
        { variant: 'success' }
      )
    }
    if (!isSuccess) {
      enqueueSnackbar(
        `Failed to ${checked ? 'activate' : 'deactivate'} variant`,
        { variant: 'error' }
      )
    }
  }

  const onSubmit = async data => {
    const pricingDirtyField = dirtyFields[TAB_PRICING]
    if (!isDirty || isEmpty(pricingDirtyField)) return
    const pickedData = pick(data[TAB_PRICING], Object.keys(pricingDirtyField))
    const promises = Object.entries(pickedData).map(async ([variantUuid, payload]) => {
      // Payload contains two fields, but in fact only one of them has been changed
      const [fieldName, fieldValue] = Object.entries(pick(payload, Object.keys(pricingDirtyField[variantUuid])))[0]
      const domFieldName = constructPricingFieldName(variantUuid, fieldName)
      const variant = item.variants.find(variant => variant.uuid === variantUuid)
      const patched = await variant.updatePrices({ [fieldName]: fieldValue })
      if (patched) resetForm({ [domFieldName]: fieldValue })
    })
    if (!promises || !promises.length) return
    await Promise.all(promises)
    await item.fetch()
  }

  const onError = async () => {
    if (!checkIfTabHasErrors(errors[TAB_PRICING])) {
      await onSubmit(getValues())
      return
    }
    enqueueSnackbar(
      `${item.name || 'Item'} was not saved. Please, ensure there are no errors.`,
      { variant: 'error' }
    )
  }

  const profitTooltipContent = t('main.productPricingProfit.tooltip')

  const negativeProfitTooltipContent = t('main.productPricingProfitNegative.tooltip')

  const currencySymbol = getSymbolFromCurrency(item.currencyIso) || item.currencyIso

  return (
    <div className={style.Wrapper}>
      {(item.is3rdPartySupplier && !item.supplierProductShipping) && (
        <Alert className={style.Alert} severity="error">
          {t('main.productPricingSelectShipping.text')}
        </Alert>
      )}
      <Actions item={item} onAutoPriceToggle={onAutoPriceChange} />
      <ImportListProductTable className={style.ImportListProductPricingTab}>
        <TableHead>
          <TableRow>
            <TableCell>
              <Hidden xsUp>
                Image
              </Hidden>
            </TableCell>
            <TableCell>
              {t('main.productPricingVariants.label')}
            </TableCell>
            <ForIntegration
              integration={item.retailerAuthorization}
              allowedIntegrations={RETAILER_AUTHORIZATION_SHOPIFY}
            >
              <TableCell>
                <span className={style.EditableCell}>
                  {t('main.productPricingShopifyComparePrice.label')}
                </span>
              </TableCell>
            </ForIntegration>
            <TableCell>
              <span className={style.EditableCell}>
                {t('main.productPricingPrice.label')}
              </span>
            </TableCell>
            <TableCell>
              {t('main.productPricingItemCost.label')}
            </TableCell>
            <TableCell>
              {t('main.productPricingShipping.label')}
            </TableCell>
            <TableCell>
              {t('main.productPricingProfit.label')} <TooltipHelper content={profitTooltipContent} />
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {item.variants.map(variant => {
            return (
              <TableRow
                key={variant.uuid}
                className={clsx(style.Variant, !variant.active && style.inactive)}
                data-testid="importList-importListProduct-variantRow"
                data-test-state={variant.active ? 'active' : 'inactive'}
              >
                <TableCell className={style.ImageCell}>
                  <Image
                    image={variant.image}
                    imgProps={{ className: style.Image }}
                    testId="importList-importListProduct-variantRow-variantImage"
                  />
                </TableCell>
                <TableCell data-testid="importList-importListProduct-variantRow-optionNamesCell">
                  {variant.optionNames}
                </TableCell>
                <ForIntegration
                  integration={item.retailerAuthorization}
                  allowedIntegrations={[RETAILER_AUTHORIZATION_SHOPIFY]}
                >
                  <TableCell data-testid="importList-importListProduct-variantRow-compareAtPriceCell">
                    <ImportListProductTableField
                      useNumberFormat
                      control={control}
                      disabled={!variant.active}
                      name={getComparedAtPriceFieldName(variant)}
                      className={style.Input}
                      prefix={currencySymbol}
                      value={watch(getComparedAtPriceFieldName(variant)) || ''}
                      defaultValue={variant.comparedAtPrice}
                      onBlur={e => {
                        variant.clearError(getComparedAtPriceFieldName(variant))
                        handleSubmit(onSubmit, onError)(e)
                      }}
                      error={getFieldErrorMessage(errors, getComparedAtPriceFieldName(variant))}
                    />
                  </TableCell>
                </ForIntegration>
                <TableCell data-testid="importList-importListProduct-variantRow-priceCell">
                  <ImportListProductTableField
                    useNumberFormat
                    control={control}
                    disabled={!variant.active}
                    name={getPriceFieldName(variant)}
                    className={style.Input}
                    prefix={currencySymbol}
                    value={watch(getPriceFieldName(variant)) || ''}
                    defaultValue={variant.price}
                    onBlur={e => {
                      variant.clearError(getPriceFieldName(variant))
                      handleSubmit(onSubmit, onError)(e)
                    }}
                    error={getFieldErrorMessage(errors, getPriceFieldName(variant))}
                  />
                </TableCell>
                <TableCell>
                  <Money
                    amount={variant.cost} currency={variant.currency} symbolOverride={currencySymbol}
                    testId="importList-importListProduct-variantRow-cost"
                  />
                </TableCell>
                <TableCell>
                  <Money
                    amount={variant.shipping} currency={variant.currency} symbolOverride={currencySymbol}
                    testId="importList-importListProduct-variantRow-shipping"
                  />
                </TableCell>
                <TableCell>
                  <Money
                    className={clsx(variant.profit < 0 && style.negative)}
                    testId="importList-importListProduct-variantRow-profit"
                    amount={variant.profit}
                    currency={variant.currency}
                    symbolOverride={currencySymbol}
                  />
                  {variant.profit < 0 && (
                    <TooltipHelper
                      content={negativeProfitTooltipContent}
                      className={clsx(style.NegativeProfitHelper, style.negative)}
                    />
                  )}
                </TableCell>
              </TableRow>
            )
          })}
        </TableBody>
      </ImportListProductTable>
      {item.shippingStore.isOpen && (
        <ImportListProductShippingSelector shippingStore={item.shippingStore} />
      )}
    </div>
  )
}

ImportListProductPricingTab.propTypes = {
  item: importListItemType.isRequired,
  errors: PropTypes.object,
}

ImportListProductPricingTab.defaultProps = {
  errors: {}
}

export default observer(ImportListProductPricingTab)
