import { placeAnOrderClicked } from '@wix/bi-logger-modalyst/v2'
import isInteger from 'lodash/isInteger'
import pick from 'lodash/pick'
import { action, computed, observable, runInAction } from 'mobx'

import { adaptItem, adaptManualOrderForApi } from 'shared/adapters/marketplace'
import { adaptRetailerItemVariant, adaptRetailerProduct } from 'shared/adapters/retailerItems'
import { getItem, placeManualOrder } from 'shared/api/marketplace'
import { getRetailerItem, getRetailerItemVariants } from 'shared/api/retailerItems'
import { LoadableStore } from 'shared/stores'
import { getBiHeadersFromEvent } from 'shared/stores/BiLoggerStore/utils'

import { ItemStore, RetailerProductStore } from 'retailer/stores'

class ManualOrderVariantStore {
  id
  sku
  price
  image
  options
  quantity
  @observable quantityToOrder = 0

  constructor (data = {}) {
    Object.assign(this, data)
  }

  @action.bound
  setQuantityToOrder (value) {
    if (!isInteger(value) || value < 0 || value > this.quantity) {
      throw new Error('Value outside allowed range')
    }
    this.quantityToOrder = value
  }
}

class ManualOrderStore {
  /** @type {(import('../context').RootStore)} */
  root

  /** @type {import('../index').ItemStore} */
  @observable item
  /** @type {import('../index').RetailerProductStore} */
  @observable retailerProduct
  @observable retailerItemVariants
  @observable loading = new LoadableStore()
  @observable open = false
  @observable isSaving = false
  @observable isSuccess = false
  @observable error
  @observable biContext

  /**
   * @param {import('../context').RootStore} root
   * @param {Object} [data]
   */
  constructor (root, data = {}) {
    this.root = root
    Object.assign(this, data)
  }

  /** Convenience map for quick access to Item's variants by Id */
  @computed get itemVariantsMap () {
    if (!this.item) return undefined
    return new Map(this.item.activeVariants.map(variant => [variant.id, variant]))
  }

  /**
   * The variants for the user to choose from
   * @returns {ManualOrderVariantStore[]}
   */
  @computed get variants () {
    if (!this.item) return []
    if (this.retailerItemVariants) {
      // If retailer item variants are provided, use them as a source of
      // customized POD images, prices and pool of variants to choose from.
      // Everything else is taken from the source Item and its variants.
      return this.retailerItemVariants.filter(riv => this.itemVariantsMap.has(riv.itemVariantId)).map(v => {
        const itemVariant = this.itemVariantsMap.get(v.itemVariantId)
        return new ManualOrderVariantStore({
          image: v.image?.thumbnail || this.item.mainImage?.thumbnail,
          price: v.cost,
          ...pick(itemVariant, ['id', 'sku', 'options', 'quantity'])
        })
      })
    } else {
      return this.item.activeVariants.map(v => {
        return new ManualOrderVariantStore({
          image: v.image?.thumbnail || this.item.mainImage?.thumbnail,
          ...pick(v, ['id', 'price', 'sku', 'options', 'quantity'])
        })
      })
    }
  }

  /**
   * The variants that the user wants to order (with selected quantity > 0)
   * @returns {ManualOrderVariantStore[]}
   */
  @computed get variantsToOrder () {
    return this.variants.filter(variant => variant.quantityToOrder > 0)
  }

  /**
   * The total quantity to order of all variants.
   * @returns {number}
   */
  @computed get totalQuantity () {
    return this.variantsToOrder.reduce((sum, variant) => sum + variant.quantityToOrder, 0)
  }

  /**
   * Get total cost of all ordered variants.
   * @returns {number}
   */
  @computed get totalCost () {
    return this.variantsToOrder.reduce(
      (sum, variant) => sum + variant.quantityToOrder * variant.price, 0
    )
  }

  /**
   * Sum shipping cost from the supplier with addon cost multiplied by the total quantity of variants ordered minus one,
   * as we don't want addon cost for the first item.
   * @returns {number}
   */
  @computed get totalShippingCost () {
    if (!this.item?.shipping?.retailerCountry) return 0
    const shippingCost = parseFloat(this.item.shipping.retailerCountry.shippingCost || '0')
    const addonCost = parseFloat(this.item.shipping.retailerCountry.shippingAddonCost || '0')
    return (
      shippingCost + Math.max(0, this.totalQuantity - 1) * addonCost
    )
  }

  @computed get totalOrderValue () {
    return this.totalCost + this.totalShippingCost
  }

  @computed get readyToPlace () {
    return !!this.variantsToOrder.length
  }

  /**
   * Open up the modal and fetch the item data.
   * @param {String} itemId
   * @param {String} [retailerItemId]
   * @returns {Promise<*>}
   */
  @action.bound
  start (itemId, retailerItemId = undefined, biContext) {
    this.biContext = biContext

    this.loading.setLoading()
    this.setOpen(true)

    this.retailerItemId = retailerItemId

    const getItemPromise = getItem(itemId)
    const getRetailerProductPromise = retailerItemId
      ? getRetailerItem(retailerItemId)
      : Promise.resolve()
    const getRetailerItemVariantsPromise = retailerItemId
      ? getRetailerItemVariants(retailerItemId)
      : Promise.resolve()

    return Promise.all([getItemPromise, getRetailerProductPromise, getRetailerItemVariantsPromise])
      .then(([itemResponse, getRetailerProductResponse, retailerItemVariantsResponse]) => {
        this.retailerProduct = getRetailerProductResponse
          ? new RetailerProductStore(this.root, adaptRetailerProduct(getRetailerProductResponse.data))
          : undefined
        this.retailerItemVariants = retailerItemVariantsResponse
          ? retailerItemVariantsResponse.data.map(adaptRetailerItemVariant)
          : undefined
        this.item = new ItemStore(this.root, adaptItem(itemResponse.data))
      })
      .catch(() => {
        this.error = true
      })
      .finally(() => {
        this.loading.setLoaded()
      })
  }

  @action.bound
  setOpen (value) {
    this.open = value
  }

  @action.bound
  async placeOrder () {
    const biEvent = await this.root.biLoggerStore.log(placeAnOrderClicked({
      ...this.item.biEventData,
      ...(this.retailerProduct?.biEventData || {}),
      ...this.biEventData,
    }))

    if (this.item?.is3rdPartySupplier || !this.item?.canOrderSample) {
      throw new Error('No orderable item loaded!')
    }

    this.isSaving = true
    this.error = null

    const payload = adaptManualOrderForApi({
      itemUuid: this.item.id,
      variants: this.variantsToOrder,
      retailerItemId: this.retailerItemId,
    })
    try {
      const response = await placeManualOrder(payload, getBiHeadersFromEvent(biEvent))
      runInAction(() => {
        this.isSaving = false
        this.isSuccess = true
      })
      return response
    } catch (e) {
      runInAction(() => {
        this.error = true
        this.isSaving = false
      })
    }
  }

  @computed get biEventData () {
    return {
      ...(this.biContext || {}),
      itemsQuantity: this.totalQuantity,
      numItems: this.variantsToOrder.length,
      numProducts: 1,
      totalAmount: this.totalOrderValue,
      shippingRate: this.totalShippingCost,
      itemCost: this.totalCost,
      variantsCount: this.variants.length,
    }
  }

  /**
   * Resets the store to the initial state (no variants ordered, clear error and success status etc.).
   */
  @action.bound
  reset () {
    this.open = false
    this.loading.reset()
    this.item = undefined
    this.retailerProduct = undefined
    this.retailerItemId = undefined
    this.retailerItemVariants = undefined
    this.isSuccess = false
    this.error = null
    this.isSaving = false
    this.biContext = undefined
  }
}

export default ManualOrderStore
