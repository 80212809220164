import React from 'react'

import PropTypes from 'prop-types'

import { Redirect as RouterRedirect } from 'react-router-dom'

/**
 * Redirect component that keeps the current query string in the target location
 *
 * Usage: <Route ... render={props => <Redirect {...props} pathname="/your-location"}
 */
const Redirect = ({ pathname, location, keepSearch, ...rest }) => {
  const to = { pathname, search: keepSearch ? location.search : undefined }
  return <RouterRedirect {...rest} to={to} />
}

Redirect.propTypes = {
  /** Pathname to redirect to. Note that it's not a full location descriptor as in `to` prop of base Redirect */
  pathname: PropTypes.string.isRequired,
  /** Current location descriptor as injected by BrowserRouter */
  location: PropTypes.object.isRequired,
  /** Should we keep the query string between redirects */
  keepSearch: PropTypes.bool,
  push: PropTypes.bool,
  from: PropTypes.string,
  path: PropTypes.string,
  exact: PropTypes.bool,
  strict: PropTypes.bool,
}

Redirect.defaultProps = {
  keepSearch: true,
}

export default Redirect
