import React from 'react'

import PropTypes from 'prop-types'

import { Link } from '@material-ui/core'
import { observer } from 'mobx-react'
import { Trans } from 'react-i18next'
import { Link as RouterLink } from 'react-router-dom'

import { Money } from 'shared/components/atoms'
import { useTranslation } from 'shared/hooks'

import { PricingMarkup } from 'retailer/components/atoms'
import { RetailerPricingRuleStore } from 'retailer/stores'

import style from './RetailerPricingRuleHelper.module.scss'

const PricingRulesLink = ({ t }) => (
  <Link
    component={RouterLink}
    to="/my-products/pricing-rules"
    target="_blank"
    rel="opener"
    className={style.Link}
  >
    {t('pricingRules.pricingRulesLink.label')}
  </Link>
)

PricingRulesLink.propTypes = {
  t: PropTypes.func.isRequired,
}
const NoPricingRuleMessage = ({ t }) => {
  return (
    <Trans
      t={t} i18nKey="pricingRules.noPricingRuleMessage"
      components={{
        pricingRulesLink: <PricingRulesLink t={t} />
      }}
    />
  )
}

NoPricingRuleMessage.propTypes = {
  t: PropTypes.func.isRequired,
}

const IncludeShippingInPriceMessage = ({ pricingRule, t }) => {
  if (!pricingRule.fixedShippingRate) {
    return (
      <Trans
        t={t} i18nKey="pricingRules.fixedShippingIncludedMessage"
        components={{
          pricingMarkup: <PricingMarkup {...pricingRule.price} currency={pricingRule.currency} />,
          pricingRulesLink: <PricingRulesLink t={t} />
        }}
      />
    )
  }
  return (
    <Trans
      t={t} i18nKey="pricingRules.fixedShippingIncludedMessage"
      components={{
        money: <Money amount={pricingRule.fixedShippingRate} currency={pricingRule.currency} />,
        pricingMarkup: <PricingMarkup {...pricingRule.price} currency={pricingRule.currency} />,
        pricingRulesLink: <PricingRulesLink t={t} />
      }}
    />
  )
}

IncludeShippingInPriceMessage.propTypes = {
  pricingRule: PropTypes.instanceOf(RetailerPricingRuleStore).isRequired,
  t: PropTypes.func.isRequired,
}

const DefaultMessage = ({ pricingRule, t }) => {
  return (
    <Trans
      t={t} i18nKey="pricingRules.defaultMessage"
      components={{
        pricingMarkup: <PricingMarkup {...pricingRule.price} currency={pricingRule.currency} />,
        pricingRulesLink: <PricingRulesLink t={t} />
      }}
    />
  )
}

DefaultMessage.propTypes = {
  pricingRule: PropTypes.instanceOf(RetailerPricingRuleStore).isRequired,
  t: PropTypes.func.isRequired,
}

const RetailerPricingRuleHelper = ({ pricingRule }) => {
  const { t } = useTranslation('productPage')

  const message = (() => {
    if (!pricingRule) return <NoPricingRuleMessage t={t} />
    if (pricingRule.includeShippingRateInPrice) return <IncludeShippingInPriceMessage pricingRule={pricingRule} t={t} />
    return <DefaultMessage pricingRule={pricingRule} t={t} />
  })()

  return message
}

RetailerPricingRuleHelper.propTypes = {
  pricingRule: PropTypes.instanceOf(RetailerPricingRuleStore),
}

export default observer(RetailerPricingRuleHelper)
