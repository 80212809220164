import { action, computed, observable } from 'mobx'

import { adaptSyncListItem } from 'shared/adapters/retailerItems'
import {
  exportRetailerItemToCsv,
  getRetailerItem,
  lockRetailerItemPrice,
  unlockRetailerItemPrice,
} from 'shared/api/retailerItems'
import { WIX } from 'shared/constants/integrations'
import { SYNC_STATUS_IN_PROGRESS } from 'shared/constants/retailerItems'
import { getBiHeadersFromEvent } from 'shared/stores/BiLoggerStore/utils'
import { getIntegrationName } from 'shared/utils/integrations'

import { RetailerProductStore } from 'retailer/stores'

class SyncListItemStore extends RetailerProductStore {
  @observable storeAdminEditUrl
  @observable hidden
  @observable exportStatus
  @observable syncStatusBadge
  @observable priceLockedChangeInProgress
  @observable activeVariantsCount
  @observable variantsCount
  @observable unsureInventory

  @observable selected = false
  @observable csvExportInProgress = false

  @computed get id () {
    return this.uuid
  }

  @computed get onSale () {
    if (!this.pricingRanges) return false

    return (
      this.pricingRanges.cost.from < this.pricingRanges.retail_price.from ||
      this.pricingRanges.cost.to < this.pricingRanges.retail_price.to
    )
  }

  /**
   * Return product external URL for products that have it (i.e. products from external suppliers
   * like Aliexpress and Alibaba), and for other simply return a URL to Modalyst own marketplace.
   * @returns {string}
   */
  @computed get productFromSourceUrl () {
    if (this.productExternalUrl) return this.productExternalUrl
    return `/retailer/marketplace/${this.itemUuid}`
  }

  @computed get isWix () {
    return this.retailerAuthorizationType === WIX
  }

  @computed get vendorDisplay () {
    if (this.isPrintOnDemand) return ''
    if (this.is3rdPartySupplier) return getIntegrationName(this.inventoryType)
    return this.vendor
  }

  @computed get exportInProgress () {
    return this.exportStatus === SYNC_STATUS_IN_PROGRESS
  }

  @action.bound
  setSelected (value) {
    this.selected = Boolean(value)
  }

  @action.bound
  refreshFromApi () {
    return getRetailerItem(this.uuid)
      .then(response => {
        const adaptedData = adaptSyncListItem(response.data)
        this.assignData(adaptedData)
      })
  }

  @action.bound
  async togglePriceLocked () {
    const action = this.priceLocked ? unlockRetailerItemPrice : lockRetailerItemPrice
    this.priceLockedChangeInProgress = true
    try {
      const biEvent = await this.logAutoPriceStatusChanged({
        selectionType: 'toggle',
        status: this.priceLocked,
      })
      await action(this.uuid, getBiHeadersFromEvent(biEvent))
      await this.refreshFromApi()
      return true
    } finally {
      this.priceLockedChangeInProgress = false
    }
  }

  @action.bound
  moveToImportList () {
    // FIXME: moveToImportList should be called on syncListPageStore
    return this.root.syncListPageStore.moveToImportList(this)
  }

  @action.bound
  removeFromStore () {
    // FIXME: removeFromStore should be called on syncListPageStore
    return this.root.syncListPageStore.productRemove.chooseProduct(this)
  }

  @action.bound
  resync () {
    // FIXME: resync should be called on syncListPageStore
    return this.root.syncListPageStore.resync(this.uuid)
  }

  @action.bound
  async exportToCsv () {
    this.csvExportInProgress = true
    const biEvent = await this.logExportToCsvClicked()
    try {
      const response = await exportRetailerItemToCsv(this.uuid, getBiHeadersFromEvent(biEvent))
      this.csvExportInProgress = false
      return {
        url: response.data.csv_url,
        fileName: response.data.filename
      }
    } catch (e) {
      // TODO: add proper error handling
      this.csvExportInProgress = false
      throw e
    }
  }
}

export default SyncListItemStore
