import { modalystPodEditorEditorLoaded, modalystPodEditorSaveDesignClicked } from '@wix/bi-logger-print-on-demand/v2'
import Cookies from 'js-cookie'
import { action, computed, observable } from 'mobx'
import qs from 'query-string'
import { v4 as uuid4 } from 'uuid'

import { adaptPostProductDraftRequest, adaptPostProductDraftResponse } from 'shared/adapters/productDrafts'
import { postProductDraft, putProductDraft } from 'shared/api/productDrafts'
import { postAsset } from 'shared/api/products'
import { RETAILER_ITEM_DRAFT, RETAILER_ITEM_NEW, RETAILER_ITEM_PUBLISHED } from 'shared/constants/retailerItems'
import { dataURItoBlob } from 'shared/utils'

import { adaptKornitSaveStateCallbackPayload } from 'retailer/components/organisms/ItemCustomizerModal/adapters'

/**
 * The default config string for most Kornit products. Just a few of them require a different one.
 * @type {string}
 */
const DEFAULT_EDITOR_CONFIG = 'qmw91f527bhmp3s'

class ItemCustomizerStore {
  /** @type {import('../root').RootStore} */
  root

  mei = Math.random().toString(16).substr(2)
  meo = window.location.origin

  @observable open = false
  @observable isSaving = false
  @observable isLoaded = true
  @observable isDraft = false
  @observable savedAs
  @observable error
  @observable item
  /** Retaile product id */
  @observable id
  /** Supplier product id */
  @observable productId
  /** Supplier external product id (Kornit's id) */
  @observable externalProductId
  /** Synced store productś id (eg. Wix's id) */
  @observable storeProductId
  @observable productName
  @observable createdFrom
  @observable draftRef
  @observable status

  @observable biContext
  @observable useStaging = false
  @observable editorConfig = DEFAULT_EDITOR_CONFIG

  /**
   * @param {import('../root').RootStore} root
   */
  constructor (root) {
    this.root = root
    this.useStaging = !!Cookies.get('kornit_staging')
  }

  /**
   * Edit design of a product. If provided with productId and externalId only,
   * will open a new design for a supplier product (saving will create a new retailer product).
   * If additionally provided with (retailer product) id, will create/edit design on existing
   * retailer product.
   * @param {Object} options params object
   * @param {string} options.productId supplier product id, should come from the `Item.uuid` field.
   * @param {string} options.externalId Kornit product ID (`Item.external_id` field).
   * @param {string} options.id retailer product id, expected to be undefined when creating a design on supplier product
   * @param {string} options.status current retailer product status
   * @param {string} options.createdFrom `RetailerItem.customized_item_draft_url`
   * @param {string} options.draftRef `RetailerItem.customized_item_draft_ref`
   * @param {string} options.customEditorConfig A custom `c` parameter for the editor <iframe> `src`.
   * @param {string} options.productName
   * @param {string} options.storeProductId product id in connected store eg. Wix
   * @param {object} options.biContext A context object for bi events
   * @param {string} options.biContext.correlationId
   * @param {string} options.biContext.origin
   */
  @action.bound
  editDesign ({
    productId, externalId,
    id, status, createdFrom, draftRef, customEditorConfig,
    productName, storeProductId, biContext = {}
  }) {
    this.productId = productId
    this.externalProductId = externalId

    this.id = id
    this.status = status
    this.createdFrom = createdFrom
    this.draftRef = draftRef
    this.editorConfig = customEditorConfig || DEFAULT_EDITOR_CONFIG

    this.productName = productName
    this.storeProductId = storeProductId
    this.biContext = { correlationId: uuid4(), ...biContext }

    this.show()
  }

  @computed get iframeOrigin () {
    return `https://${this.useStaging ? 'legacy.custom-gateway.net' : 'g3d-app.com'}`
  }

  @computed get iframeUrl () {
    const {
      useStaging,
      iframeOrigin,
      editorConfig,
      meo,
      mei,
      productId,
      externalProductId,
      draftRef,
    } = this

    if (!productId) return undefined

    const path = useStaging ? '/acp/app/' : `/s/app/wix/en_GB/${editorConfig}.html`
    const searchParams = useStaging ? { l: 'wix-staging' } : {}
    const hashParams = {
      meo,
      mei,
      d: 505063,
      r: '2d-canvas',
      a2c: 'postMessage',
      c: useStaging ? editorConfig : undefined,
      _noSavePopup: 1,
      _usePs: 1,
      epa: 'https://app.custom-gateway.net/api/p/3/epa-v2/:productRef/s/505063/b/any/c/USD/pt/2&d=422980',
    }

    if (draftRef) {
      hashParams.ps = draftRef
    } else {
      hashParams.p = externalProductId
    }

    return `${iframeOrigin}${path}?${qs.stringify(searchParams)}#${qs.stringify(hashParams)}`
  }

  @computed get biEventData () {
    return {
      retailerProductId: this.id,
      modalystProductId: this.productId,
      productId: this.storeProductId,
      supplierProductId: this.externalProductId,
      productName: this.productName,
      ...(this.biContext || {}),
      pageName: this.biContext?.origin,
      impressionId: this.biContext?.correlationId,
    }
  }

  @action.bound
  show () {
    this.open = true
  }

  @action.bound
  hide () {
    this.open = false
  }

  @action.bound
  setIsLoaded (value) {
    this.isLoaded = Boolean(value)
  }

  @action.bound
  setIsSaving (value) {
    this.isSaving = Boolean(value)
  }

  @action.bound
  handleEvent (messageEvent) {
    const { origin, data } = messageEvent
    let payload
    if (origin === this.iframeOrigin && data.id === this.mei) {
      switch (data.name) {
        case 'RENDERER_READY':
          this.setIsLoaded(true)
          this.root.biLoggerStore.log(modalystPodEditorEditorLoaded(this.biEventData))
          break
        case 'ADD_TO_CART_CALLBACK':
          break
        case 'SHOW_BUSY_NOW':
          this.setIsSaving(true)
          break
        case 'SAVE_STATE_CALLBACK':
          payload = adaptKornitSaveStateCallbackPayload(data.body)
          this.root.biLoggerStore.log(modalystPodEditorSaveDesignClicked({
            ctaName: payload.status === RETAILER_ITEM_DRAFT ? 'save_draft' : 'save_import_list',
            ...this.biEventData,
          }))

          this.saveDraft(payload)
            .then(({ status, itemUuid }) => {
              this.savedAs = status
            })
          break
        case '_CLOSE':
          this.hide()
          break
      }
    }
  }

  _validateImageType (mimeType) {
    switch (mimeType) {
      case 'image/png': return 'png'
      case 'image/jpeg': return 'jpg'
      case 'image/webp': return 'webp'
      case 'image/gif': return 'gif'
      default: throw new Error('Unknown image type')
    }
  }

  async _uploadImage (dataUrl) {
    const uuid = uuid4()
    const file = dataURItoBlob(dataUrl)
    file.lastModifiedDate = new Date()
    file.name = `${uuid}.${this._validateImageType(file.type)}`
    const formData = new FormData()
    formData.append('uuid', uuid)
    formData.append('data', file, file.name)
    return postAsset(formData)
  }

  /**
   * Uploads images sequentially if data url is passed and returns a resulting array
   * of either image URLs or new asset uuids
   **/
  async _processImages (data) {
    const images = []
    for (const image of data) {
      if (/^(http|https):\/\//.test(image)) {
        images.push(image)
      } else {
        const createAssetResponse = await this._uploadImage(image)
        images.push(createAssetResponse.data.uuid)
      }
    }
    return images
  }

  /**
   * Upload images for each variant and mutate the data
   * TODO: the BE serializers will leave just a single image for a variant
   *       so we could as well do it here
   **/
  async _processVariants (data) {
    for (const variant of data) {
      variant.images = await this._processImages(variant.images)
    }
    return data
  }

  /**
   * Handles save action from POD editor
   *
   * @param {Object} options params object
   * @param {string} options.createdFrom
   * @param {string} options.draftRef
   * @param {Array} options.variants
   * @param {Array} options.sizes
   * @param {Array} options.images
   * @param {string} options.status requested status; either save to import list (`new`) or as draft
   *
   */
  @action.bound
  async saveDraft ({ createdFrom, draftRef, sizes, status = RETAILER_ITEM_NEW, ...data }) {
    this.setIsSaving(true)
    this.isDraft = status === RETAILER_ITEM_DRAFT
    this.error = undefined
    const { id, productId } = this

    const variants = await this._processVariants(data.variants)
    const images = await this._processImages(data.images)

    const payload = adaptPostProductDraftRequest({
      productId,
      createdFrom,
      draftRef,
      variants,
      sizes,
      images,
      status: this.status === RETAILER_ITEM_PUBLISHED ? RETAILER_ITEM_PUBLISHED : status,
    })

    // if target status is draft and product is already added, create a new draft
    // from the same supplier product
    const apiCall = id && !(status === RETAILER_ITEM_DRAFT && this.status === RETAILER_ITEM_NEW)
      ? putProductDraft(id, payload)
      : postProductDraft(payload)

    try {
      const response = await apiCall
      this.hide()
      this.root.productDraftListStore.fetchFresh()
      this.setIsSaving(false)
      return adaptPostProductDraftResponse(response.data)
    } catch (e) {
      this.error = e?.response?.data?.error || String(e)
      this.setIsSaving(false)
    }
  }

  @action.bound
  reset () {
    this.hide()
    this.isDraft = false
    this.isLoaded = true
    this.isSaving = false
    this.savedAs = undefined
    this.id = undefined
    this.productId = undefined
    this.externalProductId = undefined
    this.storeProductId = undefined
    this.productName = undefined
    this.createdFrom = undefined
    this.draftRef = undefined
    this.error = undefined
    this.editorConfig = DEFAULT_EDITOR_CONFIG
    this.biContext = undefined
  }
}

export default ItemCustomizerStore
