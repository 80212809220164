import camelCase from 'lodash/camelCase'
import isArray from 'lodash/isArray'
import isEmpty from 'lodash/isEmpty'
import isString from 'lodash/isString'
import snakeCase from 'lodash/snakeCase'

const keysToCase = (obj, transformer) => {
  return Object.entries(obj).reduce((result, [key, value]) => {
    return Object.assign(result, { [transformer(key)]: value })
  }, {})
}

const valuesToCase = (obj, transformer) => {
  return Object.entries(obj).reduce((result, [key, value]) => {
    return Object.assign(result, { key: transformer(value) })
  }, {})
}

const keysToCamelCase = obj => keysToCase(obj, camelCase)
const keysToSnakeCase = obj => keysToCase(obj, snakeCase)

const valuesToCamelCase = obj => valuesToCase(obj, camelCase)
const valuesToSnakeCase = obj => valuesToCase(obj, snakeCase)

/**
 * Adapt API errors for field array.
 *
 * The errors can either pertain to field(s) inside one of the rows,
 * in which case the value is an array of objects, or to the array itself
 * (eg. for min length validation), in which case the value is an array
 * of error messages.
 *
 * @param {Array} value value received from the API
 * @param {Function} adapterFn error object adapter function
 * @returns {Array} an array of error messages or array of field error objects
 */
const adaptFieldArrayErrors = (value, adapterFn) => {
  if (isEmpty(value)) return undefined
  if (!isArray(value)) throw new Error('Value must be an array')
  // if all elements are strings, the error is for the array itself
  if (value.every(obj => isString(obj))) return value
  // otherwise we assume the array contains objects with row field errors
  return value.map(adapterFn)
}

export {
  keysToCamelCase,
  keysToSnakeCase,
  valuesToCamelCase,
  valuesToSnakeCase,
  adaptFieldArrayErrors,
}
