import React, { useEffect, useRef } from 'react'

import PropTypes from 'prop-types'

import IntersectionObserver from '@researchgate/react-intersection-observer'
import { observer } from 'mobx-react'

import { DataModal } from 'shared/components/organisms'

import { BottomLoader } from 'retailer/components/molecules'
import { ProductDraftSlate, VirtualizedGrid } from 'retailer/components/organisms'

import style from './ProductDraftsModal.module.scss'

const ProductDraftsModal = ({ productDraftListStore, onExited }) => {
  const contentRef = useRef(null)
  const {
    isLoading,
    items,
    hasMore,
    setShouldFetchMore,
    moveDraftToImportList,
    deleteDraft,
    reset,
  } = productDraftListStore

  useEffect(() => {
    return () => reset()
  }, [])

  const handleExited = () => {
    reset()
    onExited && onExited()
  }

  const handleIntersection = ({ isIntersecting }) => setShouldFetchMore(isIntersecting)
  const handleDelete = productDraftStore => deleteDraft(productDraftStore)
  const handleMoveToImportList = productDraftStore => moveDraftToImportList(productDraftStore)
  const handleOrderSample = productDraftStore => productDraftStore.orderSample()

  // FIXME: backdrop / esc won't close the modal
  return (
    <DataModal
      testId="ProductDraftsModal"
      hide={isLoading === false && items?.length === 0}
      title="Choose a draft to edit"
      className={style.ProductDraftsModal}
      showSpinner={isLoading}
      fullWidth maxWidth="md"
      renderBody={() => (
        <>
          <VirtualizedGrid
            items={items} itemMinWidth={160}
            renderItemContent={store => (
              <ProductDraftSlate
                productDraftStore={store}
                onMoveToImportList={handleMoveToImportList}
                onOrderSample={handleOrderSample}
                onDelete={handleDelete}
              />
            )}
          />
          <IntersectionObserver
            root={contentRef.current}
            onChange={handleIntersection}
            disabled={isLoading}
          >
            <BottomLoader active={hasMore} />
          </IntersectionObserver>
        </>
      )}
      onExited={handleExited}
      contentRef={contentRef}
    />
  )
}

ProductDraftsModal.propTypes = {
  onExited: PropTypes.func,
  productDraftListStore: PropTypes.shape({
    isLoading: PropTypes.bool,
    hasMore: PropTypes.bool.isRequired,
    items: PropTypes.array,
    reset: PropTypes.func.isRequired,
    setShouldFetchMore: PropTypes.func.isRequired,
    deleteDraft: PropTypes.func.isRequired,
    moveDraftToImportList: PropTypes.func.isRequired,
  }),
}

export default observer(ProductDraftsModal)
