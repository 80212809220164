import { yupResolver } from '@hookform/resolvers/yup'
import DataObjectParser from 'dataobject-parser'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import merge from 'lodash/merge'
import set from 'lodash/set'
import * as yup from 'yup'

import {
  RETAILER_AUTHORIZATION_NONE,
  RETAILER_AUTHORIZATION_SHOPIFY, RETAILER_AUTHORIZATION_WIX,
} from 'shared/constants/accounts'
import { TAB_DESCRIPTION, TAB_GENERAL, TAB_MEDIA, TAB_PRICING, TAB_VARIANTS } from 'shared/constants/importList'

const SKUS = 'skus'
const OPTION_NAMES = 'optionNames'
const OPTION_VALUES = 'optionValues'

const getDescriptionFieldName = () => `${TAB_DESCRIPTION}.description`
const getSkuFieldName = (variantUuid) => `${TAB_VARIANTS}.${SKUS}.${variantUuid}`
const getOptionNameFieldName = (optionUuid) => `${TAB_VARIANTS}.${OPTION_NAMES}.${optionUuid}`
const getOptionValueFieldName = (optionUuid, variantUuid) => (
  `${TAB_VARIANTS}.${OPTION_VALUES}.${optionUuid}.${variantUuid}`
)
const getVariantsNonFieldName = () => `${TAB_VARIANTS}.nonField`
const getComparedAtPriceFieldName = variant => `${TAB_PRICING}.${variant.uuid}.comparedAtPrice`
const getPriceFieldName = variant => `${TAB_PRICING}.${variant.uuid}.price`
const getImagesFieldName = () => `${TAB_MEDIA}.images`

/**
 * A field name for a particular variant price type (usually `price` or `comparedAtPrice`).
 * @param {string} variantUuid
 * @param {string} fieldType
 * @returns {`pricing.${string}.${string}`}
 */
const constructPricingFieldName = (variantUuid, fieldType) => (
  `${TAB_PRICING}.${variantUuid}.${fieldType}`
)

const flattenApiErrors = (data = {}) => Object.values(data).flat()

/**
 * Flattens the deeply nested fieldErrors object into a single-level dot-notated object.
 * This is important because each key has to map to a field name. Avoid passing arrays as values,
 * because keys will be altered (i.e. `general.tags[0]` instead of `general.tags`).
 * @param {Object} fieldErrors
 * @returns {Object}
 */
const parseRemoteErrorsForRhf = (fieldErrors = {}) => {
  return DataObjectParser.untranspose(fieldErrors)
}

/**
 * Get all fields names for the variants tab. This means fields for option names,
 * as well as unique field per each option per each variant.
 * @param item
 * @returns {string[]}
 */
const getVariantTabFieldNames = item => {
  const names = []
  item.uniqueOptionNames.forEach(option => names.push(getOptionNameFieldName(option.uuid)))
  item.variants.forEach(variant => {
    names.push(getSkuFieldName(variant.uuid))
    item.uniqueOptionNames.forEach(({ key }) => {
      const option = variant.options.find(option => option.key === key)
      if (option) names.push(getOptionValueFieldName(option.uuid, variant.uuid))
    })
  })
  return names
}

/***
 * Get all field names for pricing tab. For each  variant there are two fields: comparedAtPrice and price.
 * @param item
 * @returns {string[]}
 */
const getPricingTabFieldNames = item => {
  return item.variants.map(variant => [getComparedAtPriceFieldName(variant), getPriceFieldName(variant)]).flat()
}

const getGeneralTabDefaultValues = (item, data = {}) => {
  return {
    name: item.name || '',
    vendor: item.vendor || '',
    categories: item.categories || [],
    collections: item.collections || [],
    productType: item.productType || null,
    tags: item.tags || [],
    ...data,
  }
}

const getVariantsTabDefaultValues = item => {
  const values = {}
  item.uniqueOptionNames.forEach(option => {
    set(values, getOptionNameFieldName(option.uuid), option.key || '')
  })
  item.variants.forEach(variant => {
    set(values, getSkuFieldName(variant.uuid), variant.sku)
    item.uniqueOptionNames.forEach(({ key }) => {
      const option = variant.options.find(option => option.key === key)
      if (option) set(values, getOptionValueFieldName(option.uuid, variant.uuid), option.value || '')
    })
  })
  return values
}

const getPricingTabDefaultValues = item => {
  const values = {}
  item.variants.forEach(variant => {
    set(values, getPriceFieldName(variant), variant.price)
    set(values, getComparedAtPriceFieldName(variant), variant.comparedAtPrice)
  })
  return values
}

const getImportListProductDefaultValues = item => {
  return {
    [TAB_GENERAL]: getGeneralTabDefaultValues(item),
    [TAB_DESCRIPTION]: { description: item.description },
    ...getVariantsTabDefaultValues(item),
    ...getPricingTabDefaultValues(item),
  }
}

const getImportListValidationSchema = ({ currentRetailerAuthorization }) => {
  const nameValidator = yup.string()
    .required('Product name is required')
    .min(3, 'Product name cannot be shorter than 3 characters')
    .max(80, 'Maximum product name length is 80 characters')
  const vendorValidator = yup.string()
    .ensure()
    .max(255, 'Maximum vendor name length is 255 characters')
  const collectionsValidator = yup.array().of(yup.object({
    uuid: yup.string().uuid().required(),
    name: yup.string().required(),
  }))
  const productTypeValidator = yup.object({
    uuid: yup.string().uuid().required(),
    name: yup.string().required(),
  }).typeError('Product type is required')
  const tagsValidator = yup.array().of(yup.object({
    uuid: yup.string().uuid().required(),
    name: yup.string().required(),
  }))

  const integrationsGeneralTabValidationSchemas = new Map([
    [RETAILER_AUTHORIZATION_NONE, yup.object({
      name: nameValidator,
      vendor: vendorValidator,
      productType: productTypeValidator,
      tags: tagsValidator,
    })],
    [RETAILER_AUTHORIZATION_SHOPIFY, yup.object({
      name: nameValidator,
      vendor: vendorValidator,
      productType: productTypeValidator,
      tags: tagsValidator,
    })],
    [RETAILER_AUTHORIZATION_WIX, yup.object({
      name: nameValidator,
      vendor: vendorValidator,
      collections: collectionsValidator,
    })],
  ])

  return yup.object({
    [TAB_GENERAL]: integrationsGeneralTabValidationSchemas.get(currentRetailerAuthorization),
    [TAB_DESCRIPTION]: yup.object({
      description: yup.string()
        .ensure()
        .max(8000, 'Product description must be 8000 characters or less')
    }),
  })
}

const optionNameValidator = yup.string()
  .required('Provide an option name')
  .min(2, 'Option name must be at least 2-characters-long')
  .max(50, 'Option name cannot be longer than 50 characters')

const validateOptionsNames = async (data, { dirtyFields }) => {
  if (!data || isEmpty(data)) return {}
  const errors = {}
  const allOptions = Object.entries(data)
  await Promise.all(
    allOptions.map(async ([uuid, optionName]) => {
      try {
        await optionNameValidator.validate(optionName)
      } catch (e) {
        set(
          errors,
          getOptionNameFieldName(uuid),
          {
            type: 'custom',
            message: e.errors.join('. ')
          }
        )
        return
      }
      if (
        get(dirtyFields, getOptionNameFieldName(uuid)) &&
        allOptions.filter(([_, name]) => name.toLowerCase() === optionName.toLowerCase()).length > 1
      ) {
        set(
          errors,
          getOptionNameFieldName(uuid),
          {
            type: 'custom',
            message: `Option with name "${optionName}" already exists (option names are case insensitive)`
          }
        )
      }
    })
  )

  return errors
}

const optionValueValidationSchema = yup.string()
  .required('Option value is required')
  .trim()
  .max(50, 'Maximum option value is 50 characters')

/**
 * @param {{string:{string:string}}}data
 * @param {ImportListProductStore} item
 * @param {Object} dirtyFields
 * @returns {Promise<{}>}
 */
const validateOptionValues = async (data, { item, dirtyFields }) => {
  if (!data || isEmpty(data)) return {}
  const errors = {}

  const validators = Object.entries(data).map(([optionUuid, variants]) => {
    const currentOption = item.options.find(option => option.uuid === optionUuid)
    const otherValues = item.uniqueOptionValues.filter(
      option => option.key === currentOption.key && option.uuid !== currentOption.uuid
    )
    return Object.entries(variants).map(async ([variantUuid, value]) => {
      const fieldName = getOptionValueFieldName(optionUuid, variantUuid)
      try {
        await optionValueValidationSchema.validate(optionValueValidationSchema.cast(value))
      } catch (e) {
        set(errors, fieldName, { type: 'custom', message: e.errors.join('. ') })
        return
      }
      if (
        get(dirtyFields, fieldName) &&
        otherValues.find(option => option.value.toLowerCase().trim() === value.toLowerCase().trim())
      ) {
        set(
          errors,
          fieldName,
          {
            type: 'custom',
            message: `${currentOption.key} with value ${value} already exists (option values are case insensitive)`
          }
        )
      }
    })
  }).flat()
  await Promise.all(validators)
  return errors
}

const skuValidationSchema = yup.string()
  .required('SKU is required')
  .trim()
  .min(3, 'SKU cannot be shorter than 3 characters')
  .max(255, 'SKU cannot be longer than 255 characters')

const validateSkus = async (data, { dirtyFields }) => {
  if (!data || isEmpty(data)) return {}
  const errors = {}
  await Promise.all(
    Object.entries(data).map(async ([variantUuid, sku]) => {
      const fieldName = getSkuFieldName(variantUuid)
      try {
        await skuValidationSchema.validate(skuValidationSchema.cast(sku))
      } catch (e) {
        set(errors, fieldName, { type: 'custom', message: e.errors.join('. ') })
      }
      const otherSkus = (
        Object
          .entries(data)
          .filter(([vUuid, _]) => vUuid !== variantUuid)
          .map(([_, sku]) => sku.toLowerCase().trim())
      )
      if (get(dirtyFields, getSkuFieldName(variantUuid)) && otherSkus.includes(sku.toLowerCase().trim())) {
        set(
          errors,
          fieldName,
          {
            type: 'custom',
            message: `Product variant with SKU "${sku}" already exists`
          }
        )
      }
    })
  )
  return errors
}

const validateVariantsTab = async (data, context) => {
  if (!data || isEmpty(data)) return {}
  const { optionNames, optionValues, skus } = data
  const [skusErrors, optionsNamesErrors, optionsValuesErrors] = await Promise.all([
    validateSkus(skus, context), validateOptionsNames(optionNames, context), validateOptionValues(optionValues, context)
  ])
  return merge({}, skusErrors, optionsNamesErrors, optionsValuesErrors)
}

const comparedAtPriceValidationSchema = yup.object({
  price: yup.number()
    .typeError('Provide a valid price')
    .positive('Provide a positive price')
    .max(9999999999.99, 'Price is too high (maximum of 12 digits are accepted)')
    .required('Price is required'),
  comparedAtPrice: yup.number()
    .typeError('Provide a valid price')
    .positive('Provide a positive compare at price')
    .max(9999999999.99, 'Price is too high (maximum of 12 digits are accepted)')
    .nullable()
})

const priceValidationSchema = yup.object({
  price: yup.number()
    .typeError('Provide a valid price')
    .positive('Provide a positive price')
    .max(9999999999.99, 'Price is too high (maximum of 12 digits are accepted)')
    .required('Price is required'),
})

const priceSchemas = new Map([
  [RETAILER_AUTHORIZATION_NONE, priceValidationSchema],
  [RETAILER_AUTHORIZATION_SHOPIFY, comparedAtPriceValidationSchema],
  [RETAILER_AUTHORIZATION_WIX, priceValidationSchema]
])

const validatePricingTab = async (data, { currentRetailerAuthorization }) => {
  if (!data || isEmpty(data)) return {}

  const schema = priceSchemas.get(currentRetailerAuthorization) || priceValidationSchema
  const errors = {}

  Object.entries(data).map(async ([variantUuid, data]) => {
    try {
      await schema.validate(schema.cast(data, { assert: false }))
    } catch (e) {
      set(
        errors,
        constructPricingFieldName(variantUuid, e.path),
        {
          type: 'custom',
          message: e.errors.join('. ')
        }
      )
      return
    }
    const parsedData = schema.cast(data, { assert: false })
    if (schema.fields.comparedAtPrice && parsedData?.comparedAtPrice && parsedData.comparedAtPrice < parsedData.price) {
      set(
        errors,
        getComparedAtPriceFieldName({ uuid: variantUuid }),
        {
          type: 'custom',
          message: 'Compare at price cannot be lower than price'
        }
      )
    }
  })

  return errors
}

/**
 * @param {Object} data
 * @param {{item:Object,currentRetailerAuthorization:string}} context
 * @param {Object} dirtyFields
 * @returns {Promise<{values: *, errors: {[p: string]: *}}>}
 */
const validateImportListProduct = async (data, context, dirtyFields) => {
  const [{ values, errors: yupErrors }, variantsErrors, pricingErrors] = await Promise.all([
    yupResolver(getImportListValidationSchema(context))(data, context),
    validateVariantsTab(data[TAB_VARIANTS], { ...context, dirtyFields }),
    validatePricingTab(data[TAB_PRICING], { ...context, dirtyFields })
  ])
  return {
    values,
    errors: merge({}, yupErrors, variantsErrors, pricingErrors)
  }
}

const checkIfTabHasErrors = (tabErrors = {}) => {
  return !!Object.values(DataObjectParser.untranspose(tabErrors)).length
}

export {
  getDescriptionFieldName,
  getSkuFieldName,
  getOptionValueFieldName,
  getOptionNameFieldName,
  getVariantsNonFieldName,
  getComparedAtPriceFieldName,
  getPriceFieldName,
  getImagesFieldName,
  constructPricingFieldName,
  flattenApiErrors,
  getGeneralTabDefaultValues,
  getVariantTabFieldNames,
  getVariantsTabDefaultValues,
  getPricingTabDefaultValues,
  getPricingTabFieldNames,
  getImportListProductDefaultValues,
  validateImportListProduct,
  parseRemoteErrorsForRhf,
  checkIfTabHasErrors,
}
