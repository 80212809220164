import React from 'react'

import PropTypes from 'prop-types'

import { Box, Button, Typography } from '@material-ui/core'
import { BlockRounded } from '@material-ui/icons'
import { observer } from 'mobx-react'

import { Alert } from 'shared/components/molecules'
import { DataModal, ProgressBar } from 'shared/components/organisms'
import { actionsUserFriendly } from 'shared/constants/archivedRetailerItems'

import ArchiveListBatchRestoreStore from 'retailer/stores/ArchiveListBatchRestoreStore'

import style from './ArchiveListBatchRestoreModal.module.scss'

const ArchiveListBatchRestoreModal = ({ store }) => {
  return (
    <DataModal
      className={style.ArchiveListBatchRestoreModal}
      title={actionsUserFriendly.get(store.action)}
      maxWidth="md"
      fullWidth
      onExited={() => store.dismissModal()}
      renderBody={() => {
        if (store.asyncHandlerStore.inProgress) {
          return (
            <Box mt={2}>
              <Typography paragraph>
                Your products are being restored, please wait...
              </Typography>
              <ProgressBar
                plural="Products"
                processedCount={store.asyncHandlerStore.processed}
                totalCount={store.asyncHandlerStore.totalCount}
                className={style.ProgressData}
              />
            </Box>
          )
        }
        if (store.asyncHandlerStore.isFinished && store.asyncHandlerStore.isStopped) {
          return (
            <Box mt={2}>
              <Typography paragraph>
                Restoration process was stopped before it finished. {store.asyncHandlerStore.processed} products
                were restored of total {store.asyncHandlerStore.totalCount}.
              </Typography>
              {Boolean(store.asyncHandlerStore.errors) && (
                <Alert title="There Were Errors" severity="error">
                  {store.asyncHandlerStore.errors} products could not be restored due to errors.
                </Alert>
              )}
            </Box>
          )
        }
        if (store.asyncHandlerStore.isFinished && !store.asyncHandlerStore.isStopped) {
          return (
            <Box mt={2}>
              <Typography paragraph>
                Restoration process has now finished processing.
              </Typography>
              {Boolean(store.asyncHandlerStore.errors) && (
                <Alert title="There Were Errors" severity="error">
                  {store.asyncHandlerStore.errors} products could not be restored due to errors.
                </Alert>
              )}
            </Box>
          )
        }
        return (
          <Box mt={2}>
            <Typography paragraph>
              Do you wish to continue and restore selected {store.selectedCount} products?
            </Typography>
          </Box>
        )
      }}
      renderActions={({ dismissModal }) => {
        if (store.asyncHandlerStore.isFinished) {
          return (
            <>
              <Button
                variant="outlined"
                size="large"
                onClick={dismissModal}
              >
                Close
              </Button>
            </>
          )
        }
        if (store.asyncHandlerStore.inProgress) {
          return (
            <>
              <Button
                variant="contained"
                size="large"
                color="secondary"
                startIcon={<BlockRounded />}
                onClick={() => store.stop()}
              >
                Stop the removal
              </Button>
            </>
          )
        }
        return (
          <>
            <Button
              variant="outlined"
              size="large"
              onClick={dismissModal}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              size="large"
              onClick={() => store.run()}
            >
              Yes, restore products
            </Button>
          </>
        )
      }}
    />
  )
}

ArchiveListBatchRestoreModal.propTypes = {
  store: PropTypes.instanceOf(ArchiveListBatchRestoreStore).isRequired
}

export default observer(ArchiveListBatchRestoreModal)
