import React, { useCallback, useLayoutEffect, useRef, useState } from 'react'

import PropTypes from 'prop-types'

import { Box, Button, Collapse, IconButton } from '@material-ui/core'
import { Close } from '@material-ui/icons'
import { Alert as MuiAlert } from '@material-ui/lab'
import { observer } from 'mobx-react'
import { Link as RouterLink } from 'react-router-dom'

import { useTranslation } from 'shared/hooks'

import { BiLink } from 'retailer/components/atoms'
import {
  ALERT_SEVERITY_ERROR,
  ALERT_SEVERITY_INFO,
  ALERT_SEVERITY_SUCCESS,
  ALERT_SEVERITY_WARNING,
} from 'retailer/stores/AlertsStore/constants'
import { useStores } from 'retailer/stores/hooks'

import style from './Alerts.module.scss'

const SEVERITIES = [
  ALERT_SEVERITY_INFO, ALERT_SEVERITY_SUCCESS,
  ALERT_SEVERITY_WARNING, ALERT_SEVERITY_ERROR,
]

const AlertActionButton = ({ label, to, biData }) => {
  const Component = biData ? BiLink : RouterLink
  return (
    <Button
      className={style.AlertActionButton}
      variant="contained" size="small" color="inherit" disableElevation
      component={Component} to={to} biData={biData}
      data-testid="Alert-AlertActionButton"
    >
      {label}
    </Button>
  )
}

AlertActionButton.propTypes = {
  label: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
  biData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
}

const DismissAlertButton = props => (
  <IconButton aria-label="close" color="inherit" size="small" data-testid="Alert-DismissAlertButton" {...props}>
    <Close fontSize="inherit" />
  </IconButton>
)

const Alert = observer(({ alertStore }) => {
  const {
    message, severity, action,
    dismissable, dismissed, dismiss,
  } = alertStore

  const { t } = useTranslation('common')

  const handleDismissClick = () => dismiss()

  return (
    <Collapse in={!dismissed}>
      <MuiAlert
        data-testid="Alert"
        className={style.Alert}
        variant="filled" icon={false}
        severity={severity}
        action={dismissable && <DismissAlertButton onClick={handleDismissClick} />}
      >
        <span>{t(message)}</span>
        {action && (
          <AlertActionButton
            severity={severity}
            to={action.to} label={t(action.label)}
            biData={action.biData}
          />
        )}
      </MuiAlert>
    </Collapse>
  )
})

Alert.propTypes = {
  alertStore: PropTypes.shape({
    message: PropTypes.string.isRequired,
    action: PropTypes.object,
    severity: PropTypes.oneOf(SEVERITIES).isRequired,
    dismissable: PropTypes.bool.isRequired,
    dismissed: PropTypes.bool.isRequired,
    dismiss: PropTypes.func.isRequired,
  }),
}

/**
 * Displays sitewide alert boxes on the bottom of the screen
 *
 * To add more alerts, see AlertsStore
 */
const Alerts = () => {
  const { alertsStore } = useStores()
  const { alertsMap } = alertsStore

  const containerRef = useRef(null)
  const [width, setWidth] = useState(undefined)

  const adjustLayout = useCallback(() => {
    setWidth(containerRef.current?.offsetWidth)
  }, [containerRef.current])

  useLayoutEffect(() => {
    adjustLayout()
    window.addEventListener('resize', adjustLayout)
    return () => window.removeEventListener('resize', adjustLayout)
  }, [])

  return (
    <div ref={containerRef}>
      <Box className={style.Alerts} position="fixed" bottom={0} width={width}>
        {[...alertsMap.entries()].map(([key, alertStore]) => (
          alertStore && (
            <Alert key={key} alertStore={alertStore} />
          )
        ))}
      </Box>
    </div>
  )
}

export default observer(Alerts)
