import React from 'react'

import {
  TextField,
  IconButton,
  InputAdornment,
} from '@material-ui/core'
import { Visibility, VisibilityOff } from '@material-ui/icons'
import clsx from 'clsx'

import style from './PasswordField.module.scss'

const PasswordField = ({ className, autoComplete, ...rest }) => {
  const [showPassword, setShowPassword] = React.useState(false)

  return (
    <TextField
      {...rest}
      className={clsx(className, style.PasswordField)}
      InputProps={{
        type: showPassword ? 'text' : 'password',
        autoComplete,
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              edge="end"
              aria-label="toggle password visibility"
              onClick={event => setShowPassword(!showPassword)}
              onMouseDown={event => { event.preventDefault() }}
            >
              {showPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        )
      }}
    />
  )
}

PasswordField.propTypes = {
  ...TextField.propTypes,
}

PasswordField.defaultProps = {
  label: 'Password',
  variant: 'outlined',
}

export default PasswordField
