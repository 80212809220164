import React from 'react'

import PropTypes from 'prop-types'

import { Link } from '@material-ui/core'
import isEmpty from 'lodash/isEmpty'
import { observer } from 'mobx-react'

import { ColorSwatches } from 'shared/components/molecules'
import { ITEM_TABS_ID, TABS } from 'shared/constants/marketplaceItem'
import { useTranslation } from 'shared/hooks'

import { MarketplaceItemDataRow } from 'retailer/components/organisms'

import style from './MarketplaceItemVariantsData.module.scss'

const MarketplaceItemVariantsData = ({ marketplaceItemStore, testId }) => {
  const { t } = useTranslation('productPage')
  const { item, selectTab } = marketplaceItemStore

  const { isCustomizable, colorOptionValues, sizeOptionValues, otherOptions } = item
  const options = isCustomizable ? otherOptions : item.options

  return (
    <div className={style.MarketplaceItemVariantsData}>
      <MarketplaceItemDataRow
        title={t('productData.variantsStock.label')}
        content={item.isCustomizable && item.quantity ? t('productData.variantsStock.inStock') : item.quantity}
        testId={testId ? `${testId}-stock` : undefined}
      />
      {isCustomizable && (
        <>
          {!isEmpty(sizeOptionValues) && (
            <MarketplaceItemDataRow
              title={t('productData.sizes.label')}
              content={sizeOptionValues.join(', ')}
              testId={testId ? `${testId}-sizes` : undefined}
            />
          )}
          {!isEmpty(colorOptionValues) && (
            <MarketplaceItemDataRow
              title={t('productData.colors.label')} alignItems="center"
              content={<ColorSwatches colors={colorOptionValues} />}
              testId={testId ? `${testId}-colors` : undefined}
            />
          )}
        </>
      )}
      {Object.entries(options || {}).map(([name, values]) => (
        <MarketplaceItemDataRow key={name} title={name} content={values.join(', ')} />
      ))}
      {!item.isCustomizable && (
        <MarketplaceItemDataRow
          title=" "
          content={
            <Link
              href={`#${ITEM_TABS_ID}`}
              onClick={() => selectTab(TABS.VARIANTS)}
            >
              {t('productData.allVariants.cta')}
            </Link>
        }
        />
      )}
    </div>
  )
}

MarketplaceItemVariantsData.propTypes = {
  marketplaceItemStore: PropTypes.shape({
    selectTab: PropTypes.func.isRequired,
    item: PropTypes.shape({
      isCustomizable: PropTypes.bool.isRequired,
      quantity: PropTypes.number.isRequired,
      options: PropTypes.object,
      otherOptions: PropTypes.object,
      sizeOptionValues: PropTypes.arrayOf(PropTypes.string),
      colorOptionValues: PropTypes.arrayOf(PropTypes.string),
    }).isRequired,
  }).isRequired,
  testId: PropTypes.string,
}

export default observer(MarketplaceItemVariantsData)
