import head from 'lodash/head'
import { action, computed, observable, reaction } from 'mobx'
import qs from 'query-string'

import { adaptCurrentRetailerAuthorization } from 'shared/adapters/profiles'
import {
  adaptCategories, adaptCollections,
  adaptImportListApiParams,
  adaptImportListCounters,
  adaptImportListItem,
  adaptProductType,
  adaptProductTypes,
  adaptTags,
} from 'shared/adapters/retailerItems'
import { getCurrentRetailerAuthorization } from 'shared/api/profiles'
import {
  addProductType,
  exportRetailerItem,
  getProductCategories,
  getProductTags,
  getProductTypes, getRetailerItemCollections,
  getRetailerItems,
  getRetailerItemsCounters, removeRetailerItem,
} from 'shared/api/retailerItems'
import {
  RETAILER_ITEM_NEW,
  IMPORT_LIST_ITEM_OMIT_FIELDS, PRODUCT_LIST_ORDERING_RECENTLY_ADDED,
} from 'shared/constants/retailerItems'
import { BaseListStore } from 'shared/stores'
import { getBiHeadersFromEvent } from 'shared/stores/BiLoggerStore/utils'
import { toTitleCase } from 'shared/utils/text'

import { ImportListBatchActionStore, ImportListProductStore, ProductRemoveStore } from 'retailer/stores'

const defaultCounters = {
  all: 0,
  available: 0,
  unpublished: 0
}

class ImportListPageStore extends BaseListStore {
  /** @type {import('../context').RootStore} */
  root

  pathnameRegex = /^\/my-products\/import-list$/

  @observable initialized = false

  @observable retailerAuthorization
  @observable currentRetailerAuthorization

  limit = 5
  @observable counters = defaultCounters
  @observable collections = []
  @observable categories = []
  @observable productTypes = []
  @observable tags = []
  @observable filtersPanelOpen = false

  @observable batchAction
  @observable productRemove = new ProductRemoveStore({
    onRemove: async product => await this.removeItem(product),
  })

  /**
   * @param {import('../context').RootStore} root
   */
  constructor (root) {
    super(root.routerStore)
    this.root = root
    this.batchAction = new ImportListBatchActionStore(root, this)
    reaction(this.listParamsGetter, this.listParamsReaction)
  }

  listParamsGetter = () => ({
    offset: this.offset,
    search: this.search,
    supplier: this.supplier,
    ordering: this.ordering,
  })

  getUrl ({ offset, search, supplier, ordering }) {
    const searchQs = qs.stringify({
      search: search === '' ? undefined : search,
      ordering,
      supplier,
      offset,
    }, { arrayFormat: 'bracket' })
    return '/my-products/import-list' + (searchQs ? `?${searchQs}` : '')
  }

  /**
   * Get a param from the URL, or return defaultValue if the argument doesn't exist
   * @param {string} name
   * @param {*} defaultValue
   * @returns {*}
   */
  getParam = (name, defaultValue = undefined) => {
    const params = qs.parse(this.routerStore?.location.search, { arrayFormat: 'bracket' })
    return params[name] || defaultValue
  }

  /**
   * Calculate page and page size based on limit/offset data.
   * @returns {{pageSize: number, page: number}}
   */
  @computed get pagination () {
    return {
      page: Math.ceil(this.offset / this.limit) + 1,
      pageSize: this.limit
    }
  }

  @computed get ordering () {
    return this.getParam('ordering', PRODUCT_LIST_ORDERING_RECENTLY_ADDED)
  }

  @computed get search () {
    return this.getParam('search')
  }

  @computed get supplier () {
    return this.getParam('supplier', [])
  }

  @computed get apiParams () {
    return {
      omit: IMPORT_LIST_ITEM_OMIT_FIELDS,
      status: RETAILER_ITEM_NEW,
      show_out_of_stock: true,
      search: this.search,
      ordering: this.ordering,
      supplier: this.supplier,
      ...this.pagination
    }
  }

  /**
   * FIXME: Duplication with SyncList – refactor
   * Returns a list of all active filters, i.e. filters not in their default state.
   * It is returned in a form that is easy to digest by the `<ActiveFilters>` component.
   * @returns {{onDelete: function(): void, id: string, label: string}[]}
   */
  @computed get activeFilters () {
    const omitFilters = ['ordering', 'offset']
    const allFilters = this.listParamsGetter()

    // Ignore filters which are undefined or null, as these are simply unset filters
    // Also ignore `showOutOfStock` if its value is true, as this is the default value for it.
    // FIXME: Create a more generic way of handling default boolean values for filters such as `showOutOfStock`.
    const filtersFiltered = Object
      .entries(allFilters)
      .filter(([name, filter]) => filter !== undefined && filter !== null && !omitFilters.includes(name))

    // Gather active filters which values are not arrays. The tricky part here is to create a user friendly
    // `label` which is constructed from a user unfriendly param value from the URL query param...
    const nonArrayFilters = filtersFiltered
      .filter(([_, filter]) => !Array.isArray(filter))
      .map(([name, filter]) => ({
        id: name,
        label: toTitleCase(filter),
        onDelete: () => {
          if (name === 'search') {
            this.setFilters({
              ...allFilters,
              search: undefined,
            })
            return
          }
          this.setFilters({
            ...allFilters,
            [name]: undefined
          })
        }
      }))

    // Gather active filters which values are arrays. For these filters each value from the array should be
    // treated as a separate filter.
    const arrayFilters = filtersFiltered
      .filter(([_, filter]) => Array.isArray(filter))
      .map(([name, values]) => {
        return values.map(value => ({
          id: `${name}.${value}`,
          label: toTitleCase(value),
          onDelete: () => {
            this.setFilters({
              ...allFilters,
              [name]: allFilters[name].filter(v => v !== value)
            })
          }
        }))
      }).flat()

    return [].concat(nonArrayFilters, arrayFilters)
  }

  @computed get selectionActive () {
    return !!this.items?.length && (this.allOnAllPagesSelected || this.allOnPageSelected || this.partSelected)
  }

  @computed get allOnPageSelected () {
    return !!this.items?.length && this.items.length === this.selectedItems.length
  }

  @computed get oneSelected () {
    return this.selectedItems.length === 1
  }

  @computed get firstSelected () {
    return head(this.selectedItems)
  }

  /**
   * Calculates how many items are selected, using `this.counters.all` if all pages are selected.
   * @returns {number}
   */
  @computed get selectedCount () {
    if (this.allOnAllPagesSelected && this.allOnPageSelected) {
      return this.counters.all
    }
    if (this.allOnAllPagesSelected && !this.allOnPageSelected) {
      return this.counters.all - (this.items.length - this.selectedItems.length)
    }
    return this.selectedItems.length
  }

  @action.bound
  setFilters ({ search, supplier, ordering }) {
    this.routerStore.push(this.getUrl({
      search,
      ordering,
      supplier,
      offset: 0
    }))
  }

  @action.bound
  reset () {
    this.initialized = false
    super.reset()
    this.error = undefined
    this.filtersPanelOpen = false
    this.counters = defaultCounters
    this.currentRetailerAuthorization = undefined
  }

  @action.bound
  setFiltersPanelOpen (value) {
    this.filtersPanelOpen = !!value
  }

  @action.bound
  openFiltersPanel () {
    this.filtersPanelOpen = true
  }

  @action.bound
  closeFiltersPanel () {
    this.filtersPanelOpen = false
  }

  @action.bound
  async fetchAdditionalData () {
    const [retailerAuth, productTypes, tags, categories, collections] = await Promise.all([
      getCurrentRetailerAuthorization(),
      getProductTypes(),
      getProductTags(),
      getProductCategories(),
      getRetailerItemCollections(),
    ])
    this.retailerAuthorization = adaptCurrentRetailerAuthorization(retailerAuth.data)
    this.currentRetailerAuthorization = this.retailerAuthorization.currentRetailerAuthorization
    this.productTypes = adaptProductTypes(productTypes.data.results)
    this.tags = adaptTags(tags.data.results)
    this.categories = adaptCategories(categories.data.results)
    this.collections = adaptCollections(collections.data.results)
  }

  @action.bound
  async doFetch () {
    this.setAllSelected(false)
    this.error = undefined

    try {
      const [counters, items, nextPage] = await Promise.all([
        getRetailerItemsCounters(RETAILER_ITEM_NEW),
        getRetailerItems(adaptImportListApiParams(this.apiParams)),
        getRetailerItems(adaptImportListApiParams({
          ...this.apiParams,
          page: this.apiParams.page + 1,
        }))
      ])
      Object.assign(this.counters, adaptImportListCounters(counters.data))
      this.items = items.data.results.map((item, pos) => (
        new ImportListProductStore(this.root, adaptImportListItem(item), { ...this.biContext, position: pos + 1 })
      ))
      this.hasNextPage = !!nextPage.data.results.length
      this.initialized = true
      if (!this.items.length) return { data: { count: 0 } }
      return items
    } catch (e) {
      this.error = e
      this.items = []
      return { data: { count: 0 } } // Fake response object
    }
  }

  @action.bound
  next () {
    this.routerStore.push(this.getUrl({
      ...this.listParamsGetter(),
      offset: this.offset + this.count
    }))
  }

  @action.bound
  async exportItem (item) {
    try {
      const biEvent = await item.logAddProductToSyncListClicked()
      const response = await exportRetailerItem(item.uuid, getBiHeadersFromEvent(biEvent))
      if (response.status === 201) {
        this.items = this.items.filter(iterItem => iterItem.uuid !== item.uuid)
        return true
      }
      return false
    } catch (e) {
      console.info(e)
      return false
    }
  }

  @action.bound
  async removeItem (product) {
    const biEvent = await product.logRemoveProductClicked()
    await removeRetailerItem(product.uuid, getBiHeadersFromEvent(biEvent))
    this.items = this.items.filter(iterItem => iterItem.uuid !== product.uuid)
  }

  @action.bound
  async addProductType (name) {
    try {
      const response = await addProductType({ name })
      const newType = adaptProductType(response.data)
      this.productTypes.push(newType)
      return newType
    } catch (e) {
      console.info(e)
    }
  }

  /**
   * BI Context for child stores
   * @type {import('../../types').ProductBiContext
   */
  @computed get biContext () {
    return {
      origin: 'import_list',
      appliedFilters: this.apiParams,
      appliedSort: this.ordering,
    }
  }
}

export default ImportListPageStore
