import React from 'react'

import PropTypes from 'prop-types'

import { Avatar, Button } from '@material-ui/core'
import clsx from 'clsx'
import { observer } from 'mobx-react'
import { ChevronDown, User } from 'wix-ui-icons-common'

import style from './UserProfileButton.module.scss'

const UserProfileButton = ({ userProfileStore, className, ...props }) => {
  const { avatarImage } = userProfileStore
  return (
    <Button
      {...props}
      className={clsx(style.UserProfileButton, className)}
      startIcon={<Avatar src={avatarImage} color="secondary"><User /></Avatar>}
      endIcon={<ChevronDown />}
    />
  )
}

UserProfileButton.propTypes = {
  userProfileStore: PropTypes.shape({
    fullName: PropTypes.string.isRequired,
    avatarImage: PropTypes.string,
  }),
  className: PropTypes.string,
}

export default observer(UserProfileButton)
