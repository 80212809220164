import React from 'react'

import PropTypes from 'prop-types'

import { List, ListItem, Slide, TextField, Typography } from '@material-ui/core'
import { ChevronLeft, Search } from '@material-ui/icons'
import clsx from 'clsx'
import { observer } from 'mobx-react'

import style from './FiltersDrawerSection.module.scss'

/**
 * A header for an opened section, doubling as a button to close it and go
 * back to the parent section.
 */
const SectionHeader = ({ title, onClick }) => (
  <ListItem button onClick={onClick} className={style.SectionHeader}>
    <ChevronLeft data-testid="FiltersDrawerSection-backButton" />
    <Typography component="span" variant="h6" color="textPrimary">
      {title}
    </Typography>
  </ListItem>
)

SectionHeader.propTypes = {
  title: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
}

const SearchInput = observer(({ onChange, ...rest }) => {
  const handleChange = event => onChange(event.currentTarget.value)
  return (
    <ListItem>
      <TextField
        variant="outlined" size="small" fullWidth
        InputProps={{ endAdornment: <Search /> }}
        onChange={handleChange}
        data-testid="FiltersDrawerSection-searchInput"
        {...rest}
      />
    </ListItem>
  )
})

SearchInput.propTypes = TextField.propTypes

const FiltersDrawerSection = ({
  title, open, onClose, direction, search, searchPlaceholder, className, children, ...rest
}) => {
  const handleSearchChange = search?.setQuery
  const handleClose = () => onClose && onClose()
  return (
    <Slide direction={direction} in={open} unmountOnExit {...rest}>
      <List
        component="div" disablePadding className={clsx(style.FiltersDrawerSection, className)}
        subheader={title && <SectionHeader title={title} onClick={handleClose} />}
      >
        {search && (
          <SearchInput
            value={search.query} onChange={handleSearchChange}
            placeholder={searchPlaceholder}
          />
        )}
        <div className={style.SectionContent}>
          {children}
        </div>
      </List>
    </Slide>
  )
}

FiltersDrawerSection.propTypes = {
  /** Provide title to show a subheader doubling as a back button */
  title: PropTypes.string,
  onClose: PropTypes.func,
  open: PropTypes.bool,
  direction: PropTypes.oneOf(['left', 'right']),
  search: PropTypes.shape({
    query: PropTypes.string.isRequired,
    setQuery: PropTypes.func.isRequired,
  }),
  searchPlaceholder: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
}

FiltersDrawerSection.defaultProps = {
  direction: 'left',
}

export default observer(FiltersDrawerSection)
