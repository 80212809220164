import React from 'react'

import PropTypes from 'prop-types'

import { observer } from 'mobx-react'

import { Money } from 'shared/components/atoms'

const PricingMarkup = ({ markupType, markup, currency }) => {
  if (markupType === 'multiplied') {
    return (
      <span>
        multiplied markup of ×{markup}
      </span>
    )
  }
  return (
    <span>
      added markup of <Money amount={markup} currency={currency} />
    </span>
  )
}

PricingMarkup.propTypes = {
  markupType: PropTypes.oneOf(['multiplied', 'added']).isRequired,
  markup: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  currency: PropTypes.string
}

PricingMarkup.defaultProps = {
  currency: 'USD'
}

export default observer(PricingMarkup)
