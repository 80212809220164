import { action, computed, observable, reaction } from 'mobx'

import { adaptArchivedItem, adaptArchivedItemQueryParams } from 'shared/adapters/archivedRetailerItems'
import { getArchivedRetailerItems } from 'shared/api/archivedRetailerItems'
import {
  ACTION_RESTORE_TO_IMPORT_LIST,
  REMOVAL_REASON_ALL,
  removalReasonsUserFriendly,
} from 'shared/constants/archivedRetailerItems'
import { FilterStore, SearchStore, LoadableStore } from 'shared/stores'
import { QueryParamsOperator } from 'shared/utils'

import { ArchivedItemStore, ArchiveListBatchRestoreStore, PaginationStore } from 'retailer/stores'

class ArchiveListPageStore {
  @observable loading = new LoadableStore()
  @observable error = false
  @observable fetchInProgress = false

  @observable items = []

  @observable selectedAllOnAllPages = false
  @observable batchRestore = new ArchiveListBatchRestoreStore(this)
  @observable pagination = new PaginationStore(1, 1, 100)
  @observable searchFilter = new SearchStore('search', '', false)
  @observable nonRestorableFilter = new FilterStore('nonRestorable', false, false, false)
  @observable removalReasonFilter = new FilterStore(
    'removalReason', REMOVAL_REASON_ALL, true, true,
    {
      valuesMap: removalReasonsUserFriendly
    }
  )

  constructor () {
    reaction(
      () => this.queryParams,
      () => {
        this.resetPagination()
        this.fetchInProgress = true
        this.fetch().then(() => {
          this.fetchInProgress = false
        })
      }
    )
  }

  @computed get selectedItems () {
    return this.items.filter(item => item.selected)
  }

  @computed get selectedCount () {
    return this.selectedItems.length
  }

  @computed get selectedAll () {
    return Boolean(
      this.selectedCount && (this.selectedCount === this.items.length)
    )
  }

  @computed get selectedSome () {
    return Boolean(
      this.selectedCount && (this.selectedCount < this.items.length)
    )
  }

  @computed get queryParams () {
    return {
      search: this.searchFilter.value,
      removalReason: this.removalReasonFilter.value,
      nonRestorable: this.nonRestorableFilter.active,
    }
  }

  @computed get apiQueryParams () {
    return {
      ...this.pagination.params,
      ...this.queryParams
    }
  }

  @action.bound
  resetPagination () {
    this.pagination.reset()
  }

  @action.bound
  changePage (page) {
    if (page === this.pagination.currentPage) return
    this.fetchInProgress = true
    this.pagination.setPage(page)
    return this.fetch().then(() => {
      this.fetchInProgress = false
    })
  }

  @action.bound
  fetch () {
    const queryString = new QueryParamsOperator(adaptArchivedItemQueryParams(this.apiQueryParams)).getSearchAsText()
    return getArchivedRetailerItems(queryString)
      .then(response => {
        this.pagination.setTotalCount(response.data.count)
        this.items = response.data.results.map(item => new ArchivedItemStore(this, adaptArchivedItem(item)))
        this.error = false
      })
      .catch(() => {
        this.error = true
      })
  }

  @action.bound
  load () {
    if (this.loading.isLoading) return
    this.loading.setLoading()
    this.fetch().finally(() => {
      this.loading.setLoaded()
    })
  }

  @action.bound
  selectAll () {
    this.items.filter(item => item.restorable).forEach(item => item.setSelected(true))
  }

  @action.bound
  deselectAll () {
    this.selectedAllOnAllPages = false
    this.items.filter(item => item.restorable).forEach(item => item.setSelected(false))
  }

  @action.bound
  toggleSelectAll () {
    if (this.selectedAll || this.selectedSome) this.deselectAll()
    else this.selectAll()
  }

  @action.bound
  selectAllOnAllPages () {
    this.selectedAllOnAllPages = true
  }

  @action.bound
  restoreItem (id, action) {
    const item = this.items.find(item => item.id === id)
    if (!item) {
      console.error(`Item with ID ${id} was not found!`)
      return
    }
    const func = action === ACTION_RESTORE_TO_IMPORT_LIST ? item.restoreToImportList : item.restoreToSyncList
    return func(id).then(() => {
      this.items = this.items.filter(item => item.id !== id)
    })
  }
}

export default ArchiveListPageStore
