import React from 'react'

import PropTypes from 'prop-types'

import { observer } from 'mobx-react'

import { FiltersPanelSection, MultiCheckboxWithRhf, SwitchWithRhf } from 'shared/components/organisms'
import {
  PRODUCT_LIST_FILTERS_INVENTORY_TYPES,
  SYNC_LIST_FILTERS_STATUSES,
} from 'shared/constants/retailerItems'
import { useTranslation } from 'shared/hooks'

import { FiltersPanelAsForm } from 'retailer/components/organisms'

import style from './SyncListFilters.module.scss'

const initialValues = {
  inventoryTypes: [],
  status: [],
  showOutOfStock: true
}

/**
 * `<FiltersPanelAsForm>` wrapper for sync list. Notice the `filters` param which is used to pre-set filters to values
 * that are currently active (i.e. applied).
 */
const SyncListFilters = ({ open, onSubmit, onClose, filters }) => {
  const { t } = useTranslation('syncedProducts')

  return (
    <FiltersPanelAsForm
      initialValues={initialValues}
      currentValues={filters || initialValues}
      onSubmit={onSubmit}
      FiltersPanelProps={{
        open,
        onClose,
      }}
      renderBody={({ handleSubmit, control, getValues }) => {
        return (
          <form onSubmit={handleSubmit(onSubmit)}>
            <FiltersPanelSection title={t('filtersPanelSupplier.label')}>
              <MultiCheckboxWithRhf
                name="inventoryTypes"
                control={control}
                getValues={getValues}
                options={PRODUCT_LIST_FILTERS_INVENTORY_TYPES}
              />
            </FiltersPanelSection>
            <FiltersPanelSection title={t('filtersPanelStatus.label')}>
              <MultiCheckboxWithRhf
                name="status"
                control={control}
                getValues={getValues}
                options={SYNC_LIST_FILTERS_STATUSES}
                labelRenderFunc={label => t(label)}
              />
            </FiltersPanelSection>
            <FiltersPanelSection title={t('filtersPanelInventory.label')}>
              <SwitchWithRhf
                name="showOutOfStock"
                label={t('filtersPanelOutOfStock.toggle')}
                control={control}
              />
            </FiltersPanelSection>
          </form>
        )
      }}
      className={style.SyncListFilters}
    />
  )
}

SyncListFilters.propTypes = {
  open: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  filters: PropTypes.shape({
    inventoryTypes: PropTypes.arrayOf(PropTypes.string).isRequired,
    status: PropTypes.arrayOf(PropTypes.string).isRequired,
    showOutOfStock: PropTypes.bool
  })
}

export default observer(SyncListFilters)
