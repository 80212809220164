import React, { useEffect } from 'react'

import PropTypes from 'prop-types'

import { List, ListItem, ListItemText, ListSubheader, Divider, ButtonGroup, Button } from '@material-ui/core'
import { CloseRounded } from '@material-ui/icons'
import clsx from 'clsx'
import { ChevronLeft, ChevronRight } from 'wix-ui-icons-common'

import style from './HorizontalNestedList.module.scss'

const itemShape = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
}
itemShape.children = PropTypes.arrayOf(PropTypes.shape(itemShape))

const hasChildren = item => item && Array.isArray(item.children) && item.children.length > 0

const HorizontalNestedListColumn = ({
  className, dense, index, parentItem, childItems, onSelect, selected, title,
  parentSelectable, onSelectParent, testId
}) => {
  return (
    <List
      component="div"
      data-testid={testId}
      subheader={title && (
        <ListSubheader component="div" disableGutters className={style.ColumnHeader}>
          {parentSelectable && onSelectParent
            ? (
              <ListItem
                button component="div" onClick={() => onSelectParent(parentItem)}
                data-testid={testId ? `${testId}-selectItemSubheader` : undefined}
              >
                <ListItemText primary={title} />
              </ListItem>
              )
            : <span>{title}</span>}
        </ListSubheader>
      )}
      className={className}
      dense={dense}
    >
      {childItems.map(item => (
        <ListItem
          button
          key={item.id}
          onClick={() => onSelect(item, index)}
          className={clsx(style.Item, selected === item && style.ItemSelected)}
          data-testid={testId ? `${testId}-listItem` : undefined}
        >
          <ListItemText
            primary={item.name} data-testid={testId ? `${testId}-listItemText` : undefined}
            data-test-state={`has-children-${hasChildren(item)}`}
          />
          {hasChildren(item) && <ChevronRight />}
        </ListItem>
      ))}
    </List>
  )
}

HorizontalNestedListColumn.propTypes = {
  index: PropTypes.number.isRequired,
  parentItem: PropTypes.shape(itemShape).isRequired,
  childItems: PropTypes.arrayOf(PropTypes.shape(itemShape)).isRequired,
  onSelect: PropTypes.func.isRequired,
  parentSelectable: PropTypes.bool.isRequired,
  onSelectParent: PropTypes.func,
  selected: PropTypes.shape(itemShape),
  title: PropTypes.string.isRequired,
  className: PropTypes.string,
  dense: PropTypes.bool,
  testId: PropTypes.string,
}

/**
 * Displays hierarchic data as a traversable list of items that expand into side-by-side columns.
 *
 * You can listen on the following callbacks:
 * - onPick - a "pick" happens when the user "clicks" a leaf node (without children)
 *            or confirms a selection of a node with children
 * - onClick - a "click" happens when the user clicks/taps an item, which can result in a "pick"
 *             (for leaf items) or expanding a child menu (for intermediate nodes). Traversing back
 *             will also result in a "click" of the item on the level you're returning to.
 */
const HorizontalNestedList = ({
  data, onClick, onClose, dense, onPick, title, className, columnClassName,
  multicolumn, depth, renderInnerTitle, filterTopItems, allowSelectRoot, testId
}) => {
  const [path, setPath] = React.useState([])

  const lastStep = path.length ? path[path.length - 1] : undefined
  const lastFilledColumn = path.length - (path.length > 0 && !hasChildren(lastStep))

  // clear path when root changes
  useEffect(() => {
    setPath([])
  }, [data])

  const handleOnSelect = (item, index) => {
    setPath([...path.slice(0, index), item])
    onClick && onClick(item)
    onPick && !hasChildren(item) && onPick(item) // pick-on-click only applies to leaves
  }

  const handleBackClick = () => {
    if (path.length > 0) {
      const newPath = path.slice(0, hasChildren(lastStep) ? -1 : -2)
      setPath(newPath)
      onClick && onClick(newPath.length ? newPath[newPath.length - 1] : undefined)
    }
  }

  const handleSelectParent = item => onPick && onPick(item)

  const renderColumn = index => {
    const parentSelectable = index > 0 || allowSelectRoot
    const parentItem = index > 0 ? path[index - 1] : data
    const childItems = index === 0
      ? (filterTopItems ? parentItem.children.filter(filterTopItems) : parentItem.children)
      : hasChildren(parentItem) && parentItem.children
    if (childItems) {
      return (
        <HorizontalNestedListColumn
          index={index}
          parentItem={parentItem}
          childItems={childItems}
          selected={path[index]}
          parentSelectable={parentSelectable}
          title={parentSelectable ? renderInnerTitle(parentItem) : title}
          onSelect={handleOnSelect}
          onSelectParent={handleSelectParent}
          className={columnClassName}
          dense={dense}
          testId={testId ? `${testId}-column-${index}` : undefined}
        />
      )
    }
    return null
  }

  return (
    <div className={clsx(style.HorizontalNestedList, multicolumn && style.isMulticolumn, className)}>
      <div className={style.Columns} style={{ left: `-${lastFilledColumn}00%` }}>
        {Array(depth).fill(undefined).map((_, index) => (
          <div className={style.Column} key={`column-${index}`}>
            {renderColumn(index)}
          </div>
        ))}
      </div>
      <Divider />
      <ListItem className={style.Controls}>
        <ButtonGroup
          variant="outlined"
          size="large"
          className={style.Buttons}
        >
          <Button
            className={style.Button}
            onClick={handleBackClick}
            disabled={!lastStep}
            startIcon={<ChevronLeft />}
          >
            Back
          </Button>
          {onClose && (
            <Button
              className={style.Button}
              onClick={onClose}
              startIcon={<CloseRounded />}
            >
              Close
            </Button>
          )}
        </ButtonGroup>
      </ListItem>
    </div>
  )
}

HorizontalNestedList.propTypes = {
  title: PropTypes.string,
  renderInnerTitle: PropTypes.func,
  data: PropTypes.shape({
    children: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    })),
  }),
  filterTopItems: PropTypes.func,
  dense: PropTypes.bool,
  multicolumn: PropTypes.bool,
  allowSelectRoot: PropTypes.bool,
  depth: PropTypes.number,
  className: PropTypes.string,
  columnClassName: PropTypes.string,
  onClick: PropTypes.func,
  onClose: PropTypes.func,
  onPick: PropTypes.func,
  testId: PropTypes.string,
}

HorizontalNestedList.defaultProps = {
  depth: 4,
  dense: false,
  allowSelectRoot: false,
  renderInnerTitle: item => `All in ${item.name}`,
}

export default HorizontalNestedList
