import React from 'react'

import PropTypes from 'prop-types'

import { Tooltip } from '@material-ui/core'
import clsx from 'clsx'
import { observer } from 'mobx-react'

import { MoneyRange } from 'shared/components/molecules'
import { pricingRangeType } from 'shared/types'

import style from './PricingRangeDisplay.module.scss'

const PricingRangeDisplay = ({ pricing, pricingNameOverride, currencyIso, info, className, MoneyRangeProps, testId }) => {
  if (!pricing || !pricing.active) return null
  return (
    <Tooltip title={info || ''} placement="bottom">
      <div className={clsx(style.PricingRangeDisplay, className)}>
        <span>
          {pricingNameOverride || pricing.name}{' '}
        </span>
        <MoneyRange
          testId={testId}
          amountMin={pricing.from}
          amountMax={pricing.to}
          currency={currencyIso}
          currencyDisplay="code"
          {...MoneyRangeProps}
        />
      </div>
    </Tooltip>
  )
}

PricingRangeDisplay.propTypes = {
  pricing: pricingRangeType.isRequired,
  pricingNameOverride: PropTypes.string,
  currencyIso: PropTypes.string,
  info: PropTypes.node,
  className: PropTypes.string,
  MoneyRangeProps: PropTypes.object,
  testId: PropTypes.string,
}

PricingRangeDisplay.defaultProps = {
  currencyIso: 'USD',
  locked: false,
  info: null,
  className: '',
  MoneyRangeProps: {},
}

export default observer(PricingRangeDisplay)
