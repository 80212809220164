import React from 'react'

import PropTypes from 'prop-types'

import clsx from 'clsx'

import { Logo, Sign } from 'shared/icons'

import style from './ModalystLogo.module.scss'

const ModalystLogo = ({ reverse, width, height, withName }) => {
  const cssClass = clsx(style.Logo, reverse && style.Reversed)
  if (!withName) {
    return <Sign width={width || height} height={height || width} className={cssClass} />
  }
  return <Logo width={height * 260 / 40} height={height} className={cssClass} />
}

ModalystLogo.propTypes = {
  reverse: PropTypes.bool,
  width: PropTypes.number,
  height: PropTypes.number,
  withName: PropTypes.bool
}

ModalystLogo.defaultProps = {
  reverse: false,
  width: 16,
  height: 16,
  withName: false
}

export default ModalystLogo
