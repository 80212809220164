import React from 'react'

import PropTypes from 'prop-types'

import { FormControlLabel, Switch } from '@material-ui/core'
import { observer } from 'mobx-react'
import { Controller } from 'react-hook-form'

import style from './SwitchWithRhf.module.scss'

const SwitchWithRhf = ({ name, label, control }) => {
  return (
    <FormControlLabel
      className={style.SwitchWithRhf}
      label={label}
      control={
        <Controller
          name={name}
          control={control}
          render={({ value, onChange }) => (
            <Switch
              color="primary"
              checked={value}
              onChange={e => onChange(e.target.checked)}
            />
          )}
        />
      }
    />
  )
}

SwitchWithRhf.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  control: PropTypes.object.isRequired
}

export default observer(SwitchWithRhf)
