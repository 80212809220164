import React, { useState } from 'react'

import PropTypes from 'prop-types'

import { IconButton, Portal } from '@material-ui/core'
import { CloseRounded, SearchRounded } from '@material-ui/icons'
import isEmpty from 'lodash/isEmpty'
import { observer } from 'mobx-react'

import { SearchField } from 'shared/components/organisms'
import { useTranslation } from 'shared/hooks'
import { refType } from 'shared/types'

import style from './SearchFilter.module.scss'

/**
 * A reusable controlled Search Filter component. By default it displays a search field, but in compact mode
 * it will only display a search icon button. This button will open the search, which will produce
 * a search field with an additional close button INTO the portal. Provide a `containerRef`
 * to the DOM element where this opened search should be injected.
 *
 * @param {string}    activeValue   The value of the filter.
 * @param {function}  onChange      When the search is submitted, this function will be fed with the input value.
 * @param {function}  onClear       Invoked when an empty input is submitted.
 * @param {Boolean}   compact       Display a compact version of the input?
 * @param {Object}    containerRef  An element into which to render the opened input (only required in compact mode).
 * @returns {JSX.Element}
 * @constructor
 */
const SearchFilter = ({ activeValue, onChange, onClear, compact, containerRef }) => {
  const { t } = useTranslation('common')
  const [isOpen, setIsOpen] = useState(false)

  const handleSearchSubmit = value => {
    if (!isEmpty(value)) {
      onChange(value)
    } else {
      onClear()
    }
  }

  if (!compact) {
    return (
      <SearchField
        buttonPlacement="start"
        color="primary"
        variant="outlined"
        size="small"
        placeholder={t('filtersPanel.search.placeholder')}
        className={style.SearchField}
        onSubmit={handleSearchSubmit}
        defaultValue={activeValue}
      />
    )
  }

  return (
    <>
      <IconButton
        data-testid="openButton"
        onClick={() => setIsOpen(true)}
        color="primary"
        edge="end"
      >
        <SearchRounded />
      </IconButton>
      {isOpen && (
        <Portal container={containerRef.current}>
          <SearchField
            fullWidth
            buttonPlacement="start"
            color="primary"
            variant="outlined"
            size="small"
            placeholder={t('filtersPanel.search.placeholder')}
            className={style.SearchField}
            onSubmit={handleSearchSubmit}
            defaultValue={activeValue}
          />
          <IconButton
            edge="end"
            onClick={() => setIsOpen(false)}
            data-testid="closeButton"
          >
            <CloseRounded />
          </IconButton>
        </Portal>
      )}
    </>
  )
}

SearchFilter.propTypes = {
  activeValue: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onClear: PropTypes.func.isRequired,
  compact: PropTypes.bool,
  containerRef: refType,
}

SearchFilter.defaultProps = {
  compact: false
}

export default observer(SearchFilter)
