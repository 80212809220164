class ShippingCountryStore {
  countryCode
  domestic = false
  shippable
  shippingCost
  shippingAddonCost
  shippingTime

  constructor (data) {
    Object.assign(this, data)
  }
}

export default ShippingCountryStore
