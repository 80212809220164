import React from 'react'

import PropTypes from 'prop-types'

import { MenuItem, Typography } from '@material-ui/core'
import { observer } from 'mobx-react'

import { Alert } from 'shared/components/molecules'
import { importListItemType } from 'shared/types'
import { getFieldErrorMessage } from 'shared/utils/forms'

import { ImageGallery } from 'retailer/components/organisms'
import { getImagesFieldName } from 'retailer/utils/importList'

import style from './ImportListProductMediaTab.module.scss'

const ImportListProductMediaTab = ({ item, errors }) => {
  const selectedCount = item.orderedImages.filter(image => image.selected).length
  const allCount = item.orderedImages.length

  const imagesFieldName = getImagesFieldName()

  return (
    <div className={style.ImportListProductMediaTab}>
      {!!getFieldErrorMessage(errors, imagesFieldName) && (
        <Alert className={style.Alert} severity="error">
          {getFieldErrorMessage(errors, imagesFieldName)}
        </Alert>
      )}
      <div className={style.Gallery}>
        <ImageGallery
          testId="importList-importListProduct-imageGallery"
          images={item.orderedImages}
          onSelect={async (id, checked) => await item.selectImage({ id, selected: !!checked })}
          renderMenu={(id, popupState) => {
            const image = item.images.find(image => image.id === id)
            return (
              <MenuItem
                data-testid="importList-importListProduct-imageGallery-setMainImage"
                disabled={image.isMain}
                onClick={async () => {
                  popupState.close()
                  await item.setMainImage(id)
                }}
              >
                Set as main image
              </MenuItem>
            )
          }}
        />
        <Typography className={style.Counter}>
          {selectedCount}/{Math.min(item.maxImagesLimit, allCount)}
        </Typography>
      </div>
    </div>
  )
}

ImportListProductMediaTab.propTypes = {
  item: importListItemType.isRequired,
  errors: PropTypes.object,
}

export default observer(ImportListProductMediaTab)
