import { changeCurrency, unlockAllPrices } from 'shared/api/retailerPricingRules'

export const ACTION_UNLOCK_PRICES = 'unlockPrices'
export const ACTION_CHANGE_CURRENCY = 'changeCurrency'

export const PRICING_ACTIONS_CONFIG = new Map([
  [ACTION_UNLOCK_PRICES, {
    apiFn: unlockAllPrices,
    title: 'Unlock all prices',
    confirm: [
      'When a product is locked, we do not sync your item\'s pricing with any of the supplier\'s price updates. By "unlocking all prices" you are opting to change your settings so all products will be updated with the supplier\'s price changes.',
      'If you select to unlock the prices of all items, this action cannot be undone.',
      'Are you sure you want to unclock all prices?',
    ],
    done: [
      'Your prices are being unlocked in the background. This may take a while.',
      'You can continue using Modalyst as usual while we process this change.',
    ],
  }],
  [ACTION_CHANGE_CURRENCY, {
    apiFn: changeCurrency,
    title: 'Change currency',
    confirm: [
      "Are you sure you want to change your store's currency?",
      'Modalyst will check which currency is set in your integrated store and the same currency will be applied to your Modalyst account.'
    ],
    done: [
      'Your currency is being changed in the background. This may take a while.',
      'You can continue using Modalyst as usual while we process this change.',
    ],
  }]
])
