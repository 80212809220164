/**
 * Adapt PlanUpgradeModal form data for Recurly. We only want to include `state` if it was actually filled out by user,
 * as providing an nullish or empty value will result in Recurly Error.
 * @param {Object} data
 * @returns {{country: string, address2: (string|*), city: string, address1: (string|*), last_name: string, postal_code: string, first_name: string, [state]: string}}
 */
import { toTitleCase } from 'shared/utils/text'

const adaptFormDataForRecurly = data => {
  return {
    first_name: data.firstName,
    last_name: data.lastName,
    address1: data.streetAddress,
    address2: data.suiteApt,
    city: data.city,
    postal_code: data.zipCode,
    state: data.state,
    country: data.country
  }
}

const recurlyFieldToModalystField = new Map([
  ['first_name', 'firstName'],
  ['last_name', 'lastName'],
  ['address1', 'streetAddress'],
  ['address2', 'suiteApt'],
  ['city', 'city'],
  ['postal_code', 'zipCode'],
  ['state', 'state'],
  ['country', 'country']
])

/**
 * Set React Hook Form errors based on errors received from Recurly.
 * @param {{code: string, details: {field: string, messages: string[]}[], fields: string[], message: string, name: string}} error
 * @param {function} setError
 * @returns {*}
 */
const setRecurlyErrors = (error, setError) => {
  error.details.forEach(({ field, messages }) => {
    const fieldName = recurlyFieldToModalystField.get(field) || field
    setError(fieldName, { type: 'remote', message: toTitleCase(fieldName) + ' ' + messages.join(' ') })
  })
}

export {
  adaptFormDataForRecurly,
  setRecurlyErrors
}
