import React from 'react'

import PropTypes from 'prop-types'

import { Grid } from '@material-ui/core'
import { observer } from 'mobx-react'

import style from './MarketplaceItemDataRow.module.scss'

const MarketplaceItemDataRow = ({ title, alignItems, content, testId }) => {
  const _alignItems = alignItems === 'default' ? undefined : alignItems
  return (
    <Grid container spacing={1} alignItems={_alignItems} className={style.MarketplaceItemDataRow}>
      <Grid item xs={12} lg={4} xl={3} className={style.Title} data-testid={testId ? `${testId}-title` : undefined}>
        {title}
      </Grid>
      <Grid item xs={12} lg={8} xl className={style.Content} data-testid={testId ? `${testId}-content` : undefined}>
        {content}
      </Grid>
    </Grid>
  )
}

MarketplaceItemDataRow.propTypes = {
  title: PropTypes.string.isRequired,
  alignItems: PropTypes.oneOf(['default', 'center']),
  content: PropTypes.node,
  testId: PropTypes.string,
}

MarketplaceItemDataRow.defaultProps = {
  content: null
}

export default observer(MarketplaceItemDataRow)
