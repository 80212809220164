import React from 'react'

import PropTypes from 'prop-types'

import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  useTheme,
  useMediaQuery,
  Typography,
} from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'
import clsx from 'clsx'
import times from 'lodash/times'
import { observer } from 'mobx-react'

import { Alert, TooltipHelper } from 'shared/components/molecules'
import { CursorPagination } from 'shared/components/organisms'
import { useTranslation } from 'shared/hooks'

import { SyncListItem, SelectAllButton } from 'retailer/components/organisms'

import style from './SyncedProductsList.module.scss'

const SyncedProductsList = ({ syncListPageStore }) => {
  const { t } = useTranslation('syncedProducts')
  const theme = useTheme()
  const desktopLayoutBreakpoint = useMediaQuery(theme.breakpoints.up('xl'))
  const noResults = !syncListPageStore.items?.length

  return (
    <div className={style.SyncedProductsList}>
      {!syncListPageStore.error && (
        <TableContainer className={style.Table}>
          <Table>
            <TableHead className={style.TableHeader}>
              <TableRow>
                <TableCell className={style.ProductCol}>
                  <Grid container spacing={1} alignItems="center">
                    <Grid item>
                      <SelectAllButton
                        singleOption={syncListPageStore.pagination.page === 1 && !syncListPageStore.hasNextPage}
                        disabled={!syncListPageStore.items || !syncListPageStore.items.length}
                        selectionActive={syncListPageStore.selectionActive}
                        onSelectAll={() => syncListPageStore.setAllSelected(true)}
                        onSelectAllPages={() => syncListPageStore.setAllOnAllPagesSelected(true)}
                        onUnselectAll={() => syncListPageStore.setAllOnAllPagesSelected(false)}
                        allPagesSelected={syncListPageStore.allOnAllPagesSelected}
                        allOnPageSelected={syncListPageStore.allOnPageSelected}
                        partSelected={syncListPageStore.partSelected}
                        testId="syncList"
                      />
                    </Grid>
                    <Grid item>
                      {t('table.product.label')}
                    </Grid>
                  </Grid>
                </TableCell>
                {desktopLayoutBreakpoint && (
                  <>
                    <TableCell className={style.InventoryCol}>
                      {t('table.inventory.label')}
                    </TableCell>
                    <TableCell className={style.PricingCol}>
                      <div className={style.Center}>
                        {t('table.pricing.label')}
                        <TooltipHelper
                          className={style.Helper}
                          content={t('table.pricing.tooltip')}
                        />
                      </div>
                    </TableCell>
                    <TableCell className={style.ActionsCol}>
                      <div className={clsx(style.Center, style.Actions)}>
                        {t('table.autoPrice.label')}
                        <TooltipHelper
                          className={style.Helper}
                          content={t('table.autoPrice.tooltip')}
                        />
                      </div>
                    </TableCell>
                  </>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {(!syncListPageStore.isLoading && syncListPageStore.items) && syncListPageStore.items.map(item => {
                return (
                  <SyncListItem
                    className={style.ProductRow}
                    key={item.uuid}
                    syncListItemStore={item}
                  />
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      {syncListPageStore.isLoading && (
        <>
          {times(5, (idx) => {
            return (
              <Skeleton key={idx} variant="rect" width="100%" className={style.ListItemSkeleton} />
            )
          })}
        </>
      )}
      {(noResults && !syncListPageStore.isLoading && !syncListPageStore.error) && (
        <Alert title="No results" severity="info" className={style.Alert}>
          There are no store products that match your search
        </Alert>
      )}
      {syncListPageStore.error && (
        <Alert variant="filled" title="Something went wrong" severity="error" className={style.Alert}>
          <Typography paragraph>
            We couldn't fetch the items you requested. Try again or contact the support if the problem persists.
          </Typography>
          <Typography>
            {String(syncListPageStore.error)}
          </Typography>
        </Alert>
      )}
      <CursorPagination
        page={syncListPageStore.pagination.page}
        pageSize={syncListPageStore.pagination.pageSize}
        onPrevious={() => syncListPageStore.previous()}
        onNext={(noResults || !syncListPageStore.hasNextPage) ? null : syncListPageStore.next}
        disabled={syncListPageStore.isLoading || !!syncListPageStore.error}
        className={style.Pagination}
      />
    </div>
  )
}

SyncedProductsList.propTypes = {
  syncListPageStore: PropTypes.shape({
    isLoading: PropTypes.bool,
    items: PropTypes.arrayOf(PropTypes.object),
    pagination: PropTypes.shape({
      page: PropTypes.number.isRequired,
      pageSize: PropTypes.number.isRequired
    }).isRequired,
    previous: PropTypes.func.isRequired,
    next: PropTypes.func.isRequired,
    selectionActive: PropTypes.bool,
    setAllSelected: PropTypes.func.isRequired,
    setAllOnAllPagesSelected: PropTypes.func.isRequired,
    allOnAllPagesSelected: PropTypes.bool,
    allOnPageSelected: PropTypes.bool,
    partSelected: PropTypes.bool,
    error: PropTypes.instanceOf(Error),
    hasNextPage: PropTypes.bool,
  }).isRequired
}

export default observer(SyncedProductsList)
