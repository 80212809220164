import React from 'react'

import PropTypes from 'prop-types'

import { ButtonBase, Radio, FormControlLabel, Typography, Box } from '@material-ui/core'
import { useRadioGroup } from '@material-ui/core/RadioGroup'
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'

import style from './RadioTile.module.scss'

const useRadioStyles = makeStyles({
  root: {
    padding: 0,
  },
})

const RadioTile = React.forwardRef(function RadioTile (props, ref) {
  const {
    className,
    size,
    label,
    disabled,
    checked: checkedProp,
    icon: Icon,
    disableTypography,
    ...labelProps
  } = props

  const radioClasses = useRadioStyles()
  const iconSize = { small: 26, medium: 32, large: 54 }[size]
  const sizeClass = { small: style.Small, medium: style.Medium, large: style.Large }[size]
  const labelVariant = size === 'small' ? 'body2' : 'body1'

  const radioGroup = useRadioGroup()
  let checked = checkedProp

  if (radioGroup) {
    if (typeof checked === 'undefined') {
      checked = radioGroup.value === props.value
    }
  }

  const icon = (
    <Box className={clsx(style.IconWrapper)} fontSize={iconSize}>
      <Icon height={iconSize} width={iconSize} />
    </Box>
  )

  return (
    <ButtonBase component="div" disabled={disabled} className={clsx(style.Root, className)}>
      <FormControlLabel
        {...labelProps}
        className={clsx(style.RadioTile, sizeClass, checked && style.Checked)}
        labelPlacement="bottom"
        label={disableTypography
          ? label
          : <Typography className={style.Label} variant={labelVariant}>{label}</Typography>}
        control={
          <Radio
            ref={ref}
            icon={icon}
            disableRipple
            disabled={disabled}
            checkedIcon={icon}
            className={style.Radio}
            classes={{
              root: radioClasses.root,
            }}
          />
        }
      />
    </ButtonBase>
  )
})

RadioTile.propTypes = {
  ...FormControlLabel.propTypes,
  icon: PropTypes.elementType.isRequired,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  disableTypography: PropTypes.bool,
}

RadioTile.defaultProps = {
  ...FormControlLabel.defaultProps,
  size: 'medium',
  disableTypography: false,
}

export default RadioTile
