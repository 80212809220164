import React from 'react'

import PropTypes from 'prop-types'

import { Tooltip } from '@material-ui/core'
import clsx from 'clsx'
import { observer } from 'mobx-react'

import style from './TextTruncate.module.scss'

/**
 * Show only `maxWords` of given string, and if the string is longer than the limit,
 * append ellipsis at the end.
 * If you also provide `moreButton` param, it will be displayed at the end. Useful for providing Read more
 * functionality of some sort (not a responsibility of this component).
 */
const TextTruncate = ({ text, maxWords, moreButton, component, withTooltip, className, ...rest }) => {
  const wordsArray = text.split(' ')
  const textLength = wordsArray.length

  const DomElement = component

  if (textLength <= maxWords) {
    return (
      <DomElement className={clsx(style.TextTruncate, className)} {...rest}>
        {text}
      </DomElement>
    )
  }

  const truncatedText = wordsArray.slice(0, maxWords).join(' ')
  return (
    <Tooltip title={withTooltip ? text : ''}>
      <DomElement className={clsx(style.TextTruncate, className)} {...rest}>
        {truncatedText + '... '}
        {moreButton}
      </DomElement>
    </Tooltip>
  )
}

TextTruncate.propTypes = {
  text: PropTypes.string,
  maxWords: PropTypes.number,
  moreButton: PropTypes.node,
  component: PropTypes.oneOfType([PropTypes.elementType, PropTypes.string]),
  withTooltip: PropTypes.bool,
  className: PropTypes.string,
}

TextTruncate.defaultProps = {
  text: '',
  maxWords: 10,
  moreButton: null,
  component: 'span',
  withTooltip: false,
}

export default observer(TextTruncate)
