export const SHOW_CONFIRM = 'SHOW_CONFIRM'
export const HIDE_CONFIRM = 'HIDE_CONFIRM'
export const RESET_CONFIRM = 'RESET_CONFIRM'

export const initialState = {
  show: false,
  title: '',
  message: '',
}

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_CONFIRM:
      return {
        show: true,
        title: action.payload.title,
        message: action.payload.message,
        resolve: action.payload.resolve,
        config: action.payload.config,
      }
    case HIDE_CONFIRM: return { ...state, show: false }
    default: return initialState
  }
}
