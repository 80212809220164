import React from 'react'

import PropTypes from 'prop-types'

import { IconButton } from '@material-ui/core'
import { NavigateBeforeRounded, NavigateNextRounded } from '@material-ui/icons'
import clsx from 'clsx'
import { observer } from 'mobx-react'

import style from './CursorPagination.module.scss'

/**
 * A simple cursor pagination, which only displays the current page number
 * and previous/next buttons.
 *
 * You can skip onPrevious and/or onNext functions—this will disable the related button.
 * Alternatively, you can disable the entire pagination by providing `disabled`.
 * @param {Number} page
 * @param {function | undefined} onPrevious
 * @param {function | undefined} onNext
 * @param {Boolean} disabled
 * @param {string} className
 * @returns {JSX.Element}
 * @constructor
 */
const CursorPagination = ({ page, onPrevious, onNext, disabled, className }) => {
  if (page === 1 && !onNext) return null

  return (
    <div className={clsx(style.CursorPagination, className)}>
      <IconButton
        className={style.Button}
        onClick={onPrevious || (() => null)}
        disabled={!onPrevious || disabled || page === 1}
        color="secondary"
      >
        <NavigateBeforeRounded />
      </IconButton>
      <div className={style.Page}>
        {page}
      </div>
      <IconButton
        className={style.Button}
        onClick={onNext || (() => null)}
        disabled={!onNext || disabled}
        color="secondary"
      >
        <NavigateNextRounded />
      </IconButton>
    </div>
  )
}

CursorPagination.propTypes = {
  page: PropTypes.number.isRequired,
  onPrevious: PropTypes.func,
  onNext: PropTypes.func,
  disabled: PropTypes.bool,
  className: PropTypes.string
}

CursorPagination.defaultProps = {
  disabled: false,
  className: ''
}

export default observer(CursorPagination)
