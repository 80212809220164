import { action, observable } from 'mobx'

import {
  adaptOrderItemShippingMethod,
  adaptOrderItemShippingMethodErrors,
  adaptOrderItemShippingMethodReverse
} from 'shared/adapters/orders'
import { getOrderItemShippingMethod, updateOrderItemShippingMethod } from 'shared/api/orders'
import { processApiError } from 'shared/api/utils'

import { Order } from 'retailer/stores'

const pickDefaultShippingMethod = options => {
  return (Array.isArray(options) && options.length) ? options[0].shippingProvider : undefined
}

class OrderItemShippingMethodEditState {
  @observable orderItem
  @observable shippingMethod
  @observable shippingMethodOptions
  @observable errors
  @observable requestInProgress
  @observable updateDone

  constructor (orderItem) {
    if (!(orderItem instanceof Order)) {
      throw new TypeError('Requires instance of Order')
    }
    this.orderItem = orderItem
  }

  @action.bound
  setShippingMethod (value) {
    this.shippingMethod = value
  }

  @action.bound
  setShippingMethodOptions (value) {
    this.shippingMethodOptions = value
  }

  @action.bound
  setRequestInProgress (value) {
    this.requestInProgress = value
  }

  @action.bound
  setUpdateDone (value) {
    this.updateDone = value
  }

  @action.bound
  setErrors (data) {
    this.errors = data
  }

  @action.bound
  fetchShippingMethodData () {
    this.requestInProgress = true
    return getOrderItemShippingMethod(this.orderItem.uuid)
      .then(({ data }) => {
        const { shippingMethod, shippingMethodOptions } = adaptOrderItemShippingMethod(data)
        this.setShippingMethodOptions(shippingMethodOptions)
        this.setShippingMethod(shippingMethod || pickDefaultShippingMethod(shippingMethodOptions))
      })
      .catch(error => {
        this.setErrors(processApiError(error, adaptOrderItemShippingMethodErrors))
      })
      .finally(() => {
        this.setRequestInProgress(false)
      })
  }

  @action.bound
  updateShippingMethod (shippingMethod) {
    this.requestInProgress = true
    return updateOrderItemShippingMethod(this.orderItem.uuid, adaptOrderItemShippingMethodReverse({ shippingMethod }))
      .then(({ data }) => {
        const retailerOrder = this.orderItem.purchaseOrder.retailerOrder
        return retailerOrder ? retailerOrder.refreshFromRemote() : true
      })
      .then(() => this.setUpdateDone(true))
      .catch(error => this.setErrors(processApiError(error, adaptOrderItemShippingMethodErrors)))
      .finally(() => this.setRequestInProgress(false))
  }
}

export default OrderItemShippingMethodEditState
