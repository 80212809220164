import React from 'react'

import PropTypes from 'prop-types'

import { IconButton, Typography } from '@material-ui/core'
import { ChevronLeft, ChevronRight } from '@material-ui/icons'
import clsx from 'clsx'
import { observer } from 'mobx-react'

import style from './SimplePagination.module.scss'

const SimplePagination = ({ offset, count, totalCount, onNext, onPrevious, className }) => {
  const countUnknown = count === undefined || totalCount === undefined
  const prevDisabled = countUnknown || offset === 0
  const nextDisabled = countUnknown || offset + count >= totalCount
  return (
    <div className={clsx(style.SimplePagination, className)}>
      {!!count && (
        <Typography>
          {`${offset + 1}-${offset + count} of ${totalCount}`}
        </Typography>
      )}
      <IconButton onClick={onPrevious} disabled={prevDisabled}><ChevronLeft /></IconButton>
      <IconButton onClick={onNext} disabled={nextDisabled} edge="end"><ChevronRight /></IconButton>
    </div>
  )
}

SimplePagination.propTypes = {
  offset: PropTypes.number.isRequired,
  count: PropTypes.number,
  totalCount: PropTypes.number,
  onNext: PropTypes.func.isRequired,
  onPrevious: PropTypes.func.isRequired,
  className: PropTypes.string,
}

export default observer(SimplePagination)
