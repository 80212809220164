import React, { useState } from 'react'

import PropTypes from 'prop-types'

import { Box, Button, Card, CardMedia, IconButton, Menu, MenuItem, Typography } from '@material-ui/core'
import { MoreHoriz } from '@material-ui/icons'
import { observer } from 'mobx-react'

import { Time } from 'shared/components/atoms'

import { ProductSlateActionsContainer } from 'retailer/components/atoms'

import style from './ProductDraftSlate.module.scss'

/** Dropdown menu with actions and a trigger for the same */
const MoreActions = observer(({ productDraftStore, onMoveToImportList, onOrderSample, onDelete }) => {
  const [moreMenuAnchorEl, setMoreMenuAnchorEl] = useState(null)
  const closeMoreMenu = () => setMoreMenuAnchorEl(null)
  const handleOrderSampleClick = () => {
    onOrderSample(productDraftStore)
    closeMoreMenu()
  }
  const handleMoveToImportListClick = () => {
    onMoveToImportList(productDraftStore)
    closeMoreMenu()
  }
  const handleDeleteClick = () => {
    onDelete(productDraftStore)
    closeMoreMenu()
  }
  return (
    <>
      <IconButton
        color="secondary" size="small" onClick={ev => setMoreMenuAnchorEl(ev.currentTarget)}
        data-testid="ProductDraftSlate-moreMenu"
      >
        <MoreHoriz />
      </IconButton>
      <Menu
        anchorEl={moreMenuAnchorEl} open={!!moreMenuAnchorEl} keepMounted
        onClose={closeMoreMenu}
      >
        <MenuItem button onClick={handleOrderSampleClick} data-testid="ProductDraftSlate-moreMenu-orderASample">
          Order a sample
        </MenuItem>
        <MenuItem button onClick={handleMoveToImportListClick} data-testid="ProductDraftSlate-moreMenu-moveToImportList">
          Move to import list
        </MenuItem>
        <MenuItem
          button onClick={handleDeleteClick} className={style.DeleteMenuItem}
          data-testid="ProductDraftSlate-moreMenu-delete"
        >
          Delete version
        </MenuItem>
      </Menu>
    </>
  )
})

MoreActions.propTypes = {
  productDraftStore: PropTypes.object.isRequired,
  onDelete: PropTypes.func.isRequired,
  onMoveToImportList: PropTypes.func.isRequired,
  onOrderSample: PropTypes.func.isRequired,
}

const ProductDraftSlate = ({ productDraftStore, ...handlers }) => {
  const [hover, setHover] = useState(false)
  const handleMouseEnter = () => setHover(true)
  const handleMouseLeave = () => setHover(false)

  const { mainImage, created, edit } = productDraftStore

  const handleEditClick = async () => {
    await edit()
  }

  return (
    <Card
      className={style.ProductDraftSlate}
      onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}
      variant="outlined"
      data-testid="ProductDraftSlate"
    >
      <CardMedia className={style.CardMedia} image={mainImage?.thumbnail} data-testid="ProductDraftSlate-thumbnail" />
      <Box p={3 / 2}>
        <Typography variant="body2" className={style.Created} data-testid="ProductDraftSlate-createdDate">
          <Time value={created} />
        </Typography>
      </Box>
      <ProductSlateActionsContainer
        show={hover}
        moreActions={
          <MoreActions productDraftStore={productDraftStore} {...handlers} />
        }
        className={style.Actions}
      >
        <Button variant="contained" color="primary" onClick={handleEditClick} data-testid="ProductDraftSlate-editButton">
          Edit draft
        </Button>
      </ProductSlateActionsContainer>
    </Card>
  )
}

ProductDraftSlate.propTypes = {
  productDraftStore: PropTypes.shape({
    edit: PropTypes.func.isRequired,
    created: PropTypes.instanceOf(Date).isRequired,
    mainImage: PropTypes.shape({
      thumbnail: PropTypes.string.isRequired,
    }),
  }).isRequired,
}

export default observer(ProductDraftSlate)
