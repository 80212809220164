import { action, computed, observable, reaction } from 'mobx'

import { adaptRetailerOrder } from 'shared/adapters/orders'
import { defaultPageSize, getOrders } from 'shared/api/orders'
import { LoadableStore } from 'shared/stores'
import { QueryParamsOperator } from 'shared/utils'

import {
  OrdersFiltersStore,
  PaginationStore,
  RetailerOrder,
} from 'retailer/stores'

class OrdersListStore {
  @observable loading = new LoadableStore()
  @observable error = false
  @observable pagination
  @observable orders = []
  @observable paramsOperator = new QueryParamsOperator()
  @observable filters = new OrdersFiltersStore()

  constructor (search = '', pageSize = defaultPageSize) {
    this.pagination = new PaginationStore(1, 0, pageSize)
    this.setQueryFromSearch(search)
    this.load()

    reaction(
      () => this.filters.queryParams,
      (params) => {
        this.resetPagination()
        this.load()
      },
    )

    setInterval(() => {
      this.refresh()
    }, 30000)
  }

  /**
   * Merges filters params with pagination data
   */
  @computed get apiQueryParams () {
    return {
      ...this.filters.queryParams,
      page: this.pagination.currentPage,
      pageSize: this.pagination.pageSize,
    }
  }

  @action.bound
  setQueryFromSearch (search) {
    this.paramsOperator.setQueryFromSearch(search)
    this.changePage(this.paramsOperator.query.page || 1)
  }

  @action.bound
  resetPagination () {
    this.paramsOperator.set('page', 1)
    this.pagination.reset()
  }

  @action.bound
  changePage (page) {
    if (page === this.pagination.currentPage) return
    this.paramsOperator.set('page', page)
    this.pagination.setPage(page)
  }

  @action.bound
  load () {
    if (this.loading.isLoading) return
    this.loading.setLoading()
    getOrders(this.apiQueryParams).then(({ data: { count, results } }) => {
      this.pagination.setTotalCount(count)
      this.orders = results.map(
        order => new RetailerOrder(adaptRetailerOrder(order)),
      )
      this.error = false
    }).catch(() => {
      this.error = true
    }).finally(() => {
      this.loading.setLoaded()
    })
  }

  @action.bound
  refresh () {
    if (this.loading.isLoading) return
    getOrders({ uuids: this.orders.map(order => order.uuid) })
      .then(({ data: { results } }) => {
        results.forEach(result => {
          const order = this.orders.find(
            order => order.uuid === result.uuid,
          )
          if (order) order.update(adaptRetailerOrder(result))
        })
      })
  }

  /**
   * Opens a new tab that will start CSV file download for user.
   */
  exportOrders () {
    const query = new URLSearchParams({
      search: this.filters.searchFilter.activeValue,
      order_status: this.filters.orderStatusFilter.activeValue,
      order_fulfillment: this.filters.orderFulfillmentStatusFilter.activeValue,
      source: this.filters.orderSourceFilter.activeValue
    })
    window.open(
      `/my_dropshipping/orders/export/?${query.toString()}`,
      '_blank'
    )
  }
}

export default OrdersListStore
