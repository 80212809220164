import { action, computed, observable } from 'mobx'

class WarehouseStore {
  @observable shippingCountry
  @observable destinations = []

  // common shipping settings

  @observable leadTimes
  @observable shippingDetails
  @observable businessCountry

  constructor (data = {}) {
    this.update(data)
  }

  @action.bound
  update (data) {
    Object.assign(this, data)
  }

  @computed get name () {
    return this.shippingCountry.name
  }

  @computed get destinationsMapByCountry () {
    return new Map(this.destinations.map(obj => (
      [
        obj.shippingCountry,
        obj
      ]
    )))
  }
}

export default WarehouseStore
