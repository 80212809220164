import React from 'react'

import PropTypes from 'prop-types'

import { Button, Card, CardActions, CardContent, CardHeader, CircularProgress } from '@material-ui/core'
import clsx from 'clsx'
import { observer } from 'mobx-react'

import { submitForm } from 'shared/utils/forms'

import style from './CardForm.module.scss'

/**
 * Displays a form within a card. To use the component, simply import it, and
 * Displays a form within a card. To use the component, simply import it, and
 * add the form somewhere in the children. Then provide <CardForm> the ref to the form, so
 * it can handle the submit properly.
 */
const CardForm = ({
  title,
  subheader,
  submitText,
  submitIcon,
  submitButtonProps,
  discardAvailable,
  onDiscard,
  onSave,
  formRef,
  canSubmit,
  isSubmitting,
  danger,
  className,
  children,
  testId,
  ...props
}) => {
  const onSubmitClick = () => submitForm(formRef)
  return (
    <Card
      className={clsx(style.CardForm, danger && style.danger, className)}
      data-testid={testId}
      {...props}
    >
      <CardHeader
        title={title}
        subheader={subheader}
        className={style.CardHeader}
        data-testid={testId ? `${testId}-cardHeader` : undefined}
      />
      <CardContent className={style.Content}>
        {children}
      </CardContent>
      <CardActions className={style.Actions}>
        {discardAvailable && (
          <Button
            disabled={!onDiscard || isSubmitting}
            variant="outlined"
            size="large"
            color="primary"
            onClick={onDiscard}
            data-testid={testId ? `${testId}-discardButton` : undefined}
          >
            Discard
          </Button>
        )}
        <Button
          disabled={!canSubmit || isSubmitting}
          variant="contained"
          color="primary"
          size="large"
          onClick={onSave || onSubmitClick}
          className={danger ? style.DangerButton : ''}
          startIcon={submitIcon}
          data-testid={testId ? `${testId}-submitButton` : undefined}
          {...submitButtonProps}
        >
          {isSubmitting && <CircularProgress size={16} color="inherit" className={style.ProgressIcon} />} {submitText}
        </Button>
      </CardActions>
    </Card>
  )
}

CardForm.propTypes = {
  title: PropTypes.string.isRequired,
  subheader: PropTypes.string,
  submitText: PropTypes.string,
  submitIcon: PropTypes.node,
  submitButtonProps: PropTypes.object,
  discardAvailable: PropTypes.bool,
  formRef: PropTypes.object,
  canSubmit: PropTypes.bool,
  isSubmitting: PropTypes.bool,
  onDiscard: PropTypes.func,
  onSave: PropTypes.func,
  danger: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.node,
  testId: PropTypes.string,
}

CardForm.defaultProps = {
  submitText: 'Save',
  discardAvailable: true,
  onDiscard: null,
  canSubmit: false,
  isSubmitting: false,
  danger: false,
  className: '',
  children: null,
  submitButtonProps: {},
}

export default observer(CardForm)
