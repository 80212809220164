import { action, computed, observable } from 'mobx'

import {
  getAllRestorableItemIds,
  restoreArchivedRetailerItemToImportList,
  restoreArchivedRetailerItemToSyncList,
} from 'shared/api/archivedRetailerItems'
import { ACTION_RESTORE_TO_SYNC_LIST, ACTION_RESTORE_TO_IMPORT_LIST } from 'shared/constants/archivedRetailerItems'
import { AsyncBatchActionsStore } from 'shared/stores'

class ArchiveListBatchRestoreStore {
  @observable archiveListPageStore
  @observable action
  @observable open = false
  @observable asyncHandlerStore

  constructor (archiveListPageStore) {
    this.archiveListPageStore = archiveListPageStore
    this.asyncHandlerStore = new AsyncBatchActionsStore({
      onError: error => {
        // We ignore errors for now
        console.info(error)
      },
      after: () => {
        this.archiveListPageStore.fetch()
      }
    })
  }

  @computed get selectedCount () {
    if (this.archiveListPageStore.selectedAllOnAllPages) return this.archiveListPageStore.pagination.totalCount
    return this.archiveListPageStore.selectedCount
  }

  /**
   *
   * @returns {Promise<AxiosResponse<[]number>>}
   * @private
   */
  _fetchAllRestorableIds () {
    return getAllRestorableItemIds().then(response => {
      return response.data.ids
    })
  }

  /**
   * @returns {Promise<[]number>}
   * @private
   */
  _getIds () {
    return new Promise((resolve, reject) => {
      if (!this.archiveListPageStore.selectedAllOnAllPages) {
        resolve(
          this.archiveListPageStore.selectedItems.map(item => item.id) // Current page
        )
      }

      return this._fetchAllRestorableIds()
        .then(allIds => resolve(allIds))
        .catch(error => reject(error))
    })
  }

  @action.bound
  startAction (action) {
    this.action = action
    this.open = true

    this._getIds().then(ids => {
      this.asyncHandlerStore.setUp({
        iterable: ids,
        processorFunc: id => {
          if (this.action === ACTION_RESTORE_TO_IMPORT_LIST) return restoreArchivedRetailerItemToImportList(id)
          if (this.action === ACTION_RESTORE_TO_SYNC_LIST) return restoreArchivedRetailerItemToSyncList(id)
          return () => console.error(`Restore action '${this.action}' not implemented!`)
        }
      })
    })
  }

  @action.bound
  run () {
    return this.asyncHandlerStore.run()
  }

  @action.bound
  stop () {
    return this.asyncHandlerStore.stop()
  }

  @action.bound
  dismissModal () {
    this.open = false
    this.asyncHandlerStore.reset()
  }
}

export default ArchiveListBatchRestoreStore
