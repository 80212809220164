import { action, observable } from 'mobx'

import { adaptGetOrderResponse } from 'supplier/adapters/orders'
import { getOrder } from 'supplier/api/orders'
import { OrderStore, FulfillOrderStore } from 'supplier/stores'

class OrderDetailsStore {
  /** @type {import('../root').RootStore} */
  root

  @observable isLoading
  @observable isNotFound

  @observable orderStore
  @observable fulfillOrderStore

  /**
   * @param {import('../root').RootStore} root
   */
  constructor (root, data = {}) {
    this.root = root
    this.update(data)
  }

  @action.bound
  update (data) {
    Object.assign(this, data)
  }

  @action.bound
  reset () {
    this.isLoading = undefined
    this.isNotFound = undefined
    this.orderStore = undefined
  }

  @action.bound
  setIsLoading (value) {
    this.isLoading = value
  }

  @action.bound
  setIsNotFound (value) {
    this.isNotFound = value
  }

  @action.bound
  setOrderStore (obj) {
    this.orderStore = obj
  }

  @action.bound
  fetch (id) {
    this.setIsLoading(true)
    return getOrder(id)
      .then(response => {
        const data = adaptGetOrderResponse(response.data)
        this.setOrderStore(new OrderStore(data))
      })
      .catch(error => {
        this.setIsNotFound(error.response && error.response.status === 404)
      })
      .finally(() => {
        this.setIsLoading(false)
      })
  }

  @action.bound
  beginFulfillOrder () {
    this.fulfillOrderStore = new FulfillOrderStore(this.orderStore)
  }

  @action.bound
  resetFulfillOrder () {
    this.fulfillOrderStore = undefined
  }
}

export default OrderDetailsStore
