import { action, computed, observable } from 'mobx'

import { adaptRetailerNotifications } from 'shared/adapters/notifications'
import { getRetailerNotifications } from 'shared/api/notifications'
import { LoadableStore } from 'shared/stores'
import { logger } from 'shared/utils/debug'

class UniversalNotificationsStore {
  /** @type {import('../context').RootStore} */
  root

  @observable loading = new LoadableStore()

  @observable alibabaExpiration = {
    active: false,
    expirationDate: null,
    isExpired: false
  }

  @observable aliexpressExpiration = {
    active: false,
    expirationDate: null,
    isExpired: false
  }

  @observable limitReached = {
    active: false,
    limit: 0
  }

  @computed get anyNotificationsActive () {
    return this.aliexpressExpiration.active || this.alibabaExpiration.active || this.limitReached.active
  }

  /**
   * @param {import('../context').RootStore} root
   */
  constructor (root) {
    this.root = root
  }

  @action.bound
  fetch (useLoading = true) {
    if (useLoading) this.loading.setLoading()
    return getRetailerNotifications()
      .then(response => {
        Object.assign(this, adaptRetailerNotifications(response.data))
      })
      .catch(error => {
        // a side effect of frequent polling is that it can be used to check
        // if we're still signed in
        if (error.response?.status === 403) this.root.userProfileStore.logout()
        else logger.log(error)
      })
      .finally(() => {
        this.loading.setLoaded()
      })
  }

  @action.bound
  refresh () {
    if (this.loading.isLoading) return
    return this.fetch(false)
  }
}

export default UniversalNotificationsStore
