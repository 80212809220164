/* eslint camelcase: 0 */

import { keysToCamelCase, keysToSnakeCase } from 'shared/utils/api'

import { adaptRetailerProduct } from './retailerItems'

const adaptPostProductDraftRequest = ({ productId, draftRef, ...rest }) => ({
  item_uuid: productId,
  customized_item_draft_ref: draftRef,
  ...keysToSnakeCase(rest),
})

const adaptPostProductDraftResponse = ({ main_image, ...rest }) => ({
  mainImage: main_image && keysToCamelCase(main_image),
  ...keysToCamelCase(rest),
})

const adaptProductDraft = adaptRetailerProduct

const adaptGetProductDraftsParams = ({ productId, ...rest }) => ({
  item__uuid: productId,
  ...keysToSnakeCase(rest)
})

const adaptGetProductDraftsResponse = ({ results, ...meta }) => ({
  results: results.map(row => adaptProductDraft(row)),
  ...keysToCamelCase(meta)
})

export {
  adaptPostProductDraftRequest,
  adaptPostProductDraftResponse,
  adaptGetProductDraftsParams,
  adaptGetProductDraftsResponse,
}
