import React from 'react'

import PropTypes from 'prop-types'

import { Typography, useTheme, useMediaQuery, Box } from '@material-ui/core'
import { observer } from 'mobx-react'

import { TooltipHelper } from 'shared/components/molecules'

import style from './PageHeader.module.scss'

const PageHeader = ({ title, subtitle, testId, ...boxProps }) => {
  const theme = useTheme()
  const mdUp = useMediaQuery(theme.breakpoints.up('md'))

  return (
    <Box {...boxProps}>
      <Typography
        variant="h2" className={style.Title}
        data-testid={testId ? `${testId}-title` : undefined}
      >
        {title}
        {(!mdUp && subtitle) && (
          <TooltipHelper
            className={style.Helper}
            content={subtitle}
            TooltipProps={{
              interactive: true
            }}
          />
        )}
      </Typography>
      {(mdUp && subtitle) && (
        <Typography
          variant="body2" className={style.Subtitle}
          data-testid={testId ? `${testId}-subtitle` : undefined}
        >
          {subtitle}
        </Typography>
      )}
    </Box>
  )
}

PageHeader.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  testId: PropTypes.string,
}

export default observer(PageHeader)
