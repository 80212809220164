import React, { useState } from 'react'

import PropTypes from 'prop-types'

import { IconButton } from '@material-ui/core'
import { Close } from '@material-ui/icons'
import { observer } from 'mobx-react'

import style from './TopContentBanner.module.scss'

const CloseButton = ({ onClick, ...rest }) => {
  return (
    <IconButton
      color="primary"
      onClick={onClick}
      className={style.CloseButton}
      {...rest}
    >
      <Close />
    </IconButton>
  )
}

CloseButton.propTypes = {
  onClick: PropTypes.func
}

CloseButton.defaultProps = {
  onClick: undefined
}

const TopContentBanner = ({ children }) => {
  const [visible, setVisible] = useState(true)

  if (!visible) { return null }

  return (
    <div
      className={style.TopBanner}
    >
      <span className={style.Content}>
        {children}
      </span>
      <CloseButton onClick={() => setVisible(false)} />
    </div>
  )
}

TopContentBanner.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
}

export default observer(TopContentBanner)
