import React, { forwardRef } from 'react'

import PropTypes from 'prop-types'

import { Box, CircularProgress, Typography } from '@material-ui/core'
import { observer } from 'mobx-react'

import style from './BottomLoader.module.scss'

const BottomLoader = forwardRef(({ active, noResultsText }, ref) => {
  const main = active
    ? (
      <CircularProgress size="3rem" />)
    : noResultsText
      ? (
        <Typography variant="body1">{noResultsText}</Typography>
        )
      : null
  return (
    <Box className={style.BottomLoader} ref={ref}>
      {main}
    </Box>
  )
})

BottomLoader.propTypes = {
  active: PropTypes.bool,
  noResultsText: PropTypes.string,
}

BottomLoader.defaultProps = {
  active: false,
  noResultsText: null,
}

export default observer(BottomLoader)
