import React from 'react'

import { withStyles } from '@material-ui/core/styles'
import { SnackbarProvider as BaseSnackbarProvider } from 'notistack'

const getSnackbarVariantStyles = (theme, variant) => ({
  '& .MuiButton-outlined:not(.MuiButton-outlinedPrimary):not(.MuiButton-outlinedSecondary)': {
    color: theme.palette[variant].contrastText,
    borderColor: theme.palette[variant].contrastText,
  },
})

const snackbarStyles = theme => {
  return {
    variantSuccess: getSnackbarVariantStyles(theme, 'success'),
    variantInfo: getSnackbarVariantStyles(theme, 'info'),
    variantError: getSnackbarVariantStyles(theme, 'error'),
    variantWarning: getSnackbarVariantStyles(theme, 'warning'),
  }
}

const SnackbarProvider = props => <BaseSnackbarProvider {...props} />

export default withStyles(snackbarStyles)(SnackbarProvider)
