import React from 'react'

import PropTypes from 'prop-types'

import { Grid, Typography } from '@material-ui/core'
import { observer } from 'mobx-react'

import { ExternalSourceLink } from 'shared/components/atoms'
import { useTranslation } from 'shared/hooks'
import { getIntegrationIcon } from 'shared/utils/integrations'

import { InventoryTypeBadge } from 'retailer/components/atoms'
import { ItemStore } from 'retailer/stores'

import style from './MarketplaceItemHeader.module.scss'

const MarketplaceItemHeader = ({ item, testId }) => {
  const { t } = useTranslation('productPage')

  const ExternalIcon = getIntegrationIcon(item.inventoryType)

  return (
    <Grid container justifyContent="space-between" spacing={2} className={style.MarketplaceItemHeader}>
      <Grid item xs={12}>
        <InventoryTypeBadge
          id={item.inventoryType} className={style.InventoryType}
          testId={testId ? `${testId}-inventoryType` : undefined}
        />
      </Grid>
      <Grid item xs={12} className={style.Title}>
        <Typography variant="h4" component="h2" data-testid={testId ? `${testId}-itemName` : undefined}>
          {item.name}
        </Typography>
        {item.is3rdPartySupplier && (
          <ExternalSourceLink
            url={item.externalUrl}
            icon={<ExternalIcon height={16} />}
            title={t('header.externalSourceLink.title')}
            className={style.ExternalLink}
          />
        )}
      </Grid>
      {!item.isCustomizable && (
        <Grid
          item xs={12} md="auto" className={style.BrandName}
          data-testid={testId ? `${testId}-brandName` : undefined}
        >
          {item.brand.name}
        </Grid>
      )}
      <Grid
        item xs={12} md="auto" className={style.SKU}
        data-testid={testId ? `${testId}-itemSku` : undefined}
      >
        {t('header.sku.label')} {item.sku}
      </Grid>
    </Grid>
  )
}

MarketplaceItemHeader.propTypes = {
  item: PropTypes.instanceOf(ItemStore).isRequired,
  testId: PropTypes.string,
}

export default observer(MarketplaceItemHeader)
