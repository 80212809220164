import { action, observable } from 'mobx'

import { adaptRetailerSubscription } from 'shared/adapters/retailer'
import { getRetailerSubscription } from 'shared/api/retailer'
import { LoadableStore } from 'shared/stores'

import { SubscriptionPlanStore } from 'retailer/stores'

class RetailerSubscription {
  @observable loading = new LoadableStore()
  @observable validFrom
  @observable validTo
  @observable planId
  @observable planName = ''
  @observable autoRenewalCancelled = false
  @observable isActive = true
  @observable productsLeft = 0
  @observable isLimitless = false
  @observable warning = false

  @observable plan

  @action.bound
  fetch () {
    this.loading.setLoading()
    return getRetailerSubscription()
      .then(response => {
        const { plan, ...rest } = adaptRetailerSubscription(response.data)
        Object.assign(this, rest)
        this.plan = new SubscriptionPlanStore(plan)
        this.loading.setLoaded()
      }).catch(() => {
        this.loading.unsetLoading()
      })
  }

  @action.bound
  refresh () {
    return getRetailerSubscription()
      .then(response => {
        const { plan, ...rest } = adaptRetailerSubscription(response.data)
        Object.assign(this, rest)
        this.plan = new SubscriptionPlanStore(plan)
      })
  }
}

export default RetailerSubscription
