import pick from 'lodash/pick'
import { action, computed, observable } from 'mobx'

import { adaptGetPricingRulesResponse, adaptPostPricingRulesRequest } from 'shared/adapters/retailerPricingRules'
import { getPricingRules, postPricingRules } from 'shared/api/retailerPricingRules'
import { SHOPIFY } from 'shared/constants/integrations'

import { PricingActionStore, SetPrimaryShippingCountryStore } from 'retailer/stores'

import {
  INVENTORY_TYPES_CONFIG,
  INVENTORY_TYPE_TABS,
  MARKUP_COMPARE_AT_PRICE_LOWER_THAN_PRICE_ERROR,
  MARKUP_INVALID_ERROR,
  MARKUP_MULT_BY_ZERO_ERROR,
} from './constants'

class PricingRule {
  @observable markupType
  @observable markup

  constructor (data = {}) {
    this.markupType = data.markupType || 'added'
    this.markup = data.markup || '0.00'
  }

  /** Payload data */
  @computed get data () {
    return this.markupType === 'disabled' ? undefined : pick(this, ['markupType', 'markup'])
  }

  @computed get error () {
    const markup = parseFloat(this.markup)
    if (this.markupType === 'disabled') return undefined
    if (isNaN(markup) || markup < 0) return MARKUP_INVALID_ERROR
    if (this.markupType === 'multiplied' && markup === 0) return MARKUP_MULT_BY_ZERO_ERROR
  }

  @action.bound setMarkup (value) {
    this.markup = value
  }

  @action.bound setMarkupType (value) {
    this.markupType = value
  }
}

class InventoryTypeRules {
  /** @type {import('../context').RootStore} */
  root

  key
  originalPriceEnding
  @observable priceRule
  @observable compareAtPriceRule
  @observable allowCompareAtPrice = true
  @observable includeShippingRateInPrice = false
  @observable fixedShippingRate = null
  @observable freeItemPrice = '1.00'
  @observable changePriceEnding = false
  @observable priceEnding = '99'

  /**
  * @param {import('../context').RootStore} root
  */
  constructor (root, key, data = {}) {
    this.root = root
    this.key = key
    this.update(data)
  }

  @computed get compareAtPriceAvailable () {
    const platform = this.root.userProfileStore.currentStore?.platform
    return [SHOPIFY, undefined].includes(platform)
  }

  /** Payload data */
  @computed get data () {
    return {
      inventoryType: this.key,
      price: this.priceRule.data,
      compareAtPrice: this.compareAtPriceAvailable ? this.compareAtPriceRule.data : undefined,
      includeShippingRateInPrice: this.includeShippingRateInPrice,
      fixedShippingRate: this.fixedShippingRate || null,
      freeItemPrice: this.freeItemPrice,
      changePriceEnding: this.changePriceEnding,
      priceEnding: this.priceEnding.length === 2 ? this.priceEnding : this.originalPriceEnding,
    }
  }

  @computed get errors () {
    const { compareAtPriceAvailable, compareAtPriceRule, priceRule } = this
    return ({
      compareAtPrice: compareAtPriceAvailable && compareAtPriceRule.error,
      price: priceRule.error,
      compareAtPriceLowerThanPrice: (
        compareAtPriceAvailable && compareAtPriceRule.markupType !== 'disabled' &&
        compareAtPriceRule.markupType === priceRule.markupType &&
        parseFloat(compareAtPriceRule.markup) < parseFloat(priceRule.markup) &&
        MARKUP_COMPARE_AT_PRICE_LOWER_THAN_PRICE_ERROR
      ) || undefined,
      priceEnding: this.changePriceEnding && this.priceEnding.length !== 2 ? 'Please provide a valid value' : undefined,
    })
  }

  @computed get isValid () {
    return [...Object.values(this.errors)].every(error => !error)
  }

  @computed get isCompareAtPricePotentiallyLower () {
    return (
      this.compareAtPriceAvailable &&
      this.compareAtPriceRule.markupType !== 'disabled' &&
      this.compareAtPriceRule.markupType !== this.priceRule.markupType
    )
  }

  @action.bound update ({ price, compareAtPrice, priceEnding, ...rest }) {
    this.priceRule = new PricingRule(price)

    const compareAtPriceDisabled = compareAtPrice === null || !this.compareAtPriceAvailable
    this.compareAtPriceRule = new PricingRule(
      compareAtPriceDisabled ? { markupType: 'disabled' } : compareAtPrice
    )

    this.originalPriceEnding = priceEnding
    this.priceEnding = priceEnding

    Object.assign(this, rest)
  }

  @action.bound setIncludeShippingRateInPrice (value) {
    this.includeShippingRateInPrice = value
  }

  @action.bound setFixedShippingRate (value) {
    this.fixedShippingRate = value
  }

  @action.bound setChangePriceEnding (value) {
    this.changePriceEnding = value
    if (!this.changePriceEnding && this.priceEnding.length !== 2) {
      this.priceEnding = ''
    }
  }

  @action.bound setPriceEnding (value) {
    this.priceEnding = value
  }
}

class PricingRulesStore {
  /** @type {import('../root').RootStore} */
  root

  @observable inventoryType = INVENTORY_TYPE_TABS[0]
  @observable pricingRules

  @observable setPrimaryShippingCountryStore
  @observable pricingActionStore

  @observable isLoaded = false
  @observable isSaving
  @observable isDone

  /**
   * @param {import('../context').RootStore} root
   */
  constructor (root) {
    this.root = root
    this.pricingRules = new Map(INVENTORY_TYPE_TABS.map(key => (
      [key, new InventoryTypeRules(root, key)]
    )))
  }

  @action.bound setInventoryType (value) {
    this.inventoryType = value
  }

  @computed get isValid () {
    return [...this.pricingRules.values()].every(pr => pr.isValid)
  }

  @computed get inventoryTypeMessages () {
    return INVENTORY_TYPES_CONFIG.get(this.inventoryType)
  }

  @computed get inventoryTypeTabs () {
    return [...INVENTORY_TYPES_CONFIG.entries()].map(([key, { name }]) => (
      { key, name }
    ))
  }

  @computed get currentInventoryTypePricingRules () {
    return this.pricingRules.get(this.inventoryType)
  }

  @action.bound startSetPrimaryShippingCountry () {
    this.setPrimaryShippingCountryStore = new SetPrimaryShippingCountryStore(this.root)
  }

  @action.bound resetSetPrimaryShippingCountry () {
    this.setPrimaryShippingCountryStore = undefined
  }

  @action.bound startPricingAction (actionCode) {
    this.pricingActionStore = new PricingActionStore(this.root, actionCode)
  }

  @action.bound resetPricingAction (actionCode) {
    this.pricingActionStore = undefined
  }

  @action.bound async fetch () {
    this.isLoaded = false
    try {
      const response = await getPricingRules()
      const rules = adaptGetPricingRulesResponse(response.data).results
      rules.forEach(data => {
        const inventoryTypePricingRules = this.pricingRules.get(data.inventoryType)
        if (!inventoryTypePricingRules) return
        inventoryTypePricingRules.update(data)
      })
      this.isLoaded = true
    } catch (e) {
      // TODO: set error state
    }
  }

  @action.bound async save () {
    this.isError = undefined
    this.isDone = false
    this.isSaving = true
    try {
      const payload = adaptPostPricingRulesRequest(this.currentInventoryTypePricingRules.data)
      await postPricingRules(payload)
      this.isDone = true
      this.isSaving = false
    } catch (error) {
      this.isSaving = false
      throw error
    }
  }

  @computed get biData () {
    const rules = this.currentInventoryTypePricingRules
    return {
      origin: 'modalyst',
      type: this.inventoryType,
      markupType: rules.priceRule.markupType,
      markupLong: rules.priceRule.markup
        ? parseFloat(rules.priceRule.markup)
        : undefined,
      isShippingIncluded: rules.includeShippingRateInPrice,
      shippingRateLong: rules.fixedShippingRate
        ? parseFloat(rules.fixedShippingRate)
        : undefined,
      isPriceChanged: rules.changePriceEnding,
      priceEnding: rules.changePriceEnding
        ? parseInt(rules.priceEnding, 10)
        : undefined,
      retailerStorePlatform: this.root.userProfileStore.currentStore?.platform,
    }
  }
}

export default PricingRulesStore
