import { computed, observable } from 'mobx'

import ProductImageStore from 'supplier/stores/ProductImageStore'
import ProductOptionStore from 'supplier/stores/ProductOptionStore'
import ProductVariantStore from 'supplier/stores/ProductVariantStore'

class BaseProductStore {
  @observable quantity
  @observable categoryTags = []
  @observable mainImage
  @observable originalCurrency
  @observable unitPrice
  @observable unitPriceOriginal
  @observable msrpOriginal
  @observable images = []
  @observable.shallow options = []
  @observable.shallow _variants = [] // raw variants data
  @observable variants = []
  @observable active

  constructor (data = {}) {
    const { variants, images, options, ...rest } = data
    this.images = images && images.map(imageData => new ProductImageStore(imageData))
    this.options = options && options.map(optionData => new ProductOptionStore(optionData))
    this._variants = variants || []
    this.variants = this._variants.map(variantData => new ProductVariantStore(this, variantData))
    Object.assign(this, rest)
  }

  @computed get activeVariants () {
    return this.variants.filter(v => v.active)
  }

  _getVariantPriceMin (property) {
    if (!(this.activeVariants && this.activeVariants.length)) return undefined
    return this.activeVariants.reduce((_min, v) => {
      const price = Number.parseFloat(v[property])
      return _min < price ? _min : price
    }, Number.POSITIVE_INFINITY).toFixed(2)
  }

  _getVariantPriceMax (property) {
    if (!(this.activeVariants && this.activeVariants.length)) return undefined
    return this.activeVariants.reduce((_max, v) => {
      const price = Number.parseFloat(v[property])
      return _max > price ? _max : price
    }, Number.NEGATIVE_INFINITY).toFixed(2)
  }

  @computed get unitPriceMin () {
    return this._getVariantPriceMin('unitPrice')
  }

  @computed get unitPriceMax () {
    return this._getVariantPriceMax('unitPrice')
  }

  @computed get unitPriceOriginalMin () {
    return this._getVariantPriceMin('unitPriceOriginal')
  }

  @computed get unitPriceOriginalMax () {
    return this._getVariantPriceMax('unitPriceOriginal')
  }

  @computed get msrpOriginalMin () {
    return this._getVariantPriceMin('msrpOriginal')
  }

  @computed get msrpOriginalMax () {
    return this._getVariantPriceMax('msrpOriginal')
  }

  @computed get hasActiveVariants () {
    return !!this.activeVariants.length
  }

  @computed get allVariantsHaveSkus () {
    return this.variants.every(variant => !!variant.sku)
  }

  @computed get variantsErrorMessage () {
    return [
      !this.hasActiveVariants && 'Ensure product has at least one active variant.',
      !this.allVariantsHaveSkus && 'Ensure all products have SKUs assigned.',
    ].filter(msg => !!msg).join(' ')
  }
}

export default BaseProductStore
