import { api } from './common'

const baseUrl = '/api/v2/retailer/pricing-rules/'

const getPricingRules = () => {
  return api.get(baseUrl)
}

const postPricingRules = data => {
  return api.post(baseUrl, data)
}

const unlockAllPrices = () => {
  return api.post(baseUrl + 'unlock_all_prices/')
}

const changeCurrency = () => {
  return api.post(baseUrl + 'change_currency/')
}

const setShippingCountry = data => {
  return api.post('/api/v3/countries/set_current/', data)
}

export {
  getPricingRules,
  postPricingRules,
  unlockAllPrices,
  changeCurrency,
  setShippingCountry,
}
