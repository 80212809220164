import React from 'react'

import PropTypes from 'prop-types'

import { Box, Button, Typography } from '@material-ui/core'
import { CheckRounded } from '@material-ui/icons'
import { observer } from 'mobx-react'

import { DataModal } from 'shared/components/organisms'

import style from './SubscriptionPaymentSuccessModal.module.scss'

const SubscriptionPaymentSuccessModal = ({ onExited }) => {
  return (
    <DataModal
      className={style.SubscriptionPaymentSuccessModal}
      title="Thank you for your payment"
      maxWidth="md"
      fullWidth
      onExited={onExited}
      renderBody={() => {
        return (
          <Box mt={2}>
            <Typography paragraph>
              Your payment was accepted and your subscription will be activated. Please note that it might
              take up to a minute or two for the subscription to be active.
            </Typography>
            <Typography paragraph>
              For a receipt, please check your e-mail.
            </Typography>
          </Box>
        )
      }}
      renderActions={({ dismissModal }) => {
        return (
          <>
            <Button onClick={dismissModal} variant="contained" color="primary" startIcon={<CheckRounded />}>
              I understand
            </Button>
          </>
        )
      }}
    />
  )
}

SubscriptionPaymentSuccessModal.propTypes = {
  onExited: PropTypes.func
}

export default observer(SubscriptionPaymentSuccessModal)
