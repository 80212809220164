import React, { useEffect, useState } from 'react'

import PropTypes from 'prop-types'

import clsx from 'clsx'
import { observer } from 'mobx-react'
import { useForm } from 'react-hook-form'

import { filtersPanelType } from 'shared/types'
import { calculateNumberOfActiveValues } from 'shared/utils/forms/common'

import { FiltersPanel } from 'retailer/components/organisms'

import style from './FiltersPanelAsForm.module.scss'

/**
 * A wrapper over `<FiltersPanel>` component which handles common form-related logic,
 * such as setting up React Hook Form, counting applied filters, etc.
 *
 * Notice that the `renderBody` function receives the entire RHF object returned from `useForm` hook.
 * Thanks to that it's possible to use form logic in components that include `<FiltersPanelAsForm>`.
 * More: https://react-hook-form.com/api/useform
 *
 * @param {Object}    initialValues       The initial/default state of the form. This object have to be provided
 *                                        for the reset function to work properly.
 * @param {Object}    currentValues       Currently active values, which will be fed as defaultValues for the form.
 * @param {function}  onSubmit            Func invoked when the form is submitted, with the form data object.
 * @param {function}  renderBody          Function to render the content of the form-panel.
 * @param {Object}    FiltersPanelProps   Props to parametrize `<FiltersPanel>`
 * @param {string}    className           Optional class name to pass to the panel.
 * @returns {JSX.Element}
 * @constructor
 */
const FiltersPanelAsForm = ({
  initialValues,
  currentValues,
  onSubmit,
  renderBody,
  FiltersPanelProps,
  className
}) => {
  const [filtersCount, setFiltersCount] = useState(0)
  const useFormObject = useForm({
    mode: 'all',
    defaultValues: currentValues || initialValues
  })
  const { reset, handleSubmit, watch } = useFormObject

  /**
   * Reset form each time it is opened, to ensure it always reflects the most up to date values.
   */
  useEffect(() => {
    if (FiltersPanelProps.open) {
      reset(currentValues || initialValues, { isDirty: true, dirtyFields: true })
    }
  }, [FiltersPanelProps.open])

  /**
   * Get all fields from watch(), filter out these which have not been dirtied, and then calculate
   * how many values the user has selected, i.e. how many active filters there are.
   */
  useEffect(() => {
    setFiltersCount(calculateNumberOfActiveValues(watch()))
    return () => setFiltersCount(0)
  }, [watch])

  const handleSave = async () => {
    await handleSubmit(onSubmit)()
    FiltersPanelProps.onClose()
  }

  const handleClear = () => {
    reset(initialValues, { isDirty: true, dirtyFields: true })
  }

  return (
    <FiltersPanel
      className={clsx(style.FiltersPanelAsForm, className)}
      open={FiltersPanelProps.open}
      filtersCount={filtersCount}
      onClear={filtersCount ? handleClear : null}
      onSave={handleSave}
      {...FiltersPanelProps}
    >
      {renderBody(useFormObject)}
    </FiltersPanel>
  )
}

FiltersPanelAsForm.propTypes = {
  initialValues: PropTypes.object.isRequired,
  currentValues: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
  FiltersPanelProps: filtersPanelType.isRequired,
  renderBody: PropTypes.func,
  className: PropTypes.string
}

FiltersPanelAsForm.defaultProps = {
  renderBody: () => null
}

export default observer(FiltersPanelAsForm)
