import React from 'react'

import PropTypes from 'prop-types'

import style from './List.module.scss'

const List = ({ inline, className, children, ...props }) => {
  if (!children) return null

  if (!Array.isArray(children)) {
    return (
      <ul className={`${style.List} ${className}`} {...props}>
        <li className={`${style.Element} ${inline && style.inline}`}>
          {children}
        </li>
      </ul>
    )
  }

  return (
    <ul className={`${style.List} ${className}`} {...props}>
      {children.map((child, index) => {
        return (
          <li
            className={`${style.Element} ${inline && style.inline}`}
            key={index}
          >
            {child}
          </li>
        )
      })}
    </ul>
  )
}

List.propTypes = {
  inline: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
}

List.defaultProps = {
  inline: false,
  className: '',
}

export default List
