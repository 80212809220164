import React, { useEffect } from 'react'

import { Box, Button } from '@material-ui/core'
import { addNewStoreClicked } from '@wix/bi-logger-modalyst/v2'
import { observer } from 'mobx-react'
import { useSnackbar } from 'notistack'
import { Helmet } from 'react-helmet'
import { Route, useLocation } from 'react-router-dom'

import { useBiEvents } from 'shared/bi/hooks'
import { Redirect, ScrollResetter } from 'shared/components/atoms'
import { UUID_REGEX_STRING } from 'shared/constants'
import { useTranslation } from 'shared/hooks'

import { BiLink } from 'retailer/components/atoms'
import { PageHeader } from 'retailer/components/molecules'
import {
  MyStoreCard,
  VirtualizedGrid,
  ConnectStoreModal,
  CreateStoreModal,
} from 'retailer/components/organisms'
import { AppBarOnlyTemplate } from 'retailer/components/templates'
import { useStores } from 'retailer/stores/hooks'

const BI_ORIGIN = 'my stores'

const MyStoresContent = observer(() => {
  const { logPageLoaded } = useBiEvents({ correlateWithLocation: true })
  const location = useLocation()

  const { t } = useTranslation('myStores')
  const { enqueueSnackbar } = useSnackbar()

  const {
    myStoresStore,
    userProfileStore,
    connectStoreStore,
    routerStore,
  } = useStores()
  const {
    stores, createStoreStore,
    fetch, reset, handleCreateStoreSubmit, deleteStore,
  } = myStoresStore

  useEffect(() => {
    logPageLoaded({ pageName: 'my stores' })
    fetch()
    return () => reset()
  }, [])

  const handleConnectStoreModalOnExited = () => {
    connectStoreStore.reset()
    routerStore.replace('/my-stores')
    myStoresStore.refresh()
  }

  const handleCreateStoreModalOnExited = () => {
    const { designatedPlatform, createdStore } = createStoreStore

    if (createdStore) {
      enqueueSnackbar(t('addStoreModal.storeCreatedSnackbar.message'), { variant: 'success' })
    }

    const to = createdStore && designatedPlatform !== 'other'
      ? `/my-stores/${createdStore.uuid}/connect/${designatedPlatform}`
      : '/my-stores'
    routerStore.replace(to, location.state)

    createStoreStore.reset()
  }

  return (
    <>
      <Box
        display="flex" alignItems="center" justifyContent="space-between"
        mt={3} mb={4}
      >
        <PageHeader
          title={t('main.title')}
          subtitle={t('main.subtitle')}
        />
        <Button
          variant="contained" color="primary"
          component={BiLink} to="/my-stores/new"
          biData={() => addNewStoreClicked({ origin: BI_ORIGIN })}
          data-testid="MyStoresPage-addNewStoreButton"
        >
          {t('newStore.cta')}
        </Button>
      </Box>
      <VirtualizedGrid
        items={stores} itemMinWidth={300}
        renderItemContent={store => <MyStoreCard store={store} onDelete={deleteStore} />}
      />
      <Route
        path="/my-stores/new"
        render={props => (
          <CreateStoreModal
            {...props}
            createStoreStore={createStoreStore}
            onFormSubmit={handleCreateStoreSubmit}
            onExited={handleCreateStoreModalOnExited}
          />
        )}
      />
      <Route
        path="/my-stores/connect"
        render={({ location }) => {
          const params = new URLSearchParams(location.search)
          const businessId = connectStoreStore.getBusinessIdFromState(params.get('state'))
          return (
            <Redirect
              location={location}
              pathname={`/my-stores/${businessId}/connect`}
              keepSearch
            />
          )
        }}
      />
      <Route
        path={`/my-stores/:businessId(${UUID_REGEX_STRING})/connect/:platform(wix|shopify)?`}
        render={props => (
          <ConnectStoreModal
            {...props}
            userProfileStore={userProfileStore}
            connectStoreStore={connectStoreStore}
            onExited={handleConnectStoreModalOnExited}
          />
        )}
      />
    </>
  )
})

const MyStoresPage = () => {
  const { t } = useTranslation('myStores')

  return (
    <>
      <Helmet><title>{t('main.title')}</title></Helmet>
      <ScrollResetter />
      <AppBarOnlyTemplate
        main={<MyStoresContent />}
        ContainerProps={{ maxWidth: 'xl' }}
        disableStoreSelector
      />
    </>
  )
}

export default observer(MyStoresPage)
