import React, { useRef, useLayoutEffect, useState, useCallback } from 'react'

import PropTypes from 'prop-types'

import { Grid, useTheme } from '@material-ui/core'
import { observer, Observer } from 'mobx-react'
import ReactList from 'react-list'

const renderItems = (items, ref, gridProps) => {
  return (
    <Observer>
      {() => (
        <Grid container ref={ref} {...gridProps}>
          {items}
        </Grid>
      )}
    </Observer>
  )
}

const renderItem = (item, renderItemContent, gridItemProps) => {
  return (
    <Observer key={item.id}>
      {() => (
        <Grid item {...gridItemProps}>
          {renderItemContent(item)}
        </Grid>
      )}
    </Observer>
  )
}

const VirtualizedGrid = ({ items, renderItemContent, itemMinWidth, spacing }) => {
  const ref = useRef(null)
  const [gridItemStyle, setGridItemStyle] = useState()
  const theme = useTheme()

  const calculateGridItemProps = useCallback(() => {
    const gutter = theme.spacing(spacing)
    const availableWidth = ref.current?.items?.offsetWidth
    const width = `${100 / Math.floor(availableWidth / (itemMinWidth + gutter))}%`
    setGridItemStyle({ width, flexBasis: width, flexGrow: 0 })
  }, [ref.current?.items, itemMinWidth, spacing])

  useLayoutEffect(() => {
    calculateGridItemProps()
    window.addEventListener('resize', calculateGridItemProps)
    return () => window.removeEventListener('resize', calculateGridItemProps)
  }, [ref.current?.items])

  return (
    <ReactList
      ref={ref}
      itemRenderer={i => renderItem(items[i], renderItemContent, { style: gridItemStyle })}
      itemsRenderer={(items, ref) => renderItems(items, ref, { spacing })}
      length={items.length}
      type="uniform"
      threshold={2500}
      useTranslate3d
    />
  )
}

VirtualizedGrid.propTypes = {
  spacing: PropTypes.number,
  itemMinWidth: PropTypes.number.isRequired,
  renderItemContent: PropTypes.func.isRequired,
  items: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.any,
  })),
}

VirtualizedGrid.defaultProps = {
  spacing: 2,
  itemMinWidth: 250,
  items: []
}

export default observer(VirtualizedGrid)
