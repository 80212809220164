import PropTypes from 'prop-types'

import { observer } from 'mobx-react'

import { integrationIcons, integrationsNames } from 'shared/constants/integrations'

const SHOPIFY_STORE_URL_REGEX = /^((http|https):\/\/)?([A-Za-z0-9-]+)((\.myshopify.com)|$)/i

const extractShopifyStoreId = value => {
  const match = SHOPIFY_STORE_URL_REGEX.exec(value)
  if (!match || !match[3]) {
    throw new Error('Invalid value')
  }
  return match[3]
}

const getIntegrationName = key => {
  return integrationsNames.get(key) || key
}

const getIntegrationIcon = key => {
  return integrationIcons.get(key) || null
}

const ForIntegration = observer(({ integration, allowedIntegrations, children }) => {
  if (
    (typeof allowedIntegrations === 'string' && integration !== allowedIntegrations) ||
    (Array.isArray(allowedIntegrations) && !allowedIntegrations.includes(integration))
  ) {
    return null
  }
  return children
})

ForIntegration.propTypes = {
  integration: PropTypes.string,
  allowedIntegrations: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  children: PropTypes.node,
}

export {
  extractShopifyStoreId,
  getIntegrationName,
  getIntegrationIcon,
  ForIntegration,
}
