import head from 'lodash/head'
import { action, computed, observable, reaction } from 'mobx'
import qs from 'query-string'

import { adaptSyncListApiParams, adaptSyncListCounters, adaptSyncListItem } from 'shared/adapters/retailerItems'
import {
  getRetailerItems,
  getRetailerItemsCounters,
  moveRetailerItemToImportList,
  removeRetailerItem,
  resyncRetailerItem,
} from 'shared/api/retailerItems'
import {
  RETAILER_ITEM_PUBLISHED,
  SYNC_LIST_ITEM_OMIT_FIELDS,
  PRODUCT_LIST_ORDERING_RECENTLY_ADDED,
} from 'shared/constants/retailerItems'
import { BaseListStore } from 'shared/stores'
import { getBiHeadersFromEvent } from 'shared/stores/BiLoggerStore/utils'
import { toTitleCase } from 'shared/utils/text'

import {
  ProductRemoveStore,
  SyncListBatchActionStore,
  SyncListCsvExportStore,
  SyncListItemStore,
} from 'retailer/stores'

const defaultCounters = {
  all: 0,
  progress: 0,
  success: 0,
  error: 0,
  unpublished: 0
}

class SyncListPageStore extends BaseListStore {
  /** @type {import('../context').RootStore} */
  root

  pathnameRegex = /^\/my-products\/sync-list$/

  @observable initialized = false

  @observable hasNextPage = true
  @observable csvExport
  @observable filtersPanelOpen = false
  @observable counters = defaultCounters
  @observable error

  @observable batchAction
  @observable productRemove = new ProductRemoveStore({
    onRemove: async product => await this.removeFromStore(product),
  })

  listParamsGetter = () => ({
    search: this.search,
    ordering: this.ordering,
    inventoryTypes: this.inventoryTypes,
    status: this.status,
    showOutOfStock: this.showOutOfStock,
    offset: this.offset,
  })

  /**
   * Get a param from the URL, or return defaultValue if the argument doesn't exist
   * @param {string} name
   * @param {*} defaultValue
   * @returns {*}
   */
  getParam = (name, defaultValue = undefined) => {
    const params = qs.parse(this.routerStore?.location.search, { arrayFormat: 'bracket' })
    return params[name] || defaultValue
  }

  /**
   * @param {import('../context').RootStore} root
   */
  constructor (root) {
    super(root.routerStore)
    this.root = root
    this.batchAction = new SyncListBatchActionStore(root, this)
    this.csvExport = new SyncListCsvExportStore(root, this)
    reaction(this.listParamsGetter, this.listParamsReaction)
  }

  @computed get hasStoreConnected () {
    return this.root.userProfileStore.currentStore?.isConnected
  }

  /**
   * Calculate page and page size based on limit/offset data.
   * @returns {{pageSize: number, page: number}}
   */
  @computed get pagination () {
    return {
      page: Math.ceil(this.offset / this.limit) + 1,
      pageSize: this.limit
    }
  }

  @computed get search () {
    return this.getParam('search')
  }

  @computed get ordering () {
    return this.getParam('ordering', PRODUCT_LIST_ORDERING_RECENTLY_ADDED)
  }

  @computed get inventoryTypes () {
    return this.getParam('inventoryTypes', [])
  }

  @computed get showOutOfStock () {
    return this.getParam('showOutOfStock', 'true') === 'true'
  }

  @computed get status () {
    return this.getParam('status', [])
  }

  @computed get apiParams () {
    return {
      omit: SYNC_LIST_ITEM_OMIT_FIELDS,
      status: RETAILER_ITEM_PUBLISHED,
      search: this.search,
      ordering: this.ordering,
      exportStatuses: this.status,
      inventoryTypes: this.inventoryTypes,
      showOutOfStock: this.showOutOfStock,
      ...this.pagination
    }
  }

  /**
   * Returns a list of all active filters, i.e. filters not in their default state.
   * It is returned in a form that is easy to digest by the `<ActiveFilters>` component.
   * @returns {{onDelete: function(): void, id: string, label: string}[]}
   */
  @computed get activeFilters () {
    const omitFilters = ['ordering', 'offset']
    const allFilters = this.listParamsGetter()

    // Ignore filters which are undefined or null, as these are simply unset filters
    // Also ignore `showOutOfStock` if its value is true, as this is the default value for it.
    // FIXME: Create a more generic way of handling default boolean values for filters such as `showOutOfStock`.
    const filtersFiltered = Object
      .entries(allFilters)
      .filter(([name, filter]) => filter !== undefined && filter !== null && !omitFilters.includes(name))
      .filter(([name, filter]) => !(name === 'showOutOfStock' && !!filter))

    // Gather active filters which values are not arrays. The tricky part here is to create a user friendly
    // `label` which is constructed from a user unfriendly param value from the URL query param...
    const nonArrayFilters = filtersFiltered
      .filter(([_, filter]) => !Array.isArray(filter))
      .map(([name, filter]) => ({
        id: name,
        label: typeof filter === 'boolean' ? (!filter ? 'Don\'t ' : '') + toTitleCase(name) : toTitleCase(filter),
        onDelete: () => {
          if (name === 'search') {
            this.setFilters({
              ...allFilters,
              search: undefined,
            })
            return
          }
          this.setFilters({
            ...allFilters,
            [name]: undefined
          })
        }
      }))

    // Gather active filters which values are arrays. For these filters each value from the array should be
    // treated as a separate filter.
    const arrayFilters = filtersFiltered
      .filter(([_, filter]) => Array.isArray(filter))
      .map(([name, values]) => {
        return values.map(value => ({
          id: `${name}.${value}`,
          label: toTitleCase(value),
          onDelete: () => {
            this.setFilters({
              ...allFilters,
              [name]: allFilters[name].filter(v => v !== value)
            })
          }
        }))
      }).flat()

    return [].concat(nonArrayFilters, arrayFilters)
  }

  @computed get allOnPageSelected () {
    return !!this.items?.length && this.items.length === this.selectedItems.length
  }

  @computed get selectionActive () {
    return this.allOnAllPagesSelected || this.allOnPageSelected || this.partSelected
  }

  /**
   * Calculates how many items are selected, using `this.counters.all` if all pages are selected.
   * @returns {number}
   */
  @computed get selectedCount () {
    if (this.allOnAllPagesSelected && this.allOnPageSelected) {
      return this.counters.all
    }
    if (this.allOnAllPagesSelected && !this.allOnPageSelected) {
      return this.counters.all - (this.items.length - this.selectedItems.length)
    }
    return this.selectedItems.length
  }

  @computed get oneSelected () {
    return this.selectedItems.length === 1
  }

  @computed get firstSelected () {
    return head(this.selectedItems)
  }

  getUrl ({ search, ordering, inventoryTypes, showOutOfStock, status, offset }) {
    const searchQs = qs.stringify({
      search: search === '' ? undefined : search,
      offset,
      ordering,
      inventoryTypes,
      showOutOfStock,
      status
    }, { arrayFormat: 'bracket' })
    return '/my-products/sync-list' + (searchQs ? `?${searchQs}` : '')
  }

  getItemByUuid (uuid) {
    return this.items.find(item => item.uuid === uuid)
  }

  @action.bound
  removeItemByUuid (uuid) {
    this.items = this.items.filter(item => item.uuid !== uuid)
  }

  @action.bound
  setFiltersPanelOpen (value) {
    this.filtersPanelOpen = Boolean(value)
  }

  @action.bound
  setFilters ({ search, ordering, inventoryTypes, showOutOfStock, status }) {
    this.routerStore.push(this.getUrl({
      search,
      ordering,
      inventoryTypes,
      showOutOfStock,
      status,
      offset: 0
    }))
  }

  @action.bound
  async doFetch () {
    this.setAllSelected(false)
    this.error = undefined
    try {
      const [counters, items, nextPage] = await Promise.all([
        getRetailerItemsCounters(RETAILER_ITEM_PUBLISHED),
        getRetailerItems(adaptSyncListApiParams(this.apiParams)),
        getRetailerItems(adaptSyncListApiParams({
          ...this.apiParams,
          page: this.apiParams.page + 1,
        }))
      ])
      Object.assign(this.counters, adaptSyncListCounters(counters.data))

      this.items = items.data.results.map((item, pos) => (
        new SyncListItemStore(this.root, adaptSyncListItem(item), { ...this.biContext, position: 1 + pos })
      ))
      this.hasNextPage = !!nextPage.data.results.length
      this.initialized = true
      return items
    } catch (e) {
      this.error = e
      this.items = []
      return { data: { count: 0 } } // Fake response object
    }
  }

  @action.bound
  next () {
    this.routerStore.push(this.getUrl({
      ...this.listParamsGetter(),
      offset: this.offset + this.count
    }))
  }

  @action.bound
  async moveToImportList (product) {
    const biEvent = await product.logUnsyncProductClicked()
    await moveRetailerItemToImportList(product.uuid, getBiHeadersFromEvent(biEvent))
    this.removeItemByUuid(product.uuid)
  }

  @action.bound
  async removeFromStore (product) {
    const biEvent = await product.logRemoveProductClicked()
    await removeRetailerItem(product.uuid, getBiHeadersFromEvent(biEvent))
    this.removeItemByUuid(product.uuid)
  }

  @action.bound
  resync (uuid) {
    return new Promise((resolve, reject) => {
      const item = this.getItemByUuid(uuid)
      if (!item) reject(new Error(`Item with UUID ${uuid} not found`))
      return resyncRetailerItem(uuid)
        .then(() => {
          resolve(item.refreshFromApi())
        })
        .catch(reject)
    })
  }

  @action.bound
  reset () {
    this.initialized = false
    super.reset()
    this.filtersPanelOpen = false
    this.batchAction.reset()
    this.csvExport.reset()
    this.counters = defaultCounters
  }

  /**
   * BI Context for child stores
   * @type {import('../../types').ProductBiContext
   */
  @computed get biContext () {
    return {
      origin: 'sync_list',
      appliedFilters: this.apiParams,
      appliedSort: this.ordering,
    }
  }
}

export default SyncListPageStore
