import React from 'react'

import PropTypes from 'prop-types'

import { CircularProgress } from '@material-ui/core'

import style from './Loader.module.scss'

const Loader = ({ visible }) => {
  if (!visible) return null
  return (
    <div className={style.Loader}>
      <CircularProgress />
    </div>
  )
}

Loader.propTypes = {
  visible: PropTypes.bool,
}

Loader.defaultProps = {
  visible: true,
}

export default Loader
