import { connectStoreClicked } from '@wix/bi-logger-modalyst/v2'
import { action, computed, observable } from 'mobx'

import { ALERT_SEVERITY_INFO, ALERT_SEVERITY_WARNING, ALERT_CONNECT_STORE } from './constants'

/**
 * A store for an individual Alert
 **/
class AlertStore {
  @observable key
  @observable dismissable = true
  @observable dismissed = false
  @observable severity = ALERT_SEVERITY_INFO
  @observable action
  /** Object Id for when you have many alerts of the same type re different objects */
  @observable objectId

  constructor (key, data = {}) {
    this.key = key
    Object.assign(this, data)
    this.dismissed = this.dismissable && (!!localStorage.getItem(this.dismissKey) ?? this.dismissed)
  }

  @action.bound dismiss () {
    this.dismissed = true
    localStorage.setItem(this.dismissKey, true)
  }

  @computed get dismissKey () {
    return `alert_${this.key}${this.objectId || ''}_dismissed`
  }
}

/**
 * Manages state of all Alerts
 **/
class AlertsStore {
  /** @type {import('../context').RootStore} */
  root

  /**
   * @param {import('../context').RootStore} root
   */
  constructor (root) {
    this.root = root
  }

  @computed get alertsMap () {
    return new Map([
      [ALERT_CONNECT_STORE, this.connectStoreAlert],
    ])
  }

  @computed get connectStoreAlert () {
    const currentStore = this.root.userProfileStore?.currentStore
    return currentStore && !currentStore.isConnected
      ? (
          new AlertStore(ALERT_CONNECT_STORE, {
            severity: ALERT_SEVERITY_WARNING,
            message: 'storeNotConnectedAlert.message',
            objectId: currentStore.uuid,
            action: {
              label: 'storeNotConnectedAlert.connectAction.cta',
              to: `/my-stores/${currentStore.uuid}/connect`,
              biData: connectStoreClicked({
                origin: 'alert',
                storeName: currentStore.name,
                targetStoreId: currentStore.uuid,
              }),
            },
          })
        )
      : null
  }
}

export default AlertsStore
