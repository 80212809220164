const colorScheme = {
  white: '#FFFFFF',
  primary100: '#E8ECFD',
  primary400: '#706FD2',
  primary500: '#4B4AC6',
  primary600: '#3535A7',
  primary700: '#263D91',
  primary800: '#0E2139',
  primary900: '#0B1A2D',
  secondary400: '#AA69E3',
  secondary500: '#923FDB',
  secondary600: '#7824C1',
  error50: '#F8D8D8',
  error500: '#F5696A',
  error600: '#C45455',
  error700: '#933F40',
  success50: '#E3F2EE',
  success500: '#78C3AF',
  success600: '#42947E',
  warning50: '#FFF4D1',
  warning500: '#FFC107',
  warning600: '#D19D00',
  blueGrey3: '#97ABC3',
  blueGrey4: '#6E85A6',
  blueGrey5: '#617BD6',
  accent1: '#9B5983',
  accent2: '#2B3A4D',
  grey50: '#F3F4F5',
  grey100: '#E7E9EC',
  grey200: '#CFD3D8',
  grey300: '#B6BCC5',
  grey400: '#9EA6B1',
  grey500: '#86909E',
  grey600: '#6B737E',
  grey700: '#50565F',
  grey800: '#363A3F',
  grey900: '#1B1D20',
}

export default colorScheme
