import { action, observable } from 'mobx'

import { getOrdersCount } from 'shared/api/orders'

import { RetailerSubscription, RetailerItemsCount } from 'retailer/stores'

class MainMenuStore {
  @observable open = false
  @observable ordersCount = 0
  @observable retailerItemsCount = new RetailerItemsCount()
  @observable retailerSubscription = new RetailerSubscription()

  @action.bound
  fetchOrdersCount () {
    getOrdersCount().then(response => {
      this.ordersCount = response.data.count
    })
  }

  @action.bound
  toggle () {
    this.open = !this.open
  }
}

export default MainMenuStore
