import {
  productSearchSearchApplied as biSearchApplied,
  productSearchCategoryClicked as biCategoryClicked,
} from '@wix/bi-logger-modalyst/v2'
import axios from 'axios'
import isEmpty from 'lodash/isEmpty'
import pick from 'lodash/pick'
import { action, computed, observable, reaction } from 'mobx'
import qs from 'query-string'

import { adaptGetProductsParams, adaptGetProductsResponse } from 'shared/adapters/marketplace'
import { getProducts, getCustomizableItems } from 'shared/api/marketplace'
import { CATEGORIES_PRINT_ON_DEMAND_ROOT_NAME, CATEGORIES_ROOT_NAME } from 'shared/constants/categories'
import {
  DEFAULT_ORDERING,
  DEFAULT_PAGE_SIZE,
  MARKETPLACE_CODE_PRINT_ON_DEMAND,
  MARKETPLACE_CODE_READY_TO_SELL,
  NO_ORDERING,
  SHORT_MARKETPLACE_CODES,
} from 'shared/constants/marketplace'
import { BaseListStore } from 'shared/stores'
import { getTreePath } from 'shared/utils'
import { isosToCountries } from 'shared/utils/countries'
import { logger } from 'shared/utils/debug'

import { ItemStore } from 'retailer/stores'
import { getProductSourceCounts } from 'retailer/utils/marketplace'

const MARKETPLACE_PATHNAME_REGEX = new RegExp(
  `\\/marketplace\\/(?<marketplaceCode>${MARKETPLACE_CODE_READY_TO_SELL}|${MARKETPLACE_CODE_PRINT_ON_DEMAND})`
)

/** Value separator for array params */
const PARAM_VALUE_SEPARATOR = ';'

/** Names of params included in the URL */
const URL_PARAM_NAMES = [
  'categoryId', 'search', 'ordering',
  'brandIds', 'shipsFrom', 'shipsTo', 'shipping',
  'costFrom', 'costTo',
]

/** Names of params that should trigger a reaction */
const REACTION_PARAM_NAMES = [...URL_PARAM_NAMES, 'marketplaceCode']

class MarketplaceStore extends BaseListStore {
  /** @type {import('../context').RootStore} */
  root
  cancelTokenSource = axios.CancelToken.source()
  pathnameRegex = MARKETPLACE_PATHNAME_REGEX

  /** Stores timestamp of a search action used to log a BI event */
  @observable searchStartTime
  /** Flag to set if a category was clicked to log a BI event  */
  @observable clickedCategory

  @observable page = 1

  listParamsGetter = () => pick(this, REACTION_PARAM_NAMES)
  listParamsReaction = params => {
    if (!!this.pathnameMatch && this.items !== undefined) {
      this.fetchFresh()
    }
  }

  constructor (root, data = {}) {
    super(root.routerStore, data)
    this.root = root
    reaction(this.listParamsGetter, this.listParamsReaction)
  }

  /**
   * Set searchStartTime to mark the beginning of the search op and measure its duration
   *
   * This should only be set *when you want to send the respective BI event*.
   * The property should be reset to undefined after each fetch.
   *
   * @param {Number} val
   */
  @action.bound setSearchStartTime (val) {
    this.searchStartTime = val
  }

  /**
   * Flag the category clicked BI event
   * @param {Boolean} val
  */
  @action.bound setClickedCategory (val) {
    this.clickedCategory = val
  }

  // BEGIN param getters

  @computed get marketplaceCode () {
    return this.pathnameMatch?.groups.marketplaceCode
  }

  @computed get categoryId () {
    return this.routerStore.getSearchParamValue('categoryId')
  }

  @computed get search () {
    return this.routerStore.getSearchParamValue('search')
  }

  @computed get ordering () {
    return this.routerStore.getSearchParamValue('ordering', DEFAULT_ORDERING)
  }

  @computed get brandIds () {
    return this.routerStore.getSearchParamValue('brandIds')?.split(PARAM_VALUE_SEPARATOR)
  }

  @computed get shipsFrom () {
    return this.routerStore.getSearchParamValue('shipsFrom')?.split(PARAM_VALUE_SEPARATOR)
  }

  @computed get shipsTo () {
    return this.routerStore.getSearchParamValue('shipsTo')?.split(PARAM_VALUE_SEPARATOR)
  }

  @computed get shipping () {
    return this.routerStore.getSearchParamValue('shipping')?.split(PARAM_VALUE_SEPARATOR)
  }

  @computed get costFrom () {
    return this.routerStore.getSearchParamValue('costFrom')
  }

  @computed get costTo () {
    return this.routerStore.getSearchParamValue('costTo')
  }

  @computed get filterParams () {
    return pick(this, URL_PARAM_NAMES)
  }

  /**
   * Filters on Print on Demand should not be displayed: #182272230
   * @returns {boolean}
   */
  @computed get allowFilters () {
    return this.marketplaceCode !== MARKETPLACE_CODE_PRINT_ON_DEMAND
  }

  /**
   * Checks if the banner with unlimited PoD Products should be displayed.
   * Only PoD Marketplace displays this one.
   *
   * So far this is the only banner of this type, that shows up.
   * In the future, this can be extended and moved to some sort of abstraction.
   * @returns {boolean}
   */
  @computed get showUnlimitedProductsBanner () {
    return this.marketplaceCode === MARKETPLACE_CODE_PRINT_ON_DEMAND
  }

  // END param getters

  // BEGIN param setters

  @action.bound setFilters (params) {
    this.routerStore.push(this.getUrl(params))
  }

  @action.bound updateFilters (params) {
    const current = pick(this, URL_PARAM_NAMES)
    this.setFilters(Object.assign(current, params))
  }

  @action.bound setCategory (category, fromClick = false) {
    // TODO: should this reset other filters?
    this.setClickedCategory(fromClick)
    this.setFilters({ categoryId: category?.uuid || undefined })
  }

  @action.bound setSearch (search, category = undefined) {
    this.setFilters({ search, categoryId: category?.uuid || undefined })
  }

  @action.bound setOrdering (ordering) {
    this.updateFilters({ ordering })
  }

  /** Clear a specific filter param referenced by name  */
  @action.bound clearFilter (paramName) {
    this.updateFilters({ [paramName]: undefined })
  }

  /** Remove value from an array filter param referenced by name */
  @action.bound clearFilterValue (paramName, valueToRemove) {
    this.updateFilters({ [paramName]: this[paramName].filter(value => value !== valueToRemove) })
  }

  @action.bound clearAllFilters () {
    this.setFilters({})
  }

  // END param setters

  /** Category object computed from the categoryId param */
  @computed get category () {
    return this.root.appConfigStore.categoriesUuidMap.get(this.categoryId)
  }

  /** Brand objects computed from the brandIds param */
  @computed get brands () {
    const bIds = this.brandIds || []
    return bIds.map(id => this.root.appConfigStore.activeBrandsUuidMap.get(id))
  }

  /** Country objects computed from the shipsFrom param */
  @computed get shipsFromCountries () {
    return isEmpty(this.shipsFrom) ? [] : isosToCountries(this.shipsFrom)
  }

  /** Country objects computed from the shipsTo param */
  @computed get shipsToCountries () {
    return isEmpty(this.shipsTo) ? [] : isosToCountries(this.shipsTo)
  }

  /** Category tree computed from marketplaceCode param */
  @computed get categoriesTree () {
    const rootName = this.marketplaceCode === MARKETPLACE_CODE_PRINT_ON_DEMAND
      ? CATEGORIES_PRINT_ON_DEMAND_ROOT_NAME
      : CATEGORIES_ROOT_NAME
    return this.root.appConfigStore.categoryRootsMap.get(rootName)
  }

  @computed get hasMore () {
    return this.items === undefined || this.items.length < this.totalCount
  }

  getUrl (params) {
    const search = qs.stringify(
      pick(params, URL_PARAM_NAMES),
      { arrayFormat: 'separator', arrayFormatSeparator: PARAM_VALUE_SEPARATOR }
    )
    return `/marketplace/${this.marketplaceCode}` + (search ? `?${search}` : '')
  }

  @action.bound fetchFresh () {
    this.cancelFetch()
    this.page = 1
    this.reset()
    this.fetch()
  }

  @action.bound fetchMore () {
    if (this.hasMore) {
      this.page += 1
      this.fetch()
    }
  }

  @action.bound cancelFetch () {
    if (this.cancelTokenSource) this.cancelTokenSource.cancel()
    this.cancelTokenSource = axios.CancelToken.source()
  }

  @computed get fetchParams () {
    return {
      ...pick(this, [...URL_PARAM_NAMES, 'page']),
      pageSize: DEFAULT_PAGE_SIZE
    }
  }

  doFetch () {
    const params = this.fetchParams
    const apiFn = this.marketplaceCode === MARKETPLACE_CODE_PRINT_ON_DEMAND
      ? getCustomizableItems
      : getProducts

    if (params.search && params.ordering === DEFAULT_ORDERING) {
      params.ordering = NO_ORDERING
    }

    const apiFnParams = adaptGetProductsParams(
      params,
      this.root.appConfigStore.categoriesUuidMap,
      this.root.appConfigStore.activeBrandsUuidMap,
      this.root.appConfigStore.inventoryTypesMap,
    )

    return apiFn(apiFnParams, this.cancelTokenSource.token)
      .then(response => {
        const items = this.items || []
        const results = adaptGetProductsResponse(response.data).results

        // log inventory type mix info to the console
        logger.log(getProductSourceCounts(results))

        this.setItems([...items, ...results.map((product, pos) => (
          new ItemStore(this.root, product, { ...this.biContext, position: 1 + pos + items.length })
        ))])

        // send BI events #1, #2 on success
        this.logSearchAppliedBiEvent(params, response)
        this.logCategoryClickedBiEvent(params, response)

        return response
      })
      .catch(error => {
        // XXX: whatever is returned from here is handled by `then` in BaseListStore.fetch

        // "Emergency set" the state.
        // - if this is a cancel, we can exit silently
        // - if this is a 404, we need to keep the loaded items and ensure no
        //   more fetchMore's are attempted
        // - if this is a critical error (server/network), we still want to keep
        //   the items and give the user some feedback

        if (axios.isCancel(error)) return

        // Items may be undefined if request was triggered by fetchFresh
        this.setItems(this.items || [])

        if (error.response?.status !== 404) {
          // TODO: feedback for the user?
          console.error(error)
        }

        // send BI events #1, #2 on failure
        this.logSearchAppliedBiEvent(params, error.response)
        this.logCategoryClickedBiEvent(params, error.response)

        // setting the count to actual number of items prevents fetchMore
        return { data: { count: this.items?.length || 0 } }
      })
      .finally(() => {
        this.setSearchStartTime(undefined)
        this.setClickedCategory(undefined)
      })
  }

  async logSearchAppliedBiEvent (params, response) {
    if (!params.search || !this.searchStartTime) return
    return await this.root.biLoggerStore.log(biSearchApplied({
      ...this.biEventData,
      orderingType: params.ordering, // overwrite ordering if changed for text search
      duration: Math.round(performance.now() - this.searchStartTime),
      resultsNum: response?.status === 404 ? 0 : response?.data?.count, // should be undefined on server error etc
      batchSize: params.pageSize,
    }))
  }

  async logCategoryClickedBiEvent (params, response) {
    if (!this.clickedCategory) return
    const cat = this.root.appConfigStore.categoriesUuidMap.get(params.categoryId)
    const catPath = getTreePath(cat)
    return await this.root.biLoggerStore.log(biCategoryClicked({
      ...this.biEventData,
      categoriesPath: catPath.map(obj => obj.name).join(' - '),
      pathLength: catPath.length,
      resultsNum: response?.status === 404 ? 0 : response?.data?.count, // should be undefined on server error etc
    }))
  }

  /**
   * BI Context for child stores
   * @type {import('../../types').ProductBiContext
   */
  @computed get biContext () {
    const categoryPath = this.category
      ? getTreePath(this.category).map(cat => cat.name).join(' - ')
      : null
    return {
      origin: 'marketplace',
      appliedFilters: this.fetchParams,
      appliedSort: this.ordering,
      productCategory: categoryPath || null,
      searchTerm: this.search || null,
    }
  }

  @computed get biEventData () {
    return {
      ...this.biContext,
      marketplace: SHORT_MARKETPLACE_CODES[this.marketplaceCode],
      categoryPath: this.biContext.productCategory,
      orderingType: this.ordering,
    }
  }
}

export default MarketplaceStore
