import React from 'react'

import PropTypes from 'prop-types'

import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import clsx from 'clsx'

import style from './OrDivider.module.scss'

const OrDivider = ({ label, className, switchOrientationOn, alwaysHorizontal }) => {
  const theme = useTheme()
  const switchOrientation = useMediaQuery(theme.breakpoints.up(switchOrientationOn))

  return (
    <div className={clsx(style.OrDivider, !alwaysHorizontal && switchOrientation && style.Vertical, className)}>
      <span>{label}</span>
    </div>
  )
}

OrDivider.propTypes = {
  label: PropTypes.string,
  switchOrientationOn: PropTypes.string,
  className: PropTypes.string,
  alwaysHorizontal: PropTypes.bool,
}

OrDivider.defaultProps = {
  label: 'or',
  switchOrientationOn: 'lg',
  alwaysHorizontal: false,
}

export default OrDivider
