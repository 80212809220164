import { action, observable } from 'mobx'

import {
  adaptBusinessInformation,
  adaptBusinessInformationErrors,
  adaptBusinessInformationForm,
} from 'shared/adapters/settings'
import { getBusinessInfo, saveBusinessInfo } from 'shared/api/settings'
import { processApiError } from 'shared/api/utils'
import { LoadableStore } from 'shared/stores'

class BusinessInformationStore {
  parent
  @observable loading = new LoadableStore()
  @observable isSaving = false
  @observable errors

  @observable businessName = ''
  @observable streetAddress = ''
  @observable suiteApt = ''
  @observable taxId = ''
  @observable zipCode = ''
  @observable state = ''
  @observable city = ''
  @observable country = ''
  @observable phoneNumber = ''
  @observable invoiceNote = ''

  @observable isComplete = false

  constructor (parent) {
    this.parent = parent
  }

  @action.bound
  fetch () {
    this.loading.setLoading()
    return getBusinessInfo().then(response => {
      Object.assign(this, adaptBusinessInformation(response.data))
      this.loading.setLoaded()
    })
  }

  @action.bound
  save (data) {
    this.isSaving = true
    return saveBusinessInfo(adaptBusinessInformationForm(data))
      .then(response => {
        Object.assign(this, adaptBusinessInformation(response.data))
        if (this.parent.shippingInformationSettings.sameAsBusinessInfo) {
          return this.parent.shippingInformationSettings.refresh(2000)
        }
      })
      .catch(error => {
        this.errors = processApiError(error, adaptBusinessInformationErrors)
        throw error
      })
      .finally(() => {
        this.isSaving = false
      })
  }
}

export default BusinessInformationStore
