import { computed } from 'mobx'

import { toTitleCase } from 'shared/utils/text'

class SubscriptionPlanStore {
  id
  code
  name
  shortName
  tier
  itemLimit
  priceText
  monthlyPrice
  commissionPercentText
  free
  lifetime
  popular
  lead
  hidden = false
  period
  limits = []
  limitsText
  current = false

  useWixCheckout
  wixPlanId
  wixCycle
  isWixDowngrade
  retailerExceedsProductLimit = false
  wixCheckoutUrl = {
    url: null,
    error: null
  }

  useShopifyCheckout = false
  shopifyCheckoutUrl = ''

  constructor (data) {
    Object.assign(this, data)
  }

  @computed get disabled () {
    if (this.useShopifyCheckout && this.free) return true
    if (this.useShopifyCheckout && !this.shopifyCheckoutUrl) return true
    if (!this.useWixCheckout) return false
    return this.isWixDowngrade || !this.wixCheckoutUrl.url || this.wixCheckoutUrl.error
  }

  /**
   * User friendly name in form of Yearly Startup, Monthly Pro, etc.
   * For perpetual plans we just display the plan name.
   * @returns {string}
   */
  @computed get userFriendlyName () {
    if (this.wixCycle) return toTitleCase(this.wixCycle) + ' ' + this.shortName
    return this.shortName
  }
}

export default SubscriptionPlanStore
