import {
  addProductToImportListClicked,
  viewProductDetailsClicked as biViewDetailsClicked,
  designProductClicked as biDesignProductClicked,
  orderSampleClicked,
} from '@wix/bi-logger-modalyst/v2'
import webColorNames from 'color-name'
import omit from 'lodash/omit'
import { action, computed, observable } from 'mobx'

import { adaptSetStaffPickResponse } from 'shared/adapters/marketplace'
import { importItem, setStaffPick } from 'shared/api/marketplace'
import {
  ALIBABA,
  ALIEXPRESS,
  COLOR_NAME_UNKNOWN,
  KORNIT_COLORS_MAP,
  PRINT_ON_DEMAND,
  STYLE_COMMERCE,
} from 'shared/constants/marketplace'
import { ImageStore } from 'shared/stores'
import { getBiContext, getBiHeadersFromEvent } from 'shared/stores/BiLoggerStore/utils'

import { DesignerStore, ItemShippingStore, MarketplaceItemBrandStore } from 'retailer/stores'

const COLOR_OPTION_KEYS = ['color', 'colour', 'front', 'top', 'back']
const SIZE_OPTION_KEYS = ['size']

class ItemStore {
  /** @type {import('../context').RootStore} */
  root

  // Main
  id
  name
  description
  sku
  source
  inventoryType
  category
  tags = []

  /** @type {import('../../types').ProductBiContext} */
  biContext

  // Availability
  @observable available
  @observable quantity = 0
  isPremium
  isLimited

  // Branding
  designer
  brand
  vendor

  // Urls and media
  url
  externalUrl
  mainImage
  lifestyleImage
  images = []
  @observable customEditorConfig

  // Pricing
  minCost
  maxCost
  minRetailPrice
  maxRetailPrice
  minProfit
  maxProfit
  currency

  // Stats
  @observable importsCount = 0
  vendorRating
  starRating
  reviewCount = 0

  // Shipping
  shipsFrom
  shipping
  hasFastShipping
  hasFreeShipping
  customReturns

  // Variants
  variants = []
  options = []

  // Status
  @observable isImporting = false
  @observable isImported = false
  @observable isExported = false

  // internal
  @observable staffPicks

  /**
   * @param {import('../context').RootStore} root
   * @param {Object} data
   * @param {ProductBiContext} biContext
   */
  constructor (root, data = {}, biContext) {
    this.root = root

    const { shipping, designer, brand, mainImage, lifestyleImage, images = [], ...rest } = data

    this.designer = new DesignerStore(designer)
    this.brand = new MarketplaceItemBrandStore(brand)
    this.shipping = new ItemShippingStore(this, shipping)
    this.mainImage = mainImage ? new ImageStore(mainImage) : null
    this.lifestyleImage = lifestyleImage ? new ImageStore(lifestyleImage) : null
    this.images = images.map(image => new ImageStore(image))
    Object.assign(this, rest)

    this.biContext = biContext
  }

  @computed get isCustomizable () {
    return this.inventoryType === PRINT_ON_DEMAND
  }

  @computed get isImportedOrExported () {
    return this.isImported || this.isExported
  }

  @computed get isAliExpress () {
    return this.inventoryType === ALIEXPRESS
  }

  @computed get isAlibaba () {
    return this.inventoryType === ALIBABA
  }

  @computed get is3rdPartySupplier () {
    return this.isAliExpress || this.isAlibaba
  }

  @computed get isStyleCommerce () {
    return this.source === STYLE_COMMERCE
  }

  /** Can a product sample be ordered via Modalyst? */
  @computed get canOrderSample () {
    return (
      this.available && !this.isLimited &&
      !!this.shipping.activeCountry?.shippable
    )
  }

  @computed get colorOptionKey () {
    return Object.keys(this.options).find(key => COLOR_OPTION_KEYS.includes(key.toLowerCase()))
  }

  @computed get colorOptionValues () {
    if (!this.colorOptionKey) return undefined
    const values = this.options[this.colorOptionKey].filter(val => val.toLowerCase() !== 'one color')
    return values.map(val => {
      const value = val.toLowerCase().replace(/\s/g, '')
      switch (true) {
        case value.startsWith('#'): return value
        case KORNIT_COLORS_MAP.has(value): return KORNIT_COLORS_MAP.get(value)
        case value in webColorNames: return value
        default: return COLOR_NAME_UNKNOWN
      }
    })
  }

  @computed get sizeOptionKey () {
    return Object.keys(this.options).find(key => SIZE_OPTION_KEYS.includes(key.toLowerCase()))
  }

  @computed get sizeOptionValues () {
    return this.sizeOptionKey ? this.options[this.sizeOptionKey] : undefined
  }

  @computed get otherOptions () {
    return omit(this.options, [this.sizeOptionKey, this.colorOptionKey])
  }

  @computed get colors () {
    return [...new Set(this.variants.map(variant => variant.color))]
  }

  @computed get sizes () {
    return [...new Set(this.variants.map(variant => variant.size))]
  }

  @computed get activeVariants () {
    return this.variants.filter(variant => variant.quantity > 0)
  }

  @computed get hasNegativeProfit () {
    return this.minProfit < 0
  }

  @computed get primaryImage () {
    return this.isCustomizable
      ? (this.lifestyleImage ? this.lifestyleImage : this.mainImage)
      : this.mainImage
  }

  @computed get hoverImage () {
    return this.isCustomizable
      ? (this.lifestyleImage ? this.mainImage : undefined)
      : undefined
  }

  @computed get galleryImages () {
    const excluded = [this.primaryImage?.id, this.hoverImage?.id]
    return [
      this.primaryImage, this.hoverImage,
      ...this.images.filter(obj => !excluded.includes(obj.id))
    ].filter(img => !!img)
  }

  @action.bound
  setImporting () {
    this.isImported = false
    this.isImporting = true
  }

  @action.bound
  setImported () {
    this.isImporting = false
    this.isImported = true
  }

  @action.bound
  setNotImported () {
    this.isImported = false
    this.isImporting = false
  }

  @action.bound
  async addToImportList () {
    this.setImporting()
    // TODO: remove condition after new marketplace GA (should always be logged)
    const event = (
      this.biContext &&
      await this.root.biLoggerStore.log(addProductToImportListClicked(this.biEventData))
    )
    try {
      await importItem(this.id, getBiHeadersFromEvent(event))
      this.setImported()
    } catch {
      this.setNotImported()
    }
  }

  @action.bound
  async logViewDetailsClickedBiEvent () {
    return await this.root.biLoggerStore.log(biViewDetailsClicked(this.biEventData))
  }

  @action.bound
  async logDesignClickedBiEvent () {
    return await this.root.biLoggerStore.log(biDesignProductClicked(this.biEventData))
  }

  @action.bound
  async orderSample () {
    const biEvent = await this.root.biLoggerStore.log(orderSampleClicked(this.biEventData))
    if (this.is3rdPartySupplier) this.root.routerStore.openInBlank(this.externalUrl)
    else this.root.manualOrderStore.start(this.id, undefined, getBiContext(biEvent))
  }

  @computed get optionsAsArray () {
    return [...Object.entries(this.options)].reduce((prev, curr) => {
      const [key, values] = curr
      return prev.concat(values.map(value => ({ key, value })))
    }, [])
  }

  @computed get biEventData () {
    return {
      ...this.biContext, // origin, position etc
      marketplace: this.isCustomizable ? 'POD' : 'RTS',
      modalystProductId: this.id,
      productName: this.name,
      productDescription: this.description,
      supplierProductId: this.externalId,
      supplierId: this.designer.uuid,
      itemsCost: Object.fromEntries(this.variants.map(v => [v.id, v.cost])),
      modalystVariantIds: this.variants.map(v => v.id),
      variantsCount: this.variants?.length,
      mainImageUrl: this.mainImage?.thumbnail || this.mainImage?.url || null,
      imagesNum: this.images.length,
      options: this.optionsAsArray,
    }
  }

  // staff-only logic
  @action.bound
  async setStaffPick (flag, value) {
    const response = await setStaffPick(this.id, flag, value)
    const data = adaptSetStaffPickResponse(response.data)
    this.staffPicks = data.staffPicks
    return data
  }
}

export default ItemStore
