import React from 'react'

const ScrollResetter = () => {
  React.useEffect(() => {
    // XXX: deprecated 'instant' - it may fall back to 'smooth' on some systems
    window.scrollTo({ top: 0, behavior: 'instant' })
  }, [])

  return null
}

export default ScrollResetter
