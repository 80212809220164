import { action, computed, observable } from 'mobx'

import { USER_TYPE_SUPPLIER } from 'shared/constants/accounts'
import { SHOPIFY } from 'shared/constants/integrations'
import { StoreIntegrationStore } from 'shared/stores'
import { extractShopifyStoreId } from 'shared/utils/integrations'

import { CONNECT_STORE_STATE_CHECK_FAILED, CONNECT_STORE_STATE_SUCCESS, CONNECT_STORE_STATE_UNCONNECTED } from './constants'

class ConnectStoreStore {
  /** @type {import('../root').RootStore} */
  root

  /** instance of StoreIntegrationStore */
  storeIntegration = new StoreIntegrationStore({
    integrationType: SHOPIFY,
    integrationProfileType: USER_TYPE_SUPPLIER,
    referral: 'supplier-add-products',
  })

  /** The flow has finished and its ok to hide the UI from the user */
  @observable isDone

  /** The request for authorization (including the redirects) is in progress */
  @observable isAuthorizationInProgress

  /**
   * @param {import('../root').RootStore} root
   */
  constructor (root, data = {}) {
    this.root = root
    this.update(data)
  }

  @action.bound
  update (data) {
    Object.assign(this, data)
  }

  /** Current active store authorization */
  @computed get activeAuthorization () {
    return this.root.userProfileStore && this.root.userProfileStore.storeAuthorization
  }

  /** Is any request performed currently? */
  @computed get isLoading () {
    return (
      this.isAuthorizationInProgress ||
      this.storeIntegration.isAuthorizationCheckInProgress ||
      this.storeIntegration.isConfirmAuthorizationInProgress
    )
  }

  /** Result of the authorization check */
  @computed get checkResults () {
    return this.storeIntegration.authorizationCheckResults
  }

  @computed get flowState () {
    const checkFailed = this.storeIntegration.authorizationPossible === false

    if (!checkFailed && !this.activeAuthorization) {
      return CONNECT_STORE_STATE_UNCONNECTED
    }
    if (checkFailed) {
      return CONNECT_STORE_STATE_CHECK_FAILED
    }
    if (this.activeAuthorization) {
      return CONNECT_STORE_STATE_SUCCESS
    }
  }

  // helper methods

  getAuthorizationParams () {
    const search = new URLSearchParams(window.location.search)
    return search.has('code') && Object.fromEntries(search)
  }

  /** Reset store state to initial */
  @action.bound
  reset () {
    this.isDone = undefined
    this.isAuthorizationInProgress = undefined
    this.storeIntegration.reset()
  }

  @action.bound
  setIsAuthorizationInProgress (value) {
    this.isAuthorizationInProgress = value
  }

  @action.bound
  setIsDone (value) {
    this.isDone = value
  }

  @action.bound
  checkAuthorizationState (data) {
    const authParams = this.getAuthorizationParams()
    // if Oauth params are set, meaning:
    // the integration has returned from the permissions grant,
    // immediately perform a confirmation
    if (authParams) {
      this.storeIntegration.setStoreName(extractShopifyStoreId(authParams.shop))
      this.isAuthorizationInProgress = true
      return this.storeIntegration.confirmAuthorization(authParams)
        .then(() => {
          // FIXME: storeIntegration.isXInProgress is set to false before the actions below copmmence
          // since the authorization is computed from the user profile store, refetch the profile
          this.root.userProfileStore.fetch()
          this.root.productIntegrationImportManagerStore.pollImportRun()
          this.root.productIntegrationImportManagerStore.setDetailsShown(true)
          this.setIsDone(true)
        })
        .finally(() => {
          this.setIsAuthorizationInProgress(false)
        })
    }
  }

  @action.bound
  submitStoreName (data) {
    this.isAuthorizationInProgress = true
    this.storeIntegration.setStoreName(data.storeName)
    this.storeIntegration.checkAuthorization()
      .then(data => {
        if (this.storeIntegration.authorizationPossible) {
          window.location.href = data.permissionsRequestUrl
        } else {
          this.setIsAuthorizationInProgress(false)
        }
      })
      .catch(() => {
        this.setIsAuthorizationInProgress(false)
      })
  }
}

export default ConnectStoreStore
