import React from 'react'

import PropTypes from 'prop-types'

import { Container, Link, Typography } from '@material-ui/core'
import { observer } from 'mobx-react'

import { ItemStore } from 'retailer/stores'

const MarketplaceItemReturnsTab = ({ item }) => {
  if (item.customReturns) {
    return (
      <Container>
        <Typography
          component="div" variant="body1"
          dangerouslySetInnerHTML={{ __html: item.customReturns }}
        />
      </Container>
    )
  }
  if (item.isAliExpress) {
    return (
      <Container>
        <Typography variant="body1" paragraph>
          All AliExpress items follow their Return Policy per supplier. In general, you have 15 days
          from receiving the item(s) to return your order without explanation as long as:
        </Typography>
        <Typography variant="body1" component="ul" paragraph>
          <li>All items are in perfect condition and in the original packaging</li>
          <li>Any safety seals are not damaged and all labels are retained</li>
        </Typography>
        <Typography variant="body1" paragraph>
          Return policies may vary per supplier. You can find the details on the Item Detail Page on AliExpress.<br />
          Most products qualify for&nbsp;
          <Link
            href="https://sale.aliexpress.com/__pc/1BB5HiMxKx.htm"
            target="_blank"
            rel="noopener noreferrer"
          >
            Free Returns
          </Link> and/or&nbsp;
          <Link
            href="https://sale.aliexpress.com/__pc/v8Yr8f629D.htm?spm=a2g0o.detail.1000016.1.14f345bddmLWWB#origin"
            target="_blank"
            rel="noopener noreferrer"
          >
            Buyer Protection
          </Link>.
        </Typography>
      </Container>
    )
  }

  const helpCenterUrl = 'http://support.modalyst.co/modalyst-for-retailers/shipping-and-returns/how-do-returns-work-on-modalyst'

  return (
    <Container>
      <Typography variant="body1" paragraph>
        This supplier follows a 14-day return policy during which a retailer must inform the supplier
        that they would like to return an item.
      </Typography>
      <Typography variant="body1" paragraph>
        The item must be undamaged, unworn, and unused with tags still attached within 14 days of the notification
        that the item will be returned.
      </Typography>
      <Typography variant="body1" paragraph>
        The supplier will also provide a return/refund policy for any damaged or defective items
        as long as any claims are placed within the agreed upon 5 days of the item being received.
      </Typography>
      <Typography variant="body1" paragraph>
        Customers will contact the retailer with any requests for return and the retailer
        will then contact the supplier.
      </Typography>
      <Typography variant="body1" paragraph>
        For more on returns, please see
        our <Link href={helpCenterUrl} target="_blank" rel="noopener noreferrer">Help Center</Link>.
      </Typography>
    </Container>
  )
}

MarketplaceItemReturnsTab.propTypes = {
  item: PropTypes.instanceOf(ItemStore).isRequired,
}

export default observer(MarketplaceItemReturnsTab)
