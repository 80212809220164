import React from 'react'

import PropTypes from 'prop-types'

import { observer } from 'mobx-react'

import { FiltersPanelSection, MultiCheckboxWithRhf } from 'shared/components/organisms'
import { PRODUCT_LIST_FILTERS_INVENTORY_TYPES } from 'shared/constants/retailerItems'

import { FiltersPanelAsForm } from 'retailer/components/organisms'

import style from './ImportListFilters.module.scss'

const initialValues = {
  supplier: []
}

const ImportListFilters = ({ open, onClose, filters, onSubmit }) => {
  return (
    <FiltersPanelAsForm
      className={style.ImportListFilters}
      initialValues={initialValues}
      currentValues={filters}
      onSubmit={onSubmit}
      FiltersPanelProps={{
        open,
        onClose
      }}
      renderBody={({ handleSubmit, control, getValues }) => {
        return (
          <form onSubmit={handleSubmit(onSubmit)}>
            <FiltersPanelSection title="Supplier">
              <MultiCheckboxWithRhf
                name="supplier"
                control={control}
                getValues={getValues}
                options={PRODUCT_LIST_FILTERS_INVENTORY_TYPES}
              />
            </FiltersPanelSection>
          </form>
        )
      }}
    />
  )
}

ImportListFilters.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  filters: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
}

export default observer(ImportListFilters)
