/* eslint camelcase: 0 */

const { keysToCamelCase, keysToSnakeCase } = require('shared/utils/api')

const adaptWarehouse = ({ destinations, ...data }) => ({
  destinations: destinations && destinations.map(keysToCamelCase),
  ...keysToCamelCase(data)
})

const adaptGetWarehouseResponse = adaptWarehouse

const adaptGetWarehousesResponse = ({ results, ...meta }) => ({
  results: results.map(row => adaptWarehouse(row)),
  ...keysToCamelCase(meta)
})

const adaptPutWarehouseRequest = ({ destinations, ...rest }) => ({
  destinations: destinations.map(row => keysToSnakeCase(row)),
  ...keysToSnakeCase(rest)
})

const adaptPutWarehouseErrors = ({ non_field_errors, destinations, ...fieldErrors }) => ({
  nonFieldErrors: non_field_errors,
  fieldErrors: {
    destinations: destinations && destinations.map(row => keysToCamelCase(row)),
    ...keysToCamelCase(fieldErrors),
  }
})

const adaptPostWarehouseRequest = adaptPutWarehouseRequest

const adaptPostWarehouseResponse = adaptWarehouse

const adaptPostWarehouseErrors = adaptPutWarehouseErrors

const adaptPostWarehouseSetDefaultResponse = adaptWarehouse

export {
  adaptWarehouse,
  adaptGetWarehouseResponse,
  adaptGetWarehousesResponse,
  adaptPutWarehouseRequest,
  adaptPutWarehouseErrors,
  adaptPostWarehouseRequest,
  adaptPostWarehouseResponse,
  adaptPostWarehouseErrors,
  adaptPostWarehouseSetDefaultResponse,
}
