class ProductVariantSimpleStore {
  retailerProduct

  constructor (retailerProduct, data = {}) {
    this.retailerProduct = retailerProduct
    Object.assign(this, data)
  }
}

export default ProductVariantSimpleStore
