import React from 'react'

import PropTypes from 'prop-types'

import { Grid, Hidden, Typography } from '@material-ui/core'
import { DoneOutlineRounded } from '@material-ui/icons'
import { observer } from 'mobx-react'

import style from './FeaturesTable.module.scss'

const Feature = ({ name }) => {
  return (
    <Grid container spacing={4} className={style.Row}>
      <Grid item xs={12}>
        <Typography className={style.FeatureName}>
          {name}
        </Typography>
      </Grid>
      <Grid item xs={12} lg={4} className={style.Column}>
        <DoneOutlineRounded className={style.Check} />
      </Grid>
      <Hidden mdDown>
        <Grid item xs={12} lg={4} className={style.Column}>
          <DoneOutlineRounded className={style.Check} />
        </Grid>
        <Grid item xs={12} lg={4} className={style.Column}>
          <DoneOutlineRounded className={style.Check} />
        </Grid>
      </Hidden>
    </Grid>
  )
}

Feature.propTypes = {
  name: PropTypes.string.isRequired
}

const FeaturesTable = ({ features }) => {
  return (
    <div className={style.FeaturesTable}>
      <Typography component="h3" className={style.Heading}>
        Product Automation
      </Typography>
      <div className={style.Table}>
        {features.map(feature => <Feature name={feature} key={feature} />)}
      </div>
    </div>
  )
}

FeaturesTable.propTypes = {
  features: PropTypes.arrayOf(PropTypes.string).isRequired
}

export default observer(FeaturesTable)
