import React from 'react'

import PropTypes from 'prop-types'

import { Button, IconButton, Tooltip, Typography, Grid } from '@material-ui/core'
import { Email, Lock, Phone } from '@material-ui/icons'
import clsx from 'clsx'
import { observer } from 'mobx-react'

import { Edit } from 'shared/icons'

import { Country } from 'retailer/components/atoms'
import { RetailerOrder as RetailerOrderStore } from 'retailer/stores'
import { useStores } from 'retailer/stores/hooks'

import style from './Address.module.scss'

const BUTTON_VARIANT_ICON = 'icon'
const BUTTON_VARIANT_BUTTON = 'button'

const AddressLockedInfo = () => (
  <Tooltip
    arrow
    placement="bottom"
    title="Order status does not allow for address modification"
  >
    <Lock className={style.LockIcon} />
  </Tooltip>
)

const EditAddressButton = observer(({ handleEdit, editButtonVariant, testId }) => {
  const buttonProps = { color: 'primary', size: 'small', onClick: handleEdit }
  return editButtonVariant === BUTTON_VARIANT_BUTTON
    ? (
      <Button {...buttonProps} startIcon={<Edit />} data-testid={testId}>
        Edit
      </Button>
      )
    : (
      <IconButton data-testid={testId} {...buttonProps}><Edit /></IconButton>
      )
})

EditAddressButton.propTypes = {
  handleEdit: PropTypes.func,
  editButtonVariant: PropTypes.oneOf([BUTTON_VARIANT_ICON, BUTTON_VARIANT_BUTTON]),
  testId: PropTypes.string,
}

EditAddressButton.defaultProps = {
  editButtonVariant: BUTTON_VARIANT_BUTTON,
}

const Address = ({ order, testId }) => {
  const { ordersStore } = useStores()
  const { isShippingAddressLocked } = order
  const {
    companyName,
    name,
    address,
    city,
    zipCode,
    state,
    country,
    phone,
    eMail,
  } = order.address || {}
  const handleEdit = () => {
    ordersStore.selectOrderForShippingAddressEditing(order)
  }
  return (
    <Grid container className={clsx(style.Root, style.LayoutTwoColumns)}>
      <Grid
        item
        className={style.Heading}
        component={Typography}
        variant="body1"
      >
        {isShippingAddressLocked && <AddressLockedInfo />}
        Shipping address:
      </Grid>

      <Grid
        item
        className={style.Address}
        component={Typography}
        variant="body1"
      >
        {companyName && <span data-testid={`${testId}-companyName`}>{companyName}</span>}
        {name && <span data-testid={`${testId}-name`}>{name}</span>}
        {address && <span data-testid={`${testId}-address`}>{address}</span>}
        {(city || zipCode || state) && (
          <span data-testid={`${testId}-cityStateZipcode`}>
            {city}{city && state ? ', ' : ''} {state} {zipCode}
          </span>
        )}
        {country && <span data-testid={`${testId}-country`}><Country iso={country} /></span>}
        {phone && (
          <span data-testid={`${testId}-phone`}>
            <Button
              href={`tel:${phone}`}
              size="small"
              startIcon={<Phone />}
            >
              {phone}
            </Button>
          </span>
        )}
        {eMail && (
          <span data-testid={`${testId}-email`}>
            <Button
              href={`mailto:${eMail}`}
              size="small"
              startIcon={<Email />}
            >
              {eMail}
            </Button>
          </span>
        )}
      </Grid>
      <Grid item className={style.AddressAction} component={Typography} variant="body1">
        {!isShippingAddressLocked && (
          <EditAddressButton handleEdit={handleEdit} testId={testId ? `${testId}-editButton` : undefined} />
        )}
      </Grid>
    </Grid>
  )
}

Address.propTypes = {
  order: PropTypes.instanceOf(RetailerOrderStore).isRequired,
  testId: PropTypes.string,
}

export default observer(Address)
