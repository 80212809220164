import { action, observable } from 'mobx'

import { adaptPurchaseOrderPayment } from 'shared/adapters/orders'
import { setupPurchaseOrderPayment } from 'shared/api/orders'
import { ERROR_GENERIC_API_ERROR } from 'shared/constants/messages'

import PurchaseOrder from 'retailer/stores/PurchaseOrder'

class OrderPaymentState {
  @observable order
  @observable requestInProgress
  @observable setupErrors

  constructor (order) {
    if (!(order instanceof PurchaseOrder)) {
      throw new TypeError('Requires instance of PurchaseOrder')
    }
    this.order = order
  }

  @action.bound
  setRequestInProgress (value) {
    this.requestInProgress = value
  }

  @action.bound
  setSetupErrors (value) {
    this.setupErrors = value
  }

  @action.bound
  handlePaymentStart () {
    if (this.requestInProgress) return

    this.setSetupErrors(undefined)
    this.setRequestInProgress(true)

    const { origin, search } = window.location

    return setupPurchaseOrderPayment(this.order.uuid, {
      return_url: `${origin}/retailer/orders/purchase-orders/${this.order.uuid}/payment-complete${search}`,
      cancel_url: `${origin}/retailer/orders/purchase-orders/${this.order.uuid}/payment-cancelled${search}`,
    })
      .then(({ data }) => {
        window.location.href = adaptPurchaseOrderPayment(data).continueUrl
      })
      .catch(error => {
        let errors
        if (error.response && error.response.status === 400) {
          errors = error.response.data
        } else {
          errors = [ERROR_GENERIC_API_ERROR]
        }
        this.setSetupErrors(errors)
        this.setRequestInProgress(false)
      })
  }
}

export default OrderPaymentState
