import { overwrite } from 'country-list'
import dayjs from 'dayjs'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import relativeTime from 'dayjs/plugin/relativeTime'

import { i18nConfig, defaultNamespaces } from './i18n-config'

export const configure = async (options = {}) => {
  // configure dayjs
  dayjs.extend(relativeTime)
  dayjs.extend(localizedFormat)
  // configure country-list
  overwrite([{
    code: 'TW',
    name: 'Taiwan',
  }])

  const i18nNamespaces = options.i18nNamespaces || defaultNamespaces

  if (!i18nConfig.isInitialized) {
    await i18nConfig
      .init({
        lng: 'en',
        fallbackLng: 'en',
        debug: false,
        react: {
          useSuspense: false,
          transKeepBasicHtmlNodesFor: ['br', 'strong', 'em'],
        },
        defaultNS: 'common',
        ns: i18nNamespaces
      })
  }
}
