import { api } from './common'

const baseUrl = '/api/v3'
const defaultPageSize = 5

const getOrdersCount = () => {
  return api.get(`${baseUrl}/orders/open_orders_count/`)
}

const getOrder = orderUuid => {
  return api.get(`${baseUrl}/orders/${orderUuid}/`)
}

const getOrders = ({
  pageSize = defaultPageSize,
  page = 1,
  search,
  orderStatus,
  fulfillmentStatus,
  orderSource,
  startDate,
  endDate,
  uuids,
}) => {
  return api.get(`${baseUrl}/orders/`, {
    params: {
      page,
      page_size: pageSize,
      search,
      order_status: orderStatus,
      fulfillment_status: fulfillmentStatus,
      order_type: orderSource,
      start_date: startDate,
      end_date: endDate,
      uuids,
    },
  })
}

const placeOrder = (uuid) => {
  return api.post(
    `${baseUrl}/purchase_orders/${uuid}/place/`,
    {
      screen_height: window.screen.height,
      screen_width: window.screen.width,
    }
  )
}

const markOrdered = (uuid, externalId) => {
  return api.post(
    `${baseUrl}/purchase_orders/${uuid}/mark_ordered/`,
    { external_id: externalId }
  )
}

const markOrderPaid = (uuid) => {
  return api.post(`${baseUrl}/purchase_orders/${uuid}/mark_paid/`)
}

const cancelOrder = (uuid) => {
  return api.post(`${baseUrl}/purchase_orders/${uuid}/cancel/`)
}

const markOrderFulfilled = (uuid, data) => {
  return api.post(`${baseUrl}/purchase_orders/${uuid}/mark_fulfilled/`, data)
}

const setupPurchaseOrderPayment = (uuid, data) => {
  return api.post(`${baseUrl}/purchase_orders/${uuid}/payment/`, data)
}

const updateOrderShippingAddress = (orderUuid, data) => {
  return api.put(`${baseUrl}/orders/${orderUuid}/shipping_address/`, data)
}

const getPurchaseOrder = uuid => {
  return api.get(`${baseUrl}/purchase_orders/${uuid}/`)
}

const getOrderItemShippingMethod = (itemUuid) => {
  return api.get(`${baseUrl}/order_items/${itemUuid}/shipping_method/`)
}

const updateOrderItemShippingMethod = (itemUuid, data) => {
  return api.put(`${baseUrl}/order_items/${itemUuid}/shipping_method/`, data)
}

export {
  baseUrl,
  defaultPageSize,
  getOrdersCount,
  getOrder,
  getOrders,
  markOrdered,
  placeOrder,
  markOrderPaid,
  cancelOrder,
  markOrderFulfilled,
  setupPurchaseOrderPayment,
  getPurchaseOrder,
  updateOrderItemShippingMethod,
  updateOrderShippingAddress,
  getOrderItemShippingMethod,
}
