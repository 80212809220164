import { useContext } from 'react'

import StoresContext from './context'

function useStores () {
  return useContext(StoresContext)
}

export {
  useStores
}
